import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoHeadRightsSliceState {
  value: string[];
}

const initialState: DataRepoHeadRightsSliceState = {
  value: [],
};

export const DataRepoHeadRightsSlice = createSlice({
  name: "headrights",
  initialState,
  reducers: {
    setHeadRights: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
  },
});

export default DataRepoHeadRightsSlice.reducer;

export const { setHeadRights } = DataRepoHeadRightsSlice.actions;
