import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
  Stack,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const InfoModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "13rem",
            width: "28rem",
          }}
        >
          <Box
            m="0.5rem 1rem 0.5rem 1rem"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row">
              <IconButton disableRipple>
                <InfoOutlinedIcon
                  sx={{ color: "secondary.dark", mt: "-0.25rem" }}
                />
              </IconButton>
              <Typography
                variant="body1"
                color="secondary.dark"
                fontWeight="700"
                mt="0.5rem"
              >
                Info
              </Typography>
            </Stack>
            <Box onClick={handleClose}>
              <IconButton disableRipple>
                <CloseOutlinedIcon sx={{ color: "secondary.dark" }} />
              </IconButton>
            </Box>
          </Box>
          <Box ml="2rem">
            <Typography variant="body1" color="secondary.dark" mb="0.5rem">
              <b>Public:</b> Data will be available to all authorized and
              non-authorized users.
            </Typography>
            <Typography variant="body1" color="secondary.dark" mb="0.5rem">
              <b>Group:</b> Data will be available only to authorized users
              associated with the selected group.
            </Typography>
            <Typography variant="body1" color="secondary.dark">
              <b>Private:</b> Data will only be available to you.
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
