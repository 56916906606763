import React, { Dispatch, SetStateAction, useContext, useMemo, useRef } from "react";
import { Checkbox, FormControlLabel, Typography, Box, Divider, Button, IconButton, Pagination } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { TableElements, getIconStyleByDataType } from "./TableElements";
import { DataRepoTextField } from "../DataRepoTextField/DataRepoTextField";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import { deletePoint } from "../../../features/mapPointsSlice";
import { clearPolygonCoordinates } from "../../../features/PolygonCoordinatesSlice";
import { changeClearButtonVisibilityState } from "../../../features/ClearButtonVisibilitySlice";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { changeCheckedItems } from "../../../features/DataRepoCheckedITemsSlice";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  getAllBathymetryDatasets,
  getTileDataSets,
  getMetadata,
  getProjectMetadata,
  getGisVectorData,
} from "../../../api/backend_public";
import {
  deleteBathymetryData,
  getConvertedDatasetId,
  convertDataSet,
  convertToGisVectorDataset,
  convertToTimeseriesStorage,
  convertDFS0ToTimeseriesStorage,
} from "../../../api/backend";
import { DeleteAllModal } from "../DeleteAllModal/DeleteAllModal";
import { setFileUID, setUploadCompleted } from "../../../features/DataRepoUploadStatusSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { Feature, Map } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { setProcessedFilesWithMetadata, setPublicFiles } from "../../../features/DataRepoProcessedFilesDataSlice";
import { changeUploadPressedState } from "../../../features/DataRepoUploadPressedSlice";
import proj4 from "proj4";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";
import { containsCoordinate, getCenter } from "ol/extent";
import { Point, Polygon } from "ol/geom";
import { platform, publicDataSets } from "../../../api/init";
import { useSnackbar } from "notistack";
import { Geometry } from "ol/geom";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import GeoJSON from "ol/format/GeoJSON";
import { Icon, Style } from "ol/style";
import { useSearchParams } from "react-router-dom";
import { changeSummaryState } from "../../../features/DataRepoDataSummarySlice";

interface dataProps {
  datasetType: string;
  id: string;
  name: string;
  projectId: string;
  relativePath: string;
  updatedAt: string;
  datasetUrl: string;
  metadata?: metadataProps;
}

interface metadataProps {
  Projection: string;
  fileDataType: string;
}

interface DataResponseProps {
  totalCount: number;
  offset: number;
  limit: number;
  data: dataProps[];
}

interface ExtendedDataProps extends dataProps {
  file: File;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  rights: string;
  fileCRSId: number | null;
  fileProj4String: string;
}

interface checkedProps {
  checkedItems: string[];
  checkedPublicItems: string[];
  setCheckedPublicItems: Dispatch<SetStateAction<string[]>>;
  checkedAllItems: string[];
  setCheckedAllItems: Dispatch<SetStateAction<string[]>>;
  wmtsLayers: any[];
  refreshButton: boolean;
  setRefreshButton: Dispatch<SetStateAction<boolean>>;
}

type GisLayersMap = { [key: string]: VectorLayer<VectorSource<Feature<Geometry>>> };

export const CheckBoxHeader = React.memo(
  ({
    checkedItems,
    setCheckedPublicItems,
    checkedPublicItems,
    checkedAllItems,
    setCheckedAllItems,
    wmtsLayers,
    refreshButton,
    setRefreshButton,
  }: checkedProps) => {
    const [processedFiles, setProcessedFiles] = useState<any[]>([]);
    const { idTokenPayload } = useOidcIdToken();
    const dispatch = useDispatch();
    const mapInstance = useContext(OpenlayersContext);
    const [publicData, setPublicData] = useState<DataResponseProps | null>(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [filteredFiles, setFilteredFiles] = useState(processedFiles);
    const dataType = useSelector((state: RootState) => state.headDataType.value);
    const rights = useSelector((state: RootState) => state.headRights.value);
    const username = useSelector((state: RootState) => state.headUsername.value);
    const fileDetailsInsidePolygon = useSelector((state: RootState) => state.fileDetailsInsidePolygon.value);
    const [isAscending, setIsAscending] = useState(true);
    const [isProcessedAscending, setIsProcessedAscending] = useState(true);
    const isUploadCompleted = useSelector((state: RootState) => state.dataRepoUploadStatus.isUploadCompleted);
    const fileUID = useSelector((state: RootState) => state.dataRepoUploadStatus.fileUID);
    const searchBar = useSelector((state: RootState) => state.dataRepoSearchBar.value);
    const [fileMetadatas, setFileMetadatas] = useState(new Map());
    const polygonCoordinates = useSelector((state: RootState) => state.polygon.value);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [processedCurrentPage, setProcessedCurrentPage] = useState(1);
    const [publicCurrentPage, setPublicCurrentPage] = useState(1);
    const [publicTotalPages, setPublicTotalPages] = useState(1);
    const [filteredPublicDataItems, setFilteredPublicDataItems] = useState<ExtendedDataProps[]>([]);
    const publicFilesPerPage = 5;
    const [currentProcessedFiles, setCurrentProcessedFiles] = useState<any[]>([]);
    const isUploadPressed = useSelector((state: RootState) => state.uploadPressed.value);
    const [mapZoomLevel, setMapZoomLevel] = useState(0);
    const [mapCenter, setMapCenter] = useState<any[]>([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [gisLayers, setGisLayers] = useState<GisLayersMap>({});
    const cachedProcessedFiles = useSelector((state: RootState) => state.processedFiles.filesWithMetadata);
    const cachedPublicFiles = useSelector((state: RootState) => state.processedFiles.publicFiles);
    const [publicLoading, setPublicLoading] = useState(false);
    const { data: userNames } = useSelector((state: RootState) => state.userNames);

    useEffect(() => {
      let uploadSnackbarKey: any = null;
      if (isUploadPressed && !isUploadCompleted) {
        uploadSnackbarKey = enqueueSnackbar("Uploading Dataset...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
      }

      if (isUploadCompleted) {
        if (uploadSnackbarKey !== null) {
          closeSnackbar(uploadSnackbarKey);
        }
        enqueueSnackbar("Upload successful!", {
          variant: "success",
          autoHideDuration: 4000,
        });
        dispatch(changeUploadPressedState({ value: false }));
      }
      return () => {
        if (uploadSnackbarKey !== null) {
          closeSnackbar(uploadSnackbarKey);
        }
      };
    }, [isUploadPressed, isUploadCompleted, enqueueSnackbar, closeSnackbar, dispatch]);

    const fetchMetadataForFiles = (files: any) => {
      return Promise.all(
        files.map(async (file: any) => {
          const metadata = await getMetadata(file.dataset.id);
          fileMetadatas.set(file.dataset.id, metadata);
        })
      );
    };

    const getUsername = async (id: string, fileid: string) => {
      try {
        const metadata = await getProjectMetadata(id);
        const promises = await getMetadata(fileid);
        const createdBy = metadata.createdBy;
        const lastUpdated = promises.updatedAt;
        const user = userNames.find((user: any) => user.id === createdBy);
        return { name: user ? user.name : "", lastUpdated: lastUpdated };
      } catch (error) {
        return { name: "", lastUpdated: "" };
      }
    };

    const isAllChecked = useMemo(() => {
      const totalFiles = processedFiles.length;
      return totalFiles > 0 && checkedItems.length === totalFiles;
    }, [checkedItems]);

    const isPublicChecked = useMemo(() => {
      const totalFiles = publicData?.data?.length || 0;
      return totalFiles > 0 && checkedPublicItems.length === totalFiles;
    }, [publicData, checkedPublicItems]);

    const isTotalChecked = useMemo(() => {
      const totalFiles = processedFiles.length + (publicData?.data?.length || 0);

      return totalFiles > 0 && checkedAllItems.length === totalFiles;
    }, [publicData, checkedAllItems]);

    const handleCloseDelete = () => {
      setOpenDelete(false);
    };

    const { isAuthenticated } = useOidc();

    useEffect(() => {
      const fetchAndProcessData = async () => {
        setIsLoading(true);
        try {
          let combinedData: any[] = [];

          const result1 = await getAllBathymetryDatasets(publicDataSets.PublicUserUpload.id);
          if (result1.data.length > 0) {
            const filteredData1 = result1.data.map((dataset: dataProps) => ({ dataset }));
            combinedData = [...combinedData, ...filteredData1];
          }

          if (isAuthenticated) {
            const result2 = await getAllBathymetryDatasets(publicDataSets.PrivateUserUpload.id);
            if (result2.data.length > 0) {
              const filteredData2 = result2.data
                .filter((dataset: dataProps) => dataset.relativePath === `${idTokenPayload.oid}/`)
                .map((dataset: dataProps) => ({ dataset }));
              combinedData = [...combinedData, ...filteredData2];
            }
          }

          if (combinedData.length > 0) {
            await fetchMetadataForFiles(combinedData);
            const updatedProcessedFiles = await Promise.all(
              combinedData.map(async (file) => {
                const result = await getUsername(file.dataset.projectId, file.dataset.id);
                return {
                  ...file.dataset,
                  ...fileMetadatas.get(file.dataset.id)?.metadata,
                  fileDescription: fileMetadatas.get(file.dataset.id)?.description,
                  userName: result.name,
                  lastUpdated: result.lastUpdated,
                  id: file.dataset.id,
                };
              })
            );
            setProcessedFiles(updatedProcessedFiles);
            dispatch(setProcessedFilesWithMetadata(updatedProcessedFiles));
          }
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
          setRefreshButton(false);
        }
      };

      if (cachedProcessedFiles.length === 0 || refreshButton || isUploadCompleted) {
        fetchAndProcessData();
      } else {
        setProcessedFiles(cachedProcessedFiles);
      }

      dispatch(setUploadCompleted(false));

      return () => {};
    }, [
      dispatch,
      processedFiles.length,
      cachedProcessedFiles,
      isAuthenticated,
      idTokenPayload?.oid,
      fileMetadatas,
      isUploadCompleted,
      refreshButton,
    ]);

    const handleDownload = () => {
      const filesToDownload = processedFiles.filter((file) => checkedItems.includes(file.name));

      const downloadContainer = document.createElement("div");
      downloadContainer.style.position = "fixed";
      downloadContainer.style.top = "50%";
      downloadContainer.style.left = "50%";
      downloadContainer.style.transform = "translate(-50%, -50%)";
      downloadContainer.style.backgroundColor = "white";
      downloadContainer.style.padding = "20px";
      downloadContainer.style.borderRadius = "5px";
      downloadContainer.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      downloadContainer.style.textAlign = "center";
      downloadContainer.innerHTML = "<h4>Click the links below to download the files:</h4>";

      filesToDownload.forEach((file, index) => {
        const link = document.createElement("a");
        link.href = file.datasetUrl;
        link.download = file.name;
        link.innerText = `Download ${file.name}`;
        link.style.display = "block";
        link.style.margin = "10px";
        downloadContainer.appendChild(link);
      });

      const closeButton = document.createElement("button");
      closeButton.innerText = "Close";
      closeButton.style.marginTop = "20px";
      closeButton.onclick = () => document.body.removeChild(downloadContainer);
      downloadContainer.appendChild(closeButton);

      document.body.appendChild(downloadContainer);
    };

    const handleParentCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const processedFilesNames = processedFiles.map((child) => child.dataset.name);
        const publicDataFilesNames = publicData?.data?.map((dataItem) => dataItem.name) || [];
        setCheckedAllItems([...processedFilesNames, ...publicDataFilesNames]);
        setCheckedPublicItems([...publicDataFilesNames]);
        dispatch(changeCheckedItems({ value: [...processedFilesNames] }));
      } else {
        setCheckedAllItems([]);
        setCheckedPublicItems([]);
        dispatch(changeCheckedItems({ value: [] }));
      }
    };

    const handlePublicCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const publicDataFilesNames = publicData?.data?.map((dataItem) => dataItem.name) || [];

        setCheckedPublicItems([...publicDataFilesNames]);
      } else {
        setCheckedPublicItems([]);
      }
    };

    const handleProcessedCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const processedFilesNames = processedFiles.map((child) => child.name);

        dispatch(changeCheckedItems({ value: [...processedFilesNames] }));
      } else {
        dispatch(changeCheckedItems({ value: [] }));
      }
    };

    const handleChildCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, child: string) => {
      if (event.target.checked) {
        dispatch(changeCheckedItems({ value: [...checkedItems, child] }));
      } else {
        dispatch(
          changeCheckedItems({
            value: checkedItems.filter((item) => item !== child),
          })
        );
      }
    };

    const handlePublicChildCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, child: string) => {
      if (event.target.checked) {
        setCheckedPublicItems([...checkedPublicItems, child]);
      } else {
        setCheckedPublicItems(checkedPublicItems.filter((item) => item !== child));
      }
    };

    const isIndeterminate = () => {
      const checkedCount = checkedItems.length + checkedPublicItems.length;
      return checkedCount > 0 && checkedCount < processedFiles.length;
    };

    const handleDeleteOpenClick = () => {
      setOpenDelete(true);
    };
    const removeFileExtension = (filename: string) => {
      return filename.split(".").slice(0, -1).join(".");
    };

    const removeBathymetryIconFromMap = (id: string) => {
      if (mapInstance?.current) {
        const layers = mapInstance.current.getLayers();
        if (layers) {
          layers.forEach((layer) => {
            if (layer && layer.get("id") === `bathymetryIconLayer${id}`) {
              mapInstance.current?.removeLayer(layer);
            }
          });
        }
      }
    };

    const handleDelete = async () => {
      handleCloseDelete();
      dispatch(changeClearButtonVisibilityState({ value: false }));
      dispatch(clearPolygonCoordinates());

      const filesToDelete = processedFiles.filter((file) => checkedItems.includes(file.name));
      dispatch(
        changeCheckedItems({
          value: checkedItems.filter((check) => !filesToDelete.some((file) => file.name === check)),
        })
      );

      const remainingFiles = processedFiles.filter(
        (file) => !filesToDelete.some((delFile) => delFile.name === file.name)
      );

      dispatch(setProcessedFilesWithMetadata(remainingFiles));
      setProcessedFiles(remainingFiles);

      for (const file of filesToDelete) {
        removeBathymetryIconFromMap(file.id);
        await deleteFromDataset(publicDataSets.PublicUserUpload.id, file.id);
        await deleteFromDataset(publicDataSets.PrivateUserUpload.id, file.id);
      }

      if (mapInstance && mapInstance.current) {
        const layers = mapInstance.current.getLayers();
        layers.forEach((layer, index) => {
          if (layer && typeof layer.get === "function" && layer.get("id") === "polygonLayer") {
            mapInstance.current?.removeLayer(layer);
          }
        });
      }
    };

    const deleteFromDataset = async (datasetId: string, fileId: string) => {
      try {
        const result = await getAllBathymetryDatasets(datasetId);
        const matchingDataset = result.data.find((dataset: any) => dataset.id === fileId);
        if (matchingDataset) {
          await deleteBathymetryData(matchingDataset.id);
          let filenameWithoutExtension = removeFileExtension(matchingDataset.name);
          dispatch(deletePoint({ name: filenameWithoutExtension }));
        }
      } catch (error) {
        console.error("Error deleting dataset: ", error);
      }
    };

    useEffect(() => {
      let isMounted = true;
      const fetchData = async () => {
        setPublicLoading(true);
        try {
          const response = await getAllBathymetryDatasets(publicDataSets.Bathymetry.AllFolder.project);
          if (isMounted) {
            setPublicData(response);
            dispatch(setPublicFiles(response));
            setPublicLoading(false);
          }
        } catch (e) {
          console.error(e);
        }
      };

      if (cachedPublicFiles === null) {
        fetchData().catch(console.error);
      } else {
        setPublicData(cachedPublicFiles);
      }

      return () => {
        isMounted = false;
      };
    }, []);

    const isAnyItemPublic = checkedPublicItems.some((itemName) =>
      publicData?.data.some((publicItem) => publicItem.name === itemName)
    );

    useEffect(() => {
      if (Array.isArray(processedFiles)) {
        const areAllProcessedChecked = processedFiles.every((file) => checkedItems.includes(file.name));

        const areAllPublicChecked = Array.isArray(publicData?.data)
          ? publicData.data.every((dataItem) => checkedPublicItems.includes(dataItem.name))
          : false;

        if (areAllProcessedChecked && areAllPublicChecked) {
          const allProcessedNames = processedFiles.map((file) => file.name);
          const allPublicNames = publicData?.data.map((dataItem) => dataItem.name) || [];

          setCheckedAllItems([...allProcessedNames, ...allPublicNames]);
        } else {
          setCheckedAllItems([]);
        }
      }
    }, [processedFiles, publicData, checkedItems, checkedPublicItems]);

    useEffect(() => {
      let filteredPublicDataItems: any[] = [];

      if (publicData && Array.isArray(publicData.data)) {
        filteredPublicDataItems = publicData.data
          .map((dataItem) => ({
            ...dataItem,
            file: new File([""], "filename.txt"),
            fileDescription: "",
            fileDataFormat: "",
            fileCRS: "",
            fileSurveyDate: null,
            fileXCoordinate: null,
            fileYCoordinate: null,
            fileTimeZone: "",
            fileSurfaceElevation: null,
            fileDataType: dataItem.metadata?.fileDataType ?? "Misc / Free Format",
            fileDatum: "",
            lastUpdated: dataItem.updatedAt,
            fileCRSId: null,
            fileProj4String: "",
            rights: "Public",
            data: "publicData",
          }))
          .filter((file) => !searchBar || file.name.toLowerCase().includes(searchBar.toLowerCase()))
          .filter((file) => {
            if (rights.length === 0) return true;
            return rights.some((right) => file.rights.toLowerCase().includes(right.toLowerCase()));
          })
          .filter((file) => {
            if (dataType.length === 0) return true;
            return dataType.some((type) => file.fileDataType?.toLowerCase().includes(type.toLowerCase()));
          });
      }

      setFilteredPublicDataItems(filteredPublicDataItems);
      setPublicTotalPages(Math.ceil(filteredPublicDataItems.length / publicFilesPerPage));

      if (publicCurrentPage > publicTotalPages) {
        setPublicCurrentPage(1);
      }
    }, [publicData, rights, dataType, searchBar, publicFilesPerPage, publicCurrentPage]);

    const getPolygonLayer = (map: Map) => {
      const layers = map.getLayers().getArray();
      return layers.find((layer) => layer.get("id") === "polygonLayer") as VectorLayer<VectorSource<Feature>>;
    };

    const filterTableElementData = (tableElementData: any) => {
      const matchesSearchBar = !searchBar || tableElementData.name?.toLowerCase().includes(searchBar.toLowerCase());
      const matchesRights =
        rights.length === 0 ||
        rights.some((right) => tableElementData.rights?.toLowerCase().includes(right.toLowerCase()));
      const matchesUsername = !username || tableElementData.userName?.toLowerCase().includes(username.toLowerCase());
      const matchesDataType =
        dataType.length === 0 ||
        dataType.some((type) => tableElementData.fileDataType?.toLowerCase().includes(type.toLowerCase()));

      let matchesPolygonCoordinates = true;
      let matchesMapExtent = true;

      if (
        mapInstance?.current &&
        tableElementData.fileXCoordinate !== null &&
        tableElementData.fileYCoordinate !== null
      ) {
        let proj4String;
        if (tableElementData.fileProj4String) {
          proj4String = tableElementData.fileProj4String;
        } else if (tableElementData.fileCRS) {
          const match = tableElementData.fileCRS.match(/proj4String:(.+)/);
          if (match && match.length > 1) {
            proj4String = match[1].trim();
          }
        }
        if (!proj4String) {
          return false;
        }
        const customEPSGCode = `CUSTOM:${tableElementData.id}`;

        if (proj4String) {
          proj4.defs(customEPSGCode, proj4String);
          register(proj4);
        }

        const transformedCoordinates = transform(
          [tableElementData.fileXCoordinate, tableElementData.fileYCoordinate],
          customEPSGCode,
          mapInstance.current.getView().getProjection()
        );

        const polygonLayer = getPolygonLayer(mapInstance.current);
        if (polygonLayer) {
          const source = polygonLayer.getSource();
          const polygon = source?.getFeatures()?.[0]?.getGeometry();

          if (polygon) {
            matchesPolygonCoordinates = polygon.intersectsCoordinate(transformedCoordinates);
          }
        }
        const currentExtent = mapInstance.current.getView().calculateExtent(mapInstance.current.getSize());
        matchesMapExtent = containsCoordinate(currentExtent, transformedCoordinates);
      }

      return (
        matchesSearchBar &&
        matchesRights &&
        matchesUsername &&
        matchesDataType &&
        matchesPolygonCoordinates &&
        matchesMapExtent
      );
    };

    const itemsInsidePolygonRef = useRef<string[]>([]);

    useEffect(() => {
      if (polygonCoordinates) {
        const newItemsInsidePolygon = processedFiles
          .filter((item) => filterTableElementData(item))
          .filter((item) => item.fileDataType !== "Bathymetry")
          .map((item) => item.name);

        itemsInsidePolygonRef.current = newItemsInsidePolygon;
        const newCheckedItems = Array.from(new Set([...checkedItems, ...itemsInsidePolygonRef.current]));
        dispatch(changeCheckedItems({ value: newCheckedItems }));
      } else {
        dispatch(changeCheckedItems({ value: [] }));
        itemsInsidePolygonRef.current = [];
      }
    }, [polygonCoordinates, processedFiles, dispatch]);

    useEffect(() => {
      const updateCheckedItemsIfNecessary = () => {
        const newCheckedItems = Array.from(new Set([...checkedItems, ...itemsInsidePolygonRef.current]));
        const sortedNewCheckedItems = [...newCheckedItems].sort();
        const sortedCheckedItems = [...checkedItems].sort();
        if (JSON.stringify(sortedNewCheckedItems) !== JSON.stringify(sortedCheckedItems)) {
          dispatch(changeCheckedItems({ value: newCheckedItems }));
        }
      };

      updateCheckedItemsIfNecessary();
    }, [polygonCoordinates, processedFiles]);

    const filesPerPage = 5;

    useEffect(() => {
      const indexOfLastFile = processedCurrentPage * filesPerPage;
      const indexOfFirstFile = indexOfLastFile - filesPerPage;
      const newCurrentProcessedFiles = filteredFiles.slice(indexOfFirstFile, indexOfLastFile);

      setCurrentProcessedFiles(newCurrentProcessedFiles);
    }, [filteredFiles, processedCurrentPage, filesPerPage, polygonCoordinates, mapInstance]);

    const indexOfPublicLastFile = publicCurrentPage * publicFilesPerPage;
    const indexOfPublicFirstFile = indexOfPublicLastFile - publicFilesPerPage;
    const currentPublicFiles = filteredPublicDataItems.slice(indexOfPublicFirstFile, indexOfPublicLastFile);

    useEffect(() => {
      if (mapInstance?.current) {
        const handleMapChange = () => {
          const newZoomLevel = mapInstance.current?.getView().getZoom();
          const newCenter = mapInstance.current?.getView().getCenter();

          if (newZoomLevel !== undefined) {
            setMapZoomLevel(newZoomLevel);
          }
          if (newCenter !== undefined) {
            setMapCenter(newCenter);
          }
        };

        mapInstance.current.on("moveend", handleMapChange);

        return () => {
          if (mapInstance.current) {
            mapInstance.current.un("moveend", handleMapChange);
          }
        };
      }
    }, [mapInstance]);

    useEffect(() => {
      const filterFiles = () => {
        const filtered = processedFiles.filter((file) => {
          return filterTableElementData(file);
        });

        setFilteredFiles(filtered);
        const newTotalPages = Math.ceil(filtered.length / filesPerPage);
        setTotalPages(newTotalPages);

        if (processedCurrentPage > newTotalPages || filtered.length === 0) {
          setProcessedCurrentPage(1);
        } else if (filtered.length <= filesPerPage * (processedCurrentPage - 1)) {
          setProcessedCurrentPage(Math.max(1, newTotalPages));
        }
      };

      filterFiles();
    }, [
      mapCenter,
      mapZoomLevel,
      mapInstance,
      processedFiles,
      fileMetadatas,
      rights,
      username,
      dataType,
      searchBar,
      filesPerPage,
      processedCurrentPage,
      fileDetailsInsidePolygon,
      polygonCoordinates,
    ]);

    const addIconToMap = (xCoordinate: number, yCoordinate: number, id: string, child: any) => {
      if (mapInstance?.current) {
        let proj4String;
        if (child.fileProj4String) {
          proj4String = child.fileProj4String;
        } else if (child.fileCRS) {
          const match = child.fileCRS.match(/proj4String:(.+)/);
          if (match && match.length > 1) {
            proj4String = match[1].trim();
          }
        }
        if (!proj4String) {
          return;
        }
        const customEPSGCode = `CUSTOM:${id}`;

        if (proj4String) {
          proj4.defs(customEPSGCode, proj4String);
          register(proj4);
        }

        const transformedCoordinates = transform(
          [xCoordinate, yCoordinate],
          customEPSGCode,
          mapInstance.current.getView().getProjection()
        );

        const iconFeature = new Feature({
          geometry: new Point(transformedCoordinates),
        });
        iconFeature.set("featureId", id, true);

        const iconStyle = getIconStyleByDataType(child.fileDataType, child.name, checkedItems);
        iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
          features: [iconFeature],
        });
        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        vectorLayer.set("id", `bathymetryIconLayer${id}`);
        vectorLayer.setZIndex(10000000);
        mapInstance.current.addLayer(vectorLayer);
      }
    };

    const removeIconFromMap = (id: string) => {
      if (mapInstance?.current) {
        const layers = mapInstance.current.getLayers();
        if (layers) {
          layers.forEach((layer) => {
            if (layer && layer.get("id") === `bathymetryIconLayer${id}`) {
              mapInstance.current?.removeLayer(layer);
            }
          });
        }
      }
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const addBathymetryIconToMap = async (childId: string, clickedfile: any) => {
      const gisData = await getGisVectorData(childId);
      if (!gisData.Errors) {
        const geoJsonObj = typeof gisData === "string" ? JSON.parse(gisData) : gisData;
        const features = new GeoJSON().readFeatures(geoJsonObj);
        const validFeatures = features.map((feature: any) => feature as Feature<Geometry>);

        const vectorSource = new VectorSource({
          features: validFeatures,
        });
        const extent = vectorSource.getExtent();
        const center = getCenter(extent);
        const iconFeature = new Feature({
          geometry: new Point(center),
        });

        iconFeature.setId(childId);

        const iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: "icons/Bathymetry.svg",
            scale: 1,
          }),
        });

        iconFeature.setStyle(iconStyle);

        const vectorLayer = new VectorLayer({
          source: new VectorSource({
            features: [iconFeature],
          }),
        });
        vectorLayer.setZIndex(10000000);
        vectorLayer.set("id", `bathymetryLayer${childId}`);
        mapInstance?.current?.addLayer(vectorLayer);

        mapInstance?.current?.on("singleclick", function (evt) {
          mapInstance?.current?.forEachFeatureAtPixel(evt.pixel, function (feature) {
            if (feature.getId() === childId) {
              const view = mapInstance?.current?.getView();
              view?.animate({
                center: center,
                zoom: 14,
                duration: 1000,
              });
              const { file, ...rest } = clickedfile;
              const payload = {
                filename: clickedfile.name,
                ...rest,
              };
              localStorage.setItem("fileDetails", JSON.stringify(payload));
              searchParams.set("dataset", clickedfile.name);
              setSearchParams(searchParams);
              dispatch(changeSummaryState({ value: true }));
              return true;
            }
            return false;
          });
        });
      }
    };

    useEffect(() => {
      const updateMapIcons = () => {
        processedFiles.forEach((file) => {
          if (file.fileXCoordinate !== null && file.fileYCoordinate !== null) {
            addIconToMap(file.fileXCoordinate, file.fileYCoordinate, file.id, file);
          }
          if (file.fileXCoordinate === null && file.fileYCoordinate === null && file.fileDataFormat === "GisVector") {
            addBathymetryIconToMap(file.id, file);
          }
        });
      };

      updateMapIcons();
      return () => {
        processedFiles.forEach((file) => {
          removeIconFromMap(file.id);
        });
      };
    }, [processedFiles]);

    const [convertingData, setConvertingData] = React.useState<any[]>([]);
    const [datasetID, setDatasetID] = useState("");
    const [id, setId] = useState("");
    const [data, setData] = React.useState<any[]>([]);

    useEffect(() => {
      (async () => {
        if (fileUID !== "") {
          const datasetId = await getConvertedDatasetId(fileUID);
          setDatasetID(datasetId.importResults[0].projectId);
          setId(datasetId.importResults[0].datasetId);
        }
      })();
    }, [fileUID]);

    useEffect(() => {
      if (datasetID !== "") {
        (async () => {
          const { data } = await platform.getJson(`metadata/project/${datasetID}/dataset/list`, "3");
          setData(data);
        })();
      }
    }, [datasetID]);

    useEffect(() => {
      if (datasetID !== "") {
        (async () => {
          const { data } = await platform.getJson(`conversion/transfer/list?projectId=${datasetID}`, "2");
          let transferPending = data.every((t: any) => t.status !== "Pending");
          if (!transferPending) {
            const filteredData = data.filter((data: any) => data.status === "Pending");
            setConvertingData(filteredData);
          }
        })();
      }
      return () => {};
    }, [datasetID]);

    useEffect(() => {
      let convertingSnackbarKey: any = null;

      if (convertingData && convertingData.length > 0) {
        convertingSnackbarKey = enqueueSnackbar("Converting dataset...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
      }
      const refreshIntervalId = setInterval(async () => {
        for (const item of convertingData) {
          const convertingItem = await platform.getJson(`conversion/transfer/${item.id}`, "2");

          if (convertingItem.status === "Completed" || convertingItem.status === "Error") {
            if (convertingSnackbarKey !== null) {
              closeSnackbar(convertingSnackbarKey);
            }

            let message = "Conversion successful!";
            let severity: "success" | "error" = "success";
            let autohideduration: number = 4000;

            if (convertingItem.status === "Error") {
              message = `Error occurred during conversion. ${convertingItem.errorMessage || ""}`;
              severity = "error";
              autohideduration = 120000;
            }
            enqueueSnackbar(message, {
              variant: severity,
              autoHideDuration: autohideduration,
            });
            if (convertingItem.status === "Completed") {
              setRefreshButton(true);
            }
            setDatasetID("");
            setId("");

            setConvertingData(convertingData.filter((a) => a.id !== convertingItem.id));

            const { data } = await platform.getJson(`metadata/project/${datasetID}/dataset/list`, "3");
            setData(data);
          }
        }
      }, 2000);

      setTimeout(() => {
        clearInterval(refreshIntervalId);
      }, 12000000);

      return () => {
        clearInterval(refreshIntervalId);
        if (convertingSnackbarKey !== null) {
          closeSnackbar(convertingSnackbarKey);
        }
      };
    }, [convertingData, datasetID, enqueueSnackbar, closeSnackbar]);

    useEffect(() => {
      if (datasetID !== "") {
        const convertDatasetTo = async () => {
          const fileToConvert = processedFiles.find((file) => file.projectId === datasetID && file.id === id);

          if (fileToConvert && fileToConvert.fileDataFormat === "Tagged Image File Format (.tiff)") {
            const result = await convertDataSet(
              fileToConvert,
              fileToConvert.id,
              fileToConvert.projectId,
              fileToConvert.fileCRSId
            );
            dispatch(setFileUID(""));
            setConvertingData([...(convertingData ? convertingData : []), result]);
          } else if (fileToConvert && fileToConvert.fileDataFormat === "XYZ (.xyz)") {
            const result = await convertToGisVectorDataset(
              "XyzReader",
              fileToConvert,
              fileToConvert.id,
              fileToConvert.projectId,
              fileToConvert.fileCRSId
            );
            dispatch(setFileUID(""));
            setConvertingData([...(convertingData ? convertingData : []), result]);
          } else if (
            fileToConvert &&
            fileToConvert.fileDataType.includes("(Timeseries)") &&
            fileToConvert.name.split(".")[1] === "zip"
          ) {
            const result = await convertToTimeseriesStorage(fileToConvert, fileToConvert.id, fileToConvert.projectId);
            dispatch(setFileUID(""));
            setConvertingData([...(convertingData ? convertingData : []), result]);
            setId("");
          } else if (fileToConvert && fileToConvert.fileDataFormat === "MIKE DFS0 (.dfs0)") {
            const result = await convertDFS0ToTimeseriesStorage(
              fileToConvert,
              fileToConvert.id,
              fileToConvert.projectId
            );
            dispatch(setFileUID(""));
            setConvertingData([...(convertingData ? convertingData : []), result]);
            setId("");
          } else if (fileToConvert && fileToConvert.fileDataFormat === "Point Shapefile (.shp)") {
            const result = await convertToGisVectorDataset(
              "ShpReader",
              fileToConvert,
              fileToConvert.id,
              fileToConvert.projectId,
              fileToConvert.fileCRSId
            );
            dispatch(setFileUID(""));
            setConvertingData([...(convertingData ? convertingData : []), result]);
          }
        };

        convertDatasetTo();
      }
    }, [processedFiles, datasetID]);

    const handleProcessedSortClick = () => {
      const sortedFiles = [...processedFiles].sort((a, b) => {
        if (isProcessedAscending) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });

      setProcessedFiles(sortedFiles);
      setIsProcessedAscending(!isProcessedAscending);
    };

    const handlePublicProcessedSortClick = () => {
      if (publicData) {
        const sortedData = [...(publicData.data ?? [])].sort((a, b) => {
          if (isAscending) {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        });

        const updatedPublicData = {
          ...publicData,
          data: sortedData,
        };

        setPublicData(updatedPublicData);
        setIsAscending(!isAscending);
      }
    };

    return (
      <Box bgcolor="grey.50" height="100vh">
        {checkedItems.length === 0 && checkedPublicItems.length === 0 ? (
          <Box bgcolor="white" pb="0.25rem">
            <DataRepoTextField />
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "34rem 1fr 1fr",
              gap: "1rem",
              justifyContent: "center",
              m: "2.75rem 0rem 0rem 0rem",
              pl: "1rem",
              pb: "3rem",
              maxHeight: "2.5rem",
              bgcolor: "#FFF",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTotalChecked}
                  indeterminate={isIndeterminate()}
                  onChange={handleParentCheckboxChange}
                />
              }
              label={
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  {`${checkedItems.length + checkedPublicItems.length} Selected`}
                </Typography>
              }
            />
            <Button disableRipple sx={{ "&.hover": { background: "none" } }}>
              <Box
                onClick={handleDownload}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  "&.hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Box color="primary">
                  <GetAppOutlinedIcon />
                </Box>
                <Typography variant="body2" color="primary" fontWeight="700" mt="0.25rem" ml="0.25rem" pr="0.5rem">
                  Download
                </Typography>
              </Box>
            </Button>
            <Button disabled={isAnyItemPublic}>
              <Box
                onClick={handleDeleteOpenClick}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  ml: "-0.5rem",
                  "&.hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Box color="primary">
                  <DeleteOutlinedIcon />
                </Box>
                <Typography
                  variant="body2"
                  color={isAnyItemPublic ? "gray.500" : "primary"}
                  fontWeight="700"
                  mt="0.25rem"
                  ml="0.25rem"
                >
                  Delete
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
        <Box height="calc(100vh - 12rem)" overflow="auto">
          <Box ml="1rem" mt="1rem" display="flex" flexDirection="row">
            <Typography variant="body1" color="secondary.dark" fontWeight="700">
              System Data
            </Typography>
            <LightTooltip
              title={
                <>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body1">
                    Data Warning
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    System data is currently only available for download (note, key datasets are is also available for
                    display through the ribbon, and directly linked to harbours through the Dashboard).
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="1rem" variant="body2">
                    For Phase 3, planned development includes:
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    (1) Display of system data by dataset.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    (2) Inclusion of additional metadata.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    (3) Quickview of system data.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    (4) Display of icons within the map viewer.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    (5) Zoom to functionality.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    These functionalities have already been implemented on user uploaded data for Phase 2.
                  </Typography>
                </>
              }
              placement="right"
              enterDelay={300}
              leaveDelay={200}
            >
              <IconButton sx={{ color: "secondary.dark", mt: "-0.55rem" }} disableRipple>
                <WarningAmberOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>

          <Box>
            <Box
              m="0rem 2rem 0rem 2rem"
              sx={{
                display: "grid",
                gridTemplateColumns: "1.7fr 0.3fr 0.55fr 0.55fr 0.5fr",
              }}
              bgcolor="white"
              borderRadius="8px 8px 0px 0px"
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
            >
              <Box display="flex" alignItems="center">
                <Checkbox checked={isPublicChecked} onChange={handlePublicCheckboxChange} sx={{ ml: "-0.25rem" }} />
                <Typography
                  variant="body2"
                  fontWeight="700"
                  color="secondary.dark"
                  style={{ cursor: "pointer" }}
                  onClick={handlePublicProcessedSortClick}
                >
                  Data Name {isAscending ? "↑" : "↓"}
                </Typography>
              </Box>

              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  Rights
                </Typography>
              </Box>
              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  Last Updated
                </Typography>
              </Box>
              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  User
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ m: "0rem 2rem" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                overflowX: "hidden",
                height: "18rem",

                boxSizing: "border-box",
                borderRadius: "0px 0px 8px 8px",
                pr: "1rem",
              }}
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
              m="0rem 2rem"
              bgcolor="#FFF"
            >
              {publicLoading ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="7rem">
                  <CircularProgress />
                  <Typography mt={2} variant="body1" color="secondary.dark">
                    Importing files
                  </Typography>
                </Box>
              ) : filteredPublicDataItems.length === 0 ? (
                <Box mt="1rem" ml="1rem">
                  <Typography variant="body1" color="grey.500">
                    No system data is available.
                  </Typography>
                  <Typography variant="body1" color="grey.500">
                    Consider adjusting your map viewer extent or data filtering options.
                  </Typography>
                </Box>
              ) : (
                currentPublicFiles.map((enrichedDataItem) => (
                  <React.Fragment key={enrichedDataItem.id}>
                    <TableElements
                      key={enrichedDataItem.name}
                      name={enrichedDataItem.name}
                      child={enrichedDataItem}
                      checkedItems={checkedPublicItems}
                      handleChildCheckboxChange={handlePublicChildCheckboxChange}
                      wmtsLayers={wmtsLayers}
                      processedFiles={processedFiles}
                      setProcessedFiles={setProcessedFiles}
                      gisLayers={gisLayers}
                      setGisLayers={setGisLayers}
                    />
                  </React.Fragment>
                ))
              )}
              <Box display="flex" justifyContent="center" mt="0.75rem">
                <Pagination
                  count={publicTotalPages}
                  page={publicCurrentPage}
                  onChange={(event, page) => setPublicCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </Box>
          </Box>
          <Box ml="1rem" mt="1rem" display="flex" flexDirection="row">
            <Typography variant="body1" color="secondary.dark" fontWeight="700">
              User Uploaded Data
            </Typography>
            <LightTooltip
              title={
                <>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body1">
                    Data Warning
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    This Data was uploaded by a user of the CCZIS. Data uploaded by users is not quality controlled and
                    may contain errors.
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    Any use of this data or reliance on its content by third parties is the responsibility of the third
                    party.
                  </Typography>
                </>
              }
              placement="right"
              enterDelay={300}
              leaveDelay={200}
            >
              <IconButton sx={{ color: "secondary.dark", mt: "-0.55rem" }} disableRipple>
                <WarningAmberOutlinedIcon />
              </IconButton>
            </LightTooltip>
            <LightTooltip
              title={
                <>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body1">
                    Data is uploaded in two formats:
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    1. 'raw' format (i.e., the original data format provided by the user)
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                    2. 'converted' format (i.e., a standardized data format for usage and display in the CCZIS).
                  </Typography>
                  <Typography textAlign="center" justifyContent="center" mt="1.5rem" variant="body2">
                    In Phase 3, these datasets may not be displayed seperately.
                  </Typography>
                </>
              }
              placement="right"
              enterDelay={300}
              leaveDelay={200}
            >
              <IconButton sx={{ color: "secondary.dark", mt: "-0.55rem", ml: "-0.5rem" }} disableRipple>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box>
            <Box
              m="0rem 2rem"
              sx={{
                display: "grid",
                gridTemplateColumns: "1.7fr 0.3fr 0.55fr 0.55fr 0.5fr",
              }}
              bgcolor="white"
              borderRadius="8px 8px 0px 0px"
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
            >
              <Box display="flex" alignItems="center">
                <Checkbox checked={isAllChecked} onChange={handleProcessedCheckboxChange} sx={{ ml: "-0.25rem" }} />
                <Typography
                  variant="body2"
                  fontWeight="700"
                  color="secondary.dark"
                  style={{ cursor: "pointer" }}
                  onClick={handleProcessedSortClick}
                >
                  Data Name {isProcessedAscending ? "↑" : "↓"}
                </Typography>
              </Box>

              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  Rights
                </Typography>
              </Box>
              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  Last Updated
                </Typography>
              </Box>
              <Box pt="0.75rem">
                <Typography variant="body2" fontWeight="700" color="secondary.dark">
                  User
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ m: "0rem 2rem" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                overflowX: "hidden",
                height: "18rem",
                boxSizing: "border-box",
                borderRadius: "0px 0px 8px 8px",
                pr: "1rem",
              }}
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
              m="0rem 2rem"
              bgcolor="#FFF"
            >
              <Box>
                {isLoading ? (
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt="7rem">
                    <CircularProgress />
                    <Typography mt={2} variant="body1" color="secondary.dark">
                      Importing files
                    </Typography>
                  </Box>
                ) : currentProcessedFiles.filter((file) => {
                    const requiredMetadataFields = [
                      "fileCRS",
                      "fileDataFormat",
                      "fileDataType",
                      "fileDatum",
                      "fileXCoordinate",
                      "fileYCoordinate",
                    ];

                    const isMetadataComplete = requiredMetadataFields.every((field) => field in file);
                    return isMetadataComplete && filterTableElementData(file);
                  }).length > 0 ? (
                  currentProcessedFiles
                    .filter((file) => {
                      const tableElementData = {
                        ...file,
                        fileDescription: fileMetadatas.get(file.id)?.description,
                        id: file.id,
                      };

                      const requiredMetadataFields = [
                        "fileCRS",
                        "fileDataFormat",
                        "fileDataType",
                        "fileDatum",
                        "fileXCoordinate",
                        "fileYCoordinate",
                      ];
                      const isMetadataComplete = requiredMetadataFields.every((field) => field in tableElementData);
                      const isUserNameAvailable = tableElementData.userName && tableElementData.userName.trim() !== "";

                      return isMetadataComplete /*&& isUserNameAvailable*/ && filterTableElementData(tableElementData);
                    })
                    .map((file) => {
                      const tableElementData = {
                        ...file,
                        ...fileMetadatas.get(file.id)?.metadata,
                        fileDescription: fileMetadatas.get(file.id)?.description,
                        id: file.id,
                      };

                      return (
                        <TableElements
                          key={file.id}
                          child={tableElementData}
                          checkedItems={checkedItems}
                          handleChildCheckboxChange={handleChildCheckboxChange}
                          name={file.name}
                          wmtsLayers={wmtsLayers}
                          processedFiles={processedFiles}
                          setProcessedFiles={setProcessedFiles}
                          gisLayers={gisLayers}
                          setGisLayers={setGisLayers}
                        />
                      );
                    })
                ) : (
                  <Box mt="1rem" ml="1rem">
                    <Typography variant="body1" color="grey.500">
                      No user uploaded data is available.
                    </Typography>
                    <Typography variant="body1" color="grey.500">
                      Consider adjusting your map viewer extent or data filtering options.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                <Pagination
                  count={totalPages}
                  page={processedCurrentPage}
                  onChange={(event, page) => setProcessedCurrentPage(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: "0.75rem",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <DeleteAllModal open={openDelete} handleClose={handleCloseDelete} handleDelete={handleDelete} />
      </Box>
    );
  }
);
