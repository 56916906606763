import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
  Stack,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  dataSource: string;
}

export const SeaLevelRiseModal = ({
  open,
  handleClose,
  dataSource,
}: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height:
              dataSource === "NASA Sea-Level Change" ? "17rem" : "15.5rem",
            width: "30rem",
          }}
        >
          <Box
            m="0.5rem 1rem 0.5rem 1rem"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              color="secondary.dark"
              fontWeight="700"
              mt="0.5rem"
            >
              Sea Level Rise
            </Typography>

            <Box onClick={handleClose}>
              <IconButton disableRipple>
                <CloseOutlinedIcon sx={{ color: "secondary.dark" }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            {dataSource === "NASA Sea-Level Change" ? (
              <Typography
                justifyContent="center"
                textAlign="center"
                m="0rem 3rem"
              >
                Relative Sea Level Change is based on projections by the IPCC
                6th Assessment Report (AR6), as visualized and reported by the
                United States National Space Agency (NASA), available at:{" "}
                <a
                  href="https://sealevel.nasa.gov/"
                  style={{
                    color: "#0000EE",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   NASA Sea Level Change Portal. Please refer to this source for
                  terms and conditions, definitions, and further dataset
                  details.
                </a>{" "}
                Projections are provided relative to a 1995-2014
                baseline. Please refer to this source for terms and conditions,
                definitions, and further dataset details.
              </Typography>
            ) : dataSource === "CCCS Sea-Level Change" ? (
              <Typography
                justifyContent="center"
                textAlign="center"
                m="0rem 3rem"
              >
                Relative Sea Level Change is based on projections developed by
                Natural Resources Canada, available at:{" "}
                <a
                  href="https://climatedata.ca/explore/variable/slr/?coords=44.57701840906487,-65.86818695068361,11&geo-select=&rcp=rcp85-p50&decade=2100&rightrcp=disabled"
                  style={{
                    color: "#0000EE",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sea-Level Change — Climate Data Canada.
                </a>{" "}
                Projections are based on the IPCC 5th Assessment Report (AR5)
                and regional vertical land changes, relative to 1986-2005
                conditions. Please refer to this source for terms and
                conditions, definitions, and further dataset details.
              </Typography>
            ) : (
              <Typography
                justifyContent="center"
                textAlign="center"
                m="0rem 3rem"
              >
                Relative Sea Level Rise Allowances are based on the Vertical
                Allowances from the CAN-EWLAT tool, available at:{" "}
                <a
                  href="https://www.dfo-mpo.gc.ca/science/oceanography-oceanographie/accasp-psaccma/can-ewlat-ocanee/index-eng.html"
                  style={{
                    color: "#0000EE",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Canadian Extreme Water Level Adaptation Tool (CAN-EWLAT)
                  (bio.gc.ca).
                </a>{" "}
                Projections are based on the IPCC 5th Assessment Report (AR5)
                and regional vertical land changes, relative to 2010. Please
                refer to this source for terms and conditions, definitions, and
                further dataset details.
              </Typography>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
