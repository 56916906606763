import React from "react";
import ReactECharts from "echarts-for-react";
import { Box, Typography } from "@mui/material";
import { EChartsOption } from "echarts";

interface ChartData {
  name: string;
  data: number[];
  color: string;
}

interface Props {
  data: ChartData[];
  assetElevation: number | null;
  bedElevation: number | null;
}

export const DashboardWaterLevelChart: React.FC<Props> = ({ data, assetElevation, bedElevation }) => {
  const highestDataValue = Math.max(...data.flatMap((d) => d.data));
  const lowestDataValue = Math.min(...data.flatMap((d) => d.data));

  const option: EChartsOption = {
    tooltip: {
      trigger: "none",
      axisPointer: {
        type: "none",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["meters", "meters"],
      axisLabel: {
        show: false,
      },
    },

    yAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      min: lowestDataValue - 2,
      max: highestDataValue,
    },
    series: data.map((d) => ({
      name: d.name,
      type: "line",
      lineStyle: {
        color: "black",
        width: 1,
        type: "solid",
      },
      areaStyle: {
        color: d.color,
      },
      symbol: "circle",
      symbolSize: 0,
      showAllSymbol: true,
      emphasis: {
        focus: "series",
      },

      data: d.data.map((value, index) => ({
        value,
        label: {
          show: index === 1,
          offset: [-30, 0],
          position: "left",
          color: "black",
          formatter: [`{a|${value}m ${d.name}}`].join("\n"),
          rich: {
            a: {
              color: "black",
              backgroundColor: "white",
              width: 150,
              height: 20,

              align: "center",
              borderWidth: 1,
              borderColor: "#DBE4E9",
              borderRadius: 4,
              z: 100,
            },
          },
        },
      })),
      z: 5,
    })),

    graphic: [
      {
        type: "image",
        z: 10,
        left: "65",
        bottom: "0",
        style: {
          image: "images/waterImage.png",
          width: 595,
          height: 325,
        },
      },
    ],
  };

  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{
        m: "1rem 1.5rem 2rem 1.8rem",
        height: "25rem",
        width: "46rem",
        border: "1px solid #CFDBE2",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        cursor: "default",
      }}
    >
      <Box>
        <Typography variant="h4" m="1rem 0rem 0rem 1rem">
          Water Level
        </Typography>
      </Box>
      {assetElevation !== null && bedElevation !== null ? (
        <ReactECharts
          option={option}
          style={{
            width: "100%",
            height: "24rem",
            marginTop: "-2.5rem",
            marginLeft: "-1rem",
          }}
        />
      ) : (
        <Box>
          <Typography variant="body1" color="secondary.dark" textAlign="center" justifyContent="center" mt="17%">
            Please enter Asset Elevation & Bed Elevation values to see water level chart.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
