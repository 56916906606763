import { Box, Button, TextField, CircularProgress, Typography, IconButton } from "@mui/material";
import { useState, useRef } from "react";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Document, Send } from "@dhi/icons/dist";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

export const ToolboxSuggestionUpload = () => {
  const [value, setValue] = useState("");
  const [files, setFiles] = useState<Array<{ name: string; isUploading: boolean }>>([]);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [suggestionError, setSuggestionError] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event?.target.value);
    setSuggestionError(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event?.target.value);
    setNameError(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.target.value;
    setEmail(emailInput);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleUploadClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files;
    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        setFiles((prevFiles) => [...prevFiles, { name: filesList[i].name, isUploading: true }]);
        setFiles((prevFiles) =>
          prevFiles.map((file) => (file.name === filesList[i].name ? { ...file, isUploading: false } : file))
        );
      }
    }
  };

  const handleDeleteClick = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleSendButtonClick = () => {
    if (username.trim() === "" || email.trim() === "" || value.trim() === "") {
      if (username.trim() === "") {
        setNameError(true);
      }
      if (email.trim() === "") {
        setEmailError(true);
      }
      if (value.trim() === "") {
        setSuggestionError(true);
      }
      return;
    }

    const subject = encodeURIComponent("CCZIS: Toolbox Suggestion");
    const body = encodeURIComponent(
      `${username} (${email}) suggests:\n\n${value}\n\n---\nFiles (to be attached manually)\n${files
        .map((file) => file.name)
        .join("\n")}`
    );

    window.location.href = `mailto:info@cczis.ca?subject=${subject}&body=${body}`;
  };

  return (
    <Box m="1.5rem 1rem 0rem 1rem">
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          multiline={true}
          value={value}
          onChange={handleChange}
          placeholder="Write your suggestion..."
          label="Suggested Tool"
          error={suggestionError}
          helperText={suggestionError ? "Suggestion cannot be empty" : ""}
          inputProps={{
            style: {
              height: "7rem",
              overflow: "auto",
            },
          }}
        />
      </Box>
      <Box mt="0.5rem" display="flex" flexDirection="row" gap="1rem">
        <Box>
          <LightTooltip
            title="Submittal of form through the web-interface, and attachments, to come in Phase 3"
            placement="right"
            enterDelay={500}
            leaveDelay={200}
          >
            <span>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                }}
                disableRipple
                disabled
                onClick={handleUploadClick}
              >
                <Box ml="-0.5rem" mt="0.5rem">
                  <UploadOutlinedIcon />
                </Box>

                <Box>Upload</Box>
              </Button>
            </span>
          </LightTooltip>
          <input type="file" multiple style={{ display: "none" }} ref={fileInput} onChange={handleFileChange} />
          <Box>
            <Typography variant="caption" color="grey.500">
              Max file size 25MB
            </Typography>
          </Box>
        </Box>
        <Typography color="secondary.dark">
          "Enhance your suggestions with uploads. Share screenshots and documents to support your feature ideas!"
        </Typography>
      </Box>
      <Box mt="1rem" sx={{ overflow: "auto" }} maxHeight="15rem">
        {files.length > 0 && (
          <Box>
            <Typography variant="body2" fontWeight="700" color="secondary.dark">
              Uploaded Files :
            </Typography>
          </Box>
        )}
        <Box ml="-1.5rem" mt="0.5rem">
          {files.map((file, index) => (
            <Box key={index} display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row">
                {file.isUploading ? (
                  <Box ml="1.5rem" mb="0.5rem" mt="0.5rem" mr="0.5rem">
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <Box ml="1rem">
                    <Document fontSize="large" sx={{ color: "secondary.dark" }} />
                  </Box>
                )}
                <Typography variant="body2" mt="0.5rem" color="secondary.dark">
                  {file.name}
                </Typography>
              </Box>
              <Box>
                {!file.isUploading && (
                  <IconButton sx={{ mt: "-0.25rem" }} disableRipple onClick={() => handleDeleteClick(file.name)}>
                    <DeleteOutlineOutlinedIcon sx={{ color: "secondary.dark" }} />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap="1rem" mt={files.length > 0 ? "1rem" : "0rem"}>
        <TextField
          variant="outlined"
          fullWidth
          multiline={true}
          value={username}
          size="small"
          onChange={handleNameChange}
          label="Name Surname"
          error={nameError}
          helperText={nameError ? "Pease enter your name" : ""}
        />
        <TextField
          variant="outlined"
          type="email"
          fullWidth
          value={email}
          size="small"
          onChange={handleEmailChange}
          label="Email address"
          error={emailError}
          helperText={emailError ? "Pease enter a valid email" : ""}
        />
      </Box>
      <Box>
        <Typography variant="body2" color="secondary.dark" mt="0.5rem">
          Leave your email address for further communication. We may reach out to you if we require additional
          information regarding your suggestion.
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        onClick={() => {
          setEmail("");
          setFiles([]);
          setUsername("");
          setValue("");
        }}
      >
        <Button
          variant="contained"
          disableRipple
          sx={{ height: "2.5rem", width: "6rem" }}
          onClick={handleSendButtonClick}
        >
          <Box ml="-0.5rem" mt="0.2rem">
            <Send fontSize="large" />
          </Box>
          <Typography>Send</Typography>
        </Button>
      </Box>
      {/* <Snackbar open={snackbarState} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <SnackbarAlert onClose={handleCloseSnackBar} severity="success">
          Suggestion sent successfully!
        </SnackbarAlert>
      </Snackbar> */}
    </Box>
  );
};
