import { Box, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { changeToolboxActivateUpload } from "../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { UploadSeasonalModal } from "../UploadSeasonalData/UploadSeasonalModal";
import { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../../store/store";
import { ImportFromDataRepositoryModal } from "../ImportFromDataRepository/ImportFromDataRepositoryModal";
import { SeasonalStatisticsFile } from "./SeasonalStatisticsFile";
import { getAllBathymetryDatasets, getMetadata } from "../../../api/backend_public";
import { platform, publicDataSets } from "../../../api/init";
import {
  convertDFS0ToTimeseriesStorage,
  convertToTimeseriesStorage,
  getConvertedDatasetId,
} from "../../../api/backend";
import {
  setToolboxFileUID,
  setToolboxUploadCompleted,
  setToolboxUploadPressed,
} from "../../../features/ToolboxFileStatus";
import { useSnackbar } from "notistack";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface DateProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  selectedValue: string;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  setStartDate: Dispatch<SetStateAction<Dayjs | null>>;
  setEndDate: Dispatch<SetStateAction<Dayjs | null>>;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  seasonalFiles: any[];
  setSeasonalFiles: Dispatch<SetStateAction<any[]>>;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  fileTimePeriod: {
    start: string;
    end: string;
  };
  setFileTimePeriod: Dispatch<
    SetStateAction<{
      start: string;
      end: string;
    }>
  >;
}

export const SeasonalStatisticsTimeseries = ({
  setStartDate,
  setEndDate,
  setSelectedValue,
  setFileTimePeriod,
  checked,
  setChecked,
  seasonalFiles,
  setSeasonalFiles,
  refresh,
  setRefresh,
}: DateProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [fileMetadatas, setFileMetadatas] = useState(new Map());
  const fileDescription = useSelector((state: RootState) => state.ToolboxFileDescription.value);
  const bathymetryInterpolationImportStatus = useSelector(
    (state: RootState) => state.bathymetryInterpolationImport.isImportCompleted
  );
  const { isAuthenticated } = useOidc();
  const { idTokenPayload } = useOidcIdToken();
  const [isIconAdded, setIsIconAdded] = useState(false);
  const fileUID = useSelector((state: RootState) => state.toolboxUploadStatus.fileUID);
  const [convertingData, setConvertingData] = useState<any[]>([]);
  const [datasetID, setDatasetID] = useState("");
  const [data, setData] = useState<any[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isUploadPressed = useSelector((state: RootState) => state.toolboxUploadStatus.isUploadPressed);
  const isUploadCompleted = useSelector((state: RootState) => state.toolboxUploadStatus.isUploadCompleted);
  const { data: userNames } = useSelector((state: RootState) => state.userNames);

  useEffect(() => {
    if (isAuthenticated) {
      let uploadSnackbarKey: any = null;
      if (isUploadPressed && !isUploadCompleted) {
        uploadSnackbarKey = enqueueSnackbar("Uploading Dataset...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
      }

      if (isUploadCompleted) {
        if (uploadSnackbarKey !== null) {
          closeSnackbar(uploadSnackbarKey);
        }
        enqueueSnackbar("Upload successful!", {
          variant: "success",
          autoHideDuration: 4000,
        });
        dispatch(setToolboxUploadPressed(false));
        dispatch(setToolboxUploadCompleted(false));
      }
      return () => {
        if (uploadSnackbarKey !== null) {
          closeSnackbar(uploadSnackbarKey);
        }
      };
    }
  }, [isUploadPressed, isUploadCompleted, enqueueSnackbar, closeSnackbar, dispatch]);

  useEffect(() => {
    (async () => {
      if (fileUID !== "") {
        const dataset = await getConvertedDatasetId(fileUID);

        setDatasetID(dataset.importResults[0].projectId);
      }
    })();
  }, [fileUID]);

  useEffect(() => {
    if (datasetID !== "") {
      (async () => {
        const { data } = await platform.getJson(`metadata/project/${datasetID}/dataset/list`, "3");

        setData(data);
      })();
    }
  }, [seasonalFiles, fileUID, datasetID]);

  useEffect(() => {
    if (datasetID !== "") {
      (async () => {
        const { data } = await platform.getJson(`conversion/transfer/list?projectId=${datasetID}`, "2");

        let transferPending = data.every((t: any) => t.status !== "Pending");
        if (!transferPending) {
          const filteredData = data.filter((data: any) => data.status === "Pending");
          setConvertingData(filteredData);
        }
      })();
    }
    return () => {};
  }, [seasonalFiles, fileUID, datasetID]);

  useEffect(() => {
    let convertingSnackbarKey: any = null;

    if (convertingData && convertingData.length > 0) {
      convertingSnackbarKey = enqueueSnackbar("Converting dataset...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }
    const refreshIntervalId = setInterval(async () => {
      for (const item of convertingData) {
        const convertingItem = await platform.getJson(`conversion/transfer/${item.id}`, "2");

        if (convertingItem.status === "Completed" || convertingItem.status === "Error") {
          if (convertingSnackbarKey !== null) {
            closeSnackbar(convertingSnackbarKey);
          }

          let message = "Conversion successful!";
          let severity: "success" | "error" = "success";
          let autohideduration: number = 4000;

          if (convertingItem.status === "Error") {
            message = `Error occurred during conversion. ${convertingItem.errorMessage || ""}`;
            severity = "error";
            autohideduration = 4000;
          }
          enqueueSnackbar(message, {
            variant: severity,
            autoHideDuration: autohideduration,
          });
          if (convertingItem.status === "Completed") {
            setRefresh(true);
          }

          setDatasetID("");

          setConvertingData(convertingData.filter((a) => a.id !== convertingItem.id));

          const { data } = await platform.getJson(`metadata/project/${datasetID}/dataset/list`, "3");
          setData(data);
        }
      }
    }, 2000);

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 12000000);

    return () => {
      clearInterval(refreshIntervalId);
      if (convertingSnackbarKey !== null) {
        closeSnackbar(convertingSnackbarKey);
      }
    };
  }, [convertingData, datasetID, seasonalFiles, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (datasetID !== "") {
      const convertDatasetTo = async () => {
        const fileToConvert = seasonalFiles.find((file) => file.projectId === datasetID);
        if (
          fileToConvert &&
          fileToConvert.fileDataType.includes("(Timeseries)") &&
          fileToConvert.name.split(".")[1] === "zip"
        ) {
          const result = await convertToTimeseriesStorage(fileToConvert, fileToConvert.id, fileToConvert.projectId);
          dispatch(setToolboxFileUID(""));
          setConvertingData([...(convertingData ? convertingData : []), result]);
        } else if (fileToConvert && fileToConvert.fileDataFormat === "MIKE DFS0 (.dfs0)") {
          const result = await convertDFS0ToTimeseriesStorage(fileToConvert, fileToConvert.id, fileToConvert.projectId);
          dispatch(setToolboxFileUID(""));
          setConvertingData([...(convertingData ? convertingData : []), result]);
        }
      };
      convertDatasetTo();
    }
  }, [seasonalFiles, datasetID]);

  const handleOpen = () => {
    setOpen(true);
    dispatch(changeToolboxActivateUpload({ value: false }));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openImportDataRepo, setOpenImportDataRepo] = useState(false);
  const handleDataRepoOpen = () => {
    setOpenImportDataRepo(true);
  };
  const handleDataRepoClose = () => {
    setOpenImportDataRepo(false);
  };

  const fetchMetadataForFiles = (files: any) => {
    return Promise.all(
      files.map(async (file: any) => {
        const metadata = await getMetadata(file.id);
        fileMetadatas.set(file.id, metadata);
      })
    );
  };

  useEffect(() => {
    const fetchCloudData = async () => {
      try {
        let combinedData: any[] = [];

        const result1 = await getAllBathymetryDatasets(publicDataSets.PrivateTemporaryTimeseries.id);

        if (result1.data.length > 0) {
          const filteredData1 = result1.data.filter((dataset: any) => dataset.createdBy === idTokenPayload.oid);
          combinedData = [...combinedData, ...filteredData1];
        }

        if (combinedData.length > 0) {
          await fetchMetadataForFiles(combinedData);
          const updatedProcessedFiles = await Promise.all(
            combinedData.map(async (file) => {
              return {
                ...file,
                ...fileMetadatas.get(file.id)?.metadata,
                fileDescription: fileMetadatas.get(file.id)?.description,
                id: file.id,
              };
            })
          );
          setSeasonalFiles(updatedProcessedFiles);
          setToolboxUploadCompleted(false);
          setRefresh(false);
        }
      } catch (error) {
        console.error("Error fetching cloud data:", error);
      }
    };
    if (isAuthenticated) {
      if (seasonalFiles.length === 0 || refresh) {
        fetchCloudData();
      }
    }
  }, [fileDescription, bathymetryInterpolationImportStatus, refresh, seasonalFiles]);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt="1rem">
        <Box ml="1rem" display="flex" flexDirection="column" gap="0rem">
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            Timeseries
          </Typography>
          <Typography variant="caption" color="grey.500">
            Max upload size limit: 500MB.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="1rem" mr="1rem">
          <LightTooltip
            title="Login to use the toolbox"
            placement="left"
            enterDelay={500}
            leaveDelay={200}
            disableHoverListener={!!idTokenPayload}
          >
            <span>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                component="label"
                onClick={handleOpen}
                disabled={seasonalFiles.length !== 0 || !idTokenPayload}
              >
                <Typography fontWeight="700" variant="body2">
                  Upload from your device
                </Typography>
              </Button>
            </span>
          </LightTooltip>
          <LightTooltip
            title="Login to use the toolbox"
            placement="left"
            enterDelay={500}
            leaveDelay={200}
            disableHoverListener={!!idTokenPayload}
          >
            <span>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={seasonalFiles.length !== 0 || !idTokenPayload}
                onClick={handleDataRepoOpen}
              >
                <Typography fontWeight="700" variant="body2">
                  Import from data repository
                </Typography>
              </Button>
            </span>
          </LightTooltip>
        </Box>
        <UploadSeasonalModal open={open} handleClose={handleClose} setChecked={setChecked} checked={checked} />
        {userNames.length > 0 && (
          <ImportFromDataRepositoryModal
            open={openImportDataRepo}
            handleClose={handleDataRepoClose}
            type="timeseries"
          />
        )}
      </Box>

      {seasonalFiles.length > 0 &&
        (() => {
          const convertedFile = seasonalFiles.find((file) => file.name.includes("(converted)"));
          if (convertedFile) {
            const tableElementData = {
              ...convertedFile,
              fileDescription: fileMetadatas.get(convertedFile.id)?.description,
              id: convertedFile.id,
            };

            const requiredMetadataFields = [
              "fileCRS",
              "fileDataFormat",
              "fileDataType",
              "fileDatum",
              "fileXCoordinate",
              "fileYCoordinate",
            ];

            const isMetadataComplete = requiredMetadataFields.every((field) => field in tableElementData);

            if (isMetadataComplete) {
              return (
                <SeasonalStatisticsFile
                  index={seasonalFiles.indexOf(convertedFile)}
                  tableElementData={tableElementData}
                  seasonalFiles={seasonalFiles}
                  setSeasonalFiles={setSeasonalFiles}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setSelectedValue={setSelectedValue}
                  setFileTimePeriod={setFileTimePeriod}
                  isIconAdded={isIconAdded}
                  setIsIconAdded={setIsIconAdded}
                  key={convertedFile.id}
                />
              );
            }
          }
        })()}
    </>
  );
};
