import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoProcessFileSliceState {
  value: boolean;
}

const initialState: DataRepoProcessFileSliceState = {
  value: false,
};

export const DataRepoProcessFileSlice = createSlice({
  name: "ProcessFile",
  initialState,
  reducers: {
    changeDataRepoProcessFileState: (
      state,
      action: PayloadAction<DataRepoProcessFileSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoProcessFileSlice.reducer;

export const { changeDataRepoProcessFileState } =
  DataRepoProcessFileSlice.actions;
