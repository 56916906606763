import { Box } from "@mui/material";
import { wmtsGroup, debugGroup, drawLayer } from "../Openlayers/default_groups_layers";
import { useOpenlayersContext } from "../Openlayers/OpenlayersContext";
import { Snackbar, Alert, AlertProps } from "@mui/material";
import { useState, forwardRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { changeSnackbarState } from "../../features/DataRepoSnackbarSlice";
import { DataRepoHeader } from "../DataRepositoryComponents/DataRepoHeader";
import { CheckBoxHeader } from "../DataRepositoryComponents/CheckBoxHeader/CheckBoxHeader";
import { DataRepoFileDetails } from "../DataRepositoryComponents/DataRepoFileDetails/DataRepoFileDetails";
import { useLocation } from "react-router-dom";
import { fetchUserNames } from "../../features/UserNamesSlice";
import BaseLayer from "ol/layer/Base";
import { changeBathyButtonSelected } from "../../features/ToolboxBathymetryInterpolationImportSlice";
import { setTimeseriesGenerateStatisticsClicked } from "../../features/ToolboxFeatures/SeasonalStatisticsSlice";
import { platform } from "../../api/init";
import { setWaveProfileGenerateStatisticsClicked } from "../../features/ToolboxFeatures/WaveProfileToolboxSlice";
import { changeHeadDataType } from "../../features/DataRepoHeadDataTypeSlice";

const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

interface PanelProps {
  wmtsLayers: any[];
}

const RepositoryPanel = ({ wmtsLayers }: PanelProps) => {
  const map = useOpenlayersContext();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const fileParam = urlParams.get("dataset");

  useEffect(() => {
    drawLayer.setVisible(true);

    return () => {
      drawLayer.setVisible(false);
    };
  }, []);

  const dispatch = useDispatch();
  const dataSummaryState = useSelector((state: RootState) => state.dataSummary.value);
  const snackbarState = useSelector((state: RootState) => state.snackbar.value);
  const checkedItems = useSelector((state: RootState) => state.checkedItems.value);
  const [checkedPublicItems, setcheckedPublicItems] = useState<string[]>([]);
  const [checkedAllItems, setcheckedAllItems] = useState<string[]>([]);
  const [refreshButton, setRefreshButton] = useState(false);
  const { data: userNames, status } = useSelector((state: RootState) => state.userNames);
  const jobId = useSelector((state: RootState) => state.seasonalToolbox.jobId);
  const waveProfileJobId = useSelector((state: RootState) => state.waveProfileToolbox.jobId);

  useEffect(() => {
    if (!map?.current) return;
    const layers = map.current.getLayers().getArray();
    const layerToRemove = layers.find((layer) => layer.get("customPointsLayerName") === "customPointsLayer");
    if (layerToRemove) {
      map.current.removeLayer(layerToRemove);
    }
  }, []);

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setTimeseriesGenerateStatisticsClicked(false));
    }
    if (waveProfileJobId !== "") {
      await platform.putjson(`process/job/${waveProfileJobId}/cancel`, "3");
      dispatch(setWaveProfileGenerateStatisticsClicked(false));
    }
  };

  useEffect(() => {
    stopInterpolation();

    if (map?.current) {
      dispatch(changeBathyButtonSelected(""));
      const layersToRemove: BaseLayer[] = [];
      map.current.getLayers().forEach((layer: BaseLayer) => {
        if (layer.get("customLayerName") === "customPolygonLayer") {
          layersToRemove.push(layer);
        } else if (layer.get("customId") === "drawnPolygon") {
          layersToRemove.push(layer);
        } else if (layer.get("resultId") === "resultGisLayer") {
          layersToRemove.push(layer);
        }
      });

      layersToRemove.forEach((layer) => {
        map.current?.removeLayer(layer);
      });
    }
  }, []);

  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(changeSnackbarState({ value: false }));
  };
  return (
    <Box
      bgcolor="#FFFFFF"
      width="800px"
      zIndex="100"
      boxShadow="4px 0 8px 0 rgba(0, 0, 0, 0.15)"
      mt="-0.75rem"
      height="100vh"
    >
      {!fileParam ? (
        <Box>
          <DataRepoHeader setRefreshButton={setRefreshButton} />
          <Box>
            {userNames.length > 0 && (
              <CheckBoxHeader
                checkedItems={checkedItems}
                checkedPublicItems={checkedPublicItems}
                setCheckedPublicItems={setcheckedPublicItems}
                checkedAllItems={checkedAllItems}
                setCheckedAllItems={setcheckedAllItems}
                wmtsLayers={wmtsLayers}
                refreshButton={refreshButton}
                setRefreshButton={setRefreshButton}
              />
            )}
          </Box>
        </Box>
      ) : (
        <DataRepoFileDetails />
      )}

      <Snackbar open={snackbarState} autoHideDuration={2000} onClose={handleCloseSnackBar}>
        <SnackbarAlert onClose={handleCloseSnackBar} severity="success">
          Data uploaded successfully!
        </SnackbarAlert>
      </Snackbar>
    </Box>
  );
};

export default RepositoryPanel;
