import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const TandCModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "45rem" },
          }}
        >
          <Box>
            <Box display="flex" flexDirection="row">
              <Box textAlign="center" width="100%">
                <Typography
                  variant="h2"
                  sx={{
                    mt: "2rem",
                    ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                    textAlign: "inherit",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Box>
              <IconButton
                sx={{ mr: "2rem", mt: "1rem" }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box m="2rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                <b>These Terms and Conditions are DRAFT.</b> They are included
                in this Phase 2 prototype to provide an example of the final
                Privacy Policy which will be included in Phase 3. As part of
                Phase 3, the Terms and Conditions will be updated to ensure
                compliance with governing agency requirements.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Overview
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                CBCL Limited (CBCL) and DHI Water and Environment Inc. (DHI)
                provide this website as a service and for informational purposes
                only.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                The transmission of this information does not create any form of
                relationship with us. You should not act upon information
                provided on this site without seeking our personal assistance.
                While we have attempted to maintain the information, the
                software and any other services on this site as accurately as
                possible, the site may contain errors or omissions for which we
                disclaim any and all liability. The material and contents posted
                on this site is provided without warranty of any kind.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We are not responsible for any loss of hardware, software or
                files, caused by the use of the web site or elements downloaded
                or used from/on the web site. Hence we make no warranty, that
                the web site or product or services provided on the web site,
                either by us or on our behalf will meet your requirements or
                will be uninterrupted, timely, secure or without errors, or that
                the website or the servers we use are free of viruses or bugs or
                are fully functional or accurate.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                User License
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Please feel free to browse the website. We grant you permission
                to view this website and to print or download material displayed
                on the website for your own personal, non-commercial use,
                provided that you agree to and accept without modification the
                notices, terms and conditions set forth in this agreement and
                provided that you keep intact all copyright, trademark and other
                proprietary notices. You may not however copy, reproduce,
                republish, upload, transmit or distribute in any way the
                contents of this website, including the text, images, audio and
                video for public or commercial purposes, without written
                permission from us. You are also prohibited from using any
                content of this website in a frame on any other internet site in
                any way.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Your use of this website constitutes your agreement and
                acceptance without modification of the notices, terms and
                conditions set forth herein. In addition as a condition of your
                use of this website, you represent and warrant to us that you
                will not use this website for any purpose that is unlawful,
                immoral or prohibited by these terms, conditions and notices.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Liability
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Your use of and browsing in this Site is at your own risk. We do
                not warrant that the software used for this Site, and the
                information, the on-line applications, or any other services
                provided by means of this Site are error-free, or that their use
                will be uninterrupted. We expressly disclaim all warranties
                related to the above-mentioned subject matter, including,
                without limitation, those of accuracy, condition,
                merchantability and fitness for particular purpose.
                Notwithstanding anything to the contrary in this Site, in no
                event shall we be liable for any loss of profits, revenues,
                indirect, special, incidental, consequential, or other similar
                damages arising out of or in connection with this Site or out of
                the use of any of the services proposed by means of this Site.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Updates
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We take great care in creating and maintaining this website, and
                in providing accurate and up-to-date content. However, the
                content of this website is subject to frequent modifications
                without prior notice. Therefore, we do not guarantee the correct
                and actual status of said content.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We reserve the unilateral right to update, modify, change and
                alter our Terms & Conditions, Copyright, and Privacy Policy at
                any time. All such updates, modifications, changes and
                alterations are binding on all users and browsers of our website
                and will be posted here.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Security
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We have implemented security measures to ensure that our
                internal procedures meet our high security policy standards. For
                example, we use encryption. Furthermore, we strive to ensure the
                ongoing confidentiality, integrity, availability and resilience
                when processing your personal data. In the event of physical or
                technical incidents, we make every effort to restore the
                availability and access to your personal data in a timely
                manner. We also have processes for assessing and evaluating the
                effectiveness in order to ensure that the security level is
                sufficient.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                For more information regarding the data that we collect and the
                measures we take to protect the privacy of our user data please
                refer to our Privary Policy.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Links to Other Sites
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 2rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Our website may contain links to other websites or to integrated
                sites. We are not responsible for the contents of the websites
                of other companies or for the practices of such companies
                regarding the collection of personal data. When you visit other
                websites, you should read the owners' policies on the protection
                of personal data and other relevant policies.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
