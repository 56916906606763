import {
  Box,
  Modal,
  Fade,
  Typography,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
  Avatar,
  IconButton,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ModalSearchbar } from "./ModalSearchBar";
import { ModalDropDownFields } from "./ModalDropDownFields";
import { convertBathymetryInterpolationUpload, getSubProjectList, uploadFiles } from "../../../api/backend";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { useDispatch, useSelector } from "react-redux";
import { changeBathymetryInterpolationStatus } from "../../../features/ToolboxBathymetryInterpolationImportSlice";
import { RootState } from "../../../store/store";
import { toolControls } from "../../Openlayers/data_layers";
import { CircularProgress } from "@mui/material";
import { getProjectMetadata, getAllBathymetryDatasets, getMetadata } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

interface DataUploadModal {
  open: boolean;
  handleClose: () => void;
  type: string;
}

interface dataProps {
  datasetType: string;
  id: string;
  name: string;
  projectId: string;
  relativePath: string;
}
const TOTAL_SIZE_LIMIT = 2 * 1024 * 1024 * 1024;
function convert(str: string) {
  var date = new Date(str);
  return `${date.getDate()} ${new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(date)} ${date.getFullYear()}`;
}

export const ImportFromDataRepositoryModal = ({ open, handleClose, type }: DataUploadModal) => {
  const [fileMetadatas, setFileMetadatas] = useState(new Map());
  const dispatch = useDispatch();
  const { isAuthenticated } = useOidc();
  const [processedFiles, setProcessedFiles] = useState<any[]>([]);
  const { idTokenPayload } = useOidcIdToken();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const bathymetryInterpolationImportStatus = useSelector(
    (state: RootState) => state.bathymetryInterpolationImport.isImportCompleted
  );
  const rights = useSelector((state: RootState) => state.toolboxBathymetryRights.value);
  const [username, setUsername] = useState("");
  const [searchbarValue, setSearchbarValue] = useState("");
  const [timeseriesDataType, setTimeseriesDataType] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { data: userNames } = useSelector((state: RootState) => state.userNames);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const getUsername = async (id: string) => {
    try {
      const metadata = await getProjectMetadata(id);
      const createdBy = metadata.createdBy;
      const lastUpdated = metadata.updatedAt;
      const user = userNames.find((user: any) => user.id === createdBy);
      return { name: user ? user.name : "", lastUpdated: lastUpdated };
    } catch (error) {
      return { name: "", lastUpdated: "" };
    }
  };

  const handleCheckboxChange = (file: any, isChecked: boolean) => {
    if (type === "timeseries") {
      if (isChecked) {
        setSelectedFiles([file]);
      } else {
        setSelectedFiles([]);
      }
    } else {
      if (isChecked) {
        setSelectedFiles((prev) => [...prev, file]);
      } else {
        setSelectedFiles((prev) => prev.filter((f) => f.id !== file.id));
      }
    }
  };

  const handleImport = async () => {
    let uploadSnackbarKey: any = null;
    handleClose();
    uploadSnackbarKey = enqueueSnackbar("Importing Dataset...", {
      variant: "info",
      persist: true,
      action: (key) => (
        <>
          <CircularProgress size={24} sx={{ color: "white" }} />
          <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ),
    });
    for (const file of selectedFiles) {
      try {
        const response = await fetch(file.datasetUrl);
        if (!response.ok) {
          throw new Error(`Error fetching file: ${response.statusText}`);
        }
        const content = await response.blob();
        await uploadToCloud(file, content);
      } catch (error) {
        console.error("Error downloading file", error);
      }
    }
    dispatch(changeBathymetryInterpolationStatus(!bathymetryInterpolationImportStatus));
    setSelectedFiles([]);
    closeSnackbar(uploadSnackbarKey);
    enqueueSnackbar("Import successful!", {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  const uploadToCloud = async (file: any, content: any) => {
    const attachments = new FormData();
    attachments.append("files", content, file);
    const folder_id =
      type === "bathymetry"
        ? publicDataSets.PrivateTemporaryBathymetry.id
        : publicDataSets.PrivateTemporaryTimeseries.id;
    const subprojectlist = await getSubProjectList(folder_id);
    const hasPersonalFolder = await subprojectlist.data.filter((p: any) => p.name === idTokenPayload.oid);

    const uploadResponse = await uploadFiles(attachments);
    const convert = await convertBathymetryInterpolationUpload(
      file.name,
      uploadResponse.data,
      hasPersonalFolder[0].id,
      file,
      file.id,
      file.projectId
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchCloudData = async () => {
      try {
        let combinedData: any[] = [];

        const result1 = await getAllBathymetryDatasets(publicDataSets.PublicUserUpload.id);

        if (result1.data.length > 0) {
          const filteredData1 = result1.data.map((dataset: dataProps) => ({ dataset }));
          combinedData = [...combinedData, ...filteredData1];
        }

        if (isAuthenticated) {
          const result2 = await getAllBathymetryDatasets(publicDataSets.PrivateUserUpload.id);
          if (result2.data.length > 0) {
            const filteredData2 = result2.data
              .filter((dataset: dataProps) => dataset.relativePath === `${idTokenPayload.oid}/`)
              .map((dataset: dataProps) => ({ dataset }));
            combinedData = [...combinedData, ...filteredData2];
          }
        }

        if (combinedData.length > 0) {
          await fetchMetadataForFiles(combinedData);

          const updatedProcessedFiles = await Promise.all(
            combinedData.map(async (file) => {
              const result = await getUsername(file.dataset.projectId);
              return {
                ...file.dataset,
                ...fileMetadatas.get(file.dataset.id)?.metadata,
                fileDescription: fileMetadatas.get(file.dataset.id)?.description,
                userName: result.name,
                lastUpdated: result.lastUpdated,
                id: file.dataset.id,
              };
            })
          );

          setProcessedFiles(updatedProcessedFiles);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching cloud data:", error);
      }
    };

    fetchCloudData();
  }, [isAuthenticated, idTokenPayload]);

  const fetchMetadataForFiles = (files: any) => {
    return Promise.all(
      files.map(async (file: any) => {
        const metadata = await getMetadata(file.dataset.id);
        fileMetadatas.set(file.dataset.id, metadata);
      })
    );
  };
  const handleModalClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "40rem",
            width: {
              xs: "90%",
              sm: "75%",
              md: "60%",
              lg: "74rem",
            },
          }}
        >
          <Box m="1rem 2rem 1rem 2rem" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem">
              Import from Data Repository
            </Typography>
            <ModalSearchbar searchbarValue={searchbarValue} setSearchbarValue={setSearchbarValue} />
          </Box>
          <ModalDropDownFields
            username={username}
            setUsername={setUsername}
            type={type}
            timeseriesDataType={timeseriesDataType}
            setTimeseriesDataType={setTimeseriesDataType}
          />
          <Divider />
          <Box m="1rem" display="grid" gridTemplateColumns="2fr 1fr 1fr 1fr">
            <Typography variant="body2" color="secondary.dark" fontWeight="700">
              Data Name
            </Typography>
            <Typography variant="body2" color="secondary.dark" fontWeight="700">
              Rights
            </Typography>
            <Typography variant="body2" color="secondary.dark" fontWeight="700">
              Last Updated
            </Typography>
            <Typography variant="body2" color="secondary.dark" fontWeight="700">
              All Users
            </Typography>
          </Box>
          <Divider />
          <Box height="23rem" sx={{ overflowY: "auto" }}>
            {isLoading ? (
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="23rem">
                <CircularProgress />
                <Typography mt={2} variant="body1" color="secondary.dark">
                  Importing files from data repository
                </Typography>
              </Box>
            ) : (
              processedFiles
                .filter((file) => {
                  const tableElementData = {
                    ...file,
                    fileDescription: fileMetadatas.get(file.id)?.description,
                    id: file.id,
                  };
                  const requiredMetadataFields = [
                    "fileCRS",
                    "fileDataFormat",
                    "fileDataType",
                    "fileDatum",
                    "fileXCoordinate",
                    "fileYCoordinate",
                  ];
                  const isMetadataComplete = requiredMetadataFields.every((field) => field in tableElementData);
                  const isDataTypeBathymetry =
                    tableElementData.fileDataType === "Bathymetry" && tableElementData.fileDataFormat === "GisVector";
                  const isConverted = tableElementData.name.includes("(converted)");
                  const isDataTypeTimeseries =
                    tableElementData.fileDataType === "Currents (Timeseries)" ||
                    tableElementData.fileDataType === "Discharge (Timeseries)" ||
                    tableElementData.fileDataType === "Ice Thickness (Timeseries)" ||
                    tableElementData.fileDataType === "Water Level (Timeseries)" ||
                    tableElementData.fileDataType === "Waves (Timeseries)" ||
                    tableElementData.fileDataType === "Winds (Timeseries)";
                  const isUserNameAvailable = tableElementData.userName && tableElementData.userName.trim() !== "";
                  const isUserNameMatch = username
                    ? tableElementData.userName.toLowerCase().includes(username.toLowerCase())
                    : true;
                  const isSearchbarValueMatch = searchbarValue
                    ? tableElementData.name.toLowerCase().includes(searchbarValue.toLowerCase())
                    : true;
                  const isRightsMatch = rights.length === 0 || rights.includes(tableElementData.rights);
                  const isDataTypeMatch =
                    timeseriesDataType.length === 0 || timeseriesDataType.includes(tableElementData.fileDataType);
                  return type === "bathymetry"
                    ? isMetadataComplete &&
                        isRightsMatch &&
                        isUserNameAvailable &&
                        isUserNameMatch &&
                        isSearchbarValueMatch &&
                        isDataTypeBathymetry
                    : isMetadataComplete &&
                        isRightsMatch &&
                        isUserNameAvailable &&
                        isUserNameMatch &&
                        isSearchbarValueMatch &&
                        isDataTypeTimeseries &&
                        isDataTypeMatch &&
                        isConverted;
                })
                .map((file, index) => {
                  const tableElementData = {
                    ...file,
                    fileDescription: fileMetadatas.get(file.id)?.description,
                    id: file.id,
                  };
                  const disableCheckbox =
                    type === "timeseries" && selectedFiles.length > 0 && !selectedFiles.some((f) => f.id === file.id);
                  return (
                    <React.Fragment key={index}>
                      <Box ml="1rem" mb="0.25rem" display="grid" gridTemplateColumns="2fr 1fr 1fr 1fr">
                        <Box display="flex" flexDirection="row" gap="-1rem">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => handleCheckboxChange(tableElementData, e.target.checked)}
                                disabled={disableCheckbox}
                              />
                            }
                            label={""}
                          />
                          <Avatar
                            sx={{
                              width: 32,
                              height: 32,
                              border: "none",
                              backgroundColor: "transparent",
                              mt: "0.25rem",
                              ml: "-0.5rem",
                            }}
                          >
                            <img
                              src={
                                tableElementData.fileDataType === "Bathymetry"
                                  ? "icons/Bathymetry.svg"
                                  : tableElementData.fileDataType === "Currents (Timeseries)"
                                  ? "icons/Currents.svg"
                                  : tableElementData.fileDataType === "Discharge (Timeseries)"
                                  ? "icons/Discharge.svg"
                                  : tableElementData.fileDataType === "Ice Thickness (Timeseries)"
                                  ? "icons/Ice.svg"
                                  : tableElementData.fileDataType === "Water Level (Timeseries)"
                                  ? "icons/WaterLevel.svg"
                                  : tableElementData.fileDataType === "Waves (Timeseries)"
                                  ? "icons/Waves.svg"
                                  : "icons/Winds.svg"
                              }
                              alt={toolControls.BATHYMETRY}
                            />
                          </Avatar>
                          <Typography ml="0.5rem" variant="body2" color="secondary.dark" mt="0.75rem">
                            {tableElementData.name}
                          </Typography>
                        </Box>

                        <Typography variant="body2" color="secondary.dark" mt="0.75rem">
                          {tableElementData.rights}
                        </Typography>
                        <Typography variant="body2" color="secondary.dark" mt="0.75rem">
                          {tableElementData.updatedAt ? convert(tableElementData.updatedAt?.toLocaleString()) : "N/A"}
                        </Typography>
                        <Typography variant="body2" color="secondary.dark" mt="0.75rem">
                          {tableElementData.userName}
                        </Typography>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  );
                })
            )}
          </Box>
          <Box mt="1rem" mr="1rem" justifyContent="end" display="flex" gap="0.5rem">
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleImport} disabled={selectedFiles.length === 0}>
              Import
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
