import { Box, SelectChangeEvent, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { useEffect, Dispatch, SetStateAction } from "react";
import { changeFileDescription } from "../../../../features/DataRepoDescriptionSlice";
import { changeFileName } from "../../../../features/DataRepoFileNameSlice";
import UploadDropdownSelect from "../UploadDropDownSelect";
import { changeFileDataFormat } from "../../../../features/DataRepoDataFormatSlice";
import { changeFileDataType } from "../../../../features/DataRepoFileDataTypeSlice";
import { changeFileCRS } from "../../../../features/DataRepoCRSSlice";
import { changeFileDatum } from "../../../../features/DataRepoDatumSlice";
import { changeFileTimeZone } from "../../../../features/DataRepoTimeZoneSlice";
import { changeDataRepositoryXCoordinate } from "../../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../../features/DataRepositoryYCoordinateSlice";
import { changeDataRepoSurfaceElevation } from "../../../../features/DataRepoSurfaceElevationSlice";
import { toolControls } from "../../../Openlayers/data_layers";

interface validationProps {
  setLeftValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
  fileDataTypeError: boolean;
  setFileDataTypeError: Dispatch<SetStateAction<boolean>>;
  nameError: boolean;
  setNameError: Dispatch<SetStateAction<boolean>>;
  descriptionError: boolean;
  setDescriptionError: Dispatch<SetStateAction<boolean>>;
}

const fileDataTypeOptions = [
  { value: toolControls.BATHYMETRY, label: toolControls.BATHYMETRY },
  { value: toolControls.WAVESTIMESERIES, label: toolControls.WAVESTIMESERIES },
  { value: toolControls.WINDTIMESERIES, label: toolControls.WINDTIMESERIES },
  {
    value: toolControls.WATERLEVELTIMESEIRES,
    label: toolControls.WATERLEVELTIMESEIRES,
  },
  {
    value: toolControls.CURRENTSTIMESERIES,
    label: toolControls.CURRENTSTIMESERIES,
  },
  {
    value: toolControls.DISCHARGETIMESERIES,
    label: toolControls.DISCHARGETIMESERIES,
  },
  {
    value: toolControls.ICETHICKNESSTIMESERIES,
    label: toolControls.ICETHICKNESSTIMESERIES,
  },
  { value: toolControls.BOREHOLELOGS, label: toolControls.BOREHOLELOGS, disabled: true },
  {
    value: toolControls.MODELRESULTSWAVE,
    label: toolControls.MODELRESULTSWAVE,
    disabled: true,
  },
  {
    value: toolControls.INFRASTRUCTUREDRAWING,
    label: toolControls.INFRASTRUCTUREDRAWING,
    disabled: true,
  },
  { value: toolControls.MISCFREEFORMAT, label: toolControls.MISCFREEFORMAT },
  { value: toolControls.MODELGRIDMESH, label: toolControls.MODELGRIDMESH, disabled: true },
  {
    value: toolControls.MODELRESULTSHYDRO,
    label: toolControls.MODELRESULTSHYDRO,
    disabled: true,
  },
];

export const ConfigurationDetailsLeft = ({
  setLeftValidate,
  nextClicked,
  fileDataTypeError,
  setFileDataTypeError,
  setNextClicked,
  nameError,
  setNameError,
  descriptionError,
  setDescriptionError,
}: validationProps) => {
  const dispatch = useDispatch();
  const name = useSelector((state: RootState) => state.fileName.value);
  const description = useSelector((state: RootState) => state.fileDescription.value);
  const fileType = useSelector((state: RootState) => state.fileDataType.value);

  // const [nameError, setNameError] = useState(false);
  // const [descriptionError, setDescriptionError] = useState(false);

  const handlefileTypeChange = (event: SelectChangeEvent) => {
    dispatch(changeFileDataType({ value: event.target.value as string }));
    setFileDataTypeError(!event.target.value);
    dispatch(changeFileDataFormat({ value: "" }));
    dispatch(changeFileCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
    dispatch(changeFileDatum({ value: "" }));
    dispatch(changeFileTimeZone({ value: "" }));
    dispatch(changeDataRepositoryXCoordinate({ value: null }));
    dispatch(changeDataRepositoryYCoordinate({ value: null }));
    dispatch(changeDataRepoSurfaceElevation({ value: null }));
    setNextClicked(false);
  };

  const validateFields = () => {
    const nameError = name.trim() === "";
    const descriptionError = description.trim() === "";

    setNameError(nameError);
    setDescriptionError(descriptionError);

    return nameError || descriptionError;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFileName({ value: e.target.value }));
    setNameError(e.target.value === "");
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFileDescription({ value: e.target.value }));
    setDescriptionError(e.target.value === "");
  };

  useEffect(() => {
    setLeftValidate(() => validateFields);
  }, [name, description]);

  return (
    <Box>
      <TextField
        label="Name"
        error={nameError}
        variant="outlined"
        size="small"
        value={name}
        onChange={handleNameChange}
        InputLabelProps={{
          style: {
            color: "#86A2B3",
          },
        }}
        sx={{
          mt: "1rem",
          ml: "1rem",
          width: "20rem",
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
        }}
      />
      <Box width="20rem" mt="0.75rem" ml="1rem">
        <UploadDropdownSelect
          label="Data Type"
          value={fileType}
          onChange={handlefileTypeChange}
          options={fileDataTypeOptions}
          error={nextClicked && fileDataTypeError}
        />
      </Box>
      <TextField
        error={descriptionError}
        multiline={true}
        label="Description"
        variant="outlined"
        size="small"
        value={description}
        InputLabelProps={{
          style: {
            color: "#86A2B3",
          },
        }}
        onChange={handleDescriptionChange}
        sx={{
          mt: "1rem",
          ml: "1rem",
          width: "20rem",
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
        }}
        inputProps={{
          style: {
            height: "7rem",
          },
        }}
      />
    </Box>
  );
};
