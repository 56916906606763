import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { WindTabInputField } from "./WindTabInputField";
import { WindTabTable } from "./WindTabTable";
import { WindTabWaveRoseChart } from "./WindTabWaveRoseChart";
import { WindTabEVDChart } from "./WindTabEVDChart";
import { WindTimeSeriesChart } from "./WindTimeSeriesChart";
import { DashboardTabsHeadWaves } from "../DashboardWavesTabs/DashboardTabsHeadWaves";
import { publicDataSets } from "../../../api/init";
import {
  getGisVectorData,
  getRawData,
  getTimeSeriesGisQueryFeatures,
  getTimeSeriesGisRaw,
  getTimeSeriesGisValues,
} from "../../../api/backend_public";
import { Feature } from "ol";
import Papa from "papaparse";

interface portProps {
  port: Feature;
}

export const DashboardWindTab = ({ port }: portProps) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [directionValues, setDirectionValues] = useState<string[]>([]);
  const [dataSourceValue, setDataSourceValue] = useState("MSC50");
  const [returnPeriodValue, setReturnPeriodValue] = useState<string[]>([]);
  const [file, setFiles] = useState<File[]>([]);
  const [rawTs, setRawTs] = useState<any[]>([]);
  const [evaData, setEvaData] = useState<unknown[]>([]);
  const [jotHsData, setJotHsData] = useState<unknown[]>([]);

  const [selectedFature, setSeletectFeature] = useState<Feature | null>(null);
  const [hhwlt, sethhwlt] = useState<any>();

  const getHHWLTValue = async () => {
    const hhwltData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const hhwltValue = hhwltData.features.filter(
      (item: any) => item.properties.Harbour_nu === port.get("Harbour_nu")
    )[0].properties;
    return hhwltValue;
  };

  useEffect(() => {
    setJotHsData([]);
    setRawTs([]);
    setFiles([]);
    setCsvData([]);
    setEvaData([]);
    setSeletectFeature(null);
  }, [dataSourceValue]);

  useEffect(() => {
    if (dataSourceValue === "MSC50" || dataSourceValue === "WIS") {
      const filtered = csvData.filter(
        (row) => directionValues.includes(row.Direction) && returnPeriodValue.includes(row.AEP)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setEvaData([]);
    }
  }, [csvData, directionValues, returnPeriodValue, dataSourceValue]);

  useEffect(() => {
    (async () => {
      const portProperties = await getHHWLTValue();
      if (!portProperties) return;

      sethhwlt(portProperties);
    })();
    return () => {
      sethhwlt(null);
    };
  }, [port]);

  useEffect(() => {
    let active = true;
    const fetchData = async () => {
      if (port?.get("WS_selec") === "" || dataSourceValue !== "Weather Stations") return;

      const { features } = await getTimeSeriesGisQueryFeatures(
        publicDataSets.WeatherStationLocations10e.id,
        port.get("WS_selec"),
        "Climate ID"
      );

      if (!features || !active) return;
      setSeletectFeature(features[0]);

      const rawJOThs = await getTimeSeriesGisRaw(features[0].properties.jot_WS_WD);
      const rawJOThsData = Papa.parse(rawJOThs, { header: true });
      if (!active || dataSourceValue !== "Weather Stations") return;
      setJotHsData(rawJOThsData.data);
      const jotHSBlob = new Blob([rawJOThs], { type: "text/csv" });
      const jotHSFile = new File([jotHSBlob], "M3010247_jot_hs.csv");
      setFiles((prevFiles) => [...prevFiles, jotHSFile]);
      const endingSyntax =
        publicDataSets.env === "dev" ? ".csv_WindSpeed_mps" : publicDataSets.env === "prod" ? "_WindSpeed_mps" : "";
      const rawTS = await getTimeSeriesGisValues(
        publicDataSets.WeatherStationTimeseries10d.id,
        features[0].properties["Climate ID"] + endingSyntax
      );

      if (!active || dataSourceValue !== "Weather Stations") return;

      setRawTs(rawTS.data);

      const tsBlob = new Blob([Papa.unparse(rawTS)], { type: "text/csv" });
      const tsFile = new File([tsBlob], "M3010247_ts.csv");
      setFiles((prevFiles) => [...prevFiles, tsFile]);
    };
    fetchData();
    return () => {
      active = false;
    };
  }, [dataSourceValue]);

  useEffect(() => {
    let active = true;
    const fetchData = async () => {
      if (port?.get("MSC_selec") === "" || dataSourceValue !== "MSC50") return;
      const { features } = await getTimeSeriesGisQueryFeatures(
        publicDataSets.MSC50Dataset7e.id,
        port.get("MSC_selec"),
        "point"
      );

      if (!features || !active) return;
      setSeletectFeature(features[0]);

      const datasetUrl = await getRawData(features[0].properties.eva);
      if (datasetUrl) {
        const response = await fetch(datasetUrl.data);
        const csvText = await response.text();
        const parsedData = Papa.parse(csvText, { header: true, skipEmptyLines: true });
        setCsvData(parsedData.data);
      }

      const rawEVA = await getTimeSeriesGisRaw(features[0].properties.eva);
      const rawEVAData = Papa.parse(rawEVA, { header: true });
      if (!active || dataSourceValue !== "MSC50") return;
      setEvaData(rawEVAData.data);
      const evaBlob = new Blob([rawEVA], { type: "text/csv" });
      const evaFile = new File([evaBlob], "M3010247_eva.csv");
      setFiles((prevFiles) => [...prevFiles, evaFile]);

      const rawJOThs = await getTimeSeriesGisRaw(features[0].properties.jot_HS_VMD);
      const rawJOThsData = Papa.parse(rawJOThs, { header: true });
      if (!active || dataSourceValue !== "MSC50") return;
      setJotHsData(rawJOThsData.data);
      const jotHSBlob = new Blob([rawJOThs], { type: "text/csv" });
      const jotHSFile = new File([jotHSBlob], "M3010247_jot_hs.csv");
      setFiles((prevFiles) => [...prevFiles, jotHSFile]);

      const rawTS = await getTimeSeriesGisValues(
        publicDataSets.MSC50Dataset7d.id,
        features[0].properties.point + "_WS"
      );

      if (!active || dataSourceValue !== "MSC50") return;

      setRawTs(rawTS.data);
      const tsBlob = new Blob([Papa.unparse(rawTS)], { type: "text/csv" });
      const tsFile = new File([tsBlob], "M3010247_ts.csv");
      setFiles((prevFiles) => [...prevFiles, tsFile]);
    };
    fetchData();
    return () => {
      active = false;
    };
  }, [dataSourceValue]);

  useEffect(() => {
    let active = true;

    const fetchData = async () => {
      if (port?.get("WIS_selec") === "" || dataSourceValue !== "WIS") return;

      const { features } = await getTimeSeriesGisQueryFeatures(
        publicDataSets.WISShapefile08e.id,
        port.get("WIS_selec"),
        "point"
      );

      if (!features || !active) return;
      setSeletectFeature(features[0]);

      const datasetUrl = await getRawData(features[0].properties.eva);
      if (datasetUrl) {
        const response = await fetch(datasetUrl.data);
        const csvText = await response.text();
        const parsedData = Papa.parse(csvText, { header: true, skipEmptyLines: true });
        setCsvData(parsedData.data);
      }

      const rawEVA = await getTimeSeriesGisRaw(features[0].properties.eva);
      const rawEVAData = Papa.parse(rawEVA, { header: true });
      if (!active || dataSourceValue !== "WIS") return;
      setEvaData(rawEVAData.data);
      const evaBlob = new Blob([rawEVA], { type: "text/csv" });
      const evaFile = new File([evaBlob], "M3010247_eva.csv");
      setFiles((prevFiles) => [...prevFiles, evaFile]);

      const rawJOThs = await getTimeSeriesGisRaw(features[0].properties.jot_WS_WD);
      const rawJOThsData = Papa.parse(rawJOThs, { header: true });
      if (!active || dataSourceValue !== "WIS") return;
      setJotHsData(rawJOThsData.data);
      const jotHSBlob = new Blob([rawJOThs], { type: "text/csv" });
      const jotHSFile = new File([jotHSBlob], "M3010247_jot_hs.csv");
      setFiles((prevFiles) => [...prevFiles, jotHSFile]);

      const rawTS = await getTimeSeriesGisValues(
        publicDataSets.WISTimeseries8d.id,
        features[0].properties["point"] + "_WS"
      );
      if (!active || dataSourceValue !== "WIS") return;
      setRawTs(rawTS.data);

      const tsBlob = new Blob([Papa.unparse(rawTS)], { type: "text/csv" });
      const tsFile = new File([tsBlob], "M3010247_ts.csv");
      setFiles((prevFiles) => [...prevFiles, tsFile]);
    };
    fetchData();
    return () => {
      active = false;
    };
  }, [dataSourceValue]);

  return (
    <Box>
      <DashboardTabsHeadWaves name="Winds" files={file} />

      <WindTabInputField
        directionValues={directionValues}
        setDirectionValues={setDirectionValues}
        setDataSourceValue={setDataSourceValue}
        dataSourceValue={dataSourceValue}
        returnPeriodValue={returnPeriodValue}
        setReturnPeriodValue={setReturnPeriodValue}
        setRawTs={setRawTs}
      />
      <Box
        bgcolor="grey.50"
        height="calc(100vh - 16.5rem)"
        sx={{ overflowY: "scroll", overflowX: "hidden", p: "1rem" }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Box>
            <WindTabTable
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              directionValues={directionValues}
              returnPeriodValue={returnPeriodValue}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box ml="-1rem">
              <WindTabEVDChart directionValues={directionValues} windData={evaData} />
            </Box>
            <Box m="-3rem 1rem 1rem -1rem">
              <WindTabWaveRoseChart windData={jotHsData} />
            </Box>
            <Box m="-1rem" mt="-2rem">
              <WindTimeSeriesChart port={selectedFature} dataSourceValue={dataSourceValue} rawTs={rawTs} />
            </Box>
          </Box>
        </Box>
        <Typography color="grey.500" variant="body2" mb="0.5rem" mt="1rem">
          Warnings:
        </Typography>
        {hhwlt && dataSourceValue === "MSC50" && hhwlt.MSC_dstkm !== 0 ? (
          <Typography color="grey.500" variant="caption" mt="1rem">
            {`The closest MSC50 point, ${hhwlt.MSC_selec}, (latitude ${hhwlt.MSC_lat}, longitude ${
              hhwlt.MSC_lon
            }), is located  ${parseFloat(hhwlt.MSC_dstkm).toFixed(1)} km from the ${port.get(
              "Harbour_Na"
            )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
          </Typography>
        ) : hhwlt && dataSourceValue === "WIS" && hhwlt.WIS_dstkm !== 0 ? (
          <Typography color="grey.500" variant="caption" mt="1rem">
            {`The closest WIS point, ${hhwlt.WIS_selec}, (latitude ${hhwlt.WIS_lat.toFixed(
              1
            )}, longitude ${hhwlt.WIS_lon.toFixed(1)}), is located  ${parseFloat(hhwlt.WIS_dstkm).toFixed(
              1
            )} km from the ${port.get(
              "Harbour_Na"
            )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
          </Typography>
        ) : hhwlt && dataSourceValue === "Weather Stations" && hhwlt.WS_dstkm !== 0 ? (
          <Typography color="grey.500" variant="caption" mt="1rem">
            {`The closest weather station, ${hhwlt.WS_selec}, (latitude ${hhwlt.WS_lat.toFixed(
              1
            )}, longitude ${hhwlt.WS_lon.toFixed(1)}), is located  ${parseFloat(hhwlt.WS_dstkm).toFixed(
              1
            )} km from the ${port.get(
              "Harbour_Na"
            )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
          </Typography>
        ) : (
          <Typography color="grey.500" variant="caption" mt="1rem">
            {`There are no ${dataSourceValue} points located within 10 km of ${port.get("Harbour_Na")}.`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
