import { Box, Typography, Divider } from "@mui/material";
import Papa from "papaparse";
import { WaterLevelDetailsWithCaption } from "./WaterLevelDetailsWithCaption";
import { getAllBathymetryDatasets, getGisVectorData, getRawData } from "../../../api/backend_public";
import { useSearchParams } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import JSZip from "jszip";
import { publicDataSets } from "../../../api/init";

interface DataProps {
  assetElevation: number | null;
  bedElevation: number | null;
  seaLevelSCValue: string;
  seaLevelHOValue: string;
  seaLevelModelValue: string;
  waterLevelAEPValue: string;
  HATValue: any;
  setHATValue: Dispatch<SetStateAction<any>>;
  EWLValue: string;
  setEWLValue: Dispatch<SetStateAction<any>>;
  slrValue: string;
  setSlrValue: Dispatch<SetStateAction<string>>;
  hat: any;
  setHat: Dispatch<SetStateAction<any>>;
}

interface DataRow {
  [key: string]: string;
}
interface ParsedDataRow {
  AEP: string;
  bl_p05: string;
  bl_p50: string;
  bl_p95: string;
}

const yearIndexMapping: { [key: string]: number } = {
  2020: 0,
  2030: 1,
  2040: 2,
  2050: 3,
  2060: 4,
  2070: 5,
  2080: 6,
  2090: 7,
  2100: 8,
};

export const DashboardWaterLevelTable = ({
  seaLevelHOValue,
  seaLevelSCValue,
  seaLevelModelValue,
  waterLevelAEPValue,
  HATValue,
  setHATValue,
  EWLValue,
  setEWLValue,
  slrValue,
  setSlrValue,
  hat,
  setHat,
}: DataProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const port = searchParams.get("port");

  const getHATValue = async () => {
    const hatData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const hatValue = hatData.features.filter((item: any) => item.properties?.Harbour_nu === Number(port))[0].properties;
    return hatValue;
  };

  const getcgvd2013Value = async (hat: any) => {
    if (!hat) return undefined;

    const thselecValue = hat.TH_selec;
    const result = await getAllBathymetryDatasets(publicDataSets.TidalHeights.id);
    const files = await fetch(result.data[0].datasetUrl);
    const arrayBuffer = await files.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
    const csvFile = Object.keys(zip.files).find((file) => file.includes(thselecValue) && file.endsWith(".csv"));
    if (csvFile) {
      const fileContent = await zip.files[csvFile].async("string");
      const parsedData = Papa.parse(fileContent, { header: true });
      const hats = (parsedData.data.find((row: any) => row.heighttype === "HAT") as any)?.wl_CGVD2013;
      const hhwlt = (parsedData.data.find((row: any) => row.heighttype === "HHWLT") as any)?.wl_CGVD2013;
      const hhwmt = (parsedData.data.find((row: any) => row.heighttype === "HHWMT") as any)?.wl_CGVD2013;
      const mwl = (parsedData.data.find((row: any) => row.heighttype === "MWL") as any)?.wl_CGVD2013;
      const llwmt = (parsedData.data.find((row: any) => row.heighttype === "LLWMT") as any)?.wl_CGVD2013;
      const llwlt = (parsedData.data.find((row: any) => row.heighttype === "LLWLT") as any)?.wl_CGVD2013;
      return [hats, hhwlt, hhwmt, mwl, llwmt, llwlt];
    } else {
      console.error("No matching CSV file found in the ZIP archive.");
    }

    return undefined;
  };

  const getEWLValue = async () => {
    const aepMapping: { [key: string]: string } = {
      "100% AEP": "1.0",
      "50% AEP": "0.5",
      "20% AEP": "0.2",
      "10% AEP": "0.1",
      "5.0% AEP": "0.05",
      "2.0% AEP": "0.02",
      "1.0% AEP": "0.01",
      "0.5% AEP": "0.005",
    };

    const gisData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const wl_selec = gisData.features.find((item: any) => item.properties.Harbour_nu === Number(port)).properties
      .WL_selec;
    const features = await getAllBathymetryDatasets(publicDataSets.TideGaugeEWL.id);
    const csvDataset = features.data.find(
      (item: any) => item.name.split("_")[0] === wl_selec.toString().padStart(5, "0")
    );
    if (csvDataset === undefined) {
      return undefined;
    }
    const csvResponse = await fetch(csvDataset.datasetUrl);
    const csvText = await csvResponse.text();
    const parsedData: ParsedDataRow[] = Papa.parse(csvText, { header: true }).data as ParsedDataRow[];

    const aepValue = aepMapping[waterLevelAEPValue];

    const matchedRow = parsedData.find((row: any) => row.AEP === aepValue);
    if (!matchedRow) {
      console.error("No matching AEP value found");
      return undefined;
    }
    return matchedRow.bl_p50;
  };

  useEffect(() => {
    const fetchData = async () => {
      const hatResult = await getHATValue();
      if (hatResult) {
        setHat(hatResult);
        const cgvd2013Result = await getcgvd2013Value(hatResult);
        if (cgvd2013Result) {
          setHATValue(cgvd2013Result);
        } else {
          console.error("CGVD2013 result is undefined.");
        }
      } else {
        console.error("HAT result is undefined.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const ewlResult = await getEWLValue();
      setEWLValue(ewlResult);
    };
    fetchData();
  }, [waterLevelAEPValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (seaLevelModelValue === "CAN-EWLAT (Allowance)") {
        let response;
        if (seaLevelSCValue === "RCP 2.6 (mean)") {
          response = await getRawData(publicDataSets.CanEwalt.Rpc26.id);
        } else if (seaLevelSCValue === "RCP 4.5 (mean)") {
          response = await getRawData(publicDataSets.CanEwalt.Rpc45.id);
        } else if (seaLevelSCValue === "RCP 8.5 (mean)") {
          response = await getRawData(publicDataSets.CanEwalt.Rpc85.id);
        }
        const csvResponse = await fetch(response.data);
        const csvText = await csvResponse.text();
        const parsedData = Papa.parse(csvText, { header: true });
        const index = yearIndexMapping[seaLevelHOValue];
        if (index !== undefined && parsedData.data.length > index) {
          const dataRow = parsedData.data[index] as DataRow;
          const safePort = port ?? "defaultPortValue";
          const portValue = dataRow[safePort.toString()];
          setSlrValue(portValue);
        }
      } else if (seaLevelModelValue === "NASA (AR6)") {
        let response;
        if (seaLevelSCValue === "SSP2-4.5 (p05)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp245p05.id);
        } else if (seaLevelSCValue === "SSP2-4.5 (p10)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp245p10.id);
        } else if (seaLevelSCValue === "SSP2-4.5 (p50)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp245p50.id);
        } else if (seaLevelSCValue === "SSP2-4.5 (p90)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp245p90.id);
        } else if (seaLevelSCValue === "SSP2-4.5 (p95)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp245p95.id);
        } else if (seaLevelSCValue === "SSP5-8.5 (p05)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp585p05.id);
        } else if (seaLevelSCValue === "SSP5-8.5 (p10)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp585p10.id);
        } else if (seaLevelSCValue === "SSP5-8.5 (p50)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp585p50.id);
        } else if (seaLevelSCValue === "SSP5-8.5 (p90)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp585p90.id);
        } else if (seaLevelSCValue === "SSP5-8.5 (p95)") {
          response = await getRawData(publicDataSets.NasaAr6.Ssp585p95.id);
        }
        const csvResponse = await fetch(response.data);
        const csvText = await csvResponse.text();
        const parsedData = Papa.parse(csvText, { header: true });
        const index = yearIndexMapping[seaLevelHOValue];
        if (index !== undefined && parsedData.data.length > index) {
          const dataRow = parsedData.data[index] as DataRow;
          const safePort = port ?? "defaultPortValue";
          const portValue = dataRow[safePort.toString()];
          setSlrValue(portValue);
        }
      }
    };
    fetchData();
  }, [seaLevelHOValue, seaLevelSCValue]);

  return (
    <Box
      m="1rem 1.5rem"
      sx={{
        border: "1px solid #CFDBE2",
        bgcolor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
      }}
    >
      <WaterLevelDetailsWithCaption
        name="Total Still Water Level (TWL)"
        caption=" (EWL + SLR)"
        value={
          EWLValue === undefined || slrValue === undefined
            ? "---"
            : (Number(EWLValue !== "N/A" ? EWLValue : "0") + Number(slrValue !== "N/A" ? slrValue : 0)).toFixed(2)
        }
        unit="m, CGVD2013"
        title="Total water level based on combined projected sea level rise and extreme water level from tidal analysis."
      />

      <Divider />
      <WaterLevelDetailsWithCaption
        name="Relative Sea Level Rise (SLR)"
        caption={seaLevelModelValue}
        value={slrValue === "" ? "Loading..." : slrValue === undefined ? "---" : Number(slrValue).toFixed(2)}
        unit="m"
        title="Projected sea level rise relative to land by specified year for the selected emission scenario."
      />

      <Divider />
      <WaterLevelDetailsWithCaption
        name="Extreme Water Level (EWL)"
        caption="DFO-CHS Tide Gauges"
        value={EWLValue === "" ? "Loading..." : EWLValue === undefined ? "---" : Number(EWLValue).toFixed(2)}
        unit="m"
        title="Extreme water level analysis completed from nearby, relevant tidal gauge measurements."
      />
      <Divider />
      <WaterLevelDetailsWithCaption
        name="Highest Astronomical Tide (HAT)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[0]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The elevation of the highest predicted astronomical tide expected to occur."
      />
      <Divider />
      <WaterLevelDetailsWithCaption
        name="Higher High Water Larger Tide (HHWLT)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[1]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The average of the highest high waters, 1 from each of 19 years of predictions."
      />
      <Divider />
      <WaterLevelDetailsWithCaption
        name="Higher High Water Mean Tide (HHWMT)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[2]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The average of the highest high waters from 19 years of predictions."
      />
      <Divider />

      <WaterLevelDetailsWithCaption
        name="Mean Water Level (MWL)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[3]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The average of all hourly water levels over the available period of record."
      />
      <Divider />
      <WaterLevelDetailsWithCaption
        name="Lower Low Water Mean Tide (LLWMT)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[4]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The average of all the lower low waters from 19 years of predictions."
      />
      <Divider />

      <WaterLevelDetailsWithCaption
        name="Lower Low Water Large Tide (LLWLT)"
        caption="DFO-CHS Tide Tables"
        value={HATValue ? Number(HATValue[5]).toFixed(2) : "Loading..."}
        unit="m, CGVD2013"
        title="The average of the lowest low waters, 1 from each of 19 years of predictions."
      />
      <Divider />
    </Box>
  );
};
