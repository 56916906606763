import { AppWrapper, Header, Main, Aside, Article, ToggleAside } from "../assets/layout_system";
import Openlayers from "../components/Openlayers/Openlayers";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Navigation, { AppPages } from "../components/Navigation/Navigation";
import React, { useEffect, useRef } from "react";
import { Feature, Map } from "ol";
import { useLocation } from "react-router-dom";
import DashboardPanel from "../components/panels/DashboardPanel";
import RepositoryPanel from "../components/panels/RepositoryPanel";
import { OpenlayersContext } from "../components/Openlayers/OpenlayersContext";
import ToolboxPanel from "../components/panels/ToolboxPanel";
import {
  getMultiTileLayers,
  getTileLayers,
  getTileLayersFromProject,
  initBorders,
  initHarbours,
} from "../components/Openlayers/data_layers";
import {
  BathymetryGroup,
  BathymetryGroup10m,
  IceCoverGroupA,
  IceCoverGroupB,
  SeaLevelRiseGroupA,
  SeaLevelRiseGroupB,
  WaterLevelGroup,
  WaveGroup,
  WindGroup,
  drawLayer,
} from "../components/Openlayers/default_groups_layers";
import { DataRepoFileProvider } from "../components/DataRepositoryComponents/DataUploadComponents/DataRepoFileContext/DataRepoFileContext";
import { FileProvider } from "../components/ToolboxComponents/ToolboxContext/FileContext";
import { ProcessedFilesProvider } from "../components/ToolboxComponents/UploadData/ProcessedFilesContext";
import { SeasonalFileProvider } from "../components/ToolboxComponents/ToolBoxSeasonalStatistics/SeasonalFileContext";
import { SeasonalFilesProvider } from "../components/ToolboxComponents/UploadSeasonalData/SeasonalFilesContext";
import { publicDataSets } from "../api/init";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { fetchUserNames } from "../features/UserNamesSlice";

function DefaultPage() {
  const location = useLocation();
  const mapInstance = useRef<Map | null>(null);
  const [harbours, setHarbours] = React.useState<Feature[] | null>(null);
  const [wmtsLayers, setWmtsLayers] = React.useState<any[]>([]);
  const [visibleSidePanel, setVisibleSidePanel] = React.useState<boolean>(false);
  const { data: userNames, status } = useSelector((state: RootState) => state.userNames);
  const dispatches = useDispatch<AppDispatch>();
  const toggleSidePanel = () => {
    setVisibleSidePanel((prev) => !prev);
  };
  useEffect(() => {
    if (status === "idle") {
      dispatches(fetchUserNames());
    }
  }, [status, dispatches]);

  useEffect(() => {
    (async () => {
      setHarbours((await initHarbours(mapInstance)) as Feature[]);
      await initBorders(mapInstance);

      await getTileLayers(mapInstance, publicDataSets.Bathymetry.NonnaMerged.project, BathymetryGroup);
      await getMultiTileLayers(mapInstance, publicDataSets.Wind.project, WindGroup, false);
      await getTileLayersFromProject(mapInstance, publicDataSets.WaveHeight.project, WaveGroup);
      await getMultiTileLayers(mapInstance, publicDataSets.SeaLevelRise.projectA, SeaLevelRiseGroupA);
      await getMultiTileLayers(mapInstance, publicDataSets.SeaLevelRise.projectB, SeaLevelRiseGroupB, false);
      await getMultiTileLayers(mapInstance, publicDataSets.WaterLevel.project, WaterLevelGroup);
      await getMultiTileLayers(mapInstance, publicDataSets.IceCover.projectA, IceCoverGroupA, false);
      await getMultiTileLayers(mapInstance, publicDataSets.IceCover.projectB, IceCoverGroupB, false);
      await getTileLayersFromProject(mapInstance, publicDataSets.Bathymetry.Nonna.project, BathymetryGroup10m);
    })();

    return () => {
      drawLayer.getSource()?.clear();
      setHarbours(null);
    };
  }, []);

  return (
    <OpenlayersContext.Provider value={mapInstance}>
      <AppWrapper>
        <Header>
          <Navigation />
        </Header>
        <Main className={!visibleSidePanel ? "visibleSidePanel" : ""}>
          <Aside>
            {location.pathname === AppPages.DASHBOARD && harbours !== null && <DashboardPanel features={harbours} />}

            {location.pathname === AppPages.REPOSETORY && (
              <DataRepoFileProvider>
                <RepositoryPanel wmtsLayers={wmtsLayers} />
              </DataRepoFileProvider>
            )}

            {location.pathname === AppPages.TOOLBOX && (
              <ProcessedFilesProvider>
                <SeasonalFileProvider>
                  <SeasonalFilesProvider>
                    <FileProvider>
                      <ToolboxPanel />
                    </FileProvider>
                  </SeasonalFilesProvider>
                </SeasonalFileProvider>
              </ProcessedFilesProvider>
            )}
          </Aside>
          <Article>
            <Openlayers setMapInstance={mapInstance} />
            <ToggleAside onClick={toggleSidePanel} className={visibleSidePanel ? "visibleSidePanel" : ""}>
              <KeyboardDoubleArrowLeftIcon />
            </ToggleAside>
          </Article>
        </Main>
      </AppWrapper>
    </OpenlayersContext.Provider>
  );
}

export default DefaultPage;
