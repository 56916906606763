import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxBathymetrySliceSliceState {
  value: string[];
}

const initialState: ToolboxBathymetrySliceSliceState = {
  value: [],
};

export const ToolboxBathymetrySlice = createSlice({
  name: "toolboxBathymetryRights",
  initialState,
  reducers: {
    changeToolboxBathymetryRights: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
  },
});

export default ToolboxBathymetrySlice.reducer;

export const { changeToolboxBathymetryRights } = ToolboxBathymetrySlice.actions;
