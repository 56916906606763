import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PortDetailState {
  name: string;
  highwater: string;
  sealevelrise: string;
  surgelevel: string;
  windspeed: string;
  waveheight: string;
  peakwaveperiod: string;
  wavedirection: string;
  icethickness: string;
}

interface PortDetailSummaryState {
  value: PortDetailState[];
}

const initialState: PortDetailSummaryState = {
  value: [],
};

export const DashboardPortSummarySlice = createSlice({
  name: "portSummary",
  initialState,
  reducers: {
    changeDashboardPortSummaryInput: (
      state,
      action: PayloadAction<PortDetailState>
    ) => {
      state.value.push(action.payload);
    },
    removePortfromDashboardSummary: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export default DashboardPortSummarySlice.reducer;

export const {
  changeDashboardPortSummaryInput,
  removePortfromDashboardSummary,
} = DashboardPortSummarySlice.actions;
