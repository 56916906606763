import {
  Box,
  Link,
  Typography,
  IconButton,
  DialogActions,
  Divider,
  Popover,
} from "@mui/material";
import { useState } from "react";
import { PrivacyModal } from "./PrivacyModal";
import { ContactUsModal } from "./ContactUsModal";
import { TandCModal } from "./TandCModal";
import { CopyrightModal } from "./CopyrightModal";
import MenuIcon from "@mui/icons-material/Menu";

export const FooterComponent = () => {
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const handlePrivacyOpen = () => setPrivacyOpen(true);
  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
    handleDialogClose();
  };

  const [TandCOpen, setTandCOpen] = useState(false);
  const handleTandCOpen = () => setTandCOpen(true);
  const handleTandCClose = () => {
    setTandCOpen(false);
    handleDialogClose();
  };

  const [ContactOpen, setContactOpen] = useState(false);
  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => {
    setContactOpen(false);
    handleDialogClose();
  };

  const [CopyrightOpen, setCopyrightOpen] = useState(false);
  const handleCopyrightOpen = () => setCopyrightOpen(true);
  const handleCopyrightClose = () => {
    setCopyrightOpen(false);
    handleDialogClose();
  };

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDialogOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);

    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setAnchorEl(null);
    setDialogOpen(false);
  };

  return (
    <>
      <Box
        width="100%"
        padding="0 3rem"
        height="4rem"
        sx={{
          display: "flex",
          position: "fixed",
          bottom: 0,
          bgcolor: "#d0efff",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "1296px",
            minWidth: "auto",
            maxWidth: "1296px",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0rem",
              alignItems: "center",
            }}
          >
            <Link
              href="https://www.cbcl.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="logo/CBCL_Logo_BLK.jpg" alt="CBCL" width={"64px"} />
            </Link>
            <Link
              href="https://www.dhigroup.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="logo/DHI-removebg-preview.png"
                alt="DHI"
                width={"84px"}
              />
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              "@media (max-width: 900px)": {
                display: "none",
              },
            }}
          >
            <Box onClick={handlePrivacyOpen}>
              <Typography
                color="secondary.dark"
                variant="body1"
                sx={{
                  "&:hover": { cursor: "pointer" },
                  userSelect: "none !important",
                }}
              >
                Privacy
              </Typography>
            </Box>
            <Typography
              color="secondary.dark"
              variant="body1"
              sx={{
                "&:hover": { cursor: "pointer" },
                userSelect: "none !important",
              }}
              onClick={handleTandCOpen}
            >
              Terms & Conditions
            </Typography>
            <Typography
              color="secondary.dark"
              variant="body1"
              sx={{
                "&:hover": { cursor: "pointer" },
                userSelect: "none !important",
              }}
              onClick={handleContactOpen}
            >
              Contact Us
            </Typography>
            <Typography
              color="secondary.dark"
              variant="body1"
              sx={{
                "&:hover": { cursor: "pointer" },
                userSelect: "none !important",
              }}
              onClick={handleCopyrightOpen}
            >
              Copyright
            </Typography>
          </Box>

          <IconButton
            sx={{
              "@media (min-width: 901px)": {
                display: "none",
              },
              color: "black",
            }}
            onClick={handleDialogOpen}
            disableRipple
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>

      <Popover
        open={isDialogOpen}
        anchorEl={anchorEl}
        onClose={handleDialogClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DialogActions>
          <Box display="flex" flexDirection="column" gap="1rem" p="1rem">
            <Typography
              onClick={handlePrivacyOpen}
              color="secondary.dark"
              textAlign="center"
              sx={{ cursor: "pointer" }}
            >
              Privacy
            </Typography>
            <Divider />
            <Typography
              onClick={handleTandCOpen}
              color="secondary.dark"
              sx={{ cursor: "pointer" }}
              textAlign="center"
            >
              Terms & Conditions
            </Typography>
            <Divider />
            <Typography
              onClick={handleContactOpen}
              color="secondary.dark"
              sx={{ cursor: "pointer" }}
              textAlign="center"
            >
              Contact Us
            </Typography>
            <Divider />
            <Typography
              onClick={handleCopyrightOpen}
              color="secondary.dark"
              sx={{ cursor: "pointer" }}
              textAlign="center"
            >
              Copyright
            </Typography>
          </Box>
        </DialogActions>
      </Popover>

      <PrivacyModal open={privacyOpen} handleClose={handlePrivacyClose} />
      <ContactUsModal open={ContactOpen} handleClose={handleContactClose} />
      <TandCModal open={TandCOpen} handleClose={handleTandCClose} />
      <CopyrightModal open={CopyrightOpen} handleClose={handleCopyrightClose} />
    </>
  );
};
