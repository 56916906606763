import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DrawerState {
  value: boolean;
}

const initialState: DrawerState = {
  value: false,
};

export const DrawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    changeDrawerState: (state, action: PayloadAction<DrawerState>) => {
      return (state = action.payload);
    },
  },
});

export default DrawerSlice.reducer;

export const { changeDrawerState } = DrawerSlice.actions;
