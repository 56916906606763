import { Box, Typography, Divider, Stack, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface TableProps {
  mainheading: string;
  captionheading: string;
  value: string;
  tooltipValue: string;
}

export const DashboardIceTabTableColumn = ({ mainheading, captionheading, value, tooltipValue }: TableProps) => {
  return (
    <Box height="3rem" sx={{ display: "grid", gridTemplateColumns: "35rem 1fr" }}>
      <Stack direction="column">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="body2" color="secondary.dark" sx={{ ml: "1rem", mt: "0.5rem" }}>
            {mainheading}
          </Typography>
          <LightTooltip title={tooltipValue} placement={"right"} enterDelay={500} leaveDelay={200}>
            <IconButton disableRipple sx={{ "&:hover": { color: "secondary.dark" }, mt: "-0.1rem" }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </LightTooltip>
        </Box>
        <Typography variant="caption" color="grey.500" sx={{ ml: "1rem", mt: "-0.5rem" }}>
          {captionheading}
        </Typography>
      </Stack>
      <Box m="1rem">
        <Typography color="secondary.dark" variant="body2" fontWeight="700" textAlign="end">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
