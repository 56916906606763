import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { PolarComponent, TooltipComponent, LegendComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { Box, Typography } from "@mui/material";

echarts.use([PolarComponent, TooltipComponent, LegendComponent, BarChart]);

interface directionProps {
  windData: unknown[];
}

const getRandomColor = () => {
  const random255 = () => Math.floor(Math.random() * 256);
  return `rgb(${random255()}, ${random255()}, ${random255()})`;
};

const COLORS = Array(5)
  .fill(null)
  .map(() => getRandomColor());

export const WindTabWaveRoseChart = ({ windData }: directionProps) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(windData);
  }, [windData]);

  const transformedData = data.map((row: any, index: number) => {
    const binNames = Object.keys(row).filter((key) => key.includes("]"));
    const start = index * 2;
    const end = start + 2;
    const name = row.HS || row.WS || `(${start}, ${end}]`;

    const values = binNames.map((bin) => parseFloat(row[bin] || "0"));

    return {
      name,
      value: values,
    };
  });

  const options = {
    angleAxis: {
      type: "category",
      data: data.length ? Object.keys(data[0]).slice(1) : [],
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
      z: 10000,
      position: function (point: any, params: any, dom: any, rect: any, size: any) {
        return [point[0], size.viewSize[1]];
      },
    },

    radiusAxis: {
      axisLabel: {
        show: true,
      },
      axisTick: {
        show: true,
      },
    },
    polar: {
      center: ["50%", "45%"],
      radius: ["0%", "80%"],
    },
    series: transformedData.map((d: any, idx: number) => ({
      type: "bar",
      name: d.name,
      data: d.value,
      coordinateSystem: "polar",
      stack: "stack",

      color: COLORS[idx],
    })),

    legend: {
      show: true,
      top: "90%",
      orient: "horizontal",
      right: 10,
      data: transformedData.map((d: any) => d.name).filter(Boolean),
      type: "scroll",
      pageButtonPosition: "end",
      pageIconColor: "#00000042",
      pageIconInactiveColor: "#aaa",
      pageTextStyle: { color: "#333" },
    },
  };

  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{
        height: "25rem",
        mt: "1rem",
        ml: "1rem",
        width: "23.5rem",
        border: "1px solid #CFDBE2",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          mb: "1rem",
        }}
      >
        <Typography variant="h4" mt="1.5rem" ml="1rem" color="secondary.dark">
          Rose: Wind Speed [m/s]
        </Typography>
      </Box>
      {data.length > 0 ? (
        <ReactECharts option={options} echarts={echarts} notMerge={true} />
      ) : (
        <Box m="2rem" mt="7rem">
          <Typography variant="body2" color="secondary.dark" justifyContent="center" textAlign="center">
            No data available.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
