import { createSlice } from "@reduxjs/toolkit";

export const WaveProfileToolboxSlice = createSlice({
  name: "datarepouploadStatus",
  initialState: {
    jobId: "",
    generateStatisticsClicked: false,
  },
  reducers: {
    setWaveProfileJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setWaveProfileGenerateStatisticsClicked: (state, action) => {
      state.generateStatisticsClicked = action.payload;
    },
  },
});

export const { setWaveProfileJobId, setWaveProfileGenerateStatisticsClicked } = WaveProfileToolboxSlice.actions;
export default WaveProfileToolboxSlice.reducer;
