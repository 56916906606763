import { GeoJSON, WMTSCapabilities } from "ol/format";
import { Collection, Feature, Map } from "ol";
import TileLayer from "ol/layer/Tile";
import { transformExtent } from "ol/proj";
import { WMTS } from "ol/source";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { harboursLayer, canadianBorderLayer, clusterLayer } from "./default_groups_layers";
import LayerGroup from "ol/layer/Group";
import {
  getAllCapabilitiesInProject,
  getAllHarbours,
  getCanadianBorderGis,
  getTileDataSetCapabilities,
  getTileDataSets,
  getTimeSeriesGisQuery,
  tileLoader,
} from "../../api/backend_public";
import { publicDataSets } from "../../api/init";

export const initHarbours = async (mapInstance: React.MutableRefObject<Map | null>) => {
  return getAllHarbours().then((response) => {
    mapInstance?.current?.removeLayer(harboursLayer);
    mapInstance?.current?.removeLayer(clusterLayer);
    mapInstance?.current?.addLayer(harboursLayer);
    mapInstance?.current?.addLayer(clusterLayer);
    try {
      const readFeatures = new GeoJSON().readFeatures(response, {
        dataProjection: "EPSG:4326",
        featureProjection: mapInstance?.current?.getView().getProjection(),
      });

      harboursLayer.getSource()?.addFeatures(readFeatures as Feature[]);
      return readFeatures;
    } catch (error) {}
  });
};
export const initBorders = async (mapInstance: React.MutableRefObject<Map | null>) => {
  mapInstance?.current?.removeLayer(canadianBorderLayer);
  mapInstance?.current?.addLayer(canadianBorderLayer);

  publicDataSets.CoastBoundaries.map((region: any) => {
    getCanadianBorderGis(region).then((response) => {
      const readFeatures = new GeoJSON().readFeatures(response, {
        dataProjection: "EPSG:4326",
        featureProjection: mapInstance?.current?.getView().getProjection(),
      });
      readFeatures.map((f: any) => {
        f.setId(f.getId() + "_" + response.id);
      });
      canadianBorderLayer.getSource()?.addFeatures(readFeatures as Feature[]);
    });
  });

  mapInstance?.current?.on("singleclick", (evt) => {
    const view = mapInstance.current?.getView();
    const clickedFeatures = mapInstance.current?.getFeaturesAtPixel(evt.pixel);

    if (clickedFeatures) {
      const polygonFeature = clickedFeatures.find((f) => f.getGeometry()?.getType().includes("Polygon"));
      const overlappingFeatures = clickedFeatures.length > 1;

      if (polygonFeature && !overlappingFeatures && view) {
        const polygonExtent = polygonFeature.getGeometry()?.getExtent();
        if (polygonExtent) {
          view.fit(polygonExtent, { duration: 500 });
        }
      }
    }
  });
};

export const getGisTimeSeries = async (id: string) => {
  return getTimeSeriesGisQuery(id).then((response: any) => {
    return response.json;
  });
};

export const getTileLayers = async (mapInstance: React.MutableRefObject<Map | null>, id: string, group: LayerGroup) => {
  return getTileDataSets(id).then((response) => {
    mapInstance?.current?.removeLayer(group);
    mapInstance?.current?.addLayer(group);

    const filterData = response.data.filter((item: any) => item.datasetType.includes("tiles"));
    const WMTSLayers: any = [];
    const WMTSLayersSources: any = [];

    filterData.map(({ id, name, projectId }: any) => {
      const tilesWMTS = new TileLayer({
        visible: true,
        opacity: 1,
        properties: {
          id: id,
          name: name,
          project: projectId,
        },
      });
      getTileDataSetCapabilities(id).then((response) => {
        const parser = new WMTSCapabilities();
        const parsedCapabilities = parser.read(response);
        const sourceExtent = transformExtent(
          parsedCapabilities.Contents.Layer[0].WGS84BoundingBox,
          "EPSG:4326",
          mapInstance?.current?.getView().getProjection()
        );
        const options = optionsFromCapabilities(parsedCapabilities, {
          layer: parsedCapabilities.Contents.Layer[0].Identifier,
        });
        options!.interpolate = false;

        options!.tileLoadFunction = tileLoader;
        tilesWMTS.setSource(new WMTS(options!));
        WMTSLayersSources.push(new WMTS(options!));
        tilesWMTS.setExtent(sourceExtent);
      });
      WMTSLayers.push(tilesWMTS);
    });
    group.setLayers(new Collection(WMTSLayers));

    return WMTSLayers;
  });
};

export const getMultiTileLayers = async (
  mapInstance: React.MutableRefObject<Map | null>,
  id: string,
  group: LayerGroup,
  showfirst?: boolean
) => {
  return getTileDataSets(id).then((responses) => {
    mapInstance?.current?.removeLayer(group);
    mapInstance?.current?.addLayer(group);

    const isShowfirst: boolean = showfirst !== undefined ? showfirst : true;
    const WMTSLayers: any = [];
    responses.data.map(({ id, name, projectId }: any) => {
      getTileDataSetCapabilities(id).then((response) => {
        const parser = new WMTSCapabilities();
        const parsedCapabilities = parser.read(response);

        group.setProperties({ parsedCapabilities: parsedCapabilities });

        if (parsedCapabilities.Contents.Layer.length > 1) {
          parsedCapabilities.Contents.Layer.map((layer: any, index: number) => {
            const tilesWMTS = new TileLayer({
              visible: false,
              opacity: 1,
              properties: {
                id: layer.Identifier,
                name: name,
                project: projectId,
                parsedCapabilities: parsedCapabilities,
              },
            });
            const options = optionsFromCapabilities(parsedCapabilities, {
              layer: layer.Identifier,
            });
            options!.dimensions = parsedCapabilities.Contents.Layer[0].Dimension[0].Value;
            options!.interpolate = false;

            if (isShowfirst && index === 0) tilesWMTS.setVisible(true);

            options!.tileLoadFunction = tileLoader;
            tilesWMTS.setSource(new WMTS(options!));
            WMTSLayers.push(tilesWMTS);
          });
        } else {
          const tilesWMTS = new TileLayer({
            visible: false,
            opacity: 1,
            properties: {
              id: parsedCapabilities.Contents.Layer[0].Identifier,
              name: name,
              datasetId: id,
              project: projectId,
              parsedCapabilities: parsedCapabilities,
            },
          });
          const options = optionsFromCapabilities(parsedCapabilities, {
            layer: parsedCapabilities.Contents.Layer[0].Identifier,
          });
          options!.dimensions = parsedCapabilities.Contents.Layer[0].Dimension[0].Value;
          options!.interpolate = false;

          group.set("groupCount", responses.data ? responses.data.length : 0);

          options!.tileLoadFunction = tileLoader;
          tilesWMTS.setSource(new WMTS(options!));
          WMTSLayers.push(tilesWMTS);
        }
      });
    });
    group.setLayers(new Collection(WMTSLayers));
    return WMTSLayers;
  });
};

export const getTileLayersFromProject = async (
  mapInstance: React.MutableRefObject<Map | null>,
  id: string,
  group: LayerGroup
) => {
  const datasets = await getTileDataSets(id);
  mapInstance?.current?.removeLayer(group);
  mapInstance?.current?.addLayer(group);

  const capsInProject = await getAllCapabilitiesInProject(
    id,
    datasets.data.map((item: any) => item.id)
  );

  const dynamicLayers: any = [];
  capsInProject?.data?.map((getCap: any) => {
    const parser = new WMTSCapabilities();
    const parsedCapabilities = parser.read(getCap);

    if (parsedCapabilities.Contents.Layer.length > 1) {
      parsedCapabilities.Contents.Layer.map((layer: any, index: number) => {
        const tilesWMTS = new TileLayer({
          visible: false,
          opacity: 1,
          properties: {
            id: layer.Identifier,
            name: datasets.name,
            project: datasets.projectId,
            parsedCapabilities: parsedCapabilities,
          },
        });
        const options = optionsFromCapabilities(parsedCapabilities, {
          layer: layer.Identifier,
        });
        options!.dimensions = parsedCapabilities.Contents.Layer[0].Dimension[0].Value;
        options!.interpolate = false;

        if (index === 0) tilesWMTS.setVisible(true);

        options!.tileLoadFunction = tileLoader;
        tilesWMTS.setSource(new WMTS(options!));
        dynamicLayers.push(tilesWMTS);
      });
    } else {
      const tilesWMTS = new TileLayer({});
      if (tilesWMTS.getSource() !== null) return;

      const options = optionsFromCapabilities(parsedCapabilities, {
        layer: parsedCapabilities.Contents.Layer[0].Identifier,
      });
      options!.tileLoadFunction = tileLoader;
      tilesWMTS.setSource(new WMTS(options!));
      dynamicLayers.push(tilesWMTS);
    }
  });
  group.setLayers(new Collection(dynamicLayers));

  return;
};

export const fitToExtent = async (mapInstance: React.MutableRefObject<Map | null>, extent: number[]) => {
  mapInstance?.current?.getView().fit(extent, {
    maxZoom: 10,
    duration: 1000,
    size: [0.6 * mapInstance?.current?.getSize()![0], 0.6 * mapInstance?.current?.getSize()![1]],
  });
};

export interface MapProps {
  features: Feature[] | null;
}

export enum featurePages {
  OVERVIEW = "Overview",
  WATERLEVELS = "Water Levels",
  Waves = "Waves",
  WIND = "Wind",
  ICE = "Ice",
  SEALEVEL = "Sea Level",
  GEOTECHNICAL = "Geotechnical",
  OTHERDATA = "Other data",
}

export enum toolControls {
  BATHYMETRY = "Bathymetry",
  WAVEHEIGHT = "Wave Height",
  WIND = "Wind",
  WATERLEVEL = "Water Level",
  ICECOVER = "Ice Cover",
  SEALEVELRISE = "Sea Level Rise",
  INFRASTRUCTUREDRAWING = "Infrastructure Drawings",
  MISCFREEFORMAT = "Misc / Free Format",
  MODELRESULTSHYDRO = "Model Results (Hydrodynamic)",
  MODELRESULTSWAVE = "Model Results (Wave)",
  MODELGRIDMESH = "Model Grid / Mesh",
  BOREHOLELOGS = "Borehole Logs",
  WINDTIMESERIES = "Wind (Timeseries)",
  WAVESTIMESERIES = "Waves (Timeseries)",
  WATERLEVELTIMESEIRES = "Water Level (Timeseries)",
  CURRENTSTIMESERIES = "Currents (Timeseries)",
  DISCHARGETIMESERIES = "Discharge (Timeseries)",
  ICETHICKNESSTIMESERIES = "Ice Thickness (Timeseries)",
  PUBLIC = "Public",
  PRIVATE = "Private",
  GROUP = "Group",
  MIKEDFS0 = "MIKE DFS0 (.dfs0)",
  POINTSHAPEFILE = "Point Shapefile (.shp)",
  TAGGEDIMAGE = "Tagged Image File Format (.tiff)",
  LIDARSPOINTCLOUD = "LiDar Point Cloud (.las)",
  MIKEMESH = "MIKE MESH (.mesh)",
  ALL = "All",
  EASTCOAST = "East Coast",
  WESTCOAST = "West Coast",
  GREATLAKES = "Great Lakes",
  ARCTIC = "ARCTIC",
  INTERPOLATIONBOUNDARY = "Interpolation Boundary",
}

export const featureTabs = [
  featurePages.OVERVIEW,
  featurePages.WATERLEVELS,
  featurePages.Waves,
  featurePages.WIND,
  featurePages.ICE,
  featurePages.SEALEVEL,
  featurePages.GEOTECHNICAL,
  featurePages.OTHERDATA,
];

export const toolControlsItems = [
  toolControls.BATHYMETRY,
  toolControls.WAVEHEIGHT,
  toolControls.WIND,
  toolControls.WATERLEVEL,
  toolControls.ICECOVER,
  toolControls.SEALEVELRISE,
];
