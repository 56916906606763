import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoHeadDataTypeSliceState {
  value: string[];
}

const initialState: DataRepoHeadDataTypeSliceState = {
  // value: ["All"],
  value: [],
};

export const DataRepoHeadDataTypeSlice = createSlice({
  name: "headdatatype",
  initialState,
  reducers: {
    changeHeadDataType: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
  },
});

export default DataRepoHeadDataTypeSlice.reducer;

export const { changeHeadDataType } = DataRepoHeadDataTypeSlice.actions;
