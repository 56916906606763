import { Box, Divider, Stack, IconButton, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState, Dispatch, SetStateAction } from "react";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface portProps {
  port: any;
  setSearchParams: (params: URLSearchParams) => void;
  searchParams: URLSearchParams;
  favoritePorts: Record<string, string[]>;
  setFavoritePorts: Dispatch<SetStateAction<Record<string, string[]>>>;
  checked: boolean;
}

function DashboardPortsList({
  port,
  setSearchParams,
  searchParams,
  favoritePorts,
  setFavoritePorts,
  checked,
}: portProps) {
  const [value, setValue] = useState(false);

  const handleFavoriteClick = () => {
    const isFavorite = isPortFavorite();

    if (idTokenPayload && idTokenPayload.sub) {
      const userId = idTokenPayload.sub;
      const storedData = localStorage.getItem("favoritePorts");
      let allUsersFavorites = storedData ? JSON.parse(storedData) : {};
      let userFavorites = [...(allUsersFavorites[userId] || [])];

      if (isFavorite) {
        userFavorites = userFavorites.filter((p) => p !== port.get("Harbour_Na"));
      } else {
        if (!userFavorites.includes(port.get("Harbour_Na"))) {
          userFavorites.push(port.get("Harbour_Na"));
        }
      }

      const updatedFavoritePorts = {
        ...favoritePorts,
        [userId]: userFavorites,
      };
      setFavoritePorts(updatedFavoritePorts);

      allUsersFavorites[userId] = userFavorites;
      localStorage.setItem("favoritePorts", JSON.stringify(allUsersFavorites));
    }
  };

  const { idTokenPayload } = useOidcIdToken();
  const handlePortClicked = () => {
    searchParams.set("port", encodeURIComponent(port.get("Harbour_nu")));
    searchParams.set("tab", encodeURIComponent("0"));
    setSearchParams(searchParams);
  };

  const isPortFavorite = () => {
    if (idTokenPayload && idTokenPayload.sub) {
      const userId = idTokenPayload.sub;
      const userFavorites = favoritePorts[userId] || [];
      return userFavorites.includes(port.get("Harbour_Na"));
    }
    return false;
  };

  return (
    <Box
      sx={{
        "&:hover": {
          cursor: "pointer",
          bgcolor: "grey.50",
        },
      }}
    >
      <Box
        height="152px"
        width="720px"
        sx={{
          display: "flex",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
        mb="1rem"
      >
        <Box border={port.get("Photo") !== "" ? "" : "1px solid #608da2"} borderRadius="16px" ml="1rem" mt="0.75rem">
          <Box
            component="img"
            sx={{
              height: port.get("Photo") !== "" ? "137.5px" : "80px",
              mt: port.get("Photo") !== "" ? "" : "16px",
              width: "240px",
              border: port.get("Photo") !== "" ? "1px solid #608da2" : "",
              borderRadius: port.get("Photo") !== "" ? "16px" : "",
            }}
            src={port.get("Photo") !== "" ? `14b_Key_Locations_Photos/${port.get("Photo")}` : "logo/CCZIS.svg"}
            alt={port.get("Harbour_Na")}
            onClick={handlePortClicked}
          />
          {port.get("Photo") === "" && (
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="caption" color="secondary.dark">
                No Photo Available
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gridTemplateColumns: "0.5fr 0.5fr",
            justifyContent: "space-between",
          }}
        >
          <Stack width="30rem" onClick={handlePortClicked}>
            <Box width="100%" sx={{ display: "flex", gridTemplateColumns: "1fr 1fr" }}>
              <Box width="304px">
                <Typography
                  sx={{
                    mt: "19px",
                    ml: "1rem",
                    fontSize: "10px",
                    color: "grey.500",
                  }}
                >
                  Small Craft Harbour
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: "0.025rem",
                    ml: "1rem",
                    color: "secondary.main",
                    fontWeight: 700,
                  }}
                >
                  {port.get("Harbour_Na")}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{
                mt: "17px",
                ml: "1rem",
                mb: "4px",
                color: "secondary.dark",
              }}
            >
              <b>Harbour Code: </b>
              {port.get("Harbour_nu")}
            </Typography>
            <Typography variant="body2" sx={{ ml: "1rem", mb: "4px", color: "secondary.dark" }}>
              <b>Province: </b>
              {port.get("Province") === "NS"
                ? "Nova Scotia"
                : port.get("Province") === "NL"
                ? "Newfoundland and Labrador"
                : port.get("Province") === "PE"
                ? "Prince Edward Island"
                : port.get("Province") === "QC"
                ? "Quebec"
                : port.get("Province") === "BC"
                ? "British Columbia"
                : port.get("Province") === "AB"
                ? "Alberta"
                : port.get("Province") === "NB"
                ? "New Brunswick"
                : port.get("Province") === "ON"
                ? "Ontario"
                : port.get("Province") === "MB"
                ? "Manitoba"
                : port.get("Province") === "SK"
                ? "Saskatchewan"
                : port.get("Province") === "YT"
                ? "Yukon"
                : port.get("Province") === "NT"
                ? "Northwest Territories"
                : port.get("Province") === "NU"
                ? "Nunavut"
                : "Unknown"}
            </Typography>
            <Typography variant="body2" sx={{ ml: "1rem", mb: "4px", color: "secondary.dark" }}>
              <b>Authority: </b> {port.get("Authority")}
            </Typography>
          </Stack>
          <LightTooltip
            title={idTokenPayload ? "" : "login to add favourites"}
            placement="right-start"
            enterDelay={500}
            leaveDelay={200}
          >
            <Box
              sx={{
                mt: "1rem",
              }}
            >
              <IconButton
                disableRipple
                aria-checked={value || checked}
                onClick={handleFavoriteClick}
                color="primary"
                disabled={!idTokenPayload}
              >
                {value || checked || isPortFavorite() ? (
                  <FavoriteIcon fontSize="medium" />
                ) : (
                  <FavoriteBorderIcon fontSize="medium" />
                )}
              </IconButton>
            </Box>
          </LightTooltip>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}

export default DashboardPortsList;
