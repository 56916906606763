import { Box, Typography, Stack } from "@mui/material";
import { GeotechnicalTopBar } from "./GeotechnicalTopBar";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

interface ChartProps {
  yMin: number[];
  yMax: number[];
  lithoMain: string[];
  lithoSecondary: string[];
}

export const GeotecnicalHeader = ({ yMax, yMin, lithoMain, lithoSecondary }: ChartProps) => {
  const downloadCsv = (yMax: any, yMin: any, lithoMain: any, lithoSecondary: any) => {
    const generateCsvContent = () => {
      let csvContent = "to (depth),from (depth),main unit,secondary unit\r\n";

      if (yMin && yMax && lithoMain && lithoSecondary) {
        yMin.forEach((min: any, index: number) => {
          const max = yMax[index];
          const main = lithoMain[index];
          const secondary = lithoSecondary[index] || "N/A";
          csvContent += `${max},${min},${main},${secondary}\r\n`;
        });
      } else {
        console.error("One of the inputs is undefined");
      }

      return csvContent;
    };

    const csvData = generateCsvContent();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "geotechnical_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography m="0.5rem 1rem" variant="body1" color="secondary.dark" fontWeight="700">
          Geotechnical
        </Typography>
      </Box>
      <Box mr="1rem">
        {yMax.length > 0 && (
          <GeotechnicalTopBar
            name="Download"
            icon={<GetAppOutlinedIcon />}
            onclick={() => downloadCsv(yMax, yMin, lithoMain, lithoSecondary)}
          />
        )}
      </Box>
    </Box>
  );
};
