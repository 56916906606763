import React, { useState } from "react";

type ProcessedFile = {
  file: File;
  rights: string;
  id: string;
  checked: boolean;
};

type ProcessedFilesContextType = {
  processedFiles: ProcessedFile[];
  setProcessedFiles: React.Dispatch<React.SetStateAction<ProcessedFile[]>>;
};

export const ProcessedFilesContext = React.createContext<ProcessedFilesContextType | undefined>(undefined);

interface ProcessedFilesProviderProps {
  children: React.ReactNode;
}

export const ProcessedFilesProvider: React.FC<ProcessedFilesProviderProps> = ({ children }) => {
  const [processedFiles, setProcessedFiles] = useState<ProcessedFile[]>([]);

  return (
    <ProcessedFilesContext.Provider value={{ processedFiles, setProcessedFiles }}>
      {children}
    </ProcessedFilesContext.Provider>
  );
};
