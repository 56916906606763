import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { changeHeadDataType } from "../../../features/DataRepoHeadDataTypeSlice";
import { setHeadRights } from "../../../features/DataRepoHeadRightsSlice";
import { changeHeadUsername } from "../../../features/DataRepoHeadUsernameSlice";
import { toolControls } from "../../Openlayers/data_layers";
import { useSearchParams } from "react-router-dom";

export const DataRepoTextField = () => {
  const dispatch = useDispatch();
  const dataType = useSelector((state: RootState) => state.headDataType.value);
  const rights = useSelector((state: RootState) => state.headRights.value);
  const username = useSelector((state: RootState) => state.headUsername.value);
  const [searchParams, setSearchParams] = useSearchParams();
  const options = [
    { value: toolControls.PUBLIC, label: toolControls.PUBLIC },
    { value: toolControls.PRIVATE, label: toolControls.PRIVATE },
    { value: toolControls.GROUP, label: toolControls.GROUP, disabled: true },
  ];
  const fileDataTypeOptions = [
    { value: toolControls.BATHYMETRY, label: toolControls.BATHYMETRY },
    {
      value: toolControls.WAVESTIMESERIES,
      label: toolControls.WAVESTIMESERIES,
    },
    { value: toolControls.WINDTIMESERIES, label: toolControls.WINDTIMESERIES },
    {
      value: toolControls.WATERLEVELTIMESEIRES,
      label: toolControls.WATERLEVELTIMESEIRES,
    },
    {
      value: toolControls.CURRENTSTIMESERIES,
      label: toolControls.CURRENTSTIMESERIES,
    },
    {
      value: toolControls.DISCHARGETIMESERIES,
      label: toolControls.DISCHARGETIMESERIES,
    },
    {
      value: toolControls.ICETHICKNESSTIMESERIES,
      label: toolControls.ICETHICKNESSTIMESERIES,
    },
    { value: toolControls.BOREHOLELOGS, label: toolControls.BOREHOLELOGS },
    {
      value: toolControls.MODELRESULTSWAVE,
      label: toolControls.MODELRESULTSWAVE,
    },
    {
      value: toolControls.INFRASTRUCTUREDRAWING,
      label: toolControls.INFRASTRUCTUREDRAWING,
    },
    { value: toolControls.MISCFREEFORMAT, label: toolControls.MISCFREEFORMAT },
    { value: toolControls.MODELGRIDMESH, label: toolControls.MODELGRIDMESH },
    {
      value: toolControls.MODELRESULTSHYDRO,
      label: toolControls.MODELRESULTSHYDRO,
    },
  ];
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "1rem",
        justifyContent: "center",
        m: "2rem 1rem 1rem 1rem",
        maxHeight: "2.5rem",
      }}
    >
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "#86A2B3",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
            color: "#86A2B3",
          },
        }}
      >
        <InputLabel id={`Data Type-drop-down`} style={{ color: "#86A2B3" }}>
          Data Type
        </InputLabel>
        <Select
          labelId={`Data Type-drop-down`}
          id={`Data Type-drop-down-select`}
          value={dataType}
          label="Data Type"
          multiple
          onChange={(e) => dispatch(changeHeadDataType(e.target.value as string[]))}
          size="small"
          renderValue={(selected) => {
            const selectedCount = (selected as string[]).length;
            return selectedCount > 1 ? `${selectedCount} selected` : selected.join(", ");
          }}
        >
          {fileDataTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "#86A2B3",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
            color: "#86A2B3",
          },
        }}
      >
        <InputLabel id={`Rights-drop-down`} style={{ color: "#86A2B3" }}>
          Rights
        </InputLabel>
        <Select
          labelId={`Rights-drop-down`}
          id={`Rights-drop-down-select`}
          value={rights}
          label="Rights"
          multiple
          onChange={(e) => dispatch(setHeadRights(e.target.value as string[]))}
          size="small"
          renderValue={(selected) => {
            const selectedCount = (selected as string[]).length;
            return selectedCount > 1 ? `${selectedCount} selected` : selected.join(", ");
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="User"
        variant="outlined"
        size="small"
        value={username}
        sx={{
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
        }}
        onChange={(e) => dispatch(changeHeadUsername({ value: e.target.value }))}
        InputLabelProps={{ style: { color: "#86A2B3" } }}
      />
    </Box>
  );
};
