import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileDataFormatSliceState {
  value: string;
}

const initialState: FileDataFormatSliceState = {
  value: "",
};

export const DataRepoFileDataFormatSlice = createSlice({
  name: "DataFormat",
  initialState,
  reducers: {
    changeFileDataFormat: (
      state,
      action: PayloadAction<FileDataFormatSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileDataFormatSlice.reducer;

export const { changeFileDataFormat } = DataRepoFileDataFormatSlice.actions;
