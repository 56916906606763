import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const PrivacyModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "45rem" },
          }}
        >
          <Box>
            <Box display="flex" flexDirection="row">
              <Box textAlign="center" width="100%">
                <Typography
                  variant="h2"
                  sx={{
                    mt: "2rem",
                    ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                    textAlign: "inherit",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Box>
              <IconButton
                sx={{ mr: "2rem", mt: "1rem" }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box m="2rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                <b>This Privacy Policy is DRAFT.</b> It is included in this
                Phase 2 prototype to provide an example of the final Privacy
                Policy which will be included in Phase 3. As part of Phase 3,
                legal review of the Privacy Policy will be completed to ensure
                compliance with governing agency requirements.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Overview
              </Typography>
            </Box>
            <Box m="0.25rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                This Privacy Policy explains how CBCL Limited (CBCL) and DHI
                Water and Environment Inc. (DHI) ("we" or "us") process your
                personal data when visiting the CCZIS website or when contacting
                us through the website.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Your privacy is important to us. No personal information is
                collected on the CCZIS website individuals choose to provide
                that information.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Consent
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We do not collect personally identifiable information unless you
                choose to provide it to us, for example by sending an email or
                by billing out a form through the website. When you voluntarily
                submit or provide personal information through the CCZIS, you
                consent to the collection, use, and disclosure of your personal
                information as set out in this Privacy Policy, as it may be
                amended from time to time.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                You may withdraw your consent to the collection, use and
                disclosure of your personal information, subject to certain
                limitations. If you do so, we may not be able to continue to
                provide services through the CCZIS. The withdrawal of consent
                does not apply retroactively to information that may already
                have been disclosed.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                No information is collected through the CCZIS for commercial
                marketing.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Collection of Personal Information
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                When you enter and browse the CCZIS website, cookies will be
                placed in accordance with our Cookie Policy (below). Cookies are
                used to facilitate the user experience (through saving favourite
                harbours, for example), and support website navigation and
                performance improvements.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                When you contact us or submit a form, certain Personal
                Information will be collected. We process your personal data in
                order to respond to your inquiry or request, and to be able to
                get in touch with you to follow-up. We only process the
                information you choose to include in your inquiry.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                When you log-in to the CCZIS platform, you are also logging into
                DHI's MIKE Cloud Solutions. DHI may, as part of providing any
                cloud services, process personal data regarding end users as a
                data controller.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Retention of Personal Information
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We will use personal information only in ways that are
                compatible with the purposes for which it was collected or
                subsequently authorized by you. We will take reasonable steps to
                ensure that personal information is relevant to its intended
                use, accurate, complete and current.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Your personal data may be disclosed to our partners (providers
                who deliver services on behalf of DHI or CBCL), for example IT
                service providers. These partners only process the personal data
                on behalf of us and in accordance with our instructions. Your
                personal data may also be shared with other DHI affiliated
                companies. A list of all affiliated companies may be found on
                the following link: DHI Worldwide.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We will delete your personal data when the purpose for which it
                was provided has been fulfilled, unless we are required to keep
                it longer by statute or official policy. However, the data may
                be processed and stored for a longer period in anonymized form.
                We take appropriate measures to ensure that we process and store
                your personal data based on:
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •As a minimum for the time period in which we deliver a service
                to you,
              </Typography>
            </Box>
            <Box m="0rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •In accordance with law, contract, or our legal obligations,
              </Typography>
            </Box>
            <Box m="0rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •Or as long as the data are necessary in order for the purposes
                for which they are collected, or longer if it is required by
                contract, applicable law, or for statistical purposes governed
                by appropriate security measures.
              </Typography>
            </Box>

            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We do not sell or otherwise disclose information about how our
                users navigate and use our website.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Link to Other Websites
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Our website may contain links to other websites or to integrated
                sites. We are not responsible for the contents of the websites
                of other companies or for the practices of such companies
                regarding the collection of personal data. When you visit other
                websites, you should read the owners' policies on the protection
                of personal data and other relevant policies.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Cookie Policy
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                A cookie is a data file that a website stores on your IT
                equipment such as your computer, tablet or smartphone. The
                objective is to recognise your IT equipment and observe how you
                use the website.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Almost all websites use cookies. Cookies are often required to
                provide the correct standard of service at the website. A cookie
                is a passive file that cannot collect information stored on your
                computer or spread any computer virus or other harmful programs.
                We use cookies to provide the best possible service on this
                website.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                Certain information will be gathered automatically, such at the
                following. This information does not identify you personally.
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •The Internet domain from which you accessed the CCZIS
              </Typography>
            </Box>
            <Box m="0rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •Your IP address
              </Typography>
            </Box>
            <Box m="0rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •The type of browser and operating system used
              </Typography>
            </Box>
            <Box m="0rem 2rem 0rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •The date and time of your visit
              </Typography>
            </Box>
            <Box m="0rem 2rem 2rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                •The pages visited
              </Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
