import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

interface dataProps {
  datasetType: string;
  id: string;
  name: string;
  projectId: string;
  relativePath: string;
  updatedAt: string;
  datasetUrl: string;
}
interface DataResponseProps {
  totalCount: number;
  offset: number;
  limit: number;
  data: dataProps[];
}

interface ProcessedFilesState {
  filesWithMetadata: any[];
  publicFiles: DataResponseProps | null;
}

const initialState: ProcessedFilesState = {
  filesWithMetadata: [],
  publicFiles: null,
};

export const DataRepoProcessedFilesDataSlice = createSlice({
  name: "processedFilesData",
  initialState,
  reducers: {
    setProcessedFilesWithMetadata: (state, action) => {
      state.filesWithMetadata = action.payload;
    },
    setPublicFiles: (state, action) => {
      state.publicFiles = action.payload;
    },
  },
});

export const { setProcessedFilesWithMetadata, setPublicFiles } = DataRepoProcessedFilesDataSlice.actions;

export default DataRepoProcessedFilesDataSlice.reducer;
