import { Box, Icon, IconButton, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { deleteBathymetryData } from "../../../api/backend";
import { useDispatch } from "react-redux";
import { changeSeasonalFileHeaders } from "../../../features/ToolboxFeatures/SeasonalFileHeadersSlice";
import { Dayjs } from "dayjs";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { toolControls } from "../../Openlayers/data_layers";
import { Icon as OlIcon, Style } from "ol/style";
import { Point } from "ol/geom";
import { deleteBathymetryDataUnauthorised, getAllBathymetryDatasets } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";
import BaseLayer from "ol/layer/Base";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { transform } from "ol/proj";

export function getIconStyleByDataType(datatype: string, name: string, useHoverIcon: boolean) {
  let iconSrc;

  switch (datatype) {
    case toolControls.BATHYMETRY:
      iconSrc = useHoverIcon ? "icons/Bathymetry_hover.svg" : "icons/Bathymetry.svg";
      break;
    case toolControls.WINDTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Winds_hover.svg" : "icons/Winds.svg";
      break;
    case toolControls.WAVESTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Waves_hover.svg" : "icons/Waves.svg";
      break;
    case toolControls.WATERLEVELTIMESEIRES:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    case toolControls.CURRENTSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Currents_hover.svg" : "icons/Currents.svg";
      break;
    case toolControls.DISCHARGETIMESERIES:
      iconSrc = useHoverIcon ? "icons/Discharge_hover.svg" : "icons/Discharge.svg";
      break;
    case toolControls.ICETHICKNESSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Ice_hover.svg" : "icons/Ice.svg";
      break;
    case toolControls.MODELRESULTSWAVE:
      iconSrc = useHoverIcon ? "icons/Wave_hover.svg" : "icons/Wave.svg";
      break;
    case toolControls.INFRASTRUCTUREDRAWING:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MISCFREEFORMAT:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MODELGRIDMESH:
      iconSrc = useHoverIcon ? "icons/Mesh_hover.svg" : "icons/Mesh.svg";
      break;
    case toolControls.MODELRESULTSHYDRO:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    default:
      iconSrc = useHoverIcon ? "icons/Borehole_hover.svg" : "icons/Borehole.svg";
      break;
  }

  return new Style({
    image: new OlIcon({
      src: iconSrc,
      scale: 0.9,
    }),
  });
}

interface FileProps {
  index: number;
  tableElementData: any;
  seasonalFiles: any[];
  isIconAdded: boolean;
  setIsIconAdded: Dispatch<SetStateAction<boolean>>;
  setSeasonalFiles: Dispatch<SetStateAction<any[]>>;
  setStartDate: Dispatch<SetStateAction<Dayjs | null>>;
  setEndDate: Dispatch<SetStateAction<Dayjs | null>>;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  setFileTimePeriod: Dispatch<
    SetStateAction<{
      start: string;
      end: string;
    }>
  >;
}

export const SeasonalStatisticsFile = ({
  index,
  tableElementData,
  seasonalFiles,
  setSeasonalFiles,
  setStartDate,
  setEndDate,
  setSelectedValue,
  setFileTimePeriod,
  isIconAdded,
  setIsIconAdded,
}: FileProps) => {
  const map = useContext(OpenlayersContext);
  const dispatch = useDispatch();
  const [iconDeleted, setIconDeleted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleFileDelete = (fileIndex: number, name: string) => {
    const trunc_name = name.split(" (converted)")[0];
    removeBathymetryIconFromMap(tableElementData.id);
    setIconDeleted(true);
    getAllBathymetryDatasets(publicDataSets.PrivateTemporaryTimeseries.id)
      .then((result) => {
        const data = result.data;
        const matchingDatasets = data.filter((dataset: any) => dataset.name.includes(trunc_name));

        return Promise.all(matchingDatasets.map((dataset: any) => deleteBathymetryData(dataset.id)));
      })
      .then(() => {
        setSeasonalFiles([]);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const removeBathymetryIconFromMap = (id: string) => {
    if (map?.current) {
      const layerId = `bathymetryIconLayer${id}`;
      const layers = map.current.getLayers().getArray();
      const layersToRemove = layers.filter((layer) => layer.get("id") === layerId);
      layersToRemove.forEach((layer) => {
        if (layer) {
          map.current?.removeLayer(layer);
        }
      });

      setIsIconAdded(false);
    }
  };

  const addBathymetryIconToMap = (xCoordinate: number, yCoordinate: number, id: string, child: any) => {
    if (map?.current) {
      let proj4String;
      if (child.fileProj4String) {
        proj4String = child.fileProj4String;
      } else if (child.fileCRS) {
        const match = child.fileCRS.match(/proj4String:(.+)/);
        if (match && match.length > 1) {
          proj4String = match[1].trim();
        }
      }
      if (!proj4String) {
        return;
      }
      const customEPSGCode = `CUSTOM:${id}`;

      if (proj4String) {
        proj4.defs(customEPSGCode, proj4String);
        register(proj4);
      }

      const transformedCoordinates = transform(
        [xCoordinate, yCoordinate],
        customEPSGCode,
        map.current.getView().getProjection()
      );

      const iconFeature = new Feature({
        geometry: new Point(transformedCoordinates),
      });
      iconFeature.set("featureId", id, true);

      const iconStyle = getIconStyleByDataType(tableElementData.fileDataType, tableElementData.name, isHovered);
      iconFeature.setStyle(iconStyle);

      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      vectorLayer.set("id", `bathymetryIconLayer${id}`);
      vectorLayer.setZIndex(10000000);
      map.current.addLayer(vectorLayer);
    }
  };

  useEffect(() => {
    if (
      tableElementData.fileXCoordinate !== null &&
      tableElementData.fileYCoordinate !== null &&
      !isIconAdded &&
      !iconDeleted
    ) {
      addBathymetryIconToMap(
        tableElementData.fileXCoordinate,
        tableElementData.fileYCoordinate,
        tableElementData.id,
        tableElementData
      );
      setIsIconAdded(true);
    }
  }, [tableElementData, isIconAdded]);

  return (
    <Box ml="1rem" mt="0.5rem" display="flex" flexDirection="row" justifyContent="space-between" key={index}>
      <Box display="flex" flexDirection="row">
        <IconButton disableRipple sx={{ "&:hover": { cursor: "default" }, color: "secondary.dark" }}>
          <InsertDriveFileOutlinedIcon />
        </IconButton>
        <Typography variant="body2" color="secondary.dark" mt="0.75rem">{`${tableElementData.name}`}</Typography>
      </Box>
      <IconButton
        disableRipple
        sx={{
          color: "secondary.dark",
          mr: "1rem",
        }}
        onClick={() => {
          handleFileDelete(index, tableElementData.name);
          dispatch(changeSeasonalFileHeaders({ value: [] }));
          setStartDate(null);
          setEndDate(null);
          setSelectedValue("");
          setFileTimePeriod({ start: "", end: "" });
        }}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Box>
  );
};
