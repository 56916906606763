import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoTimeZoneSliceState {
  value: string;
}

const initialState: DataRepoTimeZoneSliceState = {
  value: "",
};

export const DataRepoTimeZoneSlice = createSlice({
  name: "TimeZone",
  initialState,
  reducers: {
    changeFileTimeZone: (
      state,
      action: PayloadAction<DataRepoTimeZoneSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoTimeZoneSlice.reducer;

export const { changeFileTimeZone } = DataRepoTimeZoneSlice.actions;
