import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxDataRightsSliceState {
  value: string;
}

const initialState: ToolboxDataRightsSliceState = {
  value: "Public",
};

export const ToolboxDataRightsSlice = createSlice({
  name: "ToolboxDataRights",
  initialState,
  reducers: {
    changeToolboxDataRights: (
      state,
      action: PayloadAction<ToolboxDataRightsSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxDataRightsSlice.reducer;

export const { changeToolboxDataRights } = ToolboxDataRightsSlice.actions;
