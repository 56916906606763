import { Box, Typography, Divider, IconButton } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface DataItem {
  AEP: string | number;
  WS: string | number;
  TP: string | number;
  Direction: string;
}

interface filteredData {
  filteredData: DataItem[];
  setFilteredData: Dispatch<SetStateAction<DataItem[]>>;
  directionValues: string[];
  returnPeriodValue: string[];
}

export const WindTabTable = ({ filteredData, setFilteredData, directionValues, returnPeriodValue }: filteredData) => {
  return (
    <Box
      m="1rem 1.5rem"
      sx={{
        border: "1px solid #CFDBE2",
        bgcolor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        width: "21rem",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "6rem 2fr 1fr",
          justifyContent: "space-between",
          height: "4rem",
          ml: "1rem",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body2"
              color="secondary.dark"
              sx={{ fontWeight: 700, m: "0.75rem -0.25rem 0.5rem 0.5rem", textAlign: "center" }}
            >
              AEP
            </Typography>
            <LightTooltip
              title="The probability associated with exceeding a given amount once or more in any given year"
              placement="right"
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton disableRipple sx={{ color: "secondary.dark", mt: "0rem" }}>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
          <Typography variant="body2" color="secondary.dark" sx={{ fontWeight: 700, textAlign: "left", ml: "1.5rem" }}>
            [%]
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body2"
              color="secondary.dark"
              sx={{ fontWeight: 700, m: "0.75rem -0.25rem 0.5rem 0.5rem", textAlign: "center" }}
            >
              Wind Speed
            </Typography>
            <LightTooltip title="10 m continuous wind speed" placement="right" enterDelay={500} leaveDelay={200}>
              <IconButton disableRipple sx={{ color: "secondary.dark", mt: "0rem" }}>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
          <Typography variant="body2" color="secondary.dark" sx={{ fontWeight: 700, textAlign: "left", ml: "2rem" }}>
            [m/s]
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body2"
              color="secondary.dark"
              sx={{ fontWeight: 700, m: "0.75rem -0.25rem 0.5rem 0.5rem", textAlign: "center" }}
            >
              Direction
            </Typography>
            <LightTooltip
              title="Direction clockwise from north that wind is blowing from"
              placement="right"
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton disableRipple sx={{ color: "secondary.dark", mt: "0rem" }}>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
        </Box>
      </Box>
      <Divider />

      <Box sx={{ maxHeight: "40rem", overflow: "auto" }}>
        {filteredData.length > 0 ? (
          filteredData.map((val, index) => {
            return (
              <React.Fragment key={`${val["AEP"]}+${index}`}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "6.6rem 1fr 1fr",
                    justifyContent: "space-between",
                    height: "auto",
                  }}
                >
                  <Typography variant="body2" color="secondary.dark" sx={{ m: "1rem 0.5rem", textAlign: "center" }}>
                    {val["AEP"]}
                  </Typography>
                  <Typography variant="body2" color="secondary.dark" sx={{ m: "1rem 0.5rem", textAlign: "center" }}>
                    {val["WS"]}
                  </Typography>
                  <Typography variant="body2" color="secondary.dark" sx={{ m: "1rem 0.5rem", textAlign: "center" }}>
                    {val["Direction"]}
                  </Typography>
                </Box>
                <Divider />
              </React.Fragment>
            );
          })
        ) : directionValues.length > 0 && returnPeriodValue.length > 0 ? (
          <Box height="10rem" p="2.5rem 1rem" width="auto">
            <Typography textAlign="center" variant="body2" color="secondary.dark" sx={{ whiteSpace: "normal" }}>
              No data available.
            </Typography>
          </Box>
        ) : (
          <Box height="10rem" p="2.5rem 1rem" width="auto">
            <Typography textAlign="center" variant="body2" color="secondary.dark" sx={{ whiteSpace: "normal" }}>
              Please select atleast 1 value of Annual Exceedance Probablity and Wave Direction each to see values.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
