import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilesDetailsInsidePolygonState {
  filename: string;
  rights: string;
  id: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
}

interface FileDetailsInsidePolygonSummary {
  value: FilesDetailsInsidePolygonState[];
}

const initialState: FileDetailsInsidePolygonSummary = {
  value: [],
};

export const datRepoFilesDetailsInsidePolygonSlice = createSlice({
  name: "fileDetailsInsidePolygonSlice",
  initialState,
  reducers: {
    addNewFileDetailsInsidePolygon: (
      state,
      action: PayloadAction<FilesDetailsInsidePolygonState>
    ) => {
      state.value.push(action.payload);
    },
    deleteFileDetailsInsidePolygon: (state) => {
      state.value = [];
    },
  },
});

export default datRepoFilesDetailsInsidePolygonSlice.reducer;

export const {
  addNewFileDetailsInsidePolygon,
  deleteFileDetailsInsidePolygon,
} = datRepoFilesDetailsInsidePolygonSlice.actions;
