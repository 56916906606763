import { Box, Drawer, IconButton, Tab, Tabs, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { NavigationButton } from "./NavigationButtons";
import { Data } from "@dhi/icons/dist";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { changeHeadDataType } from "../../features/DataRepoHeadDataTypeSlice";

export enum AppPages {
  HOME = "/",
  DASHBOARD = "/dashboard",
  REPOSETORY = "/repository",
  TOOLBOX = "/toolbox",
}

export const BurgerMenu = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    position: "absolute",
    width: "300px",
    top: "3rem",
    border: "0px solid transparent",
    boxShadow: "none",
  },
  "& .MuiPaper-root": {
    background: "#09334B",
  },
  "& .brugerMenuWrapper": {
    flexDirection: "column",
  },
  "& .brugerMenuWrapper > div": {
    justifyContent: "center",
    display: "flex",
  },
}));

const pages = [AppPages.DASHBOARD, AppPages.REPOSETORY, AppPages.TOOLBOX];

function Navigation() {
  const [pageIndex, setPageIndex] = React.useState<number | null>(null);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const largeBreakPoint = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useDispatch();
  React.useEffect(() => {
    const index = pages.indexOf(location.pathname as AppPages);
    setPageIndex(index !== -1 ? index : null);
  }, [location.pathname]);

  const changePage = (event: React.SyntheticEvent, newPage: number) => {
    setPageIndex(newPage);
  };

  const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpenBurgerMenu(open);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4rem",
            mr: "4rem",
          }}
        >
          <Link
            to={"/"}
            style={{
              color: "#FFFFFF",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="logo/CCZIS.svg"
              alt="logo"
              style={{
                height: "38px",
                width: "38px",
                marginTop: "0rem",
                borderRadius: "50%",
                border: "1px solid #09334B",
              }}
            />

            <Typography
              sx={{
                ml: "1rem",
                fontWeight: "700",
              }}
              noWrap
            >
              {!largeBreakPoint ? "Canadian Coastal Zone Information System" : "CCZIS"}
            </Typography>
          </Link>

          <Box m={2}>
            <Tabs
              textColor="primary"
              value={pageIndex !== null ? pageIndex : false}
              onChange={changePage}
              sx={{
                "& .MuiTab-root": {
                  fontWeight: "400",
                  color: "common.white",
                },
                "& .MuiTabs-indicator": {
                  height: "4px",
                  background: "white",
                },
                "& a.MuiButtonBase-root.MuiTab-root": {
                  minHeight: "52px",
                },
                "& a.MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  fontWeight: "400",
                  color: "white",
                  background: "#09334B",
                },
              }}
            >
              <Tab
                sx={{ minHeight: "56px" }}
                component={Link}
                label={!largeBreakPoint ? "Dashboard" : ""}
                to={`${AppPages.DASHBOARD}?${searchParams}`}
                icon={<LocationOnIcon />}
                iconPosition="start"
              />
              <Tab
                sx={{ minHeight: "56px" }}
                component={Link}
                label={!largeBreakPoint ? "Data Repository" : ""}
                icon={<Data fontSize="large" />}
                iconPosition="start"
                onClick={() => dispatch(changeHeadDataType([]))}
                to={`${AppPages.REPOSETORY}?${searchParams}`}
              />
              <Tab
                sx={{ minHeight: "56px" }}
                component={Link}
                label={!largeBreakPoint ? "Toolbox" : ""}
                icon={<HomeRepairServiceIcon fontSize="medium" />}
                iconPosition="start"
                to={`${AppPages.TOOLBOX}?${searchParams}`}
              />
            </Tabs>
          </Box>
        </Box>
        {!largeBreakPoint ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <NavigationButton />
          </Box>
        ) : (
          <>
            <IconButton edge="start" sx={{ color: "white" }} aria-label="open drawer" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>

            <BurgerMenu
              open={openBurgerMenu}
              anchor="right"
              onClose={toggleDrawer(false)}
              style={{
                height: location.pathname === "/" ? "calc(100vh - 7rem)" : "calc(100vh - 3rem)",
              }}
            >
              <NavigationButton />
            </BurgerMenu>
          </>
        )}
      </Box>
      {/* 
      {!largeBreakPoint ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavigationButton />
        </Box>
      ) : (
        <>
          <IconButton
            edge="start"
            sx={{ color: "white" }}
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <BurgerMenu
            open={openBurgerMenu}
            anchor="right"
            onClose={toggleDrawer(false)}
            style={{
              height:
                location.pathname === "/"
                  ? "calc(100vh - 7rem)"
                  : "calc(100vh - 3rem)",
            }}
          >
            <NavigationButton />
          </BurgerMenu>
        </>
      )} */}
    </>
  );
}

export default Navigation;
