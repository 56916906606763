import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoActivateUploadSliceState {
  value: boolean;
}

const initialState: DataRepoActivateUploadSliceState = {
  value: false,
};

export const DataRepoActivateUploadSlice = createSlice({
  name: "ActivateUpload",
  initialState,
  reducers: {
    changeDataRepoActivateUpload: (
      state,
      action: PayloadAction<DataRepoActivateUploadSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoActivateUploadSlice.reducer;

export const { changeDataRepoActivateUpload } =
  DataRepoActivateUploadSlice.actions;
