import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataCollectionDateState {
  value: string | null;
}

const initialState: DataCollectionDateState = {
  value: null,
};

export const DataCollectionDateSlice = createSlice({
  name: "DataCollectionDate",
  initialState,
  reducers: {
    changeDataCollectionDate: (
      state,
      action: PayloadAction<DataCollectionDateState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataCollectionDateSlice.reducer;

export const { changeDataCollectionDate } = DataCollectionDateSlice.actions;
