import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { DrawPolygon } from "@dhi/icons/dist";
import { MutableRefObject } from "react";
import { Map, Overlay } from "ol";
import { Draw, Modify, Snap } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Stroke } from "ol/style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { changeClearButtonVisibilityState } from "../../features/ClearButtonVisibilitySlice";
import Geometry from "ol/geom/Geometry";
import Polygon from "ol/geom/Polygon";
import { Close } from "@mui/icons-material";
import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import {
  addNewFileDetailsInsidePolygon,
  deleteFileDetailsInsidePolygon,
} from "../../features/FileDetailsInsidePolygonSlice";
import { clearPolygonCoordinates, setPolygonCoordinates } from "../../features/PolygonCoordinatesSlice";
import { DataRepoProcessedFilesContext } from "../DataRepositoryComponents/DataRepoContext/DataRepoContext";
import { toolControls } from "../Openlayers/data_layers";
import { setPolygonCoordinatesValue } from "../../features/DataRepoPolygonSlice";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface MapProps {
  map: MutableRefObject<Map | null>;
  drawInteraction: MutableRefObject<Draw | null>;
  overlays: MutableRefObject<Overlay[]>;
  setIsMeasuring: React.Dispatch<React.SetStateAction<boolean>>;
  drawnSource: VectorSource<any>;
  drawnFeature: Feature<Geometry>;
  setDrawnFeature: React.Dispatch<React.SetStateAction<any>>;
  isMeasuring: boolean;
  draw: Draw | null;
  setDraw: React.Dispatch<React.SetStateAction<Draw | null>>;
  setDrawClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FilesDetailsState {
  file: File;
  rights: string;
  id: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
}

export const MapViewerDrawPolygon = ({
  map,
  drawnSource,
  drawnFeature,
  setDrawnFeature,
  draw,
  setDraw,
  setDrawClicked,
}: MapProps) => {
  const dispatch = useDispatch();
  const [modify, setModify] = useState<any>(null);
  const processedFilesContext = React.useContext(DataRepoProcessedFilesContext);
  if (!processedFilesContext) {
    throw new Error("Component must be used within a ProcessedFilesProvider");
  }
  const { processedFiles, setProcessedFiles } = processedFilesContext;
  const clearButtonVisible = useSelector((state: RootState) => state.clearButton.value);
  const polygonCoordinates = useSelector((state: RootState) => state.polygon.value);

  useEffect(() => {
    if (location.pathname !== "/repository") {
      setDraw(null);
      setDrawClicked(false);
      dispatch(changeClearButtonVisibilityState({ value: false }));
      if (modify) {
        map.current?.removeInteraction(modify);
      }
      if (draw) {
        map.current?.removeInteraction(draw);
      }
    }
  }, [location, draw, modify, map, setDraw, setDrawClicked, dispatch]);

  useEffect(() => {
    return () => {
      if (draw) {
        map.current?.removeInteraction(draw);
      }
      if (modify) {
        map.current?.removeInteraction(modify);
      }
    };
  }, [draw, modify, map]);

  const toggleDrawPolygon = useCallback(() => {
    setDrawClicked(!draw);
    if (drawnSource.getFeatures().length > 0) {
      return;
    }

    if (draw) {
      map.current?.removeInteraction(draw);
      dispatch(changeClearButtonVisibilityState({ value: false }));
      setDraw(null);
      return;
    }

    if (!draw && map.current) {
      let layer = map.current
        ?.getLayers()
        .getArray()
        .find((layer: any) => layer.get("id") === "polygonLayer") as VectorLayer<VectorSource<any>>;

      if (!layer) {
        layer = new VectorLayer({
          source: drawnSource,
          style: new Style({
            stroke: new Stroke({
              color: "#D779FF",
              width: 2,
            }),
          }),
        });
        layer.set("id", "polygonLayer");
        map.current?.addLayer(layer);
      }

      drawnSource.clear();

      const newDraw = new Draw({
        source: drawnSource,
        type: "Polygon",
      });

      const newModify = new Modify({
        source: drawnSource,
      });

      const snap = new Snap({
        source: drawnSource,
      });

      map.current?.addInteraction(newDraw);
      map.current?.addInteraction(newModify);
      map.current?.addInteraction(snap);

      newDraw.on("drawend", (event: any) => {
        const feature = event.feature;
        feature.set("modifiable", true);
        setDrawnFeature(feature);
        setDraw(null);
        dispatch(changeClearButtonVisibilityState({ value: true }));
        dispatch(setPolygonCoordinatesValue({ value: true }));
        newModify.setActive(false);
      });

      setDraw(newDraw);
      setModify(newModify);
    }
  }, [draw, drawnSource, map, dispatch, setDraw, setDrawClicked]);

  const logPolygonCoordinates = (feature: Feature<Geometry>) => {
    const geometry = feature.getGeometry() as Polygon;
    const polygonCoordinates = geometry.getCoordinates();

    const pointsInsidePolygon: { name: string; coordinates: Coordinate }[] = [];

    const fileDetailsInsidePolygon: FilesDetailsState[] = [];
    for (const fileDetail of processedFiles) {
      const { fileXCoordinate, fileYCoordinate } = fileDetail;
      if (fileXCoordinate && fileYCoordinate) {
        const fileDetailCoords: Coordinate = [fileXCoordinate, fileYCoordinate];
        if (geometry.intersectsCoordinate(fileDetailCoords)) {
          fileDetailsInsidePolygon.push(fileDetail);
        }
      } else if (
        fileDetail.fileDataType === toolControls.BATHYMETRY ||
        fileDetail.fileDataType === toolControls.MODELGRIDMESH ||
        fileDetail.fileDataType === toolControls.INFRASTRUCTUREDRAWING ||
        fileDetail.fileDataType === toolControls.MODELRESULTSWAVE ||
        fileDetail.fileDataType === toolControls.MODELRESULTSHYDRO
      ) {
        fileDetailsInsidePolygon.push(fileDetail);
      }
    }

    fileDetailsInsidePolygon.map((files) => {
      const { file, ...rest } = files;

      const payload = {
        filename: file.name,
        ...rest,
      };
      dispatch(addNewFileDetailsInsidePolygon(payload));
    });
    dispatch(setPolygonCoordinates(polygonCoordinates[0]));
  };

  const clearSelection = useCallback(() => {
    setDrawClicked(false);
    if (drawnFeature) {
      drawnSource.removeFeature(drawnFeature);
      setDrawnFeature(null);
      dispatch(changeClearButtonVisibilityState({ value: false }));
      dispatch(clearPolygonCoordinates());
      dispatch(deleteFileDetailsInsidePolygon());
      dispatch(setPolygonCoordinatesValue({ value: false }));
    }
  }, [drawnFeature, drawnSource, setDrawnFeature, dispatch]);

  return (
    <>
      <Stack
        sx={{
          borderRadius: "4px",
          bgcolor: draw || clearButtonVisible ? "secondary.dark" : "#FFFFFF",
          "&:hover": {
            cursor: "pointer",
            bgcolor: "secondary.dark",
          },
        }}
      >
        <LightTooltip title="Draw Polygon" placement="right" enterDelay={500} leaveDelay={200}>
          <IconButton
            disableRipple
            onClick={toggleDrawPolygon}
            sx={{
              color: draw || clearButtonVisible ? "#FFFFFF" : "secondary.dark",
              "&:hover": {
                color: "#FFFFFF",
              },
              height: "32px",
              width: "32px",
            }}
          >
            <DrawPolygon fontSize="large" />
          </IconButton>
        </LightTooltip>
      </Stack>
      {clearButtonVisible && (
        <Box
          sx={{
            position: "absolute",
            top: "-11.5rem",
            right: "0rem",
            zIndex: "200",
            background: "white",
            color: "primary.main",
            width: "10rem",
            height: "2.5rem",
            "&:hover": {
              bgcolor: "#FFFFFF",
            },
            border: "3px solid #00A4EC",
            borderRadius: "4px",
          }}
          onClick={clearSelection}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1.25rem",
            }}
          >
            <IconButton color="primary" disableRipple sx={{ fontWeight: "700", mt: "-0.25rem" }}>
              <Close />
            </IconButton>
            <Button>
              <Typography m="0.5rem 0rem" mt="0.25rem" ml="-1.5rem" variant="body2" fontWeight="600">
                Clear Selection
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
