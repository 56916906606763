import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxActivateUploadSliceState {
  value: boolean;
}

const initialState: ToolboxActivateUploadSliceState = {
  value: false,
};

export const ToolboxActivateUploadSlice = createSlice({
  name: "ActivateUpload",
  initialState,
  reducers: {
    changeToolboxActivateUpload: (
      state,
      action: PayloadAction<ToolboxActivateUploadSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxActivateUploadSlice.reducer;

export const { changeToolboxActivateUpload } =
  ToolboxActivateUploadSlice.actions;
