import { Box, Typography, Button } from "@mui/material";

interface UploadProps {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Upload = ({ handleFileUpload }: UploadProps) => {
  return (
    <>
      <Box>
        <Typography variant="h1" textAlign="center" color="grey.500" mt="3rem">
          Select file here
        </Typography>
      </Box>

      <Box justifyContent="center" alignItems="center" display="flex" mt="1rem">
        <Typography variant="body2" color="secondary.dark" textAlign="center" width="10rem">
          Drag and drop or use the browse button below
        </Typography>
      </Box>

      <Box textAlign="center" mt="1rem">
        <Button variant="contained" component="label">
          <Typography variant="body2" fontWeight="700">
            Browse Files
          </Typography>
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>
      </Box>
    </>
  );
};
