import { useEffect, Dispatch, SetStateAction, useState } from "react";
import ReactECharts from "echarts-for-react";
import Papa from "papaparse";
import { Feature } from "ol";
import { Box, Typography } from "@mui/material";
import { getRawData, getTimeSeriesMDData, getTimeseriesMDDetails } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";

interface DataProps {
  data: { [key: string]: any[] };
  setData: Dispatch<SetStateAction<{ [key: string]: any[] }>>;
  harbours: Feature[] | null;
  dataAvailable: boolean;
  dataSource: string;
  port: any;
}

const SeaLevelRiseChart = ({ data, setData, harbours, dataAvailable, dataSource, port }: DataProps) => {
  const portname = new URLSearchParams(location.search).get("port");
  useEffect(() => {
    const fetchData = async (datasetId: string) => {
      try {
        const response = await getRawData(datasetId);
        const csvResponse = await fetch(response.data);
        return csvResponse.text();
      } catch (error) {
        console.error("Error during fetch:", error);
        return null;
      }
    };

    if (dataSource === "CAN-EWLAT Vertical Allowance")
      Promise.all([
        fetchData(publicDataSets.CanEwalt.Rpc85.id),
        fetchData(publicDataSets.CanEwalt.Rpc45.id),
        fetchData(publicDataSets.CanEwalt.Rpc26.id),
      ]).then(([csv1, csv2, csv3]) => {
        if (csv1 && csv2 && csv3) {
          setData({
            rcp85: Papa.parse(csv1, { header: true, dynamicTyping: true }).data,
            rcp45: Papa.parse(csv2, { header: true, dynamicTyping: true }).data,
            rcp26: Papa.parse(csv3, { header: true, dynamicTyping: true }).data,
          });
        }
      });
    if (dataSource === "CCCS Sea-Level Change") {
      const fetchCCCSDetails = async () => {
        try {
          const details = await getTimeseriesMDDetails(publicDataSets.SeaLevelRise.cccsDataset);
          if (details.items) {
            const filteredIndices = details.items
              .filter((item: any) => item.name.endsWith("p50"))
              .map((item: any) => item.i);

            const CCCSData = await getTimeSeriesMDData(
              publicDataSets.SeaLevelRise.cccsDataset,
              port.get("Longitude"),
              port.get("Latitude"),
              10,
              filteredIndices
            );
            setData({
              rcp85: CCCSData?.dataBlocks[2].data,
              rcp45: CCCSData?.dataBlocks[1].data,
              rcp26: CCCSData?.dataBlocks[0].data,
            });
          }
        } catch (error) {
          console.error("Error fetching dataset details:", error);
        }
      };
      fetchCCCSDetails();
    }
    if (dataSource === "NASA Sea-Level Change") {
      const fetchCCCSDetails = async () => {
        try {
          const details245 = await getTimeseriesMDDetails(publicDataSets.SeaLevelRise.nasa245Dataset);
          const details585 = await getTimeseriesMDDetails(publicDataSets.SeaLevelRise.nasa585Dataset);
          if (details245.items && details585.items) {
            const filtered245Indices = details245.items
              .filter((item: any) => item.name.endsWith("_0_5"))
              .map((item: any) => item.i);

            const filtered585Indices = details585.items
              .filter((item: any) => item.name.endsWith("_0_5"))
              .map((item: any) => item.i);

            const NASA245Data = await getTimeSeriesMDData(
              publicDataSets.SeaLevelRise.nasa245Dataset,
              port.get("Longitude"),
              port.get("Latitude"),
              13,
              filtered245Indices
            );
            const NASA585Data = await getTimeSeriesMDData(
              publicDataSets.SeaLevelRise.nasa585Dataset,
              port.get("Longitude"),
              port.get("Latitude"),
              13,
              filtered585Indices
            );

            setData({
              rcp85: NASA245Data.dataBlocks[0].data,
              rcp45: NASA585Data?.dataBlocks[0].data,
              rcp26: [],
            });
          }
        } catch (error) {
          console.error("Error fetching dataset details:", error);
        }
      };
      fetchCCCSDetails();
    }
  }, [dataSource]);

  const getOptions = () => {
    if (!data?.rcp85 || !data.rcp45 || !data.rcp26 || portname === null) {
      return {};
    }

    const dates = data.rcp85
      .map((item) => {
        const date = new Date(item["HARB_CODE"]);
        return date.getFullYear().toString();
      })
      .slice(0, -1);

    return {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data:
          dataSource === "CAN-EWLAT Vertical Allowance"
            ? ["RCP 8.5", "RCP 4.5", "RCP 2.6"]
            : dataSource === "CCCS Sea-Level Change"
            ? ["RCP 2.6 (50%)", "RCP 4.5 (50%)", "RCP 8.5 (50%)"]
            : ["SSP2-4.5 (50%)", "SSP5-8.5 (50%)", ""],
        left: 60,
        top: 20,
      },
      xAxis: {
        type: "category",
        data:
          dataSource === "CAN-EWLAT Vertical Allowance"
            ? dates
            : dataSource === "CCCS Sea-Level Change"
            ? ["2006", "2010", "2020", "2030", "2040", "2050", "2060", "2070", "2080", "2090", "2100"]
            : [
                "2020",
                "2030",
                "2040",
                "2050",
                "2060",
                "2070",
                "2080",
                "2090",
                "2100",
                "2110",
                "2120",
                "2130",
                "2140",
                "2150",
              ],
        name: "Year",
        nameLocation: "center",
        nameGap: 30,
        boundaryGap: false,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "black",
          },
        },
        axisLabel: {
          offset: -20,
        },
      },
      yAxis: {
        type: "value",
        name: "Sea Level Rise (m)",
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "black",
          },
        },

        nameLocation: "center",
        nameGap: 40,
      },
      series:
        dataSource === "CAN-EWLAT Vertical Allowance"
          ? [
              {
                name: "RCP 8.5",
                type: "line",
                data: data.rcp85.map((item) => item[portname]),
              },
              {
                name: "RCP 4.5",
                type: "line",
                data: data.rcp45.map((item) => item[portname]),
              },
              {
                name: "RCP 2.6",
                type: "line",
                data: data.rcp26.map((item) => item[portname]),
              },
            ]
          : dataSource === "CCCS Sea-Level Change"
          ? [
              {
                name: "RCP 2.6 (50%)",
                type: "line",
                data: data.rcp26,
              },
              {
                name: "RCP 4.5 (50%)",
                type: "line",
                data: data.rcp45,
              },
              {
                name: "RCP 8.5 (50%)",
                type: "line",
                data: data.rcp85,
              },
            ]
          : [
              {
                name: "SSP2-4.5 (50%)",
                type: "line",
                data: data.rcp85,
              },
              {
                name: "SSP5-8.5 (50%)",
                type: "line",
                data: data.rcp45,
              },
              {
                name: "",
                type: "line",
                data: data.rcp26,
              },
            ],
    };
  };
  const isAllNaN = (array: any) => array.every((value: any) => isNaN(parseFloat(value)));
  const allRcp85NaN = isAllNaN(data.rcp85);
  const allRcp45NaN = isAllNaN(data.rcp45);
  return (
    <>
      {portname &&
      (dataSource !== "CAN-EWLAT Vertical Allowance" ? !allRcp45NaN && !allRcp85NaN : true) &&
      (dataSource === "CAN-EWLAT Vertical Allowance" ? !dataAvailable : true) &&
      (dataSource === "CAN-EWLAT Vertical Allowance" ? data?.rcp85.map((item) => item[portname])[0] : true) ? (
        <ReactECharts option={getOptions()} />
      ) : (
        <Box width="100%" height="18rem">
          <Typography variant="h6" color="secondary.dark" display="flex" justifyContent="center" pt="15%">
            No Data Available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SeaLevelRiseChart;
