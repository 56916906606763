import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UploadSeasonalDataFiles, { useFileContext } from "./UploadSeasonalDataFiles";
import { RootState } from "../../../store/store";
import { SeasonalDataDescription } from "./SeasonalDataDescription.tsx/SeasonalDataDescription";
import { SeasonalImportOptions } from "./SeasonalImportOptions.tsx/SeasonalImportOptions";
import { changeToolboxProcessFileState } from "../../../features/ToolboxFeatures/ToolboxProcessFileSlice";
import { changeToolboxActivateUpload } from "../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { changeToolboxFileName } from "../../../features/ToolboxFeatures/ToolBoxFileName";
import { changeToolboxFileDescription } from "../../../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import { changeToolboxFileFormat } from "../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeDataCollectionDate } from "../../../features/ToolboxFeatures/DataCollectionDateSlice";
import { changeToolboxVerticalDatum } from "../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { SeasonalFilesContext } from "./SeasonalFilesContext";
import { v4 as uuidv4 } from "uuid";
import { changeSeasonalFileType } from "../../../features/ToolboxFeatures/SeasonalFileTypeSlice";
import { changeFileTimeZone } from "../../../features/DataRepoTimeZoneSlice";
import { addPoint } from "../../../features/mapPointsSlice";
import { changeDataRepositoryXCoordinate } from "../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../features/DataRepositoryYCoordinateSlice";
import {
  convertBathymetryUpload,
  convertUpload,
  createPersonalProject,
  getSubProjectList,
  uploadFiles,
} from "../../../api/backend";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import { publicDataSets } from "../../../api/init";
import JSZip from "jszip";
import {
  setToolboxFileUID,
  setToolboxUploadCompleted,
  setToolboxUploadPressed,
} from "../../../features/ToolboxFileStatus";
import { setFileUID, setUploadCompleted } from "../../../features/DataRepoUploadStatusSlice";

const steps = ["Upload", "Data Description", "Import Options"];

interface stepperProps {
  handleClose: () => void;
  files: File | null;
  setFiles: React.Dispatch<React.SetStateAction<File | null>>;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
  checked: boolean;
};

export type CheckedProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
};
export default function SeasonalDataStepper({ handleClose, files, setFiles, checked, setChecked }: stepperProps) {
  const seasonalFilesContext = React.useContext(SeasonalFilesContext);
  if (!seasonalFilesContext) {
    throw new Error("Component must be used within a ProcessedFilesProvider");
  }
  // const { seasonalFiles, setSeasonalFiles } = seasonalFilesContext;
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const { newFile, setNewFile } = useFileContext();
  const toolboxUpload = useSelector((state: RootState) => state.toolboxUpload.value);
  const [nextClicked, setNextClicked] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [processedFile, setProcessedFile] = useState<File | null>(null);

  const [errorLeftExists, setErrorLeftExists] = useState(false);
  const [errorRightExists, setErrorRightExists] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);

  const [leftValidate, setLeftValidate] = useState<() => boolean>(() => () => false);
  const [rightValidate, setRightValidate] = useState<() => boolean>(() => () => false);
  const { idTokenPayload } = useOidcIdToken();
  const toolboxRightsValue = useSelector((state: RootState) => state.toolboxRights.value);
  const fileformat = useSelector((state: RootState) => state.toolboxFileFormat.value);
  const filename = useSelector((state: RootState) => state.toolboxFileName.value);
  const dataType = useSelector((state: RootState) => state.seasonalFileType.value);
  const fileDescription = useSelector((state: RootState) => state.ToolboxFileDescription.value);
  const timezone = useSelector((state: RootState) => state.timeZone.value);
  const fileXCoordinate = useSelector((state: RootState) => state.xcoordinate.value);
  const fileYCoordinate = useSelector((state: RootState) => state.ycoordinate.value);
  const fileType = useSelector((state: RootState) => state.seasonalFileType.value);
  const fileCRS = useSelector((state: RootState) => state.toolboxCRS.CRS);
  const verticalDatum = useSelector((state: RootState) => state.toolboxVerticalDatum.value);
  const fileUID = useSelector((state: RootState) => state.dataRepoUploadStatus.fileUID);
  const fileUID2 = useSelector((state: RootState) => state.toolboxUploadStatus.fileUID);
  const handleNext = () => {
    if (activeStep === 1) {
      const errorRight = rightValidate();
      const errorLeft = leftValidate();

      setErrorLeftExists(errorLeft);
      setErrorRightExists(errorRight);

      if (errorLeft || errorRight) {
        setDisplayError(true);
        setShouldValidate(true);
        return;
      }

      if (fileformat === "LiDar Point Cloud (.las)" || fileformat === "Point Shapefile (.shp)") {
        dispatch(changeToolboxActivateUpload({ value: true }));
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (shouldValidate && fileType === "Water Level (Timeseries)") {
      const errorLeft = leftValidate();
      const errorRight = rightValidate();

      if (!errorRight && !errorLeft) {
        setDisplayError(false);
      }
    }
  }, [fileType]);
  useEffect(() => {
    if (fileType !== "Water Level (Timeseries)") {
      if (
        filename !== "" &&
        fileType !== "" &&
        fileformat !== "" &&
        fileDescription !== "" &&
        fileCRS.value !== "" &&
        fileXCoordinate !== null &&
        fileYCoordinate !== null &&
        timezone !== ""
      ) {
        setDisplayError(false);
      }
    } else {
      if (
        filename !== "" &&
        fileformat !== "" &&
        fileDescription !== "" &&
        fileCRS.value !== "" &&
        fileXCoordinate !== null &&
        fileYCoordinate !== null &&
        timezone !== "" &&
        verticalDatum !== ""
      ) {
        setDisplayError(false);
      }
    }
  }, [
    filename,
    fileType,
    fileformat,
    fileDescription,
    fileCRS,
    fileXCoordinate,
    timezone,
    fileYCoordinate,
    verticalDatum,
  ]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextClicked(false);
    if (activeStep === 1) {
      setDisplayError(false);
    }
  };

  const handleUpload = () => {
    handleClose();
    dispatch(setToolboxUploadPressed(true));
    setNewFile(null);
    dispatch(changeToolboxProcessFileState({ value: true }));
    dispatch(changeToolboxActivateUpload({ value: false }));
    setProcessedFile(null);
  };

  let projectIdBasedOnRights: string;
  if (checked) {
    if (toolboxRightsValue === "Public") {
      projectIdBasedOnRights = publicDataSets.PublicUserUpload.id;
    } else if (toolboxRightsValue === "Private") {
      projectIdBasedOnRights = publicDataSets.PrivateUserUpload.id;
    } else {
      console.error("Invalid toolboxRightsValue");
      return;
    }
  } else {
    projectIdBasedOnRights = publicDataSets.PrivateTemporaryTimeseries.id;
  }

  useEffect(() => {
    const performUpload = async () => {
      const subprojectlist = await getSubProjectList(projectIdBasedOnRights);
      let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

      if (!personalFolderId) {
        await createPersonalProject(idTokenPayload.oid, projectIdBasedOnRights);
        const updatedSubprojectList = await getSubProjectList(projectIdBasedOnRights);

        personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
      }

      if (processedFile && fileformat !== "") {
        let fileToUpload = processedFile;
        if (dataType.includes("(Timeseries)") && fileformat !== "MIKE DFS0 (.dfs0)") {
          const zip = new JSZip();
          zip.file(processedFile.name, processedFile);
          const zippedFileBlob = await zip.generateAsync({ type: "blob" });
          fileToUpload = new File([zippedFileBlob], `${processedFile.name.split(".")[0]}.zip`, {
            type: "application/zip",
          });
        }

        const updatedProcessedFile: ProcessedFile = {
          file: fileToUpload,
          rights: toolboxRightsValue,
          uid: uuidv4(),
          fileDescription: fileDescription,
          fileDataFormat: fileformat,
          fileDataType: dataType,
          fileCRS: fileCRS.value,
          checked: checked,
          fileCRSId: fileCRS.id,
          fileProj4String: fileCRS.proj4String,
          fileDatum: verticalDatum,
          fileSurveyDate: null,
          fileXCoordinate: fileXCoordinate,
          fileYCoordinate: fileYCoordinate,
          fileTimeZone: "N/A",
          fileSurfaceElevation: null,
          fileWkt: fileCRS.wkt,
        };

        const uploadResponse = await uploadFiles(fileToUpload);
        const convert = await convertBathymetryUpload(
          fileToUpload,
          uploadResponse.data,
          personalFolderId,
          updatedProcessedFile
        );
        if (convert.status === "Completed") {
          if (checked) {
            dispatch(setFileUID(convert.id));
            dispatch(setUploadCompleted(true));
          } else {
            dispatch(setToolboxUploadCompleted(true));
            dispatch(setToolboxFileUID(convert.id));
          }
        }

        if (checked) {
          const subprojectlist = await getSubProjectList(publicDataSets.PrivateTemporaryTimeseries.id);
          let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

          if (!personalFolderId) {
            await createPersonalProject(idTokenPayload.oid, publicDataSets.PrivateTemporaryTimeseries.id);
            const updatedSubprojectList = await getSubProjectList(publicDataSets.PrivateTemporaryTimeseries.id);
            personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
          }

          if (processedFile && fileformat !== "") {
            let fileToUpload = processedFile;
            if (dataType.includes("(Timeseries)") && fileformat !== "MIKE DFS0 (.dfs0)") {
              const zip = new JSZip();
              zip.file(processedFile.name, processedFile);
              const zippedFileBlob = await zip.generateAsync({ type: "blob" });
              fileToUpload = new File([zippedFileBlob], `${processedFile.name.split(".")[0]}.zip`, {
                type: "application/zip",
              });
            }
            const updatedProcessedFile: CheckedProcessedFile = {
              file: fileToUpload,
              rights: toolboxRightsValue,
              uid: uuidv4(),
              fileDescription: fileDescription,
              fileDataFormat: fileformat,
              fileDataType: dataType,
              fileCRS: fileCRS.value,
              fileCRSId: fileCRS.id,
              fileProj4String: fileCRS.proj4String,
              fileDatum: verticalDatum,
              fileSurveyDate: null,
              fileXCoordinate: fileXCoordinate,
              fileYCoordinate: fileYCoordinate,
              fileTimeZone: "N/A",
              fileSurfaceElevation: null,
              fileWkt: fileCRS.wkt,
            };

            const uploadResponse = await uploadFiles(fileToUpload);
            const convert = await convertUpload(
              fileToUpload,
              uploadResponse.data,
              personalFolderId,
              updatedProcessedFile
            );
            if (convert.status === "Completed") {
              dispatch(setToolboxUploadCompleted(true));
              dispatch(setToolboxFileUID(convert.id));
            }
          }
        }

        setProcessedFile(null);
        dispatch(changeToolboxFileName({ value: "" }));
        dispatch(changeToolboxFileDescription({ value: "" }));
        dispatch(changeToolboxFileFormat({ value: "" }));
        dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
        dispatch(changeToolboxVerticalDatum({ value: "" }));
        dispatch(changeSeasonalFileType({ value: "" }));
        dispatch(changeDataCollectionDate({ value: null }));
        dispatch(changeDataRepositoryXCoordinate({ value: null }));
        dispatch(changeDataRepositoryYCoordinate({ value: null }));
        dispatch(changeFileTimeZone({ value: "" }));
        dispatch(changeToolboxZNegative({ value: false }));
        setFiles(null);
      }
    };
    performUpload();
  }, [processedFile, toolboxRightsValue]);

  const handleCancel = () => {
    handleClose();
    setNewFile(null);
    setFiles(null);
    setChecked(false);
    dispatch(changeToolboxFileName({ value: "" }));
    dispatch(changeToolboxFileDescription({ value: "" }));
    dispatch(changeToolboxFileFormat({ value: "" }));
    dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
    dispatch(changeToolboxVerticalDatum({ value: "" }));
    dispatch(changeSeasonalFileType({ value: "" }));
    dispatch(changeDataCollectionDate({ value: null }));
    dispatch(changeDataRepositoryXCoordinate({ value: null }));
    dispatch(changeDataRepositoryYCoordinate({ value: null }));
    dispatch(changeFileTimeZone({ value: "" }));
    dispatch(changeToolboxZNegative({ value: false }));
  };
  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ m: "0rem 1rem" }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 && (
        <UploadSeasonalDataFiles
          file={files}
          setFile={setFiles}
          uploading={uploading}
          setUploading={setUploading}
          activeState={activeState}
          setActiveState={setActiveState}
          checked={checked}
          setChecked={setChecked}
        />
      )}
      {activeStep === 1 && (
        <SeasonalDataDescription
          setLeftValidate={setLeftValidate}
          setRightValidate={setRightValidate}
          nextClicked={nextClicked}
          setNextClicked={setNextClicked}
        />
      )}
      {activeStep === 2 && (
        <SeasonalImportOptions processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
      )}

      <Box display="flex" flexDirection="row" justifyContent="space-between" m="0.5rem 1rem 0rem 1rem">
        {activeStep === 1 && displayError ? (
          <Typography variant="body1" color="red">
            Please fill the required fields or enter the correct coordinates.
          </Typography>
        ) : (
          <Box></Box>
        )}
        <Stack direction="row">
          <Button onClick={activeStep == 0 ? handleCancel : handleBack} sx={{ mr: "1rem" }}>
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            onClick={activeStep === 2 ? handleUpload : handleNext}
            variant="contained"
            // disabled={activeStep === 0 || activeStep === 1 ? !activeState : !toolboxUpload}
          >
            {activeStep === steps.length - 1 ? "Upload" : "Next"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
