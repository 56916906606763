import { ToolboxMainPage } from "../ToolboxMainPage";
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeToolboxState } from "../../../features/ToolboxStateSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { SeasonalStatisticsTimeseries } from "./SeasonalStatisticsTimeseries";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SeasonalStatisticsInfoModal } from "./SeasonalStatisticsInfoModal";
import { SeasonalStatisticsTimePeriod } from "./SeasonalStatisticsTimePeriod";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import dayjs from "dayjs";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { RootState } from "../../../store/store";
import { createPersonalProject, getSubProjectList } from "../../../api/backend";
import { platform, publicDataSets } from "../../../api/init";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import { setTimeseriesGenerateStatisticsClicked } from "../../../features/ToolboxFeatures/SeasonalStatisticsSlice";
interface searchParamsProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

export const ToolBoxSeasonalStatistics = ({ searchParams, setSearchParams }: searchParamsProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [processResponse, setProcessResponse] = useState<any>(null);
  const [processLog, setProcessLog] = useState<string | null>(null);
  const jobId = useSelector((state: RootState) => state.seasonalToolbox.jobId);
  const [chartData, setChartData] = useState<any | null>(null);

  const handleClick = () => {
    setOpen(!open);
  };
  const [seasonalFiles, setSeasonalFiles] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [fileTimePeriod, setFileTimePeriod] = useState<{
    start: string;
    end: string;
  }>({ start: "", end: "" });
  const toolboxDataRights = useSelector((state: RootState) => state.toolboxRights.value);
  const handleInfoModalClose = () => setInfoModalOpen(false);

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };
  const { isAuthenticated } = useOidc();
  const { idTokenPayload } = useOidcIdToken();

  function safeParse(jsonString: any) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return { error: "Failed to parse JSON", detail: jsonString };
    }
  }

  useEffect(() => {
    if (seasonalFiles.length === 0) {
      setProcessLog(null);
      setChartData(null);
    }
  }, [seasonalFiles, chartData]);

  const getIndexName = () => {
    let xAxisLabel = "";
    if (processLog) {
      const parsedLog = safeParse(processLog);
      if (parsedLog && parsedLog.length > 0) {
        const firstLog = parsedLog[0];
        const { columns, index, data } = firstLog;

        if (index.some((item: any) => typeof item === "number" && item <= 12)) {
          xAxisLabel = "Months";
        } else if (index.includes("DJF") || index.includes("MAM") || index.includes("JJA") || index.includes("SON")) {
          xAxisLabel = "Seasonal";
        } else if (index.some((item: any) => typeof item === "number" && item > 12)) {
          xAxisLabel = "Annual";
        }
      }
    }
    return xAxisLabel;
  };

  useEffect(() => {
    if (processLog) {
      const parsedLog = safeParse(processLog);
      if (parsedLog && parsedLog.length > 0) {
        const firstLog = parsedLog[0];
        const { columns, index, data } = firstLog;
        const xAxisLabel = getIndexName();
        const seriesData = data.map((item: any) => item.slice(0, 5));
        const categories = index.map((item: any) => {
          if (typeof item === "number") {
            if (item <= 12) {
              return new Date(0, item - 1).toLocaleString("default", { month: "short" });
            } else {
              return item.toString();
            }
          }

          return item;
        });

        setChartData({
          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
          },
          xAxis: {
            type: "category",
            data: categories,
            axisTick: {
              alignWithLabel: true,
            },
            name: xAxisLabel,
            nameLocation: "middle",
            nameGap: 30,
          },
          yAxis: {
            type: "value",
            axisLine: { show: true },
            name: selectedValue,
            nameLocation: "middle",
            nameGap: 40,
          },

          series: [
            {
              name: "",
              type: "boxplot",
              data: seriesData,
              itemStyle: {
                color: "#00AAFF",
                borderColor: "#09334b",
                borderWidth: 2,
              },
            },
          ],
        });
      }
    }
  }, [processLog]);

  const getTableRows = () => {
    if (!processLog) return [];

    const parsedLog = safeParse(processLog);
    if (parsedLog && parsedLog.length > 0) {
      const { columns, index, data } = parsedLog[0];

      return data.map((rowData: number[], rowIndex: number) => {
        return {
          index: index[rowIndex],
          data: rowData,
        };
      });
    }
    return [];
  };

  const tableRows = getTableRows();

  useEffect(() => {
    if (!isAuthenticated) return;

    const projectIdBasedOnRights = checked
      ? toolboxDataRights === "Public"
        ? publicDataSets.PublicUserUpload.id
        : toolboxDataRights === "Private"
        ? publicDataSets.PrivateUserUpload.id
        : publicDataSets.PrivateTemporaryTimeseries.id
      : publicDataSets.PrivateTemporaryTimeseries.id;

    (async () => {
      try {
        const subprojectlist = await getSubProjectList(projectIdBasedOnRights);
        const hasPersonalFolder = subprojectlist.data.filter((p: any) => p.name === idTokenPayload.oid);
        if (hasPersonalFolder.length === 0) {
          await createPersonalProject(idTokenPayload.oid, projectIdBasedOnRights);
        }
      } catch (error) {
        console.error("Error in useEffect: ", error);
      }
    })();

    return () => {};
  }, [isAuthenticated, checked, toolboxDataRights]);

  const mapColumnHeadings = (columnName: any) => {
    const columnMappings: any = {
      "10th_Percentile": "10%",
      Median: "50%",
      "90th_Percentile": "90%",
      Number_of_Data_Points: "# points",
      Percent_Missing: "% Missing",
    };
    return columnMappings[columnName] || columnName;
  };

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setTimeseriesGenerateStatisticsClicked(false));
    }
  };

  const convertedSeasonalFiles = seasonalFiles.filter((file) => file.name.includes("(converted)"));

  const handleDownload = () => {
    const tableRows = getTableRows();

    const columnHeaders = [
      "Index",
      ...safeParse(processLog)[0].columns.map((column: any) => mapColumnHeadings(column)),
    ];

    const csvContent = [
      columnHeaders.join(","),
      ...tableRows.map((row: any) => [row.index, ...row.data].join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "StatisticsTable.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <ToolboxMainPage
          value={
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="row" gap="0.5rem">
                  <IconButton
                    sx={{
                      color: "secondary.dark",
                      height: "1rem",
                      width: "1rem",
                      mt: "1rem",
                      ml: "1rem",
                    }}
                    disableRipple
                    onClick={() => {
                      dispatch(changeToolboxState({ value: "toolbox" }));
                      searchParams.delete("q");
                      setSearchParams(searchParams);
                      stopInterpolation();
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Box mt="0.5rem">
                    <img src="/toolboxes/Seasonal stats.svg" alt="icon" style={{ width: "3rem", height: "2.5rem" }} />
                  </Box>
                  <Box mt="0.5rem" ml="-0.25rem">
                    <Typography variant="body1" fontWeight="700" color="secondary.dark">
                      Seasonal & Monthly statistics
                    </Typography>
                    <Typography variant="caption" color="grey.500">
                      Create annual, seasonal, or monthly statistics from a timeseries.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  mt="0.5rem"
                  mr="1rem"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleInfoModalOpen}
                >
                  <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.85rem">
                    Info
                  </Typography>
                  <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ height: "calc(100vh - 6.5rem)", overflowY: "auto", mb: "2rem" }}>
                <ListItemButton onClick={handleClick} sx={{ height: "2.5rem", mt: "0.5rem" }} disableRipple>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color="secondary.dark" fontWeight="700">
                        Inputs
                      </Typography>
                    }
                  />

                  <ExpandMore
                    style={{
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease-in-out",
                      color: "#09334B",
                    }}
                  />
                </ListItemButton>
                <Collapse in={open} timeout="auto">
                  <List component="div" disablePadding>
                    <SeasonalStatisticsTimeseries
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      fileTimePeriod={fileTimePeriod}
                      setFileTimePeriod={setFileTimePeriod}
                      checked={checked}
                      setChecked={setChecked}
                      seasonalFiles={seasonalFiles}
                      setSeasonalFiles={setSeasonalFiles}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                    <SeasonalStatisticsTimePeriod
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      fileTimePeriod={fileTimePeriod}
                      setFileTimePeriod={setFileTimePeriod}
                      seasonalFiles={convertedSeasonalFiles}
                      setSeasonalFiles={setSeasonalFiles}
                      processResponse={processResponse}
                      processLog={processLog}
                      setProcessResponse={setProcessResponse}
                      setProcessLog={setProcessLog}
                      setChartData={setChartData}
                    />
                  </List>
                </Collapse>

                <Divider
                  sx={{
                    mt: "0.5rem",
                    borderWidth: "1px",
                    borderColor: "grey.200",
                  }}
                />
                <Box
                  mt="0.5rem"
                  ml="1rem"
                  mb="1rem"
                  sx={{
                    height: open ? `calc(100vh - 35rem )` : "calc(100vh - 13rem)",
                  }}
                >
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box
                      display="flex"
                      flexDirection="row"
                      mt="0.25rem"
                      mr="1rem"
                      sx={{
                        "&:hover": {
                          cursor: "default",
                        },
                      }}
                    >
                      <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="-0.15rem">
                        Results
                      </Typography>
                      <LightTooltip
                        title="Monthly statistics
are provided for each month within the selected time period.
Seasonal statistics
are provided for Dec-Jan-Feb (DJF), Mar-Apr-May (MAM), June-July-Aug (JJA), and Sep-Oct-Nov (SON).
Annual statistics
are provided for each year within the selected time period. The number of data points and % missing data are also provided."
                        placement="right"
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <IconButton
                          sx={{
                            color: "secondary.dark",
                            "&:hover": { cursor: "pointer" },
                            mt: "-0.75rem",
                          }}
                          disableRipple
                        >
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </LightTooltip>
                    </Box>
                    <Box display="flex" flexDirection="row">
                      <Box
                        display="flex"
                        flexDirection="row"
                        mt="0.25rem"
                        mr="1rem"
                        sx={{
                          "&:hover": {
                            cursor: chartData ? "pointer" : "default",
                          },
                        }}
                        onClick={handleDownload}
                      >
                        <IconButton
                          sx={{
                            color: "secondary.dark",
                            "&:hover": { cursor: "default" },
                            mt: "-0.75rem",
                          }}
                          disableRipple
                          disabled={!chartData}
                        >
                          <DownloadOutlinedIcon />
                        </IconButton>
                        <Typography
                          variant="body1"
                          color={chartData ? "secondary.dark" : "#00000042"}
                          fontWeight="700"
                          mt="-0.15rem"
                        >
                          Download
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {chartData && seasonalFiles.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #CFDBE2",
                        borderRadius: "10px",
                        m: "1rem",
                        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "secondary.dark",
                          display: "flex",
                          justifyContent: "center",
                          mt: "0.5rem",
                          fontWeight: 700,
                        }}
                      >
                        Statistics Chart
                      </Typography>
                      <ReactECharts
                        option={chartData}
                        style={{ height: "400px", width: "100%" }}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                      />
                    </Box>
                  )}
                  {chartData && seasonalFiles.length > 0 && (
                    <Box
                      sx={{
                        border: "1px solid #CFDBE2",
                        borderRadius: "10px",
                        m: "1rem",
                        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "secondary.dark",
                          display: "flex",
                          justifyContent: "center",
                          mt: "0.5rem",
                          fontWeight: 700,
                        }}
                      >
                        Statistics Table
                      </Typography>
                      <Divider />
                      <TableContainer component={Paper} sx={{ width: "auto", p: "1rem" }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ color: "#09334B", borderBottom: "2px solid #09334B" }}>
                                {getIndexName()}
                              </TableCell>
                              {processLog &&
                                safeParse(processLog)[0].columns.map((column: any, i: any) => (
                                  <TableCell
                                    key={i}
                                    align="center"
                                    sx={{ color: "#09334B", borderBottom: "2px solid #09334B" }}
                                  >
                                    {mapColumnHeadings(column)}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableRows.map((row: any, i: number) => (
                              <TableRow
                                key={i}
                                sx={{ "& > td": { color: "#09334B", borderBottom: "2px solid #09334B" } }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ color: "#09334B", borderBottom: "2px solid #09334B" }}
                                >
                                  {row.index}
                                </TableCell>
                                {row.data.map((cell: any, j: any) => (
                                  <TableCell key={j} align="center">
                                    {cell}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          }
        />

        <SeasonalStatisticsInfoModal open={infoModalOpen} handleClose={handleInfoModalClose} />
      </Box>
    </LocalizationProvider>
  );
};
