import { Stack } from "@mui/material";
import { Profile, WaterLevel, Wind, Ice, Wave, Turbidity } from "@dhi/icons/dist";
import { ControlButtonLarge } from "./ControlButtonLarge";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import {
  BathymetryGroup,
  BathymetryGroup10m,
  IceCoverGroupA,
  IceCoverGroupB,
  SeaLevelRiseGroupA,
  SeaLevelRiseGroupB,
  WaterLevelGroup,
  WaveGroup,
  WindGroup,
} from "../Openlayers/default_groups_layers";
import { toolControls } from "../Openlayers/data_layers";

export const ToolsIcons = () => {
  const clearButtonVisibility = useSelector((state: RootState) => state.clearButton.value);

  const params = new URLSearchParams(window.location.search);
  const tool = params.get("tool");

  useEffect(() => {
    if (tool === toolControls.BATHYMETRY) {
      BathymetryGroup.setVisible(true);
      BathymetryGroup10m.setVisible(true);
    }
    if (tool === toolControls.WAVEHEIGHT) {
      WaveGroup.setVisible(true);
    }
    if (tool === toolControls.WIND) {
      WindGroup.setVisible(true);
    }
    if (tool === toolControls.SEALEVELRISE) {
      SeaLevelRiseGroupA.setVisible(true);
    }
    if (tool === toolControls.ICECOVER) {
      IceCoverGroupA.setVisible(true);
    }
    if (tool === toolControls.WATERLEVEL) {
      WaterLevelGroup.setVisible(true);
    }
    return () => {
      BathymetryGroup.setVisible(false);
      BathymetryGroup10m.setVisible(false);
      WaveGroup.setVisible(false);
      SeaLevelRiseGroupA.setVisible(false);
      SeaLevelRiseGroupB.setVisible(false);
      IceCoverGroupA.setVisible(false);
      IceCoverGroupB.setVisible(false);
      WaterLevelGroup.setVisible(false);
      WindGroup.setVisible(false);
    };
  }, [tool]);

  return (
    <Stack
      direction="column"
      spacing="0rem"
      sx={{
        bgcolor: "white",
        borderRadius: "4px",
        position: "absolute",
        top: clearButtonVisibility === true ? "18rem" : window.location.pathname !== "/repository" ? "12rem" : "15rem",
        right: "2rem",
        boxShadow: "0px 2px 4px -1px rgba(9, 51, 75, 0.5)",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <ControlButtonLarge name={toolControls.BATHYMETRY} icon={<Profile fontSize="large" />} />
      <ControlButtonLarge name={toolControls.WAVEHEIGHT} icon={<Wave fontSize="large" />} />
      <ControlButtonLarge name={toolControls.WIND} icon={<Wind fontSize="large" />} />
      <ControlButtonLarge name={toolControls.WATERLEVEL} icon={<WaterLevel fontSize="large" />} />
      <ControlButtonLarge name={toolControls.ICECOVER} icon={<Ice fontSize="large" />} />
      <ControlButtonLarge name={toolControls.SEALEVELRISE} icon={<Turbidity fontSize="large" />} />
    </Stack>
  );
};
