import { Box, Typography, Button } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { UploadModal } from "../UploadData/UploadModal";
import { changeToolboxActivateUpload } from "../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImportFromDataRepositoryModal } from "../ImportFromDataRepository/ImportFromDataRepositoryModal";
import { useOidc } from "@axa-fr/react-oidc";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface BathymetricProps {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  generateStatisticsClicked: boolean;
}

export const BathymetricData = ({ checked, setChecked, generateStatisticsClicked }: BathymetricProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openImportDataRepo, setOpenImportDataRepo] = useState(false);
  const { isAuthenticated } = useOidc();
  const handleDataRepoOpen = () => {
    setOpenImportDataRepo(true);
  };
  const handleDataRepoClose = () => {
    setOpenImportDataRepo(false);
  };
  const handleOpen = () => {
    setOpen(true);
    dispatch(changeToolboxActivateUpload({ value: false }));
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" mt="1rem">
      <Box ml="1rem" display="flex" flexDirection="column" gap="0rem">
        <Typography variant="body2" color="secondary.dark" fontWeight="700">
          Bathymetric Data
        </Typography>
        <Typography variant="caption" color="grey.500">
          Max upload size limit: 500MB.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap="1rem" mr="1rem">
        <LightTooltip
          title="Login to use the toolbox"
          placement="left"
          enterDelay={500}
          leaveDelay={200}
          disableHoverListener={!!isAuthenticated}
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              component="label"
              onClick={handleOpen}
              disabled={!isAuthenticated || generateStatisticsClicked}
            >
              <Typography fontWeight="700" variant="body2">
                Upload from your device
              </Typography>
            </Button>
          </span>
        </LightTooltip>
        <LightTooltip
          title="Login to use the toolbox"
          placement="left"
          enterDelay={500}
          leaveDelay={200}
          disableHoverListener={!!isAuthenticated}
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleDataRepoOpen}
              disabled={!isAuthenticated || generateStatisticsClicked}
            >
              <Typography fontWeight="700" variant="body2">
                Import from data repository
              </Typography>
            </Button>
          </span>
        </LightTooltip>
      </Box>
      <UploadModal open={open} handleClose={handleClose} checked={checked} setChecked={setChecked} />
      <ImportFromDataRepositoryModal open={openImportDataRepo} handleClose={handleDataRepoClose} type="bathymetry" />
    </Box>
  );
};
