import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxFileDescriptionSliceState {
  value: string;
}

const initialState: ToolboxFileDescriptionSliceState = {
  value: "",
};

export const ToolboxFileDescriptionSlice = createSlice({
  name: "Description",
  initialState,
  reducers: {
    changeToolboxFileDescription: (
      state,
      action: PayloadAction<ToolboxFileDescriptionSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxFileDescriptionSlice.reducer;

export const { changeToolboxFileDescription } =
  ToolboxFileDescriptionSlice.actions;
