import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNameOfUser } from "../api/backend_public";

interface User {
  email: string;
  id: string;
  name: string;
  role: string;
  type: string;
}

interface UserNamesState {
  data: User[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
}

const initialState: UserNamesState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchUserNames = createAsyncThunk("userNames/fetchUserNames", async () => {
  const response = await getNameOfUser();
  return response.data;
});

const userNamesSlice = createSlice({
  name: "userNames",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserNames.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserNames.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserNames.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default userNamesSlice.reducer;
