import { Box, Typography, IconButton, Divider, SelectChangeEvent, Link } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { useNavigate } from "react-router-dom";
import { useOpenlayersContext } from "../../Openlayers/OpenlayersContext";
import { useEffect, useMemo, useState } from "react";
import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { toolControls } from "../../Openlayers/data_layers";
import { Icon, Style } from "ol/style";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { get as getProjection, transform } from "ol/proj";
import { getMetadata, getTimeSeriesList, getTimeSeriesValues } from "../../../api/backend_public";
import { Select, MenuItem } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import DropdownSelect from "../../MapViewer/DropdownSelect";

function convert(str: string) {
  var date = new Date(str);
  return `${date.getDate()} ${new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(date)} ${date.getFullYear()}`;
}

interface Mapping {
  [key: string]: string;
}

interface YAxisProps {
  headers: string[];
  selectedYAxis: string;
  onYAxisChange: any;
}

export function getIconStyleByDataType(datatype: string, name: string) {
  let iconSrc;
  const useHoverIcon = false;
  switch (datatype) {
    case toolControls.BATHYMETRY:
      iconSrc = useHoverIcon ? "icons/Bathymetry_hover.svg" : "icons/Bathymetry.svg";
      break;
    case toolControls.WINDTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Winds_hover.svg" : "icons/Winds.svg";
      break;
    case toolControls.WAVESTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Waves_hover.svg" : "icons/Waves.svg";
      break;
    case toolControls.WATERLEVELTIMESEIRES:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    case toolControls.CURRENTSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Currents_hover.svg" : "icons/Currents.svg";
      break;
    case toolControls.DISCHARGETIMESERIES:
      iconSrc = useHoverIcon ? "icons/Discharge_hover.svg" : "icons/Discharge.svg";
      break;
    case toolControls.ICETHICKNESSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Ice_hover.svg" : "icons/Ice.svg";
      break;
    case toolControls.MODELRESULTSWAVE:
      iconSrc = useHoverIcon ? "icons/Wave_hover.svg" : "icons/Wave.svg";
      break;
    case toolControls.INFRASTRUCTUREDRAWING:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MISCFREEFORMAT:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MODELGRIDMESH:
      iconSrc = useHoverIcon ? "icons/Mesh_hover.svg" : "icons/Mesh.svg";
      break;
    case toolControls.MODELRESULTSHYDRO:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    default:
      iconSrc = useHoverIcon ? "icons/Borehole_hover.svg" : "icons/Borehole.svg";
      break;
  }
  return new Style({
    image: new Icon({
      src: iconSrc,
      scale: 0.9,
    }),
  });
}

const YAxisSelect = ({ headers, selectedYAxis, onYAxisChange }: YAxisProps) => {
  const options = headers
    .filter((header) => header !== "Date/Time")
    .map((header) => ({
      label: header,
      value: header,
    }));
  return <DropdownSelect label="Variable" value={selectedYAxis} onChange={onYAxisChange} options={options} />;
};

export const DataRepoFileDetails = () => {
  const map = useOpenlayersContext();
  const [fileValue, setFileValue] = useState(() => {
    const fileDetailsString = localStorage.getItem("fileDetails");
    return fileDetailsString ? JSON.parse(fileDetailsString) : null;
  });
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedYAxis, setSelectedYAxis] = useState<string>("");
  const [isMapCentered, setIsMapCentered] = useState(false);
  const [idNameMapping, setIdNameMapping] = useState<Mapping>({});
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [size, setSize] = useState<number | null>(null);

  useEffect(() => {
    if (fileValue.fileDataFormat === "") {
      const fileMetadata = async () => {
        const result = await getMetadata(fileValue.id);
        setFileValue((prevDetails: any) => ({
          ...prevDetails,
          fileCRS: result.spatialInformation.srid,
        }));
      };
      fileMetadata();
    }
  }, []);

  useEffect(() => {
    if (fileValue && fileValue.filename.includes("(converted)") && fileValue.fileDataFormat === "Timeseries Storage") {
      const getList = async () => {
        const result = await getTimeSeriesList(fileValue.id);
        if (result && result.data) {
          const mapping: Mapping = {};
          const newHeaders: string[] = [];
          result.data.forEach((item: any) => {
            const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(item.id);
            if (isUUID) {
              mapping[item.id] = item.item.name;
              newHeaders.push(item.item.name);
            } else {
              mapping[item.id] = item.id;
              newHeaders.push(item.id);
            }
          });
          setIdNameMapping(mapping);
          setHeaders(newHeaders);
        }
      };
      getList();
    }
  }, []);

  useEffect(() => {
    if (map?.current) {
      const layersToRemove: any[] = [];
      map.current.getLayers().forEach((layer) => {
        const layerId = layer.get("id");
        if (layerId && layerId.startsWith("gislayers")) {
          layersToRemove.push(layer);
        }
      });

      layersToRemove.forEach((layer) => map?.current?.removeLayer(layer));
    }
  }, [map]);

  useEffect(() => {
    if (fileValue) {
      const fetchMetadata = async () => {
        const metadata = await getMetadata(fileValue.id);
        setSize(metadata.storageSize);
      };
      fetchMetadata();
    }
  }, []);

  useEffect(() => {
    if (headers.length > 0) {
      const firstYAxisHeader = headers.find((header) => header !== "Date/Time") || "defaultHeader";
      setSelectedYAxis(firstYAxisHeader);
    }
  }, [headers]);

  const fetchAndSetTimeSeriesData = async (headerName: any) => {
    if (fileValue.filename.includes("(converted)")) {
      const selectedId = Object.keys(idNameMapping).find((key) => idNameMapping[key] === headerName);
      if (selectedId && fileValue) {
        try {
          const timeSeriesValues = await getTimeSeriesValues(fileValue.id, selectedId);
          setTimeSeriesData(timeSeriesValues.data[0]);
        } catch (error) {}
      }
    }
  };

  const handleYAxisChange = (event: SelectChangeEvent) => {
    event.stopPropagation();
    const headerName = event.target.value as string;
    setSelectedYAxis(headerName);
    fetchAndSetTimeSeriesData(headerName);
  };

  useEffect(() => {
    if (headers.length > 0) {
      const firstYAxisHeader = headers.find((header) => header !== "Date/Time") || "defaultHeader";
      setSelectedYAxis(firstYAxisHeader);
      fetchAndSetTimeSeriesData(firstYAxisHeader);
    }
  }, [headers]);

  const getTimeSeriesOption = useMemo(() => {
    return {
      tooltip: {
        trigger: "axis",
        formatter: function (params: any) {
          const date = params[0].axisValueLabel;
          const value = params[0].data[1];
          return `Date/Time: ${date}<br/>Value: ${value}`;
        },
      },
      xAxis: {
        type: "category",
        data: timeSeriesData.map((item) => item[0]),
        name: "Date/Time [UTC]",
        nameLocation: "middle",
        nameGap: 40,
      },
      yAxis: {
        type: "value",
        name: selectedYAxis,
        nameLocation: "middle",
        nameGap: 40,
        nameRotate: 90,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#333",
          },
        },
        scale: true,
      },
      series: [
        {
          data: timeSeriesData.map((item) => [item[0], item[1]]),
          type: "line",
        },
      ],
    };
  }, [timeSeriesData, selectedYAxis]);

  useEffect(() => {
    if (
      !isMapCentered &&
      fileValue &&
      fileValue.fileXCoordinate !== null &&
      fileValue.fileYCoordinate !== null &&
      map?.current
    ) {
      let proj4String;
      if (fileValue.fileProj4String) {
        proj4String = fileValue.fileProj4String;
      } else if (fileValue.fileCRS) {
        const match = fileValue.fileCRS.match(/proj4String:(.+)/);
        if (match && match.length > 1) {
          proj4String = match[1].trim();
        }
      }
      const customEPSGCode = `CUSTOM:${fileValue.id}`;

      if (proj4String) {
        proj4.defs(customEPSGCode, proj4String);
        register(proj4);
      }

      const view = map.current.getView();
      const transformedCoordinates = transform(
        [fileValue.fileXCoordinate, fileValue.fileYCoordinate],
        customEPSGCode,
        view.getProjection()
      );

      view.animate({
        center: transformedCoordinates,
        zoom: 10,
        duration: 1000,
      });
      setIsMapCentered(true);
    }
  }, [fileValue, isMapCentered]);

  const navigate = useNavigate();
  if (!fileValue) {
    return <Box>No file details available.</Box>;
  }

  const removeFileFromSearchParam = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("dataset");
    navigate(`${location.pathname}?${queryParams}`);
  };

  const addBathymetryIconToMap = (xCoordinate: number, yCoordinate: number, id: string) => {
    if (map?.current) {
      let proj4String;
      if (fileValue.fileProj4String) {
        proj4String = fileValue.fileProj4String;
      } else if (fileValue.fileCRS) {
        const match = fileValue.fileCRS.match(/proj4String:(.+)/);
        if (match && match.length > 1) {
          proj4String = match[1].trim();
        }
      }
      if (!proj4String) {
        return;
      }
      const customEPSGCode = `CUSTOM:${id}`;

      if (proj4String) {
        proj4.defs(customEPSGCode, proj4String);
        register(proj4);
      }

      const transformedCoordinates = transform(
        [xCoordinate, yCoordinate],
        customEPSGCode,
        map.current.getView().getProjection()
      );

      const iconFeature = new Feature({
        geometry: new Point(transformedCoordinates),
      });
      iconFeature.set("featureId", id, true);

      const iconStyle = getIconStyleByDataType(fileValue.fileDataType, fileValue.name);
      iconFeature.setStyle(iconStyle);

      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      vectorLayer.set("id", `bathymetryIconLayer${id}`);
      map.current.addLayer(vectorLayer);
    }
  };

  const checkAndAddIcon = () => {
    if (map?.current && fileValue?.fileXCoordinate !== null && fileValue?.fileYCoordinate !== null) {
      const layerId = `bathymetryIconLayer${fileValue.id}`;
      const existingLayer = map.current
        .getLayers()
        .getArray()
        .find((layer) => layer.get("id") === layerId);

      if (!existingLayer) {
        addBathymetryIconToMap(fileValue.fileXCoordinate, fileValue.fileYCoordinate, fileValue.id);
      }
    }
  };

  useEffect(() => {
    checkAndAddIcon();
  }, [fileValue]);

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box>
      <Box
        m="0.75rem 1rem"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column" gap="-0.5rem">
          <Typography variant="h2" color="secondary.dark" fontWeight="700">
            {fileValue.filename === "" ? fileValue.name : fileValue.filename}
          </Typography>
          <Typography variant="caption" color="#86A2B3">
            {fileValue.fileDescription}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row">
          {fileValue.datasetUrl && (
            <Link
              href={fileValue.datasetUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Box display="flex" flexDirection="row">
                <IconButton color="primary" sx={{ mt: "0.3rem" }} disableRipple>
                  <GetAppOutlinedIcon />
                </IconButton>
                <Typography variant="button" color="primary" fontWeight="700" mt="0.85rem">
                  Download
                </Typography>
              </Box>
            </Link>
          )}
          <IconButton
            sx={{ color: "secondary.dark", mt: "0.3rem", ml: "0.5rem" }}
            disableRipple
            onClick={removeFileFromSearchParam}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Box height="calc(100vh - 6.5rem)" overflow="auto">
        <Box m="1rem">
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            Data Configuration
          </Typography>
        </Box>
        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Data type
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileDataType}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Data format
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileDataFormat !== "" ? fileValue.fileDataFormat : capitalizeFirstLetter(fileValue.datasetType)}
          </Typography>
        </Box>
        <Divider />
        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Data size (MB)
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {size && size / 1000}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Privacy
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.rights}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem">
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            Data description
          </Typography>
        </Box>
        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Data collection date
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileSurveyDate ? convert(fileValue.fileSurveyDate?.toLocaleString()) : "N/A"}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Vertical datum
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileDatum !== "" ? fileValue.fileDatum : "N/A"}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary.dark">
            Coordinate reference system
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileCRS && typeof fileValue.fileCRS !== "number"
              ? fileValue.fileCRS.split("-proj4String:")[0]
              : `EPSG : ${fileValue.fileCRS}`}
          </Typography>
        </Box>
        <Divider />

        <Box m="1rem">
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            Data description
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" m="1rem">
          <Typography variant="body2" color="secondary.dark">
            X-Coordinate and Y-Coordinate
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {`${fileValue.fileXCoordinate}  and  ${fileValue.fileYCoordinate}`}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="row" justifyContent="space-between" m="1rem">
          <Typography variant="body2" color="secondary.dark">
            Time zone
          </Typography>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            {fileValue.fileTimeZone !== "" ? fileValue.fileTimeZone : "N/A"}
          </Typography>
        </Box>
        <Divider />
        {fileValue.data && fileValue.data === "publicData" && (
          <Box m="1rem">
            <Typography justifyContent="center" mt="0.5rem" variant="body1" color="grey.500">
              Data Warning
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              System data is currently only available for download (note, key datasets are is also available for display
              through the ribbon, and directly linked to harbours through the Dashboard).
            </Typography>
            <Typography justifyContent="center" mt="1rem" variant="body2" color="grey.500">
              For Phase 3, planned development includes:
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              (1) Display of system data by dataset.
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              (2) Inclusion of additional metadata.
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              (3) Quickview of system data.
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              (4) Display of icons within the map viewer.
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              (5) Zoom to functionality.
            </Typography>
            <Typography justifyContent="center" mt="0.5rem" variant="body2" color="grey.500">
              These functionalities have already been implemented on user uploaded data for Phase 2.
            </Typography>
          </Box>
        )}
        {fileValue.filename.includes("(converted)") && fileValue.fileDataFormat === "Timeseries Storage" && (
          <Box borderRadius="50px" boxShadow="1px 4px 8px 0px rgba(9, 51, 75, 0.5)" m="1rem 2rem" height="23rem">
            <Box display="flex" justifyContent="center">
              <Typography color="secondary.dark" fontWeight="700" pt="1rem">
                Quickview of Timeseries
              </Typography>

              <LightTooltip
                title={
                  <>
                    <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body1">
                      Data Warning
                    </Typography>
                    <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                      This Data was uploaded by a user of the CCZIS. Data uploaded by users is not quality controlled
                      and may contain errors.
                    </Typography>
                    <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                      Any use of this data or reliance on its content by third parties is the responsibility of the
                      third party.
                    </Typography>
                  </>
                }
                placement="right"
                enterDelay={300}
                leaveDelay={200}
              >
                <IconButton sx={{ color: "secondary.dark", mt: "0.35rem" }} disableRipple>
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box ml="2rem" mt="1rem" display="flex" flexDirection="row">
              <Typography color="secondary.dark" fontWeight="700" mt="0.5rem" mr="0.5rem" variant="body2">
                Variable :{" "}
              </Typography>
              <Box zIndex="1000">
                <YAxisSelect
                  headers={headers}
                  selectedYAxis={selectedYAxis}
                  onYAxisChange={(e: SelectChangeEvent) => handleYAxisChange(e)}
                />
              </Box>
            </Box>

            {timeSeriesData.length > 0 && (
              <Box mt="-2rem">
                <ReactECharts option={getTimeSeriesOption} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
