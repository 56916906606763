import { Box, Button, Typography } from "@mui/material";
import { DataRepositorySearchbar } from "./DataRepositorySearchBar";
import { AddDataButton } from "./AddDataButton";
import { Dispatch, SetStateAction } from "react";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface HeaderProps {
  setRefreshButton: Dispatch<SetStateAction<boolean>>;
}

export const DataRepoHeader = ({ setRefreshButton }: HeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        m: "0rem 1rem",
      }}
    >
      <Box>
        <DataRepositorySearchbar />
      </Box>
      <Box display="flex" flexDirection="row" gap="1rem">
        <Box mt="1.5rem">
          <Button startIcon={<RefreshOutlinedIcon />} onClick={() => setRefreshButton(true)}>
            Refresh
          </Button>
        </Box>
        <AddDataButton />
      </Box>
    </Box>
  );
};
