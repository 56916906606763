import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { changeDataRepositorySearchInput } from "../../features/DataRepositorySearchbarSlice";

export const DataRepositorySearchbar = () => {
  const dispatch = useDispatch();
  const input = useSelector(
    (state: RootState) => state.dataRepoSearchBar.value
  );
  const handleChange = (value: string) => {
    dispatch(changeDataRepositorySearchInput({ value: value }));
  };
  return (
    <>
      <TextField
        type="search"
        id="search"
        placeholder="What are you looking for?"
        color="secondary"
        size="small"
        autoComplete="off"
        sx={{
          width: 368,
          bgcolor: "#fff",
          top: "1rem",
          left: "1rem",
          position: "absolute",
          input: { cursor: "pointer" },
          zIndex: 100,
          borderRadius: "90px",
          boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
          border: "0.5px solid #86A2B3",
          "& fieldset": { border: "none" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};
