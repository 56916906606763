import {
  Box,
  Typography,
  IconButton,
  Stack,
  FormControlLabel,
  Checkbox,
  Radio,
  SelectChangeEvent,
} from "@mui/material";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Dispatch, SetStateAction, useContext } from "react";
import { Document } from "@dhi/icons/dist";
import { UploadFiles } from "./UploadFiles";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DropdownSelect from "../../DropDownList/DropDownSelect";
import { FileContext } from "../ToolboxContext/FileContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { changeToolboxDataRights } from "../../../features/ToolboxFeatures/ToolboxDataRightsSlice";
import { changeToolboxFileName } from "../../../features/ToolboxFeatures/ToolBoxFileName";
import { changeToolboxFileDescription } from "../../../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import { changeToolboxFileFormat } from "../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeToolboxVerticalDatum } from "../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { changeDataCollectionDate } from "../../../features/ToolboxFeatures/DataCollectionDateSlice";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { BathymetryInfoModal } from "./BathymetryInfoModal";

interface filesProps {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  uploading: boolean;
  activeState: boolean;
  setActiveState: Dispatch<SetStateAction<boolean>>;
  setUploading: Dispatch<SetStateAction<boolean>>;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

export function useFileContext() {
  const context = useContext(FileContext);

  if (!context) {
    throw new Error("useFileContext must be used within a FileProvider");
  }

  return context;
}
const FILE_SIZE_LIMIT = 500 * 1024 * 1024;
const UploadDataFiles = ({
  file,
  setFile,
  uploading,
  setUploading,
  activeState,
  setActiveState,
  checked,
  setChecked,
}: filesProps) => {
  const { newFile, setNewFile } = useFileContext();
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState<string | null>(null);
  const { idTokenPayload } = useOidcIdToken();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  const checkFileSize = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      setFileSizeError(true);
      setUploading(false);
      return false;
    }
    setFileSizeError(false);
    return true;
  };
  const handleOpenClick = () => {
    setOpen(true);
  };
  const [groupSelection, setGroupSelection] = useState("");
  const value = useSelector((state: RootState) => state.toolboxRights.value);

  const handleGroupSelectionValue = (event: SelectChangeEvent) => {
    setGroupSelection(event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeToolboxDataRights({ value: event.target.value }));
  };

  const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
    setChecked(checked);
    if (checked === false) {
      dispatch(changeToolboxDataRights({ value: "Public" }));
    }
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const droppedFile = droppedFiles[0];

    if (droppedFile && checkFileSize(droppedFile)) {
      setUploading(true);
      setNewFile(droppedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploading(false);
        setFileData(e.target?.result as string);

        setActiveState(true);
      };
      reader.readAsText(droppedFile);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile && checkFileSize(selectedFile)) {
      setUploading(true);
      setNewFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploading(false);
        setFileData(e.target?.result as string);

        setActiveState(true);
      };
      reader.readAsText(selectedFile);
    }
  };

  const readUploadedFile = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === "string") {
          setFileData(e.target.result);
        }
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    if (newFile) {
      setFile(newFile);
    }
  }, [newFile]);

  useEffect(() => {
    readUploadedFile(file);
  }, [file]);

  return (
    <Box>
      {uploading ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "23rem 1fr",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ overflowY: "auto", overflowX: "hidden", height: "16rem" }}>
            <Box mt="1rem" ml="2rem">
              <Typography variant="body2" color="secondary.dark" fontWeight="700">
                Uploaded data
              </Typography>
            </Box>

            <Box>
              <Box ml="2rem">
                <Stack direction="row" spacing="1rem">
                  <CircularProgress size="1.25rem" sx={{ mt: "1rem" }} />
                  <Box>
                    <Typography variant="body2" color="secondary.dark" mt="1.25rem">
                      {newFile?.name}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        newFile && (
          <Box sx={{ overflowY: "auto", overflowX: "hidden", height: "16rem" }}>
            <Box m="1rem 0rem 0rem 2rem">
              <Typography variant="body2" color="secondary.dark" fontWeight="700">
                Uploaded data
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box>
                <Box mb="-1rem" ml="2rem">
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      xs: "2rem 24rem 1fr",
                      sm: "2rem 22rem 1fr",
                      md: "2rem 28rem 1fr",
                      lg: "2rem 34.75rem 1fr",
                    }}
                    mb="0.5rem"
                  >
                    <Box ml="-1rem">
                      <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                        <Document fontSize="large" />
                      </IconButton>
                    </Box>

                    <Typography variant="body2" color="secondary.dark" mt="1.2rem">
                      {newFile?.name}
                    </Typography>

                    <IconButton
                      disableRipple
                      sx={{ color: "secondary.dark" }}
                      onClick={() => {
                        setNewFile(null);
                        setActiveState(false);
                        setChecked(false);
                        dispatch(changeToolboxDataRights({ value: "public" }));
                        dispatch(changeToolboxFileName({ value: "" }));
                        dispatch(changeToolboxFileDescription({ value: "" }));
                        dispatch(changeToolboxFileFormat({ value: "" }));
                        dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
                        dispatch(changeToolboxVerticalDatum({ value: "" }));
                        dispatch(changeDataCollectionDate({ value: null }));
                        dispatch(changeToolboxZNegative({ value: false }));
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box
                ml="2rem"
                style={{
                  transition: "margin 0.5s ease",
                  marginTop: checked ? "0.5rem" : "7rem",
                }}
              >
                <FormControlLabel
                  disabled={!idTokenPayload}
                  checked={checked}
                  onChange={handleChange}
                  control={<Checkbox />}
                  label="Include in Data Repository"
                />
              </Box>
            </Box>

            {checked && (
              <Box ml="2rem">
                <Box display="flex" flexDirection="row" gap="0rem">
                  <Typography color="secondary.dark" fontWeight="700" variant="body2">
                    Privacy Control
                  </Typography>

                  <IconButton
                    disableRipple
                    sx={{
                      color: "secondary.dark",
                      mt: "-0.75rem",
                    }}
                    onClick={handleOpenClick}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Box>

                <Box>
                  <Box display="flex" flexDirection="row">
                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                      display="flex"
                      flexDirection="row"
                    >
                      <Radio
                        checked={value === "Public"}
                        onChange={handleRadioChange}
                        value="Public"
                        name="radio-button-demo"
                        color="secondary"
                        inputProps={{ "aria-label": "Public" }}
                      />

                      <Typography variant="caption" mt="0.75rem" mr="1rem">
                        Public
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                      display="flex"
                      flexDirection="row"
                    >
                      <Radio
                        checked={value === "Group"}
                        onChange={handleRadioChange}
                        value="Group"
                        name="radio-button-demo"
                        color="secondary"
                        inputProps={{ "aria-label": "Group" }}
                        disabled
                      />

                      <Typography variant="caption" mt="0.75rem" mr="1rem">
                        Group
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                      }}
                      display="flex"
                      flexDirection="row"
                    >
                      <Radio
                        checked={value === "Private"}
                        onChange={handleRadioChange}
                        value="Private"
                        name="radio-button-demo"
                        color="secondary"
                        inputProps={{ "aria-label": "Private" }}
                      />

                      <Typography variant="caption" mt="0.75rem" mr="1rem">
                        Private
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {value === "Group" && checked && (
              <Box ml="2rem" width="20rem" mt="0.5rem">
                <DropdownSelect
                  label="Group Selection"
                  value={groupSelection}
                  onChange={handleGroupSelectionValue}
                  options={[
                    { value: "SCH Managers", label: "SCH Managers" },
                    { value: "DHI Managers", label: "DHI Managers" },
                    { value: "CBCL Managers", label: "CBCL Managers" },
                  ]}
                />
              </Box>
            )}
          </Box>
        )
      )}

      {!uploading && newFile === null && (
        <Box
          sx={{
            width: {
              xs: "95%",
              sm: "95%",
              md: "95%",
              lg: "42rem",
            },
            height: "16rem",
            border: "#86A2B3 dotted",
            ml: "1rem",
            mt: "0.5rem",
            borderRadius: "0.5rem",
          }}
          onDrop={handleFileDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <UploadFiles handleFileUpload={handleFileUpload} />
        </Box>
      )}
      {fileSizeError && (
        <Typography
          color="error"
          variant="body2"
          mt="-2rem"
          mb="1.35rem"
          ml="2rem"
          display="flex"
          justifyContent="center"
        >
          File size exceeds 500 MB. Please choose a smaller file.
        </Typography>
      )}
      <BathymetryInfoModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default UploadDataFiles;
