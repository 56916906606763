import { Stack, IconButton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface iconProps {
  name: string;
  icon: JSX.Element;
}

export const ControlButtonLarge = ({ name, icon }: iconProps) => {
  const params = new URLSearchParams(window.location.search);
  const toolIcon = params.get("tool");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Stack
      sx={{
        borderRadius: "4px",
        bgcolor: toolIcon === name ? "secondary.dark" : "white",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "secondary.dark",
        },
      }}
    >
      <LightTooltip title={name} placement="right" enterDelay={500} leaveDelay={200}>
        <IconButton
          disableRipple
          onClick={() => {
            if (name) {
              const toolInURL = toolIcon === name;
              if (toolInURL) {
                searchParams.delete("tool", name);
              } else if (!toolInURL) {
                searchParams.set("tool", name);
              } else {
                searchParams.set("tool", name);
              }
            }
            navigate(`?${searchParams}`);
          }}
          sx={{
            color: toolIcon === name ? "white" : "secondary.dark",
            "&:hover": {
              color: "#FFFFFF",
            },
            height: "32px",
            width: "32px",
          }}
        >
          {icon}
        </IconButton>
      </LightTooltip>
    </Stack>
  );
};
