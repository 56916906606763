import { Autocomplete, Box, SelectChangeEvent, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { changeToolboxFileFormat } from "../../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeToolboxVerticalDatum } from "../../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import UploadDropdownSelect from "../../UploadData/DataDescription/UploadDropDownSelect";
import { changeFileTimeZone } from "../../../../features/DataRepoTimeZoneSlice";
import { changeDataRepositoryXCoordinate } from "../../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../../features/DataRepositoryYCoordinateSlice";
import { getCoordinateReferenceSystemOptions } from "../../../../api/backend_public";

const fileFormatOptions = [
  { value: "Comma Separated (.csv)", label: "Comma Separated (.csv)" },
  { value: "ASCII (.asc)", label: "ASCII (.asc)", disabled: true },
  { value: "Text (.txt)", label: "Text (.txt)" },
  { value: "MIKE DFS0 (.dfs0)", label: "MIKE DFS0 (.dfs0)" },
];

const verticalDatumOptions = [
  { value: "CGVD2013a", label: "CGVD2013a" },
  { value: "CGVD2013", label: "CGVD2013" },
  { value: "CGVD28", label: "CGVD28" },
];

const timeZoneOptions = [
  { value: "UTC", label: "UTC" },
  { value: "PST (UTC - 8:00)", label: "PST (UTC - 8:00)" },
  {
    value: "PDT/PST (UTC - 8:00 / UTC - 7:00)",
    label: "PDT/PST (UTC - 8:00 / UTC - 7:00)",
  },
  { value: "MST (UTC - 7:00)", label: "MST (UTC - 7:00)" },
  {
    value: "MDT/MST (UTC - 7:00 / UTC - 6:00)",
    label: "MDT/MST (UTC - 7:00 / UTC - 6:00)",
  },
  { value: "CST (UTC - 6:00)", label: "CST (UTC - 6:00)" },
  {
    value: "CDT/CST (UTC - 6:00 / UTC - 5:00)",
    label: "CDT/CST (UTC - 6:00 / UTC - 5:00)",
  },
  { value: "EST (UTC - 5:00)", label: "EST (UTC - 5:00)" },
  {
    value: "EDT/EST (UTC - 5:00 / UTC - 4:00)",
    label: "EDT/EST (UTC - 5:00 / UTC - 4:00)",
  },
  { value: "AST (UTC - 4:00)", label: "AST (UTC - 4:00)" },
  {
    value: "ADT/AST (UTC - 4:00 / UTC - 3:00)",
    label: "ADT/AST (UTC - 4:00 / UTC - 3:00)",
  },
  { value: "NST (UTC - 3:30)", label: "NST (UTC - 3:30)" },
  {
    value: "NDT/NST (UTC - 3:30 / UTC - 2:30)",
    label: "NDT/NST (UTC - 3:30 / UTC - 2:30)",
  },
];

interface CRSDropdownOption {
  value: string;
  label: string;
  id: number;
  proj4String: string;
  wkt: string;
}

interface validationProps {
  setRightValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
}

export const SeasonalDataDescriptionRight = ({ setRightValidate, nextClicked, setNextClicked }: validationProps) => {
  const dispatch = useDispatch();
  const fileformat = useSelector((state: RootState) => state.toolboxFileFormat.value);
  const CRS = useSelector((state: RootState) => state.toolboxCRS.CRS.value);
  const verticalDatum = useSelector((state: RootState) => state.toolboxVerticalDatum.value);
  const xcoordinate = useSelector((state: RootState) => state.xcoordinate.value);
  const ycoordinate = useSelector((state: RootState) => state.ycoordinate.value);
  const fileType = useSelector((state: RootState) => state.seasonalFileType.value);
  const timezone = useSelector((state: RootState) => state.timeZone.value);
  const [fileFormatError, setFileFormatError] = useState(true);
  const [CRSError, setCRSError] = useState(true);
  const [verticalDatumError, setVerticalDatumError] = useState(true);
  const [timeZoneError, setTimeZoneError] = useState(true);
  const [xcoordinateError, setXcoordinateError] = useState(false);
  const [ycoordinateError, setYcoordinateError] = useState(false);
  const [CRSOptions, setCRSOptions] = useState<CRSDropdownOption[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isCRSOptionSelected, setIsCRSOptionSelected] = useState(false);
  const [delayedInputValue, setDelayedInputValue] = useState("");
  const [wktValue, setWktValue] = useState("");
  const [xcoordinateInputError, setXcoordinateInputError] = useState(false);
  const [ycoordinateInputError, setYcoordinateInputError] = useState(false);

  const getCRSValues = async () => {
    try {
      const response = await getCoordinateReferenceSystemOptions();
      const CRSData = response.data;

      const CRSOptions = CRSData.map((crs: any) => ({
        value: crs.name,
        label: crs.name,
        id: crs.id,
        proj4String: crs.proj4String,
        wkt: crs.wkt,
      }));

      return CRSOptions;
    } catch (error) {
      console.error("Error fetching CRS values:", error);
      return [];
    }
  };
  useEffect(() => {
    getCRSValues().then((options) => {
      setCRSOptions(options);
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedInputValue(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const handlefileformatChange = (event: SelectChangeEvent) => {
    dispatch(changeToolboxFileFormat({ value: event.target.value as string }));
    setFileFormatError(!event.target.value);
  };

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    dispatch(changeFileTimeZone({ value: event.target.value as string }));
    setTimeZoneError(!event.target.value);
  };

  const handleVerticalDatumChange = (event: SelectChangeEvent) => {
    dispatch(changeToolboxVerticalDatum({ value: event.target.value as string }));
    setVerticalDatumError(!event.target.value);
  };

  const validateCoordinate = (axis: string, value: any) => {
    let error = false;
    if (wktValue === "degrees") {
      if (axis === "x") {
        error = value < -180 || value > 180;
      } else if (axis === "y") {
        error = value < -90 || value > 90;
      }
    } else if (wktValue === "meters") {
      error = value >= -1000 && value <= 1000;
    }
    if (axis === "x") {
      setXcoordinateInputError(error);
    } else if (axis === "y") {
      setYcoordinateInputError(error);
    }
  };

  const handleXcoordinateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const numericValue = value ? Number(value) : null;
    dispatch(changeDataRepositoryXCoordinate({ value: numericValue }));

    // Validate the X coordinate immediately after change
    validateCoordinate("x", numericValue);
  };

  const handleYcoordinateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const numericValue = value ? Number(value) : null;
    dispatch(changeDataRepositoryYCoordinate({ value: numericValue }));

    validateCoordinate("y", numericValue);
  };

  function getUnitsFromProj4String(proj4String: string | undefined) {
    if (proj4String) {
      if (/proj=longlat/.test(proj4String) || /proj=latlong/.test(proj4String)) {
        return "degrees";
      } else if (/proj=/.test(proj4String)) {
        return "meters";
      }
    }
    return "unknown";
  }

  const handleCRSChange = (event: any, newValue: CRSDropdownOption | null) => {
    setIsCRSOptionSelected(!!newValue);
    const unitValue = getUnitsFromProj4String(newValue?.proj4String);
    setWktValue(unitValue);
    dispatch(
      changeToolboxCRS({
        CRS: newValue
          ? {
              value: newValue.value,
              id: newValue.id,
              proj4String: newValue.proj4String,
              wkt: unitValue,
            }
          : { value: "", id: null, proj4String: "", wkt: "" },
      })
    );
  };

  const validateFields = () => {
    let xcoordinateError = false;
    let ycoordinateError = false;
    let surfaceElevationError = false;

    xcoordinateError = xcoordinate === null;
    ycoordinateError = ycoordinate === null;

    setXcoordinateError(xcoordinateError);
    setYcoordinateError(ycoordinateError);

    return xcoordinateError || ycoordinateError || xcoordinateInputError || ycoordinateInputError;
  };

  useEffect(() => {
    if (fileType !== "Water Level (Timeseries)") {
      setVerticalDatumError(false);
    } else {
      if (verticalDatum === "") {
        setNextClicked(false);
        setVerticalDatumError(true);
      }
    }
  }, [fileType]);

  useEffect(() => {
    const val = validateFields();

    setRightValidate(() => () => {
      setNextClicked(true);

      let errorExists = fileFormatError || CRSError || timeZoneError;
      if (fileType === "Water Level (Timeseries)") {
        errorExists = errorExists || verticalDatumError;
      }
      return errorExists || val;
    });
  }, [
    fileFormatError,
    CRSError,
    verticalDatumError,
    timeZoneError,
    xcoordinateError,
    ycoordinateError,
    xcoordinateInputError,
    ycoordinateInputError,
  ]);

  useEffect(() => {
    setFileFormatError(!fileformat);
    setCRSError(!CRS);
    setTimeZoneError(!timezone);

    if (fileType === "Water Level (Timeseries)") {
      setVerticalDatumError(!verticalDatum);
    } else {
      setVerticalDatumError(false);
    }
  }, [fileformat, CRS, timezone]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <UploadDropdownSelect
            label="File Format"
            value={fileformat}
            onChange={handlefileformatChange}
            options={fileFormatOptions}
            error={nextClicked && fileFormatError}
          />
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          {CRS !== undefined && (
            <Autocomplete
              value={CRS ? CRSOptions.find((option) => option.value === CRS) ?? null : null}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setIsCRSOptionSelected(false);
              }}
              onChange={handleCRSChange}
              options={CRSOptions.filter(
                (option) =>
                  option.label.toLowerCase().includes(delayedInputValue.toLowerCase()) ||
                  option.id.toString().includes(delayedInputValue)
              )}
              getOptionLabel={(option) => `${option.label} (Code: ${option.id})`}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Box>{option.label}</Box>
                    <Box style={{ fontSize: "smaller" }}>Code - {option.id}</Box>
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Coordinate Reference System" error={nextClicked && CRSError} />
              )}
              fullWidth
              size="small"
              open={!isCRSOptionSelected && delayedInputValue.length > 0}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="row">
          <TextField
            label="X Coordinate"
            error={(nextClicked && xcoordinateError) || xcoordinateInputError}
            variant="outlined"
            size="small"
            type="number"
            value={xcoordinate === null ? "" : xcoordinate}
            onChange={(event) => handleXcoordinateChange(event)}
            InputLabelProps={{
              style: {
                color: "#86A2B3",
              },
            }}
            sx={{
              mt: "0.75rem",
              ml: "1rem",
              width: "9.5rem",
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
            }}
          />
          <TextField
            label="Y Coordinate"
            error={(nextClicked && ycoordinateError) || ycoordinateInputError}
            variant="outlined"
            size="small"
            type="number"
            value={ycoordinate === null ? "" : ycoordinate}
            onChange={(event) => handleYcoordinateChange(event)}
            InputLabelProps={{
              style: {
                color: "#86A2B3",
              },
            }}
            sx={{
              mt: "0.75rem",
              ml: "1rem",
              width: "9.5rem",
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
            }}
          />
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <UploadDropdownSelect
            label="Time Zone"
            value={timezone}
            onChange={handleTimeZoneChange}
            options={timeZoneOptions}
            error={nextClicked && timeZoneError}
          />
        </Box>
        {fileType === "Water Level (Timeseries)" && (
          <Box width="20rem" mt="0.75rem" ml="1rem">
            <UploadDropdownSelect
              label="Vertical Datum"
              value={verticalDatum}
              onChange={handleVerticalDatumChange}
              options={verticalDatumOptions}
              error={nextClicked && verticalDatumError}
            />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};
