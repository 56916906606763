import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileDataTypeSliceState {
  value: string;
}

const initialState: FileDataTypeSliceState = {
  value: "",
};

export const DataRepoFileDataTypeSlice = createSlice({
  name: "datatype",
  initialState,
  reducers: {
    changeFileDataType: (
      state,
      action: PayloadAction<FileDataTypeSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileDataTypeSlice.reducer;

export const { changeFileDataType } = DataRepoFileDataTypeSlice.actions;
