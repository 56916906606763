import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const ToolboxBathymetryInfoModal = ({
  open,
  handleClose,
}: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "50vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "45rem" },
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            ml="0.5rem"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row">
              <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <Typography
                variant="body1"
                color="secondary.dark"
                fontWeight="700"
                mt="0.5rem"
              >
                Bathymetric Interpolation
              </Typography>
            </Box>
            <IconButton
              sx={{ mr: "0.5rem" }}
              disableRipple
              onClick={handleClose}
            >
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box
            m="0.5rem 1rem 1rem 1rem"
            display="flex"
            flexDirection="column"
            gap="1rem"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="secondary.dark">
                1. Define a Boundary Polygon for interpolation. Either:
              </Typography>

              <Typography variant="body2" color={"secondary.dark"} ml="1rem">
                a. Auto-generate to your Data Viewer Extent,
              </Typography>
              <Typography variant="body2" color={"secondary.dark"} ml="1rem">
                b. Draw a polygon within the Data Viewer (double click to
                complete), or
              </Typography>
              <Typography variant="body2" color={"secondary.dark"} ml="1rem">
                c. Upload from your computer in KMZ or KML format
              </Typography>
              <Typography variant="body2" color={"secondary.dark"} ml="1rem">
                d. Upload from your computer in Shapefile format. Select both
                the shapefile (.shp) and projection file (.prj), or select a ZIP
                file (.zip) which contains both files.
              </Typography>
            </Box>
            <Typography variant="body2" color="secondary.dark">
              2. Upload one or more bathymetric datasets from your computer or
              from the Data Repository.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              3. Select datasets and re-order them by dragging them up or down
              to view in the Data Viewer and prioritize them in the
              interpolation.
            </Typography>

            <Typography variant="body2" color="secondary.dark">
              4. Input an interpolation grid spacing and click 'Interpolate'.
            </Typography>

            <Typography variant="body2" color="secondary.dark">
              5. Once processing is complete, your new DEM will be listed under
              'Results' and displayed in the Data Viewer.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              6. You may download directly here, or right click on the three
              vertical dots for more options.
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
