import { Box, Divider, Typography } from "@mui/material";
import { GeotecnicalHeader } from "./GeoTecnicalHeader";
import StackedBarChart from "./GeotechnicalChart";
import { useState } from "react";

interface portProps {
  port: any;
}

const data = [
  {
    name: "CLAY and SILT",
    value: 10,
    color: "#009900",
    image: "./src/assets/Capture6.PNG",
  },
  {
    name: "SILT with trace sand, soft, and very moist",
    value: 9,
    color: "#99ff99",
    image: "./src/assets/Capture5.PNG",
  },
  {
    name: "GRAVEL with trace sand, fine sand lenses as interbeds. Organic material traces, brown.",
    value: 7,
    color: "#cc9900",
    image: "./src/assets/Capture4.PNG",
  },
  {
    name: "SAND and SILT with trace clay, very fine to medium, brown, soft and very moist",
    value: 5.25,
    color: " #3399ff",
    image: "./src/assets/Capture3.PNG",
  },
  {
    name: "SAND with trace gravel, trace silt, fine to medium, brown",
    value: 3.5,
    color: "#ff0000",
    image: "./src/assets/Capture2.PNG",
  },
  {
    name: "GRAVEL and SAND, with trace of silt, gray to brown, moderate density",
    value: 2.25,
    color: "#ffcc00",
    image: "./src/assets/Capture.PNG",
  },
];

export const DashboardGeotechnicalTab = ({ port }: portProps) => {
  const [datasetDetails, setDatasetDetails] = useState<any>();
  const [yMin, setYMin] = useState([]);
  const [yMax, setYMax] = useState([]);
  const [lithoMain, setLithoMain] = useState([]);
  const [lithoSecondary, setLithoSecondary] = useState([]);

  return (
    <Box>
      <Box>
        <GeotecnicalHeader yMax={yMax} yMin={yMin} lithoMain={lithoMain} lithoSecondary={lithoSecondary} />

        <Divider />
      </Box>
      <Box sx={{ bgcolor: "grey.50", overflowY: "auto", overflowX: "hidden" }} height="calc(100vh - 12.5rem)">
        <StackedBarChart
          datasetDetails={datasetDetails}
          setDatasetDetails={setDatasetDetails}
          yMax={yMax}
          setYMax={setYMax}
          yMin={yMin}
          setYMin={setYMin}
          lithoMain={lithoMain}
          setLithoMain={setLithoMain}
          lithoSecondary={lithoSecondary}
          setLithoSecondary={setLithoSecondary}
        />
        <Box
          p="1rem"
          bgcolor="#FFFFFF"
          sx={{
            m: "1rem 1.5rem 2rem 1.8rem",
            width: "46rem",
            border: "1px solid #CFDBE2",
            borderRadius: "8px",
            boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
          }}
        >
          <Typography color="grey.500" variant="body2" mb="0.75rem">
            Warnings:
          </Typography>
          <Box>
            {datasetDetails && datasetDetails.BH_selec !== 0 ? (
              <Typography color="grey.500" variant="caption">
                {`The closest borehole, ${datasetDetails.BH_selec}, (latitude ${datasetDetails.BH_lat.toFixed(
                  1
                )}, longitude ${datasetDetails.BH_lon.toFixed(1)}), is located
             ${parseFloat(datasetDetails.BH_dstkm).toFixed(1)} km from the ${port.get(
                  "Harbour_Na"
                )}. It is the responsibility of the user to evaluate the applicability of this
            data at this location.`}
              </Typography>
            ) : (
              <Typography color="grey.500" variant="caption">
                No borehole data close to {port.get("Harbour_Na")} is available.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
