import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const CopyrightModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "55rem" },
          }}
        >
          <Box>
            <Box display="flex" flexDirection="row">
              <Box textAlign="center" width="100%">
                <Typography
                  variant="h2"
                  sx={{
                    mt: "2rem",
                    ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                    textAlign: "inherit",
                  }}
                >
                  Copyright
                </Typography>
              </Box>
              <IconButton
                sx={{ mr: "2rem", mt: "1rem" }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box m="1rem 5rem 0rem 5rem">
              <Typography variant="body2" color="black" textAlign="center">
                <b style={{ color: "black" }}>
                  This Copyright Notice is DRAFT.
                </b>{" "}
                It is included in this Phase 2 prototype to provide an example
                of the final Copyright Notice which will be included in Phase 3.
                As part of Phase 3, legal review of the Copyright Notice will be
                completed to ensure compliance with governing agency
                requirements.
              </Typography>
            </Box>
            <Box textAlign="center" width="90%">
              <Typography
                variant="h6"
                fontWeight="400"
                color="secondary.dark"
                sx={{
                  mt: "1rem",
                  ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                  textAlign: "inherit",
                }}
              >
                Overview
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 2rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                The texts, layouts, images, databases and other items on this
                website, as well as the website itself, are protected by
                copyright and by the right of the producer of the database. Some
                of the names, signs and logos on this website are protected
                trademarks or trade names. Nothing contained on the website
                should be construed as granting any license or right to use any
                trademark, displayed on the website without the written
                permission from us or such third party that may own the
                trademarks displayed on the website. Any copy, adaptation,
                translation, arrangement, modification, or any use whatsoever of
                the whole or of any part of this website of its protected
                elements, in any form and by any means, is strictly prohibited.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
