import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dispatch, SetStateAction } from "react";

interface SearchbarProps {
  searchbarValue: string;
  setSearchbarValue: Dispatch<SetStateAction<string>>;
}

export const ModalSearchbar = ({ searchbarValue, setSearchbarValue }: SearchbarProps) => {
  const handleChange = (value: string) => {
    setSearchbarValue(value);
  };
  return (
    <>
      <TextField
        type="search"
        id="search"
        placeholder="What are you looking for?"
        color="secondary"
        size="small"
        autoComplete="off"
        sx={{
          width: 368,
          bgcolor: "#fff",
          input: { cursor: "pointer" },
          zIndex: 100,
          borderRadius: "90px",
          boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
          border: "0.5px solid #86A2B3",
          "& fieldset": { border: "none" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchbarValue}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};
