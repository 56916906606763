import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileDatumSliceState {
  value: string;
}

const initialState: FileDatumSliceState = {
  value: "CGVD2013",
};

export const DataRepoFileDatumSlice = createSlice({
  name: "Datum",
  initialState,
  reducers: {
    changeFileDatum: (state, action: PayloadAction<FileDatumSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileDatumSlice.reducer;

export const { changeFileDatum } = DataRepoFileDatumSlice.actions;
