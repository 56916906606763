import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { changeToolboxActivateUpload } from "../../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { changeDataRepoProcessFileState } from "../../../../features/DataRepoProcessFileSlice";

interface FileProps {
  processedFile: File | null;
  setProcessedFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
}

const checkboxLabels = [
  "Bathymetric Survey",
  "Control Points",
  "Top Survey",
  "New Breakwater outline",
  "Old Breakwater outline",
  "Utilities",
  "Floating wharves",
  "Benchmarks",
  "Easement",
];

export const DataRepoModelMesh = ({
  processedFile,
  setProcessedFile,
  file,
}: FileProps) => {
  const dispatch = useDispatch();

  const filename = useSelector((state: RootState) => state.fileName.value);
  const processFileState = useSelector(
    (state: RootState) => state.dataRepoProcessFile.value
  );
  const fileDataType = useSelector(
    (state: RootState) => state.fileDataType.value
  );

  const processFile = async () => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const contents = e.target?.result;
      if (contents) {
        const processedFile = new File([contents], `${filename}.dwg`, {
          type: file.type,
        });
        setProcessedFile(processedFile);
        dispatch(changeToolboxActivateUpload({ value: false }));
        dispatch(changeDataRepoProcessFileState({ value: false }));
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (processFileState) {
      processFile();
    }
  }, [processFileState]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "16.5rem",
        overflow: "auto",
      }}
    >
      {fileDataType !== "Misc / Free Format" ? (
        <Box ml="2rem" mt="1rem" mr="2rem" gap="-1rem">
          <Box>
            <Typography variant="body1" color="secondary.dark" ml="0.2rem">
              No other user inputs required.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          ml="2rem"
          mt="1rem"
          mr="2rem"
          gap="-1rem"
          display="flex"
          flexDirection="row"
        >
          <Box>
            <Typography variant="body1" color="secondary.dark" ml="0.2rem">
              Warning:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="secondary.dark" ml="0.5rem">
              Users will not be able to view or analyze unknown data formats in
              this application. Data will be available for download.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
