import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchbarState {
  value: boolean;
}

const initialState: SearchbarState = {
  value: false,
};

export const SearchbarSlice = createSlice({
  name: "searchbar",
  initialState,
  reducers: {
    changeSearchState: (state, action: PayloadAction<SearchbarState>) => {
      return (state = action.payload);
    },
  },
});

export default SearchbarSlice.reducer;

export const { changeSearchState } = SearchbarSlice.actions;
