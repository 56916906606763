import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import { useFileContext } from "../UploadDataFiles";
import Papa from "papaparse";
import React, { useState, useEffect, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { CircularProgress } from "@mui/material";
import { changeToolboxProcessFileState } from "../../../../features/ToolboxFeatures/ToolboxProcessFileSlice";
import { changeToolboxActivateUpload } from "../../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";

interface TableProps {
  delimeter: string;
  otherValue: string;
  processedFile: File | null;
  setProcessedFile: React.Dispatch<SetStateAction<File | null>>;
  rowValue: string;
}
interface DataRow {
  [key: string]: string | number;
}

export const ImportOptionsTable = ({
  otherValue,
  delimeter,
  processedFile,
  setProcessedFile,
  rowValue,
}: TableProps) => {
  const dispatch = useDispatch();
  const processFileState = useSelector((state: RootState) => state.toolboxProcessFile.value);
  const fileformat = useSelector((state: RootState) => state.toolboxFileFormat.value);
  const filename = useSelector((state: RootState) => state.toolboxFileName.value);
  const { newFile, setNewFile } = useFileContext();
  const [filePreview, setFilePreview] = useState<any[]>([]);
  const columns = filePreview.length > 0 ? Object.keys(filePreview[0]) : [];
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    columns.reduce((acc, column) => {
      acc[column] = "No Import";
      return acc;
    }, {} as Record<string, string>)
  );
  const [emptyRowValues, setEmptyRowValues] = useState<Record<string, string>>({});

  const [fullFileData, setFullFileData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUsedDelimiter, setLastUsedDelimiter] = useState<string>("");

  const handleValuesChange = (columnName: string, value: string) => {
    let countX = 0;
    let countY = 0;
    let countZ = 0;

    for (const key in selectedValues) {
      if (selectedValues[key] === "X") countX++;
      if (selectedValues[key] === "Y") countY++;
      if (selectedValues[key] === "Z") countZ++;
    }

    if ((value === "X" && countX >= 1) || (value === "Y" && countY >= 1) || (value === "Z" && countZ >= 1)) {
      alert(`You can only have one ${value} axis. Please select another value from the drop-down list.`);
    } else {
      setSelectedValues((prevValues) => {
        return {
          ...prevValues,
          [columnName]: value,
        };
      });

      setEmptyRowValues((prevValues) => {
        const newValues = { ...prevValues };

        switch (value) {
          case "X":
          case "Y":
          case "Point Number":
          case "Description":
          case "No Import":
            newValues[columnName] = "N/A";
            break;
          case "Misc.":
            newValues[columnName] = "user input";
            break;
          case "Z":
            newValues[columnName] = "m";
            break;
          default:
            newValues[columnName] = "";
            break;
        }

        return newValues;
      });
    }
  };

  const processFile = () => {
    const filteredSelectedValues = Object.entries(selectedValues).filter(
      ([columnName, selectedValue]) => selectedValue !== "No Import"
    );

    const sortedFilteredSelectedValues = filteredSelectedValues.sort(
      ([columnNameA, selectedValueA], [columnNameB, selectedValueB]) => {
        if (selectedValueA === "X") return -1;
        if (selectedValueB === "X") return 1;
        if (selectedValueA === "Y") return -1;
        if (selectedValueB === "Y") return 1;
        if (selectedValueA === "Z") return -1;
        if (selectedValueB === "Z") return 1;
        return 0;
      }
    );

    let startRow = parseInt(rowValue) - 2;
    if (startRow < 0) startRow = 0;

    const newData = fullFileData.slice(startRow).map((row) => {
      let newRow: Record<string, any> = {};
      sortedFilteredSelectedValues.forEach(([columnName, selectedValue]) => {
        newRow[selectedValue] = row[columnName];
      });
      return newRow;
    });

    let fileType = "";
    switch (fileformat) {
      case "Text (.txt)":
        fileType = ".txt";
        break;
      case "Comma Separated (.csv)":
        fileType = ".xyz";
        break;
      case "ASCII (.asc)":
        fileType = ".asc";
        break;
      case "XYZ (.xyz)":
        fileType = ".xyz";
        break;
      default:
        return;
    }

    const processedData = Papa.unparse(newData);
    const processedBlob = new Blob([processedData], {
      type: "text/csv;charset=utf-8",
    });
    const processedFile = new File([processedBlob], `${filename}${fileType}`, {
      type: "text/csv",
    });

    setProcessedFile(processedFile);
    dispatch(changeToolboxProcessFileState({ value: false }));
    setIsLoading(false);
  };
  useEffect(() => {
    if (newFile) {
      setIsLoading(true);
      let delimiterVal: string;
      switch (delimeter) {
        case "Comma":
          delimiterVal = ",";
          break;
        case "Tab":
          delimiterVal = "\t";
          break;
        case "Space":
          delimiterVal = " ";
          break;
        case "Other":
          if (!otherValue.trim()) {
            setIsLoading(false);
            setFilePreview([]);
            return;
          }
          delimiterVal = otherValue;
          break;
        default:
          delimiterVal = "";
          break;
      }

      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target?.result) {
          const text = e.target.result as string;
          const processedText = fileformat === "ASCII (.asc)" ? text.replace(/[ \t]+/g, " ") : text;

          Papa.parse(processedText, {
            delimiter: delimiterVal,
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: function (results) {
              if (results.errors.length === 0) {
                let content = results.data as DataRow[];

                if (Object.keys(content[0] || {}).length <= 1) {
                  const lines = processedText.split("\n").map((line) => {
                    const trimmedLine = line.trim();

                    return trimmedLine.endsWith(",") ? trimmedLine.slice(0, -1) : trimmedLine;
                  });

                  const correctedText = lines.join("\n");

                  Papa.parse(correctedText, {
                    delimiter: ",",
                    header: true,
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    complete: function (nestedResults) {
                      if (nestedResults.errors.length === 0) {
                        content = nestedResults.data as DataRow[];
                      }
                    },
                  });
                }

                let startRow = parseInt(rowValue) - 2;
                if (isNaN(startRow) || startRow < 0) {
                  startRow = 0;
                }

                setFilePreview(content.slice(startRow, startRow + 14));
                setFullFileData(content);
                setLastUsedDelimiter(delimiterVal);
              }
              setIsLoading(false);
            },
          });
        }
      };

      reader.readAsText(newFile);
    }
  }, [newFile, delimeter, otherValue, rowValue]);

  useEffect(() => {
    if (lastUsedDelimiter && delimeter !== lastUsedDelimiter) {
      setFilePreview([]);
      setFullFileData([]);
      setLastUsedDelimiter("");
    }
  }, [delimeter]);

  useEffect(() => {
    const columns = filePreview.length > 0 ? Object.keys(filePreview[0]) : [];
    if (
      Object.keys(selectedValues).length > 0 &&
      columns.every((column) => selectedValues[column] && selectedValues[column].trim() !== "")
    ) {
      dispatch(changeToolboxActivateUpload({ value: true }));
    }
  }, [selectedValues, filePreview, processFileState]);

  useEffect(() => {
    if (processFileState) {
      setIsLoading(true);
      setTimeout(() => {
        processFile();
      }, 0);
    }
  }, [processFileState]);

  useEffect(() => {
    const defaultValues = columns.reduce((acc, column) => {
      acc[column] = "No Import";
      return acc;
    }, {} as Record<string, string>);

    setSelectedValues(defaultValues);
  }, [filePreview]);

  const rows = filePreview.map((row, index) => {
    return {
      index: index + 1,
      ...row,
    };
  });

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            bottom: "0",
            left: "50%",
            right: "0",
            zIndex: "1000000",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      <Box
        border="1px solid gray"
        width="auto"
        height="10.5rem"
        m="0rem 2rem"
        overflow="auto"
        sx={{
          filter: isLoading ? "blur(5px)" : null,
        }}
      >
        <Box m="1rem">
          {columns.length > 1 ? (
            <TableContainer
              sx={{
                overflowX: "visible",
              }}
            >
              <Table sx={{ minWidth: "40rem" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      sx={{
                        minWidth: "3rem",
                        height: "4rem",
                        borderRight: "1px solid gray",
                        borderBottom: "1px solid gray",
                        borderLeft: "1px solid gray",
                        borderTop: "1px solid gray",
                      }}
                    >
                      <Typography variant="body2" color="secondary.dark">
                        Type
                      </Typography>
                    </TableCell>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          width: "10rem",
                          height: "2rem",
                          border: "1px solid gray",
                        }}
                      >
                        <FormControl
                          fullWidth
                          sx={{
                            height: "2rem",
                          }}
                        >
                          <Select
                            value={selectedValues[column] || ""}
                            onChange={(event) => handleValuesChange(column, event.target.value as string)}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "2rem",
                              borderRadius: "0px",
                              pl: "1rem",
                            }}
                          >
                            <MenuItem value="X">X</MenuItem>
                            <MenuItem value="Y">Y</MenuItem>
                            <MenuItem value="Z">Z</MenuItem>
                            <MenuItem value="Point Number">Point Number</MenuItem>
                            <MenuItem value="Description">Description</MenuItem>
                            <MenuItem value="Misc.">Misc.</MenuItem>
                            <MenuItem value="No Import">No Import</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          width: "10rem",
                          height: "2rem",
                          border: "1px solid gray",
                        }}
                      >
                        {emptyRowValues[column]}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowValue === "1" && (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          width: "3rem",
                          height: "2rem",
                          borderRight: "1px solid gray",
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                          borderLeft: "1px solid gray",
                        }}
                      >
                        <Typography variant="caption" color="secondary.dark">
                          1
                        </Typography>
                      </TableCell>
                      {columns.map((column, columnIndex) => (
                        <TableCell
                          key={columnIndex}
                          align="center"
                          sx={{
                            width: "10rem",
                            height: "2rem",
                            border: "1px solid gray",
                          }}
                        >
                          <Typography variant="body2" color="secondary.dark">
                            {column}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        sx={{
                          width: "3rem",
                          height: "2rem",
                          borderRight: "1px solid gray",
                          borderBottom: "1px solid gray",
                          borderLeft: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                      >
                        <Typography variant="body2" color="secondary.dark">
                          {rowValue === "1" ? parseInt(rowValue) + index + 1 : parseInt(rowValue) + index}
                        </Typography>
                      </TableCell>
                      {columns.map((column, columnIndex) => (
                        <TableCell
                          key={columnIndex}
                          align="center"
                          sx={{
                            width: "10rem",
                            height: "2rem",
                            border: "1px solid gray",
                          }}
                        >
                          <Typography variant="body2" color="secondary.dark">
                            {row[column]}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box>
              <Typography
                variant="body1"
                color="secondary.dark"
                fontWeight="700"
                display="flex"
                justifyContent="center"
              >
                Try different delimeter
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
