import { Typography, Box, Backdrop, Modal, Fade, Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction, useState } from "react";

import { useDispatch } from "react-redux";
import { changeToolboxFileName } from "../../../features/ToolboxFeatures/ToolBoxFileName";
import { changeToolboxFileDescription } from "../../../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import { changeToolboxFileFormat } from "../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeToolboxVerticalDatum } from "../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { changeDataCollectionDate } from "../../../features/ToolboxFeatures/DataCollectionDateSlice";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import SeasonalDataStepper from "./SeasonalDataStepper";
import { changeSeasonalFileType } from "../../../features/ToolboxFeatures/SeasonalFileTypeSlice";
import { changeDataRepositoryXCoordinate } from "../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../features/DataRepositoryYCoordinateSlice";
import { changeFileTimeZone } from "../../../features/DataRepoTimeZoneSlice";
import { useFileContext } from "./UploadSeasonalDataFiles";

interface DataUploadModal {
  open: boolean;
  handleClose: () => void;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

export const UploadSeasonalModal = ({ open, handleClose, checked, setChecked }: DataUploadModal) => {
  const { newFile, setNewFile } = useFileContext();
  const [files, setFiles] = useState<File | null>(null);
  const dispatch = useDispatch();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      sx={{ backdropFilter: "blur(10px)" }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "25rem",
            width: {
              xs: "90%",
              sm: "75%",
              md: "60%",
              lg: "44rem",
            },
          }}
        >
          <Box m="0rem 1rem" display="flex" flexDirection="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem" mb="0.5rem">
                Data Upload
              </Typography>
            </Stack>
            <IconButton
              disableRipple
              onClick={() => {
                handleClose();
                setNewFile(null);
                setFiles(null);
                setChecked(false);
                dispatch(changeToolboxFileName({ value: "" }));
                dispatch(changeToolboxFileDescription({ value: "" }));
                dispatch(changeToolboxFileFormat({ value: "" }));
                dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
                dispatch(changeToolboxVerticalDatum({ value: "" }));
                dispatch(changeSeasonalFileType({ value: "" }));
                dispatch(changeDataCollectionDate({ value: null }));
                dispatch(changeDataRepositoryXCoordinate({ value: null }));
                dispatch(changeDataRepositoryYCoordinate({ value: null }));
                dispatch(changeFileTimeZone({ value: "" }));
                dispatch(changeToolboxZNegative({ value: false }));
              }}
            >
              <CloseIcon sx={{ color: "secondary.dark" }} />
            </IconButton>
          </Box>
          <SeasonalDataStepper
            handleClose={handleClose}
            files={files}
            setFiles={setFiles}
            checked={checked}
            setChecked={setChecked}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
