import { configureStore } from "@reduxjs/toolkit";
import SearchbarSlice from "../features/SearchbarSlice";
import dashboardSearchbarSlice from "../features/dashboardSearchbarSlice";
import dashboardPortSummarySlice from "../features/dashboardPortSummarySlice";
import ToolsIconSlice from "../features/ToolsIconSlice";
import DataRepoFileDataTypeSlice from "../features/DataRepoFileDataTypeSlice";
import DataRepoFileNameSlice from "../features/DataRepoFileNameSlice";
import DataRepoFileDescriptionSlice from "../features/DataRepoDescriptionSlice";
import DataRepoPrivacySlice from "../features/DataRepoPrivacySlice";
import DataRepoFileDataFormatSlice from "../features/DataRepoDataFormatSlice";
import DataRepoSurveyDateSlice from "../features/DataRepoSurveyDateSlice";
import DataRepoSurveySlice from "../features/DataRepoSurveySlice";
import DataRepoDatumSlice from "../features/DataRepoDatumSlice";
import DataRepoCRSSlice from "../features/DataRepoCRSSlice";
import DataRepoHeadDataTypeSlice from "../features/DataRepoHeadDataTypeSlice";
import DataRepoHeadRightsSlice from "../features/DataRepoHeadRightsSlice";
import DataRepoHeadUsernameSlice from "../features/DataRepoHeadUsernameSlice";
import DataRepoDataSummarySlice from "../features/DataRepoDataSummarySlice";
import DataRepoFileValueSlice from "../features/DataRepoFileValueSlice";
import DataRepoSnackbarSliceState from "../features/DataRepoSnackbarSlice";
import ToolboxStateSlice from "../features/ToolboxStateSlice";
import ClearButtonVisibilitySlice from "../features/ClearButtonVisibilitySlice";
import DataRepositoryXCoordinateSlice from "../features/DataRepositoryXCoordinateSlice";
import DataRepositoryYCoordinateSlice from "../features/DataRepositoryYCoordinateSlice";
import FileDetailsInsidePolygonSlice from "../features/FileDetailsInsidePolygonSlice";
import mapPointsSlice from "../features/mapPointsSlice";
import PolygonCoordinatesSlice from "../features/PolygonCoordinatesSlice";
import DataCollectionDateSlice from "../features/ToolboxFeatures/DataCollectionDateSlice";
import ToolBoxFileName from "../features/ToolboxFeatures/ToolBoxFileName";
import ToolboxFileDescriptionSlice from "../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import ToolboxFileFormatSlice from "../features/ToolboxFeatures/ToolboxFileFormatSlice";
import ToolboxCRSSlice from "../features/ToolboxFeatures/ToolboxCRSSlice";
import ToolboxVerticalDatumSlice from "../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import ToolboxProcessFileSlice from "../features/ToolboxFeatures/ToolboxProcessFileSlice";
import ToolboxActivateUploadSlice from "../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import ToolboxZNegativeSlice from "../features/ToolboxFeatures/ToolboxZNegativeSlice";
import ToolboxDataRightsSlice from "../features/ToolboxFeatures/ToolboxDataRightsSlice";
import DataRepoGroupValueSlice from "../features/DataRepoGroupValueSlice";
import DataRepoTimeZoneSlice from "../features/DataRepoTimeZoneSlice";
import DataRepoSurfaceElevationSlice from "../features/DataRepoSurfaceElevationSlice";
import DataRepoProcessFileSlice from "../features/DataRepoProcessFileSlice";
import ModelGridCheckedSlice from "../features/ModelGridCheckedSlice";
import DataRepoActiveUploadSlice from "../features/DataRepoActiveUploadSlice";
import ModelGridGraticuleSlice from "../features/ModelGridGraticuleSlice";
import SeasonalFileTypeSlice from "../features/ToolboxFeatures/SeasonalFileTypeSlice";
import SeasonalFileHeadersSlice from "../features/ToolboxFeatures/SeasonalFileHeadersSlice";
import DataRepositorySearchbarSlice from "../features/DataRepositorySearchbarSlice";
import LayerButtonSlice from "../features/LayerButtonSlice";
import DashboardHarborsInExtentSlice from "../features/DashboardHarborsInExtentSlice";
import DataRepoCheckedITemsSlice from "../features/DataRepoCheckedITemsSlice";
import ZoomToBayOfFundaySlice from "../features/ZoomToBayOfFundaySlice";
import ZoomToAtlanticCanadaSlice from "../features/ZoomToAtlanticCanadaSlice";
import DrawerSlice from "../features/DrawerSlice";
import DataRepoUploadStatusSlice from "../features/DataRepoUploadStatusSlice";
import DataRepoPolygonSlice from "../features/DataRepoPolygonSlice";
import ToolboxBathymetryInterpolationImportSlice from "../features/ToolboxBathymetryInterpolationImportSlice";
import ToolboxBathymetryRightsSlice from "../features/ToolboxBathymetryRightsSlice";
import DataRepoProcessedFilesDataSlice from "../features/DataRepoProcessedFilesDataSlice";
import DataRepoUploadPressedSlice from "../features/DataRepoUploadPressedSlice";
import DataRepoLayerVisibilitySlice from "../features/DataRepoLayerVisibilitySlice";
import UserNamesSlice from "../features/UserNamesSlice";
import ToolboxUploadStatusSlice from "../features/ToolboxFileStatus";
import BathymetryToolboxSlice from "../features/ToolboxFeatures/BathymetryToolboxSlice";
import SeasonalStatisticsSlice from "../features/ToolboxFeatures/SeasonalStatisticsSlice";
import WaveProfileToolboxSlice from "../features/ToolboxFeatures/WaveProfileToolboxSlice";

export const store = configureStore({
  reducer: {
    searchbar: SearchbarSlice,
    dashboardSearchbar: dashboardSearchbarSlice,
    dashboardPortSummary: dashboardPortSummarySlice,
    toolsicon: ToolsIconSlice,
    fileDataType: DataRepoFileDataTypeSlice,
    fileName: DataRepoFileNameSlice,
    fileDescription: DataRepoFileDescriptionSlice,
    filePrivacy: DataRepoPrivacySlice,
    fileDataFormat: DataRepoFileDataFormatSlice,
    fileSurveryDate: DataRepoSurveyDateSlice,
    fileSurvery: DataRepoSurveySlice,
    fileDatum: DataRepoDatumSlice,
    fileCRS: DataRepoCRSSlice,
    headDataType: DataRepoHeadDataTypeSlice,
    headRights: DataRepoHeadRightsSlice,
    headUsername: DataRepoHeadUsernameSlice,
    dataSummary: DataRepoDataSummarySlice,
    fileValue: DataRepoFileValueSlice,
    snackbar: DataRepoSnackbarSliceState,
    toolbox: ToolboxStateSlice,
    clearButton: ClearButtonVisibilitySlice,
    xcoordinate: DataRepositoryXCoordinateSlice,
    ycoordinate: DataRepositoryYCoordinateSlice,
    mapPoints: mapPointsSlice,
    fileDetailsInsidePolygon: FileDetailsInsidePolygonSlice,
    polygonCoordinates: PolygonCoordinatesSlice,
    dataCollectionDate: DataCollectionDateSlice,
    toolboxFileName: ToolBoxFileName,
    ToolboxFileDescription: ToolboxFileDescriptionSlice,
    toolboxFileFormat: ToolboxFileFormatSlice,
    toolboxCRS: ToolboxCRSSlice,
    toolboxVerticalDatum: ToolboxVerticalDatumSlice,
    toolboxProcessFile: ToolboxProcessFileSlice,
    toolboxUpload: ToolboxActivateUploadSlice,
    toolboxZNegative: ToolboxZNegativeSlice,
    toolboxRights: ToolboxDataRightsSlice,
    groupValue: DataRepoGroupValueSlice,
    timeZone: DataRepoTimeZoneSlice,
    surfaceElevation: DataRepoSurfaceElevationSlice,
    dataRepoProcessFile: DataRepoProcessFileSlice,
    dataRepoActivateUpload: DataRepoActiveUploadSlice,
    modelGridChecked: ModelGridCheckedSlice,
    modelGridGraticule: ModelGridGraticuleSlice,
    seasonalFileType: SeasonalFileTypeSlice,
    seasonalFileHeaders: SeasonalFileHeadersSlice,
    dataRepoSearchBar: DataRepositorySearchbarSlice,
    layerButton: LayerButtonSlice,
    harborsInExtent: DashboardHarborsInExtentSlice,
    checkedItems: DataRepoCheckedITemsSlice,
    zoomToBayOfFundy: ZoomToBayOfFundaySlice,
    zoomToAtlanticCanada: ZoomToAtlanticCanadaSlice,
    drawer: DrawerSlice,
    dataRepoUploadStatus: DataRepoUploadStatusSlice,
    polygon: DataRepoPolygonSlice,
    bathymetryInterpolationImport: ToolboxBathymetryInterpolationImportSlice,
    toolboxBathymetryRights: ToolboxBathymetryRightsSlice,
    processedFiles: DataRepoProcessedFilesDataSlice,
    uploadPressed: DataRepoUploadPressedSlice,
    layerVisibility: DataRepoLayerVisibilitySlice,
    userNames: UserNamesSlice,
    toolboxUploadStatus: ToolboxUploadStatusSlice,
    bathymteryToolbox: BathymetryToolboxSlice,
    seasonalToolbox: SeasonalStatisticsSlice,
    waveProfileToolbox: WaveProfileToolboxSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "map/setMapInstance",
          "fileDetails/addNewFileDetails",
          "harbours/setDashboardHarbours",
          "modelGridGraticule/changeModelGrdiGraticuleState",
          "ToolboxBathymetryInterpolationImport/changeDrawnFeatureValue",
        ],
        ignoredActionPaths: ["meta.arg", "payload.timestamp", "payload.file"],
        ignoredPaths: [
          "map.mapInstance",
          "fileDetails.value.0.file",
          "dashboardHarbours.value",
          "modelGridGraticule.value",
          "ToolboxBathymetryInterpolationImport.drawnFeature",
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
