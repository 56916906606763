import { Box, IconButton, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

export const DashboardOtherData = () => {
  return (
    <Box>
      <LightTooltip
        title={"This feature is planned for Phase 3"}
        placement="left"
        enterDelay={500}
        leaveDelay={200}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -30],
              },
            },
          ],
        }}
      >
        <Box display="flex" flexDirection="row" mt="1rem" width="10rem" sx={{ "&:hover": { cursor: "pointer" } }}>
          <IconButton size="large" disableRipple>
            <AddOutlinedIcon
              sx={{
                fontSize: "1.8rem",
                color: "gray",
                mt: "-1rem",
              }}
            />
          </IconButton>
          <Typography variant="h3" fontWeight="500" ml="-0.5rem" color="gray">
            Link Data
          </Typography>
        </Box>
      </LightTooltip>
    </Box>
  );
};
