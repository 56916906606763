import { createSlice } from "@reduxjs/toolkit";

export const ToolboxBathymetryInterpolationImportSlice = createSlice({
  name: "ToolboxBathymetryInterpolationImport",
  initialState: {
    isImportCompleted: false,
    buttonSelected: "",
    drawnFeature: null,
    convertedProjectId: "",
    convertedDatasetId: "",
    refresh: false,
  },
  reducers: {
    changeBathymetryInterpolationStatus: (state, action) => {
      state.isImportCompleted = action.payload;
    },
    changeBathyButtonSelected: (state, action) => {
      state.buttonSelected = action.payload;
    },
    changeDrawnFeatureValue: (state, action) => {
      state.drawnFeature = action.payload;
    },
    changeConvertedProjectIdValue: (state, action) => {
      state.drawnFeature = action.payload;
    },
    changeConvertedDatasetIdValue: (state, action) => {
      state.drawnFeature = action.payload;
    },
    changeBathyInterpRefreshState: (state, action) => {
      state.refresh = action.payload;
    },
  },
});

export const {
  changeBathymetryInterpolationStatus,
  changeBathyButtonSelected,
  changeDrawnFeatureValue,
  changeConvertedProjectIdValue,
  changeConvertedDatasetIdValue,
  changeBathyInterpRefreshState,
} = ToolboxBathymetryInterpolationImportSlice.actions;
export default ToolboxBathymetryInterpolationImportSlice.reducer;
