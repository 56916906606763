import React, { ElementType } from "react";
import { Stack, IconButton, SvgIconProps, Tooltip } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface ControlButtonProps {
  tooltipTitle: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  IconComponent: ElementType<SvgIconProps>;
  sx?: SxProps<Theme>;
}

const ControlButton: React.FC<ControlButtonProps> = ({ tooltipTitle, onClick, IconComponent, sx }) => {
  return (
    <Stack sx={sx}>
      <LightTooltip title={tooltipTitle} placement="right" enterDelay={500} leaveDelay={200}>
        <IconButton
          disableRipple
          onClick={onClick}
          color="secondary"
          sx={{
            "&:hover": {
              color: "#FFFFFF",
            },
            height: "32px",
            width: "32px",
          }}
        >
          <IconComponent fontSize="medium" />
        </IconButton>
      </LightTooltip>
    </Stack>
  );
};

export default ControlButton;
