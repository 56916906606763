import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ZoomToAtlanticCanadaSliceState {
  value: boolean;
}

const initialState: ZoomToAtlanticCanadaSliceState = {
  value: false,
};

export const ZoomToAtlanticCanadaSlice = createSlice({
  name: "AtlanticCanada",
  initialState,
  reducers: {
    ZoomToAtlanticCanada: (
      state,
      action: PayloadAction<ZoomToAtlanticCanadaSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ZoomToAtlanticCanadaSlice.reducer;

export const { ZoomToAtlanticCanada } = ZoomToAtlanticCanadaSlice.actions;
