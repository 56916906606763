import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SeasonalFileHeadersSliceState {
  value: string[];
}

const initialState: SeasonalFileHeadersSliceState = {
  value: [],
};

export const SeasonalFileHeadersSlice = createSlice({
  name: "SeasonalFileHeaders",
  initialState,
  reducers: {
    changeSeasonalFileHeaders: (
      state,
      action: PayloadAction<SeasonalFileHeadersSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default SeasonalFileHeadersSlice.reducer;

export const { changeSeasonalFileHeaders } = SeasonalFileHeadersSlice.actions;
