import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoDataSummarySliceState {
  value: boolean;
}

const initialState: DataRepoDataSummarySliceState = {
  value: false,
};

export const DataRepoDataSummarySlice = createSlice({
  name: "DataSummary",
  initialState,
  reducers: {
    changeSummaryState: (
      state,
      action: PayloadAction<DataRepoDataSummarySliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoDataSummarySlice.reducer;

export const { changeSummaryState } = DataRepoDataSummarySlice.actions;
