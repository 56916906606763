import { Box, Typography, Stack, IconButton } from "@mui/material";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

interface HeadingProps {
  name: string;
  onClick: () => void;
}

export const DashboardTabsHead = ({ name, onClick }: HeadingProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "670px 1fr",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="body1"
          color="secondary.dark"
          sx={{ fontWeight: 700, m: "0.5rem 1rem" }}
        >
          {name}
        </Typography>
      </Box>
      <Box onClick={onClick} mr="1rem">
        <Stack direction="row">
          <IconButton
            color="primary"
            disableRipple
            sx={{
              mt: "-0.2rem",
            }}
          >
            <GetAppOutlinedIcon fontSize="medium" />
          </IconButton>
          <Typography
            color="primary"
            variant="body2"
            sx={{
              fontWeight: 700,
              mt: "0.5rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Download
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
