import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PolygonSliceState {
  value: boolean;
}

const initialState: PolygonSliceState = {
  value: false,
};

export const DataRepoPolygonSlice = createSlice({
  name: "polygon",
  initialState,
  reducers: {
    setPolygonCoordinatesValue: (state, action: PayloadAction<PolygonSliceState>) => {
      return (state = action.payload);
    },
  },
});

export const { setPolygonCoordinatesValue } = DataRepoPolygonSlice.actions;

export default DataRepoPolygonSlice.reducer;
