import { Autocomplete, Box, SelectChangeEvent, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import UploadDropdownSelect from "../UploadDropDownSelect";
import { changeFileDataFormat } from "../../../../features/DataRepoDataFormatSlice";
import { changeFileCRS } from "../../../../features/DataRepoCRSSlice";
import { changeFileDatum } from "../../../../features/DataRepoDatumSlice";
import { DataDescriptionDatePicker } from "../DataRepoDataDescription/DataDescriptionDatePicker";
import { changeDataRepositoryXCoordinate } from "../../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../../features/DataRepositoryYCoordinateSlice";
import { changeFileTimeZone } from "../../../../features/DataRepoTimeZoneSlice";
import { changeDataRepoSurfaceElevation } from "../../../../features/DataRepoSurfaceElevationSlice";
import DropdownSelect from "../../../DropDownList/DropDownSelect";
import { toolControls } from "../../../Openlayers/data_layers";
import { DropdownSelectDisbaled } from "../../../Navigation/SettingsModal";
import { getCoordinateReferenceSystemOptions } from "../../../../api/backend_public";

const fileFormatBathymetryOptions = [
  { value: "XYZ (.xyz)", label: "Comma Separated (.csv) / XYZ (.xyz) / Text (.txt)" },
  { value: "ASCII (.asc)", label: "ASCII (.asc)", disabled: true },
  // { value: "XYZ (.xyz)", label: "XYZ (.xyz)" },
  // { value: "XYZ (.xyz)", label: "Text (.txt)" },
  { value: "LiDar Point Cloud (.las)", label: "LiDar Point Cloud (.las)", disabled: true },
  { value: "Point Shapefile (.shp)", label: "Point Shapefile (.shp)" },
  { value: "Tagged Image File Format (.tiff)", label: "Tagged Image File Format (.tiff)", disabled: true },
];

const fileFormatFreeFormatOptions = [
  { value: "Comma Separated (.csv)", label: "Comma Separated (.csv)" },
  { value: "ASCII (.asc)", label: "ASCII (.asc)", disabled: true },
  { value: "Text (.txt)", label: "Text (.txt)" },
  { value: "JPG Image (.jpg)", label: "JPG Image (.jpg)" },
  { value: "PDF-file (.pdf)", label: "PDF-file (.pdf)" },
  { value: "PNG Image (.png)", label: "PNG Image (.png)" },
  { value: "Point Shapefile (.shp)", label: "Point Shapefile (.shp)" },
];

const fileFormatWaveOptions = [
  { value: "Comma Separated (.csv)", label: "Comma Separated (.csv)" },
  { value: "ASCII (.asc)", label: "ASCII (.asc)", disabled: true },
  { value: "Text (.txt)", label: "Text (.txt)" },
  { value: "MIKE DFS0 (.dfs0)", label: "MIKE DFS0 (.dfs0)" },
];

const fileFormatModelGridOptions = [
  { value: "MIKE DFS2 (.dfs2)", label: "MIKE DFS2 (.dfs2)" },
  { value: "MIKE DFSU (.dfsu)", label: "MIKE DFSU (.dfsu)" },
  { value: "MIKE MESH (.mesh)", label: "MIKE MESH (.mesh)" },
];

const fileFormatModelWaveOptions = [
  { value: "MIKE DFS2 (.dfs2)", label: "MIKE DFS2 (.dfs2)" },
  { value: "MIKE DFSU (.dfsu)", label: "MIKE DFSU (.dfsu)" },
  { value: "Tagged Image File Format (.tiff)", label: "Tagged Image File Format (.tiff)" },
  { value: "Network Common Data Form (.netcdf", label: "Network Common Data Form (.netcdf)" },
];

const fileFormatDrawingOptions = [{ value: "CAD Drawing (.dwg)", label: "CAD Drawing (.dwg)" }];

const verticalDatumOptions = [
  { value: "CGVD2013a", label: "CGVD2013a" },
  { value: "CGVD2013", label: "CGVD2013" },
  { value: "CGVD28", label: "CGVD28" },
];

const timeZoneOptions = [
  { value: "UTC", label: "UTC" },
  { value: "PST (UTC - 8:00)", label: "PST (UTC - 8:00)" },
  {
    value: "PDT/PST (UTC - 8:00 / UTC - 7:00)",
    label: "PDT/PST (UTC - 8:00 / UTC - 7:00)",
  },
  { value: "MST (UTC - 7:00)", label: "MST (UTC - 7:00)" },
  {
    value: "MDT/MST (UTC - 7:00 / UTC - 6:00)",
    label: "MDT/MST (UTC - 7:00 / UTC - 6:00)",
  },
  { value: "CST (UTC - 6:00)", label: "CST (UTC - 6:00)" },
  {
    value: "CDT/CST (UTC - 6:00 / UTC - 5:00)",
    label: "CDT/CST (UTC - 6:00 / UTC - 5:00)",
  },
  { value: "EST (UTC - 5:00)", label: "EST (UTC - 5:00)" },
  {
    value: "EDT/EST (UTC - 5:00 / UTC - 4:00)",
    label: "EDT/EST (UTC - 5:00 / UTC - 4:00)",
  },
  { value: "AST (UTC - 4:00)", label: "AST (UTC - 4:00)" },
  {
    value: "ADT/AST (UTC - 4:00 / UTC - 3:00)",
    label: "ADT/AST (UTC - 4:00 / UTC - 3:00)",
  },
  { value: "NST (UTC - 3:30)", label: "NST (UTC - 3:30)" },
  {
    value: "NDT/NST (UTC - 3:30 / UTC - 2:30)",
    label: "NDT/NST (UTC - 3:30 / UTC - 2:30)",
  },
];

interface validationProps {
  setRightValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
  fileDataTypeError: boolean;
  setFileDataTypeError: Dispatch<SetStateAction<boolean>>;
}

interface CRSDropdownOption {
  value: string;
  label: string;
  id: number;
  proj4String: string;
  wkt: string;
}

export const ConfigurationDetailsRight = ({
  setRightValidate,
  nextClicked,
  setNextClicked,
  fileDataTypeError,
  setFileDataTypeError,
}: validationProps) => {
  const dispatch = useDispatch();
  const fileformat = useSelector((state: RootState) => state.fileDataFormat.value);
  const CRS = useSelector((state: RootState) => state.fileCRS.CRS.value) || null;
  const verticalDatum = useSelector((state: RootState) => state.fileDatum.value);
  const timezone = useSelector((state: RootState) => state.timeZone.value);
  const dataType = useSelector((state: RootState) => state.fileDataType.value);
  const xcoordinate = useSelector((state: RootState) => state.xcoordinate.value);
  const ycoordinate = useSelector((state: RootState) => state.ycoordinate.value);
  const surfaceElevation = useSelector((state: RootState) => state.surfaceElevation.value);
  const [fileFormatError, setFileFormatError] = useState(true);
  const [CRSError, setCRSError] = useState(true);
  // const [verticalDatumError, setVerticalDatumError] = useState(true);
  const [timeZoneError, setTimeZoneError] = useState(true);
  const [xcoordinateError, setXcoordinateError] = useState(false);
  const [ycoordinateError, setYcoordinateError] = useState(false);
  const [xcoordinateInputError, setXcoordinateInputError] = useState(false);
  const [ycoordinateInputError, setYcoordinateInputError] = useState(false);
  const [surfaceElevationError, setSurfaceElevationError] = useState(false);
  const [CRSOptions, setCRSOptions] = useState<CRSDropdownOption[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isCRSOptionSelected, setIsCRSOptionSelected] = useState(false);
  const [delayedInputValue, setDelayedInputValue] = useState("");
  const [wktValue, setWktValue] = useState("");

  const getCRSValues = async () => {
    try {
      const response = await getCoordinateReferenceSystemOptions();
      const CRSData = response.data;

      const CRSOptions = CRSData.map((crs: any) => ({
        value: crs.name,
        label: crs.name,
        id: crs.id,
        proj4String: crs.proj4String,
        wkt: crs.wkt,
      }));

      return CRSOptions;
    } catch (error) {
      console.error("Error fetching CRS values:", error);
      return [];
    }
  };
  useEffect(() => {
    getCRSValues().then((options) => {
      setCRSOptions(options);
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedInputValue(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);
  const handleSurfaceElevationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!event.target.value) {
      dispatch(changeDataRepoSurfaceElevation({ value: null }));
    } else {
      dispatch(changeDataRepoSurfaceElevation({ value: Number(event.target.value) }));
    }
    setSurfaceElevationError(!event.target.value);
  };

  const handlefileformatChange = (event: SelectChangeEvent) => {
    dispatch(changeFileDataFormat({ value: event.target.value as string }));
    setFileFormatError(!event.target.value);
  };

  const handleTimeZoneChange = (event: SelectChangeEvent) => {
    dispatch(changeFileTimeZone({ value: event.target.value as string }));
    setTimeZoneError(!event.target.value);
  };

  const handleVerticalDatumChange = (event: SelectChangeEvent) => {
    dispatch(changeFileDatum({ value: event.target.value as string }));
    // setVerticalDatumError(!event.target.value);
  };

  function getUnitsFromProj4String(proj4String: string | undefined) {
    if (proj4String) {
      if (/proj=longlat/.test(proj4String) || /proj=latlong/.test(proj4String)) {
        return "degrees";
      } else if (/proj=/.test(proj4String)) {
        return "meters";
      }
    }
    return "unknown";
  }

  const handleCRSChange = (event: any, newValue: CRSDropdownOption | null) => {
    setIsCRSOptionSelected(!!newValue);
    const unitValue = getUnitsFromProj4String(newValue?.proj4String);
    setWktValue(unitValue);
    dispatch(
      changeFileCRS({
        CRS: newValue
          ? {
              value: newValue.value,
              id: newValue.id,
              proj4String: newValue.proj4String,
              wkt: unitValue,
            }
          : { value: "", id: null, proj4String: "", wkt: "" },
      })
    );
  };

  const handleXcoordinateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const numericValue = value ? Number(value) : null;
    dispatch(changeDataRepositoryXCoordinate({ value: numericValue }));

    // Validate the X coordinate immediately after change
    validateCoordinate("x", numericValue);
  };

  const handleYcoordinateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const numericValue = value ? Number(value) : null;
    dispatch(changeDataRepositoryYCoordinate({ value: numericValue }));

    validateCoordinate("y", numericValue);
  };

  const validateCoordinate = (axis: string, value: any) => {
    let error = false;
    if (wktValue === "degrees") {
      if (axis === "x") {
        error = value < -180 || value > 180;
      } else if (axis === "y") {
        error = value < -90 || value > 90;
      }
    } else if (wktValue === "meters") {
      error = value >= -1000 && value <= 1000;
    }
    if (axis === "x") {
      setXcoordinateInputError(error);
    } else if (axis === "y") {
      setYcoordinateInputError(error);
    }
  };

  const validateFields = () => {
    let xcoordinateError = false;
    let ycoordinateError = false;
    let surfaceElevationError = false;

    if (
      dataType !== toolControls.INFRASTRUCTUREDRAWING &&
      dataType !== toolControls.MODELGRIDMESH &&
      dataType !== toolControls.MODELRESULTSWAVE &&
      dataType !== toolControls.MODELRESULTSHYDRO
    ) {
      xcoordinateError = xcoordinate === null;
      ycoordinateError = ycoordinate === null;
    }

    if (dataType === "Borehole Logs") {
      surfaceElevationError = surfaceElevation === null;
    }

    setXcoordinateError(xcoordinateError);
    setYcoordinateError(ycoordinateError);
    setSurfaceElevationError(surfaceElevationError);

    return (
      xcoordinateError || ycoordinateError || surfaceElevationError || xcoordinateInputError || ycoordinateInputError
    );
  };

  useEffect(() => {
    setRightValidate(() => () => {
      const val = validateFields();

      let errorExists = fileFormatError || CRSError;

      if (dataType === toolControls.BATHYMETRY || dataType === "") {
        errorExists = errorExists;
      }

      if (dataType === toolControls.WATERLEVELTIMESEIRES) {
        errorExists = errorExists || timeZoneError;
        return errorExists || val;
      }

      if (dataType === toolControls.MISCFREEFORMAT) {
        errorExists = errorExists;
        return errorExists || val;
      }

      if (
        dataType === toolControls.BOREHOLELOGS ||
        dataType === toolControls.MODELRESULTSWAVE ||
        dataType === toolControls.INFRASTRUCTUREDRAWING ||
        (dataType === toolControls.MODELGRIDMESH && fileformat !== toolControls.MIKEMESH) ||
        dataType === toolControls.MODELRESULTSHYDRO
      ) {
        errorExists = errorExists;
        return errorExists || val;
      }

      if (
        dataType === toolControls.WAVESTIMESERIES ||
        dataType === toolControls.WINDTIMESERIES ||
        dataType === toolControls.CURRENTSTIMESERIES ||
        dataType === toolControls.DISCHARGETIMESERIES ||
        dataType === toolControls.ICETHICKNESSTIMESERIES
      ) {
        errorExists = errorExists || timeZoneError;
        return errorExists || val;
      }

      return errorExists && val;
    });
  }, [
    fileFormatError,
    CRSError,
    // verticalDatumError,
    timeZoneError,
    xcoordinateError,
    ycoordinateError,
    xcoordinateInputError,
    ycoordinateInputError,
    surfaceElevationError,
    dataType,
  ]);

  useEffect(() => {
    setFileFormatError(!fileformat);
    setCRSError(!CRS);
    // setVerticalDatumError(!verticalDatum);
    setFileDataTypeError(!dataType);
    setTimeZoneError(!timezone);
  }, [fileformat, CRS, verticalDatum, dataType, timezone]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <UploadDropdownSelect
            label="File Format"
            value={fileformat}
            onChange={handlefileformatChange}
            options={
              dataType === toolControls.BATHYMETRY
                ? fileFormatBathymetryOptions
                : dataType === toolControls.INFRASTRUCTUREDRAWING
                ? fileFormatDrawingOptions
                : dataType === toolControls.MODELGRIDMESH
                ? fileFormatModelGridOptions
                : dataType === toolControls.MODELRESULTSWAVE || dataType === toolControls.MODELRESULTSHYDRO
                ? fileFormatModelWaveOptions
                : dataType === toolControls.MISCFREEFORMAT || dataType === toolControls.BOREHOLELOGS
                ? fileFormatFreeFormatOptions
                : fileFormatWaveOptions
            }
            error={nextClicked && fileFormatError}
          />
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          {CRS !== undefined && (
            <Autocomplete
              value={CRS ? CRSOptions.find((option) => option.value === CRS) ?? null : null}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setIsCRSOptionSelected(false);
              }}
              onChange={handleCRSChange}
              options={CRSOptions.filter(
                (option) =>
                  option.label.toLowerCase().includes(delayedInputValue.toLowerCase()) ||
                  option.id.toString().includes(delayedInputValue)
              )}
              getOptionLabel={(option) => `${option.label} (Code: ${option.id})`}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Box>{option.label}</Box>
                    <Box style={{ fontSize: "smaller" }}>Code - {option.id}</Box>
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Coordinate Reference System" error={nextClicked && CRSError} />
              )}
              fullWidth
              size="small"
              open={!isCRSOptionSelected && delayedInputValue.length > 0}
            />
          )}
        </Box>
        {dataType === toolControls.BATHYMETRY || dataType === "" ? (
          <>
            <Box width="20rem" mt="0.75rem" ml="1rem">
              <DropdownSelectDisbaled
                label="Vertical Datum"
                value={"CGVD2013"}
                onChange={handleVerticalDatumChange}
                options={verticalDatumOptions}
                // error={nextClicked && verticalDatumError}
              />
            </Box>
            <Box width="20rem" mt="0.75rem" ml="1rem">
              <DataDescriptionDatePicker />
            </Box>
          </>
        ) : (
          dataType !== toolControls.INFRASTRUCTUREDRAWING &&
          dataType !== toolControls.MODELGRIDMESH &&
          dataType !== toolControls.MODELRESULTSWAVE &&
          dataType !== toolControls.MODELRESULTSHYDRO && (
            <>
              <Box display="flex" flexDirection="row">
                <TextField
                  label="X Coordinate"
                  error={(nextClicked && xcoordinateError) || xcoordinateInputError}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={xcoordinate === null ? "" : xcoordinate}
                  onChange={(event) => handleXcoordinateChange(event)}
                  disabled={!isCRSOptionSelected}
                  InputLabelProps={{
                    style: {
                      color: "#86A2B3",
                    },
                  }}
                  sx={{
                    mt: "0.75rem",
                    ml: "1rem",
                    width: "9.5rem",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#86A2B3",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#86A2B3",
                    },
                  }}
                />
                <TextField
                  label="Y Coordinate"
                  error={(nextClicked && ycoordinateError) || ycoordinateInputError}
                  variant="outlined"
                  size="small"
                  type="number"
                  value={ycoordinate === null ? "" : ycoordinate}
                  onChange={(event) => handleYcoordinateChange(event)}
                  disabled={!isCRSOptionSelected}
                  InputLabelProps={{
                    style: {
                      color: "#86A2B3",
                    },
                  }}
                  sx={{
                    mt: "0.75rem",
                    ml: "1rem",
                    width: "9.5rem",
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#86A2B3",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#86A2B3",
                    },
                  }}
                />
              </Box>
              {dataType !== toolControls.BOREHOLELOGS && dataType !== toolControls.MISCFREEFORMAT && (
                <Box width="20rem" mt="0.75rem" ml="1rem">
                  <UploadDropdownSelect
                    label="Time Zone"
                    value={timezone}
                    onChange={handleTimeZoneChange}
                    options={timeZoneOptions}
                    error={nextClicked && timeZoneError}
                  />
                </Box>
              )}
              {dataType === toolControls.MISCFREEFORMAT && (
                <Box width="20rem" mt="0.75rem" ml="1rem">
                  <DropdownSelect
                    label="Time Zone (Optional)"
                    value={timezone}
                    onChange={handleTimeZoneChange}
                    options={timeZoneOptions}
                  />
                </Box>
              )}
              {dataType === toolControls.BOREHOLELOGS && (
                <Box width="20rem" mt="0.75rem" ml="1rem">
                  <TextField
                    label="Surface Elevation"
                    error={nextClicked && surfaceElevationError}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={surfaceElevation === null ? "" : surfaceElevation}
                    onChange={(event) => handleSurfaceElevationChange(event)}
                    InputLabelProps={{
                      style: {
                        color: "#86A2B3",
                      },
                    }}
                    sx={{
                      width: "20rem",
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#86A2B3",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#86A2B3",
                      },
                    }}
                  />
                </Box>
              )}
            </>
          )
        )}
        {(dataType === toolControls.WATERLEVELTIMESEIRES ||
          dataType === toolControls.INFRASTRUCTUREDRAWING ||
          dataType === toolControls.BOREHOLELOGS ||
          dataType === toolControls.MODELRESULTSWAVE ||
          dataType === toolControls.MODELRESULTSHYDRO ||
          (dataType === toolControls.MODELGRIDMESH && fileformat !== toolControls.MIKEMESH)) && (
          <Box width="20rem" mt="0.75rem" ml="1rem">
            <DropdownSelectDisbaled
              label="Vertical Datum"
              value={"CGVD2013"}
              onChange={handleVerticalDatumChange}
              options={verticalDatumOptions}
              // error={nextClicked && verticalDatumError}
            />
          </Box>
        )}
        {dataType === toolControls.MISCFREEFORMAT && (
          <Box width="20rem" mt="0.75rem" ml="1rem">
            <DropdownSelectDisbaled
              label="Vertical Datum (Optional)"
              value={"CGVD2013"}
              onChange={handleVerticalDatumChange}
              options={verticalDatumOptions}
            />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};
