import { Box, Typography } from "@mui/material";
import { DashboardWaterLevelTable } from "../DashboardWaterLevelTable/DashboardWaterLevelTable";
import { DashboardWaterLevelDropDown } from "./DashboardWaterLevelDropDown";
import { DashboardTabsHead } from "../DashboardTabsHead";
import { DashboardWaterLevelChart } from "./DashboardWaterLevelChart";
import { useEffect, useState } from "react";

export const DashboardWaterLevelTab = ({ port }: any) => {
  const [data, setData] = useState<any[]>([]);
  const [assetElevation, setAssetElevation] = useState<number | null>(null);
  const [bedElevation, setBedElevation] = useState<number | null>(null);
  const [seaLevelSCValue, setSeaLevelSCValue] = useState("RCP 2.6 (mean)");
  const [seaLevelHOValue, setSeaLevelHOValue] = useState("2100");
  const [seaLevelModelValue, setSeaLevelModelValue] = useState("CAN-EWLAT (Allowance)");
  const [waterLevelAEPValue, setWaterLevelAEPValue] = useState("100% AEP");
  const [HATValue, setHATValue] = useState<any>();
  const [EWLValue, setEWLValue] = useState<string>("");
  const [hat, setHat] = useState<any>();
  const [slrValue, setSlrValue] = useState<string>("");

  const handleDownload = () => {
    const variables = [
      {
        name: "Total Still Water Level (TWL)",
        value: EWLValue !== "" && slrValue !== "" ? (Number(EWLValue) + Number(slrValue)).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      { name: "Relative Sea Level Rise (SLR)", value: Number(slrValue)?.toFixed(2), unit: "m" },
      { name: "Extreme Water Level (EWL)", value: Number(EWLValue)?.toFixed(2), unit: "m" },
      {
        name: "Highest Astronomical Tide (HAT)",
        value: HATValue ? Number(HATValue[0]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      {
        name: "Higher High Water Larger Tide (HHWLT)",
        value: HATValue ? Number(HATValue[1]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      {
        name: "Higher High Water Mean Tide (HHWMT)",
        value: HATValue ? Number(HATValue[2]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      {
        name: "Mean Water Level (MWL)",
        value: HATValue ? Number(HATValue[3]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      {
        name: "Lower Low Water Mean Tide (LLWMT)",
        value: HATValue ? Number(HATValue[4]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
      {
        name: "Lower Low Water Large Tide (LLWLT)",
        value: HATValue ? Number(HATValue[5]).toFixed(2) : "N/A",
        unit: "m, CGVD2013",
      },
    ];

    const header = "Variable,Value,Unit\n";
    const csvContent = variables.reduce((acc, { name, value, unit }) => {
      return acc + `${name},${value === "" ? "Loading..." : Number(value).toFixed(2)},${unit}\n`;
    }, header);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "water_level_details.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (assetElevation !== null && bedElevation !== null) {
      const LLWLT = HATValue[5];
      const MWL = HATValue[3];
      const HHWLT = HATValue[1];
      const TWL = (Number(EWLValue) + Number(slrValue)).toFixed(2);
      const EWL = EWLValue;

      const newData = [
        {
          name: "Bed Elevation",
          data: [bedElevation, bedElevation],
          color: "#001BCB",
        },
        {
          name: "LLWLT",
          data: [LLWLT, LLWLT],
          color: "#6495ED",
        },
        {
          name: "MWL",
          data: [MWL, MWL],
          color: "#00A4EC",
        },
        {
          name: "HHWLT",
          data: [HHWLT, HHWLT],
          color: "#19EDFF",
        },
        {
          name: "EWL",
          data: [EWL, EWL],
          color: "rgba(25, 237, 255, 0.6)",
        },
        {
          name: "TWL",
          data: [TWL, TWL],
          color: "rgba(25,237,255,0.3)",
        },
        {
          name: "Asset",
          data: [assetElevation, assetElevation],
          color: "#FFFFFF",
        },
      ];
      setData(newData);
    }
  }, [EWLValue, slrValue, HATValue, assetElevation, bedElevation]);
  return (
    <Box>
      <DashboardTabsHead name="Water Levels" onClick={handleDownload} />
      <DashboardWaterLevelDropDown
        assetElevation={assetElevation}
        setAssetElevation={setAssetElevation}
        bedElevation={bedElevation}
        setBedElevation={setBedElevation}
        seaLevelSCValue={seaLevelSCValue}
        setSeaLevelSCValue={setSeaLevelSCValue}
        setSeaLevelHOValue={setSeaLevelHOValue}
        setSeaLevelModelValue={setSeaLevelModelValue}
        seaLevelHOValue={seaLevelHOValue}
        seaLevelModelValue={seaLevelModelValue}
        waterLevelAEPValue={waterLevelAEPValue}
        setWaterLevelAEPValue={setWaterLevelAEPValue}
      />
      <Box sx={{ bgcolor: "grey.50", overflowY: "auto" }} height="calc(100vh - 19rem)">
        <DashboardWaterLevelTable
          assetElevation={assetElevation}
          bedElevation={bedElevation}
          seaLevelSCValue={seaLevelSCValue}
          seaLevelHOValue={seaLevelHOValue}
          seaLevelModelValue={seaLevelModelValue}
          waterLevelAEPValue={waterLevelAEPValue}
          setHATValue={setHATValue}
          HATValue={HATValue}
          EWLValue={EWLValue}
          slrValue={slrValue}
          setSlrValue={setSlrValue}
          setEWLValue={setEWLValue}
          hat={hat}
          setHat={setHat}
        />
        <DashboardWaterLevelChart data={data} assetElevation={assetElevation} bedElevation={bedElevation} />
        <Box
          bgcolor="#FFFFFF"
          sx={{
            m: "1rem 1.5rem 2rem 1.8rem",
            width: "46rem",
            border: "1px solid #CFDBE2",
            borderRadius: "8px",
            boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
            cursor: "default",
            p: "1rem",
          }}
        >
          <Typography color="grey.500" variant="body2" mb="0.75rem">
            Warnings:
          </Typography>
          <Box>
            {hat && hat.WL_selec !== 0 ? (
              <Typography color="grey.500" variant="caption">
                {`The closest water level observation statistics from station, ${
                  hat.WL_selec
                }, (latitude ${hat.WL_lat.toFixed(1)}, longitude ${hat.WL_lon.toFixed(1)}), is located
             ${parseFloat(hat.WL_dstkm).toFixed(1)} km from the ${port.get(
                  "Harbour_Na"
                )}. It is the responsibility of the user to evaluate the applicability of this
            data at this location.`}
              </Typography>
            ) : (
              <Typography color="grey.500" variant="caption">
                {`No water level observation statistics close to ${port.get("Harbour_Na")} are available.`}
              </Typography>
            )}
          </Box>
          {hat && hat.TH_selec !== 0 ? (
            <Typography color="grey.500" variant="caption">
              {`The closest tidal height predictions station, ${hat.TH_selec}, (latitude ${hat.TH_lat.toFixed(
                1
              )}, longitude ${hat.TH_lon.toFixed(1)}), is located  ${parseFloat(hat.TH_dstkm).toFixed(
                1
              )} km from the ${port.get(
                "Harbour_Na"
              )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
            </Typography>
          ) : (
            <Typography color="grey.500" variant="caption">
              {`No tidal height predictions close to ${port.get("Harbour_Na")} are available.`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
