import { IconButton, Typography, Box, Backdrop, Modal, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const SeasonalStatisticsInfoModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "50vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "45rem" },
          }}
        >
          <Box display="flex" flexDirection="row" ml="0.5rem" justifyContent="space-between">
            <Box display="flex" flexDirection="row">
              <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem">
                Seasonal & Monthly Statistics
              </Typography>
            </Box>
            <IconButton sx={{ mr: "0.5rem" }} disableRipple onClick={handleClose}>
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box m="0.5rem 1rem 1rem 1rem" display="flex" flexDirection="column" gap="1rem">
            <Typography variant="body2" color="secondary.dark">
              1. Upload one timeseries dataset from your device or the data repository.
            </Typography>

            <Typography variant="body2" color="secondary.dark">
              2. Select a variable to analyze.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              3. Select a date range for analysis.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              4. Select to calculate either monthly, seasonal, or annual statistics. Seasonal statistics are calculated
              for: "Dec - Feb" "Mar - May" "Jun - Aug" and "Sep - Nov".
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              5. Click "Generate Statistics".
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              6. Explore various statistics, including min and max, lower and upper quartiles, mean, and median.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              7. Download your statistics in jpg (graph) or csv (tabular) format.
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
