import { useOidcIdToken } from "@axa-fr/react-oidc";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface checkedProps {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

const DashboardSwitch = ({ checked, setChecked }: checkedProps) => {
  const { idToken, idTokenPayload } = useOidcIdToken();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const control = (
    <FormControlLabel
      label={
        <Typography color={idTokenPayload ? "primary.main" : "grey.400"} variant="h4">
          Show only favourites
        </Typography>
      }
      control={<Switch checked={checked} onChange={handleChange} size="medium" />}
      disabled={!idTokenPayload}
    />
  );

  return (
    <Box>
      {!idTokenPayload ? (
        <LightTooltip title={"Login to see your favorite ports."} placement="right-start">
          {control}
        </LightTooltip>
      ) : (
        control
      )}
    </Box>
  );
};
export default DashboardSwitch;
