import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoSurfaceElevationState {
  value: number | null;
}

const initialState: DataRepoSurfaceElevationState = {
  value: null,
};

export const DataRepoSurfaceElevationStateSlice = createSlice({
  name: "surfaceElevation",
  initialState,
  reducers: {
    changeDataRepoSurfaceElevation: (
      state,
      action: PayloadAction<DataRepoSurfaceElevationState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoSurfaceElevationStateSlice.reducer;

export const { changeDataRepoSurfaceElevation } =
  DataRepoSurfaceElevationStateSlice.actions;
