import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  ReactNode,
} from "react";

interface SeasonalFileContextType {
  newFile: File | null;
  setNewFile: Dispatch<SetStateAction<File | null>>;
}

export const SeasonalFileContext = createContext<
  SeasonalFileContextType | undefined
>(undefined);

interface SeasonalFileProviderProps {
  children: ReactNode;
}

export const SeasonalFileProvider: React.FC<SeasonalFileProviderProps> = ({
  children,
}) => {
  const [newFile, setNewFile] = useState<File | null>(null);

  return (
    <SeasonalFileContext.Provider value={{ newFile, setNewFile }}>
      {children}
    </SeasonalFileContext.Provider>
  );
};
