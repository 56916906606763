import { Fill, Icon, Stroke, Style } from "ol/style";

export const iconTypes = {
  Bathymetry: new Style({
    image: new Icon({
      src: "icons/Bathymetry.svg",
    }),
  }),
  Bathymetry_hover: new Style({
    image: new Icon({
      src: "icons/Bathymetry_hover.svg",
    }),
  }),
  Winds: new Style({
    image: new Icon({
      src: "icons/Winds.svg",
    }),
  }),
  Winds_hover: new Style({
    image: new Icon({
      src: "icons/Winds_hover.svg",
    }),
  }),
  Waves: new Style({
    image: new Icon({
      src: "icons/Waves.svg",
    }),
  }),
  Wave_hover: new Style({
    image: new Icon({
      src: "icons/Waves_hover.svg",
    }),
  }),
  Currents: new Style({
    image: new Icon({
      src: "icons/Currents.svg",
    }),
  }),
  Currents_hover: new Style({
    image: new Icon({
      src: "icons/Currents_hover.svg",
    }),
  }),
};

export const collectionStyle = {
  Polygon: new Style({
    stroke: new Stroke({
      color: "rgba(233, 150, 122, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(233, 150, 122, 0.1)",
    }),
  }),
};
