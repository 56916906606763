import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LegendContainer } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import { Directions, AEP } from "../../../assets/BoundaryConditions";
import { toolControls } from "../../Openlayers/data_layers";
import { WindGroup } from "../../Openlayers/default_groups_layers";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "./BathymetryLegend";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";

export default function WindLegend() {
  const toolname = new URLSearchParams(location.search).get("tool");

  const [dataSetLayers, setDataSetLayers] = React.useState<any[] | null>(null);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);
  const [selectedDimensionIndex, setSelectedDimensionIndex] = React.useState<number>(0);
  const [selectedAEPIndex, setSelectedAEPIndex] = React.useState<number>(0);

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    getTileDataSetsMetadata(publicDataSets.Wind.id).then((response) => {
      setDataSetLayers(response.tilingSchemes.splice(8, response.tilingSchemes.length));
      setDataSetMetadata(response);
    });

    return () => {};
  }, []);

  useEffect(() => {
    const refreshIntervalId = setInterval(function () {
      if (WindGroup.getLayersArray().length > 0 && WindGroup.getLayersArray().length === 136) {
        clearInterval(refreshIntervalId);
        WindGroup.getLayersArray()[8].setVisible(true);
        WindGroup.changed();
      }
    }, 200);

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 12000000);

    return () => {};
  }, []);

  const handleChangeDimension = (e: any) => {
    const lookUpIndex = Directions.indexOf(e.target.value) * 8 + AEP.indexOf(AEP[selectedAEPIndex]);
    setSelectedDimensionIndex(Directions.indexOf(e.target.value));

    const spliceLayers = WindGroup.getLayersArray().splice(8, WindGroup.getLayersArray().length);
    spliceLayers.map((l: any) => l.setVisible(false));
    spliceLayers[lookUpIndex].setVisible(true);

    WindGroup.changed();
  };

  const handleChangeWindAEP = (e: any) => {
    const lookUpIndex = Directions.indexOf(Directions[selectedDimensionIndex]) * 8 + AEP.indexOf(e.target.value);
    setSelectedAEPIndex(AEP.indexOf(e.target.value));

    const spliceLayers = WindGroup.getLayersArray().splice(8, WindGroup.getLayersArray().length);
    spliceLayers.map((l: any) => l.setVisible(false));
    spliceLayers[lookUpIndex].setVisible(true);

    WindGroup.changed();
  };

  return (
    <>
      {toolname === toolControls.WIND && (
        <>
          <LegendContainer style={{ display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Wind speed
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Spatial wind data is derived from the MSC50 hindcast model of 10 m continuous wind speed.
                      </Typography>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        For the original MSC50 data, definitions, etc. please see:{" "}
                        <a
                          href="https://www.meds-sdmm.dfo-mpo.gc.ca/isdm-gdsi/waves-vagues/MSC50-eng.html"
                          target="_blank"
                        >
                          https://www.meds-sdmm.dfo-mpo.gc.ca/isdm-gdsi/waves-vagues/MSC50-eng.html
                        </a>
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Spatial wind data derived from MSC50 data are presented for display purposes only.
                      </Typography>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        CBCL and DHI assume no liability for the accuracy of results and applicable. Please refer to the
                        Terms & Conditions for more information.
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem", ml: "-0.5rem" }} disableRipple>
                    <ReportProblemOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>
              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <DropdownSelect
                  label="Variable"
                  value="0"
                  onChange={() => {}}
                  options={[
                    {
                      label: "Wind speed",
                      value: 0,
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <DropdownSelect
                  label="Direction"
                  value={Directions[selectedDimensionIndex]}
                  onChange={handleChangeDimension}
                  options={Directions.map((d: any) => {
                    return { label: d, value: d };
                  })}
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <DropdownSelect
                  label="AEP (%)"
                  value={AEP[selectedAEPIndex]}
                  onChange={handleChangeWindAEP}
                  options={AEP.map((d: any) => {
                    return { label: d, value: d };
                  })}
                />
              </Grid>
            </Grid>

            <ScaleBar
              tilingScheme={dataSetMetadata?.tilingSchemes[0]}
              schemeId={dataSetLayers ? dataSetLayers[0].schemeId : ""}
            />
          </LegendContainer>
          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
}
