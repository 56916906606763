import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import dayjs from "dayjs";
import { changeDataCollectionDate } from "../../../../features/ToolboxFeatures/DataCollectionDateSlice";

export const SelectDate = () => {
  const dispatch = useDispatch();
  const selectedDateString = useSelector(
    (state: RootState) => state.dataCollectionDate.value
  );

  const selectedDate = selectedDateString
    ? dayjs(new Date(selectedDateString))
    : null;

  return (
    <Box>
      <DatePicker
        sx={{
          width: "20rem",
          "& .MuiInputLabel-root": {
            color: "#86A2B3",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#86A2B3",
            },
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiSvgIcon-root": {
            color: "#09334B",
          },
        }}
        value={selectedDate}
        onChange={(value) =>
          dispatch(
            changeDataCollectionDate({
              value: value ? value.toISOString() : null,
            })
          )
        }
        label="Data Collection Date (Optional)"
      />
    </Box>
  );
};
