import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import FrontPage from "../../pages/FrontPage";
import DefaultPage from "../../pages/DefaultPage";
import { AppPages } from "../Navigation/Navigation";
import { DataRepoProcessedFilesProvider } from "../DataRepositoryComponents/DataRepoContext/DataRepoContext";
import { SnackbarProvider } from "notistack";
import { FrontPageModal } from "../FrontPageComponents/FrontPageModal";

function App() {
  const [openedOnce, setOpenedOnce] = useState(() => {
    const hasOpenedOnce = localStorage.getItem("modalOpenedOnce");
    return hasOpenedOnce === "true";
  });

  const handleCloseModal = () => {
    setOpen(false);
    setOpenedOnce(true);
    localStorage.setItem("modalOpenedOnce", "true");
  };

  const [open, setOpen] = useState(!openedOnce);

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <DataRepoProcessedFilesProvider>
          <Routes>
            <Route path={AppPages.HOME} element={<FrontPage />} />
            <Route path={AppPages.DASHBOARD} element={<DefaultPage />} />
            <Route path={AppPages.REPOSETORY} element={<DefaultPage />} />
            <Route path={AppPages.TOOLBOX} element={<DefaultPage />} />
          </Routes>
        </DataRepoProcessedFilesProvider>
      </SnackbarProvider>
      {!openedOnce && <FrontPageModal open={open} handleClose={handleCloseModal} />}
    </>
  );
}

export default App;
