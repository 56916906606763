import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  points: { name: string; coordinates: [number, number]; datatype: string }[];
} = {
  points: [],
};

const mapPointsSlice = createSlice({
  name: "mapPoints",
  initialState,
  reducers: {
    addPoint: (
      state,
      action: PayloadAction<{
        name: string;
        coordinates: [number, number];
        datatype: string;
      }>
    ) => {
      state.points.push(action.payload);
    },
    deletePoint: (state, action: PayloadAction<{ name: string }>) => {
      state.points = state.points.filter((point) => {
        return point.name !== action.payload.name;
      });
    },
  },
});

export const { addPoint, deletePoint } = mapPointsSlice.actions;
export default mapPointsSlice.reducer;
