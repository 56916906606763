import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoUploadPressedSliceState {
  value: boolean;
}

const initialState: DataRepoUploadPressedSliceState = {
  value: false,
};

export const DataRepoUploadPressedSlice = createSlice({
  name: "uploadPressed",
  initialState,
  reducers: {
    changeUploadPressedState: (state, action: PayloadAction<DataRepoUploadPressedSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoUploadPressedSlice.reducer;

export const { changeUploadPressedState } = DataRepoUploadPressedSlice.actions;
