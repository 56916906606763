import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashboardSearchbarState {
  value: string;
}

const initialState: DashboardSearchbarState = {
  value: "",
};

export const DashboardSearchbarSlice = createSlice({
  name: "searchbar",
  initialState,
  reducers: {
    changeDashboardSearchInput: (
      state,
      action: PayloadAction<DashboardSearchbarState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DashboardSearchbarSlice.reducer;

export const { changeDashboardSearchInput } = DashboardSearchbarSlice.actions;
