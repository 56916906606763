import { Box, Typography, Divider, Stack } from "@mui/material";
import { DashboardIceTabTableColumn } from "./DashboardIceTabTableColumn";
import { useSearchParams } from "react-router-dom";
import { getGisVectorData } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface TableProps {
  setCisValue: Dispatch<SetStateAction<string>>;
  setDetailedInfo: Dispatch<SetStateAction<any>>;
}

export const DashboardIceTabTable = ({ setCisValue, setDetailedInfo }: TableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const port_number = searchParams.get("port");
  const [maxmeansValue, setMaxmeansValue] = useState("");

  const getLocationDetails = async () => {
    const locationData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const locationDetails = locationData.features.filter(
      (item: any) => item.properties?.Harbour_nu === Number(port_number)
    )[0].properties;
    return locationDetails;
  };

  const getmaxmeansValue = async (details: any) => {
    if (!details || details.CIS_selec === "") {
      return "N/A";
    }

    const response = await getGisVectorData(publicDataSets.IceThicknessLocations12a.id);
    if (!response || !response.features || response.features.length === 0) {
      return "N/A";
    }
    const matchingFeature = response.features.find((feature: any) => feature.properties.stn_id === details.CIS_selec);
    return matchingFeature ? matchingFeature.properties.max_meas : "N/A";
  };

  useEffect(() => {
    const fetchData = async () => {
      const details = await getLocationDetails();
      if (details) {
        setDetailedInfo(details);
        setCisValue(details.CIS_selec);
        const maxmeansvalue = await getmaxmeansValue(details);
        setMaxmeansValue(maxmeansvalue);
      }
    };
    fetchData();
  }, [port_number]);

  return (
    <Box
      bgcolor="#FFFFFF"
      m="1rem"
      borderRadius="8px"
      border="1px solid #CFDBE2"
      boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
    >
      <DashboardIceTabTableColumn
        mainheading="Maximum Climatology Thickness"
        captionheading="CIS Ice Charts: 1990-2020 Climatology"
        value="Coming in Phase 3!"
        tooltipValue="Ice thickness is derived from the predominant ice type when ice is present. Average ice thicknesses are obtained across the 1990-2020 period in weekly increments. The maximum is calculated as the highest value across these weekly climatologies."
      />
      <Divider />
      <DashboardIceTabTableColumn
        mainheading="Maximum Measured Thickness"
        captionheading="CIS Ice Measurements"
        value={maxmeansValue !== "N/A" ? `${maxmeansValue} cm` : "N/A"}
        tooltipValue="The maximum recorded ice thickness over the duration of the measurement record. Ice thicknesses are measured based on one of several methods, which may include an ice auger kit or visual estimation."
      />
      <Divider />
      <DashboardIceTabTableColumn
        mainheading="Freeze-Up Date"
        captionheading="CIS Ice Charts: 1990-2020 Climatology"
        value="Coming in Phase 3!"
        tooltipValue="Average Freeze-Up Date based on the 1990-2020 Climatology."
      />
      <Divider />
      <DashboardIceTabTableColumn
        mainheading="Break-Up Date"
        captionheading="CIS Ice Charts: 1990-2020 Climatology"
        value="Coming in Phase 3!"
        tooltipValue="Average Break-Up Date based on the 1990-2020 Climatology."
      />
    </Box>
  );
};
