import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SnackbarSliceState {
  value: boolean;
}

const initialState: SnackbarSliceState = {
  value: false,
};

export const DataRepoSnackbarSliceState = createSlice({
  name: "Snackbar",
  initialState,
  reducers: {
    changeSnackbarState: (state, action: PayloadAction<SnackbarSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoSnackbarSliceState.reducer;

export const { changeSnackbarState } = DataRepoSnackbarSliceState.actions;
