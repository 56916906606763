import React from "react";
import { Box } from "@mui/material";

interface toolboxProps {
  value: React.ReactNode;
}

export const ToolboxMainPage = ({ value }: toolboxProps) => {
  return (
    <Box bgcolor="#FFFFFF" width="100%" zIndex="100" boxShadow="4px 0 8px 0 rgba(0, 0, 0, 0.15)" height="100vh">
      {value}
    </Box>
  );
};
