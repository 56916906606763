import { Box, Typography, IconButton, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface WaterLevelTabProps {
  name: string;
  caption: string;
  value: number | string;
  unit: string;
  title: string;
}

export const WaterLevelDetailsWithCaption = ({ name, caption, value, title, unit }: WaterLevelTabProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "4fr 0.5fr 1fr",
        justifyContent: "space-between",
        height: "3rem",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Stack direction="column">
          <Typography variant="body2" color="secondary.dark" sx={{ mt: "0.5rem", ml: "1rem" }}>
            {name}
          </Typography>
          <Typography variant="caption" color="grey.500" sx={{ ml: "1rem" }}>
            {caption}
          </Typography>
        </Stack>
        <LightTooltip title={title} placement="right" enterDelay={500} leaveDelay={200}>
          <IconButton
            disableRipple
            sx={{
              "&:hover": {
                color: "#09334B",
              },
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </LightTooltip>
      </Box>

      <Typography variant="body2" color="secondary.dark" sx={{ m: "0.75rem", fontWeight: "700", textAlign: "center" }}>
        {value}
      </Typography>
      <Typography variant="body2" color="secondary.dark" sx={{ m: "0.75rem", textAlign: "start" }}>
        {unit}
      </Typography>
    </Box>
  );
};
