import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CRSDetails {
  value: string;
  id: number | null;
  proj4String: string;
  wkt: string;
}

interface FileCRSSliceState {
  CRS: CRSDetails;
}

const initialState: FileCRSSliceState = {
  CRS: {
    value: "",
    id: null,
    proj4String: "",
    wkt: "",
  },
};

export const DataRepoFileCRSSlice = createSlice({
  name: "fileCRS",
  initialState,
  reducers: {
    changeFileCRS: (state, action: PayloadAction<{ CRS: CRSDetails }>) => {
      state.CRS = action.payload.CRS;
    },
  },
});

export default DataRepoFileCRSSlice.reducer;

export const { changeFileCRS } = DataRepoFileCRSSlice.actions;
