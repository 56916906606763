import * as React from "react";
import { Alert, AlertColor, Box, Snackbar, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UploadDataFiles, { useFileContext } from "./UploadDataFiles";
import { RootState } from "../../../store/store";
import { DataDescription } from "./DataDescription/DataDescription";
import { ToolboxImportOptions } from "./ImportOptions/ToolboxImportOptions";
import { changeToolboxProcessFileState } from "../../../features/ToolboxFeatures/ToolboxProcessFileSlice";
import { changeToolboxActivateUpload } from "../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { changeToolboxFileName } from "../../../features/ToolboxFeatures/ToolBoxFileName";
import { changeToolboxFileDescription } from "../../../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import { changeToolboxFileFormat } from "../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeDataCollectionDate } from "../../../features/ToolboxFeatures/DataCollectionDateSlice";
import { changeToolboxVerticalDatum } from "../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { ProcessedFilesContext } from "./ProcessedFilesContext";
import { v4 as uuidv4 } from "uuid";
import {
  convertBathymetryUpload,
  convertUpload,
  createPersonalProject,
  getSubProjectList,
  uploadFiles,
} from "../../../api/backend";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { publicDataSets } from "../../../api/init";
import { setFileUID, setUploadCompleted } from "../../../features/DataRepoUploadStatusSlice";
import {
  setBathymetryToolboxFileUID,
  setBathymetryToolboxUploadCompleted,
  setBathymetryToolboxUploadPressed,
} from "../../../features/ToolboxFeatures/BathymetryToolboxSlice";
import { changeBathyInterpRefreshState } from "../../../features/ToolboxBathymetryInterpolationImportSlice";

const steps = ["Upload", "Data Description", "Import Options"];

interface stepperProps {
  handleClose: () => void;
  files: File | null;
  setFiles: React.Dispatch<React.SetStateAction<File | null>>;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
  checked: boolean;
};
export default function DataStepper({ handleClose, files, setFiles, checked, setChecked }: stepperProps) {
  const processedFilesContext = React.useContext(ProcessedFilesContext);
  if (!processedFilesContext) {
    throw new Error("Component must be used within a ProcessedFilesProvider");
  }
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const { newFile, setNewFile } = useFileContext();
  const toolboxUpload = useSelector((state: RootState) => state.toolboxUpload.value);
  const [nextClicked, setNextClicked] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [processedFile, setProcessedFile] = useState<File | null>(null);
  const { processedFiles, setProcessedFiles } = processedFilesContext;
  const [errorLeftExists, setErrorLeftExists] = useState(false);
  const [errorRightExists, setErrorRightExists] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const filename = useSelector((state: RootState) => state.toolboxFileName.value);
  const fileDescription = useSelector((state: RootState) => state.ToolboxFileDescription.value);
  const fileCRS = useSelector((state: RootState) => state.toolboxCRS.CRS);
  const fileDatum = useSelector((state: RootState) => state.toolboxVerticalDatum.value);
  const { idTokenPayload } = useOidcIdToken();
  const [leftValidate, setLeftValidate] = useState<() => boolean>(() => () => false);
  const [rightValidate, setRightValidate] = useState<() => boolean>(() => () => false);
  const fileSurveryDate = useSelector((state: RootState) => state.dataCollectionDate.value);
  const toolboxRightsValue = useSelector((state: RootState) => state.toolboxRights.value);
  const fileformat = useSelector((state: RootState) => state.toolboxFileFormat.value);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success");

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleNext = () => {
    if (activeStep === 1) {
      const errorRight = rightValidate();
      const errorLeft = leftValidate();

      setErrorLeftExists(errorLeft);
      setErrorRightExists(errorRight);

      if (errorLeft || errorRight) {
        setDisplayError(true);
        setShouldValidate(true);
        return;
      }

      if (fileformat === "LiDar Point Cloud (.las)" || fileformat === "Point Shapefile (.shp)") {
        dispatch(changeToolboxActivateUpload({ value: true }));
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (filename !== "" && fileformat !== "" && fileDescription !== "" && fileCRS.value !== "" && fileDatum !== "") {
      setDisplayError(false);
    }
  }, [filename, fileformat, fileDescription, fileCRS, fileDatum]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextClicked(false);
    if (activeStep === 1) {
      setDisplayError(false);
    }
  };

  const handleUpload = () => {
    handleClose();
    dispatch(setBathymetryToolboxUploadPressed(true));
    setNewFile(null);
    dispatch(changeToolboxProcessFileState({ value: true }));
    dispatch(changeToolboxActivateUpload({ value: false }));
  };

  let projectIdBasedOnRights: string;
  if (checked) {
    if (toolboxRightsValue === "Public") {
      projectIdBasedOnRights = publicDataSets.PublicUserUpload.id;
    } else if (toolboxRightsValue === "Private") {
      projectIdBasedOnRights = publicDataSets.PrivateUserUpload.id;
    } else {
      console.error("Invalid toolboxRightsValue");
      return;
    }
  } else {
    projectIdBasedOnRights = publicDataSets.PrivateTemporaryBathymetry.id;
  }

  useEffect(() => {
    const performUpload = async () => {
      const subprojectlist = await getSubProjectList(projectIdBasedOnRights);
      let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

      if (!personalFolderId) {
        await createPersonalProject(idTokenPayload.oid, projectIdBasedOnRights);
        const updatedSubprojectList = await getSubProjectList(projectIdBasedOnRights);

        personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
      }

      if (processedFile && fileformat !== "") {
        let fileToUpload = processedFile;

        const updatedProcessedFile: ProcessedFile = {
          file: fileToUpload,
          rights: toolboxRightsValue,
          uid: uuidv4(),
          fileDescription: fileDescription,
          fileDataFormat: fileformat,
          fileDataType: "Bathymetry",
          fileCRS: fileCRS.value,
          checked: checked,
          fileCRSId: fileCRS.id,
          fileProj4String: fileCRS.proj4String,
          fileDatum: fileDatum,
          fileSurveyDate: fileSurveryDate,
          fileXCoordinate: null,
          fileYCoordinate: null,
          fileTimeZone: "N/A",
          fileSurfaceElevation: null,
          fileWkt: fileCRS.wkt,
        };

        const uploadResponse = await uploadFiles(fileToUpload);
        const convert = await convertBathymetryUpload(
          fileToUpload,
          uploadResponse.data,
          personalFolderId,
          updatedProcessedFile
        );
        if (convert.status === "Completed") {
          if (checked) {
            dispatch(setFileUID(convert.id));
            dispatch(setUploadCompleted(true));
          } else {
            dispatch(setBathymetryToolboxUploadCompleted(true));
            dispatch(setBathymetryToolboxFileUID(convert.id));
            dispatch(changeBathyInterpRefreshState(true));
          }
        }

        if (checked) {
          const subprojectlist = await getSubProjectList(publicDataSets.PrivateTemporaryBathymetry.id);
          let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

          if (!personalFolderId) {
            await createPersonalProject(idTokenPayload.oid, publicDataSets.PrivateTemporaryBathymetry.id);
            const updatedSubprojectList = await getSubProjectList(publicDataSets.PrivateTemporaryBathymetry.id);
            personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
          }

          if (processedFile && fileformat !== "") {
            let fileToUpload = processedFile;

            const updatedProcessedFile: ProcessedFile = {
              file: fileToUpload,
              rights: toolboxRightsValue,
              uid: uuidv4(),
              fileDescription: fileDescription,
              fileDataFormat: fileformat,
              fileDataType: "Bathymetry",
              fileCRS: fileCRS.value,
              checked: checked,
              fileCRSId: fileCRS.id,
              fileProj4String: fileCRS.proj4String,
              fileDatum: fileDatum,
              fileSurveyDate: fileSurveryDate,
              fileXCoordinate: null,
              fileYCoordinate: null,
              fileTimeZone: "N/A",
              fileSurfaceElevation: null,
              fileWkt: fileCRS.wkt,
            };

            const uploadResponse = await uploadFiles(fileToUpload);
            const convert = await convertBathymetryUpload(
              fileToUpload,
              uploadResponse.data,
              personalFolderId,
              updatedProcessedFile
            );

            if (convert.status === "Completed") {
              dispatch(setBathymetryToolboxUploadCompleted(true));
              dispatch(setBathymetryToolboxFileUID(convert.id));
              dispatch(changeBathyInterpRefreshState(true));
            }
          }
        }

        setProcessedFile(null);
        dispatch(changeToolboxVerticalDatum({ value: "" }));
        dispatch(changeToolboxZNegative({ value: false }));
        dispatch(changeToolboxFileName({ value: "" }));
        dispatch(changeToolboxFileDescription({ value: "" }));
        dispatch(changeToolboxFileFormat({ value: "" }));
        dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
        dispatch(changeDataCollectionDate({ value: null }));
        dispatch(changeToolboxZNegative({ value: false }));
        setFiles(null);
      }
    };
    performUpload();
  }, [processedFile, toolboxRightsValue]);

  const handleCancel = () => {
    handleClose();
    setNewFile(null);
    setFiles(null);
    setChecked(false);
    dispatch(changeToolboxFileName({ value: "" }));
    dispatch(changeToolboxFileDescription({ value: "" }));
    dispatch(changeToolboxFileFormat({ value: "" }));
    dispatch(changeToolboxCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
    dispatch(changeToolboxVerticalDatum({ value: "" }));
    dispatch(changeDataCollectionDate({ value: null }));
    dispatch(changeToolboxZNegative({ value: false }));
  };

  return (
    <>
      <Box>
        <Stepper activeStep={activeStep} sx={{ m: "0rem 1rem" }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === 0 && (
          <UploadDataFiles
            file={files}
            setFile={setFiles}
            uploading={uploading}
            setUploading={setUploading}
            activeState={activeState}
            setActiveState={setActiveState}
            checked={checked}
            setChecked={setChecked}
          />
        )}
        {activeStep === 1 && (
          <DataDescription
            setLeftValidate={setLeftValidate}
            setRightValidate={setRightValidate}
            nextClicked={nextClicked}
            setNextClicked={setNextClicked}
          />
        )}
        {activeStep === 2 && (
          <ToolboxImportOptions processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
        )}

        <Box display="flex" flexDirection="row" justifyContent="space-between" m="0.5rem 1rem 0rem 1rem">
          {activeStep === 1 && displayError ? (
            <Typography variant="body1" color="red">
              Please fill the required fields.
            </Typography>
          ) : (
            <Box></Box>
          )}
          <Stack direction="row">
            <Button onClick={activeStep == 0 ? handleCancel : handleBack} sx={{ mr: "1rem" }}>
              {activeStep === 0 ? "Cancel" : "Back"}
            </Button>
            <Button
              onClick={activeStep === 2 ? handleUpload : handleNext}
              variant="contained"
              disabled={activeStep === 0 || activeStep === 1 ? !activeState : !toolboxUpload}
            >
              {activeStep === steps.length - 1 ? "Upload" : "Next"}
            </Button>
          </Stack>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
