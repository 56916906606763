import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoHeadUsernameSliceState {
  value: string;
}

const initialState: DataRepoHeadUsernameSliceState = {
  value: "",
};

export const DataRepoHeadUsernameSlice = createSlice({
  name: "headusername",
  initialState,
  reducers: {
    changeHeadUsername: (
      state,
      action: PayloadAction<DataRepoHeadUsernameSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoHeadUsernameSlice.reducer;

export const { changeHeadUsername } = DataRepoHeadUsernameSlice.actions;
