import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileNameSliceState {
  value: string;
}

const initialState: FileNameSliceState = {
  value: "",
};

export const DataRepoFileNameSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    changeFileName: (state, action: PayloadAction<FileNameSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileNameSlice.reducer;

export const { changeFileName } = DataRepoFileNameSlice.actions;
