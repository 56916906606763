import { initialTilesWMTS, platform } from "./init";

export const uploadFiles = async (attachments: any) => {
  const uploadURL = await platform.getJson("conversion/transfer/upload-url", "2");
  await fetch(uploadURL.data, { method: "PUT", body: attachments, headers: { "X-Ms-Blob-Type": "BlockBlob" } });
  return uploadURL;
};
export type ProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
};

export type BathymetryProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
  checked: boolean;
};

export const convertUpload = async (file: File, url: string, project: string, fileMetadata: ProcessedFile) => {
  const data = await platform.postJson(
    "conversion/upload-convert",
    {
      projectId: project,
      originalFileName: file.name,
      uploadUrl: url,
      readerName: "FileReader",
      writerName: "FileWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: file.name,
        description: fileMetadata.fileDescription,
        metadata: {
          rights: fileMetadata.rights,
          uid: fileMetadata.uid,
          fileDataFormat: fileMetadata.fileDataFormat,
          fileDataType: fileMetadata.fileDataType,
          fileCRS: fileMetadata.fileCRS,
          fileDatum: fileMetadata.fileDatum,
          fileSurveyDate: fileMetadata.fileSurveyDate,
          fileXCoordinate: fileMetadata.fileXCoordinate,
          fileYCoordinate: fileMetadata.fileYCoordinate,
          fileTimeZone: fileMetadata.fileTimeZone,
          fileSurfaceElevation: fileMetadata.fileSurfaceElevation,
          fileCRSId: fileMetadata.fileCRSId,
          fileProj4String: fileMetadata.fileProj4String,
          fileWkt: fileMetadata.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};

export const UploadBathmetryCoordinatesFile = async (file: File, url: string, project: string) => {
  const data = await platform.postJson(
    "conversion/upload-convert",
    {
      projectId: project,
      originalFileName: file.name,
      uploadUrl: url,
      readerName: "FileReader",
      writerName: "FileWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: file.name,
        description: "geojson coordinates file for bathymetry interpolation",
      },
    },
    "3"
  );
  return await data;
};

export const convertBathymetryInterpolationUpload = async (
  name: string,
  url: string,
  project: string,
  fileMetadata: ProcessedFile,
  datasetId: string,
  projectId: string
) => {
  const data = await platform.postJson(
    "conversion/upload-convert",
    {
      projectId: project,
      originalFileName: name,
      uploadUrl: url,
      readerName: "FileReader",
      writerName: "FileWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: name,
        description: fileMetadata.fileDescription,
        metadata: {
          rights: fileMetadata.rights,
          uid: fileMetadata.uid,
          fileDataFormat: fileMetadata.fileDataFormat,
          fileDataType: fileMetadata.fileDataType,
          fileCRS: fileMetadata.fileCRS,
          fileDatum: fileMetadata.fileDatum,
          fileProj4String: fileMetadata.fileProj4String,
          fileSurveyDate: fileMetadata.fileSurveyDate,
          fileXCoordinate: fileMetadata.fileXCoordinate,
          fileYCoordinate: fileMetadata.fileYCoordinate,
          fileTimeZone: fileMetadata.fileTimeZone,
          fileSurfaceElevation: fileMetadata.fileSurfaceElevation,
          mainDatasetId: datasetId,
          mainProjectId: projectId,
        },
      },
    },
    "3"
  );
  return await data;
};

export const convertBathymetryUpload = async (
  file: File,
  url: string,
  project: string,
  fileMetadata: BathymetryProcessedFile
) => {
  const data = await platform.postJson(
    "conversion/upload-convert",
    {
      projectId: project,
      originalFileName: file.name,
      uploadUrl: url,
      readerName: "FileReader",
      writerName: "FileWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: file.name,
        description: fileMetadata.fileDescription,
        metadata: {
          checked: fileMetadata.checked,
          rights: fileMetadata.rights,
          uid: fileMetadata.uid,
          fileDataFormat: fileMetadata.fileDataFormat,
          fileDataType: fileMetadata.fileDataType,
          fileCRS: fileMetadata.fileCRS,
          fileDatum: fileMetadata.fileDatum,
          fileSurveyDate: fileMetadata.fileSurveyDate,
          fileXCoordinate: fileMetadata.fileXCoordinate,
          fileYCoordinate: fileMetadata.fileYCoordinate,
          fileTimeZone: fileMetadata.fileTimeZone,
          fileSurfaceElevation: fileMetadata.fileSurfaceElevation,
          fileCRSId: fileMetadata.fileCRSId,
          fileProj4String: fileMetadata.fileProj4String,
          fileWkt: fileMetadata.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};

export const getProjectList = async () => {
  const data = await platform.getJson("metadata/project/list", "3");
  return await data;
};

export const getSubProjectList = async (id: string) => {
  const data = await platform.getJson(`metadata/project/${id}/subprojects`, "3");
  return await data;
};

export const createPersonalProject = async (user: string, subproject: string) => {
  const data = await platform.postJson(
    `metadata/project/${subproject}/subproject`,
    {
      name: user,
      accessLevel: "Confidential",
      description: "Personal Folder",
      thumbnailBase64: "",
      members: [],
    },
    "3"
  );
  return await data;
};

export const deleteBathymetryData = async (id: string) => {
  try {
    const response = await platform.deleteJson(`metadata/dataset/${id}`, "3");
    return response;
  } catch (error) {}
};

export const getConvertedDatasetId = async (id: string) => {
  const result = await platform.getJson(`conversion/transfer/${id}`, "2");
  return result;
};

export const convertDataSet = async (dataset: any, id: string, project: any, CRS: number | null) => {
  const data = await platform.postJson(
    `metadata/dataset/${id}/convert`,
    {
      outputProjectId: project,
      readerName: "GeoTiffReader",
      writerName: "TilingWriter",
      readerParameters: [
        {
          name: "SRID",
          value: CRS,
        },
      ],
      writerParameters: [
        {
          name: "Schemes",
          value: [initialTilesWMTS],
        },
      ],
      outputDatasetData: {
        name: `${dataset.name.split(".")[0]} (converted)`,
        description: dataset.fileDescription,
        metadata: {
          rights: dataset.rights,
          id: dataset.id,
          fileDataFormat: initialTilesWMTS.schemeType,
          fileDataType: dataset.fileDataType,
          fileCRS: dataset.fileCRS,
          fileDatum: dataset.fileDatum,
          fileSurveyDate: dataset.fileSurveyDate,
          fileXCoordinate: dataset.fileXCoordinate,
          fileYCoordinate: dataset.fileYCoordinate,
          fileTimeZone: dataset.fileTimeZone,
          fileSurfaceElevation: dataset.fileSurfaceElevation,
          fileCRSId: dataset.fileCRSId,
          fileProj4String: dataset.fileProj4String,
          fileWkt: dataset.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};

export const convertJsontoGisVector = async (id: string, project: any) => {
  const data = await platform.postJson(
    `metadata/dataset/${id}/convert`,
    {
      outputProjectId: project,
      readerName: "GeoJsonReader",
      writerName: "GISWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: "geojson (converted)",
        description: "geojson coordinates file for bathymetry interpolation",
      },
    },
    "3"
  );
  return await data;
};

export const convertToGisVectorDataset = async (
  reader: string,
  dataset: any,
  id: string,
  project: any,
  CRS: number | null
) => {
  const data = await platform.postJson(
    `metadata/dataset/${id}/convert`,
    {
      outputProjectId: project,
      readerName: reader,
      writerName: "GISWriter",
      readerParameters: [{ name: "SRID", value: CRS }],
      writerParameters: [],

      transformations: [
        {
          type: "CrsTransformation",
          inputSrid: CRS,
          outputSrid: 3857,
        },
      ],
      outputDatasetData: {
        name: `${dataset.name.split(".")[0]} (converted)`,
        description: dataset.fileDescription,
        metadata: {
          rights: dataset.rights,
          id: dataset.id,
          fileDataFormat: "GisVector",
          fileDataType: dataset.fileDataType,
          fileCRS: dataset.fileCRS,
          fileDatum: dataset.fileDatum,
          fileSurveyDate: dataset.fileSurveyDate,
          fileXCoordinate: dataset.fileXCoordinate,
          fileYCoordinate: dataset.fileYCoordinate,
          fileTimeZone: dataset.fileTimeZone,
          fileSurfaceElevation: dataset.fileSurfaceElevation,
          fileCRSId: dataset.fileCRSId,
          fileProj4String: dataset.fileProj4String,
          fileWkt: dataset.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};

export const convertToTimeseriesStorage = async (dataset: any, id: string, project: any) => {
  const data = await platform.postJson(
    `metadata/dataset/${id}/convert`,
    {
      outputProjectId: project,
      readerName: "GHMReader",
      writerName: "TSWriter",
      readerParameters: [{ name: "DataFieldDelimiter", value: "," }],
      writerParameters: [],
      outputDatasetData: {
        name: `${dataset.name.split(".")[0]} (converted)`,
        description: dataset.fileDescription,
        metadata: {
          rights: dataset.rights,
          id: dataset.id,
          fileDataFormat: "Timeseries Storage",
          fileDataType: dataset.fileDataType,
          fileCRS: dataset.fileCRS,
          fileDatum: dataset.fileDatum,
          fileSurveyDate: dataset.fileSurveyDate,
          fileXCoordinate: dataset.fileXCoordinate,
          fileYCoordinate: dataset.fileYCoordinate,
          fileTimeZone: dataset.fileTimeZone,
          fileSurfaceElevation: dataset.fileSurfaceElevation,
          fileCRSId: dataset.fileCRSId,
          fileProj4String: dataset.fileProj4String,
          fileWkt: dataset.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};

export const convertDFS0ToTimeseriesStorage = async (dataset: any, id: string, project: any) => {
  const data = await platform.postJson(
    `metadata/dataset/${id}/convert`,
    {
      outputProjectId: project,
      readerName: "TimeSeriesDfs0Reader",
      writerName: "TSWriter",
      readerParameters: [],
      writerParameters: [],
      outputDatasetData: {
        name: `${dataset.name.split(".")[0]} (converted)`,
        description: dataset.fileDescription,
        metadata: {
          rights: dataset.rights,
          id: dataset.id,
          fileDataFormat: "Timeseries Storage",
          fileDataType: dataset.fileDataType,
          fileCRS: dataset.fileCRS,
          fileDatum: dataset.fileDatum,
          fileSurveyDate: dataset.fileSurveyDate,
          fileXCoordinate: dataset.fileXCoordinate,
          fileYCoordinate: dataset.fileYCoordinate,
          fileTimeZone: dataset.fileTimeZone,
          fileSurfaceElevation: dataset.fileSurfaceElevation,
          fileCRSId: dataset.fileCRSId,
          fileProj4String: dataset.fileProj4String,
          fileWkt: dataset.fileWkt,
        },
      },
    },
    "3"
  );
  return await data;
};
