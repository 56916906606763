import { Typography, Box, Backdrop, Modal, Fade, Stack, IconButton } from "@mui/material";
import UploadStepper from "./UploadStepper";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { DataRepoFileContext } from "./DataRepoFileContext/DataRepoFileContext";
import { changeFileName } from "../../../features/DataRepoFileNameSlice";
import { changeFileDescription } from "../../../features/DataRepoDescriptionSlice";
import { changeFileDataFormat } from "../../../features/DataRepoDataFormatSlice";
import { changeFileCRS } from "../../../features/DataRepoCRSSlice";
import { changeFileDataType } from "../../../features/DataRepoFileDataTypeSlice";
import { changeFileDatum } from "../../../features/DataRepoDatumSlice";
import { changeGroupValue } from "../../../features/DataRepoGroupValueSlice";
import { changeFileSurveyDate } from "../../../features/DataRepoSurveyDateSlice";
import { changeDataRepositoryXCoordinate } from "../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../features/DataRepositoryYCoordinateSlice";
import { changeFileTimeZone } from "../../../features/DataRepoTimeZoneSlice";
import { changeDataRepoSurfaceElevation } from "../../../features/DataRepoSurfaceElevationSlice";

interface DataUploadModal {
  open: boolean;
  handleClose: () => void;
}

const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

export function useFileContext() {
  const context = useContext(DataRepoFileContext);

  if (!context) {
    throw new Error("useFileContext must be used within a FileProvider");
  }

  return context;
}

export const DataUploadModal = ({ open, handleClose }: DataUploadModal) => {
  const { newFile, setNewFile } = useFileContext();
  const [files, setFiles] = useState<File | null>(null);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      sx={{ backdropFilter: "blur(10px)" }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "25rem",
            width: {
              xs: "90%",
              sm: "75%",
              md: "60%",
              lg: "44rem",
            },
          }}
        >
          <Box m="0rem 1rem" display="flex" flexDirection="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem" mb="0.5rem">
                Data Upload
              </Typography>
            </Stack>
            <IconButton
              disableRipple
              onClick={() => {
                handleClose();
                setNewFile(null);
                setFiles(null);
                setChecked(false);
                dispatch(changeFileName({ value: "" }));
                dispatch(changeFileDescription({ value: "" }));
                dispatch(changeFileDataFormat({ value: "" }));
                dispatch(changeFileCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
                dispatch(changeFileDataType({ value: "" }));
                dispatch(changeFileDatum({ value: "" }));
                dispatch(changeGroupValue({ value: "" }));
                dispatch(changeFileSurveyDate({ value: null }));
                dispatch(changeDataRepositoryXCoordinate({ value: null }));
                dispatch(changeDataRepositoryYCoordinate({ value: null }));
                dispatch(changeFileTimeZone({ value: "" }));
                dispatch(changeDataRepoSurfaceElevation({ value: null }));
                dispatch(changeToolboxZNegative({ value: false }));
              }}
            >
              <CloseIcon sx={{ color: "secondary.dark" }} />
            </IconButton>
          </Box>
          <UploadStepper
            handleClose={handleClose}
            files={files}
            setFiles={setFiles}
            checked={checked}
            setChecked={setChecked}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
