import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModelGridCheckedSliceState {
  value: boolean;
}

const initialState: ModelGridCheckedSliceState = {
  value: false,
};

export const ModelGridCheckedSlice = createSlice({
  name: "ModelGrid",
  initialState,
  reducers: {
    changeModelGridCheckedState: (
      state,
      action: PayloadAction<ModelGridCheckedSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ModelGridCheckedSlice.reducer;

export const { changeModelGridCheckedState } = ModelGridCheckedSlice.actions;
