import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Graticule from "ol/layer/Graticule";

interface ModelGridGraticuleState {
  value: Graticule | null;
}

const initialState: ModelGridGraticuleState = {
  value: null,
};

const ModelGridGraticuleSlice = createSlice({
  name: "modelGridGraticule",
  initialState,
  reducers: {
    changeModelGrdiGraticuleState: (
      state,
      action: PayloadAction<ModelGridGraticuleState>
    ) => {
      return (state = action.payload);
    },
  },
});

export const { changeModelGrdiGraticuleState } =
  ModelGridGraticuleSlice.actions;

export default ModelGridGraticuleSlice.reducer;
