import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepositorySearchbarState {
  value: string;
}

const initialState: DataRepositorySearchbarState = {
  value: "",
};

export const DataRepositorySearchbarSlice = createSlice({
  name: "DataRepositorysearchbar",
  initialState,
  reducers: {
    changeDataRepositorySearchInput: (
      state,
      action: PayloadAction<DataRepositorySearchbarState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepositorySearchbarSlice.reducer;

export const { changeDataRepositorySearchInput } =
  DataRepositorySearchbarSlice.actions;
