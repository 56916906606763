import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  ReactNode,
} from "react";

interface FileContextType {
  newFile: File | null;
  setNewFile: Dispatch<SetStateAction<File | null>>;
}

export const FileContext = createContext<FileContextType | undefined>(
  undefined
);

interface FileProviderProps {
  children: ReactNode;
}

export const FileProvider: React.FC<FileProviderProps> = ({ children }) => {
  const [newFile, setNewFile] = useState<File | null>(null);

  return (
    <FileContext.Provider value={{ newFile, setNewFile }}>
      {children}
    </FileContext.Provider>
  );
};
