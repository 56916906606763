import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HarborsInExtentState {
  value: string[];
}

const initialState: HarborsInExtentState = {
  value: [],
};

export const DashboardHarborsInExtentSlice = createSlice({
  name: "harborsInExtent",
  initialState,
  reducers: {
    changeDashboardHarborsInExtent: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.value = action.payload;
    },

    // removePortfromDashboardSummary: (state, action: PayloadAction<any>) => {
    //   state.value = action.payload;
    // },
  },
});

export default DashboardHarborsInExtentSlice.reducer;

export const { changeDashboardHarborsInExtent } =
  DashboardHarborsInExtentSlice.actions;
