import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoCheckedItemsSliceState {
  value: string[];
}

const initialState: DataRepoCheckedItemsSliceState = {
  value: [],
};

export const DataRepoCheckedItemsSlice = createSlice({
  name: "CheckedItems",
  initialState,
  reducers: {
    changeCheckedItems: (
      state,
      action: PayloadAction<DataRepoCheckedItemsSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoCheckedItemsSlice.reducer;

export const { changeCheckedItems } = DataRepoCheckedItemsSlice.actions;
