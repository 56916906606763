import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import { Box, Typography } from "@mui/material";

interface directionProps {
  directionValues: string[];
  windData: unknown[];
}

const initialOptions: EChartsOption = {
  tooltip: {},
  legend: {
    data: ["AEP (%)"],
    icon: "none",
    bottom: 18,
    right: 10,
    textStyle: {
      fontSize: 14,
      fontFamily: "Roboto",
      color: "#09334B",
      fontWeight: 700,
    },
  },
  xAxis: {
    type: "value",
    name: "AEP",
    nameLocation: "middle",
    nameTextStyle: {
      fontSize: 14,
    },
  },
  yAxis: {
    type: "value",
    name: "y",
    nameLocation: "middle",
    nameTextStyle: {
      fontSize: 14,
    },
  },
  series: [],
};

export const WindTabEVDChart = ({ directionValues, windData }: directionProps) => {
  const [chartOptions, setChartOptions] = useState<EChartsOption>(initialOptions);
  const [optionsData, setOptionsData] = useState<any>([]);
  useEffect(() => {
    if (Array.isArray(chartOptions.series)) {
      const seriesData = chartOptions.series[0]?.data ?? [];
      setOptionsData(seriesData);
    }
  }, [chartOptions.series]);

  const extractDirection = (directionString: string): string => {
    return directionString.split(" ")[0];
  };

  const processedDirectionValues = directionValues.map(extractDirection);

  useEffect(() => {
    const filteredData = windData.filter((row: any) => processedDirectionValues.includes(row.Direction));
    const seriesData = filteredData.map((row: any) => [parseFloat(row.AEP), parseFloat(row.WS)]);

    setChartOptions((prev) => ({
      ...prev,
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const firstSeriesData = params[0];
          const xAxisValue = firstSeriesData.axisValue;
          const aepValue = xAxisValue;
          const hsValue = firstSeriesData.data[1];
          return `AEP (%): ${aepValue}<br/>Ws: ${hsValue}`;
        },
      },
      yAxis: {
        ...prev.yAxis,
        name: "Wind Speed",
      },
      series: [
        {
          name: "AEP (%)",
          type: "line",
          smooth: true,
          lineStyle: {
            color: "black",
          },
          symbol: "triangle",
          symbolSize: 10,
          itemStyle: {
            borderColor: "red",
            color: "white",
          },
          data: seriesData,
        },
      ],
    }));
  }, [directionValues, windData]);

  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{
        height: "23rem",
        mt: "1rem",
        ml: "1rem",
        mb: "2rem",
        width: "23.5rem",
        border: "1px solid #CFDBE2",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          mb: "1rem",
        }}
      >
        <Typography variant="h4" mt="1.5rem" ml="1rem" color="secondary.dark">
          Extreme Value Distribution: Wind Speed [m/s]
        </Typography>
      </Box>
      {directionValues.length === 1 && optionsData.length > 0 ? (
        <ReactECharts option={chartOptions} style={{ height: "22rem", top: "-3rem" }} />
      ) : optionsData.length === 0 && directionValues.length !== 0 ? (
        <Box m="2rem" mt="7rem">
          <Typography variant="body2" color="secondary.dark" justifyContent="center" textAlign="center">
            No data available.
          </Typography>
        </Box>
      ) : (
        <Box m="2rem" mt="7rem">
          <Typography variant="body2" color="secondary.dark" justifyContent="center" textAlign="center">
            Please select 1 wave direction to see the graph.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
