import { Dispatch, SetStateAction, useContext } from "react";
import { Box, Stack } from "@mui/material";
import ControlButton from "./ControlButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { SxProps, Theme } from "@mui/system";
import { ToolsIcons } from "./ToolsIcons";
import { MeasureIcon } from "./MeasureIcon";
import { Overlay } from "ol";
import { MapViewerDrawPolygon } from "./MapViewerDrawPolygon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useRef, useState } from "react";
import { Draw } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { changeLayerButtonState } from "../../features/LayerButtonSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { OpenlayersContext } from "../Openlayers/OpenlayersContext";
import { baseMapsGroup } from "../Openlayers/default_groups_layers";

interface clickedProps {
  setMeasureClicked: Dispatch<SetStateAction<boolean>>;
  setDrawClicked: Dispatch<SetStateAction<boolean>>;
}

const MapViewerIcons = ({
  setMeasureClicked,
  setDrawClicked,
}: clickedProps) => {
  const map = useContext(OpenlayersContext);
  const dispatch = useDispatch();
  const drawInteraction = useRef<Draw | null>(null);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const overlays = useRef<Overlay[]>([]);
  const clearButtonVisibility = useSelector(
    (state: RootState) => state.clearButton.value
  );
  const [drawnSource, setDrawnSource] = useState<VectorSource>(
    new VectorSource()
  );
  const layerValue = useSelector((state: RootState) => state.layerButton.value);
  const [draw, setDraw] = useState<Draw | null>(null);
  const [drawnFeature, setDrawnFeature] = useState<any>(null);
  const generateControlStackStyle = (topValue: string): SxProps<Theme> => ({
    bgcolor: "white",
    borderRadius: "4px",
    position: "absolute",
    top: topValue,
    right: "2rem",
    boxShadow: "0px 2px 4px -1px rgba(9, 51, 75, 0.5)",
    "&:hover": {
      cursor: "pointer",
    },
  });

  const controlButtonStyle: SxProps<Theme> = {
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
      bgcolor: "secondary.dark",
      color: "white",
    },
  };

  const handleZoomIn = () => {
    const currentmap = map && map.current;
    if (currentmap) {
      currentmap.getView().animate({
        zoom: currentmap.getView().getZoom()! + 1,
        duration: 200,
      });
    }
  };

  const handleZoomOut = () => {
    const currentmap = map && map.current;
    if (currentmap) {
      currentmap.getView().animate({
        zoom: currentmap.getView().getZoom()! - 1,
        duration: 200,
      });
    }
  };

  const [layersMenuAnchor, setLayersMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const openLayersMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLayersMenuAnchor(event.currentTarget);
  };

  const closeLayersMenu = () => {
    setLayersMenuAnchor(null);
  };

  const selectLayer = (layer: string) => {
    dispatch(changeLayerButtonState({ value: layer }));
    closeLayersMenu();
  };

  return (
    <Box sx={{ zIndex: 1000 }}>
      <Stack direction="column" spacing="16px" sx={{}}>
        <Stack
          direction="column"
          spacing="0rem"
          sx={
            clearButtonVisibility
              ? generateControlStackStyle("5rem")
              : generateControlStackStyle("2rem")
          }
        >
          <ControlButton
            tooltipTitle="Zoom In"
            IconComponent={AddIcon}
            onClick={handleZoomIn}
            sx={controlButtonStyle}
          />
          <ControlButton
            tooltipTitle="Zoom Out"
            IconComponent={RemoveIcon}
            onClick={handleZoomOut}
            sx={controlButtonStyle}
          />
        </Stack>
        <Box
          sx={
            clearButtonVisibility
              ? generateControlStackStyle("9rem")
              : generateControlStackStyle("6rem")
          }
        >
          <ControlButton
            tooltipTitle="Layers"
            IconComponent={LayersOutlinedIcon}
            onClick={openLayersMenu}
            sx={controlButtonStyle}
          />
        </Box>

        <Menu
          anchorEl={layersMenuAnchor}
          open={Boolean(layersMenuAnchor)}
          onClose={closeLayersMenu}
          sx={{ transform: "translateX(-10px)" }}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {baseMapsGroup?.getLayersArray().map((l: any) => {
            return (
              <MenuItem
                key={l.get("name")}
                onClick={() => {
                  baseMapsGroup
                    ?.getLayersArray()
                    .map((ll: any) => ll.setVisible(false));
                  l.setVisible(true);
                  closeLayersMenu();
                }}
              >
                {l.getVisible() ? (
                  <VisibilityIcon
                    style={{ color: "secondary.dark" }}
                    sx={{ mr: "0.25rem" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ color: "gray" }}
                    sx={{ mr: "0.25rem" }}
                  />
                )}
                {l.get("name")}
              </MenuItem>
            );
          })}
        </Menu>

        {map && window.location.pathname === "/repository" && (
          <Stack
            direction="column"
            spacing="0rem"
            sx={{
              bgcolor: "white",
              borderRadius: "4px",
              position: "absolute",
              top: clearButtonVisibility ? "12rem " : "9rem",
              right: "2rem",
              boxShadow: "0px 2px 4px -1px rgba(9, 51, 75, 0.5)",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <MapViewerDrawPolygon
              map={map}
              drawInteraction={drawInteraction}
              overlays={overlays}
              setIsMeasuring={setIsMeasuring}
              drawnSource={drawnSource}
              drawnFeature={drawnFeature}
              setDrawnFeature={setDrawnFeature}
              isMeasuring={isMeasuring}
              draw={draw}
              setDraw={setDraw}
              setDrawClicked={setDrawClicked}
            />
          </Stack>
        )}
        {map && (
          <Stack
            direction="column"
            sx={
              clearButtonVisibility
                ? generateControlStackStyle("15rem")
                : window.location.pathname !== "/repository"
                ? generateControlStackStyle("9rem")
                : generateControlStackStyle("12rem")
            }
          >
            <MeasureIcon
              map={map}
              drawInteraction={drawInteraction}
              overlays={overlays}
              isMeasuring={isMeasuring}
              setIsMeasuring={setIsMeasuring}
              drawnFeature={drawnFeature}
              drawnSource={drawnSource}
              setDraw={setDraw}
              setMeasureClicked={setMeasureClicked}
            />
          </Stack>
        )}
        <ToolsIcons />
      </Stack>
    </Box>
  );
};

export default MapViewerIcons;
