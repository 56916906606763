import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { LegendContainer, MuiSlider } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import { IceCoverGroupA, IceCoverGroupB } from "../../Openlayers/default_groups_layers";
import { toolControls } from "../../Openlayers/data_layers";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "./BathymetryLegend";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";
import { format } from "date-fns";

export default function IceCoverLegend() {
  const toolname = new URLSearchParams(location.search).get("tool");

  const [tileSchemes, setTileSchemes] = React.useState<any[] | null>(null);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);
  const [selectedScenarioModelIndex, setSelectedScenarioModelIndex] = React.useState(0);
  const [selectedScenarioValue, setSelectedScenarioValue] = React.useState("Max_Thickness");
  const [datasetList, setDatasetList] = React.useState<any[]>([]);
  const [selectedTimeStepIndex, setSelectedTimeStepIndex] = React.useState(0);
  const [hasLoadedData, setHasLoadedData] = React.useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (!hasLoadedData) return;
    if (selectedScenarioModelIndex === 1 && toolname !== null) {
      IceCoverGroupA.setVisible(false);
      IceCoverGroupB.setVisible(true);
    }
  }, [toolname]);

  useEffect(() => {
    (async () => {
      const premed = await getTileDataSetsMetadata(publicDataSets.IceCover.prMed);
      const cpmed = await getTileDataSetsMetadata(publicDataSets.IceCover.cpMed);
      const breakup = await getTileDataSetsMetadata(publicDataSets.IceCover.breakUp);
      const freezup = await getTileDataSetsMetadata(publicDataSets.IceCover.freezeUp);
      const sact = await getTileDataSetsMetadata(publicDataSets.IceCover.sACT);
      const dsArray = [premed, cpmed, breakup, freezup, sact];

      setDatasetList(dsArray);
      setDataSetMetadata(dsArray[0]);
      setTileSchemes(dsArray[0].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
    })();

    return () => {};
  }, []);

  useEffect(() => {
    const refreshIntervalId = setInterval(function () {
      if (
        IceCoverGroupA.getLayersArray().length > 0 &&
        IceCoverGroupA.getLayersArray().length === 8 /*Group count*/ &&
        IceCoverGroupB.getLayersArray().length > 0 &&
        IceCoverGroupB.getLayersArray().length === 6 /*Group count*/
      ) {
        clearInterval(refreshIntervalId);

        IceCoverGroupA.getLayersArray().map((l: any) => {
          if (l.get("id") === selectedScenarioValue) {
            l.setVisible(true);
          }
        });
        IceCoverGroupB.getLayersArray().map((l: any) => {
          if (l.get("id") === selectedScenarioValue) {
            l.setVisible(true);
          }
        });

        IceCoverGroupA.changed();
        IceCoverGroupB.changed();

        setHasLoadedData(true);
      }
    }, 200);

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 30000);

    return () => {};
  }, []);

  const handleChangeModel = (e: any) => {
    if (e.target.value === 0) {
      IceCoverGroupA.setVisible(true);
      IceCoverGroupB.setVisible(false);

      IceCoverGroupA.getLayersArray().map((l) => {
        if (l.get("id") === selectedScenarioValue) {
          l.setVisible(true);
          switch (l.get("name")) {
            case "EC_cpmed_WMTS":
              setDataSetMetadata(datasetList[1]);
              setTileSchemes(datasetList[1].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
              break;
            case "EC_breakup_WMTS":
              setDataSetMetadata(datasetList[2]);
              setTileSchemes(datasetList[2].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
              break;
            case "EC_freezeup_WMTS":
              setDataSetMetadata(datasetList[3]);
              setTileSchemes(datasetList[3].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
              break;
            default:
              setDataSetMetadata(datasetList[0]);
              setTileSchemes(datasetList[0].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
              break;
          }
        } else {
          l.setVisible(false);
        }
      });
    }
    if (e.target.value === 1) {
      IceCoverGroupA.setVisible(false);
      IceCoverGroupB.setVisible(true);

      if (selectedScenarioValue === "freezeup" || selectedScenarioValue === "breakup") {
        setSelectedScenarioValue("Max_Thickness");
        IceCoverGroupB.getLayersArray().map((l) => {
          if (l.get("id") === "Max_Thickness") {
            l.setVisible(true);
          } else {
            l.setVisible(false);
          }
        });
        setDataSetMetadata(datasetList[4]);
        setTileSchemes(datasetList[4].tilingSchemes.filter((ts: any) => ts.schemeId === "Max_Thickness"));
      } else {
        IceCoverGroupB.getLayersArray().map((l) => {
          if (l.get("id") === selectedScenarioValue) {
            l.setVisible(true);
          } else {
            l.setVisible(false);
          }
        });
        setDataSetMetadata(datasetList[4]);
        setTileSchemes(datasetList[4].tilingSchemes.filter((ts: any) => ts.schemeId === selectedScenarioValue));
      }
    }
    setSelectedScenarioModelIndex(e.target.value);
  };

  const handleChangeScenario = (e: any) => {
    if (selectedScenarioModelIndex === 0) {
      IceCoverGroupA.getLayersArray().map((l) => {
        if (l.get("id") === e.target.value) {
          l.setVisible(true);
          switch (l.get("name")) {
            case "EC_cpmed_WMTS":
              setDataSetMetadata(datasetList[1]);
              setTileSchemes(datasetList[1].tilingSchemes.filter((ts: any) => ts.schemeId === e.target.value));
              break;
            case "EC_breakup_WMTS":
              setDataSetMetadata(datasetList[2]);
              setTileSchemes(datasetList[2].tilingSchemes.filter((ts: any) => ts.schemeId === e.target.value));
              break;
            case "EC_freezeup_WMTS":
              setDataSetMetadata(datasetList[3]);
              setTileSchemes(datasetList[3].tilingSchemes.filter((ts: any) => ts.schemeId === e.target.value));
              break;
            default:
              setDataSetMetadata(datasetList[0]);
              setTileSchemes(datasetList[0].tilingSchemes.filter((ts: any) => ts.schemeId === e.target.value));
              break;
          }
        } else {
          l.setVisible(false);
        }
      });
      IceCoverGroupA.changed();
    }

    if (selectedScenarioModelIndex === 1) {
      IceCoverGroupB.getLayersArray().map((l) => {
        if (l.get("id") === e.target.value) {
          l.setVisible(true);
          setDataSetMetadata(datasetList[4]);
          setTileSchemes(datasetList[4].tilingSchemes.filter((ts: any) => ts.schemeId === e.target.value));
        } else {
          l.setVisible(false);
        }
      });
      IceCoverGroupB.changed();
    }
    setSelectedScenarioValue(e.target.value);
  };

  const handleRangeChange = (e: any) => {
    let selectedLayer: any;

    if (selectedScenarioModelIndex === 0) {
      selectedLayer = IceCoverGroupA.getLayersArray().filter((l) => l.get("id") === selectedScenarioValue);
    }

    if (selectedScenarioModelIndex === 1) {
      selectedLayer = IceCoverGroupB.getLayersArray().filter((l) => l.get("id") === selectedScenarioValue);
    }

    if (!selectedLayer || selectedLayer?.length === 0) return;

    selectedLayer[0].getSource()?.setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
      const href = new URL(src);
      href.searchParams.set("TIMESTEP", dataSetMetadata.timeStamps[e.target.value]);
      const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
      const imageData = await response.blob();
      imageTile.getImage().src = window.URL.createObjectURL(imageData);
    });
    selectedLayer[0].getSource().updateDimensions({
      TIMESTEP: dataSetMetadata.timeStamps[e.target.value],
    });
    setSelectedTimeStepIndex(e.target.value);
    IceCoverGroupA.changed();
    IceCoverGroupB.changed();
  };

  function valueLabelFormat(value: number) {
    if (!dataSetMetadata) return "";
    if (selectedScenarioModelIndex === 0) {
      return format(new Date(dataSetMetadata.timeStamps[value]), "dd MMM");
    } else {
      return format(new Date(dataSetMetadata.timeStamps[value]), "dd MMM yyyy");
    }
  }

  return (
    <>
      {toolname === toolControls.ICECOVER && (
        <>
          <LegendContainer style={{ maxWidth: "900px", display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Ice Freeze-Up, Break-up, Thickness, and Concentration
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Ice data provided in the CCZIS is derived from Canadian Ice Service (CIS) charts. For original
                        data, definitions, etc. please see{" "}
                        <a
                          href="https://www.canada.ca/en/environment-climate-change/services/ice-forecasts-observations/latest-conditions/archive-overview.html"
                          style={{ color: "#00A4EC" }}
                        >
                          GEOSCAN Search Results: Fastlink (nrcan.gc.ca)
                        </a>
                        <br />
                        <b>Ice thickness:</b> Ice thickness derived from 'ice type' code in prmed.
                        <br />
                        <b>Ice concentration:</b> Ice concentration derived from 'ice concentration' code in cpmed.
                        <br />
                        <b>breakup:</b> Break-up day (julian days)
                        <br />
                        <b>freezeup:</b> Break-up day (julian days)
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>
              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <DropdownSelect
                  label="Model"
                  value={selectedScenarioModelIndex.toString()}
                  onChange={handleChangeModel}
                  options={[
                    {
                      label: "Climatology Raster",
                      value: 0,
                    },
                    {
                      label: "Climatology Timeseries",
                      value: 1,
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                {selectedScenarioModelIndex === 0 ? (
                  <DropdownSelect
                    label="Scenario"
                    value={selectedScenarioValue}
                    onChange={handleChangeScenario}
                    options={[
                      {
                        label: "Ice freeze-up date",
                        value: "freezeup",
                      },
                      {
                        label: "Ice break-up date",
                        value: "breakup",
                      },
                      {
                        label: "Max. Ice Thickness",
                        value: "Max_Thickness",
                      },
                      {
                        label: "Min. Ice Thickness",
                        value: "Min_Thickness",
                      },
                      {
                        label: "Max. Ice Concentration",
                        value: "Max_Concentration",
                      },
                      {
                        label: "Min. Ice Concentration",
                        value: "Min_Concentration",
                      },
                    ]}
                  />
                ) : (
                  <DropdownSelect
                    label="Scenario"
                    value={selectedScenarioValue}
                    onChange={handleChangeScenario}
                    options={[
                      {
                        label: "Max. Ice Thickness",
                        value: "Max_Thickness",
                      },
                      {
                        label: "Min. Ice Thickness",
                        value: "Min_Thickness",
                      },
                      {
                        label: "Max. Ice Concentration",
                        value: "Max_Concentration",
                      },
                      {
                        label: "Min. Ice Concentration",
                        value: "Min_Concentration",
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <MuiSlider
                  disabled={
                    !hasLoadedData || selectedScenarioValue === "freezeup" || selectedScenarioValue === "breakup"
                  }
                  value={selectedTimeStepIndex ? selectedTimeStepIndex : 0}
                  step={1}
                  size="medium"
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="auto"
                  onChange={handleRangeChange}
                  marks={[
                    {
                      value: 0,
                      label: dataSetMetadata
                        ? selectedScenarioModelIndex === 0
                          ? format(new Date(dataSetMetadata.timeStamps[0]), "dd MMM")
                          : format(new Date(dataSetMetadata.timeStamps[0]), "dd MMM yyyy")
                        : "",
                    },
                    {
                      value: dataSetMetadata ? dataSetMetadata.timeStamps.length - 1 : 0,
                      label: dataSetMetadata
                        ? selectedScenarioModelIndex === 0
                          ? format(
                              new Date(dataSetMetadata.timeStamps[dataSetMetadata.timeStamps.length - 1]),
                              "dd MMM"
                            )
                          : format(
                              new Date(dataSetMetadata.timeStamps[dataSetMetadata.timeStamps.length - 1]),
                              "dd MMM yyyy"
                            )
                        : "",
                    },
                  ]}
                  min={0}
                  max={dataSetMetadata ? dataSetMetadata.timeStamps.length - 1 : 0}
                />
              </Grid>
            </Grid>

            <ScaleBar tilingScheme={tileSchemes ? tileSchemes[0] : null} schemeId={selectedScenarioValue} />
          </LegendContainer>
          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
}
