export const _publicDataSets = {
  dev: {
    readerId: "93cc5786-a45a-494a-a13c-f62cf49f4a7a",
    dockerImage: "dhiacrdev.azurecr.io/dhi-statistics_cczis",
    dockerInterpolationImage: "dhiacrdev.azurecr.io/dhi-interp_cczis",
    dockerWaveProfileImage: "dhiacrdev.azurecr.io/dhi-wave_cczis",
    env: "dev",
    PublicUserUpload: {
      id: "a0bdcdf3-4451-4cbc-a1b7-af306882164c",
    },
    PrivateUserUpload: {
      id: "3166dd7e-576d-4b9d-becb-1b02666999ee",
    },
    DashboardKeyDataset: {
      id: "6f7e8cda-91ff-42a5-9545-05fdd9255fd3",
    },
    PublicWaveToolbox: {
      id: "68dc6631-0ab3-4c5d-9330-04ecd0a4b186",
    },
    WISShapefile08e: {
      id: "0abddbb7-ff2c-41f8-8163-e3b1946e616b",
    },
    TideGaugeEWL: {
      id: "b10e2b14-28cf-4a99-91a2-974897ebf634",
    },
    PrivateTemporaryBathymetry: {
      id: "c3f4b96d-ed82-448f-95cb-c3071a187234",
    },
    PrivateTemporaryTimeseries: {
      id: "755d08d4-19fc-428c-b6fb-09f94db701d4",
    },
    MSC50Dataset7c: {
      id: "8a367a80-fe37-48da-9ba7-4fcaeedfaed5",
    },
    MSC50Dataset7d: {
      id: "87e4011b-410a-4fce-9f36-f1a80e5bce42",
    },
    WeatherStationTimeseries10d: {
      id: "57afb24e-a409-4acf-8330-15ade5009b5b",
    },
    WeatherStationLocations10e: {
      id: "4c993e94-c776-48ec-adc3-e56e68835ca2",
    },
    WISTimeseries8d: {
      id: "ab4d4398-e296-4620-8a05-ee644b14c7dd",
    },
    MSC50Dataset7e: {
      id: "0b4bcdbc-81ce-4ee4-b7db-85fd4b6e23e3",
    },
    Wind: {
      id: "3ae1932e-e140-43bb-9c6f-39ca76cea3a6",
      project: "fc96d77c-858b-481f-be1b-9e60cc714823",
    },
    IceCover: {
      projectA: "cda0363e-ad5b-4236-af7e-8afe7ec31cb4",
      projectB: "64f92b71-64b9-49bf-8273-4189ed5bb2fc",
      prMed: "114d42af-ac8a-463c-9f01-88477decd07e",
      cpMed: "74a22cb3-0c72-4dc4-b87a-d3f6aec580ec",
      breakUp: "331f2115-81fa-4335-be60-6ce5802c4fd1",
      freezeUp: "dc50154a-6899-406d-ba6b-58db42d027a1",
      sACT: "0c4d7033-adf8-4879-8132-332e05a222d7",
    },
    IceThicknessLocations12a: {
      id: "3181b144-5408-443d-a175-1cd1effca2c0",
    },
    IceThicknessLocations12b: {
      id: "e3fb1eec-85b7-4878-81cf-6473f8560e18",
    },
    Bathymetry: {
      NonnaMerged: {
        id: "731c0128-c671-452f-af4f-e93c33ff74d4",
        project: "b89a3698-4854-4227-bb32-747da0f54ce5",
      },
      Nonna: {
        id: "",
        project: "a17bb74f-556c-47d1-a2a7-29f7f0ee3936",
      },
      AllFolder: {
        project: "a1b61b87-069c-4d99-b364-9fbf9dc5f3a0",
      },
    },
    TideGaugesExtremeWL: {
      Sample: {
        id: "af97042f-49ab-455f-95e0-e0764ddf199a",
      },
    },
    TidalHeights: {
      id: "a0611f1f-f89d-48bb-ae83-9f118e44ae26",
    },
    CanEwalt: {
      Rpc85: {
        id: "16b86b57-ab9b-4cf4-8b9b-ccfa787288d3",
      },
      Rpc45: {
        id: "bd9409d8-4589-4d8d-b716-0e202b568dd1",
      },
      Rpc26: {
        id: "f1d0fd40-dc35-4579-986f-08d14436aa67",
      },
    },
    NasaAr6: {
      Ssp245p05: {
        id: "5f1c757e-84da-4120-83b0-77d965f26c85",
      },
      Ssp245p10: {
        id: "10c696b5-37ec-40d2-879c-ba5885706ce3",
      },
      Ssp245p50: {
        id: "d671908c-fb95-477f-b2e1-a53fb7973e4c",
      },
      Ssp245p90: {
        id: "d9b59461-67b8-4906-8973-871ef4356aad",
      },
      Ssp245p95: {
        id: "b3509023-2e30-427c-aa2e-26dc58c4346e",
      },
      Ssp585p05: {
        id: "e067cb30-367e-4444-b4f4-8697ecf8bdea",
      },
      Ssp585p10: {
        id: "24d01ed1-a52f-48d2-b9a6-dea1457fc569",
      },
      Ssp585p50: {
        id: "51fb692c-71f0-44ca-b981-7f33f9eaf46b",
      },
      Ssp585p90: {
        id: "656ff814-c41a-49ce-9222-49ddf483bc5b",
      },
      Ssp585p95: {
        id: "3d019f15-ceeb-41f7-bc60-ec43fa5076a2",
      },
    },
    WaveHeight: {
      id: "8c643a51-4c6d-4417-9a49-3dd3acfb3e45",
      project: "17af1baf-c958-4e58-a331-278882c0b249",
    },
    WaterLevel: {
      id: "cb201271-3a3d-4d21-87da-3649cb31020d",
      second_id: "943bae51-6216-4909-b330-a4fcd0396c38",
      project: "b82ee4c2-8228-46bd-9b8d-245b97d03a4d",
    },
    SeaLevelRise: {
      projectA: "0e227ca9-ed16-4835-b501-acb02306871f",
      projectB: "cc1c60da-1da3-4365-9157-82521d41ad05",
      cccs: "3b32d617-f813-46ec-b795-0d338fd7b319",
      nasa: "8971c4ec-1a40-4355-a5df-52984df7f46a",
      cccsDataset: "aa49a7f7-a143-4400-8dae-d21b67a77745",
      nasa245Dataset: "6a3e5c4d-df6a-4957-97b1-478b2c27aaee",
      nasa585Dataset: "2d4ad42d-eba6-443a-b92a-91b66625f612",
    },
    CoastBoundaries: [
      "cbfb510a-b147-47f1-9f4b-e50f0548347d",
      "29646978-8283-4ee8-8037-a0fc9ee0a6d1",
      "1938273c-e849-4a9e-a51f-4c777c5d2387",
      "93f870c5-e573-40b0-8c6e-50b867b6b64e",
    ],
    SCHLocations: {
      id: "2cb86f29-eb0b-41a5-8085-64aee6e94d0d",
    },
    Boreholes: {
      id: "10ee364d-4b4b-4676-8abf-b9ecd2aa29bc",
    },
  },
  test: {
    readerId: "f1c55997-8dc5-4a86-8739-4f125005af0d",
    dockerImage: "",
    dockerInterpolationImage: "dhiacrdev.azurecr.io/dhi-interp_cczis",
    dockerWaveProfileImage: "dhiacrdev.azurecr.io/dhi-wave_cczis",
    env: "dev",
    PublicUserUpload: {
      id: "c647c3fe-2e2b-408a-ba43-ff4bf21f3f19",
    },
    PrivateUserUpload: {
      id: "ad2f3a97-b0a2-4650-bf3c-989bfb4de301",
    },
    DashboardKeyDataset: {
      id: "",
    },
    PrivateTemporaryBathymetry: {
      id: "4f9cd3d1-d82d-48fa-a197-99b83570fba6",
    },
    WISTimeseries8d: {
      id: "",
    },
    WISShapefile08e: {
      id: "",
    },
    WeatherStationTimeseries10d: {
      id: "",
    },
    WeatherStationLocations10e: {
      id: "",
    },
    IceThicknessLocations12a: {
      id: "",
    },
    IceThicknessLocations12b: {
      id: "",
    },
    TideGaugeEWL: {
      id: "",
    },
    PrivateTemporaryTimeseries: {
      id: "c2bca062-a5e2-4af8-9995-a451daab2429",
    },
    PublicWaveToolbox: {
      id: "68dc6631-0ab3-4c5d-9330-04ecd0a4b186",
    },
    TidalHeights: {
      id: "",
    },
    CanEwalt: {
      Rpc85: {
        id: "",
      },
      Rpc45: {
        id: "",
      },
      Rpc26: {
        id: "",
      },
    },
    NasaAr6: {
      Ssp245p05: {
        id: "",
      },
      Ssp245p10: {
        id: "",
      },
      Ssp245p50: {
        id: "",
      },
      Ssp245p90: {
        id: "",
      },
      Ssp245p95: {
        id: "",
      },
      Ssp585p05: {
        id: "",
      },
      Ssp585p10: {
        id: "",
      },
      Ssp585p50: {
        id: "",
      },
      Ssp585p90: {
        id: "",
      },
      Ssp585p95: {
        id: "",
      },
    },
    Bathymetry: {
      NonnaMerged: {
        id: "027afeb7-8ad5-4f20-b36a-740d219fe552",
        project: "2d8adaa6-16ad-46b0-8845-ce3ff5c3eb4c",
      },
      Nonna: {
        id: "",
        project: "13d7a26e-c75e-4ef1-91b6-d19f1dd9dda9",
      },
      AllFolder: {
        project: "cdac75dd-abcd-4840-b1f5-cb9a777e72d5",
      },
    },
    MSC50Dataset7c: {
      id: "",
    },
    MSC50Dataset7d: {
      id: "",
    },
    MSC50Dataset7e: {
      id: "",
    },
    Wind: {
      id: "",
      project: "",
    },
    IceCover: {
      projectA: "",
      projectB: "",
      prMed: "",
      cpMed: "",
      breakUp: "",
      freezeUp: "",
      sACT: "",
    },
    WaveHeight: {
      id: "0e50f206-742c-470e-b903-9d239e6c76cd",
      project: "45d2a1c3-6cc8-4dc4-b5ea-165667341fbe",
    },
    TideGaugesExtremeWL: {
      Sample: {
        id: "",
      },
    },
    WaterLevel: {
      id: "1860cc35-bedb-42a2-bcf6-6768857ca2d5",
      second_id: "d587c29a-c5e2-40da-b090-785454bb83b3",
      project: "29ff6cb3-e3aa-408d-ae23-cb04d149ca78",
    },
    SeaLevelRise: {
      projectA: "25e165d6-b027-40ad-b646-b0087eb3403d",
      projectB: "",
      cccs: "73176c03-6490-48ce-8a39-07473bf49cc2",
      nasa: "",
      cccsDataset: "",
      nasa245Dataset: "",
      nasa585Dataset: "",
    },
    CoastBoundaries: [
      "4c03f45d-ef64-42c4-a642-7a60ff198675",
      "1447e5f2-858c-41a7-b97f-4cf842c0ac6c",
      "6080ee1f-3757-4459-b712-5ff8696cec4a",
      "afa33834-1186-4124-8599-629200a6eef9",
    ],
    SCHLocations: {
      id: "509fb6a8-94b7-481f-8bf5-a5d156087c98",
    },
    Boreholes: {
      id: "94e4c9d0-fbe5-4382-8676-d07acc0d94ff",
    },
  },
  prod: {
    readerId: "2f2d3759-0fd5-414c-96d4-82f11701b189",
    env: "prod",
    dockerImage: "dhiacrprod.azurecr.io/dhi-statistics_cczis",
    dockerInterpolationImage: "dhiacrprod.azurecr.io/dhi-interp_cczis",
    dockerWaveProfileImage: "dhiacrprod.azurecr.io/dhi-wave_cczis",
    PublicUserUpload: {
      id: "92036add-f317-4ba7-8bdc-31fea77df545",
    },
    PrivateUserUpload: {
      id: "ebe8190f-3ea2-4f12-9d14-ca7bb95e96ac",
    },
    PrivateTemporaryBathymetry: {
      id: "71a1b24a-cf49-4b3f-8a3a-6152137d0ce8",
    },
    PrivateTemporaryTimeseries: {
      id: "41c2030c-a5d4-4650-ad2e-4403fdf5ba9f",
    },
    SCHLocations: {
      id: "6001fc5a-da82-4796-903c-7b2cb559f41b",
    },
    TideGaugeEWL: {
      id: "224bb4bc-9e2f-47d6-b174-1ed1b082b784",
    },
    Bathymetry: {
      NonnaMerged: {
        id: "ea7cff08-b063-4384-8134-142f841d119f",
        project: "14b76c42-24e6-4d12-a0c4-4c934237574f",
      },
      Nonna: {
        id: "",
        project: "91309477-f7b2-49ee-9276-8e41d91d2baf",
      },
      AllFolder: {
        project: "6a86bb65-472c-4cae-b9a6-95e31700eeca",
      },
    },
    TideGaugesExtremeWL: {
      Sample: {
        id: "4f9cb608-bfef-4676-ab20-a6f418c431f0",
      },
    },
    TidalHeights: {
      id: "09a3052d-f465-46f4-b624-54b5c4d73856",
    },
    CanEwalt: {
      Rpc85: {
        id: "f8712cab-4e1c-4eeb-abec-f8f72be6da3e",
      },
      Rpc45: {
        id: "8b96b2d7-3755-4d34-8169-bbe46967c187",
      },
      Rpc26: {
        id: "f55bca11-dfe9-4c8c-9a96-d202a2b80be1",
      },
    },
    NasaAr6: {
      Ssp245p05: {
        id: "937383bf-0ef0-41d9-aa89-b17c48ffe140",
      },
      Ssp245p10: {
        id: "0cb5ac96-9b1b-4a1a-93f6-f93d2180a938",
      },
      Ssp245p50: {
        id: "3de3fa71-9126-4cbe-b5ea-eb04d26a3a8c",
      },
      Ssp245p90: {
        id: "f89e6423-86a6-4cb7-b5ec-80f5fd01c0be",
      },
      Ssp245p95: {
        id: "1d146dc9-9eb3-4ec4-ba79-51378fff58b2",
      },
      Ssp585p50: {
        id: "3063cbce-c7ff-4d9e-96c5-e3ccedf9ce36",
      },
      Ssp585p10: {
        id: "68b30866-b8bb-4b0c-8a0e-dded54b6f1a2",
      },
      Ssp585p05: {
        id: "9cc343a1-a5d9-47c6-b12a-901b7aa1b9e0",
      },
      Ssp585p90: {
        id: "200dcd38-ed61-4d40-a4ca-99c9b463d9f6",
      },
      Ssp585p95: {
        id: "32b0e0ce-e08f-4540-b98f-453fde5ae4fd",
      },
    },
    PublicWaveToolbox: {
      id: "c20de98a-dce4-4359-ba4c-e9793f9fa062",
    },
    IceThicknessLocations12a: {
      id: "fc77bbc8-fac2-41b7-83c9-d2aec214352f",
    },
    IceThicknessLocations12b: {
      id: "ff16168d-3987-48d6-8867-6ad86e611ef1",
    },
    MSC50Dataset7c: {
      id: "cdaad39f-9e0f-4ec3-b451-e29ef3d0e8df",
    },
    MSC50Dataset7d: {
      id: "b7779fe4-2ecb-452e-9524-8b85051db8b2",
    },
    MSC50Dataset7e: {
      id: "95f437ef-3edc-4e32-a8b7-7f903754429c",
    },
    Wind: {
      id: "3e728170-f003-4b2d-8c81-efe02c158eb3",
      project: "fc976580-ac57-4b41-86b5-913b89959ca9",
    },
    IceCover: {
      projectA: "313927f0-ab08-4124-96f1-5fc4db7cf5a9",
      projectB: "c5ac90b6-c4c4-4179-854f-f975cbe95058",
      prMed: "2c3bc953-cbd2-4a51-8470-33ec9a0c1daf",
      cpMed: "d6d390af-e6df-4ab3-9283-ee257aacc32a",
      breakUp: "30b93102-ea0f-48a2-ab33-dfa70d2a4650",
      freezeUp: "7611e7d3-6af7-4ba2-9882-c26a7268c5bd",
      sACT: "68c3a552-ce38-4b9b-99f6-525ab1b4eaf0",
    },
    WaveHeight: {
      id: "d0fba77f-b008-4bbc-8ff6-b9cbd9d56bd5",
      project: "49a0688b-fbdc-4fb3-b12e-c6df0cd24d9c",
    },
    WaterLevel: {
      id: "5d37e30e-39bd-449c-9529-eb9a1bd0b6a9",
      second_id: "38d36002-6b07-4437-bb59-49e14d335a1e",
      project: "f5f0c892-3bad-42b7-8354-bc8d70875dc4",
    },
    WISTimeseries8d: {
      id: "5b84c0f5-04d7-4e16-ba21-bc7a85945a5f",
    },
    WISShapefile08e: {
      id: "4f8bba08-80c3-4c5f-b274-9406b66b454f",
    },
    SeaLevelRise: {
      projectA: "85e41ff0-a368-4c19-830b-42e945d331c6",
      projectB: "2ea6cbf9-b01d-4212-ad43-5d47ac9a9caf",
      cccs: "0beb6f05-749a-42dc-a3f5-49752ca07a19",
      nasa: "a0efd612-f18c-42a6-9690-181e0d7a6fe9",
      cccsDataset: "7c83a456-1e31-4bca-b17f-c36c7b9ce190",
      nasa245Dataset: "3782bc39-e217-44b4-9c3f-561d0513a074",
      nasa585Dataset: "974b57d3-afda-4d39-9a06-5eabb16f8df3",
    },
    DashboardKeyDataset: {
      id: "0b82206c-8c07-44fd-9ad7-570e0d840f5f",
    },
    WeatherStationTimeseries10d: {
      id: "267060be-ce00-4fac-b734-e720aebab39a",
    },
    WeatherStationLocations10e: {
      id: "fb5be5c6-b333-4e65-94a1-1c9bfb8687f4",
    },
    CoastBoundaries: [
      "fb105923-e0a3-4a6a-bb9d-b79eff361c2d",
      "364d2cdc-ffb0-4328-b1d7-2034144b340e",
      "bd31efca-b8a4-42ae-b490-8fb1c09995d5",
      "17184c09-e8cb-462b-8a2c-c4cbdb224f0f",
    ],

    Boreholes: {
      id: "79ad4ded-4396-4c8d-a7d7-251e2e098174",
    },
  },
};
