import {
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { BoxplotChart, BoxplotSeriesOption } from "echarts/charts";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { Box, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import { getAllBathymetryDatasets } from "../../../api/backend_public";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Papa from "papaparse";
import moment from "moment";
import { publicDataSets } from "../../../api/init";

echarts.use([TooltipComponent, GridComponent, LegendComponent, BoxplotChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
  TooltipComponentOption | GridComponentOption | LegendComponentOption | BoxplotSeriesOption
>;

interface ChartProps {
  cisValue: string;
  boxPlotData: any;
  setBoxPlotData: Dispatch<SetStateAction<any>>;
}

export const DashboardIceWeekChart = ({ cisValue, boxPlotData, setBoxPlotData }: ChartProps) => {
  const [option, setOption] = useState<EChartsOption>({});

  const calculateBoxPlotValues = (values: any) => {
    if (!values.length) return [NaN, NaN, NaN, NaN, NaN];

    const sorted = values.filter((v: any) => !isNaN(v)).sort((a: any, b: any) => a - b);
    const min = sorted[0];
    const max = sorted[sorted.length - 1];
    const median = sorted[Math.floor(sorted.length / 2)];
    const q1 = sorted[Math.floor(sorted.length / 4)] || median;
    const q3 = sorted[Math.ceil(sorted.length * (3 / 4))] || median;

    return [min, q1, median, q3, max];
  };

  const prepareBoxPlotData = (data: any) => {
    const weekData: { [key: number]: number[] } = {};
    data.forEach((item: any) => {
      const week = moment(item.date, "YYYY-MM-DD").isoWeek();
      if (!isNaN(week)) {
        if (!weekData[week]) weekData[week] = [];
        const iceThick = parseFloat(item.ice_thick);
        if (!isNaN(iceThick)) {
          weekData[week].push(iceThick);
        }
      }
    });

    const boxPlotData = Object.entries(weekData)
      .map(([week, values]) => {
        return [week, ...calculateBoxPlotValues(values)];
      })
      .filter(([week, min, q1, median, q3, max]) => !isNaN(min));

    boxPlotData.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));

    return boxPlotData;
  };

  const getDatasetList = async () => {
    const datasets = await getAllBathymetryDatasets(publicDataSets.IceThicknessLocations12b.id);
    const matchingDatasetUrl = datasets.data.filter((item: any) => item.name === `${cisValue}_ts.csv`)[0].datasetUrl;
    const csvResponse = await fetch(matchingDatasetUrl);
    const csvText = await csvResponse.text();
    const parsedData = Papa.parse(csvText, { header: true }).data;
    const plotData = prepareBoxPlotData(parsedData);
    setBoxPlotData(plotData);

    setOption({
      tooltip: { trigger: "item", axisPointer: { type: "shadow" } },
      xAxis: {
        type: "category",
        data: plotData.map((item) => `${item[0]}`),
        name: "Week of Year",
        nameLocation: "middle",
        nameGap: 30,
      },
      yAxis: {
        type: "value",
        name: "Ice Thickness (cm)",
        nameLocation: "middle",
        nameGap: 30,
        axisLine: { show: true },
      },
      series: [
        {
          name: "Ice Thickness",
          type: "boxplot",
          data: plotData.map((item) => item.slice(1)),
          itemStyle: {
            color: "#00AAFF",
            borderColor: "#09334b",
            borderWidth: 2,
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (cisValue !== "") {
      getDatasetList();
    }
  }, [cisValue]);
  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        height: "21rem",
        m: "1rem",
      }}
    >
      <Box m="1rem" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.75rem">
            Measured Ice Thickness Statistics by Week
          </Typography>
          <LightTooltip
            title={
              <Typography variant="body2">
                Measured ice thicknesses originate from two sources: 1 - Before 2002: Original Ice Thickness Program
                1947-2002. Available at:{" "}
                <a href="https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-1947-2002/?lang=en">
                  https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-1947-2002/?lang=en
                </a>
                2 - After 2002: Ice Thickness Program since 2002{" "}
                <a href="https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-2002/?lang=en">
                  https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-2002/?lang=en
                </a>
              </Typography>
            }
            placement={"right"}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton sx={{ "&:hover": { color: "secondary.dark", mt: "0rem" } }} disableRipple>
              <InfoOutlined />
            </IconButton>
          </LightTooltip>
        </Box>
        <Typography variant="caption" color="grey.500" mt="-0.5rem">
          CIS Ice Measurements
        </Typography>
      </Box>
      {cisValue !== "" ? (
        <ReactEcharts
          option={option}
          style={{ height: "100%", width: "100%", marginTop: "-3rem", marginBottom: "1rem" }}
        />
      ) : (
        <Typography
          color="secondary.dark"
          variant="body1"
          display="flex"
          fontWeight="700"
          justifyContent="center"
          mt="14%"
        >
          No Data Available
        </Typography>
      )}
    </Box>
  );
};
