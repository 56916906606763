import { Box, Divider, Pagination, Tab, Tabs, Typography, tabsClasses } from "@mui/material";
import { useOpenlayersContext } from "../Openlayers/OpenlayersContext";
import { useEffect, useRef } from "react";
import { harboursLayer, clusterLayer } from "../Openlayers/default_groups_layers";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MapProps, featureTabs } from "../Openlayers/data_layers";
import CloseIcon from "@mui/icons-material/Close";
import DashboardPortsList from "../DashboardComponents/DashboardPortsList";
import DashboardSearchBar from "../DashboardComponents/DashboardSearchBar";
import DashboardSwitch from "../DashboardComponents/DashboardSwitch";
import { DashboardOverviewTab } from "../DashboardComponents/DashboardOverviewTab";
import { DashboardWaterLevelTab } from "../DashboardComponents/DashboardWaterLevelTab/DashboardWaterLevelTab";
import { DashboardWavesTab } from "../DashboardComponents/DashboardWavesTabs/DashboardWavesTab";
import { DashboardWindTab } from "../DashboardComponents/DashboardWindTab/DashboardWindTab";
import { DashboardIceTab } from "../DashboardComponents/DashboardIceTab/DashboardIceTab";
import { DashboardSeaLevelRise } from "../DashboardComponents/DashboardSeaLevelRise/DashboardSeaLevelRise";
import { DashboardGeotechnicalTab } from "../DashboardComponents/DashboardGeotechnicalTab/DashboardGeotechnicalTab";
import { DashboardOtherData } from "../DashboardComponents/DashboardOtherData/DashboardOtherData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import { ZoomToBayOFFundy } from "../../features/ZoomToBayOfFundaySlice";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { ZoomToAtlanticCanada } from "../../features/ZoomToAtlanticCanadaSlice";
import { Fill, Stroke, Style } from "ol/style";
import { Feature } from "ol";
import { getGreatLakeCoordinates } from "../../api/backend_public";
import { platform, publicDataSets } from "../../api/init";
import BaseLayer from "ol/layer/Base";
import { changeBathyButtonSelected } from "../../features/ToolboxBathymetryInterpolationImportSlice";
import { setTimeseriesGenerateStatisticsClicked } from "../../features/ToolboxFeatures/SeasonalStatisticsSlice";
import { setWaveProfileGenerateStatisticsClicked } from "../../features/ToolboxFeatures/WaveProfileToolboxSlice";
import { resetLayerVisibilities } from "../../features/DataRepoLayerVisibilitySlice";

function DashboardPanel({ features }: MapProps) {
  const map = useOpenlayersContext();
  const navigate = useNavigate();
  const location = useLocation();
  const itemsPerPage = 25;
  const dispatch = useDispatch();
  const portname = new URLSearchParams(location.search).get("port");
  const tabNumber = new URLSearchParams(location.search).get("tab");
  const [activeTab, setActiveTab] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredHarbours, setFilteredHarbours] = useState<any[]>([]);
  const userFavoritesRef = useRef<string[]>([]);
  const [favoritePorts, setFavoritePorts] = useState<Record<string, string[]>>({});
  const polygonLayers = useRef<{ [id: string]: VectorLayer<VectorSource> }>({});
  const [currentHarbours, setCurrentHarbours] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dashboardSearchValue = useSelector((state: RootState) => state.dashboardSearchbar.value);
  const harboursInExtent = useSelector((state: RootState) => state.harborsInExtent.value);
  const [dashboardFeatures, setDashboardFeatures] = useState(features);
  const bayOfFundyValue = useSelector((state: RootState) => state.zoomToBayOfFundy.value);
  const atlanticCanadaValue = useSelector((state: RootState) => state.zoomToAtlanticCanada.value);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const { idTokenPayload } = useOidcIdToken();
  const [prevPortname, setPrevPortname] = useState<string | null>(null);
  const jobId = useSelector((state: RootState) => state.seasonalToolbox.jobId);
  const waveProfileJobId = useSelector((state: RootState) => state.waveProfileToolbox.jobId);

  useEffect(() => {
    const feature = dashboardFeatures?.find((f: any) => f.get("Harbour_nu") == portname);
    const geometry = feature?.getGeometry();

    if (portname && map?.current && geometry && portname !== prevPortname) {
      const extent = geometry.getExtent();
      const maxZoom = 15;
      map.current.getView().fit(extent, {
        maxZoom: maxZoom,
        duration: 1000,
        padding: [50, 50, 50, 50],
      });

      setPrevPortname(portname);
    }
  }, [portname, map, dashboardFeatures, prevPortname]);

  if (map?.current) {
    map.current.getLayers().forEach((layer) => {
      if (
        layer.get("id")?.startsWith("bathymetryIconLayer") ||
        layer.get("id")?.startsWith("gislayers") ||
        layer.get("id")?.startsWith("bathymetryLayer")
      ) {
        layer.setVisible(false);
      }
    });
  }

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setTimeseriesGenerateStatisticsClicked(false));
    }
    if (waveProfileJobId !== "") {
      await platform.putjson(`process/job/${waveProfileJobId}/cancel`, "3");
      dispatch(setWaveProfileGenerateStatisticsClicked(false));
    }
  };

  useEffect(() => {
    stopInterpolation();
    dispatch(resetLayerVisibilities());
    if (map?.current) {
      dispatch(changeBathyButtonSelected(""));
      const layersToRemove: BaseLayer[] = [];
      map.current.getLayers().forEach((layer: BaseLayer) => {
        if (layer.get("customLayerName") === "customPolygonLayer") {
          layersToRemove.push(layer);
        } else if (layer.get("customId") === "drawnPolygon") {
          layersToRemove.push(layer);
        } else if (layer.get("resultId") === "resultGisLayer") {
          layersToRemove.push(layer);
        }
      });

      layersToRemove.forEach((layer) => {
        map.current?.removeLayer(layer);
      });
    }
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (tabNumber) {
      setActiveTab(parseInt(tabNumber));
    }
    return () => {
      setActiveTab(0);
    };
  }, [tabNumber]);

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    searchParams.set("tab", newValue.toString());
    navigate(`?${searchParams}`);
    setActiveTab(newValue);
  };

  const isPartialMatch = (input: string, target: string): boolean => {
    return target.toLowerCase().startsWith(input.toLowerCase());
  };

  const getProvinceValue = (input: string): string | undefined => {
    const provinceMap: Record<string, string> = {
      NS: "Nova Scotia",
      NL: "Newfoundland and Labrador",
      PE: "Prince Edward Island",
      QC: "Quebec",
      BC: "British Columbia",
      AB: "Alberta",
      NB: "New Brunswick",
      ON: "Ontario",
      MB: "Manitoba",
      SK: "Saskatchewan",
      YT: "Yukon",
      NT: "Northwest Territories",
      NU: "Nunavut",
    };

    if (provinceMap[input]) {
      return provinceMap[input];
    }
    const matchedKey = Object.keys(provinceMap).find((key) => isPartialMatch(input, provinceMap[key]));
    return matchedKey;
  };

  const ZoomToBayofFundy = () => {
    const extent = [-7529973.195528984, 5456382.688832779, -7028380.710229826, 5806232.461351052];
    map?.current?.getView().fit(extent, { duration: 500 });
    dispatch(ZoomToBayOFFundy({ value: false }));
  };

  useEffect(() => {
    if (bayOfFundyValue === true) {
      ZoomToBayofFundy();
    }
  }, []);

  const fetchCoordinates = async (id: string) => {
    const response = await getGreatLakeCoordinates(id);
    const featuresTOPOJson = new GeoJSON().readFeatures(response, {
      dataProjection: "EPSG:4326",
      featureProjection: map?.current?.getView().getProjection(),
    });

    const boundaryLayer = new VectorLayer({
      source: new VectorSource({
        features: featuresTOPOJson,
      }),
      style: new Style({
        stroke: new Stroke({
          color: "rgba(233, 150, 122, 0.8)",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(233, 150, 122, 0.2)",
        }),
      }),
    } as any);

    polygonLayers.current[id] = boundaryLayer;
    return response;
  };

  const onNavigationLinkClick = async (id: string) => {
    let layer = polygonLayers.current[id];
    if (!layer) {
      await fetchCoordinates(id);
      layer = polygonLayers.current[id];
    }
    const features = layer?.getSource()?.getFeatures();
    if (features && features.length > 0) {
      const extent = features[0].getGeometry()?.getExtent();
      if (extent) {
        map?.current?.getView().fit(extent, { duration: 500 });
      }
    } else {
      console.error("No features found in layer ", layer);
    }
    dispatch(ZoomToAtlanticCanada({ value: false }));
  };
  useEffect(() => {
    if (atlanticCanadaValue === true) {
      onNavigationLinkClick(publicDataSets.CoastBoundaries[2]);
    }
  }, []);

  useEffect(() => {
    if (dashboardSearchValue.length === 0) {
      setFilteredHarbours(features || []);
    } else {
      if (features) {
        const filtered = features.filter((port: any) => {
          const matchedProvinceValue = getProvinceValue(dashboardSearchValue);
          return (
            port.get("Harbour_Na").toLowerCase().includes(dashboardSearchValue.toLowerCase()) ||
            port.get("Harbour_nu").toString().toLowerCase().includes(dashboardSearchValue.toLowerCase()) ||
            (port.get("Province") &&
              (port.get("Province").toLowerCase().includes(dashboardSearchValue.toLowerCase()) ||
                (matchedProvinceValue &&
                  port.get("Province").toLowerCase().includes(matchedProvinceValue.toLowerCase()))))
          );
        });
        setFilteredHarbours(filtered);
      }
    }
    setCurrentPage(1);
  }, [dashboardSearchValue, features, itemsPerPage]);

  useEffect(() => {
    clusterLayer.setVisible(true);
    return () => {
      clusterLayer.setVisible(false);
    };
  }, []);

  useEffect(() => {
    let currentList: any = [];

    if (checked) {
      currentList = dashboardFeatures?.filter((harbour) =>
        userFavoritesRef.current.includes(harbour.get("Harbour_Na"))
      );

      setCurrentHarbours(currentList);
      setTotalPages(1);
    } else {
      if (dashboardSearchValue.length > 0) {
        currentList = dashboardFeatures?.filter((feature) =>
          filteredHarbours.some((filteredHarbour) => filteredHarbour.get("Harbour_nu") === feature.get("Harbour_nu"))
        );
      } else {
        currentList = dashboardFeatures;
      }
      if (currentList) {
        const paginatedHarbours = currentList.slice(startIndex, endIndex);
        setCurrentHarbours(paginatedHarbours);
        setTotalPages(Math.ceil(currentList.length / itemsPerPage));
      }
    }
  }, [checked, currentPage, filteredHarbours, userFavoritesRef.current, harboursInExtent, dashboardFeatures]);

  useEffect(() => {
    if (idTokenPayload && idTokenPayload.sub) {
      const userId = idTokenPayload.sub;
      const storedData = localStorage.getItem("favoritePorts");
      let allUsersFavorites = storedData ? JSON.parse(storedData) : {};
      userFavoritesRef.current = allUsersFavorites[userId] || [];
      setFavoritePorts((prevState) => ({
        ...prevState,
        [userId]: userFavoritesRef.current,
      }));
    }
  }, [idTokenPayload]);

  if (idTokenPayload && idTokenPayload.sub && favoritePorts[idTokenPayload.sub]) {
    userFavoritesRef.current = favoritePorts[idTokenPayload.sub];
  }

  const onMapZoomFilterFeature = () => {
    const extent: any = map?.current?.getView().calculateExtent(map?.current.getSize());
    const filteredExtentFeatures: Feature[] = [];

    harboursLayer?.getSource()?.forEachFeatureInExtent(extent, function (feature) {
      filteredExtentFeatures.push(feature);
    });

    filteredExtentFeatures.sort((a: Feature, b: Feature) => a.get("Harbour_Na").localeCompare(b.get("Harbour_Na")));

    setDashboardFeatures(filteredExtentFeatures);
  };

  useEffect(() => {
    if (map?.current && !portname) {
      map?.current.on("moveend", onMapZoomFilterFeature);
      return () => {
        map?.current?.un("moveend", onMapZoomFilterFeature);
      };
    }
  }, [map?.current, portname]);

  return (
    <>
      {portname ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              m: "1rem 1rem 0rem 1rem",
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography style={{ color: "#09334B" }} variant="body1" fontWeight="700" component="span">
                {dashboardFeatures
                  ?.find((feature) => feature.get("Harbour_nu") === parseInt(portname))
                  ?.get("Harbour_Na") ?? ""}
              </Typography>
              <Typography variant="caption" sx={{ color: "grey.500" }}>
                Small Craft Harbour
              </Typography>
            </Box>
            <CloseIcon
              fontSize="medium"
              sx={{
                color: "grey.500",
                mt: "0.5rem",
                mr: "2rem",
                "&:hover": { cursor: "Pointer" },
              }}
              onClick={() => {
                searchParams.delete("port");
                searchParams.delete("tab");
                navigate(`?${searchParams}`);
              }}
            />
          </Box>

          <Tabs
            value={activeTab}
            onChange={changeTab}
            scrollButtons="auto"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                color: "secondary.dark",
                "&.Mui-disabled": { opacity: 0.3 },
              },
              width: "100%",
            }}
          >
            {featureTabs.map((tab: any) => (
              <Tab
                disableRipple
                key={tab}
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: featureTabs.indexOf(tab) == activeTab ? 700 : 400,
                      mr: "0.5rem",
                    }}
                  >
                    {tab}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          <Divider />

          {activeTab === 0 && (
            <DashboardOverviewTab
              port={
                (dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) as Feature) ??
                null
              }
            />
          )}
          {activeTab === 1 && (
            <DashboardWaterLevelTab
              port={dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) ?? null}
            />
          )}
          {activeTab === 2 && (
            <DashboardWavesTab
              port={
                (dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) as Feature) ??
                null
              }
            />
          )}
          {activeTab === 3 && (
            <DashboardWindTab
              port={
                (dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) as Feature) ??
                null
              }
            />
          )}
          {activeTab === 4 && (
            <DashboardIceTab
              port={dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) ?? null}
            />
          )}
          {activeTab === 5 && (
            <DashboardSeaLevelRise
              features={features}
              port={dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) ?? null}
            />
          )}
          {activeTab === 6 && (
            <DashboardGeotechnicalTab
              port={dashboardFeatures?.find((feature) => feature.get("Harbour_nu") === parseInt(portname)) ?? null}
            />
          )}
          {activeTab === 7 && <DashboardOtherData />}
        </>
      ) : (
        <Box
          bgcolor="#FFFFFF"
          width="800px"
          zIndex="100"
          boxShadow="4px 0 8px 0 rgba(0, 0, 0, 0.15)"
          height="calc(100vh - 3rem)"
        >
          <DashboardSearchBar />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body2" mt="1rem" ml="1.5rem" color="secondary.dark">
              Explore available data by clicking on the map or from location's list below
            </Typography>
            <DashboardSwitch checked={checked} setChecked={setChecked} />
          </Box>
          <Box
            sx={{
              height: "calc(100% - 10.5rem)",
              overflowY: "auto",
              marginTop: "0.5rem",
              overflowX: "hidden",
            }}
          >
            {currentHarbours?.map((harbour, index) => (
              <DashboardPortsList
                key={`${harbour}-${index}`}
                port={harbour}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                favoritePorts={favoritePorts}
                setFavoritePorts={setFavoritePorts}
                checked={checked}
              />
            ))}
            {dashboardSearchValue.length > 0 && filteredHarbours.length === 0 && (
              <Typography variant="h5" mt="40%" ml="1.5rem" textAlign="center" color="secondary.dark">
                No matching harbours found.
              </Typography>
            )}
            {checked && userFavoritesRef.current.length === 12 && (
              <Typography variant="h5" mt="40%" ml="1.5rem" textAlign="center" color="secondary.dark">
                No harbours have been favourited.
              </Typography>
            )}
          </Box>
          <Box>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              color="primary"
              sx={{ justifyContent: "center", display: "flex", mt: "0.5rem" }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default React.memo(DashboardPanel);
