import { Button, Typography, Box, Grid, Paper, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ZoomToBayOFFundy } from "../../features/ZoomToBayOfFundaySlice";
import { ZoomToAtlanticCanada } from "../../features/ZoomToAtlanticCanadaSlice";
import { dataList } from "./DataList";
import { useState } from "react";
import { changeHeadDataType } from "../../features/DataRepoHeadDataTypeSlice";

const PaperItem = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  minHeight: "200px",
  maxWidth: "384px",
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export const FrontpageDataList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container item spacing={0}>
        <Box
          style={{
            height: "480px",
            width: "100%",
            backgroundImage: "url(images/homePageBannerImageWithGradient.jpg)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: " center bottom",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "1296px",
              margin: "0 auto",
              paddingTop: "5rem",
              pl: "1rem",
            }}
          >
            <Typography
              color="#FFFFFF"
              sx={{
                fontWeight: "700",
                fontSize: "48px",
                lineHeight: "3.5rem",
                maxWidth: "33rem",
                width: "auto",
              }}
              component="div"
            >
              Empowering Decision Making for Canadian Coastal Infrastructure
            </Typography>

            <Typography
              color="#FFFFFF"
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                maxWidth: "33rem",
                width: "auto",
              }}
              component="div"
            >
              A one-stop decision support system, with coastal datasets, analyses, and tools to allow for planning,
              design, and climate change risk assessment of marine and coastal infrastructure.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "11rem",
                height: "3rem",
                display: "flex",
                flexDirection: "row",
                gap: "0rem",
                mt: "0.5rem",
              }}
              disableRipple
              disableElevation
              onClick={() => {
                navigate(`/dashboard`);
              }}
            >
              <Typography variant="body2" fontWeight="700" m="0.5rem 0rem 0.5rem 0.5rem">
                Start Exploring
              </Typography>
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Box>
      </Grid>

      <Box
        sx={{
          flexGrow: 1,
          maxWidth: "1296px",
          margin: "0 auto",
          pl: "1rem",
          bgcolor: "white",
        }}
      >
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <Typography variant="h1" color="secondary.dark" fontWeight="700" mt="1rem">
              Canadian Coastal Data
            </Typography>
            <Typography variant="body2" color="secondary.dark" ml="0.25rem" mb="1rem">
              All you need for your next challenge
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ mb: "2rem" }}>
          <Grid container item spacing={3}>
            {dataList.map((data) => (
              <Grid key={data.title} item xs={6} md={4}>
                <PaperItem
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigate(data.toPage);
                    if (data.title === "Small Craft Harbours in Atlantic Canada") {
                      dispatch(ZoomToAtlanticCanada({ value: true }));
                    } else if (data.title === "Coastal Conditions in a Changing Climate") {
                      dispatch(ZoomToBayOFFundy({ value: true }));
                    }
                    if (data.title === "Hydrographic Datasets") {
                      dispatch(changeHeadDataType(["Bathymetry"]));
                    }
                  }}
                >
                  <Box
                    style={{
                      minHeight: "200px",
                      maxWidth: "384px",
                      backgroundImage: "url(" + data.image + ")",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center top",
                      borderRadius: "8px",
                    }}
                  />
                  <Typography gutterBottom variant="h6" mt="0.75rem">
                    {data.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data.value}
                  </Typography>
                </PaperItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
