import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxFileFormatSliceState {
  value: string;
}

const initialState: ToolboxFileFormatSliceState = {
  value: "",
};

export const ToolboxFileFormatSlice = createSlice({
  name: "datatype",
  initialState,
  reducers: {
    changeToolboxFileFormat: (
      state,
      action: PayloadAction<ToolboxFileFormatSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxFileFormatSlice.reducer;

export const { changeToolboxFileFormat } = ToolboxFileFormatSlice.actions;
