import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileSurveyDateSliceState {
  value: string | null;
}

const initialState: FileSurveyDateSliceState = {
  value: null,
};

export const DataRepoFileSurveyDateSlice = createSlice({
  name: "SurveyDate",
  initialState,
  reducers: {
    changeFileSurveyDate: (
      state,
      action: PayloadAction<FileSurveyDateSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileSurveyDateSlice.reducer;

export const { changeFileSurveyDate } = DataRepoFileSurveyDateSlice.actions;
