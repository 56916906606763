import { _publicDataSets } from "./datasets";

export let backend: IApi;
export let platform: IApi | any;
export let publicDataSets: any;

export const isApiError = (error: any) => error instanceof ApiError;
export class ApiError extends Error {
  httpStatus: string;
  httpStatusText: string;
  body: any;
  constructor(httpStatus: string, httpStatusText: string, body: any) {
    super(`${httpStatusText} [${httpStatus}]`);
    this.httpStatus = httpStatus;
    this.httpStatusText = httpStatusText;
    this.body = body;
  }
}

interface IApi {
  postJson?: (resource: string, payload?: object, version?: string, headers?: object) => Promise<any>;
  getJson?: (resource: string, version?: string, headers?: object) => Promise<any>;
  deleteJson?: (resource: string, version?: string, headers?: object) => Promise<any>;
  putjson?: (resource: string, version?: string, headers?: object) => Promise<any>;
}

export default function init(config: any) {
  const backendUrl = config.backendUrl;
  const platformUrl = config.platformUrl;
  if (!backendUrl || !platformUrl) return;

  let hostEnv = new URL(platformUrl).hostname;

  backend = {
    postJson: makePost(backendUrl),
    getJson: makeGet(backendUrl),
    deleteJson: makeDelete(backendUrl),
    putjson: makePut(backendUrl),
  };

  platform = {
    postJson: makePost(platformUrl),
    getJson: makeGet(platformUrl),
    deleteJson: makeDelete(platformUrl),
    putjson: makePut(platformUrl),
  };

  switch (true) {
    case hostEnv.includes("-dev"):
      publicDataSets = { ..._publicDataSets["dev"], envURL: config.platformUrl };
      break;
    case hostEnv.includes("-test"):
      publicDataSets = { ..._publicDataSets["test"], envURL: config.platformUrl };
      break;
    default:
      publicDataSets = { ..._publicDataSets["prod"], envURL: config.platformUrl };
  }
}

function makeDelete(baseUrl: string) {
  return async (resource: string, version = "1", additionalheaders = {}) => {
    const headers = {
      "api-version": version,
      ...additionalheaders,
    };
    const defaultOptions = {
      method: "DELETE",
      headers,
    };
    const url = `${baseUrl}/${resource}`;
    const resp = await fetch(url, defaultOptions);
    return handleResponse(resp, [200, 204]);
  };
}

function makePut(baseUrl: string) {
  return async (resource: string, version = "1", additionalheaders = {}) => {
    const headers = {
      "api-version": version,
      ...additionalheaders,
    };
    const defaultOptions = {
      method: "PUT",
      headers,
    };
    const url = `${baseUrl}/${resource}`;
    const resp = await fetch(url, defaultOptions);
    return handleResponse(resp, [200, 204]);
  };
}

function makePost(baseUrl: string) {
  return async (resource: string, payload = {}, version = "1", additionalheaders = {}) => {
    const headers = {
      "api-version": version,
      "Content-Type": "application/json",
      ...additionalheaders,
    };
    const defaultOptions = {
      method: "POST",
      headers,
      body: payload && JSON.stringify(payload),
    };
    const url = `${baseUrl}/${resource}`;
    const resp = await fetch(url, defaultOptions);
    return handleResponse(resp, [201, 200, 204]);
  };
}

function makeGet(baseUrl: string) {
  return async (resource: string, version = "1", additionalheaders = {}) => {
    const headers = {
      "api-version": version,
      "Content-Type": "application/json",
      ...additionalheaders,
    };
    const defaultOptions = { headers };
    const url = `${baseUrl}/${resource}`;
    const resp = await fetch(url, defaultOptions);
    return handleResponse(resp, [200, 204]);
  };
}

const handleResponse = async (resp: any, okStatuses: any) => {
  const result = await getResponseBody(resp);
  if (!okStatuses.includes(resp.status)) {
    // console.error(`Invalid status code ${resp.status}, expected ${okStatuses}`);
    return Promise.reject(apiError(resp, result));
  }
  return result;
};
const getResponseBody = async (resp: any) => {
  const contentType = resp.headers.get("Content-Type") || "";
  return contentType.includes("application/json") ? await resp.json() : await resp.text();
};
const apiError = (resp: any, body: any) => {
  const error = new ApiError(resp.status, resp.statusText, body);
  return error;
};

interface ItilesWMTS {
  schemeType: string;
  itemName: string;
  schemeId: string;
  outputType: string;
  aggregationMethod: string;
  tileWidth: number;
  tileHeight: number;
  minValue: number;
  maxValue: number;
  compressionLevel: number;
  renderValuesOutOfRangeAsTransparent: string;
  zoomLevels: number[];
  colors: string[];
}

export const initialTilesWMTS: ItilesWMTS = {
  schemeType: "WMTS",
  itemName: "Depth",
  schemeId: "Depth",
  outputType: "WEBP",
  aggregationMethod: "Mean",
  tileWidth: 512,
  tileHeight: 512,
  compressionLevel: 1,
  renderValuesOutOfRangeAsTransparent: "Above",
  zoomLevels: [0, 1, 2, 3],
  minValue: -30.0,
  maxValue: 5.0,
  colors: [
    "800080",
    "5500ab",
    "2a00d5",
    "0000ff",
    "0038e1",
    "006fc3",
    "00a6a6",
    "00c46e",
    "00e237",
    "00ff00",
    "55ff00",
    "aaff00",
    "ffaa00",
    "ffaa00",
    "ff5500",
    "ff0000",
  ],
};
