import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SeasonalFileTypeSliceState {
  value: string;
}

const initialState: SeasonalFileTypeSliceState = {
  value: "",
};

export const SeasonalFileTypeSlice = createSlice({
  name: "SeasonalFileType",
  initialState,
  reducers: {
    changeSeasonalFileType: (
      state,
      action: PayloadAction<SeasonalFileTypeSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default SeasonalFileTypeSlice.reducer;

export const { changeSeasonalFileType } = SeasonalFileTypeSlice.actions;
