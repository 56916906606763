import { Box, IconButton, Stack, Typography } from "@mui/material";

interface TopBarProps {
  onclick: () => void;
  icon: JSX.Element;
  name: string;
}

export const GeotechnicalTopBar = ({ onclick, icon, name }: TopBarProps) => {
  return (
    <Box
      onClick={onclick}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Stack direction="row">
        <IconButton color="primary" disableRipple sx={{ mt: "-0.2rem" }}>
          {icon}
        </IconButton>
        <Typography
          variant="body2"
          color="primary.main"
          fontWeight="700"
          mt="0.7rem"
        >
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};
