import { IconButton, Typography, Box, Backdrop, Modal, Fade, ImageList, ImageListItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const itemData = [
  {
    img: "./images/DirectionDiagram.tif",
    title: "DirectionDiagram",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
];

export const ToolboxInfoWaveProfileInfoModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "50rem" },
          }}
        >
          <Box display="flex" flexDirection="row" ml="0.5rem" justifyContent="space-between">
            <Box display="flex" flexDirection="row">
              <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
              <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem">
                Wave Profile Model
              </Typography>
            </Box>
            <IconButton sx={{ mr: "0.5rem" }} disableRipple onClick={handleClose}>
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box m="0.5rem 1rem 1rem 1rem" display="flex" flexDirection="column" gap="1rem">
            <Typography variant="body2" color="secondary.dark">
              1. Define a beach profile by inputting:
            </Typography>
            <Box pl={4}>
              <Typography variant="body2" color="secondary.dark" mt="-0.75rem">
                a. Offshore depth (z<sub>o</sub>)
              </Typography>
              <Typography variant="body2" color="secondary.dark" ml="1rem">
                Note that depths are negative.
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                b. Offshore distance (x<sub>o</sub>)
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                c. Beach orientation (θ<sub>b</sub>) *
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                d. Slope steepness parameter (b).
              </Typography>
              <Typography variant="body2" color="secondary.dark" ml="1rem">
                Note that b is an exponential coefficient for controlling the slope shape. For b = 1, the slope is
                linear. As b increases, the beach slope steepens and offshore flattens.
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                e. (Optional) Bed disturbance height (A<sub>b</sub>)
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                f. (Optional) X-Distance of bed disturbance height (X<sub>b</sub>)
              </Typography>
            </Box>
            <Typography variant="body2" color="secondary.dark">
              2. Define offshore wave conditions by inputting:
            </Typography>
            <Box pl={4}>
              <Typography variant="body2" color="secondary.dark" mt="-0.75rem">
                a. Significant wave height (H<sub>s</sub>)
              </Typography>

              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                b. Peak wave period (T<sub>p</sub>)
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                c. Dominant wave direction (θ<sub>o</sub>) *
              </Typography>
              <Typography variant="body2" color="secondary.dark" mt="0.25rem">
                d. Still water level (η), including storm surge, tide, and the magnitude of sea level rise.
              </Typography>
              <Typography variant="body2" color="secondary.dark" ml="1rem">
                Note that still water levels above the reference datum should be positive.
              </Typography>
            </Box>
            <Typography variant="body2" color="secondary.dark">
              3. Hit 'Run Wave Model' when ready.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              4. Check outputs of the bed profile and wave conditions to make sure they represent the desired
              conditions.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              5. Query results by hovering over the resulting profiles.
            </Typography>
            <Typography variant="body2" color="secondary.dark">
              6. Export and download results in CSV format by hitting 'Download'
            </Typography>
            <Typography variant="body2" color="secondary.dark" mt="1rem">
              * Note that wave direction is defined with a nautical convention, meaning the direction waves are coming
              from is measured clockwise from North.
            </Typography>
          </Box>
          <Box
            component="img"
            src="images/Direction_Diagram.png"
            alt="DirectionDiagram"
            sx={{ height: "20rem", width: "25rem", ml: "1rem" }}
          />
          <Box
            component="img"
            src="images/Input_Definition.png"
            alt="DirectionDiagram"
            sx={{ height: "25rem", width: "45rem", ml: "1rem", mt: "1rem" }}
          />
        </Box>
      </Fade>
    </Modal>
  );
};
