import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ZoomToBayOfFundySliceState {
  value: boolean;
}

const initialState: ZoomToBayOfFundySliceState = {
  value: false,
};

export const ZoomToBayOFFundySlice = createSlice({
  name: "bayoffundy",
  initialState,
  reducers: {
    ZoomToBayOFFundy: (
      state,
      action: PayloadAction<ZoomToBayOfFundySliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ZoomToBayOFFundySlice.reducer;

export const { ZoomToBayOFFundy } = ZoomToBayOFFundySlice.actions;
