import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ClearButtonVisibilityState {
  value: boolean;
}

const initialState: ClearButtonVisibilityState = {
  value: false,
};

export const ClearButtonVisibilitySlice = createSlice({
  name: "clearButtonVisibility",
  initialState,
  reducers: {
    changeClearButtonVisibilityState: (
      state,
      action: PayloadAction<ClearButtonVisibilityState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ClearButtonVisibilitySlice.reducer;

export const { changeClearButtonVisibilityState } =
  ClearButtonVisibilitySlice.actions;
