import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilePrivacySliceState {
  value: string;
}

const initialState: FilePrivacySliceState = {
  value: "Public",
};

export const DataRepoFilePrivacySlice = createSlice({
  name: "Privacy",
  initialState,
  reducers: {
    changeFilePrivacy: (
      state,
      action: PayloadAction<FilePrivacySliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFilePrivacySlice.reducer;

export const { changeFilePrivacy } = DataRepoFilePrivacySlice.actions;
