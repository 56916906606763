import { createSlice } from "@reduxjs/toolkit";

export const BathymetryToolboxSlice = createSlice({
  name: "datarepouploadStatus",
  initialState: {
    isBathymetryUploadCompleted: false,
    fileBathymetryUID: "",
    isBathymetryUploadPressed: false,
    jobId: "",
    generateStatisticsClicked: false,
  },
  reducers: {
    setBathymetryToolboxUploadCompleted: (state, action) => {
      state.isBathymetryUploadCompleted = action.payload;
    },
    setBathymetryToolboxFileUID: (state, action) => {
      state.fileBathymetryUID = action.payload;
    },
    setBathymetryToolboxUploadPressed: (state, action) => {
      state.isBathymetryUploadPressed = action.payload;
    },
    setBathymetryJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setBathymetryGenerateStatisticsClicked: (state, action) => {
      state.generateStatisticsClicked = action.payload;
    },
  },
});

export const {
  setBathymetryToolboxUploadCompleted,
  setBathymetryToolboxFileUID,
  setBathymetryToolboxUploadPressed,
  setBathymetryJobId,
  setBathymetryGenerateStatisticsClicked,
} = BathymetryToolboxSlice.actions;
export default BathymetryToolboxSlice.reducer;
