import { Box } from "@mui/material";
import { DataDescriptionLeft } from "./DataDescriptionLeft";
import { DataDescriptionRight } from "./DataDescriptionRight";
import { Dispatch, SetStateAction } from "react";

interface validationProps {
  setLeftValidate: Dispatch<SetStateAction<() => boolean>>;
  setRightValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
}

export const DataDescription = ({
  setLeftValidate,
  setRightValidate,
  nextClicked,
  setNextClicked,
}: validationProps) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: "1fr ",
        sm: "1fr",
        md: "1fr",
        lg: "1fr 1fr",
      }}
      height="16rem"
      overflow="auto"
    >
      <DataDescriptionLeft setLeftValidate={setLeftValidate} />
      <DataDescriptionRight
        setRightValidate={setRightValidate}
        nextClicked={nextClicked}
        setNextClicked={setNextClicked}
      />
    </Box>
  );
};
