import { Box, Typography, IconButton, Button, Link, Divider } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { getGisVectorData, getRawData } from "../../../api/backend_public";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import GeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";
import { Geometry, Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import WebGLPointsLayer from "ol/layer/WebGLPoints.js";
import OverlayPopup from "ol-ext/overlay/Popup";
import Select from "ol/interaction/Select";
import { singleClick } from "ol/events/condition";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { deleteBathymetryData } from "../../../api/backend";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface ResultsProps {
  interpolatedFiles: any[];
  setInterpolatedFiles: Dispatch<SetStateAction<any[]>>;
}

export const BathymetryResults = ({ interpolatedFiles, setInterpolatedFiles }: ResultsProps) => {
  const mapInstance = useContext(OpenlayersContext);
  const [visibility, setVisibility] = useState(false);

  const fetchURL = async (fileId: string) => {
    const url = await getRawData(fileId);
    return url.data;
  };

  const handleDownloadClick = async (fileId: string) => {
    const url = await fetchURL(fileId);
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const createGisVectorLayer = async (childId: string) => {
    const gisData = await getGisVectorData(childId);
    const features = new GeoJSON().readFeatures(gisData);

    if (features.length === 0) {
      alert("Check your files/grid resolution value.");
      return;
    }

    const validFeatures = features.map((feature) => feature as Feature<Geometry>);

    const vectorSource = new VectorSource({
      features: validFeatures,
    });

    const webGLLayer = new WebGLPointsLayer({
      source: vectorSource,
      style: {
        "circle-radius": 5,
        "circle-fill-color": [
          "interpolate",
          ["linear"],
          ["get", "Value1"],
          -500,
          "rgb(63, 47, 129)",
          -470,
          "rgb(44,76,142)",
          -430.5,
          "rgb(33,117,150)",
          -390,
          "rgb(64,152,136)",
          -337.7,
          "rgb(131,184,132)",
          -290,
          "rgb(171, 200, 124)",
          -245,
          "rgb(218, 215, 152)",
          -200,
          "rgb(252, 248, 200)",
          -152.3,
          "rgb(252, 196, 140)",
          -100,
          "rgb(254, 149, 84)",
          -59.5,
          "rgb(209, 83, 41)",
          -20,
          "rgb(178, 24, 43)",
          10,
          "rgb(178, 24, 43)",
        ],
      },
    });

    const select = new Select({
      layers: [webGLLayer],
      condition: singleClick,
    });
    mapInstance?.current?.addInteraction(select);

    const popup = new OverlayPopup({
      popupClass: "default",
      closeBox: true,
      positioning: "auto",
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    mapInstance?.current?.addOverlay(popup);

    select.on("select", (evt) => {
      const selectedFeature = evt.selected[0];
      if (selectedFeature) {
        const geometry = selectedFeature.getGeometry();
        if (geometry instanceof Point) {
          const coordinate = geometry.getCoordinates();
          const zValue = selectedFeature.get("Value1");
          popup.show(coordinate, `<div style="color: black;">Z Value: ${zValue}</div>`);
        }
      } else {
        popup.hide();
      }
    });

    return webGLLayer;
  };

  const addGisLayerToMap = async (dataset: string) => {
    const newGisLayer = await createGisVectorLayer(dataset);
    if (newGisLayer) {
      newGisLayer.set("resultId", "resultGisLayer");
      mapInstance?.current?.addLayer(newGisLayer);
    }
  };

  const removeLayer = () => {
    const layerToRemove = mapInstance?.current
      ?.getLayers()
      .getArray()
      .find((layer) => layer.get("resultId") === "resultGisLayer");

    if (layerToRemove) {
      mapInstance?.current?.removeLayer(layerToRemove);
    }
  };

  const handleFileVisibility = () => {
    if (interpolatedFiles.length > 0 && !visibility) {
      const gisVectorFile = interpolatedFiles.find((file: any) => !file.name.includes(".csv"));
      addGisLayerToMap(gisVectorFile?.id);
    } else {
      removeLayer();
    }
    setVisibility(!visibility);
  };

  const handleOnDeleteClick = () => {
    if (interpolatedFiles.length > 0) {
      interpolatedFiles.forEach((file: any) => {
        deleteBathymetryData(file.id);
      });
    }
    removeLayer();
    setVisibility(false);
    setInterpolatedFiles([]);
  };

  return (
    <Box m="0rem 1rem">
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt="1rem">
        <Box display="flex" flexDirection="row">
          <Typography color="secondary.dark" variant="h4" fontWeight="700">
            Results
          </Typography>
          <LightTooltip
            title={
              <>
                <Typography variant="body2" color="secondary.dark" mb="0.5rem">
                  Results may take several minutes to process. The interpolated bathymetry will be displayed upon
                  completion. The colour range is based on that set in the 'bathymetry' ribbon, which will display data
                  as 'red' in the nearshore. Hover over the data to display values or download the data to explore
                  further
                </Typography>
              </>
            }
            placement="right"
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton disableRipple sx={{ color: "secondary.dark", mt: "-0.75rem" }}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </LightTooltip>
        </Box>
        <Button
          variant="outlined"
          size="small"
          sx={{ mt: "-0.25rem" }}
          disabled={interpolatedFiles.length === 0}
          onClick={handleOnDeleteClick}
        >
          <Box display="flex" flexDirection="row" gap="0.5rem">
            <DeleteOutlineOutlinedIcon />
            <Typography variant="body2" fontWeight="700" mt="0.2rem">
              Delete
            </Typography>
          </Box>
        </Button>
      </Box>
      {interpolatedFiles.length > 0 &&
        interpolatedFiles.map((file: any) => {
          return (
            <Box key={file.id}>
              {file.name.includes(".csv") && (
                <Box
                  border="2px solid #CFDBE2"
                  maxWidth="100%"
                  boxShadow="0px 0px 2px 0px gray"
                  borderRadius="0.5rem"
                  ml="0.5rem"
                  mt="1rem"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: "1rem" }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <IconButton
                        disableRipple
                        sx={{ color: "secondary.dark", mt: "-1rem", "&:hover": { cursor: "default" } }}
                      >
                        <FileCopyOutlinedIcon />
                      </IconButton>
                      <Typography variant="body1" color="secondary.dark">
                        {file.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", mr: "0.5rem", gap: "0.5rem" }}>
                      <IconButton
                        disableRipple
                        sx={{ color: "secondary.dark", mt: "-1rem" }}
                        onClick={handleFileVisibility}
                      >
                        {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                      <Link
                        onClick={() => handleDownloadClick(file.id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#09334B", textDecoration: "none" }}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <Box
                          sx={{
                            p: "0.25rem",
                            borderRadius: "0.5rem",
                            mt: "-0.5rem",
                          }}
                        >
                          <GetAppOutlinedIcon />
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};
