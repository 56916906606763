import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { PolarComponent, TooltipComponent, LegendComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { Box, Typography, SelectChangeEvent } from "@mui/material";
import DropdownSelect from "../../DropDownList/DropDownSelect";

interface directionProps {
  jotTpData: unknown[];
  jotHsData: unknown[];
}

echarts.use([PolarComponent, TooltipComponent, LegendComponent, BarChart]);

const getRandomColor = () => {
  const random255 = () => Math.floor(Math.random() * 256);
  return `rgb(${random255()}, ${random255()}, ${random255()})`;
};

const COLORS = Array(5)
  .fill(null)
  .map(() => getRandomColor());

export const WavesTabWaveRoseChart = ({ jotHsData, jotTpData }: directionProps) => {
  const [data, setData] = useState<any>([]);
  const [roseValue, setRoseValueValue] = useState("Hs [m]");
  useEffect(() => {
    if (roseValue === "Tp [s]") {
      setData(jotTpData);
    } else {
      setData(jotHsData);
    }
  }, [jotHsData, jotTpData, roseValue]);

  const transformedData = data.map((row: any) => ({
    name: roseValue === "Hs [m]" ? row.HS : row.TP,
    value: Object.values(row).slice(1),
  }));

  const options = {
    angleAxis: {
      type: "category",
      data: data.length ? Object.keys(data[0]).slice(1) : [],
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
      z: 10000,
      position: function (point: any, params: any, dom: any, rect: any, size: any) {
        return [point[0], size.viewSize[1]];
      },
    },

    radiusAxis: {
      axisLabel: {
        show: true,
      },
    },
    polar: {
      center: ["50%", "45%"],
      radius: ["0%", "80%"],
    },
    series: transformedData.map((d: any, idx: number) => ({
      type: "bar",
      name: d.name,
      data: d.value,
      coordinateSystem: "polar",
      stack: "stack",
      color: COLORS[idx],
    })),

    legend: {
      show: true,
      top: "90%",
      orient: "horizontal",
      right: 10,
      data: transformedData.map((d: any) => d.name).filter(Boolean),
      type: "scroll",
      pageButtonPosition: "end",
      pageIconColor: "#00000042",
      pageIconInactiveColor: "#aaa",
      pageTextStyle: { color: "#333" },
    },
  };

  const handleRoseChange = (event: SelectChangeEvent) => {
    setRoseValueValue(event.target.value as string);
  };

  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{
        height: "25rem",
        mt: "1rem",
        ml: "1rem",
        width: "23.5rem",
        border: "1px solid #CFDBE2",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        overflow: "visible",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          mb: "1rem",
        }}
      >
        <Typography variant="h4" mt="1.5rem" ml="1rem" color="secondary.dark">
          Rose:
        </Typography>
        <Box width="6rem" mt="0.75rem">
          <DropdownSelect
            label="Rose"
            value={roseValue}
            onChange={handleRoseChange}
            options={[
              { value: "Hs [m]", label: "Hs [m]" },
              { value: "Tp [s]", label: "Tp [s]" },
            ]}
          />
        </Box>
      </Box>

      {data.length > 0 ? (
        <ReactECharts key={roseValue} option={options} echarts={echarts} notMerge={true} />
      ) : (
        <Box m="2rem" mt="7rem">
          <Typography variant="body2" color="secondary.dark" justifyContent="center" textAlign="center">
            No data available.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
