import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxVerticalDatumSliceState {
  value: string;
}

const initialState: ToolboxVerticalDatumSliceState = {
  value: "",
};

export const ToolboxVerticalDatumSlice = createSlice({
  name: "verticalDatum",
  initialState,
  reducers: {
    changeToolboxVerticalDatum: (
      state,
      action: PayloadAction<ToolboxVerticalDatumSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxVerticalDatumSlice.reducer;

export const { changeToolboxVerticalDatum } = ToolboxVerticalDatumSlice.actions;
