import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
  Button,
} from "@mui/material";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

interface FilesDetailsState {
  file: File;
  name: string;
  user: string;
  datatype: string;
  description: string;
  privacycontrol: string;
  dataformat: string;
  surveydate: Date | null;
  surveryDescription: string;
  datum: string;
  CRS: string;
}

export const DeleteAllModal = ({
  open,
  handleClose,
  handleDelete,
}: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "18rem",
            width: "30rem",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="0.5rem"
          >
            <IconButton disableRipple sx={{ color: "#fdd128" }}>
              <ErrorOutlineSharpIcon sx={{ fontSize: "6rem" }} />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h1" color="secondary.dark" textAlign="center">
              Are you sure?
            </Typography>
            <Typography
              variant="h4"
              color="secondary.dark"
              textAlign="center"
              fontWeight="400"
              mt="0.5rem"
            >
              Are you sure to delete the selected files
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "1rem",
              mt: "2rem",
              mr: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "5rem",
                height: "3rem",
                bgcolor: "#D4D4D4",
                color: "#1C1C1C",
                "&:hover": {
                  bgcolor: "#B4B4B4",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ width: "5rem", height: "3rem" }}
              onClick={handleDelete}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
