import { styled, Box, Theme, List, Slider } from "@mui/material";

export const ControlWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: "absolute",
  zIndex: 100,
  top: "32px",
  right: "32px",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  flexDirection: "column",
  alignContent: "space-between",
  // '& svg:first-of-type': {
  //   marginBottom: '1px',
  // },
  // '& svg': {
  //   boxShadow: '4px 0px 4px rgb(0 0 0 / 16%)',
  //   background: theme.palette.common.white,
  //   borderRadius: '4px',
  //   display: 'block',
  //   cursor: 'pointer',
  //   fontSize: '20px',
  //   width: '28px',
  //   height: '32px',
  // },
  // '& svg path': {
  //   fill: theme.palette.secondary.main,
  // },
  // '& svg:hover': {
  //   background: theme.palette.grey[300],
  // },
}));

export const LegendContainer = styled(List)(() => ({
  padding: "5px 25px 25px 25px",
  position: "absolute",
  bottom: "32px",
  right: "32px",
  width: "calc(100% - 60px)",
  maxWidth: "700px",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
}));

export const LegendList = styled(List)(() => ({
  display: "flex",
  listStyle: "none",
  padding: "15px 0 0 0 ",
  margin: 0,
  width: "100%",
  color: "#09334B",
  fontWeight: "300",
  fontSize: "12px",
  //flexDirection: 'row-reverse',
  "& > li": {
    width: "20px",
    height: "25px",
    lineHeight: "15px",
    display: "flex",
    flex: "auto",
    borderTop: "1px solid  #09334B",
    borderBottom: "1px solid #09334B",
    justifyContent: "space-around",
    position: "relative",
  },
  "& > li div.start": {
    position: "absolute",
    left: "-8px",
  },
  "& > li div.end": {
    position: "absolute",
    right: "-8px",
  },
  "& > li > div": {
    position: "absolute",
    bottom: "-19px",
    right: "-8px",
  },
  "& > li:not(:first-of-type):not(:last-of-type):after": {
    content: `""`,
    width: "-webkit-fill-available",
    borderLeft: "1px solid #09334B",
    height: "8px",
    bottom: 0,
    position: "absolute",
  },
  "& > li:first-of-type": {
    borderLeft: "1px solid  #09334B",
  },
  "& > li:last-of-type": {
    justifyContent: "flex-center",
    width: "auto",
    paddingLeft: "2px",
    border: 0,
    borderLeft: "1px solid  #09334B",
    fontWeight: "500",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
  },
}));

export const MuiSlider = styled(Slider)(() => ({
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: "translateX(0%)",
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: "translateX(-100%)",
  },
}));
