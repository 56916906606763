import { useState, useEffect, useMemo, useCallback } from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getTimeSeriesGisValues } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";

interface windTSProps {
  port: any;
  dataSourceValue: string;
  rawTs: any[];
}

export const WindTimeSeriesChart = ({ port, dataSourceValue, rawTs }: windTSProps) => {
  const EDValue = "WS [m/s]";
  const [endDateError, setEndDateError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [tsData, setTsData] = useState<unknown[]>([]);
  const [availableDates, setAvailableDates] = useState<dayjs.Dayjs[]>([]);

  useEffect(() => {
    if (rawTs && rawTs.length > 0) {
      const dates = rawTs.map((ts) => dayjs(ts[0]));
      setAvailableDates(dates);
      setStartDate(dates[0]);
      setEndDate(dates[dates.length - 1]);
    }
  }, [rawTs]);

  const minDate = useMemo(() => availableDates[0], [availableDates]);
  const maxDate = useMemo(() => availableDates[availableDates.length - 1], [availableDates]);

  useEffect(() => {
    if (port && rawTs && rawTs.length > 0) {
      const mostRecentTimestamp = rawTs[rawTs.length - 1][0];
      const endOfDataset = dayjs(mostRecentTimestamp);
      const startOfLastMonth = endOfDataset.subtract(1, "month").startOf("month");

      setStartDate(startOfLastMonth);
      setEndDate(endOfDataset);

      const formattedStart = startOfLastMonth.format("YYYY-MM-DDTHHmmss");
      const formattedEnd = endOfDataset.format("YYYY-MM-DDTHHmmss");

      (async () => {
        const data = await getTsData(formattedStart, formattedEnd);
        if (data && data.data) {
          setTsData(data.data);
        }
      })();
    } else if (rawTs.length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [port, rawTs]);

  const getTsData = async (to: string, from: string) => {
    if (dataSourceValue === "MSC50") {
      return await getTimeSeriesGisValues(publicDataSets.MSC50Dataset7d.id, port.properties.point + "_WS", to, from);
    }
    if (dataSourceValue === "Weather Stations") {
      const endingSyntax =
        publicDataSets.env === "dev" ? ".csv_WindSpeed_mps" : publicDataSets.env === "prod" ? "_WindSpeed_mps" : "";
      return await getTimeSeriesGisValues(
        publicDataSets.WeatherStationTimeseries10d.id,
        port.properties["Climate ID"] + endingSyntax,
        to,
        from
      );
    }
    if (dataSourceValue === "WIS") {
      return await getTimeSeriesGisValues(
        publicDataSets.WISTimeseries8d.id,
        port.properties["point"] + "_WS",
        to,
        from
      );
    }
  };

  const handleStartDateChange = useCallback(
    (value: dayjs.Dayjs | null) => {
      if (endDate && value && endDate.isBefore(value) && !endDate.isSame(value)) {
        setStartDateError(true);
        setStartDate(null);
        return;
      } else {
        setStartDateError(false);
        setStartDate(value);
        (async () => {
          const rawTS = await getTsData(
            dayjs(value, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHHmmss"),
            dayjs(endDate, "YYYY-MM-DDTHH:mm:ss").endOf("month").format("YYYY-MM-DDTHHmmss")
          );

          setTsData(rawTS.data);
        })();
      }
    },
    [endDate]
  );

  const handleEndDateChange = useCallback(
    (value: dayjs.Dayjs | null) => {
      if (startDate && value && startDate.isAfter(value) && !startDate.isSame(value)) {
        setEndDateError(true);
        setEndDate(null);
        return;
      } else {
        setEndDateError(false);
        setEndDate(value);
        (async () => {
          const rawTS = await getTsData(
            dayjs(startDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHHmmss"),
            dayjs(value, "YYYY-MM-DDTHH:mm:ss").endOf("month").format("YYYY-MM-DDTHHmmss")
          );
          setTsData(rawTS.data);
        })();
      }
    },
    [startDate]
  );

  const computedChartOptions = useMemo(() => {
    const options: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
        formatter: function (params) {
          const dataPoints = params as any;
          if (dataPoints.length > 0) {
            const firstPoint = dataPoints[0];

            const dateStr = dayjs(firstPoint.axisValue).format("YYYY-MM-DD HH:mm:ss");
            const valueStr = firstPoint.data[1];
            return `${dateStr} :  ${valueStr}`;
          }
          return "";
        },
      },
      xAxis: {
        type: "time",
        name: "Date / Time [UTC]",
        nameLocation: "middle",
        nameGap: 60,
        axisLabel: {
          rotate: 45,
          formatter: (value: number) => {
            return dayjs(value).format("YYYY-MM-DD");
          },
          fontSize: 9,
        },
      },
      yAxis: {
        type: "value",
        name: EDValue,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        min: dataSourceValue === "Weather Stations" ? 0 : undefined,
        max: dataSourceValue === "Weather Stations" ? 25 : undefined,
      },
      series: [
        {
          type: "line",
          data: tsData?.map((item: any) => [item[0], item[1]]),
          smooth: true,
          symbol: "none",
        },
      ],
    };
    return options;
  }, [tsData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        bgcolor="#FFFFFF"
        sx={{
          height: endDateError ? "29rem" : "26rem",
          mt: "1rem",
          ml: "1rem",
          mb: "2rem",
          width: "23.5rem",
          border: "1px solid #CFDBE2",
          borderRadius: "8px",
          boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            mb: "1rem",
          }}
        >
          <Typography variant="body1" mt="1rem" ml="1rem" color="secondary.dark" fontWeight="700">
            Timeseries: Wind Speed [m/s]
          </Typography>
        </Box>
        <Box ml="1rem" mt="1rem" mr="1rem" display="flex" flexDirection="row" gap="0.5rem">
          <DatePicker
            views={["month", "year"]}
            slotProps={{
              textField: {
                size: "small",
                error: startDateError,
                helperText: startDateError ? "Start date should be before the end date." : "",
              },
            }}
            sx={{
              width: "14rem",
              "& .MuiInputLabel-root": {
                color: "#86A2B3",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#86A2B3",
                },
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
              "& .MuiSvgIcon-root": {
                color: "#09334B",
              },
            }}
            value={startDate}
            onAccept={handleStartDateChange}
            label="Start [mm/yyyy]"
            minDate={minDate}
            maxDate={maxDate}
          />

          <DatePicker
            views={["month", "year"]}
            slotProps={{
              textField: {
                size: "small",
                error: endDateError,
                helperText: endDateError ? "End date should be after the start date." : "",
              },
            }}
            sx={{
              width: "13.5rem",
              "& .MuiInputLabel-root": {
                color: "#86A2B3",
              },

              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#86A2B3",
                },
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#86A2B3",
              },
              "& .MuiSvgIcon-root": {
                color: "#09334B",
              },
            }}
            value={endDate}
            onAccept={handleEndDateChange}
            label="End [mm/yyyy]"
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>

        {tsData && tsData.length > 0 && rawTs && rawTs.length > 0 ? (
          <ReactECharts option={computedChartOptions} />
        ) : port && rawTs?.length > 0 ? (
          <Typography variant="body1" align="center" mt={3} color={"GrayText"}>
            No data available for the selected date range.
          </Typography>
        ) : (
          <Box m="2rem" mt="7rem">
            <Typography variant="body2" color="secondary.dark" justifyContent="center" textAlign="center">
              No data available.
            </Typography>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};
