import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileValueState {
  filename: string;
  rights: string;
  id: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
}

interface dataProps {
  datasetType: string;
  id: string;
  name: string;
  projectId: string;
  relativePath: string;
}

interface ExtendedDataProps extends dataProps {
  file: File;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  rights: string;
}

// Define the state to accept either FileValueState or ExtendedDataProps
type StateType = FileValueState | ExtendedDataProps;

const initialState: StateType = {
  filename: "",
  rights: "",
  id: "",
  fileDescription: "",
  fileDataFormat: "",
  fileCRS: "",
  fileSurveyDate: null,
  fileXCoordinate: null,
  fileYCoordinate: null,
  fileTimeZone: "",
  fileSurfaceElevation: null,
  fileDataType: "",
  fileDatum: "",
};

export const DataRepoFileValueSlice = createSlice({
  name: "DataRepoFileValue",
  initialState,
  reducers: {
    changeFileValue: (state, action: PayloadAction<StateType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default DataRepoFileValueSlice.reducer;

export const { changeFileValue } = DataRepoFileValueSlice.actions;
