export const Directions = [
  "N",
  "NNE",
  "NE",
  "ENE",
  "E",
  "ESE",
  "SE",
  "SSE",
  "S",
  "SSW",
  "SW",
  "WSW",
  "W",
  "WNW",
  "NW",
  "NNW",
];

export const AEP = ["100.00%", "50.00%", "20.00%", "10.00%", "5.00%", "2.00%", "1.00%", "0.50%"];
export const AEP_SHORT = ["1", "0.5", "0.2", "0.1", "0.05", "0.02", "0.01", "0.005"];
