import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileDescriptionSliceState {
  value: string;
}

const initialState: FileDescriptionSliceState = {
  value: "",
};

export const DataRepoFileDescriptionSlice = createSlice({
  name: "Description",
  initialState,
  reducers: {
    changeFileDescription: (
      state,
      action: PayloadAction<FileDescriptionSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileDescriptionSlice.reducer;

export const { changeFileDescription } = DataRepoFileDescriptionSlice.actions;
