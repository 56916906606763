import { ToolboxMainPage } from "../ToolboxMainPage";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeToolboxState } from "../../../features/ToolboxStateSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ExpandMore, GetAppOutlined } from "@mui/icons-material";
import { WaveProfileBeachProfile } from "./WaveProfileBeachProfile";
import { getRawData } from "../../../api/backend_public";
import { parse } from "papaparse";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { RootState } from "../../../store/store";
import { platform } from "../../../api/init";
import { setWaveProfileGenerateStatisticsClicked } from "../../../features/ToolboxFeatures/WaveProfileToolboxSlice";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import { ToolboxInfoWaveProfileInfoModal } from "./ToolboxWaveProfileInfoModal";

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

interface searchParamsProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

export const ToolBoxWaveProfile = ({ searchParams, setSearchParams }: searchParamsProps) => {
  const dispatch = useDispatch();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [datasetId, setDatasetId] = useState("");
  const [open, setOpen] = useState(true);
  const [options, setOptions] = useState({});
  const [dissipationOptions, setDissipationOptions] = useState({});
  const [setupOptions, setSetupOptions] = useState({});
  const [waveHeightOptions, setWaveHeightOptions] = useState({});
  const [dirOptions, setDirOptions] = useState({});
  const [energyOptions, setEnergyOptions] = useState({});
  const [clicked, setClicked] = useState(false);
  const [offshoreDepth, setOffshoreDepth] = useState<any>({ value: -20, error: false });
  const [slopeSteepness, setSlopeSteepness] = useState<any>({ value: 2, error: false });
  const [bedDisturbanceHeight, setBedDisturbanceHeight] = useState<any>({ value: 0, error: false });
  const [offshoreDistance, setOffShoreDistance] = useState<any>({ value: -2000, error: false });
  const [distOfBedDisturbance, setDistOfBedDisturbance] = useState<any>({ value: -300, error: false });
  const [beachOrientation, setBeachOrientation] = useState<any>({ value: 0, error: false });
  const [significantWaveHeight, setSignificantWaveHeight] = useState<any>({ value: 5, error: false });
  const [dominantWaveDirection, setDominantWaveDirection] = useState<any>({ value: 45, error: false });
  const [peakWavePeriod, setPeakWavePeriod] = useState<any>({ value: 2, error: false });
  const [stillWaterLevel, setStillWaterLevel] = useState<any>({ value: 0, error: false });
  const jobId = useSelector((state: RootState) => state.waveProfileToolbox.jobId);
  const [url, setUrl] = useState("");

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setWaveProfileGenerateStatisticsClicked(false));
    }
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };
  const handleInfoModalClose = () => setInfoModalOpen(false);

  const getDatasetValues = async (datasetId: string) => {
    setOptions({});
    setWaveHeightOptions({});
    setDirOptions({});
    setSetupOptions({});
    setEnergyOptions({});
    setDissipationOptions({});
    const response = await getRawData(datasetId);
    const csvUrl = response.data;
    setUrl(csvUrl);
    const csvResponse = await fetch(csvUrl);
    const csvText = await csvResponse.text();

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesDataZb = data.map((item: any) => [item.x, item.zb]);
        const seriesDataWL = data.map((item: any) => [item.x, item.WL]);
        const allYValues = data.flatMap((item: any) => [item.zb, item.WL]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Profile",
            left: "center",
          },
          legend: {
            data: ["zb", "WL"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "z_b, η (m)",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "zb",
              data: seriesDataZb,
              type: "line",
              color: "#8B4513",
              smooth: true,
              lineStyle: {
                width: 1,
              },
            },

            {
              name: "WL",
              data: seriesDataWL,
              type: "line",
              color: "blue",
              smooth: true,
              lineStyle: {
                width: 1,
              },
            },
          ],
        });
      },
    });

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesDataHS = data.map((item: any) => [parseFloat(item.x), parseFloat(item.Hm0)]);
        const allYValues = data.flatMap((item: any) => [item.Hm0]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setWaveHeightOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Wave Height",
            left: "center",
          },
          legend: {
            data: ["H_s"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "H_s (m)",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "H_s",
              data: seriesDataHS,
              type: "line",
              color: "#00008B",
              smooth: true,
              lineStyle: {
                width: 1,
              },
            },
          ],
        });
      },
    });

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesData = data.map((item: any) => [parseFloat(item.x), parseFloat(item.MWD)]);
        const allYValues = data.flatMap((item: any) => [item.MWD]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setDirOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Relative Wave Direction",
            left: "center",
          },
          legend: {
            data: ["Dir"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "Dir",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "Dir",
              data: seriesData,
              type: "line",
              color: "#DC143C",
              smooth: true,
            },
          ],
        });
      },
    });

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesData = data.map((item: any) => [parseFloat(item.x), parseFloat(item.WSU)]);
        const allYValues = data.flatMap((item: any) => [item.WSU]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setSetupOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Set Up",
            left: "center",
          },
          legend: {
            data: ["Setup (m)"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "Setup (m)",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "Setup (m)",
              data: seriesData,
              type: "line",
              color: "#008080",
              smooth: true,
            },
          ],
        });
      },
    });

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesDataZb = data.map((item: any) => [item.x, item.E]);
        const seriesDataWL = data.map((item: any) => [item.x, item.Er]);
        const allYValues = data.flatMap((item: any) => [item.E, item.Er]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setEnergyOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Energy",
            left: "center",
          },
          legend: {
            data: ["Wave Energy", "Roller Energy"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "E (j/m^2)",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "Wave Energy",
              data: seriesDataZb,
              type: "line",
              color: "#4B0082",
              smooth: true,
            },

            {
              name: "Roller Energy",
              data: seriesDataWL,
              type: "line",
              color: "#FF4500",
              smooth: true,
            },
          ],
        });
      },
    });

    parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const data = results.data;
        const seriesDataZb = data.map((item: any) => [item.x, item.Dw]);
        const seriesDataWL = data.map((item: any) => [item.x, item.Dr]);

        const allYValues = data.flatMap((item: any) => [item.Dw, item.Dr]).filter((val) => val !== undefined);
        const minYValue = Math.min(...allYValues);
        const maxYValue = Math.max(...allYValues);

        setDissipationOptions({
          tooltip: {
            trigger: "axis",
          },
          title: {
            text: "Dissipation",
            left: "center",
          },
          legend: {
            data: ["Breaking Dissipation", "Roller Dissipation"],
            orient: "horizontal",
            bottom: 10,
            right: 10,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "value",
            name: "Dissipation (j/m^2)",
            nameLocation: "middle",
            nameGap: 40,
            min: minYValue,
            max: maxYValue,
          },

          series: [
            {
              name: "Breaking Dissipation",
              data: seriesDataZb,
              type: "line",
              color: "#4B0082",
              smooth: true,
            },

            {
              name: "Roller Dissipation",
              data: seriesDataWL,
              type: "line",
              color: "#FF4500",
              smooth: true,
            },
          ],
        });
      },
    });
  };

  useEffect(() => {
    if (datasetId !== "") {
      getDatasetValues(datasetId);
    }
  }, [datasetId]);

  return (
    <Box>
      <ToolboxMainPage
        value={
          <Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap="0.5rem">
                <IconButton
                  sx={{
                    color: "secondary.dark",
                    height: "1rem",
                    width: "1rem",
                    mt: "1rem",
                    ml: "1rem",
                  }}
                  disableRipple
                  onClick={() => {
                    dispatch(changeToolboxState({ value: "toolbox" }));
                    searchParams.delete("q");
                    setSearchParams(searchParams);
                    stopInterpolation();
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box mt="0.5rem">
                  <img src="/toolboxes/Wave profile.svg" alt="icon" style={{ width: "3rem", height: "2.5rem" }} />
                </Box>
                <Box mt="0.5rem" ml="-0.25rem">
                  <Typography variant="body1" fontWeight="700" color="secondary.dark">
                    Wave Profile Model
                  </Typography>
                  <Typography variant="caption" color="grey.500">
                    Calculate wave propagation along a bathymetry profile
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                mt="0.5rem"
                mr="1rem"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleInfoModalOpen}
              >
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.85rem">
                  Info
                </Typography>
                <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ height: "calc(100vh - 7rem)", overflow: "auto" }}>
              <ListItemButton onClick={handleClick} sx={{ height: "2.5rem", mt: "0.5rem" }} disableRipple>
                <ListItemText
                  primary={
                    <Typography variant="body2" color="secondary.dark" fontWeight="700">
                      Inputs
                    </Typography>
                  }
                />

                <ExpandMore
                  style={{
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                    color: "#0B4566",
                  }}
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto">
                <List component="div" disablePadding>
                  <WaveProfileBeachProfile
                    setDatasetId={setDatasetId}
                    clicked={clicked}
                    setClicked={setClicked}
                    offshoreDepth={offshoreDepth}
                    setOffshoreDepth={setOffshoreDepth}
                    slopeSteepness={slopeSteepness}
                    setSlopeSteepness={setSlopeSteepness}
                    bedDisturbanceHeight={bedDisturbanceHeight}
                    setBedDisturbanceHeight={setBedDisturbanceHeight}
                    offshoreDistance={offshoreDistance}
                    setOffShoreDistance={setOffShoreDistance}
                    distOfBedDisturbance={distOfBedDisturbance}
                    setDistOfBedDisturbance={setDistOfBedDisturbance}
                    beachOrientation={beachOrientation}
                    setBeachOrientation={setBeachOrientation}
                    significantWaveHeight={significantWaveHeight}
                    setSignificantWaveHeight={setSignificantWaveHeight}
                    dominantWaveDirection={dominantWaveDirection}
                    peakWavePeriod={peakWavePeriod}
                    setDominantWaveDirection={setDominantWaveDirection}
                    setPeakWavePeriod={setPeakWavePeriod}
                    stillWaterLevel={stillWaterLevel}
                    setStillWaterLevel={setStillWaterLevel}
                  />
                </List>
              </Collapse>

              <Divider
                sx={{
                  mt: "0.5rem",
                  borderWidth: "1px",
                  borderColor: "grey.200",
                }}
              />
              <Box display="flex" flexDirection="row" justifyContent="space-between" m="1rem">
                <Box display="flex" flexDirection="row">
                  <Typography color="secondary.dark" variant="h4" fontWeight="700">
                    Results
                  </Typography>
                  <LightTooltip
                    title={
                      <Box m="1rem 0rem">
                        <Typography variant="body2" color="secondary.dark" mb="0.5rem">
                          This model calculates 1D wave profiles based on a given beach profile, and the offshore wave
                          conditions. The model simulates waves propogating through shallow water, and includes
                          processes such as shoaling, refraction, wave breaking, and wave setup by solving the wave
                          action balance equation. The modelling approach is modified from methods outlined in Roelvink
                          (2011).
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          The following assumptions/limitations are made to simplify the model:
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          1. Wave conditions vary slowly in time compared to the time scale of waves travelling through
                          the surf zone
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          2. Inertial effects of currents are negligible
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          3. Wave height variations within a wave group are neglected, applying a time averaged
                          distribution
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          4. Beach profile is alongshore uniform
                        </Typography>
                        <Typography variant="body2" color="secondary.dark" mb="1rem">
                          5. Wind wave growth is neglected
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          Reference
                        </Typography>
                        <Typography variant="body2" color="secondary.dark">
                          Roelvink, D. (2011). A Guide to modeling coastal morphology. Advances in Coastal Engineering:
                          Volume 23. <a href="https://doi.org/10.1142/7712">https://doi.org/10.1142/7712</a>
                        </Typography>
                      </Box>
                    }
                    placement="right"
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <IconButton disableRipple sx={{ color: "secondary.dark", mt: "-0.75rem" }}>
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </Box>
                <Button
                  variant="outlined"
                  disabled={Object.keys(options).length === 0}
                  size="small"
                  sx={{ mt: "-0.25rem" }}
                >
                  <Link
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Box display="flex" flexDirection="row" gap="0.5rem">
                      <GetAppOutlined />
                      <Typography variant="body2" fontWeight="700" mt="0.2rem">
                        Download
                      </Typography>
                    </Box>
                  </Link>
                </Button>
              </Box>
              <Box>
                {Object.keys(options).length > 0 && !clicked && (
                  <>
                    <ReactECharts option={options} style={{ height: 250, border: "1px solid black", margin: "1rem" }} />
                    <ReactECharts
                      option={waveHeightOptions}
                      style={{ height: 200, border: "1px solid black", margin: "1rem" }}
                    />
                    <ReactECharts
                      option={dirOptions}
                      style={{ height: 200, border: "1px solid black", margin: "1rem" }}
                    />

                    <ReactECharts
                      option={setupOptions}
                      style={{ height: 200, border: "1px solid black", margin: "1rem" }}
                    />
                    <ReactECharts
                      option={energyOptions}
                      style={{ height: 200, border: "1px solid black", margin: "1rem" }}
                    />
                    <ReactECharts
                      option={dissipationOptions}
                      style={{ height: 200, border: "1px solid black", margin: "1rem" }}
                    />
                  </>
                )}
              </Box>
            </Box>
            <ToolboxInfoWaveProfileInfoModal open={infoModalOpen} handleClose={handleInfoModalClose} />
          </Box>
        }
      />
    </Box>
  );
};
