import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxZNegativeSliceState {
  value: boolean;
}

const initialState: ToolboxZNegativeSliceState = {
  value: true,
};

export const ToolboxZNegativeSlice = createSlice({
  name: "ZNegative",
  initialState,
  reducers: {
    changeToolboxZNegative: (state, action: PayloadAction<ToolboxZNegativeSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxZNegativeSlice.reducer;

export const { changeToolboxZNegative } = ToolboxZNegativeSlice.actions;
