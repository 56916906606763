import { createSlice } from "@reduxjs/toolkit";

export const ToolboxUploadStatusSlice = createSlice({
  name: "datarepouploadStatus",
  initialState: {
    isUploadCompleted: false,
    fileUID: "",
    isUploadPressed: false,
  },
  reducers: {
    setToolboxUploadCompleted: (state, action) => {
      state.isUploadCompleted = action.payload;
    },
    setToolboxFileUID: (state, action) => {
      state.fileUID = action.payload;
    },
    setToolboxUploadPressed: (state, action) => {
      state.isUploadPressed = action.payload;
    },
  },
});

export const { setToolboxUploadCompleted, setToolboxFileUID, setToolboxUploadPressed } =
  ToolboxUploadStatusSlice.actions;
export default ToolboxUploadStatusSlice.reducer;
