import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { Box, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import { getAllBathymetryDatasets } from "../../../api/backend_public";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Papa from "papaparse";
import { publicDataSets } from "../../../api/init";

echarts.use([TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
  TooltipComponentOption | GridComponentOption | LegendComponentOption | LineSeriesOption
>;

interface ChartProps {
  cisValue: string;
  parsedData: any[];
  setParsedData: Dispatch<SetStateAction<any>>;
}

export const DashboardIceTimeseriesChart = ({ cisValue, parsedData, setParsedData }: ChartProps) => {
  const [option, setOption] = useState<EChartsOption>({});

  const getObservationMethodDescription = (obsMethod: string) => {
    switch (obsMethod) {
      case "0.0":
        return "Visually";
      case "1.0":
        return "Ice auger kit";
      case "2.0":
        return "Hot wire";
      case "3.0":
        return "Other means";
      default:
        return "N/A";
    }
  };

  const getDatasetList = async () => {
    const datasets = await getAllBathymetryDatasets(publicDataSets.IceThicknessLocations12b.id);
    const matchingDatasetUrl = datasets.data.filter((item: any) => item.name === `${cisValue}_ts.csv`)[0].datasetUrl;
    const csvResponse = await fetch(matchingDatasetUrl);
    const csvText = await csvResponse.text();
    const parsedData = Papa.parse(csvText, { header: true }).data;
    setParsedData(parsedData);

    const dataForPlot = parsedData.map((item: any) => ({
      value: [item.date, parseFloat(item.ice_thick)],
      date: item.date,
      ice_thick: item.ice_thick,
      snow_dep: item.snow_dep,
      obs_method: getObservationMethodDescription(item.obs_method),
    }));

    setOption({
      tooltip: {
        trigger: "item",
        formatter: function (params: any) {
          return `
            Date: ${params.data.date}<br/>
            Ice Thickness: ${params.data.ice_thick} cm<br/>
            Snow Depth: ${params.data.snow_dep} cm<br/>
            Observation Method: ${params.data.obs_method}
          `;
        },
      },
      xAxis: {
        type: "time",
        name: "Year",
        nameLocation: "middle",
        nameGap: 30,
      },
      yAxis: {
        type: "value",
        name: "Ice Thickness (cm)",
        nameLocation: "middle",
        nameGap: 30,
      },
      series: [
        {
          name: "Ice Thickness",
          type: "line",
          data: dataForPlot.map((item) => ({
            value: [item.date, item.ice_thick],
            date: item.date,
            ice_thick: item.ice_thick,
            snow_dep: item.snow_dep,
            obs_method: item.obs_method,
          })),
          symbolSize: 10,
        },
      ],
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const datasets = getDatasetList();
    };
    if (cisValue !== "") {
      fetchData();
    }
  }, [cisValue]);
  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        height: "21rem",
        m: "1rem",
      }}
    >
      <Box m="1rem" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.75rem">
            Measured Ice Thickness Timeseries
          </Typography>
          <LightTooltip
            title={
              <Typography variant="body2">
                Measured ice thicknesses originate from two sources: 1 - Before 2002: Original Ice Thickness Program
                1947-2002. Available at:{" "}
                <a href="https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-1947-2002/?lang=en">
                  https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-1947-2002/?lang=en
                </a>
                2 - After 2002: Ice Thickness Program since 2002{" "}
                <a href="https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-2002/?lang=en">
                  https://data-donnees.ec.gc.ca/data/ice/products/ice-thickness-program-collection/ice-thickness-program-collection-2002/?lang=en
                </a>
              </Typography>
            }
            placement={"right"}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton sx={{ "&:hover": { color: "secondary.dark", mt: "0rem" } }} disableRipple>
              <InfoOutlined />
            </IconButton>
          </LightTooltip>
        </Box>
        <Typography variant="caption" color="grey.500" mt="-0.5rem">
          CIS Ice Measurements
        </Typography>
      </Box>
      {cisValue !== "" ? (
        <ReactEcharts
          option={option}
          style={{ height: "100%", width: "100%", marginTop: "-3rem", marginBottom: "1rem" }}
        />
      ) : (
        <Typography
          color="secondary.dark"
          variant="body1"
          display="flex"
          fontWeight="700"
          justifyContent="center"
          mt="14%"
        >
          No Data Available
        </Typography>
      )}
    </Box>
  );
};
