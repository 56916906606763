import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { LegendContainer, MuiSlider } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import { SeaLevelRiseGroupA, SeaLevelRiseGroupB } from "../../Openlayers/default_groups_layers";
import { toolControls } from "../../Openlayers/data_layers";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "./BathymetryLegend";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";

export default function SeaLevelRiseLegend() {
  const toolname = new URLSearchParams(location.search).get("tool");

  const [dataSetLayers, setDataSetLayers] = React.useState<any[] | null>(null);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);
  const [selectedLayerIndex, setSelectedLayerIndex] = React.useState(0);
  const [selectedVariableGroup, setSelectedVariableGroup] = React.useState(
    "total_ssp585_medium_confidence_values_0_5_WMTS"
  );
  const [selectedTimeStepIndex, setSelectedTimeStepIndex] = React.useState(0);
  const [selectedScenarioModelIndex, setSelectedScenarioModelIndex] = React.useState(0);

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    getTileDataSetsMetadata(publicDataSets.SeaLevelRise.cccs).then((response) => {
      setDataSetLayers(response.tilingSchemes);
      setDataSetMetadata(response);
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (selectedScenarioModelIndex === 1 && toolname !== null && toolname === toolControls.SEALEVELRISE) {
      SeaLevelRiseGroupA.setVisible(false);
      SeaLevelRiseGroupB.setVisible(true);
    }
  }, [toolname]);

  const handleChangeModel = (e: any) => {
    if (e.target.value === 0) {
      SeaLevelRiseGroupA.setVisible(true);
      SeaLevelRiseGroupB.setVisible(false);

      getTileDataSetsMetadata(publicDataSets.SeaLevelRise.cccs).then((response) => {
        setDataSetLayers(response.tilingSchemes);
        setDataSetMetadata(response);
      });
    }
    if (e.target.value === 1) {
      SeaLevelRiseGroupA.setVisible(false);
      SeaLevelRiseGroupB.setVisible(true);

      getTileDataSetsMetadata(publicDataSets.SeaLevelRise.nasa).then((response) => {
        setDataSetLayers(response.tilingSchemes);
        setDataSetMetadata(response);
      });

      SeaLevelRiseGroupB.getLayersArray().map((l: any) => {
        if (l.get("name") === selectedVariableGroup) {
          l.setVisible(true);
        }
      });
    }
    setSelectedLayerIndex(0);
    setSelectedTimeStepIndex(0);
    setSelectedScenarioModelIndex(e.target.value);
  };

  const handleChangeGroupB = (e: any) => {
    SeaLevelRiseGroupB.getLayersArray().map((l) => {
      if (l.get("name") === e.target.value) {
        l.setVisible(true);
      } else {
        l.setVisible(false);
      }
    });
    setSelectedTimeStepIndex(0);
    setSelectedVariableGroup(e.target.value);
    SeaLevelRiseGroupB.changed();
  };

  const handleChange = (e: any) => {
    SeaLevelRiseGroupA.getLayersArray().map((l) => {
      if (l.get("id") === e.target.value) {
        l.setVisible(true);
      } else {
        l.setVisible(false);
      }
    });

    const selctedIndex = SeaLevelRiseGroupA.getLayersArray().findIndex((item) => item.get("id") === e.target.value);
    const selectedLayer: any = SeaLevelRiseGroupA.getLayersArray()[selctedIndex];
    setSelectedLayerIndex(selctedIndex);

    selectedLayer.getSource()?.setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
      const href = new URL(src);
      href.searchParams.set("TIMESTEP", dataSetMetadata.timeStamps[selectedTimeStepIndex]);
      const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
      const imageData = await response.blob();
      imageTile.getImage().src = window.URL.createObjectURL(imageData);
    });

    selectedLayer.getSource().updateDimensions({
      TIMESTEP: dataSetMetadata.timeStamps[selectedTimeStepIndex],
    });

    SeaLevelRiseGroupA.changed();
    SeaLevelRiseGroupB.changed();
  };

  const handleRangeChange = (e: any) => {
    let selectedLayer: any;

    if (selectedScenarioModelIndex === 0) {
      selectedLayer = SeaLevelRiseGroupA.getLayersArray()[selectedLayerIndex];
    }
    if (selectedScenarioModelIndex === 1) {
      selectedLayer = SeaLevelRiseGroupB.getLayersArray().filter((i) => i.get("name") === selectedVariableGroup)[0];
    }

    selectedLayer.getSource()?.setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
      const href = new URL(src);
      href.searchParams.set("TIMESTEP", dataSetMetadata.timeStamps[e.target.value]);
      const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
      const imageData = await response.blob();
      imageTile.getImage().src = window.URL.createObjectURL(imageData);
    });

    selectedLayer.getSource().updateDimensions({
      TIMESTEP: dataSetMetadata.timeStamps[e.target.value],
    });

    setSelectedTimeStepIndex(e.target.value);
    SeaLevelRiseGroupA.changed();
    SeaLevelRiseGroupB.changed();
  };

  function valueLabelFormat(value: number) {
    if (!dataSetMetadata) return "";
    return new Date(dataSetMetadata.timeStamps[value]).getFullYear();
  }

  return (
    <>
      {toolname === toolControls.SEALEVELRISE && (
        <>
          <LegendContainer style={{ display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Sea level rise
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="start" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        <b>CCCS AR5:</b> <br />
                        Relative Sea Level Change is based on projections developed by Natural Resources Canada,
                        available at:
                        <a
                          href="https://climatedata.ca/explore/variable/slr/?coords=44.57701840906487,-65.86818695068361,11&geo-select=&rcp=rcp85-p50&decade=2100&rightrcp=disabled"
                          target="_blank"
                        >
                          Sea-Level Change — Climate Data Canada
                        </a>
                        . Projections are based on the IPCC 5th Assessment Report (AR5) and regional vertical land
                        changes, relative to 1986-2005 conditions. Please refer to this source for terms and conditions,
                        definitions, and further dataset details.
                        <br />
                        <br />
                        <b>NASA AR6:</b>
                        <br />
                        Relative Sea Level Change is based on projections by the IPCC 6th Assessment Report (AR6), as
                        visualized and reported by the United States National Space Agency (NASA), available at:
                        <a href="https://sealevel.nasa.gov/" target="_blank">
                          NASA Sea Level Change Portal
                        </a>
                        . Please refer to this source for terms and conditions, definitions, and further dataset
                        details. Projections are provided relative to a 1995-2014 baseline. Please refer to this source
                        for terms and conditions, definitions, and further dataset details.
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>
              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <DropdownSelect
                  label="Model"
                  value={selectedScenarioModelIndex.toString()}
                  onChange={handleChangeModel}
                  options={[
                    {
                      label: "CCCS AR5",
                      value: 0,
                    },
                    {
                      label: "NASA AR6",
                      value: 1,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                {selectedScenarioModelIndex === 0 ? (
                  <DropdownSelect
                    label="Variable"
                    value={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
                    onChange={handleChange}
                    options={
                      dataSetLayers
                        ? dataSetLayers.map((l: any) => {
                            return { label: l.schemeId, value: l.schemeId };
                          })
                        : []
                    }
                  />
                ) : (
                  <DropdownSelect
                    label="Variable"
                    value={selectedVariableGroup}
                    onChange={handleChangeGroupB}
                    options={[
                      {
                        label: "SSP2-4.5 (5th Percentile)",
                        value: "total_ssp245_medium_confidence_values_0_05_WMTS",
                      },
                      {
                        label: "SSP2-4.5 (10th Percentile)",
                        value: "total_ssp245_medium_confidence_values_0_1_WMTS",
                      },
                      {
                        label: "SSP2-4.5 (50th Percentile)",
                        value: "total_ssp245_medium_confidence_values_0_5_WMTS",
                      },
                      {
                        label: "SSP2-4.5 (90th Percentile)",
                        value: "total_ssp245_medium_confidence_values_0_9_WMTS",
                      },
                      {
                        label: "SSP2-4.5 (95th Percentile)",
                        value: "total_ssp245_medium_confidence_values_0_95_WMTS",
                      },
                      {
                        label: "SSP5-8.5 (5th Percentile)",
                        value: "total_ssp585_medium_confidence_values_0_05_WMTS",
                      },
                      {
                        label: "SSP5-8.5 (10th Percentile)",
                        value: "total_ssp585_medium_confidence_values_0_1_WMTS",
                      },
                      {
                        label: "SSP5-8.5 (50th Percentile)",
                        value: "total_ssp585_medium_confidence_values_0_5_WMTS",
                      },
                      {
                        label: "SSP5-8.5 (90th Percentile)",
                        value: "total_ssp585_medium_confidence_values_0_9_WMTS",
                      },
                      {
                        label: "SSP5-8.5 (95th Percentile)",
                        value: "total_ssp585_medium_confidence_values_0_95_WMTS",
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <MuiSlider
                  value={selectedTimeStepIndex ? selectedTimeStepIndex : 0}
                  step={1}
                  size="medium"
                  valueLabelFormat={valueLabelFormat}
                  valueLabelDisplay="auto"
                  onChange={handleRangeChange}
                  marks={[
                    {
                      value: 0,
                      label: dataSetMetadata ? new Date(dataSetMetadata.timeStamps[0]).getFullYear() : "",
                    },
                    {
                      value: dataSetMetadata ? dataSetMetadata.timeStamps.length - 1 : 0,
                      label: dataSetMetadata
                        ? new Date(dataSetMetadata.timeStamps[dataSetMetadata.timeStamps.length - 1]).getFullYear()
                        : "",
                    },
                  ]}
                  min={0}
                  max={dataSetMetadata ? dataSetMetadata.timeStamps.length - 1 : 0}
                />
              </Grid>
            </Grid>

            <ScaleBar
              tilingScheme={dataSetMetadata?.tilingSchemes[selectedLayerIndex]}
              schemeId={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
            />
          </LegendContainer>
          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
}
