import { Box, Divider, Stack, Typography, IconButton, Button } from "@mui/material";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import Papa from "papaparse";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";
import {
  getAllBathymetryDatasets,
  getGisVectorData,
  getRawData,
  getTimeSeriesGisQueryFeatures,
  getTimeSeriesGisRaw,
  getTimeSeriesMDData,
} from "../../api/backend_public";
import { useEffect, useState } from "react";
import JSZip from "jszip";
import { publicDataSets } from "../../api/init";
import { Feature } from "ol";

interface portProps {
  port: Feature;
}

interface TitleProp {
  title: string;
  caption: string;
  tooltipTitle: string;
  value?: string;
  unit: string;
}

export const InfoTooltip = ({ title, caption, tooltipTitle, value, unit }: TitleProp) => {
  return (
    <Box
      height="3rem"
      sx={{
        display: "grid",
        gridTemplateColumns: "3fr 1fr",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Stack direction="column" m="0.5rem 1rem">
          <Typography variant="body2" color="secondary">
            {title}
          </Typography>
          <Typography variant="caption" color="grey.500">
            {caption}
          </Typography>
        </Stack>
        <Box ml="-1rem">
          <LightTooltip title={tooltipTitle} placement="right" enterDelay={500} leaveDelay={200}>
            <IconButton
              disableRipple
              sx={{
                "&:hover": {
                  color: "#09334B",
                },
              }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </LightTooltip>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap="1rem" mr="1rem" mt="1rem">
        <Box
          sx={{
            border: "1px solid #d3d3d3",
            bgcolor: "#ededed",
            width: "4rem",
            mb: "0.5rem",
            borderRadius: "0.25rem",
            height: "1.5rem",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" color="secondary.dark">
            {value}
          </Typography>
        </Box>
        <Typography variant="caption" color="secondary.dark">
          {unit}
        </Typography>
      </Box>
    </Box>
  );
};

export const DashboardOverviewTab = ({ port }: portProps) => {
  const [hhwlt, sethhwlt] = useState<any>();
  const [sealeveldata, setSealeveldata] = useState<any>();
  const [hsValue, setHsValue] = useState<any>();
  const [HHWLTValue, setHHWLTValue] = useState<any>();
  const [harbourData, setHarbourData] = useState<any>({});
  const [isHarbourDataLoading, setIsHarbourDataLoading] = useState(true);

  const downloadData = [
    {
      Variable: "Higher High Water Large Tide (HHWLT)",
      Value: Number(HHWLTValue)?.toFixed(2),
      Unit: `m, CGVD2013`,
    },
    {
      Variable: "Sea Level Rise (RCP 8.5 - 2100)",
      Value: Number(sealeveldata)?.toFixed(2),
      Unit: "cm",
    },

    {
      Variable: "Wind Speed (AEP 1.0%)",
      Value: Number(hsValue).toFixed(2),
      Unit: "m/s",
    },
    {
      Variable: `Significant Wave Height, Hs (AEP 1.0%)`,
      Value: Number(harbourData?.Hs_100yr).toFixed(2),
      Unit: "m",
    },
    {
      Variable: `Associated Peak Wave Period, Tp (AEP 1.0%)`,
      Value: Number(harbourData?.Tp_100yr).toFixed(2),
      Unit: "s",
    },
    {
      Variable: "Dominant Wave Direction (AEP 1.0%)",
      Value: Number(harbourData?.Dr_100yr).toFixed(2),
      Unit: "degrees",
    },
  ];

  const getHHWLTValue = async () => {
    const hhwltData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const hhwltValue = hhwltData.features.filter(
      (item: any) => item.properties.Harbour_nu === port?.get("Harbour_nu")
    )[0]?.properties;
    if (hhwltValue) {
      return hhwltValue;
    } else return 0;
  };

  const getcgvd2013Value = async (hhwlt: any) => {
    if (!hhwlt) return undefined;

    const thselecValue = hhwlt.TH_selec;

    if (thselecValue === 0) {
      return "N/A";
    }
    const result = await getAllBathymetryDatasets(publicDataSets.TidalHeights.id);
    const files = await fetch(result.data[0].datasetUrl);
    const arrayBuffer = await files.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
    const csvFile = Object.keys(zip.files).find((file) => {
      return file.split("/")[1].includes(`${thselecValue.toString().padStart(5, "0")}`);
    });
    if (csvFile) {
      const fileContent = await zip.files[csvFile].async("string");
      const parsedData = Papa.parse(fileContent, { header: true });
      const hhwlt = (parsedData.data.find((row: any) => row.heighttype === "HHWLT") as any)?.wl_CGVD2013;
      return hhwlt;
    } else {
      return "N/A";
    }
  };

  function findHarbourData(csvData: any, harbourNu: number) {
    const rows = csvData.split("\n");
    const headers = rows[0].split(",").map((header: string) => header.trim());
    const result = rows
      .slice(1)
      .map((row: string) => row.split(","))
      .map((values: any) =>
        headers.reduce((object: any, header: any, index: any) => {
          const rawValue = values[index];
          const value = typeof rawValue === "string" ? rawValue.replace(/\r/, "").trim() : rawValue;
          return { ...object, [header]: value };
        }, {})
      )
      .find((row: any) => row.Harbour_nu == harbourNu);

    return result ? { Hs_100yr: result.Hs_100yr, Tp_100yr: result.Tp_100yr, Dr_100yr: result["Dr_100yr"] } : null;
  }

  const getKeyLocationsValue = async () => {
    const response = await getRawData(publicDataSets.DashboardKeyDataset.id);
    const csvUrl = response.data;
    const csvResponse = await fetch(csvUrl);
    const csvData = await csvResponse.text();
    setIsHarbourDataLoading(true);
    const harbourData = findHarbourData(csvData, port?.get("Harbour_nu"));
    setHarbourData(harbourData);
    setIsHarbourDataLoading(false);
  };

  const windSpeed = async (hhwlt: any) => {
    if (!hhwlt) return "N/A";
    if (port) {
      const { features } = await getTimeSeriesGisQueryFeatures(
        publicDataSets.MSC50Dataset7e.id,
        port?.get("MSC_selec"),
        "point"
      );

      if (features) {
        const rawEVA = await getTimeSeriesGisRaw(features[0].properties.eva);
        const { data } = Papa.parse(rawEVA, { header: true });
        const wsValue = (data.find((row: any) => row.Direction === "Omni-Directional" && row.AEP === "1") as any)?.WS;
        return wsValue;
      }
    }
    return "N/A";
  };

  const getSeaLevelValue = async () => {
    const CCCSData = await getTimeSeriesMDData(
      publicDataSets.SeaLevelRise.cccsDataset,
      port.get("Longitude"),
      port.get("Latitude"),
      10,
      [7]
    );
    if (!CCCSData.dataBlocks[0].data.every((element: any) => element === "NaN")) {
      const seaLevelValue = CCCSData.dataBlocks[0].data;
      if (seaLevelValue && seaLevelValue.length > 0) {
        return seaLevelValue[seaLevelValue.length - 1].toString();
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    getKeyLocationsValue();
    (async () => {
      const portProperties = await getHHWLTValue();
      if (!portProperties) return;

      sethhwlt(portProperties);
      const hsvalue = await windSpeed(portProperties);
      if (hsvalue) {
        setHsValue(hsvalue);
      }

      const seaLevelDataResult = await getSeaLevelValue();
      if (seaLevelDataResult) {
        setSealeveldata(seaLevelDataResult);
      }

      const cgvd2013Result = await getcgvd2013Value(portProperties);
      if (cgvd2013Result) {
        setHHWLTValue(cgvd2013Result);
      }
    })();
    return () => {
      sethhwlt(null);
      setHsValue(0);
      setSealeveldata(0);
      setHHWLTValue("");
    };
  }, [port]);

  return (
    <Box>
      <Box sx={{ overflowY: "auto", height: "calc(100vh - 9rem)" }}>
        <Box height="10rem" sx={{ display: "grid", gridTemplateColumns: "1fr 260px" }}>
          <Box>
            <Stack direction="column">
              <Box mt="1rem">
                <Typography variant="body2" sx={{ ml: "1rem", mb: "4px", color: "secondary.dark" }}>
                  <b>Province: </b>{" "}
                  {port?.get("Province") === "NS"
                    ? "Nova Scotia"
                    : port?.get("Province") === "NL"
                    ? "Newfoundland and Labrador"
                    : port?.get("Province") === "PE"
                    ? "Prince Edward Island"
                    : port?.get("Province") === "QC"
                    ? "Quebec"
                    : port?.get("Province") === "BC"
                    ? "British Columbia"
                    : port?.get("Province") === "AB"
                    ? "Alberta"
                    : port?.get("Province") === "NB"
                    ? "New Brunswick"
                    : port?.get("Province") === "ON"
                    ? "Ontario"
                    : port?.get("Province") === "MB"
                    ? "Manitoba"
                    : port?.get("Province") === "SK"
                    ? "Saskatchewan"
                    : port?.get("Province") === "YT"
                    ? "Yukon"
                    : port?.get("Province") === "NT"
                    ? "Northwest Territories"
                    : port?.get("Province") === "NU"
                    ? "Nunavut"
                    : "Unknown"}
                </Typography>
                <Typography variant="body2" sx={{ ml: "1rem", mb: "4px", color: "secondary.dark" }}>
                  <b>Authority: </b> {port?.get("Authority")}
                </Typography>
                <Typography variant="body2" sx={{ ml: "1rem", mb: "4px", color: "secondary.dark" }}>
                  <b>Harbour Code: </b> {port?.get("Harbour_nu")}
                </Typography>
                <Typography variant="body2" sx={{ ml: "1rem", color: "secondary.dark" }}>
                  <b>Lat/Long: </b> {port?.get("Latitude")}&deg; / {port?.get("Longitude")}&deg;
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Box
            border={port?.get("Photo") !== "" ? "" : "1px solid #608da2"}
            borderRadius="16px"
            height="130px"
            mt="0.75rem"
            mr="1rem"
            mb="0.75rem"
          >
            <Box
              component="img"
              sx={{
                height: port?.get("Photo") !== "" ? "130px" : "80px",
                mt: port?.get("Photo") !== "" ? "" : "16px",

                width: "241.5px",
                borderRadius: port?.get("Photo") !== "" ? "16px" : "",
                border: port?.get("Photo") !== "" ? "1px solid #608da2" : "",
              }}
              src={port?.get("Photo") !== "" ? `14b_Key_Locations_Photos/${port?.get("Photo")}` : "logo/CCZIS.svg"}
              alt={port?.get("Harbour_Na")}
            />
            {port?.get("Photo") === "" && (
              <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="caption" color="secondary.dark">
                  No Photo Available
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{
              display: "flex",
              gridTemplateColumns: "1.75fr 0.25fr",
              justifyContent: "space-between",
            }}
          >
            <Box m="0.5rem 1rem">
              <Typography variant="body1" color="secondary.dark" sx={{ fontWeight: 700 }}>
                Summary
              </Typography>
            </Box>

            <Button
              variant="text"
              color="info"
              disabled={hsValue === 0 ? true : false}
              startIcon={
                <IconButton color="primary" disableRipple disabled={hsValue === 0}>
                  <GetAppOutlinedIcon fontSize="medium" />
                </IconButton>
              }
              href={URL.createObjectURL(
                new Blob([Papa.unparse(downloadData)], {
                  type: "text/csv;charset=utf-8;",
                })
              )}
              download={`data.csv`}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                  mr: "0.5rem",
                }}
              >
                Download
              </Typography>
            </Button>
          </Box>

          <InfoTooltip
            title="Higher High Water Large Tide (HHWLT)"
            caption="DFO-CHS Tide Tables"
            tooltipTitle="The average of the highest high waters levels, 1 from each of the 19 years of predictions based on DFO-CHS tide tables"
            value={
              HHWLTValue === undefined || HHWLTValue === ""
                ? "Loading..."
                : HHWLTValue === "N/A"
                ? "---"
                : isNaN(Number(sealeveldata))
                ? "Loading..."
                : Number(HHWLTValue).toFixed(2)
            }
            unit="m, CGVD2013"
          />
          <Divider />
          <InfoTooltip
            title="Sea Level Rise (RCP 8.5 - 2100)"
            caption="CCCS (AR5) - NRCan/ClimateData.ca"
            tooltipTitle="Projected median sea level rise relative to land by 2100 for the RCP8.5 emission scenario based on the SLR Natural Resources Canada dataset based on the IPCC 5th assessment."
            value={
              sealeveldata === 0 || sealeveldata === undefined
                ? "Loading..."
                : sealeveldata === "N/A"
                ? "---"
                : isNaN(Number(sealeveldata))
                ? "Loading..."
                : Number(sealeveldata).toFixed(2)
            }
            unit="cm"
          />
          <Divider />
          <InfoTooltip
            title="Wind Speed (AEP 1.0%)"
            caption="MSC50 Hindcast"
            tooltipTitle="MSC50 hindcast model of 10 m continuous wind speed (omni-directional) with an annual exceedance probability of 1.0%"
            value={
              hsValue === 0 || hsValue === undefined
                ? "Loading..."
                : hsValue === "N/A"
                ? "---"
                : isNaN(Number(hsValue))
                ? "Loading..."
                : Number(hsValue).toFixed(2)
            }
            unit="m/s"
          />
          <Divider />
          <InfoTooltip
            title="Significant Wave Height, Hs (AEP 1.0%)"
            caption="Regional Model"
            tooltipTitle="Spectral approximation of the average height of the highest one-third of the waves with an annual exceedance probability of 1.0%"
            value={
              isHarbourDataLoading
                ? "Loading..."
                : harbourData === null || Object.keys(harbourData ?? {}).length === 0
                ? "---"
                : Number(harbourData?.Hs_100yr).toFixed(2)
            }
            unit="m"
          />
          <Divider />
          <InfoTooltip
            title="Associated Peak Wave Period, Tp (AEP 1.0%)"
            caption="Regional Model"
            tooltipTitle="The wave period associated with the most energetic waves in the total wave spectrum"
            value={
              isHarbourDataLoading
                ? "Loading..."
                : harbourData === null || Object.keys(harbourData ?? {}).length === 0
                ? "---"
                : Number(harbourData?.Tp_100yr).toFixed(2)
            }
            unit="s"
          />
          <Divider />
          <InfoTooltip
            title="Dominant Wave Direction (AEP 1.0%)"
            caption="Regional Model"
            tooltipTitle="Direction of the most energetic waves in the total wave spectrum in degrees clockwise from North (coming from)"
            value={
              isHarbourDataLoading
                ? "Loading..."
                : harbourData === null || Object.keys(harbourData ?? {}).length === 0
                ? "---"
                : Number(harbourData?.Dr_100yr).toFixed(2)
            }
            unit="degrees"
          />
          <Divider />
        </Box>
        <Box m="1rem">
          <Typography color="grey.500" variant="body2" mb="0.75rem">
            Warnings:
          </Typography>
          <Box>
            {hhwlt && hhwlt.TH_selec !== 0 ? (
              <Typography color="grey.500" variant="caption">
                {`The closest tidal height predictions station, ${hhwlt.TH_selec}, (latitude ${hhwlt.TH_lat.toFixed(
                  1
                )}, longitude ${hhwlt.TH_lon.toFixed(1)}), is located
             ${parseFloat(hhwlt.TH_dstkm).toFixed(1)} km from the ${port?.get(
                  "Harbour_Na"
                )}. It is the responsibility of the user to evaluate the applicability of this
            data at this location.`}
              </Typography>
            ) : (
              <Typography color="grey.500" variant="caption">
                No tidal height predictions close to {port?.get("Harbour_Na")} are available.
              </Typography>
            )}
          </Box>
          {hhwlt && hhwlt.MSC_selec !== "" ? (
            <Typography color="grey.500" variant="caption">
              {`The closest MSC50 point, ${hhwlt.MSC_selec}, (latitude ${hhwlt.MSC_lat.toFixed(
                1
              )}, longitude ${hhwlt.MSC_lon.toFixed(1)}), is located  ${parseFloat(hhwlt.MSC_dstkm).toFixed(
                1
              )} km from the ${port?.get(
                "Harbour_Na"
              )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
            </Typography>
          ) : (
            <Typography color="grey.500" variant="caption">
              There are no MSC50 points located within 20 km of {port?.get("Harbour_Na")}.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
