import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  label: string;
  value: string;
  options: { value: number | string; label: string; disabled?: boolean }[];
  onChange: (event: SelectChangeEvent) => void;
  error: boolean;
}

const UploadDropdownSelect = ({ label, value, options, onChange, error }: Props) => {
  return (
    <FormControl
      fullWidth
      size="small"
      error={error}
      sx={{
        "& .MuiInputLabel-root": {
          color: "#86A2B3",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#86A2B3",
            color: "#86A2B3",
          },
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#86A2B3",
          color: "#86A2B3",
        },
      }}
    >
      <InputLabel id={`${label}-drop-down`} style={{ color: "#86A2B3" }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-drop-down`}
        id={`${label}-drop-down-select`}
        value={value}
        label={label}
        onChange={onChange}
        size="small"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UploadDropdownSelect;
