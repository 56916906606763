import { LegendList } from "../../assets/component_styles";

interface ScaleBarProps {
  tilingScheme: any;
  schemeId?: string;
}

function ScaleBar({ tilingScheme, schemeId }: ScaleBarProps) {
  return (
    <LegendList>
      {tilingScheme &&
        tilingScheme.colors.map((l: any, index: any) => {
          const scaleBarAverageStepValue =
            (tilingScheme.maxValue - tilingScheme.minValue) / (tilingScheme.colors.length - 1);

          return (
            <li key={l + index} style={{ background: `#${l}` }}>
              {index === 0 && <div className="start">{tilingScheme.minValue.toFixed(1)}</div>}

              {index !== 0 && index !== tilingScheme.colors.length - 1 && index % 2 === 1 && (
                <div>{(tilingScheme.minValue + scaleBarAverageStepValue * (index + 0.5)).toFixed(1)}</div>
              )}

              {index === tilingScheme.colors.length - 1 && (
                <div className="end">{tilingScheme.maxValue.toFixed(1)}</div>
              )}
            </li>
          );
        })}
      <li>
        {schemeId && ["height", "depth"].some((el) => schemeId.toLowerCase().includes(el)) && <>m</>}
        {schemeId &&
          ["max_thickness", "min_thickness", "rcp", "sea_level_change"].some((el) =>
            schemeId.toLowerCase().includes(el)
          ) && <>cm</>}
        {schemeId && ["max_concentration", "min_concentration"].some((el) => schemeId.toLowerCase().includes(el)) && (
          <>x100%</>
        )}
        {schemeId && ["breakup", "freezeup"].some((el) => schemeId.toLowerCase().includes(el)) && <>Julian days</>}
        {schemeId && ["n_aep_1000"].some((el) => schemeId.toLowerCase().includes(el)) && <>m/s</>}
        {schemeId && ["period"].some((el) => schemeId.toLowerCase().includes(el)) && <>s</>}
        {schemeId && ["direction"].some((el) => schemeId.toLowerCase().includes(el)) && <>deg</>}
        {schemeId && ["tidelevel"].some((el) => schemeId.toLowerCase().includes(el)) && <>m, CGVD2013</>}
      </li>
    </LegendList>
  );
}

export default ScaleBar;
