import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepositoryYCoordinateState {
  value: number | null;
}

const initialState: DataRepositoryYCoordinateState = {
  value: null,
};

export const DataRepositoryYCoordinateSlice = createSlice({
  name: "YCoordinate",
  initialState,
  reducers: {
    changeDataRepositoryYCoordinate: (
      state,
      action: PayloadAction<DataRepositoryYCoordinateState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepositoryYCoordinateSlice.reducer;

export const { changeDataRepositoryYCoordinate } =
  DataRepositoryYCoordinateSlice.actions;
