import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LayerButtonState {
  value: string;
}

const initialState: LayerButtonState = {
  value: "default",
};

export const LayerButtonStateSlice = createSlice({
  name: "LayerButton",
  initialState,
  reducers: {
    changeLayerButtonState: (
      state,
      action: PayloadAction<LayerButtonState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default LayerButtonStateSlice.reducer;

export const { changeLayerButtonState } = LayerButtonStateSlice.actions;
