import App from "./components/App/App";
import init from "./api/init";
import React from "react";
import ReactDOM from "react-dom/client";
import fetchConfig from "./config/setup";
import { ThemeProvider } from "@dhi/react-components-lab";
import { OidcProvider } from "@axa-fr/react-oidc";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";

fetchConfig().then((config) => {
  const oidcConfig = {
    client_id: config.oAuth2.clientName,
    authority: config.oAuth2.url,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: "openid offline_access",
    refresh_time_before_token_expiration_in_second: 120,
    automaticSilentRenew: true,
    service_worker_relative_url: "/OidcServiceWorker.js",
  };

  init(config);

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider mode="light">
          <OidcProvider
            configuration={oidcConfig}
            loadingComponent={() => <></>}
            authenticatingErrorComponent={() => <></>}
            authenticatingComponent={() => <></>}
            callbackSuccessComponent={() => <></>}
          >
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </OidcProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );

  navigator.serviceWorker.getRegistration().then(function (reg) {
    // There's an active SW, but no controller for this tab.
    if (reg?.active && !navigator.serviceWorker.controller) {
      // Perform a soft reload to load everything from the SW and get
      // a consistent set of resources.
      window.location.reload();
    }
  });
});
