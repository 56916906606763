import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileSurveySliceState {
  value: string;
}

const initialState: FileSurveySliceState = {
  value: "",
};

export const DataRepoFileSurveySlice = createSlice({
  name: "SurveyDescription",
  initialState,
  reducers: {
    changeFileSurvey: (state, action: PayloadAction<FileSurveySliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoFileSurveySlice.reducer;

export const { changeFileSurvey } = DataRepoFileSurveySlice.actions;
