import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepoLayerVisibilityState {
  layerVisibility: { [key: string]: boolean };
}
const initialState: DataRepoLayerVisibilityState = {
  layerVisibility: {},
};

const DataRepolayerVisibilitySlice = createSlice({
  name: "layerVisibility",
  initialState,
  reducers: {
    setLayerVisibility: (state, action: PayloadAction<{ childId: string; isVisible: boolean }>) => {
      const { childId, isVisible } = action.payload;
      state.layerVisibility[childId] = isVisible;
    },
    resetLayerVisibilities: (state) => {
      Object.keys(state.layerVisibility).forEach((childId) => {
        state.layerVisibility[childId] = false;
      });
    },
  },
});

export const { setLayerVisibility, resetLayerVisibilities } = DataRepolayerVisibilitySlice.actions;
export default DataRepolayerVisibilitySlice.reducer;
