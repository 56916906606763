import React, { useState } from "react";

// type DataRepoProcessedFile = {
//   file: File;
//   rights: string;
//   id: string;
//   fileDescription: string;
//   fileDataFormat: string;
//   fileCRS: string;
//   fileSurveyDate: string | null;
//   fileXCoordinate: number | null;
//   fileYCoordinate: number | null;
//   fileTimeZone: string;
//   fileSurfaceElevation: number | null;
//   fileDataType: string;
//   fileDatum: string;
// };

// type DataRepoProcessedFilesContextType = {
//   processedFiles: DataRepoProcessedFile[];
//   setProcessedFiles: React.Dispatch<React.SetStateAction<DataRepoProcessedFile[]>>;
// };

type DataRepoProcessedFilesContextType = {
  processedFiles: any[];
  setProcessedFiles: React.Dispatch<React.SetStateAction<any[]>>;
};
export const DataRepoProcessedFilesContext = React.createContext<DataRepoProcessedFilesContextType | undefined>(
  undefined
);

interface DataRepoProcessedFilesProviderProps {
  children: React.ReactNode;
}

export const DataRepoProcessedFilesProvider: React.FC<DataRepoProcessedFilesProviderProps> = ({ children }) => {
  const [processedFiles, setProcessedFiles] = useState<any[]>([]);

  return (
    <DataRepoProcessedFilesContext.Provider value={{ processedFiles, setProcessedFiles }}>
      {children}
    </DataRepoProcessedFilesContext.Provider>
  );
};
