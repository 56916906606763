import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
  Stack,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface Props {
  label: string;
  value: string;
  options: { value: number | string; label: string }[];
  onChange: (event: SelectChangeEvent) => void;
}

export const DropdownSelectDisbaled = ({ label, value, options, onChange }: Props) => {
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        "& .MuiInputLabel-root": {
          color: "#86A2B3",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#86A2B3",
            color: "#86A2B3",
          },
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#86A2B3",
          color: "#86A2B3",
        },
      }}
      disabled
    >
      <InputLabel id={`${label}-drop-down`} style={{ color: "#86A2B3" }}>
        {label}
      </InputLabel>
      <LightTooltip title={"Coming in Phase 3"} placement="right" enterDelay={500} leaveDelay={200}>
        <Select
          labelId={`${label}-drop-down`}
          id={`${label}-drop-down-select`}
          value={value}
          label={label}
          onChange={onChange}
          size="small"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography color="black"> {option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </LightTooltip>
    </FormControl>
  );
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const SettingsModal = ({ open, handleClose }: ModalProps) => {
  const [measurementValue, setMeasurementValue] = useState("Metric");
  const storedCoordinatesValue = localStorage.getItem("coordinatesValue");
  const [coordinatesValue, setCoordinatesValue] = useState(storedCoordinatesValue || "Geographic (lat/long)");
  const [projectionValue, setProjectionValue] = useState("WGS 84");
  const [dataSourceValue, setDataSourceValue] = useState("CGVD2013");
  const handleProjectionChange = (event: SelectChangeEvent) => {
    setProjectionValue(event.target.value as string);
  };
  const handleDataSourceChange = (event: SelectChangeEvent) => {
    setDataSourceValue(event.target.value as string);
  };
  const handleMeasurementsValue = (event: React.ChangeEvent<HTMLInputElement> | null) => {
    setMeasurementValue((event?.target as HTMLInputElement).value);
  };
  const handleCoordinatesValue = (event: React.ChangeEvent<HTMLInputElement> | null) => {
    const newCoordinatesValue = (event?.target as HTMLInputElement).value;
    setCoordinatesValue(newCoordinatesValue);
    localStorage.setItem("coordinatesValue", newCoordinatesValue);
  };
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            height: "15rem",
            width: "44rem",
          }}
        >
          <Box
            m="0.5rem 1rem 0.5rem 1rem"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "space-between",
              gap: "33rem",
            }}
          >
            <Stack direction="row">
              <IconButton disableRipple>
                <SettingsOutlinedIcon sx={{ color: "secondary.dark" }} />
              </IconButton>
              <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.5rem">
                Settings
              </Typography>
            </Stack>
            <Box onClick={handleClose}>
              <IconButton disableRipple>
                <CloseOutlinedIcon sx={{ color: "secondary.dark" }} />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box m="0rem 0rem 0.5rem 1.5rem">
              <Typography variant="body2" fontWeight="700" color="secondary.dark">
                Measurement Units:
              </Typography>
            </Box>

            <Box m="-0.05rem 1rem 0.5rem 0rem">
              <FormControl sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <FormLabel id="measurement-radio-button"></FormLabel>
                <RadioGroup
                  sx={{ mt: "-0.6rem" }}
                  row
                  aria-labelledby="measurement-radio-button"
                  name="measurement"
                  value={measurementValue || null}
                  onChange={handleMeasurementsValue}
                >
                  <FormControlLabel
                    value="Metric"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        variant="body2"
                        color="secondark.dark"
                        sx={{
                          fontWeight: measurementValue === "Metric" ? 700 : 400,
                        }}
                      >
                        Metric
                      </Typography>
                    }
                  />
                  <LightTooltip title="Coming in Phase 3" placement="right-start">
                    <FormControlLabel
                      disabled
                      value="Imperial"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          variant="body2"
                          color="grey.500"
                          sx={{
                            fontWeight: measurementValue === "Imperial" ? 700 : 400,
                          }}
                        >
                          Imperial
                        </Typography>
                      }
                    />
                  </LightTooltip>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box m="0rem 0rem 0.5rem 1.5rem">
              <Typography variant="body2" fontWeight="700" color="secondary.dark">
                Display Projection:
              </Typography>
            </Box>

            <Box m="-0.05rem 1rem 0.5rem 0rem">
              <FormControl sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <FormLabel id="coordinates-radio-button"></FormLabel>
                <RadioGroup
                  sx={{ mt: "-0.6rem" }}
                  row
                  aria-labelledby="coordinates-radio-button"
                  name="coordinates"
                  value={coordinatesValue}
                  onChange={handleCoordinatesValue}
                >
                  <FormControlLabel
                    value="Geographic (lat/long)"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        variant="body2"
                        color="secondark.dark"
                        sx={{
                          fontWeight: coordinatesValue === "Geographic (lat/long)" ? 700 : 400,
                        }}
                      >
                        Geographic (lat/long)
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="UTM (Easting/Northing)"
                    control={<Radio size="small" />}
                    label={
                      <Typography
                        variant="body2"
                        color="secondary.dark"
                        sx={{
                          fontWeight: coordinatesValue === "UTM (Easting/Northing)" ? 700 : 400,
                        }}
                      >
                        UTM (Easting/Northing)
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Stack direction="column" spacing="1rem" m="0rem 1.5rem 0.5rem 1.5rem">
            <DropdownSelectDisbaled
              label="Horizontal Datum"
              value={projectionValue}
              onChange={handleProjectionChange}
              options={[
                { value: "WGS 84", label: "WGS 84" },
                { value: "NAD 83", label: "NAD 83" },
                { value: "NAD 83 (CSRS)", label: "NAD 83 (CSRS)" },
                { value: "NAD 27", label: "NAD 27" },
              ]}
            />
            <DropdownSelectDisbaled
              label="Vertical Datum"
              value={dataSourceValue}
              onChange={handleDataSourceChange}
              options={[
                { value: "CGVD2013", label: "CGVD2013" },
                { value: "CGVD28", label: "CGVD28" },
              ]}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};
