import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { changeHeadDataType } from "../../../features/DataRepoHeadDataTypeSlice";
import { setHeadRights } from "../../../features/DataRepoHeadRightsSlice";
import { changeHeadUsername } from "../../../features/DataRepoHeadUsernameSlice";
import { toolControls } from "../../Openlayers/data_layers";
import { changeToolboxBathymetryRights } from "../../../features/ToolboxBathymetryRightsSlice";
import { Dispatch, SetStateAction } from "react";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface DropDownProps {
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;
  type: string;
  timeseriesDataType: string[];
  setTimeseriesDataType: Dispatch<SetStateAction<string[]>>;
}

export const ModalDropDownFields = ({
  username,
  setUsername,
  type,
  timeseriesDataType,
  setTimeseriesDataType,
}: DropDownProps) => {
  const dispatch = useDispatch();
  const dataType = useSelector((state: RootState) => state.headDataType.value);
  const rights = useSelector((state: RootState) => state.toolboxBathymetryRights.value);

  const options = [
    { value: toolControls.PUBLIC, label: toolControls.PUBLIC },
    { value: toolControls.PRIVATE, label: toolControls.PRIVATE },
    { value: toolControls.GROUP, label: toolControls.GROUP, disabled: true },
  ];
  const timeseriesDataTypeOptions = [
    { value: toolControls.CURRENTSTIMESERIES, label: toolControls.CURRENTSTIMESERIES },
    { value: toolControls.DISCHARGETIMESERIES, label: toolControls.DISCHARGETIMESERIES },
    { value: toolControls.ICETHICKNESSTIMESERIES, label: toolControls.ICETHICKNESSTIMESERIES },
    { value: toolControls.WATERLEVELTIMESEIRES, label: toolControls.WATERLEVELTIMESEIRES },
    { value: toolControls.WAVESTIMESERIES, label: toolControls.WAVESTIMESERIES },
    { value: toolControls.WINDTIMESERIES, label: toolControls.WINDTIMESERIES },
  ];
  const extentOptions = [
    { value: toolControls.ALL, label: toolControls.ALL },
    { value: toolControls.EASTCOAST, label: toolControls.EASTCOAST },
    { value: toolControls.WESTCOAST, label: toolControls.WESTCOAST },
    { value: toolControls.GREATLAKES, label: toolControls.GREATLAKES },
    { value: toolControls.ARCTIC, label: toolControls.ARCTIC },
    { value: toolControls.INTERPOLATIONBOUNDARY, label: toolControls.INTERPOLATIONBOUNDARY },
  ];
  const fileDataTypeOptions = [
    { value: toolControls.BATHYMETRY, label: toolControls.BATHYMETRY },
    {
      value: toolControls.WAVESTIMESERIES,
      label: toolControls.WAVESTIMESERIES,
    },
    { value: toolControls.WINDTIMESERIES, label: toolControls.WINDTIMESERIES },
    {
      value: toolControls.WATERLEVELTIMESEIRES,
      label: toolControls.WATERLEVELTIMESEIRES,
    },
    {
      value: toolControls.CURRENTSTIMESERIES,
      label: toolControls.CURRENTSTIMESERIES,
    },
    {
      value: toolControls.DISCHARGETIMESERIES,
      label: toolControls.DISCHARGETIMESERIES,
    },
    {
      value: toolControls.ICETHICKNESSTIMESERIES,
      label: toolControls.ICETHICKNESSTIMESERIES,
    },
    { value: toolControls.BOREHOLELOGS, label: toolControls.BOREHOLELOGS },
    {
      value: toolControls.MODELRESULTSWAVE,
      label: toolControls.MODELRESULTSWAVE,
    },
    {
      value: toolControls.INFRASTRUCTUREDRAWING,
      label: toolControls.INFRASTRUCTUREDRAWING,
    },
    { value: toolControls.MISCFREEFORMAT, label: toolControls.MISCFREEFORMAT },
    { value: toolControls.MODELGRIDMESH, label: toolControls.MODELGRIDMESH },
    {
      value: toolControls.MODELRESULTSHYDRO,
      label: toolControls.MODELRESULTSHYDRO,
    },
  ];
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "1rem",
        justifyContent: "center",
        m: "2rem 1rem 1rem 1rem",
        maxHeight: "2.5rem",
      }}
    >
      <LightTooltip title="Coming in Phase 3" placement="left" enterDelay={500} leaveDelay={200}>
        <FormControl
          fullWidth
          size="small"
          sx={{
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
                color: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          }}
          disabled
        >
          <InputLabel id={`Data Type-drop-down`} style={{ color: "#86A2B3" }}>
            Extent
          </InputLabel>
          <Select
            labelId={`Data Type-drop-down`}
            id={`Data Type-drop-down-select`}
            value="All"
            label="Data Type"
            // multiple
            // onChange={(e) => dispatch(changeHeadDataType(e.target.value as string[]))}
            size="small"
            // renderValue={(selected) => {
            //   const selectedCount = (selected as string[]).length;
            //   return selectedCount > 1 ? `${selectedCount} selected` : selected.join(", ");
            // }}
          >
            {extentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </LightTooltip>
      {type === "bathymetry" && (
        <TextField
          id="data-type-textfield"
          label="Data Type"
          size="small"
          value={toolControls.BATHYMETRY}
          InputLabelProps={{ style: { color: "#86A2B3" } }}
          sx={{
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
          }}
        />
      )}
      {type === "timeseries" && (
        <FormControl
          fullWidth
          size="small"
          sx={{
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
                color: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          }}
        >
          <InputLabel id={`Data Type-drop-down`} style={{ color: "#86A2B3" }}>
            Data Type
          </InputLabel>
          <Select
            labelId={`Data Type-drop-down`}
            id={`Data Type-drop-down-select`}
            value={timeseriesDataType}
            label="Data Type"
            multiple
            onChange={(e) => setTimeseriesDataType(e.target.value as string[])}
            size="small"
            renderValue={(selected) => {
              const selectedCount = (selected as string[]).length;
              return selectedCount > 1 ? `${selectedCount} selected` : selected.join(", ");
            }}
          >
            {timeseriesDataTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "#86A2B3",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
            color: "#86A2B3",
          },
        }}
      >
        <InputLabel id={`Rights-drop-down`} style={{ color: "#86A2B3" }}>
          Rights
        </InputLabel>
        <Select
          labelId={`Rights-drop-down`}
          id={`Rights-drop-down-select`}
          value={rights}
          label="Rights"
          multiple
          onChange={(e) => dispatch(changeToolboxBathymetryRights(e.target.value as string[]))}
          size="small"
          renderValue={(selected) => {
            const selectedCount = (selected as string[]).length;
            return selectedCount > 1 ? `${selectedCount} selected` : selected.join(", ");
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="User"
        variant="outlined"
        size="small"
        value={username}
        sx={{
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
        }}
        onChange={(e) => setUsername(e.target.value)}
        InputLabelProps={{ style: { color: "#86A2B3" } }}
      />
    </Box>
  );
};
