import { changeFileName } from "../../../features/DataRepoFileNameSlice";
import { changeFileDataType } from "../../../features/DataRepoFileDataTypeSlice";
import { changeFileDescription } from "../../../features/DataRepoDescriptionSlice";
import { changeFilePrivacy } from "../../../features/DataRepoPrivacySlice";
import { changeFileDataFormat } from "../../../features/DataRepoDataFormatSlice";
import { changeFileSurveyDate } from "../../../features/DataRepoSurveyDateSlice";
import { changeFileDatum } from "../../../features/DataRepoDatumSlice";
import { changeFileCRS } from "../../../features/DataRepoCRSSlice";
import { changeDataRepositoryXCoordinate } from "../../../features/DataRepositoryXCoordinateSlice";
import { changeDataRepositoryYCoordinate } from "../../../features/DataRepositoryYCoordinateSlice";
import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { v4 as uuidv4 } from "uuid";
import { DataRepoConfiguration } from "./DataRepoConfiguration/DataRepoConfiguration";
import { changeGroupValue } from "../../../features/DataRepoGroupValueSlice";
import { changeFileTimeZone } from "../../../features/DataRepoTimeZoneSlice";
import { changeDataRepoSurfaceElevation } from "../../../features/DataRepoSurfaceElevationSlice";
import { DataRepoImportOptions } from "./DataRepoImportOptions/DataRepoImportOptions";
import { changeDataRepoProcessFileState } from "../../../features/DataRepoProcessFileSlice";
import { changeDataRepoActivateUpload } from "../../../features/DataRepoActiveUploadSlice";
import { DataRepoWaveTimeSeries } from "./DataRepoImportOptions/DataRepoWaveTmeSeries";
import { DataRepoDrawing } from "./DataRepoImportOptions/DataRepoDrawing";
import { DataRepoModelMesh } from "./DataRepoImportOptions/DataRepoModelMesh";
import UploadDataFiles from "./UploadFiles";
import { useFileContext } from "./DataUploadModal";
import { toolControls } from "../../Openlayers/data_layers";
import { convertUpload, createPersonalProject, getSubProjectList, uploadFiles } from "../../../api/backend";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { setFileUID, setUploadCompleted } from "../../../features/DataRepoUploadStatusSlice";
import { changeUploadPressedState } from "../../../features/DataRepoUploadPressedSlice";
import { publicDataSets } from "../../../api/init";
import JSZip from "jszip";

const steps = ["Select", "Data Description", "Import Options"];

interface stepperProps {
  handleClose: () => void;
  files: File | null;
  setFiles: React.Dispatch<React.SetStateAction<File | null>>;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ProcessedFile = {
  file: File;
  rights: string;
  uid: string;
  fileDescription: string;
  fileDataFormat: string;
  fileCRS: string;
  fileSurveyDate: string | null;
  fileXCoordinate: number | null;
  fileYCoordinate: number | null;
  fileTimeZone: string;
  fileSurfaceElevation: number | null;
  fileDataType: string;
  fileDatum: string;
  fileCRSId: number | null;
  fileProj4String: string;
  fileWkt: string;
};
export default function UploadStepper({ handleClose, files, setFiles, checked, setChecked }: stepperProps) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const { newFile, setNewFile } = useFileContext();
  const dataRepoUpload = useSelector((state: RootState) => state.dataRepoActivateUpload.value);

  const fileDescription = useSelector((state: RootState) => state.fileDescription.value);
  const fileDataFormat = useSelector((state: RootState) => state.fileDataFormat.value);

  const dataType = useSelector((state: RootState) => state.fileDataType.value);
  const [nextClicked, setNextClicked] = useState(false);
  const [groupNextClicked, setGroupNextClicked] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [processedFile, setProcessedFile] = useState<File | null>(null);
  const [errorLeftExists, setErrorLeftExists] = useState(false);
  const [errorRightExists, setErrorRightExists] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [shouldGroupValidate, setShouldGroupValidate] = useState(false);
  const [GroupErrorExists, setGroupErrorExists] = useState(false);

  const [leftValidate, setLeftValidate] = useState<() => boolean>(() => () => false);
  const [rightValidate, setRightValidate] = useState<() => boolean>(() => () => false);

  const [groupValidation, setGroupValidation] = useState<() => boolean>(() => () => false);

  const fileformat = useSelector((state: RootState) => state.fileDataFormat.value);
  const fileDataType = useSelector((state: RootState) => state.fileDataType.value);
  const filePrivacy = useSelector((state: RootState) => state.filePrivacy.value);
  const fileCRS = useSelector((state: RootState) => state.fileCRS.CRS);
  const fileDatum = useSelector((state: RootState) => state.fileDatum.value);
  const fileSurveryDate = useSelector((state: RootState) => state.fileSurveryDate.value);
  const fileXCoordinate = useSelector((state: RootState) => state.xcoordinate.value);
  const fileYCoordinate = useSelector((state: RootState) => state.ycoordinate.value);
  const fileTimeZone = useSelector((state: RootState) => state.timeZone.value);
  const fileSurfaceElevation = useSelector((state: RootState) => state.surfaceElevation.value);
  const filename = useSelector((state: RootState) => state.fileName.value);
  const processFileState = useSelector((state: RootState) => state.dataRepoProcessFile.value);
  const { idTokenPayload } = useOidcIdToken();
  const { isAuthenticated } = useOidc();
  const [projectId, setProjectId] = useState("");

  const handleNext = () => {
    if (activeStep === 0 && filePrivacy === "Group") {
      const errorRight = groupValidation();

      setGroupErrorExists(errorRight);

      if (errorRight) {
        setDisplayError(true);
        setShouldGroupValidate(true);
        return;
      }
    }
    if (activeStep === 1) {
      setNextClicked(true);
      const errorRight = rightValidate();
      const errorLeft = leftValidate();
      setErrorLeftExists(errorLeft);
      setErrorRightExists(errorRight);

      if (errorLeft || errorRight) {
        setDisplayError(true);
        setShouldValidate(true);
        return;
      }

      if (
        fileformat === toolControls.LIDARSPOINTCLOUD ||
        fileformat === toolControls.POINTSHAPEFILE ||
        fileformat === toolControls.TAGGEDIMAGE ||
        fileformat === toolControls.MIKEDFS0 ||
        fileDataType === toolControls.MODELRESULTSWAVE ||
        fileDataType === toolControls.MISCFREEFORMAT ||
        fileDataType === toolControls.MODELGRIDMESH ||
        fileDataType === toolControls.MODELRESULTSHYDRO
      ) {
        dispatch(changeDataRepoActivateUpload({ value: true }));
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (
      filename !== "" &&
      fileDataType === toolControls.BATHYMETRY &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileDatum !== ""
    ) {
      setDisplayError(false);
    } else if (
      filename !== "" &&
      (fileDataType === toolControls.WAVESTIMESERIES ||
        fileDataType === toolControls.WINDTIMESERIES ||
        fileDataType === toolControls.CURRENTSTIMESERIES ||
        fileDataType === toolControls.DISCHARGETIMESERIES ||
        fileDataType === toolControls.ICETHICKNESSTIMESERIES) &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileTimeZone !== "" &&
      fileXCoordinate !== null &&
      fileYCoordinate !== null
    ) {
      setDisplayError(false);
    } else if (
      filename !== "" &&
      fileDataType === toolControls.WATERLEVELTIMESEIRES &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileTimeZone !== "" &&
      fileXCoordinate !== null &&
      fileYCoordinate !== null &&
      fileDatum !== ""
    ) {
      setDisplayError(false);
    } else if (
      filename !== "" &&
      fileDataType === toolControls.BOREHOLELOGS &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileSurfaceElevation !== null &&
      fileXCoordinate !== null &&
      fileYCoordinate !== null &&
      fileDatum !== ""
    ) {
      setDisplayError(false);
    } else if (
      filename !== "" &&
      (fileDataType === toolControls.MODELRESULTSWAVE ||
        fileDataType === toolControls.INFRASTRUCTUREDRAWING ||
        fileDataType === toolControls.MODELGRIDMESH ||
        fileDataType === toolControls.MODELRESULTSHYDRO) &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileDatum !== ""
    ) {
      setDisplayError(false);
    } else if (
      filename !== "" &&
      fileDataType === toolControls.MISCFREEFORMAT &&
      fileDescription !== "" &&
      fileDataFormat !== "" &&
      fileCRS.value !== "" &&
      fileXCoordinate !== null &&
      fileYCoordinate !== null
    ) {
      setDisplayError(false);
    }
  }, [
    filename,
    fileDataType,
    fileDescription,
    fileDataFormat,
    fileCRS,
    fileDatum,
    fileXCoordinate,
    fileYCoordinate,
    fileTimeZone,
    fileDatum,
    fileSurfaceElevation,
  ]);

  useEffect(() => {
    if (shouldGroupValidate) {
      const errorRight = groupValidation();

      setGroupErrorExists(errorRight);

      if (!errorRight) {
        setDisplayError(false);
      }
    }
  }, [groupValidation, activeStep]);

  const handleBack = () => {
    if (activeStep === 1) {
      setNameError(false);
      setDescriptionError(false);
      setDisplayError(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextClicked(false);
  };

  const handleUpload = () => {
    handleClose();
    setNewFile(null);
    dispatch(changeDataRepoActivateUpload({ value: false }));
    dispatch(changeDataRepoProcessFileState({ value: true }));
    dispatch(changeUploadPressedState({ value: true }));
    setProcessedFile(null);
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const projectIdBasedOnRights =
      filePrivacy === "Public"
        ? publicDataSets.PublicUserUpload.id
        : filePrivacy === "Private"
        ? publicDataSets.PrivateUserUpload.id
        : publicDataSets.PrivateTemporaryBathymetry.id;
    const performUpload = async () => {
      const subprojectlist = await getSubProjectList(projectIdBasedOnRights);
      let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

      if (!personalFolderId) {
        await createPersonalProject(idTokenPayload.oid, projectIdBasedOnRights);
        const updatedSubprojectList = await getSubProjectList(projectIdBasedOnRights);
        personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
      }

      if (processedFile && fileDataFormat !== "") {
        let fileToUpload = processedFile;
        if (dataType.includes("(Timeseries)") && fileDataFormat !== "MIKE DFS0 (.dfs0)") {
          const zip = new JSZip();
          zip.file(processedFile.name, processedFile);
          const zippedFileBlob = await zip.generateAsync({ type: "blob" });
          fileToUpload = new File([zippedFileBlob], `${processedFile.name.split(".")[0]}.zip`, {
            type: "application/zip",
          });
        }

        const updatedProcessedFile: ProcessedFile = {
          file: fileToUpload,
          rights: filePrivacy,
          uid: uuidv4(),
          fileDescription: fileDescription,
          fileDataFormat: fileDataFormat,
          fileDataType: dataType,
          fileCRS: fileCRS.value,
          fileCRSId: fileCRS.id,
          fileProj4String: fileCRS.proj4String,
          fileDatum: fileDatum,
          fileSurveyDate: fileSurveryDate,
          fileXCoordinate: fileXCoordinate,
          fileYCoordinate: fileYCoordinate,
          fileTimeZone: fileTimeZone,
          fileSurfaceElevation: fileSurfaceElevation,
          fileWkt: fileCRS.wkt,
        };

        const uploadResponse = await uploadFiles(fileToUpload);
        const convert = await convertUpload(fileToUpload, uploadResponse.data, personalFolderId, updatedProcessedFile);
        if (convert.status === "Completed") {
          dispatch(setUploadCompleted(true));
          dispatch(setFileUID(convert.id));
        }

        setProcessedFile(null);
        dispatch(changeFileName({ value: "" }));
        dispatch(changeFileDescription({ value: "" }));
        dispatch(changeFileDataFormat({ value: "" }));
        dispatch(changeFileCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
        dispatch(changeFileDatum({ value: "" }));
        dispatch(changeFileDataType({ value: "" }));
        dispatch(changeFilePrivacy({ value: "Public" }));
        dispatch(changeFileSurveyDate({ value: null }));
        dispatch(changeToolboxZNegative({ value: false }));
        dispatch(changeGroupValue({ value: "" }));
        dispatch(changeDataRepositoryXCoordinate({ value: null }));
        dispatch(changeDataRepositoryYCoordinate({ value: null }));
        dispatch(changeFileTimeZone({ value: "" }));
        dispatch(changeDataRepoSurfaceElevation({ value: null }));
        setFiles(null);
      }
    };
    performUpload();
  }, [processedFile, filePrivacy]);

  const handleCancel = () => {
    handleClose();
    setNewFile(null);
    setFiles(null);
    setChecked(false);
    dispatch(changeFileName({ value: "" }));
    dispatch(changeFileDescription({ value: "" }));
    dispatch(changeFileDataFormat({ value: "" }));
    dispatch(changeFileCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
    dispatch(changeFileDataType({ value: "" }));
    dispatch(changeFileDatum({ value: "" }));
    dispatch(changeGroupValue({ value: "" }));
    dispatch(changeFileSurveyDate({ value: null }));
    dispatch(changeDataRepositoryXCoordinate({ value: null }));
    dispatch(changeFilePrivacy({ value: "Public" }));
    dispatch(changeDataRepositoryYCoordinate({ value: null }));
    dispatch(changeFileTimeZone({ value: "" }));
    dispatch(changeDataRepoSurfaceElevation({ value: null }));
    dispatch(changeToolboxZNegative({ value: false }));
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ m: "0rem 1rem" }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 && (
        <UploadDataFiles
          file={files}
          setFile={setFiles}
          uploading={uploading}
          setUploading={setUploading}
          activeState={activeState}
          setActiveState={setActiveState}
          checked={checked}
          setChecked={setChecked}
          setGroupValidation={setGroupValidation}
          nextClicked={groupNextClicked}
          setNextClicked={setGroupNextClicked}
        />
      )}
      {activeStep === 1 && (
        <DataRepoConfiguration
          setLeftValidate={setLeftValidate}
          setRightValidate={setRightValidate}
          nextClicked={nextClicked}
          setNextClicked={setNextClicked}
          nameError={nameError}
          setNameError={setNameError}
          descriptionError={descriptionError}
          setDescriptionError={setDescriptionError}
        />
      )}
      {activeStep === 2 && dataType === toolControls.BATHYMETRY && (
        <DataRepoImportOptions processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
      )}
      {activeStep === 2 && dataType === toolControls.INFRASTRUCTUREDRAWING && (
        <DataRepoDrawing processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
      )}
      {(activeStep === 2 && dataType === toolControls.WAVESTIMESERIES) ||
      (activeStep === 2 && dataType === toolControls.WINDTIMESERIES) ||
      (activeStep === 2 && dataType === toolControls.WATERLEVELTIMESEIRES) ||
      (activeStep === 2 && dataType === toolControls.CURRENTSTIMESERIES) ||
      (activeStep === 2 && dataType === toolControls.DISCHARGETIMESERIES) ||
      (activeStep === 2 && dataType === toolControls.ICETHICKNESSTIMESERIES) ? (
        <DataRepoWaveTimeSeries processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
      ) : null}

      {(activeStep === 2 && dataType === toolControls.MODELGRIDMESH) ||
      (activeStep === 2 && dataType === toolControls.MODELRESULTSWAVE) ||
      (activeStep === 2 && dataType === toolControls.MODELRESULTSHYDRO) ||
      (activeStep === 2 && dataType === toolControls.MISCFREEFORMAT) ? (
        <DataRepoModelMesh processedFile={processedFile} setProcessedFile={setProcessedFile} file={files} />
      ) : null}

      <Box display="flex" flexDirection="row" justifyContent="space-between" m="0.5rem 1rem 0rem 1rem">
        {activeStep === 1 && displayError ? (
          <Typography variant="body1" color="red">
            Please fill the required fields or enter the correct coordinates.
          </Typography>
        ) : (
          <Box></Box>
        )}
        <Stack direction="row">
          <Button onClick={activeStep == 0 ? handleCancel : handleBack} sx={{ mr: "1rem" }}>
            {activeStep === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            onClick={activeStep === 2 ? handleUpload : handleNext}
            variant="contained"
            disabled={activeStep === 0 || activeStep === 1 ? !activeState : !dataRepoUpload}
          >
            {activeStep === steps.length - 1 ? "Upload" : "Next"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
