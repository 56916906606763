import { Box, Divider, Typography } from "@mui/material";
import { DashboardTabsHead } from "../DashboardTabsHead";
import { useState } from "react";
import { DashboardIceTabTable } from "./DashboardIceTabTable";
import { IceCoverChartBreaks } from "./IceCoverChartBreaks";
import { IceCoverTickBarGraph } from "./IceCoverTickBarGraph";
import { DashboardIceTimeseriesChart } from "./DashboardIceTimseriesChart";
import { DashboardIceWeekChart } from "./DashboardIceWeekChart";

interface portProps {
  port: any;
}

export const DashboardIceTab = ({ port }: portProps) => {
  const [cisValue, setCisValue] = useState("");
  const [parsedData, setParsedData] = useState<any>();
  const [boxPlotData, setBoxPlotData] = useState<any>();
  const [detailedInfo, setDetailedInfo] = useState<any>();

  const downloadCSVFiles = () => {
    const generateCSV = (data: any, fileName: string) => {
      const header = Array.isArray(data[0])
        ? "Week,Minimum,Q1,Median,Q3,Maximum\n"
        : Object.keys(data[0]).join(",") + "\n";

      const csvContent = data.reduce((csvString: string, row: any) => {
        const rowData = Array.isArray(row)
          ? row.join(",")
          : Object.values(row)
              .map((value) => `"${String(value).replace(/"/g, '""')}"`)
              .join(",");
        return csvString + rowData + "\n";
      }, header);

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 0);
    };

    if (parsedData) {
      generateCSV(parsedData, "MeasuredIceThicknessTimeseries.csv");
      setTimeout(() => {
        if (boxPlotData) {
          generateCSV(boxPlotData, "BoxPlotData.csv");
        }
      }, 100);
    } else if (boxPlotData) {
      setTimeout(() => {
        generateCSV(boxPlotData, "BoxPlotData.csv");
      }, 100);
    }
  };

  return (
    <Box>
      <DashboardTabsHead name="Ice" onClick={() => downloadCSVFiles()} />

      <Divider />
      <Box bgcolor="grey.50" height="calc(100vh - 12.5rem)" sx={{ overflowY: "auto", overflowX: "hidden" }}>
        <DashboardIceTabTable setCisValue={setCisValue} setDetailedInfo={setDetailedInfo} />
        <Box>
          <IceCoverChartBreaks />
        </Box>
        <IceCoverTickBarGraph />
        <DashboardIceTimeseriesChart cisValue={cisValue} parsedData={parsedData} setParsedData={setParsedData} />
        <DashboardIceWeekChart cisValue={cisValue} boxPlotData={boxPlotData} setBoxPlotData={setBoxPlotData} />
        <Box m="1rem">
          <Typography color="grey.500" variant="body2" mb="0.75rem">
            Warnings:
          </Typography>
          <Box>
            <Typography color="grey.500" variant="caption">
              No CIS climatology data exists for the {port.get("Harbour_Na")}.
            </Typography>
          </Box>
          {detailedInfo && detailedInfo.CIS_selec !== "" ? (
            <Typography color="grey.500" variant="caption">
              {`The closest CIS measurement station, ${
                detailedInfo.CIS_selec
              }, (latitude ${detailedInfo.CIS_lat.toFixed(1)}, longitude ${detailedInfo.CIS_lon.toFixed(
                1
              )}), is located  ${parseFloat(detailedInfo.CIS_dstkm).toFixed(1)} km from the ${port.get(
                "Harbour_Na"
              )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
            </Typography>
          ) : (
            <Typography color="grey.500" variant="caption">
              CIS measurements close to {port.get("Harbour_Na")} is not available.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
