import { Box, Divider, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { canadianBorderLayer } from "../Openlayers/default_groups_layers";
import { OpenlayersContext } from "../Openlayers/OpenlayersContext";
import { ToolboxMainPage } from "../ToolboxComponents/ToolboxMainPage";
import { changeToolboxState } from "../../features/ToolboxStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ToolBoxSuggestion } from "../ToolboxComponents/ToolboxSuggestion/ToolboxSuggestion";
import { ToolBoxWaveProfile } from "../ToolboxComponents/ToolBoxWaveProfile/ToolBoxWaveProfile";
import { ToolBoxBathymetryInterpolation } from "../ToolboxComponents/ToolBoxBathymetryInterpolation/ToolBoxBathymetryInterpolation";
import { ToolBoxSeasonalStatistics } from "../ToolboxComponents/ToolBoxSeasonalStatistics/ToolBoxSeasonalStatistics";
import { RootState } from "../../store/store";
import { resetLayerVisibilities } from "../../features/DataRepoLayerVisibilitySlice";

interface boxProps {
  name: string;
  caption: string;
  onClick: () => void;
  image: string;
}

export const BoxComponent = ({ name, caption, onClick, image }: boxProps) => {
  return (
    <>
      <Box
        width="100%"
        height="6rem"
        onClick={onClick}
        sx={{
          "&:hover": {
            cursor: "pointer",
            bgcolor: "grey.50",
          },
        }}
        display="flex"
        flexDirection="row"
      >
        <Box ml="1rem" mt="1rem">
          <img src={image} alt="icon" style={{ width: "4rem", height: "4rem" }} />
        </Box>
        <Box ml="1rem" pt="1rem">
          <Typography variant="body1" fontWeight="700" color="secondary.dark">
            {name}
          </Typography>
          <Divider sx={{ width: "4rem", mt: "0.5rem" }} />
          <Typography variant="body2" color="secondary.dark" mt="0.5rem">
            {caption}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ m: "0rem 1rem" }} />
    </>
  );
};

function ToolboxPanel() {
  const map = useContext(OpenlayersContext);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: userNames } = useSelector((state: RootState) => state.userNames);

  if (map?.current) {
    map.current.getLayers().forEach((layer) => {
      if (
        layer.get("id")?.startsWith("bathymetryIconLayer") ||
        layer.get("id")?.startsWith("gislayers") ||
        layer.get("id")?.startsWith("bathymetryLayer")
      ) {
        layer.setVisible(false);
      }
    });
  }
  useEffect(() => {
    if (!map?.current) return;
    const layers = map.current.getLayers().getArray();
    const layerToRemove = layers.find((layer) => layer.get("customPointsLayerName") === "customPointsLayer");
    if (layerToRemove) {
      map.current.removeLayer(layerToRemove);
    }
    dispatch(resetLayerVisibilities());
  }, []);

  return (
    <Box>
      {!searchParams.has("q") && (
        <ToolboxMainPage
          value={
            <Box>
              <Box ml="1rem" mb="0.5rem">
                <Typography variant="body1" color="secondary.dark" fontWeight="700" pt="0.5rem">
                  Toolbox
                </Typography>
                <Typography variant="caption" color="grey.500">
                  Dynamic toolbox for accurate data generation
                </Typography>
              </Box>
              <Divider />
              <BoxComponent
                name={"Wave Profile Model"}
                caption={"Calculate wave propagation along a bathymetry profile"}
                onClick={() => {
                  searchParams.set("q", "waveProfile");
                  setSearchParams(searchParams);
                  dispatch(changeToolboxState({ value: "waveProfile" }));
                }}
                image={"/toolboxes/Wave profile.svg"}
              />
              <BoxComponent
                name={"Bathymetry Interpolation"}
                caption={"Transform hydrographic surveys to a seamless Digital Elevation Model"}
                onClick={() => {
                  searchParams.set("q", "bathymetryinterpolation");
                  setSearchParams(searchParams);
                  dispatch(changeToolboxState({ value: "bathymetryinterpolation" }));
                }}
                image="/toolboxes/Bathymetry interpolation.svg"
              />

              <BoxComponent
                name={"Seasonal and Monthly Statistics"}
                caption={"Calculate annual, seasonal, or monthly statistics from a timeseries"}
                onClick={() => {
                  searchParams.set("q", "seasonalstatistics");
                  setSearchParams(searchParams);
                  dispatch(changeToolboxState({ value: "seasonalStatistics" }));
                }}
                image="/toolboxes/Seasonal stats.svg"
              />

              <BoxComponent
                name={"Suggest a Toolbox"}
                caption={"Make a suggestion for a function that should be added to the toolbox"}
                onClick={() => {
                  searchParams.set("q", "suggest");
                  setSearchParams(searchParams);
                  dispatch(changeToolboxState({ value: "suggest" }));
                }}
                image="/toolboxes/suggest a tool.svg"
              />
            </Box>
          }
        />
      )}
      {searchParams.get("q") === "suggest" && (
        <ToolBoxSuggestion searchParams={searchParams} setSearchParams={setSearchParams} />
      )}
      {searchParams.get("q") === "waveProfile" && (
        <ToolBoxWaveProfile searchParams={searchParams} setSearchParams={setSearchParams} />
      )}
      {searchParams.get("q") === "bathymetryinterpolation" && (
        <ToolBoxBathymetryInterpolation searchParams={searchParams} setSearchParams={setSearchParams} />
      )}
      {searchParams.get("q") === "seasonalstatistics" && (
        <ToolBoxSeasonalStatistics searchParams={searchParams} setSearchParams={setSearchParams} />
      )}
    </Box>
  );
}

export default ToolboxPanel;
