import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { changeToolboxActivateUpload } from "../../../../features/ToolboxFeatures/ToolboxActivateUploadSlice";
import { changeDataRepoActivateUpload } from "../../../../features/DataRepoActiveUploadSlice";

interface FileProps {
  processedFile: File | null;
  setProcessedFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
}

const checkboxLabels = [
  "Bathymetric Survey",
  "Control Points",
  "Top Survey",
  "New Breakwater outline",
  "Old Breakwater outline",
  "Utilities",
  "Floating wharves",
  "Benchmarks",
  "Easement",
];

export const DataRepoDrawing = ({
  processedFile,
  setProcessedFile,
  file,
}: FileProps) => {
  const dispatch = useDispatch();
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>(
    new Array(9).fill(false)
  );
  const [clickedCheckbox, setClickedCheckbox] = useState(false);

  const handleChangeCheckbox = (index: number) => {
    const newCheckboxValues = [...checkboxValues];
    newCheckboxValues[index] = !newCheckboxValues[index];
    setCheckboxValues(newCheckboxValues);
    if (!newCheckboxValues.includes(true)) {
      dispatch(changeDataRepoActivateUpload({ value: false }));
    } else {
      if (newCheckboxValues[index]) {
        setClickedCheckbox(true);
        dispatch(changeDataRepoActivateUpload({ value: true }));
      }
    }
  };

  const filename = useSelector((state: RootState) => state.fileName.value);
  const processFileState = useSelector(
    (state: RootState) => state.dataRepoProcessFile.value
  );

  const processFile = async () => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const contents = e.target?.result;
      if (contents) {
        const processedFile = new File([contents], `${filename}.dwg`, {
          type: file.type,
        });
        setProcessedFile(processedFile);
        dispatch(changeToolboxActivateUpload({ value: true }));
        setClickedCheckbox(false);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (processFileState && clickedCheckbox) {
      processFile();
    }
  }, [processFileState]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "16.5rem",
        overflow: "auto",
      }}
    >
      <Box ml="2rem" mt="1rem" mr="2rem" gap="-1rem">
        <Box display="flex" flexDirection="column" gap="1rem">
          <Typography
            variant="body1"
            color="secondary.dark"
            fontWeight="700"
            ml="0.2rem"
          >
            Drawing Layers
          </Typography>
          <Box
            width="100%"
            height="13.5rem"
            bgcolor="grey.50"
            borderRadius="0.5rem"
            border="1px solid gray"
            mt="-0.75rem"
            display="flex"
            flexDirection="column"
            padding="1rem"
          >
            {checkboxValues.map((checked, index) => (
              <FormControlLabel
                key={index}
                sx={{ mt: "-1rem" }}
                control={
                  <Checkbox
                    checked={checked}
                    size="small"
                    onChange={() => handleChangeCheckbox(index)}
                  />
                }
                label={
                  <Typography variant="body2" color="secondary.dark">
                    {checkboxLabels[index]}
                  </Typography>
                }
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
