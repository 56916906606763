import { createContext, useContext, MutableRefObject, useRef } from 'react';
import { Map } from 'ol';

export const OpenlayersContext = createContext<MutableRefObject<Map | null> | null>(null);
export const useOpenlayersContext = () => useContext(OpenlayersContext);

// export function OpenlayersProvider({ children }: any) {
//   const mapInstance = useRef<Map | null>(null);
//   return <OpenlayersContext.Provider value={mapInstance}>{children}</OpenlayersContext.Provider>;
// }
