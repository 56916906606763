import {
  Menu,
  Typography,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Divider,
  Collapse,
  List,
  Stack,
} from "@mui/material";
// import { useSelector } from "react-redux";
// import { RootState } from "../../app/store";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { useState, Dispatch, SetStateAction } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";

interface usernameProps {
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  openUsername: boolean;
  handleCloseUsername: () => void;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  initials: string;
}

const groups = Array.from({ length: 5 }, (_, i) => ({
  name: `Group ${i + 1}`,
  avatarBgColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
}));

export const UsernameModal = ({
  anchorEl,
  openUsername,
  setAnchorEl,
  handleCloseUsername,
  initials,
}: usernameProps) => {
  const { logout } = useOidc();
  const [open, setOpen] = useState(true);
  const { idTokenPayload } = useOidcIdToken();

  const handleClick = () => {
    setOpen(!open);
  };
  const handleLogoutClick = () => {
    const currentRouteWithSearchParams = window.location.pathname + window.location.search;
    logout(currentRouteWithSearchParams);
  };

  return (
    <Menu
      id="username-menu"
      // This needs to be fixed
      // @ts-ignore
      anchorEl={anchorEl}
      open={openUsername}
      onClose={handleCloseUsername}
      MenuListProps={{
        "aria-labelledby": "username-button",
      }}
      PaperProps={{
        style: {
          width: "20rem",
          height: "auto",
          borderRadius: "0.5rem",
          overflowY: "hidden",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "3.75rem 1fr",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "primary.main",
            width: 32,
            height: 32,
            m: "0.75rem 0.5rem 0.5rem 1rem",
          }}
        >
          <Typography variant="body1" mt="0.1rem">
            {initials}
          </Typography>
        </Avatar>
        <Box mt="0.5rem">
          <Stack>
            <Typography variant="body2" color="secondary.dark">
              {idTokenPayload && idTokenPayload.sub}
            </Typography>
            <Typography variant="caption" color="grey.500" mb="0.625rem">
              {idTokenPayload && idTokenPayload.email}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Divider />

      <ListItemButton onClick={handleClick} sx={{ height: "2.5rem" }} disabled>
        <ListItemIcon sx={{ color: "secondary.dark" }}>
          <GroupOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" color="secondary.dark" ml="-1.5rem">
              Groups
            </Typography>
          }
        />
        {!open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={!open} timeout="auto">
        <List component="div" disablePadding>
          {groups.map((group) => (
            <ListItemButton key={group.name} sx={{ pl: 2, height: "2.25rem" }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: group.avatarBgColor,
                    width: 24,
                    height: 24,
                    m: "0.5rem 0.5rem 0.5rem 1rem",
                  }}
                >
                  <Typography variant="caption" mt="0.25rem">
                    {group.name.slice(0, 2).toUpperCase()}
                  </Typography>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body2" color="secondary.dark" ml="-0.5rem">
                    {group.name}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>

      <Divider />
      <ListItemButton
        sx={{ ml: "-1rem", height: "2.5rem" }}
        onClick={() => {
          setAnchorEl(null);
          setOpen(false);
          handleLogoutClick();
        }}
      >
        <ListItemIcon
          sx={{
            mt: "2rem",
            transform: "rotate(90deg)",
            color: "secondary.dark",
          }}
        >
          <UploadOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" color="secondary.dark" ml="-.5rem">
              Signout
            </Typography>
          }
        />
      </ListItemButton>
    </Menu>
  );
};
