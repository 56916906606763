import { styled, Box, Theme, Button, Drawer } from "@mui/material";

export const AppWrapper = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, [col] 1fr)",
  gridTemplateRows: "[row] 48px [row] auto",
  height: "100vh",
  color: "white",
  background: theme.palette.secondary.dark,
  "&.footer": {
    gridTemplateRows: "[row] 48px [row] auto [row] 48px",
  },
  "&.noFooter.noHeader": {
    gridTemplateRows: "[row] 0 [row] auto [row] 0",
  },
}));

export const Header = styled(Box)(({ theme }: { theme: Theme }) => ({
  gridColumn: "col / span 4",
  gridRow: "row 1",
  background: theme.palette.secondary.main,
  color: "red",
  borderBottom: "4px solid",
  borderBottomColor: theme.palette.secondary.dark,
  padding: "0 8px",
  display: "flex",
  alignItems: "center",
}));

export const Main = styled(Box)(({ theme }: { theme: Theme }) => ({
  gridColumn: "col / span 4",
  gridRow: "row 2",
  background: theme.palette.secondary.dark,
  display: "grid",
  gridGap: "0px",
  gridTemplateColumns: "[col] 0 [col] 1fr",
  gridTemplateRows: "1fr",
  overflowX: "hidden",
  position: "relative",
  // transition: "grid-template-columns 500ms, grid-template-rows 500ms",
  "&.fullWidth": {
    gridTemplateColumns: "[col] 1fr",
  },
  "&.visibleSidePanel": {
    gridTemplateColumns: "[col] 800px [col] 1fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "[col] 1fr",
    gridTemplateRows: "[row] 1fr [row] 0",
    "&.fullWidth": {
      gridTemplateRows: "[row] 1fr",
    },
    "&.visibleSidePanel": {
      gridTemplateColumns: "[col] 1fr",
      gridTemplateRows: "[row] 1fr [row] 35%",
    },
  },
}));

export const Aside = styled(Box)(({ theme }: { theme: Theme }) => ({
  gridRow: "row 0",
  gridColumn: "col / span 1",
  position: "relative",
  marginLeft: "-1px",
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: "rgba(0, 0, 0, 0.25) 4px 0px 4px",
  overflowY: "hidden",
  overflowX: "hidden",
  zIndex: 50,
  background: theme.palette.common.white,
  [theme.breakpoints.down("md")]: {
    gridColumn: "col",
    gridRow: "row 2",
    borderRight: 0,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export const Article = styled(Box)(({ theme }: { theme: Theme }) => ({
  gridRow: "row 0",
  gridColumn: "col 2/ span 1",
  background: "white",
  position: "relative",
  "&.fullWidth": {
    gridColumn: "col / span 1",
  },
  [theme.breakpoints.down("md")]: {
    gridColumn: "col",
    gridRow: "row 1",
  },
}));

export const Footer = styled(Box)(({ theme }: { theme: Theme }) => ({
  gridRow: "row 3",
  gridColumn: "col / span 4",
  backgroundColor: theme.palette.action.active,
  color: theme.palette.common.white,
  overflow: "auto",
  display: "flex",
  alignItems: "center",
}));

export const ToggleAside = styled(Button)(({ theme }: { theme: Theme }) => ({
  position: "absolute",
  left: "-1px",
  zIndex: "51",
  height: "3rem",
  width: "1.3rem",
  maxWidth: "auto",
  minWidth: "auto",
  marginBottom: "-1.5rem",
  bottom: "50%",
  border: 0,
  cursor: "pointer",
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.common.white,
  borderRadius: "0 4px 4px 0",
  boxShadow: "rgba(0, 0, 0, 0.10) 5px 0px 5px 1px",
  color: theme.palette.secondary.main,
  "&:focus,&:hover": {
    background: theme.palette.common.white,
  },
  "& svg": {
    fontSize: "1.0rem",
  },
  "&.visibleSidePanel svg": {
    rotate: "180deg",
  },
  [theme.breakpoints.down("md")]: {
    height: "1.3rem",
    width: "3rem",
    marginLeft: "-1.5rem",
    marginBottom: "auto",
    left: "50%",
    bottom: "-1px",
    borderRadius: "4px 4px 0 0",
    borderBottom: 0,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: "rgba(0, 0, 0, 0.10) 0px -5px 7px 1px",
    "& svg": {
      rotate: "-90deg",
    },
    "&.visibleSidePanel svg": {
      rotate: "90deg",
    },
  },
}));
