import { SetStateAction, useContext, useRef, useState } from "react";
import { MutableRefObject } from "react";
import { Stack, IconButton } from "@mui/material";
import { Measure } from "@dhi/icons/dist";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Overlay from "ol/Overlay";
import { Geometry, LineString } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { Fill, Stroke, Style, Text } from "ol/style";
import { useDispatch, useSelector } from "react-redux";
import { changeClearButtonVisibilityState } from "../../features/ClearButtonVisibilitySlice";
import { Feature } from "ol";
import { deleteFileDetailsInsidePolygon } from "../../features/FileDetailsInsidePolygonSlice";
import { clearPolygonCoordinates } from "../../features/PolygonCoordinatesSlice";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

interface MapProps {
  map: MutableRefObject<Map | null>;
  drawInteraction: MutableRefObject<Draw | null>;
  overlays: MutableRefObject<Overlay[]>;
  isMeasuring: boolean;
  setIsMeasuring: React.Dispatch<React.SetStateAction<boolean>>;
  drawnSource: VectorSource<any>;
  drawnFeature: Feature<Geometry>;
  setMeasureClicked: React.Dispatch<SetStateAction<boolean>>;
  setDraw: React.Dispatch<React.SetStateAction<Draw | null>>;
}

export const MeasureIcon = ({
  map,
  overlays,
  drawInteraction,
  isMeasuring,
  setIsMeasuring,
  drawnSource,
  drawnFeature,
  setDraw,
  setMeasureClicked,
}: MapProps) => {
  const dispatch = useDispatch();
  const vectorLayer = useRef<VectorLayer<VectorSource> | null>(null);

  const toggleMeasureMode = () => {
    setMeasureClicked((prev) => !prev);
    if (map && typeof map.current?.getLayers === "function") {
      const layers = map.current.getLayers();
      if (layers) {
        setDraw(null);
        layers.forEach((layer) => {
          if (layer && typeof layer.get === "function" && layer.get("id") === "polygonLayer") {
            map.current?.removeLayer(layer);
            dispatch(changeClearButtonVisibilityState({ value: false }));
            dispatch(deleteFileDetailsInsidePolygon());
            dispatch(clearPolygonCoordinates());
          }
        });
        if (drawnFeature) {
          drawnSource.removeFeature(drawnFeature);
        }
      }
    }

    if (isMeasuring) {
      removeInteraction();
    } else {
      addInteraction();
    }
    setIsMeasuring(!isMeasuring);
  };

  const addInteraction = () => {
    if (map.current) {
      const vectorSource = new VectorSource();

      vectorLayer.current = new VectorLayer({
        source: vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: "#00AAFF",
            width: 2,
          }),
          text: new Text({
            font: "14px Calibri,sans-serif",
            fill: new Fill({
              color: "#fff",
            }),
            stroke: new Stroke({
              color: "#000",
              width: 3,
            }),
          }),
        }),
      });

      vectorLayer.current.set("id", "measureLayer");
      map.current.addLayer(vectorLayer.current);

      drawInteraction.current = new Draw({
        source: vectorSource,
        type: "LineString",
      });

      let tooltipElement = document.createElement("div");
      tooltipElement.className = "tooltip";
      tooltipElement.style.color = "black";
      tooltipElement.style.backgroundColor = "white";
      tooltipElement.style.border = "1px solid black";
      tooltipElement.style.padding = "2px 5px";
      tooltipElement.style.borderRadius = "4px";
      const tooltip = new Overlay({
        element: tooltipElement,
        offset: [0, -5],
        positioning: "bottom-center",
      });

      let pointerMoveFunction: (e: any) => void;

      drawInteraction.current.on("drawstart", (event) => {
        map.current && map.current.addOverlay(tooltip);

        pointerMoveFunction = (e) => {
          if (event.feature) {
            const featureGeometry = event.feature.getGeometry();
            if (featureGeometry instanceof LineString) {
              const coordinates = featureGeometry.getCoordinates().slice();
              coordinates.push(e.coordinate);

              const geom = new LineString(coordinates);
              const length = geom.getLength();

              tooltipElement.innerHTML = `${(length / 1000).toFixed(2)} kilometers`;
              tooltip.setPosition(e.coordinate);
            }
          }
        };

        map.current && map.current.on("pointermove", pointerMoveFunction);
      });

      drawInteraction.current.on("drawend", (event) => {
        const geom = event.feature.getGeometry() as LineString;
        const length = geom.getLength();
        const coordinates = geom.getLastCoordinate();

        const finalTooltipElement = document.createElement("div");
        finalTooltipElement.className = "tooltip";
        finalTooltipElement.style.color = "black";
        finalTooltipElement.style.backgroundColor = "white";
        finalTooltipElement.style.border = "1px solid black";
        finalTooltipElement.style.padding = "2px 5px";
        finalTooltipElement.style.borderRadius = "4px";
        finalTooltipElement.innerHTML = `${(length / 1000).toFixed(2)} kilometers`;

        const finalTooltip = new Overlay({
          element: finalTooltipElement,
          offset: [0, -5],
          positioning: "bottom-center",
        });

        map.current && map.current.addOverlay(finalTooltip);
        finalTooltip.setPosition(coordinates);

        overlays.current.push(finalTooltip);

        map.current && map.current.removeOverlay(tooltip);

        if (pointerMoveFunction) {
          map.current && map.current.un("pointermove", pointerMoveFunction);
        }
      });

      map.current.addInteraction(drawInteraction.current);
    }
  };

  const removeInteraction = () => {
    if (map.current) {
      if (drawInteraction.current) {
        map.current.removeInteraction(drawInteraction.current);
      }
      if (vectorLayer.current) {
        map.current.removeLayer(vectorLayer.current);
      }
      overlays.current.forEach((overlay) => map.current?.removeOverlay(overlay));
      overlays.current.length = 0;

      const dynamicTooltips = document.getElementsByClassName("tooltip");
      while (dynamicTooltips.length > 0) {
        dynamicTooltips[0].parentNode?.removeChild(dynamicTooltips[0]);
      }
    }
  };

  return (
    <Stack
      sx={{
        borderRadius: "4px",
        bgcolor: isMeasuring ? "secondary.dark" : "white",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <LightTooltip title="Measure" placement="right" enterDelay={500} leaveDelay={200}>
        <IconButton
          disableRipple
          onClick={toggleMeasureMode}
          sx={{
            color: isMeasuring ? "#FFFFFF" : "secondary.dark",
            height: "32px",
            width: "32px",
          }}
        >
          <Measure fontSize="large" />
        </IconButton>
      </LightTooltip>
    </Stack>
  );
};
