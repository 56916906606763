import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LegendContainer } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import { Directions, AEP } from "../../../assets/BoundaryConditions";
import { toolControls } from "../../Openlayers/data_layers";
import { WaveGroup } from "../../Openlayers/default_groups_layers";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LightTooltip } from "./BathymetryLegend";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";

export default function WaveLegend() {
  const toolname = new URLSearchParams(location.search).get("tool");

  const [dataSetLayers, setDataSetLayers] = React.useState<any[] | null>(null);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);

  const [selectedLayerIndex, setSelectedLayerIndex] = React.useState<number>(0);
  const [selectedDimensionIndex, setSelectedDimensionIndex] = React.useState<number>(0);
  const [selectedAEPIndex, setSelectedAEPIndex] = React.useState<number>(0);

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    getTileDataSetsMetadata(publicDataSets.WaveHeight.id).then((response) => {
      setDataSetLayers(response.tilingSchemes.map((t: any) => t));
      setDataSetMetadata(response);
    });

    return () => {};
  }, []);

  const handleChangeLayer = (e: any) => {
    WaveGroup.getLayersArray().map((l) => {
      if (l.get("id") === e.target.value) {
        l.setVisible(true);
      } else {
        l.setVisible(false);
      }
    });

    const lookUpIndex = Directions.indexOf(Directions[selectedDimensionIndex]) * 8 + AEP.indexOf(AEP[selectedAEPIndex]);
    const lookUpDimension = dataSetMetadata ? dataSetMetadata.timeStamps[lookUpIndex] : 0;
    const layerIndex = WaveGroup.getLayersArray().findIndex((item) => item.get("id") === e.target.value);
    setSelectedLayerIndex(layerIndex);

    WaveGroup.getLayersArray().map((l: any) => {
      if (e.target.value === l.get("id")) {
        l.getSource()?.setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
          const href = new URL(src);
          href.searchParams.set("TIMESTEP", lookUpDimension);
          const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
          const imageData = await response.blob();
          imageTile.getImage().src = window.URL.createObjectURL(imageData);
        });

        l.getSource().updateDimensions({
          TIMESTEP: lookUpDimension,
        });

        WaveGroup.changed();
      }
    });
  };

  const handleChangeDimension = (e: any) => {
    const lookUpIndex = Directions.indexOf(e.target.value) * 8 + AEP.indexOf(AEP[selectedAEPIndex]);
    const lookUpDimension = dataSetMetadata ? dataSetMetadata.timeStamps[lookUpIndex] : 0;
    setSelectedDimensionIndex(Directions.indexOf(e.target.value));

    WaveGroup.getLayersArray().map((l: any) => {
      if (dataSetLayers && dataSetLayers[selectedLayerIndex].schemeId === l.get("id")) {
        l.getSource()?.setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
          const href = new URL(src);
          href.searchParams.set("TIMESTEP", lookUpDimension);
          const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
          const imageData = await response.blob();
          imageTile.getImage().src = window.URL.createObjectURL(imageData);
        });

        l.getSource().updateDimensions({
          TIMESTEP: lookUpDimension,
        });

        WaveGroup.changed();
      }
    });
  };

  const handleChangeWaveAEP = (e: any) => {
    const lookUpIndex = Directions.indexOf(Directions[selectedDimensionIndex]) * 8 + AEP.indexOf(e.target.value);
    const lookUpDimension = dataSetMetadata ? dataSetMetadata.timeStamps[lookUpIndex] : 0;
    setSelectedAEPIndex(AEP.indexOf(e.target.value));

    WaveGroup.getLayersArray().map((l: any) => {
      if (dataSetLayers && dataSetLayers[selectedLayerIndex].schemeId === l.get("id")) {
        l.getSource().setTileLoadFunction(async function tileLoader(imageTile: any, src: any) {
          const href = new URL(src);
          href.searchParams.set("TIMESTEP", lookUpDimension);
          const response = await fetch(href.toString(), { headers: { "dhi-open-api-key": publicDataSets.readerId } });
          const imageData = await response.blob();
          imageTile.getImage().src = window.URL.createObjectURL(imageData);
        });

        l.getSource().updateDimensions({
          TIMESTEP: lookUpDimension,
        });

        WaveGroup.changed();
      }
    });
  };

  return (
    <>
      {toolname === toolControls.WAVEHEIGHT && (
        <>
          <LegendContainer style={{ display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Wave height
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Wave modelling was performed at a regional scale to derive nearshore wave conditions throughout
                        Atlantic Canada.
                      </Typography>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        The models were developed by CBCL using DHI MIKE21 FM SW module. Model bathymetry was based on
                        CHS-NONNA, supplemented with CHS nautical charts. Boundary conditions were based on extreme
                        value analysis of wind and waves from the MSC50 dataset.
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Model Results are presented for display purposes only. Model results should not be used for
                        local assessments and to inform engineering design, due to potential inaccuracies.
                      </Typography>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        CBCL and DHI assume no liability for the accuracy of results and applicable. Please refer to the
                        Terms & Conditions for more information.
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem", ml: "-0.5rem" }} disableRipple>
                    <ReportProblemOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>
              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <DropdownSelect
                  label="Variable"
                  value={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
                  onChange={handleChangeLayer}
                  options={
                    dataSetLayers
                      ? dataSetLayers.map((l: any) => {
                          return { label: l.schemeId, value: l.schemeId };
                        })
                      : []
                  }
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <DropdownSelect
                  label="Direction"
                  value={Directions[selectedDimensionIndex]}
                  onChange={handleChangeDimension}
                  options={Directions.map((d: any) => {
                    return { label: d, value: d };
                  })}
                />
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <DropdownSelect
                  label="AEP (%)"
                  value={AEP[selectedAEPIndex]}
                  onChange={handleChangeWaveAEP}
                  options={AEP.map((d: any) => {
                    return { label: d, value: d };
                  })}
                />
              </Grid>
            </Grid>

            <ScaleBar
              tilingScheme={dataSetMetadata?.tilingSchemes[selectedLayerIndex]}
              schemeId={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
            />
          </LegendContainer>
          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
}
