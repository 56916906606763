import {
  AppWrapper,
  Header,
  Main,
  Article,
  Footer,
} from "../assets/layout_system";
import Navigation from "../components/Navigation/Navigation";
import { FooterComponent } from "../components/Footer/FooterComponent";
import { FrontpageDataList } from "../components/FrontPageComponents/FrontpageDataList";

function FrontPage() {
  return (
    <AppWrapper className="footer">
      <Header>
        <Navigation />
      </Header>
      <Main className="fullWidth">
        <Article className="fullWidth">
          <FrontpageDataList />
        </Article>
      </Main>
      <FooterComponent />
    </AppWrapper>
  );
}

export default FrontPage;
