import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PolygonCoordinatesState {
  coordinates: number[][] | null;
}

const initialState: PolygonCoordinatesState = {
  coordinates: null,
};

const polygonCoordinatesSlice = createSlice({
  name: "polygon",
  initialState,
  reducers: {
    setPolygonCoordinates: (state, action: PayloadAction<number[][]>) => {
      state.coordinates = action.payload;
    },
    clearPolygonCoordinates: (state) => {
      state.coordinates = null;
    },
  },
});

export const { setPolygonCoordinates, clearPolygonCoordinates } = polygonCoordinatesSlice.actions;

export default polygonCoordinatesSlice.reducer;
