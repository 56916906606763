import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxProcessFileSliceState {
  value: boolean;
}

const initialState: ToolboxProcessFileSliceState = {
  value: false,
};

export const ToolboxProcessFileSlice = createSlice({
  name: "ProcessFile",
  initialState,
  reducers: {
    changeToolboxProcessFileState: (
      state,
      action: PayloadAction<ToolboxProcessFileSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxProcessFileSlice.reducer;

export const { changeToolboxProcessFileState } =
  ToolboxProcessFileSlice.actions;
