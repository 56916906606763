import { ToolboxMainPage } from "../ToolboxMainPage";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useState, useEffect, useMemo } from "react";
import { changeToolboxState } from "../../../features/ToolboxStateSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ToolboxBathymetryInfoModal } from "../ToolboxBathymetryInfoModal/ToolboxBathymetryInfoModal";
import { InterpolationBoundary } from "./InterpolationBoundary";
import { BathymetricData } from "./BathymetricData";
import { BathymetryResults } from "./BathymetryResults";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import BaseLayer from "ol/layer/Base";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { Vector as VectorSource } from "ol/source";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import {
  convertToGisVectorDataset,
  createPersonalProject,
  deleteBathymetryData,
  getConvertedDatasetId,
  getSubProjectList,
} from "../../../api/backend";
import { RootState } from "../../../store/store";
import { getAllBathymetryDatasets, getGisVectorData, getMetadata } from "../../../api/backend_public";
import { platform, publicDataSets } from "../../../api/init";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  setBathymetryGenerateStatisticsClicked,
  setBathymetryJobId,
  setBathymetryToolboxFileUID,
  setBathymetryToolboxUploadCompleted,
  setBathymetryToolboxUploadPressed,
} from "../../../features/ToolboxFeatures/BathymetryToolboxSlice";
import {
  changeBathyButtonSelected,
  changeBathyInterpRefreshState,
  changeBathymetryInterpolationStatus,
} from "../../../features/ToolboxBathymetryInterpolationImportSlice";
import GeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";
import { Geometry, Point } from "ol/geom";
import WebGLPointsLayer from "ol/layer/WebGLPoints.js";
import OverlayPopup from "ol-ext/overlay/Popup";
import Select from "ol/interaction/Select";
import { singleClick } from "ol/events/condition";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface searchParamsProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

export const ToolBoxBathymetryInterpolation = ({ searchParams, setSearchParams }: searchParamsProps) => {
  const [processedFiles, setProcessedFiles] = useState<any[]>([]);
  const [interpolatedFiles, setInterpolatedFiles] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const bathymetryInterpolationStatus = useSelector(
    (state: RootState) => state.bathymetryInterpolationImport.isImportCompleted
  );
  const refresh = useSelector((state: RootState) => state.bathymetryInterpolationImport.refresh);
  const toolboxDataRights = useSelector((state: RootState) => state.toolboxRights.value);
  const map = useContext(OpenlayersContext);
  const [polygonOnMap, setPolygonOnMap] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [gridResolution, setGridResolution] = useState(10);
  const [convertedDatasetId, setConvertedDatasetId] = useState("");
  const [convertedProjectId, setConvertedProjectId] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [fileMetadatas, setFileMetadatas] = useState(new Map());
  const generateStatisticsClicked = useSelector(
    (state: RootState) => state.bathymteryToolbox.generateStatisticsClicked
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [processSchema, setProcessSchema] = useState("");
  const [processResponse, setProcessResponse] = useState<any>(null);
  const [processLog, setProcessLog] = useState<string | null>(null);
  const jobId = useSelector((state: RootState) => state.bathymteryToolbox.jobId);
  const isUploadPressed = useSelector((state: RootState) => state.bathymteryToolbox.isBathymetryUploadPressed);
  const fileUID = useSelector((state: RootState) => state.bathymteryToolbox.fileBathymetryUID);
  const { isAuthenticated } = useOidc();
  const isUploadCompleted = useSelector((state: RootState) => state.bathymteryToolbox.isBathymetryUploadCompleted);
  const [convertingData, setConvertingData] = useState<any[]>([]);
  const [convertedBathyProjectID, setConvertedBathyProjectID] = useState("");
  const [convertedBathyDatasetId, setConvertedBathyDatasetId] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [layerVisibility, setLayerVisibility] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (processLog?.includes("The grid resolution is very big, no grid is created!")) {
      enqueueSnackbar(
        "There is no resulting data within the boundary extent. Consider decreasing the grid resolution.",
        {
          variant: "error",
          autoHideDuration: 8000,
        }
      );
    }
  }, [processLog]);

  useEffect(() => {
    let uploadSnackbarKey: any = null;
    if (isUploadPressed && !isUploadCompleted) {
      uploadSnackbarKey = enqueueSnackbar("Uploading Dataset...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }

    if (isUploadCompleted) {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
      enqueueSnackbar("Upload successful!", {
        variant: "success",
        autoHideDuration: 4000,
      });
      dispatch(setBathymetryToolboxUploadPressed(false));
      dispatch(setBathymetryToolboxUploadCompleted(false));
    }
    return () => {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
    };
  }, [isUploadPressed, isUploadCompleted, enqueueSnackbar, closeSnackbar, dispatch]);

  useEffect(() => {
    (async () => {
      if (fileUID !== "") {
        const dataset = await getConvertedDatasetId(fileUID);
        setConvertedBathyProjectID(dataset.importResults[0].projectId);
        setConvertedBathyDatasetId(dataset.importResults[0].datasetId);
      }
    })();
  }, [fileUID]);

  useEffect(() => {
    if (convertedBathyProjectID !== "") {
      (async () => {
        const { data } = await platform.getJson(`metadata/project/${convertedBathyProjectID}/dataset/list`, "3");

        setData(data);
      })();
    }
  }, [processedFiles, fileUID, convertedBathyProjectID]);

  useEffect(() => {
    if (convertedBathyProjectID !== "") {
      (async () => {
        const { data } = await platform.getJson(`conversion/transfer/list?projectId=${convertedBathyProjectID}`, "2");

        let transferPending = data.every((t: any) => t.status !== "Pending");
        if (!transferPending) {
          const filteredData = data.filter((data: any) => data.status === "Pending");
          setConvertingData(filteredData);
        }
      })();
    }
    return () => {};
  }, [processedFiles, fileUID, convertedBathyProjectID]);

  useEffect(() => {
    let convertingSnackbarKey: any = null;

    if (convertingData && convertingData.length > 0) {
      convertingSnackbarKey = enqueueSnackbar("Converting dataset...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }
    const refreshIntervalId = setInterval(async () => {
      for (const item of convertingData) {
        const convertingItem = await platform.getJson(`conversion/transfer/${item.id}`, "2");

        if (convertingItem.status === "Completed" || convertingItem.status === "Error") {
          if (convertingSnackbarKey !== null) {
            closeSnackbar(convertingSnackbarKey);
          }

          let message = "Conversion successful!";
          let severity: "success" | "error" = "success";
          let autohideduration: number = 4000;

          if (convertingItem.status === "Error") {
            message = `Error occurred during conversion. ${convertingItem.errorMessage || ""}`;
            severity = "error";
            autohideduration = 4000;
          }
          enqueueSnackbar(message, {
            variant: severity,
            autoHideDuration: autohideduration,
          });
          if (convertingItem.status === "Completed") {
            dispatch(changeBathyInterpRefreshState(true));
            await deleteBathymetryData(convertedBathyDatasetId);
          }

          setConvertedBathyProjectID("");
          setConvertedBathyDatasetId("");

          setConvertingData(convertingData.filter((a) => a.id !== convertingItem.id));

          const { data } = await platform.getJson(`metadata/project/${convertedBathyProjectID}/dataset/list`, "3");
          setData(data);
        }
      }
    }, 2000);

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 12000000);

    return () => {
      clearInterval(refreshIntervalId);
      if (convertingSnackbarKey !== null) {
        closeSnackbar(convertingSnackbarKey);
      }
    };
  }, [convertingData, convertedBathyProjectID, processedFiles, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (convertedBathyProjectID !== "" && convertedBathyDatasetId !== "") {
      const convertDatasetTo = async () => {
        const fileToConvert = processedFiles.find(
          (file) => file.projectId === convertedBathyProjectID && file.id === convertedBathyDatasetId
        );

        if (fileToConvert && fileToConvert.fileDataFormat === "XYZ (.xyz)") {
          const result = await convertToGisVectorDataset(
            "XyzReader",
            fileToConvert,
            fileToConvert.id,
            fileToConvert.projectId,
            fileToConvert.fileCRSId
          );
          dispatch(setBathymetryToolboxFileUID(""));
          setConvertingData([...(convertingData ? convertingData : []), result]);
        } else if (fileToConvert && fileToConvert.fileDataFormat === "Point Shapefile (.shp)") {
          const result = await convertToGisVectorDataset(
            "ShpReader",
            fileToConvert,
            fileToConvert.id,
            fileToConvert.projectId,
            fileToConvert.fileCRSId
          );
          dispatch(setBathymetryToolboxFileUID(""));
          setConvertingData([...(convertingData ? convertingData : []), result]);
        }
      };
      convertDatasetTo();
    }
  }, [processedFiles, convertedBathyProjectID]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const droppableId = useMemo(() => uuidv4(), []);

  const handleGridResolutionValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGridResolution(Number(e.target.value));
  };

  const handleInfoModalClose = () => setInfoModalOpen(false);

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };

  const fetchMetadataForFiles = (files: any) => {
    return Promise.all(
      files.map(async (file: any) => {
        const metadata = await getMetadata(file.id);
        fileMetadatas.set(file.id, metadata);
      })
    );
  };
  useEffect(() => {
    let uploadSnackbarKey: any = null;
    if (generateStatisticsClicked && !processLog) {
      uploadSnackbarKey = enqueueSnackbar("Generating bathymetry...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "#FFFFFF" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }

    if (processLog) {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
      if (processResponse.statusMessage === "Output ready") {
        enqueueSnackbar("Successfully generated bathymetry model", {
          variant: "success",
          autoHideDuration: 4000,
        });
      } else {
        enqueueSnackbar("Error generating bathymetry model", {
          variant: "error",
          autoHideDuration: 4000,
        });
      }
    }
    return () => {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
    };
  }, [generateStatisticsClicked, processLog, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    const fetchCloudData = async () => {
      try {
        let combinedData: any[] = [];

        const result1 = await getAllBathymetryDatasets(publicDataSets.PrivateTemporaryBathymetry.id);

        if (result1.data.length > 0) {
          const filteredData1 = result1.data.filter(
            (dataset: any) => dataset.relativePath === `${idTokenPayload.oid}/`
          );

          combinedData = [...combinedData, ...filteredData1];
        }

        if (combinedData.length > 0) {
          await fetchMetadataForFiles(combinedData);
          const updatedProcessedFiles = await Promise.all(
            combinedData.map(async (file) => {
              return {
                ...file,
                ...fileMetadatas.get(file.id)?.metadata,
                fileDescription: fileMetadatas.get(file.id)?.description,
                id: file.id,
              };
            })
          );

          const newProcessedFiles = updatedProcessedFiles.filter(
            (file: any) => file.name !== "geojson.json" && file.name !== "geojson (converted)"
          );
          const interpolatedFiles = updatedProcessedFiles.filter((file: any) =>
            file.name.includes("interpolated_file")
          );

          setInterpolatedFiles(interpolatedFiles);
          setProcessedFiles(newProcessedFiles);
          dispatch(changeBathyInterpRefreshState(false));
          dispatch(changeBathymetryInterpolationStatus(false));
        }
      } catch (error) {
        console.error("Error fetching cloud data:", error);
      }
    };
    if (isAuthenticated) {
      if (refresh || bathymetryInterpolationStatus || processedFiles.length === 0) {
        fetchCloudData();
      }
    }
  }, [bathymetryInterpolationStatus, refresh, processedFiles.length]);

  const handleFileDelete = (fileIndex: number, name: string, checked: boolean, rights: string) => {
    const newProcessedFiles = processedFiles.filter((_, index) => index !== fileIndex);
    setProcessedFiles(newProcessedFiles);

    getAllBathymetryDatasets(publicDataSets.PrivateTemporaryBathymetry.id)
      .then((result) => {
        const data = result.data;
        const matchingDataset = data.find((dataset: any) => dataset.name === name);
        if (matchingDataset) {
          return deleteBathymetryData(matchingDataset.id);
        } else {
          return null;
        }
      })
      .then((deleteResult) => {})
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    setProcessedFiles((prevFiles) => {
      const newFiles = Array.from(prevFiles);
      const [reorderedItem] = newFiles.splice(result.source.index, 1);
      newFiles.splice(result.destination.index, 0, reorderedItem);
      return newFiles;
    });
  };

  const { idTokenPayload } = useOidcIdToken();
  useEffect(() => {
    if (!isAuthenticated) return;

    const projectIdBasedOnRights = checked
      ? toolboxDataRights === "Public"
        ? publicDataSets.PublicUserUpload.id
        : toolboxDataRights === "Private"
        ? publicDataSets.PrivateUserUpload.id
        : publicDataSets.PrivateTemporaryBathymetry.id
      : publicDataSets.PrivateTemporaryBathymetry.id;

    (async () => {
      try {
        const subprojectlist = await getSubProjectList(projectIdBasedOnRights);
        const hasPersonalFolder = subprojectlist.data.filter((p: any) => p.name === idTokenPayload.oid);
        if (hasPersonalFolder.length === 0) {
          await createPersonalProject(idTokenPayload.oid, projectIdBasedOnRights);
        }
      } catch (error) {
        console.error("Error in useEffect: ", error);
      }
    })();

    return () => {};
  }, [isAuthenticated, checked, toolboxDataRights]);

  useEffect(() => {
    if (processedFiles.length > 0) {
      const bathymetryDatasetIds = processedFiles
        .filter((file) => file.name.includes("(converted)"))
        .map((file) => (file.mainDatasetId ? file.mainDatasetId : file.id));

      const bathymetryIdsString = bathymetryDatasetIds.map((id) => `"${id}"`).join(",\n  ");

      const commandString = `python main.py '{"projectId": "${convertedProjectId}", "boundary_file": "${convertedDatasetId}","grid_resolution": ${gridResolution},
  "grid_resolution_unit": "m","env":"${publicDataSets.env}","bathymetry": [${bathymetryIdsString}]}'`;

      const processSchemaValue = JSON.stringify(
        {
          Runtime: {
            type: "ContainerRuntimeSpec",
            Containers: [
              {
                Image: publicDataSets.dockerInterpolationImage,
                Command: ["/bin/sh", "-c", commandString],
              },
            ],
          },
        },
        undefined,
        2
      );
      setProcessSchema(processSchemaValue);
      // setConvertedDatasetId("");
    }
  }, [processedFiles, convertedDatasetId, gridResolution]);

  const handleGenerateStatistics = async () => {
    dispatch(setBathymetryGenerateStatisticsClicked(true));
    setProcessResponse(null);
    setProcessLog(null);

    if (processSchema) {
      const data = await platform.postJson("process/job", JSON.parse(processSchema), "3");
      dispatch(setBathymetryJobId(data.jobId));
      const refreshIntervalId = setInterval(async function () {
        const convertingItem = await platform.getJson(`process/job/${data.jobId}`, "3");
        if (convertingItem.jobState === "Running") {
          setProcessResponse(convertingItem);
        }
        if (convertingItem.jobState === "Finished" || convertingItem.jobState === "Error") {
          setProcessResponse(convertingItem);
          clearInterval(refreshIntervalId);

          const convertingLog = await platform.getJson(`process/job/${data.jobId}/log`, "3");
          setProcessLog(convertingLog.logs[0].log);
          dispatch(setBathymetryGenerateStatisticsClicked(false));

          dispatch(setBathymetryJobId(""));
          dispatch(changeBathyInterpRefreshState(true));
        }
      }, 1000);

      setTimeout(() => {
        clearInterval(refreshIntervalId);
      }, 12000000);

      setProcessResponse(data);
    }
  };

  useEffect(() => {
    function checkForLayer() {
      const layersCollection = map?.current?.getLayers();
      if (layersCollection) {
        const layers = layersCollection.getArray();
        if (layers) {
          const foundLayer = layers.find((layer) => layer.get("customLayerName") === "customPolygonLayer");
          setPolygonOnMap(!!foundLayer);
        } else {
          setPolygonOnMap(false);
        }
      } else {
        setPolygonOnMap(false);
      }
    }
    checkForLayer();
    const intervalId = setInterval(checkForLayer, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [map]);

  const removeAllCustomPolygonLayers = () => {
    if (!map?.current) return;
    const layersToRemove: BaseLayer[] = [];
    map.current.getLayers().forEach((layer: BaseLayer) => {
      const customLayerName = layer.get("customLayerName");
      const customId = layer.get("customId");
      const id = layer.get("id");
      const resultId = layer.get("resultId");

      if (customLayerName === "customPolygonLayer") {
        layersToRemove.push(layer);
      } else if (customId === "drawnPolygon") {
        layersToRemove.push(layer);
      } else if (typeof id === "string" && id.startsWith("gislayers")) {
        layersToRemove.push(layer);
      } else if (resultId === "resultGisLayer") {
        layersToRemove.push(layer);
      }
    });

    layersToRemove.forEach((layer) => {
      map.current?.removeLayer(layer);
    });
  };
  const [layerExtents, setLayerExtents] = useState<any>({});

  useEffect(() => {
    const getVectorSource = async (childId: string) => {
      const gisData = await getGisVectorData(childId);

      const features = new GeoJSON().readFeatures(gisData);
      const validFeatures = features.map((feature) => feature as Feature<Geometry>);

      const vectorSource = new VectorSource({
        features: validFeatures,
      });
      const extent = vectorSource.getExtent();
      setLayerExtents((prevExtents: any) => ({ ...prevExtents, [childId]: extent }));
    };

    processedFiles
      .filter((file) => {
        const tableElementData = {
          ...file,
          ...fileMetadatas.get(file.id)?.metadata,
          fileDescription: fileMetadatas.get(file.id)?.description,
          id: file.id,
        };

        const requiredMetadataFields = [
          "fileCRS",
          "fileDataFormat",
          "fileDataType",
          "fileDatum",
          "fileXCoordinate",
          "fileYCoordinate",
        ];

        const isMetadataComplete = requiredMetadataFields.every((field) => field in tableElementData);
        const isConverted = file.name.includes("(converted)");
        return isMetadataComplete && isConverted;
      })
      .map((file) => {
        const tableElementData = {
          ...file,
          id: file.id,
        };
        getVectorSource(tableElementData.mainDatasetId ? tableElementData.mainDatasetId : tableElementData.id);
      });
  }, [processedFiles]);

  const createGisVectorLayer = async (childId: string) => {
    const gisData = await getGisVectorData(childId);

    const features = new GeoJSON().readFeatures(gisData);
    const validFeatures = features.map((feature) => feature as Feature<Geometry>);

    const vectorSource = new VectorSource({
      features: validFeatures,
    });
    const extent = vectorSource.getExtent();
    setLayerExtents((prevExtents: any) => ({ ...prevExtents, [childId]: extent }));
    const webGLLayer = new WebGLPointsLayer({
      source: vectorSource,
      style: {
        "circle-radius": 5,
        "circle-fill-color": [
          "interpolate",
          ["linear"],
          ["get", "Z"],
          -500,
          "rgb(63, 47, 129)",
          -470,
          "rgb(44,76,142)",
          -430.5,
          "rgb(33,117,150)",
          -390,
          "rgb(64,152,136)",
          -337.7,
          "rgb(131,184,132)",
          -290,
          "rgb(171, 200, 124)",
          -245,
          "rgb(218, 215, 152)",
          -200,
          "rgb(252, 248, 200)",
          -152.3,
          "rgb(252, 196, 140)",
          -100,
          "rgb(254, 149, 84)",
          -59.5,
          "rgb(209, 83, 41)",
          -20,
          "rgb(178, 24, 43)",
          10,
          "rgb(178, 24, 43)",
        ],
      },
    });

    const select = new Select({
      layers: [webGLLayer],
      condition: singleClick,
    });
    map?.current?.addInteraction(select);

    const popup = new OverlayPopup({
      popupClass: "default",
      closeBox: true,
      positioning: "auto",
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    map?.current?.addOverlay(popup);

    select.on("select", (evt) => {
      const selectedFeature = evt.selected[0];
      if (selectedFeature) {
        const geometry = selectedFeature.getGeometry();
        if (geometry instanceof Point) {
          const coordinate = geometry.getCoordinates();
          const zValue = selectedFeature.get("Z");
          popup.show(coordinate, `<div style="color: black;">Z Value: ${zValue}</div>`);
        }
      } else {
        popup.hide();
      }
    });

    return webGLLayer;
  };

  const addGisLayerToMap = async (dataset: string) => {
    if (dataset) {
      const newGisLayer = await createGisVectorLayer(dataset);
      if (newGisLayer) {
        newGisLayer.set("id", `gislayers${dataset}`);
        map?.current?.addLayer(newGisLayer);
      }
    }
  };

  const removeGisLayerFromMap = (dataset: string) => {
    if (!map?.current) return;

    const layerId = `gislayers${dataset}`;
    const layersArray = map.current.getLayers().getArray();
    const layerToRemove = layersArray.find((layer) => layer.get("id") === layerId);

    if (layerToRemove) {
      map.current.removeLayer(layerToRemove);
      setLayerVisibility((prevState) => ({
        ...prevState,
        [dataset]: false,
      }));
    }
  };
  const handleVisibilityIconClick = (dataset: string) => {
    const isVisible = layerVisibility[dataset];

    if (isVisible) {
      removeGisLayerFromMap(dataset);
    } else {
      addGisLayerToMap(dataset);
    }

    setLayerVisibility((prev) => ({
      ...prev,
      [dataset]: !isVisible,
    }));
  };

  const handleZoomTo = (datasetId: string) => {
    const extent = layerExtents[datasetId];
    if (!extent) {
      alert("Display the data on the map first");
      handleClose();
      return;
    }
    if (map?.current && extent) {
      map.current.getView().fit(extent, {
        padding: [100, 100, 100, 100],
        duration: 1000,
      });
    }
    handleClose();
  };

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setBathymetryGenerateStatisticsClicked(false));
    }
  };

  return (
    <Box>
      <ToolboxMainPage
        value={
          <Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap="0.5rem">
                <IconButton
                  sx={{
                    color: "secondary.dark",
                    height: "1rem",
                    width: "1rem",
                    mt: "1rem",
                    ml: "1rem",
                  }}
                  disableRipple
                  onClick={() => {
                    dispatch(changeToolboxState({ value: "toolbox" }));
                    searchParams.delete("q");
                    setSearchParams(searchParams);
                    dispatch(changeBathyButtonSelected(""));
                    removeAllCustomPolygonLayers();
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box mt="0.5rem">
                  <img
                    src="/toolboxes/Bathymetry interpolation.svg"
                    alt="icon"
                    style={{ width: "3rem", height: "2.5rem" }}
                  />
                </Box>
                <Box mt="0.5rem" ml="-0.25rem">
                  <Typography variant="body1" fontWeight="700" color="secondary.dark">
                    Bathymetry Interpolation
                  </Typography>
                  <Typography variant="caption" color="grey.500">
                    Transform hydrographic surveys to seamless Digital Elevation Model
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                mt="0.5rem"
                mr="1rem"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleInfoModalOpen}
              >
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.85rem">
                  Info
                </Typography>
                <IconButton disableRipple sx={{ color: "secondary.dark" }}>
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ height: "calc(100vh - 7rem)", overflowY: "auto" }}>
              <ListItemButton onClick={handleClick} sx={{ height: "2.5rem", mt: "0.5rem" }} disableRipple>
                <ListItemText
                  primary={
                    <Typography variant="body2" color="secondary.dark" fontWeight="700">
                      Inputs
                    </Typography>
                  }
                />

                <ExpandMore
                  style={{
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                    color: "#0B4566",
                  }}
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto">
                <List component="div" disablePadding>
                  <InterpolationBoundary
                    setPolygonOnMap={setPolygonOnMap}
                    setConvertedDatasetId={setConvertedDatasetId}
                    setConvertedProjectId={setConvertedProjectId}
                    convertedProjectId={convertedProjectId}
                    generateStatisticsClicked={generateStatisticsClicked}
                  />
                  <Box ml="1rem" mt="0.75rem">
                    {polygonOnMap ? (
                      <Typography variant="body2" color="secondary.dark">
                        Boundary has been defined on the map
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="secondary.dark">
                        Import, draw on the map or use the current map extent to define a boundary
                      </Typography>
                    )}
                  </Box>
                  <BathymetricData
                    checked={checked}
                    setChecked={setChecked}
                    generateStatisticsClicked={generateStatisticsClicked}
                  />

                  <Box
                    display="grid"
                    gridTemplateColumns={processedFiles.length > 0 ? "1fr 8fr" : "1fr"}
                    maxHeight="25rem"
                    sx={{ overflowY: "auto" }}
                  >
                    {processedFiles.length > 0 && (
                      <Box ml="1rem" mt="5rem">
                        {processedFiles
                          .filter(
                            (file) =>
                              file.name.includes("(converted)") &&
                              file.fileDescription !== "geojson coordinates file for bathymetry interpolation"
                          )
                          .map((file, index) => (
                            <Box
                              bgcolor="#FCE05D"
                              sx={{
                                p: "4px 10px",
                                width: "4.5rem",
                                mb: "1.25rem",
                                borderRadius: "0.25rem",
                              }}
                              key={index}
                            >
                              <Typography variant="caption" color="secondary.dark">{`Priority ${
                                index + 1
                              }`}</Typography>
                            </Box>
                          ))}
                      </Box>
                    )}

                    <Box mt="1rem" ml="0.5rem" mr="2rem" overflow="hidden">
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        {processedFiles.length > 0 ? (
                          <Droppable droppableId={droppableId}>
                            {(provided, snapshot) => (
                              <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  border: "2px solid #CFDBE2",
                                }}
                                maxWidth="100%"
                                boxShadow="0px 0px 2px 0px gray"
                                borderRadius="0.5rem"
                                ml="0.5rem"
                                overflow="hidden"
                              >
                                <Box display="grid" gridTemplateColumns="3fr 1.4fr 2.2fr" p="1rem 0.5rem" mb="-0.5rem">
                                  <Box display="flex" flexDirection="row">
                                    <IconButton
                                      disableRipple
                                      sx={{
                                        color: "secondary.dark",
                                        "&:hover": { cursor: "default" },
                                        mt: "-0.5rem",
                                      }}
                                    >
                                      <FileCopyOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    <Typography variant="body2" color="secondary.dark" fontWeight="700">
                                      Data Name
                                    </Typography>
                                  </Box>
                                  <Typography variant="body2" color="secondary.dark" fontWeight="700" ml="-1rem">
                                    Data Source
                                  </Typography>
                                  <Typography variant="body2" color="secondary.dark" fontWeight="700" ml="0.25rem">
                                    Rights
                                  </Typography>
                                </Box>
                                <Divider />
                                {processedFiles
                                  .filter((file) => {
                                    const tableElementData = {
                                      ...file,
                                      ...fileMetadatas.get(file.id)?.metadata,
                                      fileDescription: fileMetadatas.get(file.id)?.description,
                                      id: file.id,
                                    };

                                    const requiredMetadataFields = [
                                      "fileCRS",
                                      "fileDataFormat",
                                      "fileDataType",
                                      "fileDatum",
                                      "fileXCoordinate",
                                      "fileYCoordinate",
                                    ];

                                    const isMetadataComplete = requiredMetadataFields.every(
                                      (field) => field in tableElementData
                                    );
                                    const isConverted = file.name.includes("(converted)");
                                    return isMetadataComplete && isConverted;
                                  })
                                  .map((file, index) => {
                                    const tableElementData = {
                                      ...file,
                                      id: file.id,
                                    };

                                    return (
                                      <Box key={tableElementData.name}>
                                        <Draggable draggableId={tableElementData.name} index={index}>
                                          {(provided, snapshot) => (
                                            <Box
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              style={{
                                                ...provided.draggableProps.style,
                                                boxShadow: snapshot.isDragging ? "0px 0px 5px 0px gray" : "",
                                                background: snapshot.isDragging ? "white" : "",
                                              }}
                                              maxWidth="100%"
                                            >
                                              <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Box display="grid" gridTemplateColumns="3.5fr 1.5fr 2fr" width="100%">
                                                  <Box display="flex" flexDirection="row">
                                                    <IconButton
                                                      disableRipple
                                                      {...provided.dragHandleProps}
                                                      sx={{
                                                        color: "secondary.dark",
                                                      }}
                                                    >
                                                      <DragHandleIcon />
                                                    </IconButton>
                                                    <Typography
                                                      key={index}
                                                      variant="body2"
                                                      color="secondary.dark"
                                                      p="1rem 0.5rem"
                                                      ml="-0.5rem"
                                                    >
                                                      {tableElementData.name}
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      variant="body2"
                                                      color="secondary.dark"
                                                      mt="1rem"
                                                      ml="1.6rem"
                                                    >
                                                      Local Device
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      variant="body2"
                                                      color="secondary.dark"
                                                      mt="1rem"
                                                      ml="3.9rem"
                                                    >
                                                      {`${tableElementData.rights
                                                        .charAt(0)
                                                        .toUpperCase()}${tableElementData.rights
                                                        .slice(1)
                                                        .toLowerCase()}`}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box display="flex" flexDirection="row">
                                                  <IconButton
                                                    sx={{ color: "secondary.dark" }}
                                                    disableRipple
                                                    onClick={() =>
                                                      handleVisibilityIconClick(
                                                        tableElementData.mainDatasetId
                                                          ? tableElementData.mainDatasetId
                                                          : tableElementData.id
                                                      )
                                                    }
                                                  >
                                                    {layerVisibility[
                                                      tableElementData.mainDatasetId
                                                        ? tableElementData.mainDatasetId
                                                        : tableElementData.id
                                                    ] ? (
                                                      <VisibilityIcon />
                                                    ) : (
                                                      <VisibilityOffIcon />
                                                    )}
                                                  </IconButton>
                                                  <IconButton
                                                    disableRipple
                                                    sx={{
                                                      color: "secondary.dark",
                                                    }}
                                                    onClick={() =>
                                                      handleFileDelete(
                                                        index,
                                                        tableElementData.name,
                                                        tableElementData.checked,
                                                        tableElementData.rights
                                                      )
                                                    }
                                                  >
                                                    <DeleteOutlineOutlinedIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    disableRipple
                                                    sx={{
                                                      color: "secondary.dark",
                                                      mr: "0.5rem",
                                                    }}
                                                    id="zoom-button"
                                                    aria-controls={openMenu ? "zoom-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? "true" : undefined}
                                                    onClick={handleMenuClick}
                                                  >
                                                    <MoreVertIcon />
                                                  </IconButton>
                                                  <Menu
                                                    id="zoom-menu"
                                                    anchorEl={anchorEl}
                                                    open={openMenu}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                      "aria-labelledby": "zoom-button",
                                                    }}
                                                  >
                                                    <MenuItem
                                                      onClick={() =>
                                                        handleZoomTo(
                                                          tableElementData.mainDatasetId
                                                            ? tableElementData.mainDatasetId
                                                            : tableElementData.id
                                                        )
                                                      }
                                                    >
                                                      <Typography variant="body2" color="secondary.dark">
                                                        Zoom To
                                                      </Typography>
                                                    </MenuItem>
                                                  </Menu>
                                                </Box>
                                              </Box>
                                            </Box>
                                          )}
                                        </Draggable>
                                        {index === processedFiles.length - 1 ? null : <Divider />}
                                      </Box>
                                    );
                                  })}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        ) : (
                          <Typography color="secondary.dark" variant="body2" ml="0.5rem">
                            Select or upload bathymetric data to visualize and prioritize it
                          </Typography>
                        )}
                      </DragDropContext>
                    </Box>
                  </Box>

                  <Box pl="1rem" mt="0.75rem" display="flex" flexDirection="row" gap="0.25rem">
                    <Typography variant="body1" color="secondary.dark" fontWeight="700" mt="0.65rem">
                      Interpolation
                    </Typography>
                    <LightTooltip
                      title={
                        <>
                          <Typography variant="body2" color="secondary.dark" mb="0.5rem">
                            Interpolation is completed on a grid, with the specified resolution. Linear interpolation is
                            used. The tool will not extrapolate results outside of the limits of the data.
                          </Typography>

                          <Typography variant="body2" color="secondary.dark">
                            Note that if the grid resolution is too large, relative to the size of the interpolation
                            boundary and the limits of data, the toolbox may not result in any data.
                          </Typography>
                        </>
                      }
                      placement="right"
                      enterDelay={500}
                      leaveDelay={200}
                    >
                      <IconButton
                        disableRipple
                        sx={{
                          color: "secondary.dark",
                          "&:hover": { cursor: "default" },
                        }}
                      >
                        <HelpOutlineOutlinedIcon />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                  <Box ml="1rem" mt="1rem" display="flex" flexDirection="row" justifyContent="space-between">
                    <TextField
                      label="Grid resolution"
                      type="number"
                      variant="outlined"
                      size="small"
                      value={gridResolution}
                      onChange={handleGridResolutionValue}
                      InputLabelProps={{
                        style: {
                          color: "#86A2B3",
                        },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#86A2B3",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#86A2B3",
                        },
                      }}
                    />
                    <Box>
                      {generateStatisticsClicked && (
                        <LightTooltip title="Stop interpolation" placement="left" enterDelay={500} leaveDelay={200}>
                          <IconButton disableRipple sx={{ color: "red", mt: "-0.5rem" }} onClick={stopInterpolation}>
                            <StopCircleOutlinedIcon fontSize="large" />
                          </IconButton>
                        </LightTooltip>
                      )}
                      <LightTooltip
                        title="Login to use the toolbox"
                        placement="left"
                        enterDelay={500}
                        leaveDelay={200}
                        disableHoverListener={!!idTokenPayload}
                      >
                        <span>
                          <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                            disabled={
                              convertedProjectId === "" || processedFiles.length === 0 || interpolatedFiles.length > 0
                            }
                            sx={{ mr: "1rem", mb: "0.5rem", width: "10rem" }}
                            onClick={generateStatisticsClicked ? () => {} : handleGenerateStatistics}
                          >
                            {generateStatisticsClicked ? (
                              <>
                                <CircularProgress color="inherit" size="2rem" />
                              </>
                            ) : (
                              <Typography variant="body2" fontWeight="500">
                                Interpolate
                              </Typography>
                            )}
                          </Button>
                        </span>
                      </LightTooltip>
                    </Box>
                  </Box>
                </List>
              </Collapse>

              <Divider
                sx={{
                  mt: "0.5rem",
                  borderWidth: "1px",
                  borderColor: "grey.200",
                }}
              />
              <BathymetryResults interpolatedFiles={interpolatedFiles} setInterpolatedFiles={setInterpolatedFiles} />
            </Box>
          </Box>
        }
      />

      <ToolboxBathymetryInfoModal open={infoModalOpen} handleClose={handleInfoModalClose} />
    </Box>
  );
};
