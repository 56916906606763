import { IconButton, Typography, Box, Backdrop, Modal, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const AboutUsModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "95vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "54rem" },
          }}
        >
          <Box>
            <Box display="flex" flexDirection="row">
              <Box textAlign="center" width="100%">
                <Typography
                  variant="h2"
                  sx={{
                    mt: "1rem",
                    ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                    textAlign: "inherit",
                  }}
                >
                  About Us
                </Typography>
              </Box>
              <IconButton sx={{ mr: "2rem", mt: "1rem" }} disableRipple onClick={handleClose}>
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="center" width="100%" mt="1rem">
              <img src="logo/CBCL_Logo_BLK.jpg" alt="CBCL" style={{ width: "8rem", height: "4.5rem" }} />
              <img src="logo/DHI-removebg-preview.png" alt="DHI" style={{ width: "8rem", height: "5rem" }} />
            </Box>
            <Box m="1rem 2rem 1rem 2rem">
              <Typography variant="h2" fontWeight="700" color="secondary.dark" textAlign="center">
                The CCZIS is a comprehensive system that supports decision-making for marine and coastal infrastructure
                planning, design, and risk assessment, among other applications.
              </Typography>
            </Box>
            <Box m="1rem 2rem 0.5rem 2rem">
              <Typography variant="body1" fontWeight="700" color="secondary.dark" textAlign="center">
                Streamlining Coastal Infrastructure Management
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="secondary.dark" textAlign="center">
                The development of the CCZIS was initiated in response to a Challenge presented by Innovative Solutions
                Canada, sponsored by Public Services and Procurement Canada (PSPC). PSPC provides technical services,
                expert advice and strategic planning for coastal and marine infrastructure, erosion protection and flood
                mitigation projects to federal departments such as the Department of Fisheries and Oceans (DFO),
                Transport Canada, Parks Canada, and Indigenous Services Canada. Notably, DFO oversees approximately
                1,008 harbours nationwide, encompassing over 10,000 structures. The availability and reliability of
                coastal datasets across Canada pose significant challenges to PSPC in delivering services and managing
                its infrastructure. The CCZIS aims to provide PSPC and other users with quality-checked datasets,
                provide additional model results and analytics to fill existing data gaps, and provide tools to support
                planning, design, and management of marine and coastal infrastructure and services - all in a single,
                user-friendly, web-based application.
              </Typography>
            </Box>
            <Box m="1rem 2rem 0.5rem 2rem">
              <Typography variant="body1" fontWeight="700" color="secondary.dark" textAlign="center">
                From Concept to Functional Prototype
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="secondary.dark" textAlign="center">
                The first phase of the Challenge involved developing a proof-of-concept. The current iteration of the
                CCZIS web-application is the product of the second phase of the Challenge, which was aimed at developing
                and delivering a functional prototype of the system. This functional prototype aims to deliver essential
                features and demonstrate functionality. While complete datasets and features are currently accessible
                for the Atlantic region, partial features are available for the Great Lakes, Arctic, and West Coast
                regions.
              </Typography>
            </Box>

            <Box m="1rem 2rem 0.5rem 2rem">
              <Typography variant="body1" fontWeight="700" color="secondary.dark" textAlign="center">
                Review Phase and Future Development
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 1rem 2rem">
              <Typography variant="body2" color="secondary.dark" textAlign="center">
                The CCZIS prototype is presently undergoing a two-month review period, scheduled from 18 April to 18
                June 2024, marking the conclusion of Phase 2. Full datasets, features, and functionality will be made
                available in Phase 3, which is expected to occur in year 2025/2026.
              </Typography>
              <Typography variant="body2" color="secondary.dark" textAlign="center">
                The CCZIS is a collaborative effort between CBCL Ltd. and DHI Water and Environment Inc.
              </Typography>
            </Box>

            <Box m="1rem 2rem 0.5rem 2rem">
              <Typography variant="body1" fontWeight="700" color="secondary.dark" textAlign="center">
                Contact Us
              </Typography>
            </Box>
            <Box m="0.5rem 2rem 0.5rem 2rem">
              <Typography variant="body2" color="secondary.dark" textAlign="center">
                For inquiries regarding the CCZIS application, please reach out to:
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                  pb: "1rem",
                }}
              >
                <IconButton
                  disableRipple
                  sx={{
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                >
                  <MailOutlineOutlinedIcon fontSize="large" sx={{ color: "secondary.dark" }} />
                </IconButton>
                <Box>
                  <Typography variant="body2" color="secondary.dark" textAlign="center">
                    General Inquiries
                  </Typography>
                  <Typography variant="body2" color="secondary.dark" textAlign="center">
                    Email: <a href="mailto:info@cczis.ca?subject=CCZIS Inquiry">info@cczis.ca</a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
