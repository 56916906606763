import { Box, Typography } from "@mui/material";
import { WavesTabInputField } from "./WavesTabInputField";
import { WavesTabTable } from "./WavesTabTable";
import { WavesTabWaveRoseChart } from "./WavesTabWaveRoseChart";
import { WavesTabEVDChart } from "./WavesTabEVDChart";
import { useEffect, useState } from "react";
import { WavesTimeSeriesChart } from "./WavesTimeSeriesChart";
import { DashboardTabsHeadWaves } from "./DashboardTabsHeadWaves";
import {
  getGisVectorData,
  getMdDataSets,
  getRawData,
  getTimeSeriesGisQueryFeatures,
  getTimeSeriesGisRaw,
  getTimeSeriesGisValues,
  getTimeSeriesMD,
} from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";
import Papa from "papaparse";
import { Feature } from "ol";
import { Directions, AEP_SHORT } from "../../../assets/BoundaryConditions";

interface portProps {
  port: any;
}

export const DashboardWavesTab = ({ port }: portProps) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredRegionalData, setFilteredRegionalData] = useState<any>();
  const [directionValues, setDirectionValues] = useState<string[]>([]);
  const [file, setFiles] = useState<File[]>([]);
  const [returnPeriodValue, setReturnPeriodValue] = useState<string[]>([]);
  const [hhwlt, sethhwlt] = useState<any>();
  const [dataSourceValue, setDataSourceValue] = useState("MSC50");
  const [csvData, setCsvData] = useState<any[]>([]);
  const [rawTs, setRawTs] = useState<any[]>([]);
  const [rawHs, setRawHs] = useState<any[]>([]);
  const [evaData, setEvaData] = useState<unknown[]>([]);
  const [jotHsData, setJotHsData] = useState<unknown[]>([]);
  const [jotTpData, setJotTpData] = useState<unknown[]>([]);

  const [selectedFature, setSeletectFeature] = useState<Feature | null>(null);

  const getHHWLTValue = async () => {
    const hhwltData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const hhwltValue = hhwltData.features.filter(
      (item: any) => item.properties.Harbour_nu === port.get("Harbour_nu")
    )[0].properties;
    return hhwltValue;
  };

  const getRegionalData = async () => {
    return new Promise((resolve, reject) => {
      Papa.parse("data/Key_Locations_w_WaveInfo_DHIedits.csv", {
        header: true,
        download: true,
        dynamicTyping: true,
        complete: async function (results) {
          const selectedHarbour: any = results.data.filter((h: any) => h.Harbour_nu === port.get("Harbour_nu"));

          if (selectedHarbour.length === 0) return;

          const waveModel = await getMdDataSets(publicDataSets.WaveHeight.project, "_MD_TS");

          const timeseries = waveModel.data.filter((h: any) =>
            h.name.includes(selectedHarbour[0]["Model Domain (Converted)"])
          );

          if (timeseries.length === 0) return;

          const waveModelTimeSeries = await getTimeSeriesMD(
            timeseries[0].id,
            selectedHarbour[0].X_UTM20,
            selectedHarbour[0].Y_UTM20,
            127
          );

          resolve(waveModelTimeSeries);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  useEffect(() => {
    (async () => {
      const regionalData: any = await getRegionalData();
      setFilteredRegionalData(regionalData);
    })();
    return () => {};
  }, [port]);

  useEffect(() => {
    (async () => {
      const portProperties = await getHHWLTValue();
      if (!portProperties) return;
      sethhwlt(portProperties);
    })();
    return () => {
      sethhwlt(null);
    };
  }, [port]);

  useEffect(() => {
    setJotHsData([]);
    setJotTpData([]);
    setRawTs([]);
    setRawHs([]);
    setFiles([]);
  }, [dataSourceValue]);

  useEffect(() => {
    if (dataSourceValue === "MSC50") {
      const filtered = csvData.filter(
        (row) => directionValues.includes(row.Direction) && returnPeriodValue.includes(row.AEP)
      );
      setFilteredData(filtered);
    } else {
      if (filteredRegionalData) {
        const resultArray: any = [];
        directionValues.map((d: any) => {
          returnPeriodValue.map((p: any) => {
            resultArray.push({
              Direction: d,
              AEP: p,
              HS: filteredRegionalData.dataBlocks[0].data[Directions.indexOf(d) * 8 + AEP_SHORT.indexOf(p)],
              TP: filteredRegionalData.dataBlocks[1].data[Directions.indexOf(d) * 8 + AEP_SHORT.indexOf(p)],
            });
          });
        });

        if (directionValues.length === 1) {
          const resultChartArray: any = [];
          let countDirections = 0;
          for (let i = 0; i < filteredRegionalData.dataBlocks[0].data.length; i++) {
            if (i % 8 === 0) {
              countDirections++;
            }
            resultChartArray.push({
              Direction: Directions[countDirections - 1],
              AEP: AEP_SHORT[i % AEP_SHORT.length],
              HS: filteredRegionalData.dataBlocks[0].data[i],
              TP: filteredRegionalData.dataBlocks[1].data[i],
            });
          }

          setEvaData(resultChartArray);
        }
        if (directionValues.length === 0 && returnPeriodValue.length === 0) {
          setFilteredData([]);
          setEvaData([]);
        } else {
          setFilteredData(resultArray);
        }
      } else {
        setFilteredData([]);
        setEvaData([]);
      }
    }
  }, [csvData, directionValues, returnPeriodValue, dataSourceValue]);

  useEffect(() => {
    (async () => {
      if (port?.get("MSC_selec") === "" || dataSourceValue !== "MSC50") {
        setFiles([]);
        return;
      }

      const { features } = await getTimeSeriesGisQueryFeatures(
        publicDataSets.MSC50Dataset7e.id,
        port.get("MSC_selec"),
        "point"
      );

      if (!features) {
        return;
      }
      setSeletectFeature(features[0]);

      const datasetUrl = await getRawData(features[0].properties.eva);
      if (datasetUrl) {
        const response = await fetch(datasetUrl.data);
        const csvText = await response.text();
        const parsedData = Papa.parse(csvText, { header: true, skipEmptyLines: true });
        setCsvData(parsedData.data);
      }

      const rawEVA = await getTimeSeriesGisRaw(features[0].properties.eva);
      const rawEVAData = Papa.parse(rawEVA, { header: true });
      setEvaData(rawEVAData.data);
      const evaBlob = new Blob([rawEVA], { type: "text/csv" });
      const evaFile = new File([evaBlob], "M3010247_eva.csv");
      setFiles((prevFiles) => [...prevFiles, evaFile]);

      const rawJOThs = await getTimeSeriesGisRaw(features[0].properties.jot_HS_VMD);
      const rawJOThsData = Papa.parse(rawJOThs, { header: true });
      setJotHsData(rawJOThsData.data);
      const jotHSBlob = new Blob([rawJOThs], { type: "text/csv" });
      const jotHSFile = new File([jotHSBlob], "M3010247_jot_hs.csv");
      setFiles((prevFiles) => [...prevFiles, jotHSFile]);

      const rawJOTTP = await getTimeSeriesGisRaw(features[0].properties.jot_TP_VMD);
      const rawJOTTPData = Papa.parse(rawJOTTP, { header: true });
      setJotTpData(rawJOTTPData.data);
      const jotTPBlob = new Blob([rawJOTTP], { type: "text/csv" });
      const jotTPFile = new File([jotTPBlob], "M3010247_jot_tp.csv");
      setFiles((prevFiles) => [...prevFiles, jotTPFile]);

      const rawTS = await getTimeSeriesGisValues(
        publicDataSets.MSC50Dataset7d.id,
        features[0].properties.point + "_TP"
      );

      setRawTs(rawTS.data);
      const tsBlob = new Blob([Papa.unparse(rawTS)], { type: "text/csv" });
      const tsFile = new File([tsBlob], "M3010247_tp.csv");
      setFiles((prevFiles) => [...prevFiles, tsFile]);

      const rawHS = await getTimeSeriesGisValues(
        publicDataSets.MSC50Dataset7d.id,
        features[0].properties.point + "_HS"
      );

      setRawHs(rawHS.data);
      const hsBlob = new Blob([Papa.unparse(rawHS)], { type: "text/csv" });
      const hsFile = new File([hsBlob], "M3010247_hs.csv");
      setFiles((prevFiles) => [...prevFiles, hsFile]);
    })();

    return () => {};
  }, [dataSourceValue]);

  return (
    <Box>
      <DashboardTabsHeadWaves name="Waves" files={file} />

      <WavesTabInputField
        setFilteredData={setFilteredData}
        directionValues={directionValues}
        setDirectionValues={setDirectionValues}
        returnPeriodValue={returnPeriodValue}
        setReturnPeriodValue={setReturnPeriodValue}
        dataSourceValue={dataSourceValue}
        setDataSourceValue={setDataSourceValue}
      />
      <Box
        bgcolor="grey.50"
        height="calc(100vh - 16.5rem)"
        sx={{ overflowY: "scroll", overflowX: "hidden", p: "1rem" }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Box>
            <WavesTabTable
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              directionValues={directionValues}
              returnPeriodValue={returnPeriodValue}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Box ml="-1rem">
              <WavesTabEVDChart
                directionValues={directionValues}
                returnPeriodValue={returnPeriodValue}
                evaData={evaData}
              />
            </Box>
            <Box m="-3rem 1rem 1rem -1rem">
              <WavesTabWaveRoseChart jotHsData={jotHsData} jotTpData={jotTpData} />
            </Box>
            <Box m="-1rem" mt="-2rem">
              <WavesTimeSeriesChart
                port={selectedFature}
                dataSourceValue={dataSourceValue}
                rawTs={rawTs}
                rawHs={rawHs}
              />
            </Box>
          </Box>
        </Box>
        <Typography color="grey.500" variant="body2" mb="0.5rem" mt="1rem">
          Warnings:
        </Typography>
        {hhwlt && dataSourceValue === "MSC50" && hhwlt.MSC_dstkm !== 0 ? (
          <Typography color="grey.500" variant="caption" mt="1rem">
            {`The closest MSC50 point, ${hhwlt.MSC_selec}, (latitude ${hhwlt.MSC_lat.toFixed(
              1
            )}, longitude ${hhwlt.MSC_lon.toFixed(1)}), is located  ${parseFloat(hhwlt.MSC_dstkm).toFixed(
              1
            )} km from the ${port.get(
              "Harbour_Na"
            )}. It is the responsibility of the user to evaluate the applicability of this data at this location.`}
          </Typography>
        ) : dataSourceValue === "Regional Model" && !filteredRegionalData ? (
          <Typography color="grey.500" variant="caption" mt="1rem">
            No Regional model data exists for the {port.get("Harbour_Na")}.
          </Typography>
        ) : (
          <Typography color="grey.500" variant="caption" mt="1rem">
            N/A
          </Typography>
        )}
      </Box>
    </Box>
  );
};
