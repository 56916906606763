import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GroupValueSliceState {
  value: string;
}

const initialState: GroupValueSliceState = {
  value: "",
};

export const DataRepoGroupValueSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    changeGroupValue: (state, action: PayloadAction<GroupValueSliceState>) => {
      return (state = action.payload);
    },
  },
});

export default DataRepoGroupValueSlice.reducer;

export const { changeGroupValue } = DataRepoGroupValueSlice.actions;
