import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxState {
  value: string;
}

const initialState: ToolboxState = {
  value: "toolbox",
};

export const ToolboxStateSlice = createSlice({
  name: "toolbox",
  initialState,
  reducers: {
    changeToolboxState: (state, action: PayloadAction<ToolboxState>) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxStateSlice.reducer;

export const { changeToolboxState } = ToolboxStateSlice.actions;
