import { ConfigurationDetailsLeft } from "./ConfigurationDetailsLeft";
import { ConfigurationDetailsRight } from "./ConfigurationDetailsRight";
import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

interface validationProps {
  setLeftValidate: Dispatch<SetStateAction<() => boolean>>;
  setRightValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
  nameError: boolean;
  setNameError: Dispatch<SetStateAction<boolean>>;
  descriptionError: boolean;
  setDescriptionError: Dispatch<SetStateAction<boolean>>;
}

export const DataRepoConfiguration = ({
  setLeftValidate,
  setRightValidate,
  nextClicked,
  setNextClicked,
  nameError,
  setNameError,
  descriptionError,
  setDescriptionError,
}: validationProps) => {
  const [fileDataTypeError, setFileDataTypeError] = useState(true);
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: "1fr ",
        sm: "1fr",
        md: "1fr",
        lg: "1fr 1fr",
      }}
      height="16.5rem"
      overflow="auto"
    >
      <ConfigurationDetailsLeft
        setLeftValidate={setLeftValidate}
        nextClicked={nextClicked}
        fileDataTypeError={fileDataTypeError}
        setFileDataTypeError={setFileDataTypeError}
        setNextClicked={setNextClicked}
        nameError={nameError}
        setNameError={setNameError}
        descriptionError={descriptionError}
        setDescriptionError={setDescriptionError}
      />
      <ConfigurationDetailsRight
        setRightValidate={setRightValidate}
        nextClicked={nextClicked}
        setNextClicked={setNextClicked}
        fileDataTypeError={fileDataTypeError}
        setFileDataTypeError={setFileDataTypeError}
      />
    </Box>
  );
};
