import { ToolboxMainPage } from "../ToolboxMainPage";
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { changeToolboxState } from "../../../features/ToolboxStateSlice";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ToolboxSuggestionUpload } from "./ToolboxSuggestionUpload";

interface searchParamsProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

export const ToolBoxSuggestion = ({ searchParams, setSearchParams }: searchParamsProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <ToolboxMainPage
        value={
          <Box>
            <Box display="flex" flexDirection="row" gap="0.5rem">
              <IconButton
                sx={{
                  color: "secondary.dark",
                  height: "1rem",
                  width: "1rem",
                  mt: "1rem",
                  ml: "1rem",
                }}
                disableRipple
                onClick={() => {
                  dispatch(changeToolboxState({ value: "toolbox" }));
                  searchParams.delete("q");
                  setSearchParams(searchParams);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box mt="0.5rem">
                <img src="/toolboxes/suggest a tool.svg" alt="icon" style={{ width: "3rem", height: "2.5rem" }} />
              </Box>
              <Box mt="0.5rem" ml="-0.25rem">
                <Typography variant="body1" fontWeight="700" color="secondary.dark">
                  Suggest a toolbox
                </Typography>
                <Typography variant="caption" color="grey.500">
                  Make a suggestion for a function that should be added to the toolbox
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "calc(100vh - 7rem)",
                overflowY: "auto",
                msOverflowX: "hidden",
              }}
            >
              <ListItemButton onClick={handleClick} sx={{ height: "2.5rem", mt: "0.5rem" }} disableRipple>
                <ListItemText
                  primary={
                    <Typography variant="body2" color="secondary.dark" fontWeight="700">
                      We value your input and are excited to hear your ideas.
                    </Typography>
                  }
                />

                <ExpandMore
                  style={{
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  sx={{ color: "secondary.dark" }}
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto">
                <List component="div" disablePadding>
                  <ListItemText
                    sx={{ pl: 2, height: "auto" }}
                    primary={
                      <Typography variant="body2" color="secondary.dark">
                        This is your opportunity to contribute to our growing toolbox and help shape the future of
                        CCZIS.
                      </Typography>
                    }
                  />
                  <ListItemText
                    sx={{ pl: 2, height: "auto", overflowWrap: "break-word" }}
                    primary={
                      <Typography variant="body2" color="secondary.dark" mt="1rem" mr="1rem">
                        Use the space below to share your suggestion for a new tool. Whether it's cutting-edge
                        technology, a helpful library, or a creative solution, we're eager to explore your insights.
                        Your contribution can make a real impact on our development team and enhance the overall user
                        experience.
                      </Typography>
                    }
                  />
                  <ListItemText
                    sx={{ pl: 2, height: "auto" }}
                    primary={
                      <Typography variant="body2" color="secondary.dark" mt="1rem" mr="1rem">
                        We appreciate your time and effort in providing us with your suggestions. Please ensure your
                        submission includes relevant details and any additional information that may help evaluate your
                        idea.
                      </Typography>
                    }
                  />
                  <ListItemText
                    sx={{ pl: 2, height: "auto" }}
                    primary={
                      <Typography variant="body2" color="secondary.dark" mt="1rem" mr="1rem">
                        Thank you for being part of our collaborative journey. Together, let's empower CCZIS with
                        innovative tools and take it to new heights!
                      </Typography>
                    }
                  />
                </List>
              </Collapse>

              <Divider
                sx={{
                  mt: "0.5rem",
                  borderWidth: "1px",
                  borderColor: "grey.200",
                }}
              />
              <ToolboxSuggestionUpload />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
