import { Box, Typography, Stack, IconButton } from "@mui/material";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

interface HeadingProps {
  name: string;
  files: File[];
}

export const DashboardTabsHeadWaves = ({ name, files }: HeadingProps) => {
  const downloadUniqueFiles = () => {
    const uniqueFileNames = new Set<string>();
    const uniqueFiles: File[] = [];
    for (let file of files) {
      if (!uniqueFileNames.has(file.name)) {
        uniqueFileNames.add(file.name);
        uniqueFiles.push(file);
      }
    }

    for (let file of uniqueFiles) {
      const url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "670px 1fr",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="body1" color="secondary.dark" sx={{ fontWeight: 700, m: "0.5rem 1rem" }}>
          {name}
        </Typography>
      </Box>
      {files.length > 0 && (
        <Box onClick={downloadUniqueFiles} mr="1rem">
          <Stack direction="row">
            <IconButton
              color="primary"
              disableRipple
              sx={{
                mt: "-0.2rem",
              }}
            >
              <GetAppOutlinedIcon fontSize="medium" />
            </IconButton>
            <Typography
              color="primary"
              variant="body2"
              sx={{
                fontWeight: 700,
                mt: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              Download
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
