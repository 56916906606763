export const dataList = [
  {
    title: "Small Craft Harbours in Atlantic Canada",
    value:
      "Explore coastal data, statistics, and other information for Small Craft Harbours in the Maritimes and Gulf Region",
    image: "images/smallCraftHarbours.png",
    toPage: "/dashboard",
    tabNumber: 0,
  },
  {
    title: "Hydrographic Datasets",
    value: "Upload, download, and visualize hydrographic surveys and datasets across Canada",
    image: "images/HydrographicDatasets.png",
    toPage: "/repository?tool=Bathymetry",
    tabNumber: 1,
  },
  {
    title: "Wave Profile Model",
    value: "Calculate wave generation and propagation along a bathymetric profile",
    image: "images/WaveProfileModel.png",
    toPage: "/toolbox?q=waveProfile",
    tabNumber: 2,
  },
  {
    title: "Seasonal and Monthly Statistics",
    value: "Calculate seasonal or monthly statistics from a timeseries using existing or user uploaded data",
    image: "images/Statistics.png",
    toPage: "/toolbox?q=seasonalstatistics",
    tabNumber: 0,
  },
  {
    title: "Coastal Conditions in a Changing Climate",
    value: "Explore the effects of climate change on the predicted wave climate in the Bay of Fundy",
    image: "images/WavesinaChangingClimate.png",
    toPage: "/dashboard?tool=Wave+Height",
    tabNumber: 1,
  },
  {
    title: "Ice Distribution & Thickness",
    value: "Delve into 52 weeks of ice thickness data across Canada",
    image: "images/IceDistribution&Thickness.png",
    toPage: "/dashboard?tool=Ice+Cover",
    tabNumber: 2,
  },
];
