import { Alert, Box, Button, CircularProgress, IconButton, Snackbar, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Stroke, Style } from "ol/style";
import { GeoJSON } from "ol/format";
import JSZip from "jszip";
import * as shapefile from "shapefile";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import Feature from "ol/Feature";
import KML from "ol/format/KML";
import { Fill } from "ol/style";
import BaseLayer from "ol/layer/Base";
import Polygon, { fromExtent } from "ol/geom/Polygon";
import { MultiPolygon, Point, LineString, MultiLineString } from "ol/geom";
import { SetStateAction, Dispatch } from "react";
import { Draw, Modify, Snap } from "ol/interaction";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import { Coordinate } from "ol/coordinate";
import {
  UploadBathmetryCoordinatesFile,
  convertJsontoGisVector,
  createPersonalProject,
  deleteBathymetryData,
  getConvertedDatasetId,
  getSubProjectList,
  uploadFiles,
} from "../../../api/backend";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { platform, publicDataSets } from "../../../api/init";
import { getAllDatasetsInsideProject } from "../../../api/backend_public";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import {
  changeBathyButtonSelected,
  changeConvertedProjectIdValue,
  changeDrawnFeatureValue,
} from "../../../features/ToolboxBathymetryInterpolationImportSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

interface InterpolationProps {
  setPolygonOnMap: Dispatch<SetStateAction<boolean>>;
  setConvertedDatasetId: Dispatch<SetStateAction<string>>;
  setConvertedProjectId: Dispatch<SetStateAction<string>>;
  convertedProjectId: string;
  generateStatisticsClicked: boolean;
}

interface clearButtonProps {
  removePolygon: () => void;
  isUploading: boolean;
}
const FILE_SIZE_LIMIT = 500 * 1024 * 1024;
export const ClearButton = ({ removePolygon, isUploading }: clearButtonProps) => {
  return (
    <Button variant="outlined" color="primary" size="small" onClick={removePolygon} disabled={isUploading}>
      <Typography fontWeight="700" variant="body2">
        Clear
      </Typography>
    </Button>
  );
};

export const InterpolationBoundary = ({
  setPolygonOnMap,
  setConvertedProjectId,
  setConvertedDatasetId,
  convertedProjectId,
  generateStatisticsClicked,
}: InterpolationProps) => {
  const map = useContext(OpenlayersContext);
  const dispatch = useDispatch();
  const buttonSelected = useSelector((state: RootState) => state.bathymetryInterpolationImport.buttonSelected);
  const [drawnFeature, setDrawnFeature] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modify, setModify] = useState<any>(null);
  const [drawnSource, setDrawnSource] = useState<VectorSource>(new VectorSource());
  const [draw, setDraw] = useState<Draw | null>(null);
  const [coordinates, setCoordinates] = useState<Coordinate[] | Coordinate[][]>([]);
  const [datasetId, setDatasetId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [convertingData, setConvertingData] = useState<any[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isAuthenticated } = useOidc();

  useEffect(() => {
    const deleteDatasets = async () => {
      try {
        const storedConvertedProjectId = localStorage.getItem("convertedProjectId");
        const selectedId = convertedProjectId !== "" ? convertedProjectId : storedConvertedProjectId;
        if (selectedId) {
          const datasets = await getAllDatasetsInsideProject(selectedId);

          const res = datasets.data.filter(
            (dataset: any) =>
              (dataset.name.includes("geojson") &&
                dataset.description === "geojson coordinates file for bathymetry interpolation") ||
              dataset.name.includes("interpolated_file")
          );
          res.forEach((dataset: any) => {
            deleteBathymetryData(dataset.id);
          });
        }
      } catch (error) {}
    };
    deleteDatasets();
  }, []);

  const isValidCoordinate = (coord: any) =>
    Array.isArray(coord) && (coord.length === 2 || coord.length === 3) && coord.every((c) => typeof c === "number");

  const transformCoordinate = (coord: any[], sourceProjection: string, targetProjection: string) => {
    if (!isValidCoordinate(coord)) {
      return null;
    }
    const [x, y] = proj4(sourceProjection, targetProjection, [coord[0], coord[1]]);
    return [x, y];
  };

  const transformPolygon = (polygon: any[], sourceProjection: string, targetProjection: string) => {
    return polygon
      .filter(isValidCoordinate)
      .map((coord) => transformCoordinate(coord, sourceProjection, targetProjection));
  };

  const transformCoordinates = (coords: any[], sourceProjection: string, targetProjection: string) => {
    if (Array.isArray(coords[0]) && Array.isArray(coords[0][0])) {
      return coords.map((polygon) => transformPolygon(polygon, sourceProjection, targetProjection));
    } else {
      return transformPolygon(coords, sourceProjection, targetProjection);
    }
  };

  const generateGeoJSON = (coordinates: any[], sourceProjection = "EPSG:3857", targetProjection = "EPSG:4326") => {
    if (!coordinates || coordinates.length === 0) return null;
    const transformedCoordinates = transformCoordinates(coordinates, sourceProjection, targetProjection);
    const geojsonObject = {
      type: "FeatureCollection",
      name: "TestBoundary_v01",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      features: [
        {
          type: "Feature",
          id: 0,
          properties: { Id: 0 },
          geometry: {
            type: "Polygon",
            coordinates: transformedCoordinates,
          },
        },
      ],
    };

    return JSON.stringify(geojsonObject, null, 2);
  };

  const { idTokenPayload } = useOidcIdToken();
  const performUpload = async (processedFile: File) => {
    const subprojectlist = await getSubProjectList(publicDataSets.PrivateTemporaryBathymetry.id);
    let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

    if (!personalFolderId) {
      await createPersonalProject(idTokenPayload.oid, publicDataSets.PrivateTemporaryBathymetry.id);
      const updatedSubprojectList = await getSubProjectList(publicDataSets.PrivateTemporaryBathymetry.id);
      personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
    }

    if (processedFile) {
      let fileToUpload = processedFile;
      const uploadResponse = await uploadFiles(fileToUpload);
      const convert = await UploadBathmetryCoordinatesFile(fileToUpload, uploadResponse.data, personalFolderId);
      return convert;
    }
  };

  useEffect(() => {
    const uploadGeoJSON = async () => {
      if (coordinates.length > 0 && buttonSelected !== "") {
        setIsUploading(true);
        const convertingSnackbarKey = enqueueSnackbar("Uploading coordinates...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
        const geoJSON = generateGeoJSON(coordinates);
        if (geoJSON) {
          const blob = new Blob([geoJSON], { type: "application/json" });
          const geoJSONFile = new File([blob], "geojson.json", { type: "application/json" });
          try {
            const convert = await performUpload(geoJSONFile);
            if (convert.status === "Completed") {
              const datasetId = await getConvertedDatasetId(convert.id);
              setDatasetId(datasetId.importResults[0].datasetId);
              setProjectId(datasetId.importResults[0].projectId);
              if (convertingSnackbarKey !== null) {
                closeSnackbar(convertingSnackbarKey);
              }
              enqueueSnackbar("Coordinates uploaded successfully", {
                variant: "success",
                autoHideDuration: 2000,
              });
            }
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        }
      }
    };
    uploadGeoJSON();
  }, [coordinates]);

  useEffect(() => {
    if (projectId !== "") {
      (async () => {
        const { data } = await platform.getJson(`metadata/project/${projectId}/dataset/list`, "3");
        setData(data);
      })();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId !== "") {
      (async () => {
        const { data } = await platform.getJson(`conversion/transfer/list?projectId=${projectId}`, "2");
        let transferPending = data.every((t: any) => t.status !== "Pending");
        if (!transferPending) {
          const filteredData = data.filter((data: any) => data.status === "Pending");
          setConvertingData(filteredData);
        }
      })();
    }
    return () => {};
  }, [projectId]);

  useEffect(() => {
    if (projectId !== "") {
      let convertingSnackbarKey: any = null;

      if (convertingData && convertingData.length > 0) {
        convertingSnackbarKey = enqueueSnackbar("Uploading coordinates...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
      }
      const refreshIntervalId = setInterval(async () => {
        for (const item of convertingData) {
          const convertingItem = await platform.getJson(`conversion/transfer/${item.id}`, "2");

          if (convertingItem.status === "Completed" || convertingItem.status === "Error") {
            if (convertingSnackbarKey !== null) {
              closeSnackbar(convertingSnackbarKey);
            }

            let message = "Conversion successful!";
            let severity: "success" | "error" = "success";
            let autohideduration: number = 4000;

            if (convertingItem.status === "Error") {
              message = `Error occurred during conversion. ${convertingItem.errorMessage || ""}`;
              severity = "error";
              autohideduration = 4000;
            }
            enqueueSnackbar(message, {
              variant: severity,
              autoHideDuration: autohideduration,
            });

            setDatasetId("");
            setProjectId("");

            setConvertingData(convertingData.filter((a) => a.id !== convertingItem.id));

            const { data } = await platform.getJson(`metadata/project/${projectId}/dataset/list`, "3");
            setData(data);
          }
        }
      }, 2000);

      setTimeout(() => {
        clearInterval(refreshIntervalId);
      }, 12000000);

      return () => {
        clearInterval(refreshIntervalId);
        if (convertingSnackbarKey !== null) {
          closeSnackbar(convertingSnackbarKey);
        }
      };
    }
  }, [convertingData, projectId, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (projectId !== "" && datasetId !== "") {
      let convertingSnackbarKey: any = null;
      const fetchAndCheckData = async () => {
        convertingSnackbarKey = enqueueSnackbar("Converting coordinates...", {
          variant: "info",
          persist: true,
          action: (key) => (
            <>
              <CircularProgress size={24} sx={{ color: "white" }} />
              <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "white" }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });

        const result = await convertJsontoGisVector(datasetId, projectId);
        const checkForPendingTransfers = async () => {
          const { data } = await platform.getJson(`conversion/transfer/list?projectId=${projectId}`, "2");

          const transferPending = data.some((t: any) => t.status !== "Completed" && t.status !== "Error");

          if (transferPending) {
            data.filter((data: any) => data.status !== "Completed");

            setTimeout(checkForPendingTransfers, 2000);
          } else {
            const datasetId = await getConvertedDatasetId(result.id);
            setConvertedDatasetId(datasetId.importResults[0].datasetId);
            setConvertedProjectId(datasetId.importResults[0].projectId);
            localStorage.setItem("convertedProjectId", `${datasetId.importResults[0].projectId}`);
            setCoordinates([]);
            closeSnackbar(convertingSnackbarKey);
            setIsUploading(false);
            enqueueSnackbar("Conversion successful", {
              variant: "success",
              autoHideDuration: 4000,
            });
          }
        };

        await checkForPendingTransfers();
        setConvertingData([...(convertingData ? convertingData : []), result]);

        setDatasetId("");
        setProjectId("");
      };

      fetchAndCheckData();
    }
  }, [projectId, datasetId, convertingData, convertedProjectId]);

  const removeAllCustomPolygonLayers = async () => {
    dispatch(changeBathyButtonSelected(""));
    setConvertedDatasetId("");
    setConvertedProjectId("");
    if (!map?.current) return;
    const layersToRemove: BaseLayer[] = [];
    map.current.getLayers().forEach((layer: BaseLayer) => {
      if (layer.get("customLayerName") === "customPolygonLayer") {
        layersToRemove.push(layer);
      }
    });

    layersToRemove.forEach((layer) => {
      map.current?.removeLayer(layer);
    });

    if (convertedProjectId !== "") {
      const datasets = await getAllDatasetsInsideProject(convertedProjectId);
      const res = datasets.data.filter(
        (dataset: any) =>
          dataset.name.includes("geojson") &&
          dataset.description === "geojson coordinates file for bathymetry interpolation"
      );

      res.forEach((dataset: any) => {
        deleteBathymetryData(dataset.id);
      });
    }
    setCoordinates([]);
    setPolygonOnMap(false);
  };

  const validateGeojsonFeatures = (features: Feature[]): boolean => {
    for (let i = 0; i < features.length; i++) {
      let geometry = features[i].getGeometry();

      if (
        geometry instanceof Point ||
        geometry instanceof LineString ||
        geometry instanceof MultiLineString ||
        (geometry instanceof MultiPolygon && geometry.getPolygons().length > 1)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleKMZFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    removeAllCustomPolygonLayers();
    const file = event.target.files?.[0];
    if (!file) {
      alert("No file selected");
      return;
    }

    if (file.size > FILE_SIZE_LIMIT) {
      enqueueSnackbar("File size exceeds 500MB limit. Please choose a smaller file.", {
        variant: "error",
        autoHideDuration: 4000,
      });
      event.target.value = "";
      return;
    }

    const addLayerToMap = (features: Feature[]) => {
      features.forEach((feature) => {
        const geometry = feature.getGeometry();
        if (geometry instanceof Polygon || geometry instanceof LineString) {
          const coordinates = geometry.getCoordinates();
          setCoordinates(coordinates);
        }
      });
      const vectorSource = new VectorSource({
        features: features,
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      vectorLayer.set("customLayerName", "customPolygonLayer");

      if (vectorLayer) {
        map?.current?.addLayer(vectorLayer);

        const source = vectorLayer.getSource();
        if (source) {
          const extent = source.getExtent();
          map?.current?.getView().fit(extent, { padding: [50, 50, 50, 50], duration: 1000 });
        }
      }
    };

    const processKmlContent = (kmlContent: string) => {
      const kmlFormat = new KML();
      const features = kmlFormat.readFeatures(kmlContent, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      if (!validateGeojsonFeatures(features)) {
        alert("The KML/KMZ file should not contain a single point, line, or multiple polygons.");
        return;
      }

      features.forEach((feature) => {
        feature.setStyle(
          new Style({
            stroke: new Stroke({
              color: "red",
              width: 3,
            }),
            fill: new Fill({
              color: "rgba(255, 0, 0, 0.1)",
            }),
          })
        );
      });

      addLayerToMap(features);
      dispatch(changeBathyButtonSelected("KMZFile"));
    };

    const processZipContents = async (zip: JSZip) => {
      for (const relativePath in zip.files) {
        if (relativePath) {
          if (relativePath.endsWith(".kml")) {
            const kmlContent = await zip.file(relativePath)!.async("text");
            processKmlContent(kmlContent);
            return;
          }
        }
        if (relativePath.endsWith(".kmz")) {
          const kmzContent = await zip.file(relativePath)!.async("blob");
          const kmzZip = await JSZip.loadAsync(kmzContent);
          const kmlKey = Object.keys(kmzZip.files).find((name) => name.endsWith(".kml"));
          if (kmlKey) {
            const kmlContent = await kmzZip.file(kmlKey)!.async("text");
            processKmlContent(kmlContent);
            return;
          }
        }
      }
      alert("No KML or KMZ file found inside the zip archive.");
    };

    if (file.name.endsWith(".kmz")) {
      const zip = await JSZip.loadAsync(file);
      await processZipContents(zip);
    } else if (file.name.endsWith(".kml")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target !== null) {
          const content = event.target.result;
          if (typeof content === "string") {
            processKmlContent(content);
          }
        }
      };

      reader.readAsText(file);
    } else if (file.name.endsWith(".zip")) {
      const zip = await JSZip.loadAsync(file);
      await processZipContents(zip);
    } else {
      alert("Unsupported file format");
    }
    event.target.value = "";
  };

  const handleShapeFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    removeAllCustomPolygonLayers();
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > FILE_SIZE_LIMIT) {
        enqueueSnackbar("File size exceeds 500MB limit. Please choose a smaller file.", {
          variant: "error",
          autoHideDuration: 4000,
        });
        event.target.value = "";
        return;
      }
    }

    let shpArrayBuffer = null;
    let prjText = null;

    let hasZip = false;
    let hasShpOrPrj = false;

    let shpFileCount = 0;
    let prjFileCount = 0;

    for (let i = 0; i < files.length; i++) {
      if (
        files[i].type === "application/zip" ||
        files[i].type === "application/x-zip-compressed" ||
        files[i].type === "application/octet-stream"
      ) {
        hasZip = true;
      } else if (files[i].name.endsWith(".shp") || files[i].name.endsWith(".prj")) {
        hasShpOrPrj = true;
        if (files[i].name.endsWith(".shp")) {
          shpFileCount++;
        } else if (files[i].name.endsWith(".prj")) {
          prjFileCount++;
        }
      }
    }

    if (hasZip && hasShpOrPrj) {
      alert("Please either select a zip file or individual .shp and .prj files, not both.");
      event.target.value = "";
      return;
    }

    if (shpFileCount > 1 || prjFileCount > 1) {
      alert("Please upload only one .shp file and one .prj file.");
      event.target.value = "";
      return;
    }

    if (
      (files.length === 1 && files[0].type === "application/zip") ||
      (files.length === 1 && files[0].type === "application/x-zip-compressed") ||
      (files.length === 1 && files[0].type === "application/octet-stream")
    ) {
      const zip = await JSZip.loadAsync(files[0]);

      for (const relativePath in zip.files) {
        if (relativePath.endsWith(".shp")) {
          shpArrayBuffer = await zip.file(relativePath)!.async("arraybuffer");
        } else if (relativePath.endsWith(".prj")) {
          prjText = await zip.file(relativePath)!.async("text");
        }
      }

      if (!shpArrayBuffer || !prjText) {
        alert("Shapefile or projection file not found in the zip archive.");
        event.target.value = "";
        return;
      }
    } else {
      let shpFile = null;
      let prjFile = null;

      for (let i = 0; i < files.length; i++) {
        if (files[i].name.endsWith(".shp")) {
          shpFile = files[i];
        } else if (files[i].name.endsWith(".prj")) {
          prjFile = files[i];
        }
      }

      if (!shpFile || !prjFile) {
        alert("Please ensure that you have both .shp and .prj files selected.");
        event.target.value = "";
        return;
      }

      shpArrayBuffer = await shpFile.arrayBuffer();
      prjText = await prjFile.text();
    }

    if (shpArrayBuffer && prjText) {
      const projCode = "CUSTOM_PROJ";
      proj4.defs(projCode, prjText);
      register(proj4);

      const geojsonFeatures = [];
      const source = await shapefile.open(shpArrayBuffer);
      let result = await source.read();
      while (!result.done) {
        const geometryType = result.value.geometry.type;
        if (
          geometryType === "Point" ||
          geometryType === "LineString" ||
          geometryType === "MultiLineString" ||
          (geometryType === "MultiPolygon" && result.value.geometry.coordinates.length > 1)
        ) {
          alert("Invalid geometry type. The shapefile must contain a single polygon.");
          event.target.value = "";
          return;
        }
        geojsonFeatures.push(result.value);
        result = await source.read();
      }

      if (map?.current?.getView().getProjection().getCode()) {
        const vectorSource = new VectorSource({
          features: new GeoJSON({
            dataProjection: projCode,
            featureProjection: map?.current?.getView().getProjection().getCode(),
          }).readFeatures({
            type: "FeatureCollection",
            features: geojsonFeatures,
          }),
        });

        vectorSource.getFeatures().forEach((feature) => {
          const geometry = feature.getGeometry();
          if (geometry instanceof Polygon || geometry instanceof LineString) {
            const coordinates = geometry.getCoordinates();

            setCoordinates(coordinates);
          }
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            stroke: new Stroke({
              color: "red",
              width: 3,
            }),
          }),
        } as any);

        vectorLayer.set("customLayerName", "customPolygonLayer");
        map?.current?.addLayer(vectorLayer);

        const view = map.current.getView();
        const extent = vectorSource.getExtent();

        view.fit(extent, {
          duration: 1000,
          padding: [50, 50, 50, 50],
        });
      } else {
        alert("Failed to get feature projection");
      }
    } else {
      alert("Shapefile or projection file not found.");
    }
    event.target.value = "";
    dispatch(changeBathyButtonSelected("SHPFile"));
  };

  const handleMapViewerExtent = () => {
    removeAllCustomPolygonLayers();
    if (!map?.current) return;

    const extent = map.current.getView().calculateExtent(map.current.getSize());
    const polygon = fromExtent(extent);

    const polygonCoordinates = polygon.getCoordinates();
    setCoordinates(polygonCoordinates);

    const feature = new Feature(polygon);
    feature.setStyle(
      new Style({
        stroke: new Stroke({
          color: "red",
          width: 3,
        }),
      })
    );

    const vectorSource = new VectorSource();
    vectorSource.addFeature(feature);

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    vectorLayer.set("customLayerName", "customPolygonLayer");

    map.current.addLayer(vectorLayer);

    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 2000);
    dispatch(changeBathyButtonSelected("mapViewer"));
  };

  const enableDrawMode = () => {
    if (drawnSource.getFeatures().length > 0) {
      if (draw) {
        map?.current?.removeInteraction(draw);
        setDraw(null);
      }
      return;
    }

    if (draw) {
      map?.current?.removeInteraction(draw);
      setDraw(null);
      return;
    }

    if (!draw && map?.current) {
      let layer = map.current
        ?.getLayers()
        .getArray()
        .find((layer: any) => layer.get("customLayerName") === "customPolygonLayer") as VectorLayer<VectorSource<any>>;

      if (!layer) {
        layer = new VectorLayer({
          source: drawnSource,
          style: new Style({
            stroke: new Stroke({
              color: "red",
              width: 2,
            }),
          }),
        });
        layer.set("customLayerName", "customPolygonLayer");
        map.current?.addLayer(layer);
      }

      drawnSource.clear();

      const newDraw = new Draw({
        source: drawnSource,
        type: "Polygon",
      });

      const newModify = new Modify({
        source: drawnSource,
      });

      const snap = new Snap({
        source: drawnSource,
      });

      map.current?.addInteraction(newDraw);
      map.current?.addInteraction(newModify);
      map.current?.addInteraction(snap);

      newDraw.on("drawend", (event: any) => {
        const feature = event.feature;

        const polygon = feature.getGeometry() as any;

        polygon.set("customId", "drawnPolygon");
        const coordinates = polygon.getCoordinates();
        setCoordinates(coordinates);
        feature.set("modifiable", true);
        setDrawnFeature(feature);
        setDraw(null);
        newModify.setActive(false);
        map?.current?.removeInteraction(newDraw);
      });

      setDraw(newDraw);
      setModify(newModify);

      dispatch(changeBathyButtonSelected("Draw"));
    }
  };

  const clearSelection = async () => {
    if (drawnFeature) {
      drawnSource.removeFeature(drawnFeature);
      setConvertedDatasetId("");
      setConvertedProjectId("");
      setDrawnFeature(null);
      dispatch(changeBathyButtonSelected(""));
      setPolygonOnMap(false);
      setCoordinates([]);
      if (convertedProjectId !== "") {
        const datasets = await getAllDatasetsInsideProject(convertedProjectId);

        const res = datasets.data.filter(
          (dataset: any) =>
            dataset.name.includes("geojson") &&
            dataset.description === "geojson coordinates file for bathymetry interpolation"
        );

        res.forEach((dataset: any) => {
          deleteBathymetryData(dataset.id);
        });
        setConvertedProjectId("");
      }
    }
  };

  return (
    <>
      <Snackbar open={snackbarOpen}>
        <Alert
          severity="success"
          sx={{
            boxShadow: "4px 8px 8px 4px gray",
          }}
        >
          Map viewer extent set as interpolation boundary
        </Alert>
      </Snackbar>

      <Box ml="1rem" mt="0.75rem" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="body2" fontWeight="700" color="secondary.dark" mt="0.5rem">
          Interpolation boundary
        </Typography>
        <Box mr="1rem" display="flex" flexDirection="row" gap="1rem">
          {buttonSelected === "mapViewer" ? (
            <ClearButton
              removePolygon={removeAllCustomPolygonLayers}
              isUploading={isUploading || generateStatisticsClicked}
            />
          ) : (
            <LightTooltip
              title="Login to use the toolbox"
              placement="left"
              enterDelay={500}
              leaveDelay={200}
              disableHoverListener={!!idTokenPayload}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  disabled={(buttonSelected !== "draw" && buttonSelected !== "") || !isAuthenticated}
                  onClick={handleMapViewerExtent}
                >
                  <Typography fontWeight="700" variant="body2">
                    Map Viewer Extent
                  </Typography>
                </Button>
              </span>
            </LightTooltip>
          )}

          {buttonSelected === "Draw" ? (
            <ClearButton removePolygon={clearSelection} isUploading={isUploading || generateStatisticsClicked} />
          ) : (
            <LightTooltip
              title="Login to use the toolbox"
              placement="left"
              enterDelay={500}
              leaveDelay={200}
              disableHoverListener={!!idTokenPayload}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  disabled={(buttonSelected !== "draw" && buttonSelected !== "") || !isAuthenticated}
                  onClick={enableDrawMode}
                >
                  <Typography fontWeight="700" variant="body2">
                    Draw
                  </Typography>
                </Button>
              </span>
            </LightTooltip>
          )}

          {buttonSelected === "KMZFile" ? (
            <ClearButton
              removePolygon={removeAllCustomPolygonLayers}
              isUploading={isUploading || generateStatisticsClicked}
            />
          ) : (
            <LightTooltip
              title="Login to use the toolbox"
              placement="left"
              enterDelay={500}
              leaveDelay={200}
              disableHoverListener={!!idTokenPayload}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  disabled={(buttonSelected !== "KMZFile" && buttonSelected !== "") || !isAuthenticated}
                >
                  <Typography fontWeight="700" variant="body2">
                    KML/KMZ File Import
                  </Typography>
                  <input type="file" hidden onChange={handleKMZFileUpload} />
                </Button>
              </span>
            </LightTooltip>
          )}
          {buttonSelected === "SHPFile" ? (
            <ClearButton
              removePolygon={removeAllCustomPolygonLayers}
              isUploading={isUploading || generateStatisticsClicked}
            />
          ) : (
            <LightTooltip
              title="Login to use the toolbox"
              placement="left"
              enterDelay={500}
              leaveDelay={200}
              disableHoverListener={!!idTokenPayload}
            >
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  disabled={(buttonSelected !== "SHPFile" && buttonSelected !== "") || !isAuthenticated}
                >
                  <Typography fontWeight="700" variant="body2">
                    Import SHP File
                  </Typography>
                  <input type="file" hidden multiple onChange={handleShapeFileUpload} />
                </Button>
                |
              </span>
            </LightTooltip>
          )}
        </Box>
      </Box>
    </>
  );
};
