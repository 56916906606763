import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataRepositoryXCoordinateState {
  value: number | null;
}

const initialState: DataRepositoryXCoordinateState = {
  value: null,
};

export const DataRepositoryXCoordinateSlice = createSlice({
  name: "XCoordinate",
  initialState,
  reducers: {
    changeDataRepositoryXCoordinate: (
      state,
      action: PayloadAction<DataRepositoryXCoordinateState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default DataRepositoryXCoordinateSlice.reducer;

export const { changeDataRepositoryXCoordinate } =
  DataRepositoryXCoordinateSlice.actions;
