import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CRSDetails {
  value: string;
  id: number | null;
  proj4String: string;
  wkt: string;
}

interface ToolboxCRSSliceState {
  CRS: CRSDetails;
}

const initialState: ToolboxCRSSliceState = {
  CRS: {
    value: "",
    id: null,
    proj4String: "",
    wkt: "",
  },
};

export const ToolboxCRSSlice = createSlice({
  name: "toolboxCRS",
  initialState,
  reducers: {
    changeToolboxCRS: (state, action: PayloadAction<{ CRS: CRSDetails }>) => {
      state.CRS = action.payload.CRS;
    },
  },
});

export default ToolboxCRSSlice.reducer;

export const { changeToolboxCRS } = ToolboxCRSSlice.actions;
