import { Box, Typography, Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import { DataUploadModal } from "./DataUploadComponents/DataUploadModal";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

export const AddDataButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const { idTokenPayload } = useOidcIdToken();

  return (
    <>
      <LightTooltip title="You need to be logged in" placement="left-end" disableHoverListener={!!idTokenPayload}>
        <span>
          <Button
            onClick={handleOpen}
            disabled={!idTokenPayload}
            sx={{
              boxShadow: idTokenPayload ? "1px 9px 8px -2px rgba(9, 51, 75, 0.5)" : null,
              borderRadius: "4px",
              height: "2.5rem",
              width: "7rem",
              bgcolor: !idTokenPayload ? "grey.300" : "primary.main",
              mt: "1.5rem",
              "&:hover": {
                bgcolor: "primary.dark",
                cursor: "Pointer",
              },
              "&:active": {
                bgcolor: "primary.dark",
                boxShadow: "1px 9px 8px -2px rgba(9, 51, 75, 0.5)",
                transform: "translateY(1px)",
              },
            }}
          >
            <AddIcon sx={{ color: "#FFFFFF", ml: "-0.5rem" }} />

            <Box m="0.6875rem -0.5rem" pl="0.75rem">
              <Typography variant="body2" color="#FFFFFF">
                Add Data
              </Typography>
            </Box>
          </Button>
        </span>
      </LightTooltip>
      <DataUploadModal open={open} handleClose={handleClose} />
    </>
  );
};
