import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { TooltipComponent, TooltipComponentOption, GridComponent, GridComponentOption } from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { Box, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<TooltipComponentOption | GridComponentOption | BarSeriesOption>;

export const IceCoverChartBreaks: React.FC = () => {
  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: [
          "Nov 01",
          "Nov 08",
          "Nov 15",
          "Nov 22",
          "Nov 29",
          "Dec 06",
          "Dec 13",
          "Dec 20",
          "Dec 27",
          "Jan 03",
          "Jan 10",
          "Jan 17",
          "Jan 24",
          "Feb 01",
        ],
        axisTick: {
          alignWithLabel: true,
        },
        name: "Week of Year",
        nameLocation: "middle",
        nameGap: 30,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
        },
        splitLine: {
          lineStyle: {
            type: "solid",
          },
        },
        name: "Week of Year",
        nameLocation: "middle",
        nameGap: 30,
      },
    ],
    series: [
      {
        name: "Ice-season length",
        type: "bar",
        barWidth: "30%",
        data: [10, 52, 20, 33, 39, 30, 20, 20, 62, 40, 34, 50, 30, 20],
        itemStyle: {
          color: "#00A4EC",
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
        height: "22rem",
        m: "1rem",
      }}
    >
      <Box m="1rem" display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.75rem">
            Ice Thickness Climatology
          </Typography>
          <LightTooltip
            title="Ice thickness is derived from the predominant ice type when ice is present. Average ice thicknesses are obtained across the 1990-2020 period in weekly increments. The maximum and minimum values are calculated as the highest and lowest values, respectively, across these weekly climatologies."
            placement={"right"}
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton sx={{ "&:hover": { color: "secondary.dark", mt: "0rem" } }} disableRipple>
              <InfoOutlined />
            </IconButton>
          </LightTooltip>
        </Box>
        <Typography variant="caption" color="grey.500" mt="-0.5rem">
          CIS Ice Charts: 1990-2020 Climatology
        </Typography>
      </Box>
      <Box>
        {/* <ReactEcharts
          style={{
            height: "340px",
            width: "auto",
            padding: "1rem",
            top: "-4rem",
          }}
          option={option}
        /> */}
        <Typography
          color="secondary.dark"
          variant="body1"
          display="flex"
          fontWeight="700"
          justifyContent="center"
          textAlign="center"
          mt="14%"
          mr="5rem"
          ml="5rem"
        >
          Coming in Phase 3! For Phase 2, CIS Climatology Data may be viewed directly from the ribbon in the Map Viewer
        </Typography>
      </Box>
    </Box>
  );
};
