import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartsOption, BarSeriesOption } from "echarts";
import { Box, IconButton, Typography } from "@mui/material";
import { getGisVectorData } from "../../../api/backend_public";
import { publicDataSets } from "../../../api/init";
import { useSearchParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";

const getLithoImagePath = (lithoType: string) => {
  switch (lithoType) {
    case "CLAY & BOULDERS":
      return "/borehole_images/Boulders_v01.png";
    case "BOULDERS":
      return "/borehole_images/Boulders_v01.png";
    case "GRANITE":
      return "/borehole_images/Stone-Basalt-Bedrock-Granite_v01.png";
    case "BASALT":
      return "/borehole_images/Stone-Basalt-Bedrock-Granite_v01.png";
    case "GRAVEL":
      return "/borehole_images/Gravel_v01.png";
    case "QUARTZITE":
      return "/borehole_images/Shale_v01.png";
    case "SHALE":
      return "/borehole_images/Shale_v01.png";
    case "SLATE":
      return "/borehole_images/Slate_v01.png";
    case "SLATE & SHALE":
      return "/borehole_images/Slate_v01.png";
    case "UNKNOWN":
      return "/borehole_images/Unknown-Predrilled_v01.png";
    case "CLAY & ROCK":
      return "/borehole_images/Clay_v01.png";
    case "CLAY":
      return "/borehole_images/Clay_v01.png";
    default:
      return "/borehole_images/AllElse-Other_v01.png";
  }
};

interface ChartProps {
  datasetDetails: any;
  setDatasetDetails: Dispatch<SetStateAction<any>>;
  yMin: any;
  setYMin: Dispatch<SetStateAction<any>>;
  yMax: any;
  setYMax: Dispatch<SetStateAction<any>>;
  lithoMain: any;
  setLithoMain: Dispatch<SetStateAction<any>>;
  lithoSecondary: any;
  setLithoSecondary: Dispatch<SetStateAction<any>>;
}

const StackedBarChart = ({
  datasetDetails,
  setDatasetDetails,
  yMax,
  setYMax,
  yMin,
  setYMin,
  setLithoMain,
  lithoMain,
  lithoSecondary,
  setLithoSecondary,
}: ChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption>({});
  const [distanceToHarbour, setDistanceToHarbour] = useState("");

  const [boreholeDate, setBoreholeDate] = useState("");
  const [data, setData] = useState<any>();
  const [renderKey, setRenderKey] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const port = searchParams.get("port");

  const getDatasetDetails = async () => {
    const datasetData = await getGisVectorData(publicDataSets.SCHLocations.id);
    const datasetValue = datasetData.features.filter((item: any) => item.properties?.Harbour_nu === Number(port))[0]
      .properties;
    return datasetValue;
  };

  const getBoreholeData = async (details: any) => {
    const boreholeData = await getGisVectorData(publicDataSets.Boreholes.id);
    const data = boreholeData.features.filter((item: any) => item.properties.WellNum == details.BH_selec);
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const details = await getDatasetDetails();
      if (details) {
        setDatasetDetails(details);
        const boreholeData = await getBoreholeData(details);
        setData(boreholeData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setRenderKey((prevKey) => prevKey + 1);
    if (datasetDetails) {
      const distance = datasetDetails.BH_dstkm;
      setDistanceToHarbour(`${distance} km`);
    } else {
      setDistanceToHarbour("N/A");
    }
    if (data && data.length > 0) {
      setBoreholeDate(data[0].properties.Date.split(" ")[0]);
    } else {
      setBoreholeDate("N/A");
    }

    let sortedFeatures: any[] = [];

    if (data) {
      sortedFeatures = data.sort((a: any, b: any) => {
        const aValue = a.properties && a.properties.fromdep_m ? a.properties.fromdep_m : 0;
        const bValue = b.properties && b.properties.fromdep_m ? b.properties.fromdep_m : 0;

        return aValue - bValue;
      });
    }

    let yAxisMin, yAxisMax;
    let tempYAxisMin: any[] = [];
    let tempYAxisMax: any[] = [];
    let tempLithoMain: any[] = [];
    let tempLithoSecondary: any[] = [];
    let series: BarSeriesOption[];

    if (sortedFeatures.length === 1) {
      const feature = sortedFeatures[0];
      const depthValue = feature.properties.todep_m - feature.properties.fromdep_m;
      const lithoImage = getLithoImagePath(feature.properties.litho_main);
      const lithoMain = feature.properties.litho_main || "N/A";
      const lithoSecond = feature.properties.litho_2ndr || "N/A";
      tempLithoMain.push(lithoMain);
      tempLithoSecondary.push(lithoSecond);
      yAxisMin = feature.properties.todep_m;
      yAxisMax = feature.properties.fromdep_m;
      tempYAxisMax.push(feature.properties.todep_m);
      tempYAxisMin.push(feature.properties.fromdep_m);
      setYMax(tempYAxisMax);
      setYMin(tempYAxisMin);
      setLithoMain(tempLithoMain);
      setLithoSecondary(tempLithoSecondary);

      series = [
        {
          name: `Depth ${feature.properties.fromdep_m}m - ${feature.properties.todep_m}m`,
          type: "bar",
          barWidth: "50%",
          label: {
            show: true,
            position: "right",
            formatter: `Main: ${lithoMain}\nSecondary: ${lithoSecond}`,
          },
          data: [
            {
              value: depthValue,
              itemStyle: {
                color: {
                  image: lithoImage,
                  repeat: "repeat",
                },
              },
            },
          ],
        },
      ];
    } else {
      yAxisMin = Math.min(...sortedFeatures.map((f) => f.properties.fromdep_m));
      yAxisMax = Math.max(...sortedFeatures.map((f) => f.properties.todep_m));

      series = sortedFeatures.map((feature, index) => {
        const lithoImage = getLithoImagePath(feature.properties.litho_main);
        const lithoMain = feature.properties.litho_main || "N/A";
        const lithoSecond = feature.properties.litho_2ndr || "N/A";
        tempLithoMain.push(lithoMain);
        tempLithoSecondary.push(lithoSecond);
        setLithoMain(tempLithoMain);
        setLithoSecondary(tempLithoSecondary);
        tempYAxisMax.push(feature.properties.todep_m);
        tempYAxisMin.push(feature.properties.fromdep_m);
        setYMax(tempYAxisMax);
        setYMin(tempYAxisMin);
        return {
          name: `Depth ${feature.properties.fromdep_m}m - ${feature.properties.todep_m}m`,
          type: "bar",
          barWidth: "50%",
          stack: "Total",
          label: {
            show: true,
            position: "right",
            formatter: `Main: ${lithoMain}\n\nSecondary: ${lithoSecond}`,
          },
          data: [
            {
              value: feature.properties.todep_m - feature.properties.fromdep_m,
              itemStyle: {
                color: {
                  image: lithoImage,
                  repeat: "repeat",
                },
              },
            },
          ],
        };
      });
    }

    const option: EChartsOption = {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          return `${params.seriesName}<br/>${params.name} Total Depth:  ${params.value}m`;
        },
      },
      xAxis: {
        type: "category",
        data: [""],
        axisLabel: { show: true },
        axisLine: { show: false },
      },
      yAxis: {
        type: "value",
        axisLine: { show: true },
        splitLine: { show: false },
        inverse: true,
        min: yAxisMin,
        max: yAxisMax,
        name: "Depth Range",
        nameLocation: "middle",
        nameGap: 50,
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 14,
        },
        axisLabel: {
          formatter: "{value} m",
          show: true,
        },
      },
      series: series,
    };
    setChartOption(option);
  }, [data, datasetDetails]);
  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{
        m: "1rem 1.5rem 2rem 1.8rem",
        height: "40rem",
        width: "46rem",
        border: "1px solid #CFDBE2",
        borderRadius: "8px",
        boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
      }}
    >
      <Box display="flex" flexDirection="row" gap="2rem">
        <Box display="flex" flexDirection="row">
          <Typography variant="h4" color="secondary.dark" fontWeight="700" ml="1rem" mt="1rem" mb="-1rem">
            Borehole Lithography
          </Typography>
          <LightTooltip
            title={
              <Typography variant="body2">
                Borehole data is from Natural Resources Canada, for Nova Scotia. Stratigraphy is shown as depth below
                ground. Data is available online here:{" "}
                <a href="https://open.canada.ca/data/en/dataset/25181e31-f4d0-5534-a8bd-df06082af723">
                  Borehole Geophysical Logs in Surficial Sediments of Canada-Nova Scotia - Open Government Portal
                </a>
              </Typography>
            }
            placement="right"
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton disableRipple sx={{ "&:hover": { color: "secondary.dark" }, mt: "-0.75rem" }}>
              <InfoOutlined />
            </IconButton>
          </LightTooltip>
        </Box>
        <Box display="flex" flexDirection="column" mt="0.5rem">
          <Typography variant="caption" color="#86A2B3">
            Borehole Number : {datasetDetails?.BH_selec}
          </Typography>
          <Typography variant="caption" color="#86A2B3">
            Distance to Harbour : {distanceToHarbour}
          </Typography>
          <Typography variant="caption" color="#86A2B3">
            Date of Borehole : {boreholeDate}
          </Typography>
        </Box>
      </Box>
      {data && data.length > 0 && datasetDetails?.BH_selec !== 0 ? (
        <ReactEcharts
          key={renderKey}
          option={chartOption}
          style={{ height: "38rem", width: "30rem", marginLeft: "2rem" }}
        />
      ) : datasetDetails?.BH_selec === 0 ? (
        <Typography variant="h6" color="secondary.dark" display="flex" justifyContent="center" pt="30%">
          No Data Available
        </Typography>
      ) : (
        <Typography variant="h6" color="secondary.dark" display="flex" justifyContent="center" pt="30%">
          Loading Data ...
        </Typography>
      )}
    </Box>
  );
};

export default StackedBarChart;
