import { Box, SelectChangeEvent, Divider } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import DropdownSelect from "../../DropDownList/DropDownSelect";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface filteredData {
  setFilteredData: Dispatch<SetStateAction<any>>;
  directionValues: string[];
  setDirectionValues: Dispatch<SetStateAction<string[]>>;
  returnPeriodValue: string[];
  setReturnPeriodValue: Dispatch<SetStateAction<string[]>>;
  dataSourceValue: string;
  setDataSourceValue: Dispatch<SetStateAction<string>>;
}

export const WavesTabInputField = ({
  setFilteredData,
  directionValues,
  setDirectionValues,
  returnPeriodValue,
  setReturnPeriodValue,
  dataSourceValue,
  setDataSourceValue,
}: filteredData) => {
  const handleDataSourceChange = (event: SelectChangeEvent) => {
    setDataSourceValue(event.target.value as string);
  };

  const handleReturnPeriodChange = (event: SelectChangeEvent<typeof returnPeriodValue>) => {
    setReturnPeriodValue(event.target.value as typeof returnPeriodValue);
  };

  const handleDirectionChange = (event: SelectChangeEvent<typeof directionValues>) => {
    setDirectionValues(event.target.value as typeof directionValues);
  };

  const options = [
    {
      value: "Omni-Directional",
      label: "Omni-Directional (0 – 360)",
    },
    { value: "N", label: "N (348.75 – 11.25)" },
    { value: "NNE", label: "NNE (11.25 – 33.75)" },
    { value: "NE", label: "NE (33.75 – 56.25)" },
    { value: "ENE", label: "ENE (56.25 – 78.75)" },
    { value: "E", label: "E (78.75 – 101.25)" },
    { value: "ESE", label: "ESE (101.25 – 123.75)" },
    { value: "SE", label: "SE (123.75 – 146.25)" },
    { value: "SSE", label: "SSE (146.25 – 168.75)" },
    { value: "S", label: "S (168.75 – 191.25)" },
    { value: "SSW", label: "SSW (191.25 – 213.75)" },
    { value: "SW", label: "SW (213.75 – 236.25)" },
    { value: "WSW", label: "WSW (236.25 – 258.75)" },
    { value: "W", label: "W (258.75 – 281.25)" },
    { value: "WNW", label: "WNW (281.25 – 303.75)" },
    { value: "NW", label: "NW (303.75 – 326.25)" },
    { value: "NNW", label: "NNW (326.25 – 348.75)" },
  ];

  const ExceedanceProbablityOptions = [
    { value: "1", label: "100% AEP" },
    { value: "0.5", label: "50% AEP" },
    { value: "0.2", label: "20% AEP" },
    { value: "0.1", label: "10% AEP" },
    { value: "0.05", label: "5.0% AEP" },
    { value: "0.02", label: "2.0% AEP" },
    { value: "0.01", label: "1.0% AEP" },
    { value: "0.005", label: "0.5% AEP" },
  ];

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr 1fr",
          gap: "1rem",
          justifyContent: "center",
          m: "1rem 1rem",
          maxHeight: "2.5rem",
        }}
      >
        <DropdownSelect
          label="Data Source"
          value={dataSourceValue}
          onChange={handleDataSourceChange}
          options={[
            { value: "Regional Model", label: "Regional Model" },
            { value: "MSC50", label: "MSC50" },
            { value: "Wave Buoys", label: "Wave Buoys", disabled: true },
          ]}
        />

        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
                color: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          }}
        >
          <InputLabel id={`Annual Exceedance Probablity-drop-down`} style={{ color: "#86A2B3" }}>
            Annual Exceedance Probablity
          </InputLabel>
          <Select
            labelId={`Annual Exceedance Probablity-drop-down`}
            id={`Annual Exceedance Probablity-drop-down-select`}
            multiple
            value={returnPeriodValue}
            label="Annual Exceedance Probablity"
            onChange={handleReturnPeriodChange}
            size="small"
            renderValue={(selected) => {
              const count = (selected as string[]).length;
              return count > 1 ? `${count} items selected` : selected;
            }}
          >
            {ExceedanceProbablityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
                color: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
              color: "#86A2B3",
            },
          }}
        >
          <InputLabel id={`Wave Direction-drop-down`} style={{ color: "#86A2B3" }}>
            Wave Direction
          </InputLabel>
          <Select
            labelId={`Wave Direction-drop-down`}
            id={`Wave Direction-drop-down-select`}
            multiple
            value={directionValues}
            label="Wave Direction"
            onChange={handleDirectionChange}
            size="small"
            renderValue={(selected) => {
              const count = (selected as string[]).length;
              return count > 1 ? `${count} items selected` : selected;
            }}
          >
            {options
              .filter((option) => (dataSourceValue !== "MSC50" ? option.value !== "Omni-Directional" : option.value))
              .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Divider />
    </>
  );
};
