import { publicDataSets } from "./init";

export const getTileDataSets = async (id: string) => {
  const data = await fetch(
    `${publicDataSets.envURL}/metadata/project/${id}/dataset/recursive-list?limit=20000&datasetType=tiles`,
    {
      method: "GET",
      headers: { "Api-Version": "3", ...{ "dhi-open-api-key": publicDataSets.readerId } },
    }
  );
  return await data.json();
};

export const getTileDataSetsMetadata = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/tiles/dataset/${id}/metadata/`, {
    headers: { "Api-Version": "1", ...{ "dhi-open-api-key": publicDataSets.readerId } },
  });
  return await data.json();
};

export const getMdDataSets = async (id: string, pattern?: string) => {
  const data = await fetch(
    `${publicDataSets.envURL}/metadata/project/${id}/dataset/recursive-list?limit=20000&datasetType=Multidimensional${
      pattern ? "&endswith=" + pattern : ""
    }`,
    {
      method: "GET",
      headers: { "Api-Version": "3", ...{ "dhi-open-api-key": publicDataSets.readerId } },
    }
  );
  return await data.json();
};

export const getTimeSeriesMD = async (id: string, x: number, y: number, to: number) => {
  const data = await fetch(`${publicDataSets.envURL}/md/dataset/${id}/query-timeseries`, {
    method: "POST",
    body: JSON.stringify({
      itemFilter: {
        itemIndices: [0, 2],
      },
      spatialFilter: {
        geometry: `POINT (${x} ${y})`,
        srid: 2961,
      },
      temporalFilter: {
        type: "TemporalIndexFilter",
        from: 0,
        to: to,
      },
      verticalFilter: null,
      includeGeometries: true,
      includeValues: true,
      outputSRID: 4326,
    }),
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getTimeSeriesGisQuery = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${id}/query`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getTimeSeriesList = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/ts/dataset/${id}/timeseries/list`, {
    headers: { "Api-Version": "2", ...{ "dhi-open-api-key": publicDataSets.readerId } },
  });
  return await data.json();
};

export const getTimeSeriesValues = async (id: string, header: string) => {
  const data = await fetch(`${publicDataSets.envURL}/ts/dataset/${id}/timeseries/values`, {
    method: "POST",
    body: JSON.stringify([header]),
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getAllHarbours = async () => {
  try {
    const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${publicDataSets.SCHLocations.id}`, {
      headers: {
        "Api-Version": "2",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
        "Content-Type": "application/json",
      },
    });
    return await data.json();
  } catch (error) {}
};

export const getBoreHoleData = async () => {
  try {
    const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${publicDataSets.Boreholes.id}`, {
      headers: {
        "Api-Version": "2",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
        "Content-Type": "application/json",
      },
    });
    return await data.json();
  } catch (error) {}
};

export const getTimeseriesMDDetails = async (id: string) => {
  try {
    const data = await fetch(`${publicDataSets.envURL}/md/dataset/${id}`, {
      headers: {
        "Api-Version": "3",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
        "Content-Type": "application/json",
      },
    });
    return await data.json();
  } catch (error) {}
};

export const getTimeSeriesMDData = async (id: string, x: number, y: number, to: number, indices: number[]) => {
  const data = await fetch(`${publicDataSets.envURL}/md/dataset/${id}/query-timeseries`, {
    method: "POST",
    body: JSON.stringify({
      itemFilter: {
        itemIndices: indices,
      },
      spatialFilter: {
        geometry: `POINT (${x} ${y})`,
        srid: 4326,
      },
      temporalFilter: {
        type: "TemporalIndexFilter",
        from: 0,
        to: to,
      },
      verticalFilter: null,
      includeGeometries: true,
      includeValues: true,
      outputSRID: 4326,
    }),
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getCanadianBorderGis = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${id}`, {
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });

  return await data.json();
};

export const getGisVectorData = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${id}`, {
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });

  return await data.json();
};

export const getRawData = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/raw/dataset/${id}`, {
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });

  return await data.json();
};

export const getTimeSeriesGisQueryFeatures = async (dataset: string, portId: string, name: string) => {
  const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${dataset}/query`, {
    method: "POST",
    body: JSON.stringify({
      conditions: [
        {
          type: "AttributeQueryCondition",
          name: name,
          operator: "Equal",
          value: portId,
        },
      ],
    }),
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getTimeSeriesGisRaw = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/raw/dataset/${id}`, {
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  const dataURL = await data.json();
  const dataRequest = await fetch(dataURL.data);

  return await dataRequest.text();
};

export const getTimeSeriesGisValues = async (id: string, tsname: string, from?: string, to?: string) => {
  const data = await fetch(
    `${publicDataSets.envURL}/ts/dataset/${id}/timeseries/${tsname}/values${from ? `?from=${from}&to=${to}` : ""}`,
    {
      headers: {
        "Api-Version": "2",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
      },
    }
  );
  return await data.json();
};

export const getAllBathymetryDatasets = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/metadata/project/${id}/dataset/recursive-list?limit=20000`, {
    headers: {
      "Api-Version": "3",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getAllDatasetsInsideProject = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/metadata/project/${id}/dataset/list`, {
    headers: {
      "Api-Version": "3",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getGreatLakeCoordinates = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/gis/dataset/${id}`, {
    method: "GET",
    headers: {
      "Api-Version": "2",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const deleteBathymetryDataUnauthorised = async (id: string) => {
  try {
    const response = await fetch(`${publicDataSets.envURL}/metadata/dataset/${id}`, {
      method: "DELETE",
      headers: {
        "Api-Version": "3",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error deleting bathymetry data: ", error);
    throw error;
  }
};

export const getMetadata = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/metadata/dataset/${id}`, {
    method: "GET",
    headers: {
      "Api-Version": "3",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getCoordinateReferenceSystemOptions = async () => {
  const data = await fetch(`${publicDataSets.envURL}/crs/coordinate-system/list?fulltext=`, {
    method: "GET",
    headers: {
      "Api-Version": "2",
      "Dhi-Service-id": "gis",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getProjectMetadata = async (id: string) => {
  const data = await fetch(`${publicDataSets.envURL}/metadata/project/${id}`, {
    method: "GET",
    headers: {
      "Api-Version": "3",
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export const getNameOfUser = async () => {
  const data = await fetch(
    `${publicDataSets.envURL}/iam/project/${publicDataSets.PublicUserUpload.id}/principal/list`,
    {
      method: "GET",
      headers: {
        "Api-Version": "3",
        ...{ "dhi-open-api-key": publicDataSets.readerId },
        "Content-Type": "application/json",
      },
    }
  );
  return await data.json();
};

export const getTileDataSetCapabilities = async (id: any) => {
  const data = await fetch(`${publicDataSets.envURL}/tiles/dataset/${id}/wmts?service=wmts&request=getcapabilities`, {
    headers: { "dhi-open-api-key": publicDataSets.readerId },
  });
  return await data.text();
};

export const getAllCapabilitiesInProject = async (id: any, datasets: any) => {
  const data = await fetch(`${publicDataSets.envURL}/tiles/project/${id}/wmts/capabilities/list`, {
    method: "POST",
    body: JSON.stringify(datasets),
    headers: {
      ...{ "dhi-open-api-key": publicDataSets.readerId },
      "Content-Type": "application/json",
    },
  });
  return await data.json();
};

export async function tileLoader(imageTile: any, src: any) {
  const response = await fetch(src, { headers: { "dhi-open-api-key": publicDataSets.readerId } });
  const imageData = await response.blob();
  imageTile.getImage().src = window.URL.createObjectURL(imageData);
}
