import { Box, SelectChangeEvent, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { changeToolboxFileName } from "../../../../features/ToolboxFeatures/ToolBoxFileName";
import { changeToolboxFileDescription } from "../../../../features/ToolboxFeatures/ToolboxFileDescriptionSlice";
import UploadDropdownSelect from "../../UploadData/DataDescription/UploadDropDownSelect";
import { changeSeasonalFileType } from "../../../../features/ToolboxFeatures/SeasonalFileTypeSlice";

interface validationProps {
  setLeftValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
}

const fileTypeOptions = [
  { value: "Currents (Timeseries)", label: "Currents (Timeseries)" },
  { value: "Discharge (Timeseries)", label: "Discharge (Timeseries)" },
  { value: "Ice Thickness (Timeseries)", label: "Ice Thickness (Timeseries)" },
  { value: "Water Level (Timeseries)", label: "Water Level (Timeseries)" },
  { value: "Waves (Timeseries)", label: "Waves (Timeseries)" },
  { value: "Winds (Timeseries)", label: "Winds (Timeseries)" },
];

export const SeasonalDataDescriptionLeft = ({
  setLeftValidate,
  nextClicked,
  setNextClicked,
}: validationProps) => {
  const dispatch = useDispatch();
  const name = useSelector((state: RootState) => state.toolboxFileName.value);
  const description = useSelector(
    (state: RootState) => state.ToolboxFileDescription.value
  );

  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const fileType = useSelector(
    (state: RootState) => state.seasonalFileType.value
  );
  const [fileTypeError, setFileTypeError] = useState(false);
  const validateFields = () => {
    const nameError = name.trim() === "";
    const descriptionError = description.trim() === "";

    setNameError(nameError);
    setDescriptionError(descriptionError);

    return nameError || descriptionError || fileTypeError;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeToolboxFileName({ value: e.target.value }));
    setNameError(e.target.value === "");
  };

  const handlefileTypeChange = (event: SelectChangeEvent) => {
    dispatch(changeSeasonalFileType({ value: event.target.value as string }));
    setFileTypeError(!event.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeToolboxFileDescription({ value: e.target.value }));
    setDescriptionError(e.target.value === "");
  };

  useEffect(() => {
    setLeftValidate(() => validateFields);
  }, [name, description, fileType]);

  useEffect(() => {
    setFileTypeError(!fileType);
  }, [fileType]);

  return (
    <Box>
      <TextField
        label="Name"
        error={nameError}
        variant="outlined"
        size="small"
        value={name}
        onChange={handleNameChange}
        InputLabelProps={{
          style: {
            color: "#86A2B3",
          },
        }}
        sx={{
          mt: "1rem",
          ml: "1rem",
          width: "20rem",
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#86A2B3",
            },
        }}
      />
      <Box width="20rem" mt="0.75rem" ml="1rem">
        <UploadDropdownSelect
          label="File Type"
          value={fileType}
          onChange={handlefileTypeChange}
          options={fileTypeOptions}
          error={nextClicked && fileTypeError}
        />
      </Box>
      <TextField
        error={descriptionError}
        multiline={true}
        label="Description"
        variant="outlined"
        size="small"
        value={description}
        InputLabelProps={{
          style: {
            color: "#86A2B3",
          },
        }}
        onChange={handleDescriptionChange}
        sx={{
          mt: "1rem",
          ml: "1rem",
          width: "20rem",
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#86A2B3",
            },
        }}
        inputProps={{
          style: {
            height: "7rem",
          },
        }}
      />
    </Box>
  );
};
