import {
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { changeSummaryState } from "../../../features/DataRepoDataSummarySlice";
import { deletePoint } from "../../../features/mapPointsSlice";
import { clearPolygonCoordinates } from "../../../features/PolygonCoordinatesSlice";
import { changeClearButtonVisibilityState } from "../../../features/ClearButtonVisibilitySlice";
import { OpenlayersContext } from "../../Openlayers/OpenlayersContext";
import VectorLayer from "ol/layer/Vector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changeCheckedItems } from "../../../features/DataRepoCheckedITemsSlice";
import { Point, Polygon } from "ol/geom";
import { Icon, Stroke, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import { getCenter } from "ol/extent";
import { DeleteModal } from "./DeleteModal";
import { toolControls } from "../../Openlayers/data_layers";
import { deleteBathymetryData } from "../../../api/backend";
import { getAllBathymetryDatasets, getGisVectorData, getRawData } from "../../../api/backend_public";
import { get as getProjection, transform } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import { useSearchParams } from "react-router-dom";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { RootState } from "../../../store/store";
import WebGLPointsLayer from "ol/layer/WebGLPoints.js";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import OverlayPopup from "ol-ext/overlay/Popup";
import { Feature } from "ol";
import Select from "ol/interaction/Select";
import { singleClick } from "ol/events/condition";
import "ol/ol.css";
import { setLayerVisibility } from "../../../features/DataRepoLayerVisibilitySlice";
import { publicDataSets } from "../../../api/init";
import { setProcessedFilesWithMetadata } from "../../../features/DataRepoProcessedFilesDataSlice";
import { enqueueSnackbar } from "notistack";

interface FileProps {
  child: any;
  checkedItems: string[];
  handleChildCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, child: string) => void;
  name: string;
  wmtsLayers: any[];
  processedFiles: any[];
  setProcessedFiles: Dispatch<SetStateAction<any[]>>;
  gisLayers: GisLayersMap;
  setGisLayers: Dispatch<SetStateAction<GisLayersMap>>;
}
type GisLayersMap = { [key: string]: VectorLayer<VectorSource<Feature<Geometry>>> };

function convert(str: string) {
  var date = new Date(str);
  return `${date.getDate()} ${new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(date)} ${date.getFullYear()}`;
}

export function getIconStyleByDataType(datatype: string, name: string, checkedItems: string[]) {
  let iconSrc;

  const checkedNames = checkedItems.map((item) => (item.includes(".") ? item.split(".")[0] : item));
  const useHoverIcon = checkedNames.includes(name);
  switch (datatype) {
    case toolControls.BATHYMETRY:
      iconSrc = useHoverIcon ? "icons/Bathymetry_hover.svg" : "icons/Bathymetry.svg";
      break;
    case toolControls.WINDTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Winds_hover.svg" : "icons/Winds.svg";
      break;
    case toolControls.WAVESTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Waves_hover.svg" : "icons/Waves.svg";
      break;
    case toolControls.WATERLEVELTIMESEIRES:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    case toolControls.CURRENTSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Currents_hover.svg" : "icons/Currents.svg";
      break;
    case toolControls.DISCHARGETIMESERIES:
      iconSrc = useHoverIcon ? "icons/Discharge_hover.svg" : "icons/Discharge.svg";
      break;
    case toolControls.ICETHICKNESSTIMESERIES:
      iconSrc = useHoverIcon ? "icons/Ice_hover.svg" : "icons/Ice.svg";
      break;
    case toolControls.MODELRESULTSWAVE:
      iconSrc = useHoverIcon ? "icons/Wave_hover.svg" : "icons/Wave.svg";
      break;
    case toolControls.INFRASTRUCTUREDRAWING:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MISCFREEFORMAT:
      iconSrc = useHoverIcon ? "icons/File_hover.svg" : "icons/File.svg";
      break;
    case toolControls.MODELGRIDMESH:
      iconSrc = useHoverIcon ? "icons/Mesh_hover.svg" : "icons/Mesh.svg";
      break;
    case toolControls.MODELRESULTSHYDRO:
      iconSrc = useHoverIcon ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg";
      break;
    default:
      iconSrc = useHoverIcon ? "icons/Borehole_hover.svg" : "icons/Borehole.svg";
      break;
  }

  return new Style({
    image: new Icon({
      src: iconSrc,
      scale: 0.9,
    }),
  });
}

export const TableElements = ({
  child,
  checkedItems,
  handleChildCheckboxChange,
  name,
  wmtsLayers,
  processedFiles,
  setProcessedFiles,
  gisLayers,
  setGisLayers,
}: FileProps) => {
  register(proj4);
  const dispatch = useDispatch();
  const map = useContext(OpenlayersContext);
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isUploadCompleted = useSelector((state: RootState) => state.dataRepoUploadStatus.isUploadCompleted);
  const mapInstance = useContext(OpenlayersContext);
  const [openDelete, setOpenDelete] = useState(false);
  const open = Boolean(anchorEl);
  const isConverted = child.name.includes("(converted)");
  const layerVisibilityState = useSelector((state: RootState) => state.layerVisibility.layerVisibility);
  const [layerExtents, setLayerExtents] = useState<any>({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    handleClose();
  };

  const zoomToExtent = (sourceExtent: any) => {
    if (map && map.current) {
      const view = map?.current?.getView();
      if (view) {
        const center = getCenter(sourceExtent);
        const targetZoom = view.getZoomForResolution(
          view.getResolutionForExtent(sourceExtent, [
            0.8 * map?.current?.getSize()![0],
            0.8 * map?.current?.getSize()![1],
          ])
        );

        view.animate({
          center: center,
          zoom: targetZoom,
          duration: 1000,
        });
      }
    }
  };

  useEffect(() => {
    const getVectorSource = async (childId: string) => {
      const gisData = await getGisVectorData(childId);
      if (!gisData.Errors) {
        const features = new GeoJSON().readFeatures(gisData);
        const validFeatures = features.map((feature) => feature as Feature<Geometry>);

        const vectorSource = new VectorSource({
          features: validFeatures,
        });
        const extent = vectorSource?.getExtent();
        setLayerExtents((prevExtents: any) => ({ ...prevExtents, [childId]: extent }));
      }
    };
    if (child.datasetType === "gisvectordata") {
      getVectorSource(child.id);
    }
  }, []);

  const createGisVectorLayer = async (childId: string, fileCRSId: string) => {
    const gisData = await getGisVectorData(childId);

    const features = new GeoJSON().readFeatures(gisData);
    const validFeatures = features.map((feature) => feature as Feature<Geometry>);

    const vectorSource = new VectorSource({
      features: validFeatures,
    });
    const extent = vectorSource.getExtent();
    setLayerExtents((prevExtents: any) => ({ ...prevExtents, [childId]: extent }));
    const webGLLayer = new WebGLPointsLayer({
      source: vectorSource,
      style: {
        "circle-radius": 5,
        "circle-fill-color": [
          "interpolate",
          ["linear"],
          ["get", "Z"],
          -500,
          "rgb(63, 47, 129)",
          -470,
          "rgb(44,76,142)",
          -430.5,
          "rgb(33,117,150)",
          -390,
          "rgb(64,152,136)",
          -337.7,
          "rgb(131,184,132)",
          -290,
          "rgb(171, 200, 124)",
          -245,
          "rgb(218, 215, 152)",
          -200,
          "rgb(252, 248, 200)",
          -152.3,
          "rgb(252, 196, 140)",
          -100,
          "rgb(254, 149, 84)",
          -59.5,
          "rgb(209, 83, 41)",
          -20,
          "rgb(178, 24, 43)",
          10,
          "rgb(178, 24, 43)",
        ],
      },
    });

    const select = new Select({
      layers: [webGLLayer],
      condition: singleClick,
    });
    mapInstance?.current?.addInteraction(select);

    const popup = new OverlayPopup({
      popupClass: "default",
      closeBox: true,
      positioning: "auto",
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    mapInstance?.current?.addOverlay(popup);

    select.on("select", (evt) => {
      const selectedFeature = evt.selected[0];
      if (selectedFeature) {
        const geometry = selectedFeature.getGeometry();
        if (geometry instanceof Point) {
          const coordinate = geometry.getCoordinates();
          const zValue = selectedFeature.get("Z");
          popup.show(coordinate, `<div style="color: black;">Z Value: ${zValue}</div>`);
        }
      } else {
        popup.hide();
      }
    });

    return webGLLayer;
  };

  const addGisLayerToMap = async () => {
    if (!gisLayers[child.id] && child.fileCRSId) {
      const newGisLayer = await createGisVectorLayer(child.id, child.fileCRSId);
      setGisLayers({ ...gisLayers, [child.id]: newGisLayer });
      if (newGisLayer) {
        newGisLayer.set("id", `gislayers${child.id}`);
        mapInstance?.current?.addLayer(newGisLayer);
      }
    }
  };

  const removeGisLayerFromMap = () => {
    const layer = gisLayers[child.id];
    if (layer) {
      mapInstance?.current?.removeLayer(layer);
      const updatedLayers = { ...gisLayers };
      delete updatedLayers[child.id];
      setGisLayers(updatedLayers);
    }
  };

  const handleVisibilityIconClick = () => {
    const layerCurrentlyVisible = layerVisibilityState[child.id];
    if (!layerCurrentlyVisible) {
      addGisLayerToMap();
      dispatch(setLayerVisibility({ childId: child.id, isVisible: true }));
    } else {
      removeGisLayerFromMap();
      dispatch(setLayerVisibility({ childId: child.id, isVisible: false }));
    }
  };

  const handleZoomTo = (id: string) => {
    const view = mapInstance?.current?.getView();
    const extent = layerExtents[id];
    if (map?.current && extent) {
      map.current.getView().fit(extent, {
        padding: [100, 100, 100, 100],
        duration: 1000,
      });
    }
    if (child.fileXCoordinate && child.fileYCoordinate && view) {
      let proj4String;
      if (child.fileProj4String) {
        proj4String = child.fileProj4String;
      } else if (child.fileCRS) {
        const match = child.fileCRS.match(/proj4String:(.+)/);
        if (match && match.length > 1) {
          proj4String = match[1].trim();
        }
      }
      const customEPSGCode = `CUSTOM:${id}`;

      if (proj4String) {
        proj4.defs(customEPSGCode, proj4String);
        register(proj4);
      }

      const transformedCoordinates = transform(
        [child.fileXCoordinate, child.fileYCoordinate],
        customEPSGCode,
        view.getProjection()
      );

      view.animate({
        center: transformedCoordinates,
        zoom: 10,
        duration: 1000,
      });
    } else {
      const targetLayer = wmtsLayers.find((layer) => {
        const layerId = layer.get("id") || (layer.getProperties() && layer.getProperties().id);
        return layerId === id;
      });
      if (targetLayer && view) {
        const sourceExtent =
          targetLayer.get("sourceExtent") || (targetLayer.getProperties() && targetLayer.getProperties().sourceExtent);
        if (sourceExtent) {
          zoomToExtent(sourceExtent);
        } else if (!layerExtents[id]) {
          alert("Layer not found or view not available");
          console.error("Source extent not found for layer:", id);
        }
      } else if (!layerExtents[id]) {
        enqueueSnackbar("Layer not found or view not available. Try displaying the data on map first.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
    handleClose();
  };

  const removeFileExtension = (filename: string) => {
    return filename.split(".").slice(0, -1).join(".");
  };

  const removeBathymetryIconFromMap = (id: string) => {
    if (map?.current) {
      const layers = map.current.getLayers();
      if (layers) {
        layers.forEach((layer) => {
          if (layer && layer.get("id") === `bathymetryIconLayer${id}`) {
            map.current?.removeLayer(layer);
          }
        });
      }
    }
  };

  map?.current?.on("singleclick", function (evt) {
    const feature = map?.current?.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature;
    });

    if (feature && feature.get("featureId") === child.id) {
      handleDetailsClick();
    }
  });

  const handleDelete = async () => {
    removeBathymetryIconFromMap(child.id);

    const deletedFromFirst = await deleteFromDataset(publicDataSets.PublicUserUpload.id, child.id);

    if (!deletedFromFirst) {
      await deleteFromDataset(publicDataSets.PrivateUserUpload.id, child.id);
    }

    const updatedProcessedFiles = processedFiles.filter((file) => file.id !== child.id);
    setProcessedFiles(updatedProcessedFiles);
    dispatch(setProcessedFilesWithMetadata(updatedProcessedFiles));

    const updatedCheckedItems = checkedItems.filter((itemName) => itemName !== child.name);
    dispatch(changeCheckedItems({ value: updatedCheckedItems }));

    const filenameWithoutExtension = removeFileExtension(child.name);
    dispatch(deletePoint({ name: filenameWithoutExtension }));
    dispatch(clearPolygonCoordinates());
    dispatch(changeClearButtonVisibilityState({ value: false }));

    if (mapInstance && mapInstance.current) {
      const layers = mapInstance.current.getLayers();
      layers.forEach((layer) => {
        if (layer && layer.get("id") === "polygonLayer") {
          mapInstance.current?.removeLayer(layer);
        }
      });
    }

    handleCloseDelete();
    handleClose();
  };

  const deleteFromDataset = async (datasetId: string, fileId: string) => {
    try {
      const result = await getAllBathymetryDatasets(datasetId);
      const matchingDataset = result.data.find((dataset: any) => dataset.id === fileId);
      if (matchingDataset) {
        await deleteBathymetryData(matchingDataset.id);
        let filenameWithoutExtension = removeFileExtension(matchingDataset.name);
        dispatch(deletePoint({ name: filenameWithoutExtension }));
        return true;
      }
    } catch (error) {
      console.error("Error deleting dataset: ", error);
    }
    return false;
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const handleDetailsClick = () => {
    const { file, ...rest } = child;

    const payload = {
      filename: name,
      ...rest,
    };

    localStorage.setItem("fileDetails", JSON.stringify(payload));

    searchParams.set("dataset", name);
    setSearchParams(searchParams);

    dispatch(changeSummaryState({ value: true }));
  };

  const { idTokenPayload } = useOidcIdToken();

  const truncateName = (name: string): string => {
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  };
  const truncateUserName = (name: string): string => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };

  const shouldUseHoverIcon = (): boolean => {
    return isHovered || checkedItems.includes(name);
  };

  const isTileBoundaryDisplayed = (id: string) => {
    if (map?.current) {
      const layers = map.current.getLayers();
      return layers.getArray().some((layer) => layer.get("id") === `tileBoundaryLayer${id}`);
    }
    return false;
  };
  React.useEffect(() => {
    setIsVisible(isTileBoundaryDisplayed(child.id));
  }, []);
  return (
    <>
      <Box
        sx={{
          ml: "0.5rem",
          display: "grid",
          gridTemplateColumns: " 1.5rem 1fr",
        }}
      >
        <FormControlLabel
          key={name}
          control={
            <Checkbox checked={checkedItems.includes(name)} onChange={(e) => handleChildCheckboxChange(e, name)} />
          }
          label={""}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "42.5rem 1fr",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.7fr 0.25fr 0.7fr 1fr",

              "&:hover": {
                cursor: "pointer",
              },
            }}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
            onClick={handleDetailsClick}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2.5rem 1fr",
              }}
            >
              <Box mt="0.25rem" ml="0.25rem">
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {child.fileDataType === toolControls.BATHYMETRY ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Bathymetry_hover.svg" : "icons/Bathymetry.svg"}
                      alt={toolControls.BATHYMETRY}
                    />
                  ) : child.fileDataType === toolControls.WINDTIMESERIES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Winds_hover.svg" : "icons/Winds.svg"}
                      alt={toolControls.WINDTIMESERIES}
                    />
                  ) : child.fileDataType === toolControls.WAVESTIMESERIES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Waves_hover.svg" : "icons/Waves.svg"}
                      alt={toolControls.WAVESTIMESERIES}
                    />
                  ) : child.fileDataType === toolControls.WATERLEVELTIMESEIRES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/WaterLevel_hover.svg" : "icons/WaterLevel.svg"}
                      alt={toolControls.WATERLEVELTIMESEIRES}
                    />
                  ) : child.fileDataType === toolControls.CURRENTSTIMESERIES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Currents_hover.svg" : "icons/Currents.svg"}
                      alt={toolControls.CURRENTSTIMESERIES}
                    />
                  ) : child.fileDataType === toolControls.DISCHARGETIMESERIES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Discharge_hover.svg" : "icons/Discharge.svg"}
                      alt={toolControls.DISCHARGETIMESERIES}
                    />
                  ) : child.fileDataType === toolControls.ICETHICKNESSTIMESERIES ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Ice_hover.svg" : "icons/Ice.svg"}
                      alt={toolControls.ICETHICKNESSTIMESERIES}
                    />
                  ) : child.fileDataType === toolControls.BOREHOLELOGS ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Borehole_hover.svg" : "icons/Borehole.svg"}
                      alt={toolControls.BOREHOLELOGS}
                    />
                  ) : child.fileDataType === toolControls.MODELRESULTSWAVE ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Wave_hover.svg" : "icons/Wave.svg"}
                      alt={toolControls.MODELRESULTSWAVE}
                    />
                  ) : child.fileDataType === toolControls.INFRASTRUCTUREDRAWING ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Draw_hover.svg" : "icons/Draw.svg"}
                      alt={toolControls.INFRASTRUCTUREDRAWING}
                    />
                  ) : child.fileDataType === toolControls.MISCFREEFORMAT ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/File_hover.svg" : "icons/File.svg"}
                      alt={toolControls.MISCFREEFORMAT}
                    />
                  ) : child.fileDataType === toolControls.MODELGRIDMESH ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Mesh_hover.svg" : "icons/Mesh.svg"}
                      alt={toolControls.MODELGRIDMESH}
                    />
                  ) : child.fileDataType === toolControls.MODELRESULTSHYDRO ? (
                    <img
                      src={shouldUseHoverIcon() ? "icons/Current_hover.svg" : "icons/Current.svg"}
                      alt={toolControls.MODELRESULTSHYDRO}
                    />
                  ) : null}
                </Avatar>
              </Box>
              <Typography variant="body2" color="secondary.dark" pt="0.75rem" maxWidth="15rem" overflow="hidden">
                {truncateName(name)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography pt="0.75rem" variant="body2" color="secondary.dark">
                {child.rights}
              </Typography>
            </Box>
            <Typography pt="0.75rem" pl="1rem" variant="body2" color="secondary.dark" textAlign="left">
              {child.lastUpdated ? convert(child.lastUpdated?.toLocaleString()) : "N/A"}
            </Typography>
            <Typography pt="0.75rem" ml="-0.25rem" variant="body2" color="secondary.dark">
              {child.userName && child.userName !== "" ? truncateUserName(child.userName) : "System Data"}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mr="0rem" ml="-6rem">
            <IconButton disableRipple sx={{ color: "secondary.dark" }} disabled={isConverted || !child.datasetUrl}>
              <Link
                href={child.datasetUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <GetAppOutlinedIcon />
              </Link>
            </IconButton>
            <IconButton
              disableRipple
              sx={{ color: "secondary.dark" }}
              onClick={handleVisibilityIconClick}
              disabled={
                !isConverted ||
                child.fileDataType === toolControls.BOREHOLELOGS ||
                child.fileDataType === toolControls.CURRENTSTIMESERIES ||
                child.fileDataType === toolControls.DISCHARGETIMESERIES ||
                child.fileDataType === toolControls.ICETHICKNESSTIMESERIES ||
                child.fileDataType === toolControls.MISCFREEFORMAT ||
                child.fileDataType === toolControls.WATERLEVELTIMESEIRES ||
                child.fileDataType === toolControls.WAVESTIMESERIES ||
                child.fileDataType === toolControls.WINDTIMESERIES
              }
            >
              {layerVisibilityState[child.id] ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              disableRipple
              sx={{ color: "secondary.dark" }}
              id="delete-button"
              aria-controls={open ? "delete-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Menu
        id="delete-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "delete-button",
        }}
      >
        <MenuItem
          onClick={() => {
            if ("relativePath" in child && child.relativePath !== `${idTokenPayload.oid}/`) {
              return;
            }
            setOpenDelete(true);
            handleClose();
          }}
          disabled={("relativePath" in child && child.relativePath !== `${idTokenPayload?.oid}/`) || !idTokenPayload}
        >
          <Typography
            variant="body2"
            color={
              ("relativePath" in child && child.relativePath !== `${idTokenPayload?.oid}/`) || !idTokenPayload
                ? "gray"
                : "secondary.dark"
            }
          >
            Delete
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleZoomTo(child.id)} disabled={child.fileCRS === ""}>
          <Typography variant="body2" color="secondary.dark">
            Zoom To
          </Typography>
        </MenuItem>
      </Menu>

      <DeleteModal open={openDelete} handleClose={handleCloseDelete} handleDelete={handleDelete} child={child} />
    </>
  );
};
