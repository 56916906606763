import { Box, Grid, IconButton, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import React, { useState, useEffect } from "react";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { toolControls } from "../../Openlayers/data_layers";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { BathymetryGroup } from "../../Openlayers/default_groups_layers";
import { LegendContainer } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#09334B",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export const BathymetryLegend = () => {
  const toolname = new URLSearchParams(window.location.search).get("tool");

  const [dataSetLayers, setDataSetLayers] = React.useState<any[] | null>(null);
  const [selectedLayerIndex, setSelectedLayerIndex] = React.useState(0);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);

  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    getTileDataSetsMetadata(publicDataSets.Bathymetry.NonnaMerged.id).then((response) => {
      setDataSetLayers(response.tilingSchemes.map((t: any) => t));
      setDataSetMetadata(response);
    });

    return () => {};
  }, []);

  const handleChange = (e: any) => {
    const selctedIndex = BathymetryGroup.getLayersArray().findIndex((item) => item.get("id") === e.target.value);
    setSelectedLayerIndex(selctedIndex);
  };

  // const handleRangeChange = (e: any) => {
  //   setSelectedTimeStepIndex(e.target.value);
  //   BathymetryGroup.changed();
  // };

  return (
    <>
      {toolname === toolControls.BATHYMETRY && (
        <>
          <LegendContainer style={{ display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Bathymetry
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Bathymertry data is sourced from the:{" "}
                        <a
                          href="https://statics.teams.cdn.office.net/evergreen-assets/safelinks/1/atp-safelinks.html"
                          style={{ color: "#00A4EC" }}
                        >
                          Canadian Hydrographic Service Non-Navigational (NONNA) Bathymetric Data - Open Government
                          Portal (canada.ca).
                        </a>{" "}
                        Please refer to this source for terms and conditions, definitions, and futher dataset details.
                      </Typography>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2" mb="0.5rem">
                        Note that bathymetric data from the above source is provided with a vertical datum equal to
                        Chart Datum, and converted to CGVD2013 as part of the CCZIS project.
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>

              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12}>
                <DropdownSelect
                  label="Variable"
                  value={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
                  onChange={handleChange}
                  options={
                    dataSetLayers
                      ? dataSetLayers.map((l: any) => {
                          return { label: l.schemeId, value: l.schemeId };
                        })
                      : []
                  }
                />
              </Grid>
            </Grid>

            <ScaleBar
              tilingScheme={dataSetMetadata?.tilingSchemes[selectedLayerIndex]}
              schemeId={dataSetLayers ? dataSetLayers[selectedLayerIndex].schemeId : ""}
            />
          </LegendContainer>

          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
};
