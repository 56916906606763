import { createSlice } from "@reduxjs/toolkit";

export const SeasonalStatisticsToolboxSlice = createSlice({
  name: "datarepouploadStatus",
  initialState: {
    jobId: "",
    generateStatisticsClicked: false,
  },
  reducers: {
    setTimeseriesJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setTimeseriesGenerateStatisticsClicked: (state, action) => {
      state.generateStatisticsClicked = action.payload;
    },
  },
});

export const { setTimeseriesJobId, setTimeseriesGenerateStatisticsClicked } = SeasonalStatisticsToolboxSlice.actions;
export default SeasonalStatisticsToolboxSlice.reducer;
