const getConfigUrl = () => {
  switch (process.env.NODE_ENV) {
    case "local":
      return "/config/appsettings-local.json";
    case "dev":
      return "/config/appsettings-dev.json";
    default:
      return "/config/appsettings.json";
  }
};

async function fetchConfig() {
  const configPath = getConfigUrl();
  const resp = await fetch(configPath);
  const config = await resp.json();
  return config;
}

export default fetchConfig;
