import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import React, { useState, SetStateAction } from "react";
import { changeToolboxZNegative } from "../../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { changeDataRepoProcessFileState } from "../../../../features/DataRepoProcessFileSlice";
import { DataRepoTimeseriesTable } from "./DataRepoTimeseriesTable";
import { toolControls } from "../../../Openlayers/data_layers";

interface FileProps {
  processedFile: File | null;
  setProcessedFile: React.Dispatch<SetStateAction<File | null>>;
  file: File | null;
}

export const DataRepoWaveTimeSeries = ({
  processedFile,
  setProcessedFile,
  file,
}: FileProps) => {
  const dispatch = useDispatch();
  const fileformat = useSelector(
    (state: RootState) => state.fileDataFormat.value
  );

  const processFileState = useSelector(
    (state: RootState) => state.dataRepoProcessFile.value
  );
  const checked = useSelector(
    (state: RootState) => state.toolboxZNegative.value
  );
  const dataType = useSelector((state: RootState) => state.fileDataType.value);
  const [delimeter, setDelimeter] = useState("Comma");
  const [rowValue, setRowValue] = useState("1");
  const [otherValue, setOtherValue] = useState("");

  const handleOtherValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherValue((event.target as HTMLInputElement).value);
  };

  const handleRowValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRowValue(value === "" ? "1" : value);
  };

  const filename = useSelector((state: RootState) => state.fileName.value);

  const handleDelimeterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDelimeter((event.target as HTMLInputElement).value);
  };

  const handleChange = () => {
    dispatch(changeToolboxZNegative({ value: !checked }));
  };

  const processFile = async () => {
    if (!file) {
      return;
    }

    let fileType = "";
    switch (fileformat) {
      case "Text (.txt)":
        fileType = ".txt";
        break;
      case "Comma Separated (.csv)":
        fileType = ".csv";
        break;
      case "ASCII (.asc)":
        fileType = ".asc";
        break;
      case "MIKE DFS0 (.dfs0)":
        fileType = ".dfs0";
        break;
      default:
        return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const contents = e.target?.result;
      if (contents) {
        const processedFile = new File([contents], `${filename}${fileType}`, {
          type: file.type,
        });
        setProcessedFile(processedFile);
        dispatch(changeDataRepoProcessFileState({ value: false }));
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (processFileState && fileformat === toolControls.MIKEDFS0) {
      processFile();
    }
  }, [processFileState]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "16.5rem",
        overflow: "auto",
      }}
    >
      {fileformat === toolControls.MIKEDFS0 ? (
        <Box ml="2rem" mt="1rem">
          <Box display="flex" flexDirection="row" mb="0.75rem" gap="1rem">
            <Typography color="secondary.dark" variant="body2">
              Depths (Z) Negative:
            </Typography>
            <FormControlLabel
              checked={checked}
              onChange={handleChange}
              control={<Checkbox disableRipple />}
              label=""
              sx={{ mt: "-0.8rem" }}
            />
          </Box>
          <Typography color="secondary.dark" variant="body2">
            No other user inputs required.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            mt="1rem"
            ml="2rem"
            gap="1rem"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row">
              <Box>
                <Typography variant="body2" color="secondary.dark">
                  Delimeter:
                </Typography>
              </Box>

              <RadioGroup
                aria-label="typography"
                name="typography"
                value={delimeter}
                onChange={handleDelimeterChange}
              >
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr 1fr"
                  gap="0.5rem"
                  mt="-0.75rem"
                  ml="1rem"
                >
                  <Box
                    sx={{
                      border: "1px solid #86A2B3",
                      borderRadius: "4px",
                      p: "0 0.2rem ",
                      height: "1.75rem",
                    }}
                  >
                    <FormControlLabel
                      value="Comma"
                      control={<Radio size="small" sx={{ p: 0, m: 0 }} />}
                      label={
                        <Typography
                          color="secondary.dark"
                          variant="body2"
                          mt="0.1rem"
                        >
                          Comma
                        </Typography>
                      }
                      sx={{ m: 0 }}
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "1px solid #86A2B3",
                      borderRadius: "4px",
                      p: "0 0.2rem ",
                      height: "1.75rem",
                    }}
                  >
                    <FormControlLabel
                      value="Other"
                      control={<Radio size="small" sx={{ p: 0, m: 0 }} />}
                      label={
                        <Typography
                          color="secondary.dark"
                          variant="body2"
                          mt="0.1rem"
                        >
                          Other
                        </Typography>
                      }
                      sx={{ m: 0 }}
                    />
                  </Box>
                  <Box>
                    <TextField
                      value={otherValue}
                      onChange={handleOtherValueChange}
                      disabled={delimeter !== "Other"}
                      size="small"
                      sx={{
                        width: "3rem",
                        "& input": {
                          fontSize: "1.1rem",
                          padding: "1px 6px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #86A2B3",
                      borderRadius: "4px",
                      p: "0 0.2rem ",
                      height: "1.75rem",
                    }}
                  >
                    <FormControlLabel
                      value="Tab"
                      control={<Radio size="small" sx={{ p: 0, m: 0 }} />}
                      label={
                        <Typography
                          color="secondary.dark"
                          variant="body2"
                          mt="0.1rem"
                        >
                          Tab
                        </Typography>
                      }
                      sx={{ m: 0 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #86A2B3",
                      borderRadius: "4px",
                      p: "0 0.2rem ",
                      height: "1.75rem",
                    }}
                  >
                    <FormControlLabel
                      value="Space"
                      control={<Radio size="small" sx={{ p: 0, m: 0 }} />}
                      label={
                        <Typography
                          color="secondary.dark"
                          variant="body2"
                          mt="0.1rem"
                        >
                          Space
                        </Typography>
                      }
                      sx={{ m: 0 }}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Box>
            <Box mr="2rem">
              <Box display="flex" flexDirection="row">
                <Typography color="secondary.dark" variant="body2">
                  Start import at Row:
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  value={rowValue}
                  onChange={handleRowValueChange}
                  inputProps={{ min: "1" }}
                  sx={{
                    width: "4rem",
                    mt: "-0.25rem",
                    ml: "0.25rem",
                    "& input": {
                      fontSize: "1rem",
                      padding: "1px 6px",
                    },
                  }}
                />
              </Box>
              {dataType === toolControls.BATHYMETRY && (
                <Box
                  display="flex"
                  flexDirection="row"
                  mb="0.75rem"
                  gap="1rem"
                  mt="0.5rem"
                >
                  <Typography color="secondary.dark" variant="body2">
                    Depths (Z) Negative:
                  </Typography>
                  <FormControlLabel
                    checked={checked}
                    onChange={handleChange}
                    control={<Checkbox disableRipple />}
                    label=""
                    sx={{ mt: "-0.8rem" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box mt="1rem">
            <DataRepoTimeseriesTable
              otherValue={otherValue}
              delimeter={delimeter}
              processedFile={processedFile}
              setProcessedFile={setProcessedFile}
              rowValue={rowValue}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
