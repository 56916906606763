import "ol/ol.css";
import { Map, View } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { defaults as defaultInteractions } from "ol/interaction";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Attribution, ScaleLine, defaults as defaultControls } from "ol/control";
import {
  baseMapsGroup,
  bathymaryPointersLayer,
  clusterLayer,
  drawInteraction,
  drawLayer,
} from "./default_groups_layers";
import { boundingExtent } from "ol/extent";
import { fitToExtent } from "./data_layers";
import { useNavigate } from "react-router-dom";
import proj4 from "proj4";
import { Box, Typography } from "@mui/material";
import { BathymetryLegend } from "../MapViewer/Legends/BathymetryLegend";
import MapViewerIcons from "../MapIcons/MapViewerIcons";
import { iconTypes } from "./styles";
import SeaLevelRiseLegend from "../MapViewer/Legends/SeaLevelRiseLegend";
import WaveLegend from "../MapViewer/Legends/WaveLegend";
import { WaterLevelLegend } from "../MapViewer/Legends/WaterLevelLegend";
import IceCoverLegend from "../MapViewer/Legends/IceCoverLegend";
import WindLegend from "../MapViewer/Legends/WindLegend";

interface MapProps {
  setMapInstance: MutableRefObject<Map | null>;
}
function getUTMZone(longitude: number) {
  return (Math.floor((longitude + 180) / 6) % 60) + 1;
}

interface CoordinatesDisplayProps {
  coords: [number, number];
  format: string | null;
  utmZone: number | null;
}

const CoordinatesDisplay = React.memo(({ coords, format, utmZone }: CoordinatesDisplayProps) => {
  let formattedCoords;
  if (format === "Geographic (lat/long)") {
    formattedCoords = `Latitude: ${coords[1].toFixed(4)}, Longitude: ${coords[0].toFixed(4)}`;
  } else if (format === "UTM (Easting/Northing)" && utmZone) {
    const epsgCode = `EPSG:326${utmZone < 10 ? "0" + utmZone : utmZone}`;

    if (!proj4.defs(epsgCode)) {
      proj4.defs(epsgCode, `+proj=utm +zone=${utmZone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`);
    }

    const utmCoords = proj4("EPSG:4326", epsgCode, coords);
    formattedCoords = `Easting: ${utmCoords[0].toFixed(2)}, Northing: ${utmCoords[1].toFixed(2)}, Zone: ${utmZone}`;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      }}
    >
      <Typography
        variant="caption"
        color="black"
        style={{
          width: "max-content",
          display: "block",
          background: "rgba(255,255,255, 0.75)",
          borderRadius: "0 0 4px 4px",
          margin: "0 auto",
          fontWeight: 500,
          padding: "0 1rem",
        }}
      >
        {formattedCoords}
      </Typography>
    </Box>
  );
});

const Openlayers = ({ setMapInstance }: MapProps) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const toolValue = params.get("tool");
  const topbarCoordinatesValue = localStorage.getItem("coordinatesValue")
    ? localStorage.getItem("coordinatesValue")
    : "Geographic (lat/long)";

  const topbarCoordinatesValueRef = useRef(topbarCoordinatesValue);
  const [currentCoords, setCurrentCoords] = useState<[number, number] | null>(null);

  const [measureClicked, setMeasureClicked] = useState(false);
  const [drawClicked, setDrawClicked] = useState(false);
  const [currentUTMZone, setCurrentUTMZone] = useState<number | null>(null);
  useEffect(() => {
    topbarCoordinatesValueRef.current = topbarCoordinatesValue;
  }, [topbarCoordinatesValue]);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = new Map({
      target: mapRef.current,
      maxTilesLoading: Infinity,
      layers: [baseMapsGroup, drawLayer],
      view: new View({
        center: fromLonLat([-61, 47]),
        zoom: 3,
      }),
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: false,
      }).extend([
        new Attribution({
          collapsible: false,
        }),
        new ScaleLine({
          units: "metric",
        }),
      ]),
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false,
        shiftDragZoom: false,
      }).extend([drawInteraction]),
    });

    setMapInstance.current = map;

    map.on("click", function (event) {
      try {
        clusterLayer
          .getFeatures(event.pixel)
          .then((clickedFeatures: any) => {
            try {
              const features = clickedFeatures[0]?.get("features");
              if (features?.length === 1) {
                navigate(`/dashboard?port=${clickedFeatures[0].get("features")[0].get("Harbour_nu")}`);
              }
              if (features?.length > 1) {
                const extent = boundingExtent(features.map((f: any) => f.getGeometry().getCoordinates()));
                fitToExtent(setMapInstance, extent);
              }
            } catch (error) {
              console.error("Error handling clicked features:", error);
            }
          })
          .catch((error) => {
            console.error("Error getting features:", error);
          });
      } catch (error) {
        console.error("Error in click event handler:", error);
      }
    });

    map.on("pointermove", (e) => {
      const coordinates = e.coordinate;
      const lonLat = toLonLat(coordinates, "EPSG:3857");
      const newUTMZone = getUTMZone(lonLat[0]);

      if (
        topbarCoordinatesValueRef.current === "UTM (Easting/Northing)" &&
        (currentUTMZone === null || newUTMZone !== currentUTMZone)
      ) {
        setCurrentUTMZone(newUTMZone);
        setCurrentCoords(lonLat as [number, number]);
      } else if (topbarCoordinatesValueRef.current === "Geographic (lat/long)") {
        setCurrentCoords(lonLat as [number, number]);
      }
    });

    let selected: any = null;
    map.on("pointermove", function (e) {
      if (selected !== null) {
        selected.setStyle(undefined);
        map.getTargetElement().style.cursor = "default";
        selected = null;
      }

      map.forEachFeatureAtPixel(
        e.pixel,
        function (f: any) {
          selected = f;

          if (bathymaryPointersLayer.getSource()?.hasFeature(f)) {
            f.setStyle(iconTypes.Bathymetry_hover);
          }
          map.getTargetElement().style.cursor = "pointer";
          return true;
        },
        {
          layerFilter: function (layer) {
            return layer === clusterLayer || layer === bathymaryPointersLayer;
          },
          hitTolerance: 10,
        }
      );
    });

    let lastPointerMove = Date.now();
    map.on("pointermove", function (evt) {
      const now = Date.now();
      if (now - lastPointerMove > 100) {
        lastPointerMove = now;
        const pixel = map.getEventPixel(evt.originalEvent);
        const targetElement = map.getTargetElement();
        if (pixel && targetElement) {
          const hit = map.hasFeatureAtPixel(pixel);
          targetElement.style.cursor = hit ? "pointer" : "";
        }
      }
    });

    return () => map.setTarget(undefined);
  }, []);

  return (
    <>
      <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
      {currentCoords && (
        <CoordinatesDisplay
          coords={currentCoords}
          format={topbarCoordinatesValueRef.current}
          utmZone={currentUTMZone}
        />
      )}
      <MapViewerIcons setMeasureClicked={setMeasureClicked} setDrawClicked={setDrawClicked} />

      <BathymetryLegend />
      <WaveLegend />
      <WindLegend />
      <SeaLevelRiseLegend />
      <WaterLevelLegend />
      <IceCoverLegend />
    </>
  );
};

export default Openlayers;
