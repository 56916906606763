import { Box, TextField, InputAdornment, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import DropdownSelect from "../../DropDownList/DropDownSelect";
import { Dispatch, SetStateAction, useEffect } from "react";
import { DropdownSelectDisbaled } from "../../Navigation/SettingsModal";

interface DataProps {
  assetElevation: number | null;
  setAssetElevation: Dispatch<SetStateAction<number | null>>;
  bedElevation: number | null;
  setBedElevation: Dispatch<SetStateAction<number | null>>;
  seaLevelSCValue: string;
  setSeaLevelSCValue: Dispatch<SetStateAction<string>>;
  seaLevelHOValue: string;
  setSeaLevelHOValue: Dispatch<SetStateAction<string>>;
  seaLevelModelValue: string;
  setSeaLevelModelValue: Dispatch<SetStateAction<string>>;
  waterLevelAEPValue: string;
  setWaterLevelAEPValue: Dispatch<SetStateAction<string>>;
}

export const DashboardWaterLevelDropDown = ({
  assetElevation,
  setAssetElevation,
  bedElevation,
  setBedElevation,
  seaLevelSCValue,
  setSeaLevelSCValue,
  setSeaLevelHOValue,
  setSeaLevelModelValue,
  seaLevelHOValue,
  seaLevelModelValue,
  waterLevelAEPValue,
  setWaterLevelAEPValue,
}: DataProps) => {
  const [datumValue, setDatumValueValue] = useState("CGVD2013");
  const handleDatumChange = (event: SelectChangeEvent) => {
    setDatumValueValue(event.target.value as string);
  };
  const handleSeaLevelSCChange = (event: SelectChangeEvent) => {
    setSeaLevelSCValue(event.target.value as string);
  };
  const handleSeaLevelHOChange = (event: SelectChangeEvent) => {
    setSeaLevelHOValue(event.target.value as string);
  };
  const handleSeaLevelModelChange = (event: SelectChangeEvent) => {
    setSeaLevelModelValue(event.target.value as string);
  };
  const handleWaterLevelAEPChange = (event: SelectChangeEvent) => {
    setWaterLevelAEPValue(event.target.value as string);
  };

  const handleAssetElevationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAssetElevation(value === "" ? null : Number(value));
  };

  const handleBedElevationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setBedElevation(value === "" ? null : Number(value));
  };

  const CanewaltOptions = [
    {
      value: "RCP 2.6 (mean)",
      label: "RCP 2.6 (mean)",
    },
    {
      value: "RCP 4.5 (mean)",
      label: "RCP 4.5 (mean)",
    },
    {
      value: "RCP 8.5 (mean)",
      label: "RCP 8.5 (mean)",
    },
  ];

  const cccsar5Options = [
    {
      value: "RCP 2.6 (p05)",
      label: "RCP 2.6 (p05)",
    },
    {
      value: " RCP 2.6 (p50)",
      label: " RCP 2.6 (p50)",
    },
    {
      value: "RCP 2.6 (p95)",
      label: "RCP 2.6 (p95)",
    },
    {
      value: "RCP 4.5 (p05)",
      label: "RCP 4.5 (p05)",
    },
    {
      value: "RCP 4.5 (p50)",
      label: "RCP 4.5 (p50)",
    },
    {
      value: "RCP 4.5 (p95)",
      label: "RCP 4.5 (p95)",
    },
    {
      value: "RCP 8.5 (p05)",
      label: "RCP 8.5 (p05)",
    },
    {
      value: "RCP 8.5 (p50)",
      label: "RCP 8.5 (p50)",
    },
    {
      value: "RCP 8.5 (p95)",
      label: "RCP 8.5 (p95)",
    },
  ];

  const cccsar6Options = [
    {
      value: "SSP2-4.5 (p05)",
      label: "SSP2-4.5 (p05)",
    },
    {
      value: "SSP2-4.5 (p10)",
      label: "SSP2-4.5 (p10)",
    },
    {
      value: "SSP2-4.5 (p50)",
      label: "SSP2-4.5 (p50)",
    },
    {
      value: "SSP2-4.5 (p90)",
      label: "SSP2-4.5 (p90)",
    },
    {
      value: "SSP2-4.5 (p95)",
      label: "SSP2-4.5 (p95)",
    },
    {
      value: "SSP5-8.5 (p05)",
      label: "SSP5-8.5 (p05)",
    },
    {
      value: "SSP5-8.5 (p10)",
      label: "SSP5-8.5 (p10)",
    },
    {
      value: "SSP5-8.5 (p50)",
      label: "SSP5-8.5 (p50)",
    },
    {
      value: "SSP5-8.5 (p90)",
      label: "SSP5-8.5 (p90)",
    },
    {
      value: "SSP5-8.5 (p95)",
      label: "SSP5-8.5 (p95)",
    },
  ];

  const CanewaltSLROptions = [
    { value: 2020, label: "2020" },
    { value: 2030, label: "2030" },
    { value: 2040, label: "2040" },
    { value: 2050, label: "2050" },
    { value: 2060, label: "2060" },
    { value: 2070, label: "2070" },
    { value: 2080, label: "2080" },
    { value: 2090, label: "2090" },
    { value: 2100, label: "2100" },
  ];

  const cccsar5SLROptions = [
    { value: 2006, label: "2006" },
    { value: 2010, label: "2010" },
    { value: 2020, label: "2020" },
    { value: 2030, label: "2030" },
    { value: 2040, label: "2040" },
    { value: 2050, label: "2050" },
    { value: 2060, label: "2060" },
    { value: 2070, label: "2070" },
    { value: 2080, label: "2080" },
    { value: 2090, label: "2090" },
    { value: 2100, label: "2100" },
  ];

  const cccsar6SLROptions = [
    { value: 2020, label: "2020" },
    { value: 2030, label: "2030" },
    { value: 2040, label: "2040" },
    { value: 2050, label: "2050" },
    { value: 2060, label: "2060" },
    { value: 2070, label: "2070" },
    { value: 2080, label: "2080" },
    { value: 2090, label: "2090" },
    { value: 2100, label: "2100" },
    { value: 2100, label: "2150" },
  ];

  useEffect(() => {
    seaLevelModelValue === "CAN-EWLAT (Allowance)"
      ? setSeaLevelSCValue("RCP 2.6 (mean)")
      : seaLevelModelValue === "CCCS (AR5)"
      ? setSeaLevelSCValue("RCP 2.6 (p05)")
      : setSeaLevelSCValue("SSP2-4.5 (p05)");
  }, [seaLevelModelValue]);

  useEffect(() => {
    seaLevelModelValue === "CAN-EWLAT (Allowance)"
      ? setSeaLevelHOValue("2100")
      : seaLevelModelValue === "CCCS (AR5)"
      ? setSeaLevelHOValue("2100")
      : seaLevelModelValue === "NASA (AR6)"
      ? setSeaLevelHOValue("2020")
      : "";
  }, [seaLevelModelValue]);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "1rem",
          justifyContent: "center",
          m: "0.5rem 1rem",
          maxHeight: "2.5rem",
        }}
      >
        <TextField
          label="Asset Elevation"
          variant="outlined"
          size="small"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
          InputLabelProps={{
            style: {
              color: "#86A2B3",
            },
          }}
          value={assetElevation !== null ? assetElevation : ""}
          onChange={handleAssetElevationChange}
          sx={{
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
          }}
        />
        <TextField
          label="Bed Elevation"
          variant="outlined"
          type="number"
          size="small"
          value={bedElevation !== null ? bedElevation : ""}
          onChange={handleBedElevationChange}
          InputLabelProps={{
            style: {
              color: "#86A2B3",
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
          sx={{
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
          }}
        />
        <DropdownSelectDisbaled
          label="Datum"
          value={datumValue}
          onChange={handleDatumChange}
          options={[
            { value: "CGVD28 (HT2_1997)", label: "CGVD28 (HT2_1997)" },
            { value: "CGVD28 (HT2_2002)", label: "CGVD28 (HT2_2002)" },
            { value: "CGVD28 (HT2_2010)", label: "CGVD28 (HT2_2010)" },
            { value: "CGVD2013a", label: "CGVD2013a" },
            { value: "CGVD2013", label: "CGVD2013" },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "1rem",
          justifyContent: "center",
          m: "1rem 1rem",
          maxHeight: "2.5rem",
        }}
      >
        <DropdownSelect
          label="SLR Model"
          value={seaLevelModelValue}
          onChange={handleSeaLevelModelChange}
          options={[
            { value: "CAN-EWLAT (Allowance)", label: "CAN-EWLAT (Allowance)" },
            { value: "CCCS (AR5)", label: "CCCS (AR5)", disabled: true },
            { value: "NASA (AR6)", label: "NASA (AR6)", disabled: true },
          ]}
        />

        <DropdownSelect
          label="SLR Scenario"
          value={seaLevelSCValue}
          onChange={handleSeaLevelSCChange}
          options={
            seaLevelModelValue === "CAN-EWLAT (Allowance)"
              ? CanewaltOptions
              : seaLevelModelValue === "CCCS (AR5)"
              ? cccsar5Options
              : cccsar6Options
          }
        />

        <DropdownSelect
          label="SLR Horizon"
          value={seaLevelHOValue}
          onChange={handleSeaLevelHOChange}
          options={
            seaLevelModelValue === "CAN-EWLAT (Allowance)"
              ? CanewaltSLROptions
              : seaLevelModelValue === "CCCS (AR5)"
              ? cccsar5SLROptions
              : cccsar6SLROptions
          }
        />

        <DropdownSelect
          label="Extreme Water Level AEP"
          value={waterLevelAEPValue}
          onChange={handleWaterLevelAEPChange}
          options={[
            { value: "100% AEP", label: "100% AEP" },
            { value: "50% AEP", label: "50% AEP" },
            { value: "20% AEP", label: "20% AEP" },
            { value: "10% AEP", label: "10% AEP" },
            { value: "5.0% AEP", label: "5.0% AEP" },
            { value: "2.0% AEP", label: "2.0% AEP" },
            { value: "1.0% AEP", label: "1.0% AEP" },
            { value: "0.5% AEP", label: "0.5% AEP" },
          ]}
        />
      </Box>
    </>
  );
};
