import { IconButton, Typography, Box, Backdrop, Modal, Fade, Button } from "@mui/material";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { PrivacyModal } from "../Footer/PrivacyModal";
import { useState } from "react";
import { TandCModal } from "../Footer/TandCModal";
import { CopyrightModal } from "../Footer/CopyrightModal";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const FrontPageModal = ({ open, handleClose }: ModalProps) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsConditionsModalOpen, setIsTermsConditionsModalOpen] = useState(false);
  const [isCopyrightModalOpen, setIsCopyrightModalOpen] = useState(false);
  const togglePrivacyModal = () => setIsPrivacyModalOpen(!isPrivacyModalOpen);
  const toggleTermsConditionsModal = () => setIsTermsConditionsModalOpen(!isTermsConditionsModalOpen);
  const toggleCopyrightModal = () => setIsCopyrightModalOpen(!isCopyrightModalOpen);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      <Modal
        aria-labelledby="settings-modal-title"
        aria-describedby="settings-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            onClick: handleBackdropClick,
          },
        }}
        sx={{ backdropFilter: "blur(10px)" }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "rgba(255,255,255,0.9)",
              boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
              borderRadius: "8px",
              maxHeight: "90vh",
              overflow: "auto",
              width: { xs: "90%", sm: "75%", md: "60%", lg: "55rem" },
            }}
          >
            <Box>
              <Box display="flex" flexDirection="row">
                <Box width="100%">
                  <Typography
                    variant="h2"
                    sx={{
                      mt: "1rem",
                      ml: "2rem",
                      textAlign: "inherit",
                      color: "black",
                    }}
                  >
                    Disclaimer
                  </Typography>
                </Box>
              </Box>
              <Box m="1rem 2rem 1rem 2rem">
                <Typography variant="body2" color="black" fontWeight="700">
                  The CCZIS is currently under development and testing.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  CBCL Limited (CBCL) and DHI Water and Environment Inc. (DHI) provide this website as a service and for
                  informational purposes only.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  The transmission of this information does not create any form of relationship with us. You should not
                  act upon information provided on this site without seeking our personal assistance.
                  <b>
                    While we have attempted to maintain the information, the software and any other services on this
                    site as accurately as possible, the site may contain errors or omissions for which we disclaim any
                    and all liability.
                  </b>
                  The material and contents posted on this site is provided without warranty of any kind.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  <b>
                    Through use of this website, you consent to collection of cookies, which are gathered automatically
                    to improve the website performance.
                  </b>
                  The user is directed to our{" "}
                  <span style={{ cursor: "pointer", color: "blue" }} onClick={togglePrivacyModal}>
                    Privacy Policy
                  </span>
                  , which includes information on Consent, Collection and Retention of Personal Information, and our
                  Cookie Policy.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  The user is also directed to the{" "}
                  <span style={{ cursor: "pointer", color: "blue" }} onClick={toggleTermsConditionsModal}>
                    Terms & Conditions
                  </span>
                  , which provides details on the terms and conditions related to the usage of this website, and data
                  and links provided therein.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  The user is also directed to the{" "}
                  <span style={{ cursor: "pointer", color: "blue" }} onClick={toggleCopyrightModal}>
                    Copyright
                  </span>{" "}
                  Notice.
                </Typography>
                <Typography variant="body2" color="black" mt="1rem">
                  We welcome your inquiries, feedback, and questions. If you have any concerns or need assistance,
                  please reach out to the below email. We strive to respond to your inquiries as promptly as possible.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  ml: "1rem",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                    pb: "1rem",
                  }}
                >
                  <IconButton
                    disableRipple
                    sx={{
                      "&:hover": {
                        cursor: "default",
                      },
                    }}
                  >
                    <MailOutlineOutlinedIcon fontSize="large" sx={{ color: "secondary.dark" }} />
                  </IconButton>
                  <Box>
                    <Typography variant="body2" color="secondary.dark">
                      General Inquiries
                    </Typography>
                    <Typography variant="body2" color="secondary.dark">
                      Email: <a href="mailto:info@cczis.ca?subject=CCZIS Inquiry">info@cczis.ca</a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box width="100%" textAlign={"center"}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{
                    mb: "1rem",
                    textAlign: "center",
                    justifyContent: "center",
                    width: "50%",
                    color: "secondary.dark",
                  }}
                >
                  <Typography variant="body1">I Consent to the Terms & Conditions</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <PrivacyModal open={isPrivacyModalOpen} handleClose={togglePrivacyModal} />
      <TandCModal open={isTermsConditionsModalOpen} handleClose={toggleTermsConditionsModal} />
      <CopyrightModal open={isCopyrightModalOpen} handleClose={toggleCopyrightModal} />
    </>
  );
};
