import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToolboxFileNameSliceState {
  value: string;
}

const initialState: ToolboxFileNameSliceState = {
  value: "",
};

export const ToolboxFileNameSlice = createSlice({
  name: "filename",
  initialState,
  reducers: {
    changeToolboxFileName: (
      state,
      action: PayloadAction<ToolboxFileNameSliceState>
    ) => {
      return (state = action.payload);
    },
  },
});

export default ToolboxFileNameSlice.reducer;

export const { changeToolboxFileName } = ToolboxFileNameSlice.actions;
