import { Draw } from "ol/interaction";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Cluster, XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { collectionStyle, iconTypes } from "./styles";

export const harboursLayer = new VectorLayer({
  source: new VectorSource(),
  visible: false,
  zIndex: 2,
});
export const canadianBorderLayer = new VectorLayer({
  source: new VectorSource(),
  maxZoom: 8,
  visible: true,
  zIndex: 1,
  style: collectionStyle.Polygon,
});

export const drawLayer = new VectorLayer({
  source: new VectorSource(),
  visible: false,
});
export const drawInteraction = new Draw({
  source: drawLayer.getSource()!,
  type: "Polygon",
});
drawInteraction.setActive(false);

export const clusterSource = new Cluster({
  distance: 30,
  minDistance: 10,
  source: harboursLayer.getSource()!,
});

export const bathymaryPointersLayer = new VectorLayer({
  source: new VectorSource(),
  visible: false,
  zIndex: 3,
  style: iconTypes.Bathymetry,
});

const styleCache: any = {};
export const clusterLayer = new VectorLayer({
  visible: false,
  source: clusterSource,
  zIndex: 2,
  style: function (feature, resolution) {
    const features = feature.get("features");
    const size = features.length;

    const baseRadius = 10;
    const multiplier = 1;

    const circleRadius = size === 1 ? baseRadius : baseRadius + multiplier * Math.sqrt(size);

    let text;
    let offsetY = 0;

    if (size === 1) {
      text = features[0].get("Harbour_Na");
      offsetY = 15;
    } else {
      text = size.toString();
    }

    let style = styleCache[size];
    if (!style) {
      style = new Style({
        image: new CircleStyle({
          radius: circleRadius,
          fill: new Fill({ color: "#00A4EC" }),
          stroke: new Stroke({
            color: "#ffffff",
            width: 2,
          }),
        }),

        text: new Text({
          text: text,
          font: `${resolution < 1000 ? 12 : 16}px Roboto`,
          fill: new Fill({
            color: "white",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,1)",
            width: 3,
          }),
          offsetY: offsetY,
        }),
      });
      return style;
    }
  },
});

export const baseMapsGroup = new LayerGroup({
  visible: true,
  layers: [
    new TileLayer({
      source: new XYZ({
        url: "https://api.maptiler.com/maps/e94d0110-9b75-4485-b12f-9aebc6c9d713/{z}/{x}/{y}.png?key=yUODsamnqGkS27n1vMBe",
        attributions: `© <a target="_blank" href="https://maptiler.com/copyright">MapTiler</a> © <a target="_blank" href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>`,
      }),
      preload: 12,
      properties: {
        name: "Default",
      },
      visible: true,
    }),
    new TileLayer({
      source: new XYZ({
        url: "https://api.maptiler.com/maps/dfbdabaa-61bf-4ddf-860e-a13bd30383e6/{z}/{x}/{y}.png?key=yUODsamnqGkS27n1vMBe",
        attributions: `© <a target="_blank" href="https://maptiler.com/copyright">MapTiler</a> © <a target="_blank" href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>`,
      }),
      preload: 12,
      properties: {
        name: "Satellite",
      },
      visible: false,
    }),
    new TileLayer({
      source: new XYZ({
        url: "https://api.maptiler.com/maps/8dad8ad8-5375-4331-b35a-4a1eda2fd3a2/{z}/{x}/{y}@2x.png?key=yUODsamnqGkS27n1vMBe",
        attributions: `© <a target="_blank" href="https://maptiler.com/copyright">MapTiler</a> © <a target="_blank" href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>`,
      }),
      preload: 12,
      properties: {
        name: "Light",
      },
      visible: false,
    }),
    new TileLayer({
      source: new XYZ({
        url: "https://api.maptiler.com/maps/cfecb45c-004a-4ec8-a2ba-35ed698cd6d1/256/{z}/{x}/{y}@2x.png?key=yUODsamnqGkS27n1vMBe",
        attributions: `© <a target="_blank" href="https://maptiler.com/copyright">MapTiler</a> © <a target="_blank" href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>`,
      }),
      preload: 12,
      properties: {
        name: "Dark",
      },
      visible: false,
    }),
    new TileLayer({
      source: new XYZ({
        url: `https://api.maptiler.com/maps/ocean/256/{z}/{x}/{y}@2x.png?key=yUODsamnqGkS27n1vMBe`, // source URL
        tileSize: 512,
        crossOrigin: "anonymous",
      }),
      preload: 12,
      properties: {
        name: "Ocean",
      },
      visible: false,
    }),
    new TileLayer({
      source: new XYZ({
        url: `https://api.maptiler.com/maps/toner-v2/256/{z}/{x}/{y}.png?key=yUODsamnqGkS27n1vMBe`, // source URL
        tileSize: 512,
        crossOrigin: "anonymous",
      }),
      preload: 12,
      properties: {
        name: "Toner",
      },
      visible: false,
    }),
    new TileLayer({
      properties: {
        name: "None",
      },
      visible: false,
    }),
  ],
});
export const wmtsGroup = new LayerGroup({
  visible: false,
});
export const debugGroup = new LayerGroup({
  visible: false,
});

export const BathymetryGroup = new LayerGroup({
  visible: false,
  maxZoom: 10,
});
export const BathymetryGroup10m = new LayerGroup({
  visible: false,
  minZoom: 10,
});
export const WaveGroup = new LayerGroup({
  visible: false,
});
export const WindGroup = new LayerGroup({
  visible: false,
});
export const WaterLevelGroup = new LayerGroup({
  visible: false,
});
export const IceCoverGroupA = new LayerGroup({
  visible: false,
});
export const IceCoverGroupB = new LayerGroup({
  visible: false,
});
export const SeaLevelRiseGroupA = new LayerGroup({
  visible: false,
});
export const SeaLevelRiseGroupB = new LayerGroup({
  visible: false,
});
