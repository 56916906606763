import {
  IconButton,
  Typography,
  Box,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export const ContactUsModal = ({ open, handleClose }: ModalProps) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: handleBackdropClick,
        },
      }}
      sx={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: "0px 16px 32px -8px rgba(9, 51, 75, 0.5)",
            borderRadius: "8px",
            maxHeight: "90vh",
            overflow: "auto",
            width: { xs: "90%", sm: "75%", md: "60%", lg: "45rem" },
          }}
        >
          <Box>
            <Box display="flex" flexDirection="row">
              <Box textAlign="center" width="100%">
                <Typography
                  variant="h2"
                  sx={{
                    mt: "2rem",
                    ml: { xs: "1rem", sm: "2rem", md: "4rem" },
                    textAlign: "inherit",
                  }}
                >
                  Contact Us
                </Typography>
              </Box>
              <IconButton
                sx={{ mr: "2rem", mt: "1rem" }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>
            <Box m="1rem 2rem 1rem 2rem">
              <Typography variant="body2" color="black" textAlign="center">
                We welcome your inquiries, feedback, and questions. If you have
                any concerns or need assistance, please reach out to the below
                email. We strive to respond to your inquiries as promptly as
                possible.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                  pb: "1rem",
                }}
              >
                <IconButton
                  disableRipple
                  sx={{
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                >
                  <MailOutlineOutlinedIcon
                    fontSize="large"
                    sx={{ color: "secondary.dark" }}
                  />
                </IconButton>
                <Box>
                  <Typography
                    variant="body2"
                    color="secondary.dark"
                    textAlign="center"
                  >
                    General Inquiries
                  </Typography>
                  <Typography
                    variant="body2"
                    color="secondary.dark"
                    textAlign="center"
                  >
                    Email:{" "}
                    <a href="mailto:info@cczis.ca?subject=CCZIS Inquiry">
                      info@cczis.ca
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
