import { Upload } from "./Upload";
import { Box, Typography, IconButton, Stack, Radio, SelectChangeEvent } from "@mui/material";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Dispatch, SetStateAction, useContext } from "react";
import { Document } from "@dhi/icons/dist";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { changeToolboxZNegative } from "../../../features/ToolboxFeatures/ToolboxZNegativeSlice";
import { changeFilePrivacy } from "../../../features/DataRepoPrivacySlice";
import { changeFileName } from "../../../features/DataRepoFileNameSlice";
import { changeFileDescription } from "../../../features/DataRepoDescriptionSlice";
import { changeFileDataFormat } from "../../../features/DataRepoDataFormatSlice";
import { changeFileCRS } from "../../../features/DataRepoCRSSlice";
import { changeFileDatum } from "../../../features/DataRepoDatumSlice";
import { changeFileSurveyDate } from "../../../features/DataRepoSurveyDateSlice";
import { changeFileDataType } from "../../../features/DataRepoFileDataTypeSlice";
import UploadDropdownSelect from "./UploadDropDownSelect";
import { changeGroupValue } from "../../../features/DataRepoGroupValueSlice";
import { InfoModal } from "./InfoModal";
import { DataRepoFileContext } from "./DataRepoFileContext/DataRepoFileContext";

interface filesProps {
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  uploading: boolean;
  activeState: boolean;
  setActiveState: Dispatch<SetStateAction<boolean>>;
  setUploading: Dispatch<SetStateAction<boolean>>;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  setGroupValidation: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
}

const FILE_SIZE_LIMIT = 500 * 1024 * 1024;

export function useFileContext() {
  const context = useContext(DataRepoFileContext);

  if (!context) {
    throw new Error("useFileContext must be used within a FileProvider");
  }

  return context;
}

const UploadDataFiles = ({
  file,
  setFile,
  uploading,
  setUploading,
  setActiveState,
  setChecked,
  setGroupValidation,
  nextClicked,
  setNextClicked,
}: filesProps) => {
  const { newFile, setNewFile } = useFileContext();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const groupSelection = useSelector((state: RootState) => state.groupValue.value);
  const handleClose = () => setOpen(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const handleOpenClick = () => {
    setOpen(true);
  };
  const value = useSelector((state: RootState) => state.filePrivacy.value);
  const [groupError, setGroupError] = useState(true);

  const handleGroupSelectionValue = (event: SelectChangeEvent) => {
    dispatch(changeGroupValue({ value: event.target.value }));
    setGroupError(!event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFilePrivacy({ value: event.target.value }));
  };

  const checkFileSize = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      setFileSizeError(true);
      setUploading(false);
      return false;
    }
    setFileSizeError(false);
    return true;
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const droppedFile = droppedFiles[0];

    if (droppedFile && checkFileSize(droppedFile)) {
      setUploading(true);
      setNewFile(droppedFile);
      setUploading(false);
      setActiveState(true);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile && checkFileSize(selectedFile)) {
      setUploading(true);
      setNewFile(selectedFile);
      setUploading(false);
      setActiveState(true);
    }
  };

  useEffect(() => {
    if (newFile) {
      setFile(newFile);
    }
  }, [newFile]);

  useEffect(() => {
    setGroupValidation(() => () => {
      setNextClicked(true);
      const errorExists = groupError;

      return errorExists;
    });
  }, [groupError]);

  useEffect(() => {
    setGroupError(!groupSelection);
  }, [groupSelection]);

  return (
    <Box>
      {uploading ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "23rem 1fr",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ overflowY: "auto", overflowX: "hidden", height: "16.5rem" }}>
            <Box mt="1rem" ml="2rem">
              <Typography variant="body2" color="secondary.dark" fontWeight="700">
                Selected data
              </Typography>
            </Box>

            <Box>
              <Box ml="2rem">
                <Stack direction="row" spacing="1rem">
                  <CircularProgress size="1.25rem" sx={{ mt: "1rem" }} />
                  <Box>
                    <Typography variant="body2" color="secondary.dark" mt="1.25rem">
                      {newFile?.name}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        newFile && (
          <Box sx={{ overflowY: "auto", overflowX: "hidden", height: "16.5rem" }}>
            <Box m="1rem 0rem 0rem 2rem">
              <Typography variant="body2" color="secondary.dark" fontWeight="700">
                Selected data
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Box>
                <Box mb="-1rem" ml="2rem">
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      xs: "2rem 24rem 1fr",
                      sm: "2rem 22rem 1fr",
                      md: "2rem 28rem 1fr",
                      lg: "2rem 34.75rem 1fr",
                    }}
                    mb="0.5rem"
                  >
                    <Box ml="-1rem">
                      <IconButton
                        disableRipple
                        sx={{
                          color: "secondary.dark",
                          "&:hover": { cursor: "default" },
                        }}
                      >
                        <Document fontSize="large" />
                      </IconButton>
                    </Box>

                    <Typography variant="body2" color="secondary.dark" mt="1.2rem">
                      {newFile?.name}
                    </Typography>

                    <IconButton
                      disableRipple
                      sx={{ color: "secondary.dark" }}
                      onClick={() => {
                        setNewFile(null);
                        setActiveState(false);
                        setChecked(false);
                        dispatch(changeFilePrivacy({ value: "Public" }));
                        dispatch(changeFileName({ value: "" }));
                        dispatch(changeFileDescription({ value: "" }));
                        dispatch(changeFileDataFormat({ value: "" }));
                        dispatch(changeFileCRS({ CRS: { value: "", id: null, proj4String: "", wkt: "" } }));
                        dispatch(changeFileDataType({ value: "" }));
                        dispatch(changeFileDatum({ value: "" }));
                        dispatch(changeFileSurveyDate({ value: null }));
                        dispatch(changeToolboxZNegative({ value: false }));
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box ml="2rem" mt={value === "Group" ? "3rem" : "6rem"} sx={{ transition: "margin-top 0.3s ease-in-out" }}>
              <Box display="flex" flexDirection="row" gap="0rem">
                <Typography color="secondary.dark" fontWeight="700" variant="body2">
                  Privacy Control
                </Typography>

                <IconButton
                  disableRipple
                  sx={{
                    color: "secondary.dark",
                    mt: "-0.75rem",
                  }}
                  onClick={handleOpenClick}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Box>

              <Box>
                <Box display="flex" flexDirection="row">
                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                    }}
                    display="flex"
                    flexDirection="row"
                  >
                    <Radio
                      checked={value === "Public"}
                      onChange={handleRadioChange}
                      value="Public"
                      name="radio-button-demo"
                      color="secondary"
                      inputProps={{ "aria-label": "Public" }}
                    />

                    <Typography variant="caption" mt="0.75rem" mr="1rem">
                      Public
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                    }}
                    display="flex"
                    flexDirection="row"
                  >
                    <Radio
                      checked={value === "Group"}
                      onChange={handleRadioChange}
                      value="Group"
                      name="radio-button-demo"
                      color="secondary"
                      inputProps={{ "aria-label": "Group" }}
                      disabled
                    />

                    <Typography variant="caption" mt="0.75rem" mr="1rem">
                      Group
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                    }}
                    display="flex"
                    flexDirection="row"
                  >
                    <Radio
                      checked={value === "Private"}
                      onChange={handleRadioChange}
                      value="Private"
                      name="radio-button-demo"
                      color="secondary"
                      inputProps={{ "aria-label": "Private" }}
                    />

                    <Typography variant="caption" mt="0.75rem" mr="1rem">
                      Private
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {value === "Group" && (
              <Box ml="2rem" width="20rem" mt="0.5rem">
                <UploadDropdownSelect
                  label="Group Selection"
                  value={groupSelection}
                  onChange={handleGroupSelectionValue}
                  options={[
                    { value: "SCH Managers", label: "SCH Managers" },
                    { value: "DHI Managers", label: "DHI Managers" },
                    { value: "CBCL Managers", label: "CBCL Managers" },
                  ]}
                  error={nextClicked && groupError}
                />
              </Box>
            )}
          </Box>
        )
      )}

      {!uploading && newFile === null && (
        <Box
          sx={{
            width: {
              xs: "95%",
              sm: "95%",
              md: "95%",
              lg: "42rem",
            },
            height: "16rem",
            border: "#86A2B3 dotted",
            ml: "1rem",
            mt: "0.5rem",
            borderRadius: "0.5rem",
          }}
          onDrop={handleFileDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Upload handleFileUpload={handleFileUpload} />
        </Box>
      )}
      {fileSizeError && (
        <Typography
          color="error"
          variant="body2"
          mt="-2rem"
          mb="1.35rem"
          ml="2rem"
          display="flex"
          justifyContent="center"
        >
          File size exceeds 500 MB. Please choose a smaller file.
        </Typography>
      )}
      <InfoModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default UploadDataFiles;
