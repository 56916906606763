import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IconsState {
  value: string;
}

const initialState: IconsState = {
  value: "",
};

export const ToolsIconSlice = createSlice({
  name: "dashboardPortDetail",
  initialState,
  reducers: {
    changeToolsIconName: (state, action: PayloadAction<IconsState>) => {
      return (state = action.payload);
    },
  },
});

export default ToolsIconSlice.reducer;

export const { changeToolsIconName } = ToolsIconSlice.actions;
