import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import DropdownSelect from "../../DropDownList/DropDownSelect";
import dayjs, { Dayjs } from "dayjs";
import { getTimeSeriesList, getTimeSeriesValues } from "../../../api/backend_public";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { platform, publicDataSets } from "../../../api/init";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setTimeseriesGenerateStatisticsClicked,
  setTimeseriesJobId,
} from "../../../features/ToolboxFeatures/SeasonalStatisticsSlice";
import { useOidcIdToken } from "@axa-fr/react-oidc";

interface DateProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  selectedValue: string;
  setStartDate: Dispatch<SetStateAction<Dayjs | null>>;
  setEndDate: Dispatch<SetStateAction<Dayjs | null>>;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  seasonalFiles: any[];
  setSeasonalFiles: Dispatch<SetStateAction<any[]>>;
  fileTimePeriod: {
    start: string;
    end: string;
  };
  setFileTimePeriod: Dispatch<
    SetStateAction<{
      start: string;
      end: string;
    }>
  >;
  processResponse: any;
  setProcessResponse: Dispatch<SetStateAction<any>>;
  processLog: string | null;
  setProcessLog: Dispatch<SetStateAction<string | null>>;
  setChartData: Dispatch<SetStateAction<any | null>>;
}

interface DropdownOption {
  value: string;
  label: string;
}

interface Mapping {
  [key: string]: string;
}

export const SeasonalStatisticsTimePeriod = ({
  startDate,
  endDate,
  selectedValue,
  setStartDate,
  setEndDate,
  setSelectedValue,
  fileTimePeriod,
  setFileTimePeriod,
  seasonalFiles,
  setProcessResponse,
  processResponse,
  setProcessLog,
  processLog,
  setChartData,
}: DateProps) => {
  const [endDateError, setEndDateError] = useState(false);
  const [idNameMapping, setIdNameMapping] = useState<Mapping>({});
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [seasonalValue, setSeasonalValue] = useState("Monthly");
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [matchingkey, setMatchingkey] = useState<string>("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const jobId = useSelector((state: RootState) => state.seasonalToolbox.jobId);
  const generateStatisticsClicked = useSelector((state: RootState) => state.seasonalToolbox.generateStatisticsClicked);
  const dispatch = useDispatch();
  const { idTokenPayload } = useOidcIdToken();

  const processTarget =
    seasonalFiles.length > 0
      ? seasonalFiles[0].mainProjectId
        ? seasonalFiles[0].mainProjectId
        : seasonalFiles[0].projectId
      : "";
  const [processSchema, setProcessSchema] = useState("");

  useEffect(() => {
    let uploadSnackbarKey: any = null;
    if (generateStatisticsClicked && !processLog) {
      uploadSnackbarKey = enqueueSnackbar("Generating statistics...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "#FFFFFF" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }

    if (processLog) {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
      console.log("processResponse ==>", processResponse);
      if (processResponse.statusMessage === "Output ready") {
        enqueueSnackbar("Successfully generated statistics", {
          variant: "success",
          autoHideDuration: 4000,
        });
      } else {
        enqueueSnackbar("Error generating statistics", {
          variant: "error",
          autoHideDuration: 4000,
        });
      }
    }
    return () => {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
    };
  }, [generateStatisticsClicked, processLog, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (seasonalFiles.length > 0) {
      const file = seasonalFiles.find((file) => file.name.includes("(converted)"));
      if (file) {
        const datasetId = file.mainDatasetId ? file.mainDatasetId : file.id;
        const projectId = file.mainProjectId ? file.mainProjectId : file.projectId;

        const commandString = `python main.py '{"projectId": "${projectId}", "datasetId": "${datasetId}", "tsId": "${matchingkey}", "start": "${dayjs(
          startDate
        ).format("YYYY-MM")}", "end": "${dayjs(endDate).format("YYYY-MM")}", "type": "${seasonalValue}","env":"${
          publicDataSets.env
        }"}'`;

        const processSchemaValue = JSON.stringify(
          {
            Runtime: {
              type: "ContainerRuntimeSpec",
              Containers: [
                {
                  Image: publicDataSets.dockerImage,
                  Command: ["/bin/sh", "-c", commandString],
                },
              ],
            },
          },
          undefined,
          2
        );
        setProcessSchema(processSchemaValue);
      }
    }
  }, [seasonalFiles, matchingkey, startDate, endDate, seasonalValue]);

  useEffect(() => {
    if (seasonalFiles.length > 0) {
      const getList = async () => {
        const result = await getTimeSeriesList(
          seasonalFiles[0].mainDatasetId ? seasonalFiles[0].mainDatasetId : seasonalFiles[0].id
        );
        if (result && result.data) {
          const mapping: Mapping = {};
          const newHeaders: string[] = [];
          const newDropdownOptions: DropdownOption[] = [];
          result.data.forEach((item: any) => {
            const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(item.id);
            if (isUUID) {
              mapping[item.id] = item.item.name;
              newHeaders.push(item.item.name);
              newDropdownOptions.push({ value: item.item.name, label: item.item.name });
            } else {
              mapping[item.id] = item.id;
              newHeaders.push(item.id);
              newDropdownOptions.push({ value: item.id, label: item.id });
            }
          });
          setIdNameMapping(mapping);
          setDropdownOptions(newDropdownOptions);
        }
      };
      getList();
    }
  }, [seasonalFiles]);

  const fetchAndSetTimeSeriesData = async (headerName: any) => {
    if (seasonalFiles.length > 0) {
      const selectedId = Object.keys(idNameMapping).find((key) => idNameMapping[key] === headerName);
      if (selectedId && seasonalFiles) {
        try {
          const timeSeriesValues = await getTimeSeriesValues(
            seasonalFiles[0].mainDatasetId ? seasonalFiles[0].mainDatasetId : seasonalFiles[0].id,
            selectedId
          );
          setTimeSeriesData(timeSeriesValues.data[0]);
        } catch (error) {
          console.error("Error fetching time series values:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (timeSeriesData.length > 0) {
      const firstDate = dayjs(timeSeriesData[0][0]).format("YYYY-MM-DD");
      const lastDate = dayjs(timeSeriesData[timeSeriesData.length - 1][0]).format("YYYY-MM-DD");

      setFileTimePeriod({ start: firstDate, end: lastDate });
    }
  }, [timeSeriesData]);

  const handleSelectedValueChange = (event: SelectChangeEvent) => {
    const headername = event.target.value as string;
    setSelectedValue(headername);
    const matchingKey = Object.keys(idNameMapping).find((key) => idNameMapping[key] === headername);
    if (matchingKey) {
      setMatchingkey(matchingKey);
    }
    fetchAndSetTimeSeriesData(headername);
    setChartData(null);
  };

  const handleSeasonalValue = (event: React.ChangeEvent<HTMLInputElement> | null) => {
    setSeasonalValue((event?.target as HTMLInputElement).value);
    setChartData(null);
  };

  const handleEndDateChange = (value: dayjs.Dayjs | null) => {
    if (startDate && value && startDate.isAfter(value)) {
      setEndDateError(true);
      setEndDate(null);
      return;
    } else {
      setEndDateError(false);
      setEndDate(value);
      setChartData(null);
    }
  };

  const handleGenerateStatistics = async () => {
    dispatch(setTimeseriesGenerateStatisticsClicked(true));
    setChartData(null);
    setProcessResponse(null);
    setProcessLog(null);

    if (processSchema) {
      const data = await platform.postJson("process/job", JSON.parse(processSchema), "3", {
        "dhi-project-id": processTarget,
      });
      dispatch(setTimeseriesJobId(data.jobId));
      const refreshIntervalId = setInterval(async function () {
        const convertingItem = await platform.getJson(`process/job/${data.jobId}`, "3", {
          "dhi-project-id": processTarget,
        });
        if (convertingItem.jobState === "Running") {
          setProcessResponse(convertingItem);
        }
        if (convertingItem.jobState === "Finished" || convertingItem.jobState === "Error") {
          setProcessResponse(convertingItem);
          clearInterval(refreshIntervalId);

          const convertingLog = await platform.getJson(`process/job/${data.jobId}/log`, "3", {
            "dhi-project-id": processTarget,
          });
          setProcessLog(convertingLog.logs[0].log);
          dispatch(setTimeseriesJobId(""));
          dispatch(setTimeseriesGenerateStatisticsClicked(false));
        }
      }, 1000);

      setTimeout(() => {
        clearInterval(refreshIntervalId);
      }, 120000);

      setProcessResponse(data);
    }
  };

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setTimeseriesGenerateStatisticsClicked(false));
    }
  };

  return (
    <>
      <Box pl="1rem" mt="0.75rem" display="flex" flexDirection="column">
        <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.65rem">
          Select Variable
        </Typography>
        <Box mt="0.75rem" width="20rem">
          <DropdownSelect
            label="Selected Variable"
            value={selectedValue}
            onChange={handleSelectedValueChange}
            options={dropdownOptions}
          />
        </Box>
        <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.65rem">
          Select Time Period
        </Typography>
        <Typography variant="caption" color="grey.500">
          Timeseries length: {fileTimePeriod.start} - {fileTimePeriod.end}
        </Typography>
      </Box>
      <Box ml="1rem" mt="0.5rem" display="flex" flexDirection="row" gap="1rem">
        <DatePicker
          views={["month", "year"]}
          slotProps={{ textField: { size: "small" } }}
          sx={{
            width: "15rem",
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
            "& .MuiSvgIcon-root": {
              color: "#09334B",
            },
          }}
          value={startDate}
          onChange={(value) => {
            setStartDate(value);
            setChartData(null);
          }}
          label="Start [mm/yyyy]"
          minDate={dayjs(fileTimePeriod.start)}
          maxDate={dayjs(fileTimePeriod.end)}
        />
        <DatePicker
          views={["month", "year"]}
          slotProps={{
            textField: {
              size: "small",
              error: endDateError,
              helperText: endDateError ? "End date should be after the start date." : "",
            },
          }}
          sx={{
            width: "15rem",
            "& .MuiInputLabel-root": {
              color: "#86A2B3",
            },

            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#86A2B3",
              },
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#86A2B3",
            },
            "& .MuiSvgIcon-root": {
              color: "#09334B",
            },
          }}
          value={endDate}
          onChange={handleEndDateChange}
          label="End [mm/yyyy]"
          minDate={dayjs(fileTimePeriod.start)}
          maxDate={dayjs(fileTimePeriod.end)}
        />
      </Box>

      <Box ml="1rem" mt="1rem" display="flex" flexDirection="row" gap="1rem" justifyContent="space-between">
        <Box>
          <Typography variant="body2" color="secondary.dark" fontWeight="700">
            Select Analysis Type
          </Typography>
          <Box>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="seasonal-radio-button"
                name="seasonal"
                value={seasonalValue || null}
                onChange={handleSeasonalValue}
              >
                <FormControlLabel
                  value="Monthly"
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      variant="body2"
                      color="secondary.dark"
                      sx={{
                        fontWeight: seasonalValue === "Monthly" ? 700 : 400,
                      }}
                    >
                      Monthly
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="Seasonal"
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      variant="body2"
                      color="secondary.dark"
                      sx={{
                        fontWeight: seasonalValue === "Seasonal" ? 700 : 400,
                      }}
                    >
                      Seasonal
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="Annual"
                  control={<Radio size="small" />}
                  label={
                    <Typography
                      variant="body2"
                      color="secondary.dark"
                      sx={{
                        fontWeight: seasonalValue === "Annual" ? 700 : 400,
                      }}
                    >
                      Annual
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          {generateStatisticsClicked && (
            <LightTooltip title="Stop interpolation" placement="left" enterDelay={500} leaveDelay={200}>
              <IconButton disableRipple sx={{ color: "red", mt: "-0.5rem" }} onClick={stopInterpolation}>
                <StopCircleOutlinedIcon fontSize="large" />
              </IconButton>
            </LightTooltip>
          )}
          <LightTooltip
            title="Login to use the toolbox"
            placement="left"
            enterDelay={500}
            leaveDelay={200}
            disableHoverListener={!!idTokenPayload}
          >
            <span>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                disabled={selectedValue === "" || startDate === null || endDate === null}
                sx={{ mr: "1rem", mb: "0.5rem", width: "10rem" }}
                onClick={generateStatisticsClicked ? () => {} : handleGenerateStatistics}
              >
                {generateStatisticsClicked ? (
                  <>
                    <CircularProgress color="inherit" size="2rem" />
                  </>
                ) : (
                  <Typography variant="body2" fontWeight="500">
                    Generate Statistics
                  </Typography>
                )}
              </Button>
            </span>
          </LightTooltip>
        </Box>
      </Box>
    </>
  );
};
