import { Box, Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { platform, publicDataSets } from "../../../api/init";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { createPersonalProject, deleteBathymetryData, getSubProjectList } from "../../../api/backend";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import { getAllDatasetsInsideProject } from "../../../api/backend_public";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setWaveProfileGenerateStatisticsClicked,
  setWaveProfileJobId,
} from "../../../features/ToolboxFeatures/WaveProfileToolboxSlice";
import { LightTooltip } from "../../MapViewer/Legends/BathymetryLegend";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

interface TextFieldProps {
  label: JSX.Element | string;
  text: string;
  value: number | null;
  onChange: (e: any) => void;
  error?: boolean;
  helperText?: string;
}

interface WaveProps {
  setDatasetId: Dispatch<SetStateAction<string>>;
  clicked: boolean;
  setClicked: Dispatch<SetStateAction<boolean>>;
  offshoreDepth: { value: number | null; error: boolean };
  setOffshoreDepth: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  slopeSteepness: { value: number | null; error: boolean };
  setSlopeSteepness: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  bedDisturbanceHeight: { value: number | null; error: boolean };
  setBedDisturbanceHeight: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  offshoreDistance: { value: number | null; error: boolean };
  setOffShoreDistance: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  distOfBedDisturbance: { value: number | null; error: boolean };
  setDistOfBedDisturbance: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  beachOrientation: { value: number | null; error: boolean };
  setBeachOrientation: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  significantWaveHeight: { value: number | null; error: boolean };
  setSignificantWaveHeight: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  dominantWaveDirection: { value: number | null; error: boolean };
  peakWavePeriod: { value: number | null; error: boolean };
  setDominantWaveDirection: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  setPeakWavePeriod: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
  stillWaterLevel: { value: number | null; error: boolean };
  setStillWaterLevel: Dispatch<SetStateAction<{ value: number | null; error: boolean }>>;
}

const TextFieldComponent = ({ label, text, value, onChange, error = false, helperText = "" }: TextFieldProps) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 0.1fr" gap="0.5rem">
      <TextField
        error={error}
        helperText={helperText}
        id="data-type-textfield"
        label={label}
        size="small"
        value={value === null ? "" : value}
        type="number"
        InputLabelProps={{ style: { color: "#86A2B3" } }}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#86A2B3",
          },
          "& .Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        }}
      />
      <Typography color="grey.500" mt="0.5rem">
        {text}
      </Typography>
    </Box>
  );
};

export const WaveProfileBeachProfile = ({
  setDatasetId,
  clicked,
  setClicked,
  offshoreDepth,
  setOffShoreDistance,
  slopeSteepness,
  setSlopeSteepness,
  bedDisturbanceHeight,
  setBedDisturbanceHeight,
  offshoreDistance,
  setOffshoreDepth,
  distOfBedDisturbance,
  setDistOfBedDisturbance,
  beachOrientation,
  setBeachOrientation,
  significantWaveHeight,
  setSignificantWaveHeight,
  dominantWaveDirection,
  setDominantWaveDirection,
  peakWavePeriod,
  setPeakWavePeriod,
  stillWaterLevel,
  setStillWaterLevel,
}: WaveProps) => {
  const dispatch = useDispatch();
  const [processSchema, setProcessSchema] = useState("");
  const [processResponse, setProcessResponse] = useState<any>(null);
  const [processLog, setProcessLog] = useState<string | null>(null);
  const generateStatisticsClicked = useSelector(
    (state: RootState) => state.waveProfileToolbox.generateStatisticsClicked
  );
  const jobId = useSelector((state: RootState) => state.waveProfileToolbox.jobId);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { idTokenPayload } = useOidcIdToken();
  const { isAuthenticated } = useOidc();
  const [projectId, setProjectId] = useState("");
  const performUpload = async () => {
    const subprojectlist = await getSubProjectList(publicDataSets.PublicWaveToolbox.id);
    let personalFolderId = subprojectlist.data.find((p: any) => p.name === idTokenPayload.oid)?.id;
    if (personalFolderId) {
      setProjectId(personalFolderId);
    }

    if (!personalFolderId) {
      await createPersonalProject(idTokenPayload.oid, publicDataSets.PublicWaveToolbox.id);
      const updatedSubprojectList = await getSubProjectList(publicDataSets.PublicWaveToolbox.id);

      personalFolderId = updatedSubprojectList.data.find((p: any) => p.name === idTokenPayload.oid)?.id;

      setProjectId(personalFolderId);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      performUpload();
    }
  }, []);

  const validateAndSetErrorStates = () => {
    let isValid = true;

    const isOffshoreDepthValid =
      offshoreDepth.value !== null && offshoreDepth.value >= -200 && offshoreDepth.value <= -3;
    if (!isOffshoreDepthValid) {
      setOffshoreDepth((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setOffshoreDepth((prev: any) => ({ ...prev, error: false }));
    }

    const isSlopeSteepnessValid =
      slopeSteepness.value !== null && slopeSteepness.value >= 1 && slopeSteepness.value <= 3;
    if (!isSlopeSteepnessValid) {
      setSlopeSteepness((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setSlopeSteepness((prev: any) => ({ ...prev, error: false }));
    }

    const isBedDisturbanceHeightValid =
      bedDisturbanceHeight.value !== null && bedDisturbanceHeight.value >= 0 && bedDisturbanceHeight.value <= 3;
    if (!isBedDisturbanceHeightValid) {
      setBedDisturbanceHeight((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setBedDisturbanceHeight((prev: any) => ({ ...prev, error: false }));
    }

    const isOffShoreDistanceValid =
      offshoreDistance.value !== null && offshoreDistance.value >= -10000 && offshoreDistance.value <= -300;
    if (!isOffShoreDistanceValid) {
      setOffShoreDistance((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setOffShoreDistance((prev: any) => ({ ...prev, error: false }));
    }

    const isDistOfBedDisturbanceValid = distOfBedDisturbance.value !== null && distOfBedDisturbance.value <= 0;
    if (!isDistOfBedDisturbanceValid) {
      setDistOfBedDisturbance((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setDistOfBedDisturbance((prev: any) => ({ ...prev, error: false }));
    }

    const isBeachOrientationValid =
      beachOrientation.value !== null && beachOrientation.value >= 0 && beachOrientation.value <= 360;
    if (!isBeachOrientationValid) {
      setBeachOrientation((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setBeachOrientation((prev: any) => ({ ...prev, error: false }));
    }

    const isSignificantWaveHeightValid =
      significantWaveHeight.value !== null && significantWaveHeight.value >= 0 && significantWaveHeight.value <= 5;
    if (!isSignificantWaveHeightValid) {
      setSignificantWaveHeight((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setSignificantWaveHeight((prev: any) => ({ ...prev, error: false }));
    }

    const isDominantWaveDirectionValid =
      dominantWaveDirection.value !== null && dominantWaveDirection.value >= 0 && dominantWaveDirection.value <= 360;
    if (!isDominantWaveDirectionValid) {
      setDominantWaveDirection((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setDominantWaveDirection((prev: any) => ({ ...prev, error: false }));
    }

    const isPeakWavePeriodValid =
      peakWavePeriod.value !== null && peakWavePeriod.value >= 1.5 && peakWavePeriod.value <= 25;
    if (!isPeakWavePeriodValid) {
      setPeakWavePeriod((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setPeakWavePeriod((prev: any) => ({ ...prev, error: false }));
    }

    const isStillWaterLevelValid =
      stillWaterLevel.value !== null && stillWaterLevel.value >= -15 && stillWaterLevel.value <= 10;
    if (!isStillWaterLevelValid) {
      setStillWaterLevel((prev: any) => ({ ...prev, error: true }));
      isValid = false;
    } else {
      setStillWaterLevel((prev: any) => ({ ...prev, error: false }));
    }

    return isValid;
  };

  useEffect(() => {
    let uploadSnackbarKey: any = null;
    if (generateStatisticsClicked && !processLog) {
      uploadSnackbarKey = enqueueSnackbar("Running wave profile model...", {
        variant: "info",
        persist: true,
        action: (key) => (
          <>
            <CircularProgress size={24} sx={{ color: "white" }} />
            <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{ color: "#FFFFFF" }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
    }

    if (processLog) {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
      if (processResponse.statusMessage === "Output ready") {
        enqueueSnackbar("Successfully generated wave profile", {
          variant: "success",
          autoHideDuration: 4000,
        });
        const dataset = processLog.split("file_id")[1].split(": ")[1].split(",")[0];
        setDatasetId(dataset);
      } else {
        enqueueSnackbar("Error generating wave profile", {
          variant: "error",
          autoHideDuration: 4000,
        });
      }
    }
    return () => {
      if (uploadSnackbarKey !== null) {
        closeSnackbar(uploadSnackbarKey);
      }
    };
  }, [generateStatisticsClicked, processLog, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    const commandString = `python main.py '{"projectId": "${projectId}","env":"${publicDataSets.env}", "Hm0": ${significantWaveHeight.value},"Tp": ${peakWavePeriod.value},"MWD": ${dominantWaveDirection.value},"BD": ${beachOrientation.value},"WL": ${stillWaterLevel.value},"b": ${slopeSteepness.value},"z0": ${offshoreDepth.value},"x0": ${offshoreDistance.value},"Ab": ${bedDisturbanceHeight.value},"xb": ${distOfBedDisturbance.value}}'`;

    const processSchemaValue = JSON.stringify(
      {
        Runtime: {
          type: "ContainerRuntimeSpec",
          Containers: [
            {
              Image: publicDataSets.dockerWaveProfileImage,
              Command: ["/bin/sh", "-c", commandString],
            },
          ],
        },
      },
      undefined,
      2
    );
    setProcessSchema(processSchemaValue);
  }, [
    offshoreDepth,
    slopeSteepness,
    bedDisturbanceHeight,
    offshoreDistance,
    distOfBedDisturbance,
    beachOrientation,
    significantWaveHeight,
    dominantWaveDirection,
    peakWavePeriod,
    stillWaterLevel,
    projectId,
  ]);

  const handleGenerateStatistics = async (): Promise<void> => {
    setDatasetId("");
    dispatch(setWaveProfileGenerateStatisticsClicked(true));
    setProcessResponse(null);
    setProcessLog(null);

    if (processSchema) {
      const data = await platform.postJson("process/job", JSON.parse(processSchema), "3");
      dispatch(setWaveProfileJobId(data.jobId));
      const refreshIntervalId = setInterval(async function () {
        try {
          const convertingItem = await platform.getJson(`process/job/${data.jobId}`, "3");
          if (convertingItem.jobState === "Running") {
            setProcessResponse(convertingItem);
          }
          if (convertingItem.jobState === "Finished" || convertingItem.jobState === "Error") {
            setProcessResponse(convertingItem);
            clearInterval(refreshIntervalId);

            const convertingLog = await platform.getJson(`process/job/${data.jobId}/log`, "3");
            setProcessLog(convertingLog.logs[0].log);
            dispatch(setWaveProfileGenerateStatisticsClicked(false));
            dispatch(setWaveProfileJobId(""));
            setClicked(false);
          }
        } catch (error) {
          clearInterval(refreshIntervalId);
        }
      }, 1000);

      setTimeout(() => {
        clearInterval(refreshIntervalId);
      }, 12000000);

      setProcessResponse(data);
    }
  };

  const deleteData = async () => {
    if (projectId !== "") {
      const datasets = await getAllDatasetsInsideProject(projectId);
      datasets.data.forEach((dataset: any) => deleteBathymetryData(dataset.id));
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setClicked(true);
    deleteData();
    const formIsValid = validateAndSetErrorStates();
    if (!formIsValid) {
      return;
    }
    handleGenerateStatistics();
  };

  const stopInterpolation = async () => {
    if (jobId !== "") {
      await platform.putjson(`process/job/${jobId}/cancel`, "3");
      dispatch(setWaveProfileGenerateStatisticsClicked(false));
    }
  };

  return (
    <Box m="0rem 1rem 0.6rem 1rem">
      <Typography variant="h4" color="secondary.dark" mt="0.5rem" mb="1rem">
        Beach Profile
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="1rem" columnGap="1rem">
        <TextFieldComponent
          label={
            <div>
              Offshore depth , z<sub>o</sub> [-200, -3]
            </div>
          }
          text="m"
          value={offshoreDepth.value}
          error={offshoreDepth.error}
          helperText={offshoreDepth.error ? "Value must be between -200 and -3" : ""}
          onChange={(e) =>
            setOffshoreDepth({
              ...offshoreDepth,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: offshoreDepth.error,
            })
          }
        />
        <TextFieldComponent
          label="Slope steepness parameter, b [1, 3]"
          text="[-]"
          value={slopeSteepness.value}
          error={slopeSteepness.error}
          helperText={slopeSteepness.error ? "Value must be between 1 and 3" : ""}
          onChange={(e) =>
            setSlopeSteepness({
              ...slopeSteepness,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: slopeSteepness.error,
            })
          }
        />

        <TextFieldComponent
          label={
            <div>
              Offshore distance, x<sub>o</sub> [-10,000, -300]
            </div>
          }
          text="m"
          value={offshoreDistance.value}
          error={offshoreDistance.error}
          helperText={offshoreDistance.error ? "Value must be between -10,000 and -300" : ""}
          onChange={(e) =>
            setOffShoreDistance({
              ...offshoreDistance,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: offshoreDistance.error,
            })
          }
        />
        <TextFieldComponent
          label={
            <div>
              (Opt) Bed disturbance height, A<sub>b</sub> [0, 3]
            </div>
          }
          text="m"
          value={bedDisturbanceHeight.value}
          error={bedDisturbanceHeight.error}
          helperText={bedDisturbanceHeight.error ? "Value must be between 0 and 3" : ""}
          onChange={(e) =>
            setBedDisturbanceHeight({
              ...bedDisturbanceHeight,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: bedDisturbanceHeight.error,
            })
          }
        />

        <TextFieldComponent
          label={
            <div>
              Beach orientation, θ<sub>b</sub> [0, 360]
            </div>
          }
          text="deg"
          value={beachOrientation.value}
          error={beachOrientation.error}
          helperText={beachOrientation.error ? "Value must be between 0 and 360" : ""}
          onChange={(e) =>
            setBeachOrientation({
              ...beachOrientation,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: beachOrientation.error,
            })
          }
        />
        <TextFieldComponent
          label={
            <div>
              (Opt) X-Dist of bed disturbance, x<sub>b</sub> [x<sub>o</sub>, 0]
            </div>
          }
          text="m"
          value={distOfBedDisturbance.value}
          error={distOfBedDisturbance.error}
          helperText={distOfBedDisturbance.error ? "Value must be between 0 and 3" : ""}
          onChange={(e) =>
            setDistOfBedDisturbance({
              ...distOfBedDisturbance,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: distOfBedDisturbance.error,
            })
          }
        />
      </Box>
      <Typography variant="h4" color="secondary.dark" mt="2rem" mb="1rem">
        Offshore Wave Conditions
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="1rem" columnGap="1rem">
        <TextFieldComponent
          label={
            <div>
              Significant wave height, H<sub>s</sub> [0, 5]
            </div>
          }
          text="m"
          value={significantWaveHeight.value}
          error={significantWaveHeight.error}
          helperText={significantWaveHeight.error ? "Value must be between 0 and 5" : ""}
          onChange={(e) =>
            setSignificantWaveHeight({
              ...significantWaveHeight,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: significantWaveHeight.error,
            })
          }
        />
        <TextFieldComponent
          label={
            <div>
              Dominant wave direction, θ<sub>o</sub> [0, 360]
            </div>
          }
          text="deg"
          value={dominantWaveDirection.value}
          error={dominantWaveDirection.error}
          helperText={dominantWaveDirection.error ? "Value must be between 0 and 360" : ""}
          onChange={(e) =>
            setDominantWaveDirection({
              ...dominantWaveDirection,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: dominantWaveDirection.error,
            })
          }
        />
        <TextFieldComponent
          label={
            <div>
              Peak wave period, T<sub>p</sub> [1.5, 25]
            </div>
          }
          text="s"
          value={peakWavePeriod.value}
          error={peakWavePeriod.error}
          helperText={peakWavePeriod.error ? "Value must be between 1.5 and 25" : ""}
          onChange={(e) =>
            setPeakWavePeriod({
              ...peakWavePeriod,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: peakWavePeriod.error,
            })
          }
        />
        <TextFieldComponent
          label="Still water level, η [-15, 10]"
          text="m"
          value={stillWaterLevel.value}
          error={stillWaterLevel.error}
          helperText={stillWaterLevel.error ? "Value must be between -15 and 10" : ""}
          onChange={(e) =>
            setStillWaterLevel({
              ...stillWaterLevel,
              value: e.target.value === "" || e.target.value === "-" ? null : parseFloat(e.target.value),
              error: stillWaterLevel.error,
            })
          }
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
        {generateStatisticsClicked && (
          <LightTooltip title="Stop wave model" placement="left" enterDelay={500} leaveDelay={200}>
            <IconButton disableRipple sx={{ color: "red", mt: "1.25rem" }} onClick={stopInterpolation}>
              <StopCircleOutlinedIcon fontSize="large" />
            </IconButton>
          </LightTooltip>
        )}
        <LightTooltip
          title="Login to use the toolbox"
          placement="left"
          enterDelay={500}
          leaveDelay={200}
          disableHoverListener={!!idTokenPayload}
        >
          <span style={{ marginTop: "1.5rem", width: "9rem" }}>
            <Button
              variant="contained"
              disabled={!idTokenPayload}
              disableElevation
              onClick={generateStatisticsClicked ? () => {} : handleSubmit}
              sx={{ width: "9rem" }}
            >
              {generateStatisticsClicked ? (
                <>
                  <CircularProgress color="inherit" size="2rem" />
                </>
              ) : (
                <Typography variant="body2" fontWeight="500">
                  Run Wave Model
                </Typography>
              )}
            </Button>
          </span>
        </LightTooltip>
      </Box>
    </Box>
  );
};
