import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { toolControls } from "../../Openlayers/data_layers";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { LegendContainer } from "../../../assets/component_styles";
import ScaleBar from "../../ScaleBar/ScaleBar";
import DropdownSelect from "../DropdownSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { publicDataSets } from "../../../api/init";
import { getTileDataSetsMetadata } from "../../../api/backend_public";
import { WaterLevelGroup } from "../../Openlayers/default_groups_layers";
import { LightTooltip } from "./BathymetryLegend";

export const WaterLevelLegend = () => {
  const toolname = new URLSearchParams(window.location.search).get("tool");

  const [dataSetLayers, setDataSetLayers] = React.useState<any[] | null>(null);
  const [dataSetMetadata, setDataSetMetadata] = React.useState<any>(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [selectedDatasetIndex, setSelectedDatasetIndex] = React.useState<number>(0);
  const [datasetList, setDatasetList] = React.useState<any[] | null>(null);

  useEffect(() => {
    (async () => {
      const defaultDS = await getTileDataSetsMetadata(publicDataSets.WaterLevel.id);
      const secondDS = await getTileDataSetsMetadata(publicDataSets.WaterLevel.second_id);

      const dsArray = [defaultDS, secondDS];
      setDatasetList(dsArray);
      setDataSetLayers(dsArray[0].tilingSchemes);
      setDataSetMetadata(dsArray[0]);
    })();

    WaterLevelGroup.changed();

    return () => {
      WaterLevelGroup.set("groupCount", undefined);
    };
  }, []);

  useEffect(() => {
    const refreshIntervalId = setInterval(function () {
      if (
        WaterLevelGroup.getLayersArray().length > 0 &&
        WaterLevelGroup.getLayersArray().length === WaterLevelGroup.get("groupCount")
      ) {
        clearInterval(refreshIntervalId);

        const Layer0 = WaterLevelGroup.getLayersArray().findIndex(
          (item) => item.get("datasetId") === publicDataSets.WaterLevel.id
        );
        const Layer1 = WaterLevelGroup.getLayersArray().findIndex(
          (item) => item.get("datasetId") === publicDataSets.WaterLevel.second_id
        );

        if (selectedDatasetIndex === 0) {
          WaterLevelGroup.getLayersArray()[Layer0].setVisible(true);
          WaterLevelGroup.getLayersArray()[Layer1].setVisible(false);
        } else {
          WaterLevelGroup.getLayersArray()[Layer0].setVisible(false);
          WaterLevelGroup.getLayersArray()[Layer1].setVisible(true);
        }

        WaterLevelGroup.changed();
      }
    }, 200);

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 12000000);

    return () => {};
  }, [selectedDatasetIndex]);

  const handleChange = (e: any) => {
    if (!datasetList) return;
    setSelectedDatasetIndex(e.target.value);
    setDataSetLayers(datasetList[e.target.value].tilingSchemes);
    setDataSetMetadata(datasetList[e.target.value]);
    WaterLevelGroup.changed();
  };

  return (
    <>
      {toolname === toolControls.WATERLEVEL && (
        <>
          <LegendContainer style={{ display: `${isMinimized ? "none" : "block"}` }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "12px",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography variant="body2" color="secondary.dark" fontWeight="700" mt="0.5rem">
                  Water levels
                </Typography>
                <LightTooltip
                  title={
                    <>
                      <Typography textAlign="center" justifyContent="center" mt="0.5rem" variant="body2">
                        Higher High Water Large Tide (HHWLT) and Chart Datum level surfaces were developed for this
                        project, based on HyVSEPs vertical separation surfaces developed by the Canadian Hydrographic
                        Service (CHS). More information is available online here:{" "}
                        <a
                          href="https://statics.teams.cdn.office.net/evergreen-assets/safelinks/1/atp-safelinks.html"
                          style={{ color: "#00A4EC" }}
                        >
                          GEOSCAN Search Results: Fastlink (nrcan.gc.ca).
                        </a>{" "}
                      </Typography>
                    </>
                  }
                  placement="right"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <IconButton sx={{ color: "secondary.dark", mt: "-0.25rem" }} disableRipple>
                    <InfoOutlinedIcon />
                  </IconButton>
                </LightTooltip>
              </Box>

              <IconButton
                size="small"
                onClick={() => setIsMinimized(true)}
                sx={{ color: "secondary.dark" }}
                disableRipple
              >
                <CloseFullscreenIcon fontSize="small" />
              </IconButton>
            </Box>
            <Grid container spacing={3} direction="row-reverse">
              <Grid item xs={12}>
                <DropdownSelect
                  label="Variable"
                  value={selectedDatasetIndex.toString()}
                  onChange={handleChange}
                  options={[
                    {
                      label: "HHWLT",
                      value: 0,
                    },
                    {
                      label: "Chart Datum",
                      value: 1,
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <ScaleBar
              tilingScheme={dataSetMetadata?.tilingSchemes[0]}
              schemeId={dataSetLayers ? dataSetLayers[0].schemeId : ""}
            />
          </LegendContainer>

          <IconButton
            size="small"
            onClick={() => setIsMinimized(false)}
            sx={{
              position: "absolute",
              bottom: "2rem",
              right: "65px",
              transition: "transform 0.3s",
              color: "secondary.dark",
              zIndex: 20,
              display: `${isMinimized ? "block" : "none"}`,
            }}
            disableRipple
          >
            <FitScreenIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </>
  );
};
