import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { changeDashboardSearchInput } from "../../features/dashboardSearchbarSlice";

const DashboardSearchBar = () => {
  const dispatch = useDispatch();
  const searchbarValue = useSelector(
    (state: RootState) => state.dashboardSearchbar.value
  );

  const handleChange = (value: string) => {
    dispatch(changeDashboardSearchInput({ value: value }));
  };

  return (
    <>
      <TextField
        type="search"
        id="search"
        placeholder="What location are you looking for?"
        color="secondary"
        size="small"
        variant="outlined"
        autoComplete="off"
        sx={{
          width: "400px",
          bgcolor: "#fff",
          left: "1rem",
          input: { cursor: "pointer" },
          "& fieldset": { border: "none" },
          border: "0.5px solid #86A2B3",
          boxShadow: "1px 4px 4px -2px rgba(9, 51, 75, 0.5)",
          borderRadius: "90px",
          mt: "1rem",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchbarValue}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};

export default DashboardSearchBar;
