import React, { useState } from "react";

type SeasonalFile = {
  file: File;
  rights: string;
  id: string;
};

type SeasonalFilesContextType = {
  seasonalFiles: SeasonalFile | null;
  setSeasonalFiles: React.Dispatch<React.SetStateAction<SeasonalFile | null>>;
};

export const SeasonalFilesContext = React.createContext<
  SeasonalFilesContextType | undefined
>(undefined);

interface SeasonalFilesProviderProps {
  children: React.ReactNode;
}

export const SeasonalFilesProvider: React.FC<SeasonalFilesProviderProps> = ({
  children,
}) => {
  const [seasonalFiles, setSeasonalFiles] = useState<SeasonalFile | null>(null);

  return (
    <SeasonalFilesContext.Provider value={{ seasonalFiles, setSeasonalFiles }}>
      {children}
    </SeasonalFilesContext.Provider>
  );
};
