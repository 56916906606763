import { createSlice } from "@reduxjs/toolkit";

export const DataRepoUploadStatusSlice = createSlice({
  name: "datarepouploadStatus",
  initialState: {
    isUploadCompleted: false,
    fileUID: "",
  },
  reducers: {
    setUploadCompleted: (state, action) => {
      state.isUploadCompleted = action.payload;
    },
    setFileUID: (state, action) => {
      state.fileUID = action.payload;
    },
  },
});

export const { setUploadCompleted, setFileUID } = DataRepoUploadStatusSlice.actions;
export default DataRepoUploadStatusSlice.reducer;
