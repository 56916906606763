import { Box, Divider, IconButton, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React from "react";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SeaLevelRiseModal } from "./SeaLevelRiseModal";
import SeaLevelRiseChart from "./SeaLevelRiseChart";
import { useEffect } from "react";
import { saveAs } from "file-saver";
import DropdownSelect from "../../DropDownList/DropDownSelect";
import { MapProps } from "../../Openlayers/data_layers";

interface TableProps {
  year: string;
  rcp26: string;
  rcp45: string;
  rcp85: string;
  unit: string;
}

interface NasaTableProps {
  year: string;

  rcp45: string;
  rcp85: string;
}

export const TableDetails = ({ year, rcp26, rcp45, rcp85, unit }: TableProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        m: "0.5rem 0rem",
      }}
    >
      <Typography
        variant="body1"
        color="secondary.dark"
        fontWeight="700"
        mt="0.5rem"
        flexBasis="20%"
        textAlign="center"
      >
        {year}
      </Typography>
      <Typography variant="body1" color="secondary.dark" mt="0.5rem" flexBasis="20%" textAlign="center">
        {unit === "cm" ? Number(rcp26).toFixed(1) : rcp26}
      </Typography>
      <Typography variant="body1" color="secondary.dark" mt="0.5rem" flexBasis="20%" textAlign="center">
        {unit === "cm" ? Number(rcp45).toFixed(1) : rcp45}
      </Typography>
      <Typography variant="body1" color="secondary.dark" mt="0.5rem" flexBasis="20%" textAlign="center">
        {unit === "cm" ? Number(rcp85).toFixed(1) : rcp85}
      </Typography>
      <Typography
        variant="body1"
        color="secondary.dark"
        mt="0.5rem"
        flexBasis="20%"
        textAlign="center"
        fontWeight="700"
      >
        {unit}
      </Typography>
    </Box>
  );
};

export const NASATableDetails = ({ year, rcp85, rcp45 }: NasaTableProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        m: "0.5rem 0rem",
      }}
    >
      <Typography
        variant="body1"
        color="secondary.dark"
        fontWeight="700"
        mt="0.5rem"
        flexBasis="20%"
        textAlign="center"
      >
        {year}
      </Typography>
      <Typography variant="body1" color="secondary.dark" mt="0.5rem" flexBasis="20%" textAlign="center">
        {Number(rcp85).toFixed(1)}
      </Typography>
      <Typography variant="body1" color="secondary.dark" mt="0.5rem" flexBasis="20%" textAlign="center">
        {Number(rcp45).toFixed(1)}
      </Typography>

      <Typography
        variant="body1"
        color="secondary.dark"
        mt="0.5rem"
        flexBasis="20%"
        textAlign="center"
        fontWeight="700"
      >
        cm
      </Typography>
    </Box>
  );
};

interface portProps {
  port: any;
}

type DashboardSeaLevelRiseProps = MapProps & portProps;

export const DashboardSeaLevelRise = ({ features, port }: DashboardSeaLevelRiseProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleSettingOpenClick = () => {
    setOpen(true);
  };

  const portname = new URLSearchParams(window.location.search).get("port");

  const [data, setData] = React.useState<{ [key: string]: any[] }>({
    rcp85: [],
    rcp45: [],
    rcp26: [],
  });

  const [rcp85Values, setRcp85Values] = React.useState<any[]>([]);
  const [rcp45Values, setRcp45Values] = React.useState<any[]>([]);
  const [rcp26Values, setRcp26Values] = React.useState<any[]>([]);

  const [dataSource, setDataSource] = React.useState("CAN-EWLAT Vertical Allowance");
  const handleDataSourceValue = (event: SelectChangeEvent) => {
    setData({
      rcp85: [],
      rcp45: [],
      rcp26: [],
    });
    setDataSource(event.target.value as string);
  };

  useEffect(() => {
    if (portname) {
      setRcp85Values(data.rcp85.map((item) => item[portname] || null));
      setRcp45Values(data.rcp45.map((item) => item[portname] || null));
      setRcp26Values(data.rcp26.map((item) => item[portname] || null));
    }
  }, [portname, data]);

  const convertArraysToCSV = (years: string[], arr1: any[], arr2: any[], arr3: any[]) => {
    let csv = "Years, RCP 8.5, RCP 4.5, RCP 2.6\n";
    years.forEach((year, index) => {
      const value1 = arr1[index] !== undefined ? arr1[index] : "";
      const value2 = arr2[index] !== undefined ? arr2[index] : "";
      const value3 = arr3[index] !== undefined ? arr3[index] : "";
      csv += `${year}, ${value1}, ${value2}, ${value3}\n`;
    });

    return csv;
  };
  const generateYearsArray = () => {
    let years = [];
    for (let year = 2020; year <= 2100; year += 10) {
      years.push(year.toString());
    }
    return years;
  };

  const handleDownloadClick = () => {
    const years = generateYearsArray();
    const combinedCSV = convertArraysToCSV(years, rcp85Values, rcp45Values, rcp26Values);
    const blob = new Blob([combinedCSV], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "CAN_EWLAT_Vertical_Allowance.csv");
  };

  const handleCCCSDownload = () => {
    const csvContent = generateCSVContentWithCustomHeaders(data.rcp26, data.rcp45, data.rcp85);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "CCCS_Sea_Level_Change_Data.csv");
  };

  const generateCSVContentWithCustomHeaders = (rcp26: any, rcp45: any, rcp85: any) => {
    const headers = "Year,RCP 2.6 (50%),RCP 4.5 (50%),RCP 8.5 (50%)";
    const rows = years.map((year, index) => [year, rcp26[index] || "", rcp45[index] || "", rcp85[index] || ""]);

    const csvRows = rows.map((row) => row.join(","));
    csvRows.unshift(headers);
    return csvRows.join("\n");
  };

  const handleNASADownload = () => {
    const csvContent = generateCSVForNASA(data.rcp85, data.rcp45);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "NASA_Sea_Level_Change_Data.csv");
  };

  const generateCSVForNASA = (rcp45: any, rcp85: any) => {
    const headers = "Year,SSP2-4.5 (50%),SSP5-8.5 (50%)";
    const rows = nasaYears.map((year, index) => [year, rcp45[index] || "", rcp85[index] || ""]);

    const csvRows = rows.map((row) => row.join(","));
    csvRows.unshift(headers);
    return csvRows.join("\n");
  };

  const isNoDataValue = (value: any) => parseFloat(value) === -99.9999 || value === null;
  const noDataAvailable = (rcp26Values: any[], rcp45Values: any[], rcp85Values: any[]) => {
    const allValues = [...rcp26Values, ...rcp45Values, ...rcp85Values];
    return allValues.every(isNoDataValue);
  };
  const years = ["2006", "2010", "2020", "2030", "2040", "2050", "2060", "2070", "2080", "2090", "2100"];
  const nasaYears = [
    "2020",
    "2030",
    "2040",
    "2050",
    "2060",
    "2070",
    "2080",
    "2090",
    "2100",
    "2110",
    "2120",
    "2130",
    "2140",
    "2150",
  ];
  const isDataEmpty = data.rcp85.length === 0 && data.rcp45.length === 0;

  const isAllNaN = (array: any) => array.every((value: any) => isNaN(parseFloat(value)));
  const allRcp85NaN = isAllNaN(data.rcp85);
  const allRcp45NaN = isAllNaN(data.rcp45);
  return (
    <Box>
      {features && (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "670px 1fr",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" color="secondary.dark" sx={{ fontWeight: 700, m: "0.5rem 1rem" }}>
              Sea Level Rise
            </Typography>

            <Box
              onClick={
                dataSource === "CAN-EWLAT Vertical Allowance"
                  ? handleDownloadClick
                  : dataSource === "CCCS Sea-Level Change"
                  ? handleCCCSDownload
                  : handleNASADownload
              }
              mr="1rem"
            >
              <Stack direction="row">
                <IconButton color="primary" disableRipple>
                  <GetAppOutlinedIcon fontSize="medium" />
                </IconButton>
                <Typography
                  color="primary"
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    mt: "0.75rem",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Download
                </Typography>
              </Stack>
            </Box>
            <Box width="15rem" m="0.5rem 0rem 1rem 1rem">
              <DropdownSelect
                label="Data Source"
                value={dataSource}
                onChange={handleDataSourceValue}
                options={[
                  {
                    value: "CCCS Sea-Level Change",
                    label: "CCCS Sea-Level Change",
                  },
                  {
                    value: "NASA Sea-Level Change",
                    label: "NASA Sea-Level Change",
                  },
                  {
                    value: "CAN-EWLAT Vertical Allowance",
                    label: "CAN-EWLAT Vertical Allowance",
                  },
                ]}
              />
            </Box>
          </Box>
          <Divider />
          <Box bgcolor="grey.50" width="100%" height="calc(100vh - 15.5rem)" sx={{ overflowY: "auto" }}>
            <Box
              bgcolor="#FFFFFF"
              m="1rem 2rem"
              width="auto"
              height="auto"
              borderRadius="8px"
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
            >
              <Box m="1rem">
                <Box display="flex" flexDirection="row">
                  <Typography variant="body1" color="secondary.dark" fontWeight="700" pt="0.5rem">
                    {dataSource === "CCCS Sea-Level Change"
                      ? "Relative Sea Level Rise"
                      : dataSource === "NASA Sea-Level Change"
                      ? "Relative Sea Level Rise"
                      : "Vertical Sea Level Rise Allowance"}
                  </Typography>
                  <IconButton
                    sx={{ color: "secondary.dark", mt: "-0.05rem" }}
                    disableRipple
                    onClick={handleSettingOpenClick}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Box>
                <Box mt="-0.5rem">
                  <Typography variant="caption" color="secondary.dark">
                    {dataSource === "CCCS Sea-Level Change"
                      ? "Projections of relative sea level rise (AR5) with respect to a 1986-2005 baseline"
                      : dataSource === "NASA Sea-Level Change"
                      ? "Median projections of relative sea level rise (AR6) with respect to a 1995-2014 baseline"
                      : "Vertical allowances with respect to Mean Water Level (MWL) epoch 2010"}
                  </Typography>
                </Box>
                <Box>
                  <SeaLevelRiseChart
                    data={data}
                    setData={setData}
                    harbours={features}
                    dataAvailable={noDataAvailable(rcp26Values, rcp45Values, rcp85Values)}
                    dataSource={dataSource}
                    port={port}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              bgcolor="#FFFFFF"
              m="1rem 2rem 2rem 2rem"
              pb="0.25rem"
              pt="0.25rem"
              width="auto"
              height="auto"
              borderRadius="50px"
              boxShadow="1px 4px 8px -2px rgba(9, 51, 75, 0.5)"
            >
              {portname &&
              !noDataAvailable(rcp26Values, rcp45Values, rcp85Values) &&
              data.rcp85.map((item) => item[portname])[0] ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "3rem",
                      m: "0.5rem 3rem 0.5rem 3rem",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 2.6
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      ml="1rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 4.5
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      ml="1rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 8.5
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                  </Box>

                  <Divider />
                  <TableDetails
                    year={"2020"}
                    rcp26={rcp26Values[0]}
                    rcp45={rcp45Values[0]}
                    rcp85={rcp85Values[0]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2030"}
                    rcp26={rcp26Values[1]}
                    rcp45={rcp45Values[1]}
                    rcp85={rcp85Values[1]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2040"}
                    rcp26={rcp26Values[2]}
                    rcp45={rcp45Values[2]}
                    rcp85={rcp85Values[2]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2050"}
                    rcp26={rcp26Values[3]}
                    rcp45={rcp45Values[3]}
                    rcp85={rcp85Values[3]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2060"}
                    rcp26={rcp26Values[4]}
                    rcp45={rcp45Values[4]}
                    rcp85={rcp85Values[4]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2070"}
                    rcp26={rcp26Values[5]}
                    rcp45={rcp45Values[5]}
                    rcp85={rcp85Values[5]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2080"}
                    rcp26={rcp26Values[6]}
                    rcp45={rcp45Values[6]}
                    rcp85={rcp85Values[6]}
                    unit="m"
                  />
                  <Divider />
                  <TableDetails
                    year={"2090"}
                    rcp26={rcp26Values[7]}
                    rcp45={rcp45Values[7]}
                    rcp85={rcp85Values[7]}
                    unit="m"
                  />
                  <Divider />

                  <TableDetails
                    year={"2100"}
                    rcp26={rcp26Values[8]}
                    rcp45={rcp45Values[8]}
                    rcp85={rcp85Values[8]}
                    unit="m"
                  />
                </>
              ) : portname && dataSource === "CCCS Sea-Level Change" && !allRcp45NaN && !allRcp85NaN ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "3rem",
                      m: "0.5rem 3rem 0.5rem 3rem",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 2.6 (50%)
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      ml="1rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 4.5 (50%)
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      ml="1rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      RCP 8.5 (50%)
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                  </Box>

                  {years.map((year, index) => (
                    <React.Fragment key={year}>
                      <Divider />
                      <TableDetails
                        year={year}
                        rcp26={data.rcp26[index]}
                        rcp45={data.rcp45[index]}
                        rcp85={data.rcp85[index]}
                        unit="cm"
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : portname && dataSource === "NASA Sea-Level Change" && !allRcp45NaN && !allRcp85NaN ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "3rem",
                      m: "0.5rem 3rem 0.5rem 3rem",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      SSP2-4.5 (50%)
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      ml="1rem"
                      flexBasis="20%"
                      textAlign="center"
                    >
                      SSP5-8.5 (50%)
                    </Typography>
                    <Typography
                      variant="body1"
                      color="secondary.dark"
                      fontWeight="700"
                      mt="0.5rem"
                      flexBasis="20%"
                      textAlign="center"
                    ></Typography>
                  </Box>

                  {nasaYears.map((year, index) => (
                    <React.Fragment key={year}>
                      <Divider />
                      <NASATableDetails year={year} rcp85={data.rcp85[index]} rcp45={data.rcp45[index]} />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Box width="100%" height="18rem">
                  <Typography variant="h6" color="secondary.dark" display="flex" justifyContent="center" pt="15%">
                    No Data Available
                  </Typography>
                </Box>
              )}
            </Box>
            {portname &&
              (dataSource !== "CAN-EWLAT Vertical Allowance" ? isDataEmpty || (allRcp45NaN && allRcp85NaN) : true) &&
              (dataSource === "CAN-EWLAT Vertical Allowance" ? !data.rcp85.map((item) => item[portname])[0] : true) && (
                <Box
                  p="1rem"
                  bgcolor="#FFFFFF"
                  sx={{
                    m: "1rem 1.5rem 2rem 1.8rem",
                    width: "46rem",
                    border: "1px solid #CFDBE2",
                    borderRadius: "8px",
                    boxShadow: "1px 4px 8px -2px rgba(9, 51, 75, 0.5)",
                  }}
                >
                  <Typography color="grey.500" variant="body2" mb="0.75rem">
                    Warnings:
                  </Typography>
                  <Box>
                    {dataSource === "CAN-EWLAT Vertical Allowance" ? (
                      <Typography color="grey.500" variant="caption">
                        No CAN-EWALT data exists for {port.get("Harbour_Na")}.
                      </Typography>
                    ) : dataSource === "CCCS Sea-Level Change" ? (
                      <Typography color="grey.500" variant="caption">
                        CCCS AR5 sea level rise projections do not exist for this site.
                      </Typography>
                    ) : dataSource === "NASA Sea-Level Change" ? (
                      <Typography color="grey.500" variant="caption">
                        NASA AR6 sea level rise projections do not exist for this site.
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              )}
          </Box>
          <SeaLevelRiseModal open={open} handleClose={handleClose} dataSource={dataSource} />
        </>
      )}
    </Box>
  );
};
