import { Button, IconButton, Typography, Box, Avatar, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useState, useContext } from "react";
import { SettingsModal } from "./SettingsModal";
import { UsernameModal } from "./UsernameModal";
import { useOidc, useOidcIdToken } from "@axa-fr/react-oidc";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AboutUsModal } from "./AboutUsModal";
import { OpenlayersContext } from "../Openlayers/OpenlayersContext";
import { LightTooltip } from "../MapViewer/Legends/BathymetryLegend";

const getInitials = (name: string): string => {
  const names = name.split(" ");
  const initials = names
    .map((n) => n.charAt(0))
    .join("")
    .toUpperCase();
  return initials;
};

export const NavigationButton = () => {
  const map = useContext(OpenlayersContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [aboutusOpen, setAboutusOpen] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setOpen(false);
  const handleAboutUsClose = () => setAboutusOpen(false);

  const [language, setLanguage] = useState<string>("English");

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const openLanguage = Boolean(languageAnchorEl);
  const openUsername = Boolean(anchorEl);
  const handleCloseUsername = () => {
    setAnchorEl(null);
  };
  const handleUsernameClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingOpenClick = () => {
    setOpen(true);
  };

  const handleAboutusOpenClick = () => {
    setAboutusOpen(true);
  };

  const handleLoginOpenClick = () => {
    const currentRouteWithSearchParams = window.location.pathname + window.location.search;
    login(currentRouteWithSearchParams);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageSelection = (newLanguage: string) => {
    setLanguage(newLanguage);
    handleLanguageClose();
  };

  const { login, isAuthenticated } = useOidc();
  const { idToken, idTokenPayload } = useOidcIdToken();
  const theme = useTheme();
  const largeBreakPoint = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Box mt="0.25rem" display="flex" alignItems="center" flexDirection={!largeBreakPoint ? "row" : "column"}>
      <Box onClick={handleSettingOpenClick}>
        <IconButton disableRipple>
          <SettingsOutlinedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </IconButton>
        <Button
          variant="text"
          disableRipple
          sx={{
            margin: "0rem 0.5rem 0rem -0.4rem",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          <Typography variant="caption">Settings</Typography>
        </Button>
      </Box>

      <Button
        id="LanguageSelection"
        aria-controls={open ? "languageSelection" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableRipple
        variant="text"
        disableElevation
        onClick={handleLanguageChange}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        }
        sx={{
          m: "0rem 0.5rem",
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        <Box sx={{ height: "2rem", width: "2rem", mt: "0.5rem", mr: "0.5rem" }}>
          <img src={language === "English" ? "lang/english.svg" : "lang/french.svg"} alt="language img" />
        </Box>
        <Typography variant="caption" color="#FFFFFF">
          {language}
        </Typography>
      </Button>

      <Menu
        id="languageSelection"
        MenuListProps={{
          "aria-labelledby": "languageSelection",
        }}
        anchorEl={languageAnchorEl}
        open={openLanguage}
        onClose={handleLanguageClose}
        sx={{
          mt: "0.25rem",
          ml: "-0.25rem",
        }}
      >
        <LightTooltip title="Coming soon!" placement="left">
          <MenuItem
            disableRipple
            onClick={() => {
              if (language === "French") {
                handleLanguageSelection("English");
              }
            }}
            sx={{
              width: "8rem",
              height: "2rem",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <Box>
              <Typography variant="caption">
                {language === "English" ? (
                  <Box sx={{ display: "flex", flexDirection: "row", mt: "0.5rem" }}>
                    <Box sx={{ height: "2rem", width: "2rem", mr: "0.5rem" }}>
                      <img src="lang/french.svg" alt="English" />
                    </Box>
                    <Typography variant="caption" mt="0.15rem" color={"grey.500"}>
                      French
                    </Typography>
                  </Box>
                ) : (
                  "English"
                )}
              </Typography>
            </Box>
          </MenuItem>
        </LightTooltip>
      </Menu>

      <Box onClick={handleAboutusOpenClick}>
        <IconButton disableRipple sx={{ ml: "0.5rem" }}>
          <InfoOutlinedIcon
            sx={{
              color: "#FFFFFF",
            }}
          />
        </IconButton>
        <Button
          variant="text"
          disableRipple
          sx={{
            margin: "0rem 0.5rem 0rem -0.4rem",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          <Typography variant="caption">About Us</Typography>
        </Button>
      </Box>

      <Button
        variant={!isAuthenticated ? "contained" : "text"}
        size="small"
        disableRipple
        sx={{
          mr: "1rem",
          ml: "0.5rem",
          mt: "0.25rem",
          mb: "0.25rem",
          color: "primary.main",
          "&:hover": {
            backgroundColor: !isAuthenticated ? "primary.main" : "inherit",
            boxShadow: "0",
          },
        }}
        onClick={!isAuthenticated ? handleLoginOpenClick : handleUsernameClick}
      >
        <Typography variant="caption">
          {!isAuthenticated ? (
            <Typography fontWeight="700" variant="caption" color="#FFFFFF">
              Login
            </Typography>
          ) : (
            <Box display="flex" flexDirection="row" gap="1rem">
              <Box mr="-0.5rem" mt="0.5rem">
                <Typography variant="caption" color="#FFFFFF">
                  {idTokenPayload && idTokenPayload?.sub}
                </Typography>
              </Box>
              <Avatar
                sx={{
                  bgcolor: "#FFFFFF",
                  width: 32,
                  height: 32,
                  mb: "0.25rem",
                }}
              >
                <Typography variant="body2" mt="0.15rem" color={"secondary.dark"}>
                  {idTokenPayload && getInitials(idTokenPayload.sub)}
                </Typography>
              </Avatar>
            </Box>
          )}
        </Typography>
      </Button>

      <SettingsModal open={open} handleClose={handleClose} />

      <AboutUsModal open={aboutusOpen} handleClose={handleAboutUsClose} />
      <UsernameModal
        anchorEl={anchorEl}
        openUsername={openUsername}
        handleCloseUsername={handleCloseUsername}
        setAnchorEl={setAnchorEl}
        initials={idTokenPayload && getInitials(idTokenPayload.sub)}
      />
    </Box>
  );
};
