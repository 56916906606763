import { Autocomplete, Box, SelectChangeEvent, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store/store";
import { SelectDate } from "./SelectDate";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { changeToolboxFileFormat } from "../../../../features/ToolboxFeatures/ToolboxFileFormatSlice";
import { changeToolboxCRS } from "../../../../features/ToolboxFeatures/ToolboxCRSSlice";
import { changeToolboxVerticalDatum } from "../../../../features/ToolboxFeatures/ToolboxVerticalDatumSlice";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import UploadDropdownSelect from "./UploadDropDownSelect";
import { getCoordinateReferenceSystemOptions } from "../../../../api/backend_public";
import { DropdownSelectDisbaled } from "../../../Navigation/SettingsModal";

const fileFormatOptions = [
  { value: "XYZ (.xyz)", label: "Comma Separated (.csv) / XYZ (.xyz) / Text (.txt)" },
  { value: "ASCII (.asc)", label: "ASCII (.asc)", disabled: true },
  // { value: "XYZ (.xyz)", label: "XYZ (.xyz)" },
  // { value: "XYZ (.xyz)", label: "Text (.txt)" },
  { value: "LiDar Point Cloud (.las)", label: "LiDar Point Cloud (.las)", disabled: true },
  { value: "Point Shapefile (.shp)", label: "Point Shapefile (.shp)" },
  { value: "Tagged Image File Format (.tiff)", label: "Tagged Image File Format (.tiff)", disabled: true },
];

const verticalDatumOptions = [
  { value: "CGVD2013a", label: "CGVD2013a" },
  { value: "CGVD2013", label: "CGVD2013" },
  { value: "CGVD28", label: "CGVD28" },
];

interface validationProps {
  setRightValidate: Dispatch<SetStateAction<() => boolean>>;
  nextClicked: boolean;
  setNextClicked: Dispatch<SetStateAction<boolean>>;
}

interface CRSDropdownOption {
  value: string;
  label: string;
  id: number;
  proj4String: string;
  wkt: string;
}

export const DataDescriptionRight = ({ setRightValidate, nextClicked, setNextClicked }: validationProps) => {
  const dispatch = useDispatch();
  const fileformat = useSelector((state: RootState) => state.toolboxFileFormat.value);
  const CRS = useSelector((state: RootState) => state.toolboxCRS.CRS.value);
  const verticalDatum = useSelector((state: RootState) => state.toolboxVerticalDatum.value);

  const [fileFormatError, setFileFormatError] = useState(true);
  const [CRSError, setCRSError] = useState(true);
  // const [verticalDatumError, setVerticalDatumError] = useState(true);

  const [CRSOptions, setCRSOptions] = useState<CRSDropdownOption[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isCRSOptionSelected, setIsCRSOptionSelected] = useState(false);
  const [delayedInputValue, setDelayedInputValue] = useState("");
  const [wktValue, setWktValue] = useState("");

  const getCRSValues = async () => {
    try {
      const response = await getCoordinateReferenceSystemOptions();
      const CRSData = response.data;

      const CRSOptions = CRSData.map((crs: any) => ({
        value: crs.name,
        label: crs.name,
        id: crs.id,
        proj4String: crs.proj4String,
        wkt: crs.wkt,
      }));

      return CRSOptions;
    } catch (error) {
      console.error("Error fetching CRS values:", error);
      return [];
    }
  };
  useEffect(() => {
    getCRSValues().then((options) => {
      setCRSOptions(options);
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedInputValue(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);
  const handlefileformatChange = (event: SelectChangeEvent) => {
    dispatch(changeToolboxFileFormat({ value: event.target.value as string }));
    setFileFormatError(!event.target.value);
  };

  function getUnitsFromProj4String(proj4String: string | undefined) {
    if (proj4String) {
      if (/proj=longlat/.test(proj4String) || /proj=latlong/.test(proj4String)) {
        return "degrees";
      } else if (/proj=/.test(proj4String)) {
        return "meters";
      }
    }
    return "unknown";
  }

  const handleCRSChange = (event: any, newValue: CRSDropdownOption | null) => {
    setIsCRSOptionSelected(!!newValue);
    const unitValue = getUnitsFromProj4String(newValue?.proj4String);
    setWktValue(unitValue);
    dispatch(
      changeToolboxCRS({
        CRS: newValue
          ? {
              value: newValue.value,
              id: newValue.id,
              proj4String: newValue.proj4String,
              wkt: unitValue,
            }
          : { value: "", id: null, proj4String: "", wkt: "" },
      })
    );
  };

  const handleVerticalDatumChange = (event: SelectChangeEvent) => {
    dispatch(changeToolboxVerticalDatum({ value: event.target.value as string }));
    // setVerticalDatumError(!event.target.value);
  };

  useEffect(() => {
    setRightValidate(() => () => {
      setNextClicked(true);
      const errorExists = fileFormatError || CRSError; /*|| verticalDatumError*/

      return errorExists;
    });
  }, [fileFormatError, CRSError /*verticalDatumError*/]);

  useEffect(() => {
    setFileFormatError(!fileformat);
    setCRSError(!CRS);
    // setVerticalDatumError(!verticalDatum);
  }, [fileformat, CRS, verticalDatum]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <UploadDropdownSelect
            label="File Format"
            value={fileformat}
            onChange={handlefileformatChange}
            options={fileFormatOptions}
            error={nextClicked && fileFormatError}
          />
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          {CRS !== undefined && (
            <Autocomplete
              value={CRS ? CRSOptions.find((option) => option.value === CRS) ?? null : null}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setIsCRSOptionSelected(false);
              }}
              onChange={handleCRSChange}
              options={CRSOptions.filter(
                (option) =>
                  option.label.toLowerCase().includes(delayedInputValue.toLowerCase()) ||
                  option.id.toString().includes(delayedInputValue)
              )}
              getOptionLabel={(option) => `${option.label} (Code: ${option.id})`}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Box>{option.label}</Box>
                    <Box style={{ fontSize: "smaller" }}>Code - {option.id}</Box>
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Coordinate Reference System" error={nextClicked && CRSError} />
              )}
              fullWidth
              size="small"
              open={!isCRSOptionSelected && delayedInputValue.length > 0}
            />
          )}
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <DropdownSelectDisbaled
            label="Vertical Datum"
            value="CGVD2013"
            onChange={handleVerticalDatumChange}
            options={verticalDatumOptions}
            // error={nextClicked && verticalDatumError}
          />
        </Box>
        <Box width="20rem" mt="0.75rem" ml="1rem">
          <SelectDate />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
